<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 85%">
            <mat-card-title>Approval Flow</mat-card-title>
          </div>
          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>

        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="user">
              <mat-header-cell *matHeaderCellDef mat-sort-header="user">
                User
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let rowIndex = index">
                <span>{{ row.name }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="approvers">
              <mat-header-cell *matHeaderCellDef mat-sort-header="approvers">
                Approvers</mat-header-cell
              >
              <mat-cell *matCellDef="let row; let rowIndex = index">
                <span
                  (dblclick)="editing[rowIndex + '-approvers'] = true"
                  *ngIf="!editing[rowIndex + '-approvers']"
                  title="Double click to edit"
                >
                  {{ row.approversStr != null ? row.approversStr : "-" }}
                </span>
                <select
                  (change)="onUpdateId($event, 'approverUserId', rowIndex)"
                  *ngIf="editing[rowIndex + '-approvers']"
                >
                  <option value="" disabled selected>
                    {{ row.approversStr != null ? row.approversStr : "-" }}
                  </option>
                  <option
                    *ngFor="let o of userList"
                    [value]="o.id"
                    value="option"
                  >
                    {{ o.name }}
                  </option>
                </select>
                <button
                  (click)="editing[rowIndex + '-approvers'] = false"
                  *ngIf="editing[rowIndex + '-approvers']"
                  color="basic"
                  mat-icon-button
                >
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
