<div>
  <div>
    <mat-card *ngIf="guidanceIsStart == true">
      <mat-card-content>
        <mat-card-title>{{ "timeline.title" | translate }}</mat-card-title>
        <br /><br />
        <div *ngIf="run == true">
          <chart
            class="chart"
            type="horizontalBar"
            [data]="chartData"
            [options]="options"
          ></chart>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="guidanceIsStart == false">
      <mat-card-content style="min-height: 175px">
        <div>
          <div class="div" style="width: 50%">
            <mat-card-title>{{ "timeline.title" | translate }}</mat-card-title>
          </div>
        </div>
        <span style="width: 60%; float: right; color: rgb(103, 101, 105)">{{
          "dashboard.noGuidance" | translate
        }}</span>
      </mat-card-content>
    </mat-card>
  </div>
</div>
