<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <mat-card-title>Notifications</mat-card-title>

        <div class="bg-light p-10 p-r-20 p-l-20">
          <mat-form-field>
            <input
              matInput
              (keyup)="applyFilter($event.target.value)"
              placeholder="Filter"
            />
          </mat-form-field>
        </div>

        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Description
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let rowIndex = index">
                <span>
                  {{ row.description }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Notification Start Date
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span> </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Notification End Date
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let rowIndex = index">
                <span> </span>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
