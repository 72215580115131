import { CommonModule, DatePipe, DecimalPipe } from "@angular/common";
import { DemoMaterialModule } from "@planard/common/demo-material-module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { PlanningSalesRoutes } from "./planning-sales.routing";
import { NgModule } from "@angular/core";
import { PriceService } from "@planard/@core/backend/service/price.service";
import { PriceApi } from "@planard/@core/backend/api/price.api";
import { SalesService } from "@planard/@core/backend/service/sales.service";
import { SalesApi } from "@planard/@core/backend/api/sales.api";
import { ForecastService } from "@planard/@core/backend/service/forecast.service";
import { ForecastApi } from "@planard/@core/backend/api/forecast.api";
import { AddRiskOpportunitiesComponent } from "./risk-opportunities/add/add-risk-opportunities.component";
import { RiskOpportunitiesService } from "@planard/@core/backend/service/risk-opportunities.service";
import { RiskOpportunitiesApi } from "@planard/@core/backend/api/risk-opportunities.api";
import { PipesModule } from "@planard/@core/pipes/pipes.module";
import { AddNarrativesComponent } from "./narratives/add/add-narratives.component";
import { NarrativeApi } from "@planard/@core/backend/api/narrative.api";
import { NarrativeService } from "@planard/@core/backend/service/narrative.service";
import { AgGridModule } from "ag-grid-angular";
import { RiskOpportunitiesNewComponent } from "./risk-opportunities/risk-opportunities-new.component";
import { NarrativesNewComponent } from "./narratives/narratives-new.component";
import { MapleModule } from "@planard/maple/maple.module";
import { PricesNewComponent } from "./prices/prices-new.component";
import { ForecastNewComponent } from "./forecast/forecast-new.component";
import { ForecastIncomeComponent } from "./forecast-income/forecast-income.component";
import { OtherForecastApi } from "@planard/@core/backend/api/otherForecast.api";
import { OtherForecastService } from "@planard/@core/backend/service/otherForecast.service";
import { PricesApproverComponent } from "./prices-approver/prices-approver.component";
import { ForecastApproverComponent } from "./forecast-approver/forecast-approver.component";
import { ForecastIncomeApproverComponent } from "./forecast-income-approver/forecast-income-approver.component";
import { ApproverRequestApi } from "@planard/@core/backend/api/approverRequest.api";
import { ApproverRequestService } from "@planard/@core/backend/service/approverRequest.service";
import { SalesApprovalsComponent } from "./sales-approvals/sales-approvals.component";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { TranslateModule } from "@ngx-translate/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from "@angular/material-moment-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";

const API = [
  PriceApi,
  SalesApi,
  ForecastApi,
  RiskOpportunitiesApi,
  NarrativeApi,
  OtherForecastApi,
  ApproverRequestApi,
];

const SERVICES = [
  PriceService,
  SalesService,
  ForecastService,
  RiskOpportunitiesService,
  NarrativeService,
  OtherForecastService,
  ApproverRequestService,
];

@NgModule({
  imports: [
    PipesModule,
    CommonModule,
    MapleModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatMomentDateModule,
    RouterModule.forChild(PlanningSalesRoutes),
    AgGridModule.withComponents([]),
  ],
  declarations: [
    PricesNewComponent,
    RiskOpportunitiesNewComponent,
    AddRiskOpportunitiesComponent,
    NarrativesNewComponent,
    AddNarrativesComponent,
    ForecastNewComponent,
    ForecastIncomeComponent,
    PricesApproverComponent,
    ForecastApproverComponent,
    ForecastIncomeApproverComponent,
    SalesApprovalsComponent,
  ],
  providers: [
    ...API,
    ...SERVICES,
    DatePipe,
    DecimalPipe,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class PlanningSalesModule {}
