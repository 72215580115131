import { Component } from "@angular/core";
import { Channel } from "@planard/@core/entities/channel";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";
@Component({
  templateUrl: "./channel-add.component.html",
})
export class ChannelAddComponent {
  channel: Channel;

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, removeSpaces]),
  });
  options: FormGroup;

  constructor(
    public channelService: ChannelService,
    public dialogRef: MatDialogRef<ChannelAddComponent>,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public fb: FormBuilder
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  save() {
    if (this.form.valid) {
      this.channel = this.form.value;
      this.channelService.add(this.channel).subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.dialogRef.close(this.channel);
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
