<h1 mat-dialog-title>{{ "channelAdd.title" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        tabindex="1"
        formControlName="name"
        matInput
        name="name"
        placeholder="{{ 'channelAdd.name' | translate }}"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="2" type="submit">
      {{ "channelAdd.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="3" type="button">
      {{ "channelAdd.cancel" | translate }}
    </button>
  </div>
</form>
