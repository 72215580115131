import { ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ToastrService } from "ngx-toastr";
import { ProcessResult } from "@planard/@core/entities/process-result";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { ProductChannelAddComponent } from "@planard/admin/general/products/productChannel/add/productChannel-add.component";
import { ProductChannel } from "@planard/@core/entities/productChannel";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { Channel } from "@planard/@core/entities/channel";
@Component({
  templateUrl: "./productChannel.component.html",
})
export class ProductChannelComponent {
  channelList: Channel[];
  editing = {};
  rows = [];

  loadingIndicator: boolean = true;
  dataSource = new MatTableDataSource<ProductChannel>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns = ["channel", "actions"];

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder,
    public productsService: ProductsService,
    public channelService: ChannelService,
    public toastrService: ToastrService,
    public changeDetectorRefs: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ProductChannelComponent>,
    @Inject(MAT_DIALOG_DATA) public productId: number
  ) {
    this.productsService.onProductFormReload.subscribe(() => {
      this.getData();
    });
  }

  ngOnInit() {
    this.channelService.listAll().subscribe((result) => {
      this.channelList = result;
    });
    this.getData();
    setTimeout(() => {
      this.loadingIndicator = false;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 1500);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  getData() {
    this.productsService.listAllChannels(this.productId).subscribe((result) => {
      this.dataSource = new MatTableDataSource(result);
      this.rows = result;
    });
  }

  onAdd() {
    const dialogRef = this.dialog.open(ProductChannelAddComponent, {
      width: "500px",
      data: this.productId,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataSource.data.push(result);
        this.refresh();
      }
    });
  }

  onUpdate(event, cell, rowIndex) {
    var productChannel = this.rows[rowIndex];
    productChannel[cell] = parseInt(event.target.value);
    this.productsService
      .updateChannel(productChannel)
      .subscribe((result: ProcessResult) => {
        if (result.isSuccess) {
          this.editing[rowIndex + "-" + cell] = false;
          this.refresh();
          this.toastrService.success(result.message);
        } else {
          this.toastrService.error(result.message);
        }
      });
  }

  onDelete(rowId: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "Are you sure to delete this record?",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.productsService.deleteChannel(rowId).subscribe((backendResult) => {
          if (backendResult.isSuccess) {
            this.refresh();
            this.toastrService.success(backendResult.message);
          } else {
            this.toastrService.error(backendResult.message);
          }
        });
      }
    });
  }

  getChannel(id: any) {
    if (id != null) {
      for (let cn of Object.keys(this.channelList)) {
        if (this.channelList[cn].id === id) {
          return this.channelList[cn].name;
        }
      }
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  refresh() {
    this.productsService.onProductReload.emit();
    this.changeDetectorRefs.detectChanges();
    this.rows = [...this.rows];
    this.dataSource.data = [...this.dataSource.data];
  }
}
