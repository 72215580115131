import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { CustomValidators } from "ngx-custom-validators";
// import {UserService} from "../../@core/backend/service/user.service";

const password = new FormControl("", Validators.required);
const confirmPassword = new FormControl("", CustomValidators.equalTo(password));

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  isRequesting: boolean;
  submitted: boolean = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};

  public form: FormGroup;

  constructor(private fb: FormBuilder, private router: Router) {}

  get email() {
    return this.form.get("email");
  }

  get password() {
    return this.form.get("password");
  }

  get confirmPassword() {
    return this.form.get("confirmPassword");
  }

  get firstName() {
    return this.form.get("firstName");
  }

  get lastName() {
    return this.form.get("lastName");
  }

  ngOnInit() {
    const fullNameValidators = [
      Validators.minLength(4),
      Validators.maxLength(25),
    ];
    fullNameValidators.push(Validators.required);
    const lastNameValidators = [
      Validators.minLength(4),
      Validators.maxLength(25),
    ];
    lastNameValidators.push(Validators.required);
    const passwordValidators = [
      Validators.minLength(8),
      Validators.maxLength(12),
    ];
    passwordValidators.push(Validators.required);

    this.form = this.fb.group({
      fullName: this.fb.control("", [...fullNameValidators]),
      lastName: this.fb.control("", [...lastNameValidators]),
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email]),
      ],
      password: this.fb.control("", [...passwordValidators]),
      confirmPassword: this.fb.control("", [...passwordValidators]),
    });
  }

  registerUser() {
    this.submitted = true;
    this.isRequesting = true;
    this.errors = this.messages = [];
    this.user = this.form.value;

    // if (this.form.valid) {
    //   this.userService.register(this.user)
    //     .finally(() => this.isRequesting = false)
    //     .subscribe(
    //       result => {
    //         if (result) {
    //           this.router.navigate(['/login'], {queryParams: {brandNew: true, email: this.form.get('email')}});
    //         }
    //       },
    //       errors => this.errors = errors);
    // }
  }
}
