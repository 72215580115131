import { CommonModule, DecimalPipe } from "@angular/common";
import { DemoMaterialModule } from "@planard/common/demo-material-module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { PlanningCostsRoutes } from "./planning-costs.routing";
import { NgModule } from "@angular/core";
import { SalesService } from "@planard/@core/backend/service/sales.service";
import { SalesApi } from "@planard/@core/backend/api/sales.api";
import { AgGridModule } from "ag-grid-angular";
import { PipesModule } from "@planard/@core/pipes/pipes.module";
import { AddNarrativesComponent } from "./narratives/add/add-narratives.component";
import { NarrativeApi } from "@planard/@core/backend/api/narrative.api";
import { NarrativeService } from "@planard/@core/backend/service/narrative.service";
import { NarrativesNewComponent } from "./narratives/narratives-new.component";
import { MapleModule } from "@planard/maple/maple.module";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { PlanService } from "@planard/@core/backend/service/plan.service";
import { CentralPlanService } from "@planard/@core/backend/service/centralPlan.service";
import { PlanComponent } from "./plan/plan.component";
import { PlanApi } from "@planard/@core/backend/api/plan.api";
import { CentralPlanComponent } from "./centralPlan/centralPlan.component";
import { CentralPlanApi } from "@planard/@core/backend/api/centralPlan.api";
import { PlanApproverComponent } from "./plan-approver/plan-approver.component";
import { CentralPlanApproverComponent } from "./centralPlan-approver/centralPlan-approver.component";
import { ApproverRequestApi } from "@planard/@core/backend/api/approverRequest.api";
import { ApproverRequestService } from "@planard/@core/backend/service/approverRequest.service";
import { CostApprovalsComponent } from "./cost-approvals/cost-approvals.component";
import { TranslateModule } from "@ngx-translate/core";

const API = [
  SalesApi,
  NarrativeApi,
  PlanApi,
  CentralPlanApi,
  ApproverRequestApi,
];

const SERVICES = [
  SalesService,
  NarrativeService,
  ScenarioService,
  GuidanceService,
  PlanService,
  CentralPlanService,
  CentralPlanService,
  ApproverRequestService,
];

@NgModule({
  imports: [
    PipesModule,
    CommonModule,
    MapleModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule.forChild(PlanningCostsRoutes),
    AgGridModule.withComponents([]),
  ],
  declarations: [
    NarrativesNewComponent,
    AddNarrativesComponent,
    PlanComponent,
    CentralPlanComponent,
    PlanApproverComponent,
    CentralPlanApproverComponent,
    CostApprovalsComponent,
  ],
  providers: [...API, ...SERVICES, DecimalPipe],
})
export class PlanningCostsModule {}
