import { BaseEntity } from "@planard/@core/base/BaseEntity";
export class LaunchTimeline extends BaseEntity {
  scenarioId?: number;
  launchName?: string;
  field1?: string;
  field2?: string;
  field3?: string;
  field4?: string;
  field5?: string;
  isHeader: Boolean;
}
