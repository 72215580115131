import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";
import { RiskOpportunities } from "@planard/@core/entities/riskOpportunities";

@Injectable()
export class RiskOpportunitiesApi {
  private readonly apiController: string = "riskopportunity";

  constructor(private api: HttpService) {}

  listAll<T>(page?: number, pageSize?: number): Observable<any> {
    const endpointUrl =
      page && pageSize
        ? `${this.apiController}/listAll/${page}/${pageSize}`
        : `${this.apiController}/listAll`;
    return this.api.get(endpointUrl);
  }

  listAllByGuidanceId<T>(guidanceId: number): Observable<any> {
    const endpointUrl = `${this.apiController}/listAllByGuidanceId/${guidanceId}`;
    return this.api.get(endpointUrl);
  }

  report<T>(
    guidanceId: number,
    impactArea: any,
    typeOfImpact: any,
    likeliHood: any
  ): Observable<any> {
    const endpointUrl = `${this.apiController}/riskOppsReport/${guidanceId}/${impactArea}/${typeOfImpact}/${likeliHood}`;
    return this.api.get(endpointUrl);
  }

  add<T>(riskOpportunities: RiskOpportunities): Observable<any> {
    return this.api.post(`${this.apiController}/add`, riskOpportunities);
  }

  update<T>(riskOpportunities: RiskOpportunities): Observable<any> {
    return this.api.post(`${this.apiController}/update`, riskOpportunities);
  }

  delete<T>(riskOpportunitiesId: any): Observable<any> {
    var json = { id: riskOpportunitiesId };
    const endPointUrl = `${this.apiController}/delete`;
    return this.api.post(endPointUrl, json);
  }
}
