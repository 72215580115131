import { Routes } from "@angular/router";

import { NotificationComponent } from "./notification.component";

export const NotificationRoutes: Routes = [
  {
    path: "",
    component: NotificationComponent,
    data: {
      title: "Notification",
      urls: [{ title: "Notification", url: "/notification" }],
    },
  },
];
