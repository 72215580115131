import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Observable } from "rxjs";
import { ApplicationUser } from "@planard/@core/entities/applicationUser";

@Injectable()
export class AccountsApi {
  private readonly apiController: string = "accounts";

  constructor(private api: HttpService) {}

  getUser<T>(): Observable<ApplicationUser> {
    return this.api.get(`${this.apiController}/getUser`);
  }

  getSignedUser<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/getSignedUser`);
  }

  isApprover<T>(userId: any): Observable<any> {
    return this.api.get(`${this.apiController}/isApprover/${userId}`);
  }

  listUsers<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listUsers`);
  }

  add<T>(user: any): Observable<any> {
    return this.api.post(`${this.apiController}/addUser`, user);
  }

  changePassword<T>(password: any): Observable<any> {
    return this.api.post(`${this.apiController}/changeUserPassword`, password);
  }

  listDepartments<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listDepartments`);
  }

  listSubDepartments<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listSubDepartments`);
  }

  updateUser<T>(user: any): Observable<any> {
    return this.api.post(`${this.apiController}/updateUser`, user);
  }

  deleteUser<T>(accountId: number): Observable<any> {
    var json = { id: accountId };
    return this.api.post(`${this.apiController}/deleteUser`, json);
  }
}
