import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { ProcessResult } from "@planard/@core/entities/process-result";
import { Scenario } from "@planard/@core/entities/scenario";
import { EnumImpactArea } from "@planard/@core/enums/EnumImpactArea";
import { Products } from "@planard/@core/entities/product";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";

@Component({
  templateUrl: "./scenario.component.html",
  styles: [
    `
      .toRightReverse {
        flex: 0 0 10%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class ScenarioComponent implements OnInit {
  edit: boolean;
  impactAreaList: EnumImpactArea[] = EnumImpactArea.values;
  productList: Products[];
  editing = {};
  rows = [];
  guidanceIsStart: boolean;
  processResult: ProcessResult;
  loadingIndicator = true;
  scenario: Scenario;

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required]),
    impactedProducts: new FormControl("", [Validators.required]),
    impactArea: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  dataSource: MatTableDataSource<Scenario>;
  displayedColumns: string[] = [
    "name",
    "impactedProducts",
    "impactArea",
    "description",
  ];
  @ViewChild(ScenarioComponent, { static: true }) table: any[];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  products: any = [];
  impactAreas: any = [];

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder,
    public productsService: ProductsService,
    public guidanceService: GuidanceService,
    public changeDetectorRefs: ChangeDetectorRef,
    public scenarioService: ScenarioService,
    public toastr: ToastrService
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
    this.scenarioService.onScenarioReload.subscribe(() => {
      this.getData();
    });
  }

  ngOnInit() {
    this.getData();
    setTimeout(() => {
      this.loadingIndicator = false;
      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "name", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }
    }, 1500);
  }

  getData() {
    this.guidanceService.getGuidance().subscribe((data) => {
      this.guidanceIsStart = data.start ? true : false;
    });
    this.scenarioService.listAll().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);
      this.rows = data;
    });
    this.productsService.listAll().subscribe((data) => {
      this.productList = data;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  getImpactArea(impact: string) {
    var Arr = impact.split("");
    var impactArea = [];
    Arr.forEach((ele) => {
      if (ele == "P") {
        impactArea.push("Price");
      } else if (ele == "V") {
        impactArea.push("Volume");
      } else if (ele == "C") {
        impactArea.push("Cost");
      }
    });
    var Str = impactArea.join(", ");
    return impactArea;
  }
}
