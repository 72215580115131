import { Injectable } from "@angular/core";
import { ApplicationUser } from "@planard/@core/entities/applicationUser";
import { AccountsService } from "@planard/@core/backend/service/accounts.service";
import { UserManager, User } from "oidc-client";
import { environment } from "../../../environments/environment";
import { TimelineService } from "@planard/@core/backend/service/timeline.service";
import { CompanySetupService } from "@planard/@core/backend/service/company-setup.service";
import { Optional } from "ag-grid-community";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { LocalStorageService } from "./storage.service";
import { TranslateService } from "@ngx-translate/core";

const settings: any = {
  authority: environment.authority,
  client_id: "spa-client",
  response_type: "code",
  scope: "Planard.WebApi",
  redirect_uri: environment.callback + "#/auth/callback",
  post_logout_redirect_uri: environment.callback,
  silent_redirect_uri: environment.callback + "renew-callback.html",
  automaticSilentRenew: false,
  accessTokenExpiringNotificationTime: 4,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

export class UserServiceConfig {
  user;
}

@Injectable({
  providedIn: "root",
})
@Injectable()
export class AuthService {
  private manager: UserManager = new UserManager(settings);
  private user: User = null;
  private applicationUser: ApplicationUser = null;
  today;

  constructor(
    private accountsService: AccountsService,
    private companyService: CompanySetupService,
    private timelineService: TimelineService,
    public storageService: LocalStorageService,
    public translate: TranslateService,
    @Optional() config?: UserServiceConfig
  ) {
    if (config) {
      this.user = config.user;
    }

    this.manager.getUser().then((user) => {
      this.user = user;
    });

    let date = new Date();
    this.today = new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: "Europe/Berlin" }
      )
    );
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  getClaims(): any {
    return this.user.profile;
  }

  getAuthorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  startAuthentication(): Promise<void> {
    return this.manager.signinRedirect();
  }

  completeAuthentication(): Promise<void> {
    return this.manager.signinRedirectCallback().then(
      (user) => {
        this.user = user;

        this.companyService.get().subscribe((result) => {
          var lang = result.language;
          this.translate.use(lang);
        });

        this.getAppUser();
      },
      (err) => {
        alert("You are Redirecting ...");
        console.log("Error caught in signinRedirectCallback().");
        console.error(err);
      }
    );
  }

  completeLogout(): Promise<any> {
    this.storageService.remove("applicationUser");
    this.storageService.remove("isApprover");

    this.storageService.remove("companyPackage");
    this.storageService.remove("companyDecimalFormat");
    this.storageService.remove("companyNumberFormat");
    this.storageService.remove("companyUserCount");
    this.storageService.remove("companyLanguage");
    this.storageService.remove("companyCurrency");
    this.storageService.remove("companyName");

    this.storageService.remove("pricePlanningValue");
    this.storageService.remove("forecastPlanningValue");
    this.storageService.remove("costPlanningValue");
    this.storageService.remove("centralCostPlanningValue");
    this.storageService.remove("priceApprovalValue");
    this.storageService.remove("forecastApprovalValue");
    this.storageService.remove("costApprovalValue");
    this.storageService.remove("centralCostApprovalValue");
    this.storageService.remove("riskOppValue");
    this.storageService.remove("salesNarrativesValue");
    this.storageService.remove("costNarrativesValue");

    return this.manager.signoutRedirect();
  }

  /* Application User Start */
  getAppUser(): void {
    if (this.applicationUser == null) {
      this.accountsService.getUser().subscribe((response) => {
        this.applicationUser = response;

        this.storageService.set("applicationUser", JSON.stringify(response));

        console.log("Application User Information :");
        console.log(this.applicationUser);

        this.accountsService
          .isApprover(this.applicationUser.userId)
          .subscribe((result) => {
            this.applicationUser.isApprover = result;
            this.storageService.set("isApprover", JSON.stringify(result));
          });

        /* company */
        this.companyService.get().subscribe((result) => {
          this.storageService.set("companyPackage", result.package);
          this.storageService.set("companyDecimalFormat", result.decimalFormat);
          this.storageService.set("companyNumberFormat", result.numberFormat);
          this.storageService.set("companyUserCount", result.userCount);
          this.storageService.set("companyLanguage", result.language);
          this.storageService.set("companyCurrency", result.currency);
          this.storageService.set("companyName", result.name);
        });

        /* timeline tasks start and end dates */
        this.timelineService.listProcessesTime().subscribe((result) => {
          result.forEach((element) => {
            if (element.task == "PRICE_PLANNING") {
              let data = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
              this.storageService.set(
                "pricePlanningValue",
                JSON.stringify(data)
              );
            }

            if (element.task == "FORECAST_PLANNING") {
              let data = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
              this.storageService.set(
                "forecastPlanningValue",
                JSON.stringify(data)
              );
            }

            if (element.task == "COST_PLANNING") {
              let data = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
              this.storageService.set(
                "costPlanningValue",
                JSON.stringify(data)
              );
            }

            if (element.task == "CENTRAL_COST_PLANNING") {
              let data = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
              this.storageService.set(
                "centralCostPlanningValue",
                JSON.stringify(data)
              );
            }

            if (element.task == "PRICE_APPROVAL") {
              let data = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
              this.storageService.set(
                "priceApprovalValue",
                JSON.stringify(data)
              );
            }

            if (element.task == "FORECAST_APPROVAL") {
              let data = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
              this.storageService.set(
                "forecastApprovalValue",
                JSON.stringify(data)
              );
            }

            if (element.task == "COST_APPROVAL") {
              let data = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
              this.storageService.set(
                "costApprovalValue",
                JSON.stringify(data)
              );
            }

            if (element.task == "CENTRAL_COST_APPROVAL") {
              let data = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
              this.storageService.set(
                "centralCostApprovalValue",
                JSON.stringify(data)
              );
            }

            if (element.task == "RISK_OPPORTUNITIES") {
              let data = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
              this.storageService.set("riskOppValue", JSON.stringify(data));
            }

            if (element.task == "SALES_NARRATIVES") {
              let data = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
              this.storageService.set(
                "salesNarrativesValue",
                JSON.stringify(data)
              );
            }

            if (element.task == "COST_NARRATIVES") {
              let data = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
              this.storageService.set(
                "costNarrativesValue",
                JSON.stringify(data)
              );
            }
          });
        });
      });
    }
  }

  getRoles(): any {
    let data = JSON.parse(this.storageService.get("applicationUser"));
    return data.roles;
  }

  /* is user approver */
  isApprover(): boolean {
    let data = this.storageService.get("isApprover");
    return data == "true";
  }

  /* roles */
  isAdmin(): boolean {
    let data = JSON.parse(this.storageService.get("applicationUser"));
    return data.roles.includes("Admin");
  }

  isPricePlanner(): boolean {
    let data = JSON.parse(this.storageService.get("applicationUser"));
    return data.roles.includes("Price Planner");
  }

  isCentralCostPlanner(): boolean {
    let data = JSON.parse(this.storageService.get("applicationUser"));
    return data.roles.includes("Central Cost Planner");
  }

  isBusinessUnitPlanner(): boolean {
    let data = JSON.parse(this.storageService.get("applicationUser"));
    return data.roles.includes("Sales Planner");
  }

  isBusinessUnitApprover(): boolean {
    let data = JSON.parse(this.storageService.get("applicationUser"));
    return data.roles.includes("Sales Approver");
  }

  isSupportFunctionPlanner(): boolean {
    let data = JSON.parse(this.storageService.get("applicationUser"));
    return data.roles.includes("Cost Planner");
  }

  isSupportFunctionApprover(): boolean {
    let data = JSON.parse(this.storageService.get("applicationUser"));
    return data.roles.includes("Cost Approver");
  }

  isSuperAdmin(): boolean {
    let data = JSON.parse(this.storageService.get("applicationUser"));
    return data.roles.includes("Super Admin");
  }
  /* Application User End */

  /* Timeline tasks Start */
  pricePlanning(): boolean {
    let pricePlanningValue = JSON.parse(
      this.storageService.get("pricePlanningValue")
    );
    if (pricePlanningValue == null) return false;
    let start = new Date(
      (typeof pricePlanningValue.startDate === "string"
        ? new Date(pricePlanningValue.startDate)
        : pricePlanningValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof pricePlanningValue.endDate === "string"
        ? new Date(pricePlanningValue.endDate)
        : pricePlanningValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  forecastPlanning(): boolean {
    let forecastPlanningValue = JSON.parse(
      this.storageService.get("forecastPlanningValue")
    );
    if (forecastPlanningValue == null) return false;
    let start = new Date(
      (typeof forecastPlanningValue.startDate === "string"
        ? new Date(forecastPlanningValue.startDate)
        : forecastPlanningValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof forecastPlanningValue.endDate === "string"
        ? new Date(forecastPlanningValue.endDate)
        : forecastPlanningValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  costPlanning(): boolean {
    let costPlanningValue = JSON.parse(
      this.storageService.get("costPlanningValue")
    );
    if (costPlanningValue == null) return false;
    let start = new Date(
      (typeof costPlanningValue.startDate === "string"
        ? new Date(costPlanningValue.startDate)
        : costPlanningValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof costPlanningValue.endDate === "string"
        ? new Date(costPlanningValue.endDate)
        : costPlanningValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  centralCostPlanning(): boolean {
    let centralCostPlanningValue = JSON.parse(
      this.storageService.get("centralCostPlanningValue")
    );
    if (centralCostPlanningValue == null) return false;
    let start = new Date(
      (typeof centralCostPlanningValue.startDate === "string"
        ? new Date(centralCostPlanningValue.startDate)
        : centralCostPlanningValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof centralCostPlanningValue.endDate === "string"
        ? new Date(centralCostPlanningValue.endDate)
        : centralCostPlanningValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  priceApproval(): boolean {
    let priceApprovalValue = JSON.parse(
      this.storageService.get("priceApprovalValue")
    );
    if (priceApprovalValue == null) return false;
    let start = new Date(
      (typeof priceApprovalValue.startDate === "string"
        ? new Date(priceApprovalValue.startDate)
        : priceApprovalValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof priceApprovalValue.endDate === "string"
        ? new Date(priceApprovalValue.endDate)
        : priceApprovalValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  forecastApproval(): boolean {
    let forecastApprovalValue = JSON.parse(
      this.storageService.get("forecastApprovalValue")
    );
    if (forecastApprovalValue == null) return false;
    let start = new Date(
      (typeof forecastApprovalValue.startDate === "string"
        ? new Date(forecastApprovalValue.startDate)
        : forecastApprovalValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof forecastApprovalValue.endDate === "string"
        ? new Date(forecastApprovalValue.endDate)
        : forecastApprovalValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  costApproval(): boolean {
    let costApprovalValue = JSON.parse(
      this.storageService.get("costApprovalValue")
    );
    if (costApprovalValue == null) return false;
    let start = new Date(
      (typeof costApprovalValue.startDate === "string"
        ? new Date(costApprovalValue.startDate)
        : costApprovalValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof costApprovalValue.endDate === "string"
        ? new Date(costApprovalValue.endDate)
        : costApprovalValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  centralCostApproval(): boolean {
    let centralCostApprovalValue = JSON.parse(
      this.storageService.get("centralCostApprovalValue")
    );
    if (centralCostApprovalValue == null) return false;
    let start = new Date(
      (typeof centralCostApprovalValue.startDate === "string"
        ? new Date(centralCostApprovalValue.startDate)
        : centralCostApprovalValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof centralCostApprovalValue.endDate === "string"
        ? new Date(centralCostApprovalValue.endDate)
        : centralCostApprovalValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  riskOpp(): boolean {
    let riskOppValue = JSON.parse(this.storageService.get("riskOppValue"));
    if (riskOppValue == null) return false;
    let start = new Date(
      (typeof riskOppValue.startDate === "string"
        ? new Date(riskOppValue.startDate)
        : riskOppValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof riskOppValue.endDate === "string"
        ? new Date(riskOppValue.endDate)
        : riskOppValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  salesNarratives(): boolean {
    let salesNarrativesValue = JSON.parse(
      this.storageService.get("salesNarrativesValue")
    );
    if (salesNarrativesValue == null) return false;
    let start = new Date(
      (typeof salesNarrativesValue.startDate === "string"
        ? new Date(salesNarrativesValue.startDate)
        : salesNarrativesValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof salesNarrativesValue.endDate === "string"
        ? new Date(salesNarrativesValue.endDate)
        : salesNarrativesValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  costNarratives(): boolean {
    let costNarrativesValue = JSON.parse(
      this.storageService.get("costNarrativesValue")
    );
    if (costNarrativesValue == null) return false;
    let start = new Date(
      (typeof costNarrativesValue.startDate === "string"
        ? new Date(costNarrativesValue.startDate)
        : costNarrativesValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof costNarrativesValue.endDate === "string"
        ? new Date(costNarrativesValue.endDate)
        : costNarrativesValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }
  /* Timeline tasks End */

  /* Company Info */
  getNumberFormat(): string {
    return this.storageService.get("companyNumberFormat");
  }

  getDecimalFormat(): string {
    return this.storageService.get("companyDecimalFormat");
  }

  getCompanyPackage(): string {
    return this.storageService.get("companyPackage");
  }

  getCompanyUserCount(): number {
    return Number(this.storageService.get("companyUserCount"));
  }

  getCompanyLanguage(): string {
    return this.storageService.get("companyLanguage");
  }

  getCompanyCurrency(): string {
    return EnumCurrency[`${this.storageService.get("companyCurrency")}`];
  }

  getCompanyName(): string {
    return this.storageService.get("companyName");
  }
  /* Company Info Ends */
}
