import { Component, Inject, OnInit } from "@angular/core";
import { Users } from "../../../../@core/entities/user";
import { Department } from "../../../../@core/entities/department";
import { SubDepartment } from "../../../../@core/entities/subDepartment";
import { Products } from "../../../../@core/entities/product";
import { ProductsService } from "../../../../@core/backend/service/products.service";
import { AccountsService } from "../../../../@core/backend/service/accounts.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Role } from "@planard/@core/entities/role";
import { RoleService } from "@planard/@core/backend/service/role.service";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import { ProductFamilyService } from "@planard/@core/backend/service/product-family.service";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { CostType } from "@planard/@core/entities/costType";
import { ProductFamily } from "@planard/@core/entities/productFamily";
import { BusinessUnit } from "@planard/@core/entities/businessUnit";
import { MatSelect } from "@angular/material/select";
import { TranslateService } from "@ngx-translate/core";
@Component({
  templateUrl: "./users-update.component.html",
})
export class UsersUpdateComponent implements OnInit {
  roles: any;
  central: boolean;
  product: boolean;

  centralCostList: CostType[];
  costTypeList: CostType[];

  user: Users;
  departmentList: Department[];
  subdepartmentList: SubDepartment[];
  productList: Products[];
  roleList: Role[];
  department: Department;
  subdeptDepList: SubDepartment[] = [];
  productFamilyList: ProductFamily[];
  productFamilyBuList: ProductFamily[] = [];
  productFamList: Products[] = [];
  businessUnitList: BusinessUnit[];
  productFamily: ProductFamily[];
  businessUnit: BusinessUnit[];
  allProducts: boolean;
  allBu: boolean;
  allPf: boolean;
  companyPackage: string;

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email]),
    departmentId: new FormControl("", [Validators.required]),
    subDepartmentId: new FormControl(""),
    businessUnitId: new FormControl(""),
    productFamilyId: new FormControl(""),
    selectedProducts: new FormControl(""),
    selectedRoles: new FormControl("", [Validators.required]),
    selectedCentralCosts: new FormControl(""),
  });
  options: FormGroup;

  constructor(
    private productsService: ProductsService,
    private toastrService: ToastrService,
    private businessUnitService: BusinessUnitService,
    private productFamilyService: ProductFamilyService,
    private costtypeService: CostService,
    private fb: FormBuilder,
    public translate: TranslateService,
    private accountsService: AccountsService,
    private roleService: RoleService,
    public dialogRef: MatDialogRef<UsersUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user = data.user;
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.accountsService.listDepartments().subscribe((resultList) => {
      this.departmentList = resultList;
    });
    this.accountsService.listSubDepartments().subscribe((resultList) => {
      this.subdepartmentList = resultList;
    });
    this.productsService.listAll().subscribe((resultList) => {
      this.productList = resultList;
    });
    this.roleService.list().subscribe((resultList) => {
      this.roleList = [];
      resultList.forEach((element) => {
        if (element.name != "Super Admin") {
          this.roleList.push(element);
        }
      });
    });
    this.productFamilyService.listAll().subscribe((result: any) => {
      this.productFamilyList = result;
    });
    this.businessUnitService.listAll().subscribe((result: any) => {
      this.businessUnitList = result;
    });
    this.costtypeService.listAll().subscribe((result: any) => {
      this.costTypeList = result;
      this.centralCostList = [...result];
      this.setCentral();
    });
  }

  save() {
    if (this.form.valid) {
      this.user.name = this.form.get("name").value;
      this.user.email = this.form.get("email").value;
      this.user.departmentId = this.form.get("departmentId").value;
      this.user.subDepartmentId = this.form.get("subDepartmentId").value;
      this.user.selectedProducts = this.form.get("selectedProducts").value;
      this.user.selectedRoles = this.form.get("selectedRoles").value;
      this.user.selectedCentralCosts = [];
      this.user.approverUserId = null;

      if (this.getSupportFunction(this.user.selectedRoles)) {
        if (this.user.subDepartmentId == null || !this.user.subDepartmentId) {
          this.translate.get(`users.spf`).subscribe((data: any) => {
            this.toastrService.error(data);
          });
          return;
        }
      }

      this.accountsService.updateUser(this.user).subscribe((result) => {
        if (result.isSuccess) {
          this.translate.get(`users.contactChanged`).subscribe((data: any) => {
            this.toastrService.success(data);
          });
          this.dialogRef.close();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.dialogRef.close();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancelClick() {
    this.dialogRef.close(false);
  }

  subdeptSet() {
    this.department = this.form.get("departmentId").value;
    this.subdeptDepList.length = 0;
    for (let dp of Object.keys(this.subdepartmentList)) {
      if (this.department == this.subdepartmentList[dp].departmentId) {
        this.subdeptDepList.push(this.subdepartmentList[dp]);
      }
    }
  }

  ProductFamilySet() {
    this.businessUnit = this.form.get("businessUnitId").value;
    this.productFamilyBuList.length = 0;
    for (let bu of this.businessUnit) {
      for (let pf of Object.keys(this.productFamilyList)) {
        if (bu.id === this.productFamilyList[pf].businessUnitId) {
          this.productFamilyBuList.push(this.productFamilyList[pf]);
        }
      }
    }
  }

  ProductSet() {
    this.productFamily = this.form.get("productFamilyId").value;
    this.productFamList.length = 0;
    for (let pfl of this.productFamily) {
      for (let pf of Object.keys(this.productList)) {
        if (pfl.id === this.productList[pf].productFamilyId) {
          this.productFamList.push(this.productList[pf]);
        }
        this.form.patchValue({ product: this.productFamList });
      }
    }
  }

  roleSet() {
    this.central = false;
    this.roles = this.form.get("selectedRoles").value;
    for (let rl of this.roles) {
      for (let role of this.roleList) {
        if (rl === role.id && role.name == "Central Cost Planner") {
          this.central = true;
        }
      }
    }
  }

  roleSetProduct() {
    this.product = false;
    this.roles = this.form.get("selectedRoles").value;
    for (let rl of this.roles) {
      for (let role of this.roleList) {
        if (
          rl === role.id &&
          (role.name == "Sales Planner" ||
            role.name == "Sales Approver" ||
            role.name == "Price Planner")
        ) {
          this.product = true;
        }
      }
    }
  }

  selectAll(select: MatSelect) {
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    this.form.controls["selectedProducts"].setValue(selectedIds);
    this.allProducts = true;
  }

  selectOne(id: number) {
    if (this.allProducts == true) {
      this.allProducts = false;
      let selectedId = [id];
      this.form.controls["selectedProducts"].setValue(selectedId);
    }
  }

  deselectOne(id: number, select: MatSelect) {
    if (this.allProducts == true) {
      this.allProducts = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && id != element.value.id) {
          selectedIds.push(element.value);
        }
      });
      this.form.controls["selectedProducts"].setValue(selectedIds);
    }
  }

  deselectAll() {
    this.allProducts = false;
    let selectedIds = [];
    this.form.controls["selectedProducts"].setValue(selectedIds);
  }

  selectAllBu(select: MatSelect) {
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    this.form.controls["businessUnitId"].setValue(selectedIds);
    this.allBu = true;
    this.ProductFamilySet();
  }

  selectOneBu(bu: BusinessUnit) {
    // kullanma.
    if (this.allBu == true) {
      this.allBu = false;
      let selected = [bu];
      this.form.controls["businessUnitId"].setValue(selected);
      this.ProductFamilySet();
    }
  }

  deselectOneBu(bu: BusinessUnit, select: MatSelect) {
    if (this.allBu == true) {
      this.allBu = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && bu.id != element.value.id) {
          selectedIds.push(element.value);
        }
      });
      this.form.controls["businessUnitId"].setValue(selectedIds);
      this.ProductFamilySet();
    }
  }

  deselectAllBu() {
    this.allBu = false;
    let selectedIds = [];
    this.form.controls["businessUnitId"].setValue(selectedIds);
    this.ProductFamilySet();
  }

  selectAllPf(select: MatSelect) {
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    this.form.controls["productFamilyId"].setValue(selectedIds);
    this.allPf = true;
    this.ProductSet();
  }

  selectOnePf(pf: ProductFamily) {
    if (this.allPf == true) {
      this.allPf = false;
      let selected = [pf];
      this.form.controls["productFamilyId"].setValue(selected);
      this.ProductSet();
    }
  }

  deselectOnePf(pf: ProductFamily, select: MatSelect) {
    if (this.allPf == true) {
      this.allPf = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && pf.id != element.value.id) {
          selectedIds.push(element.value);
        }
      });
      this.form.controls["productFamilyId"].setValue(selectedIds);
      this.ProductSet();
    }
  }

  deselectAllPf() {
    this.allPf = false;
    let selectedIds = [];
    this.form.controls["productFamilyId"].setValue(selectedIds);
    this.ProductSet();
  }

  setCentral() {
    this.centralCostList.length = 0;
    for (let ct of Object.keys(this.costTypeList)) {
      if (this.costTypeList[ct].costType === "CENTRAL") {
        this.centralCostList.push(this.costTypeList[ct]);
      }
    }
  }

  getSupportFunction(roles: any) {
    for (let rl of roles) {
      for (let role of this.roleList) {
        if (
          rl === role.id &&
          (role.name == "Cost Planner" || role.name == "Cost Approver")
        ) {
          return true;
        }
      }
    }
  }
}
