<h1 mat-dialog-title>{{ "economicReportsAdd.title" | translate }}</h1>

<form [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        tabindex="1"
        formControlName="description"
        matInput
        name="description"
        placeholder="{{ 'economicReportsAdd.description' | translate }}"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="2"
        formControlName="type"
        name="type"
        [(ngModel)]="type"
        placeholder="{{ 'economicReports.type' | translate }}"
      >
        <mat-option [value]="0" value="option">{{
          "economicReports.ecoT" | translate
        }}</mat-option>
        <mat-option [value]="1" value="option">{{
          "economicReports.macroT" | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="3"
        formControlName="country"
        name="country"
        placeholder="{{ 'economicReports.country' | translate }}"
      >
        <mat-option
          *ngFor="let country of country_list"
          [value]="country.name"
          value="option"
        >
          <span
            class="flag-icon flag-icon-{{
              country.code | lowercase
            }} flag-icon-squared"
            style="font-size: 25px"
          ></span>
          {{ country.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="type == 0">
      <input
        style="display: none"
        type="file"
        (change)="upload($event)"
        #hiddenfileinput
        accept="application/pdf"
      />
      <button
        (click)="hiddenfileinput.click()"
        color="accent"
        mat-raised-button
      >
        <mat-icon>cloud_download</mat-icon>&nbsp;{{
          "economicReportsAdd.select" | translate
        }}
        (.pdf)&nbsp;&nbsp;</button
      >&nbsp;&nbsp;{{ fileName }}
    </div>

    <div *ngIf="type == 1">
      <input
        style="display: none"
        type="file"
        (change)="upload($event)"
        #hiddenfileinput
        accept="image/png"
      />
      <button
        (click)="hiddenfileinput.click()"
        color="accent"
        mat-raised-button
      >
        <mat-icon>cloud_download</mat-icon>&nbsp;{{
          "economicReportsAdd.select" | translate
        }}
        (.png)&nbsp;&nbsp;</button
      >&nbsp;&nbsp;{{ fileName }}
    </div>

    <div *ngIf="type == null">
      <button color="accent" mat-raised-button disabled>
        <mat-icon>cloud_download</mat-icon>&nbsp;{{
          "economicReportsAdd.select" | translate
        }}
        (.-)&nbsp;&nbsp;
      </button>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button tabindex="4" (click)="save()">
      {{ "economicReportsAdd.add" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="5" type="button">
      {{ "economicReportsAdd.cancel" | translate }}
    </button>
  </div>
</form>
