import { EventEmitter, Injectable } from "@angular/core";
import { Role } from "@planard/@core/entities/role";
import { RoleApi } from "@planard/@core/backend/api/role.api";
import { RoleData } from "@planard/@core/entities/interface/role";

@Injectable()
export class RoleService extends RoleData {
  onRoleReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: RoleApi) {
    super();
  }

  list() {
    return this.api.list<Role[]>();
  }
}
