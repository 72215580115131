<h1 mat-dialog-title>{{ "guidance.target.add" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <div>
      <mat-form-field>
        <mat-select
          tabindex="1"
          placeholder="{{ 'guidance.target.year' | translate }}"
          name="year"
          formControlName="year"
        >
          <mat-option *ngFor="let o of yearList" [value]="o" value="option">
            {{ o }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <input
          matInput
          tabindex="2"
          type="number"
          placeholder="{{ 'guidance.target.salesTarget' | translate }}"
          name="salestarget"
          formControlName="salestarget"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <input
          matInput
          tabindex="3"
          type="number"
          placeholder="{{ 'guidance.target.costTarget' | translate }}"
          name="costtarget"
          formControlName="costtarget"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-select
          tabindex="4"
          formControlName="fxRateId"
          name="fxRateId"
          placeholder="{{ 'guidance.target.currency' | translate }}"
        >
          <mat-option
            *ngFor="let o of fxRateList"
            [value]="o.id"
            value="option"
          >
            {{ o.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button tabindex="5" type="submit">
      {{ "guidance.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="6" type="button">
      {{ "guidance.cancel" | translate }}
    </button>
  </div>
</form>
