import { EventEmitter, Injectable } from "@angular/core";
import { OtherForecastData } from "@planard/@core/entities/interface/otherForecast";
import { OtherForecast } from "@planard/@core/entities/otherForecast";
import { OtherForecastApi } from "../api/otherForecast.api";

@Injectable()
export class OtherForecastService extends OtherForecastData {
  onForecastReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: OtherForecastApi) {
    super();
  }

  listPrices(scenarioId: number, approveRequestId?: number) {
    return this.api.listPrices<any>(scenarioId, approveRequestId);
  }

  update(otherForecast: OtherForecast) {
    return this.api.update<any>(otherForecast);
  }
}
