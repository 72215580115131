import { EnumRegistry } from "./EnumRegistry";

export abstract class AbstractEnum {
  constructor() {}

  _name: string;

  get name() {
    return this._name;
  }

  set name(_name: string) {
    this._name = _name;
  }

  static getValues(enumClass: any): any[] {
    EnumRegistry[enumClass.name] = enumClass;
    return Object.keys(enumClass)
      .filter((key) => enumClass[key] instanceof AbstractEnum)
      .map((key) => {
        enumClass[key].name = key;
        return enumClass[key];
      });
  }

  toJSON(): string {
    return this.name;
  }

  toString(): string {
    return this.name;
  }
}
