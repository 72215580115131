import { Observable } from "rxjs";
import { Forecast } from "@planard/@core/entities/forecast";
import { Channel } from "../channel";
import { BusinessUnit } from "../businessUnit";
import { ProductFamily } from "../productFamily";
import { Products } from "../product";

export abstract class ForecastData {
  abstract listPrices(
    scenarioId: number,
    approveRequestId?: number
  ): Observable<any>;

  abstract growthDrivers(
    count: number,
    scenarioId: number,
    granularity: number,
    startActuals: number,
    startPeriods: number,
    endActuals: number,
    endPeriods: number,
    indicator: number,
    currency: number
  ): Observable<any>;

  abstract salesTrend(
    scenarioId: number,
    granularity: number,
    parentId: any,
    subId: number,
    indicator: number,
    timescale: number,
    actuals: number,
    period: number,
    currency: number
  ): Observable<any>;

  abstract update(forecast: Forecast): Observable<any>;

  abstract listForecastBusinessUnits(
    scenarioId: any
  ): Observable<BusinessUnit[]>;

  abstract listForecastChannels(scenarioId: any): Observable<Channel[]>;

  abstract listForecastProductFamilies(
    scenarioId: any
  ): Observable<ProductFamily[]>;

  abstract listForecastProducts(scenarioId: any): Observable<Products[]>;

  abstract listProductFamiliesByBusinessUnit(
    scenarioId: any,
    businessUnitId: number
  ): Observable<BusinessUnit[]>;

  abstract listProductsByProductFamily(
    scenarioId: any,
    productFamilyId: number
  ): Observable<Channel[]>;

  abstract listFormsByProduct(
    scenarioId: any,
    productId: number
  ): Observable<ProductFamily[]>;

  abstract listBusinessUnitsByChannel(
    scenarioId: any,
    channelId: number
  ): Observable<Products[]>;
}
