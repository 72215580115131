import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Users } from "@planard/@core/entities/user";
import { Department } from "@planard/@core/entities/department";
import { SubDepartment } from "@planard/@core/entities/subDepartment";
import { Products } from "@planard/@core/entities/product";
import { AccountsService } from "@planard/@core/backend/service/accounts.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Role } from "@planard/@core/entities/role";
import { RoleService } from "@planard/@core/backend/service/role.service";
import { BusinessUnit } from "@planard/@core/entities/businessUnit";
import { ProductFamilyService } from "@planard/@core/backend/service/product-family.service";
import { ProductFamily } from "@planard/@core/entities/productFamily";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import { CostType } from "@planard/@core/entities/costType";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { MatSelect } from "@angular/material/select";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";
@Component({
  templateUrl: "./users-add.component.html",
  styles: [
    `
      ::ng-deep .mat-option:first-child .mat-pseudo-checkbox {
        display: none;
      }
    `,
  ],
})
export class UsersAddComponent implements OnInit {
  roles: any;
  central: boolean;
  product: boolean;

  centralCostList: CostType[];
  costTypeList: CostType[];

  user: Users;
  departmentList: Department[];
  subdepartmentList: SubDepartment[];
  productList: Products[];
  roleList: Role[];
  department: Department;
  subdeptDepList: SubDepartment[] = [];
  productFamilyList: ProductFamily[];
  productFamilyBuList: ProductFamily[] = [];
  productFamList: Products[] = [];
  businessUnitList: BusinessUnit[];
  productFamily: ProductFamily[];
  businessUnit: BusinessUnit[];
  allProducts: boolean;
  allBu: boolean;
  allPf: boolean;
  companyPackage: string;

  basicRoles: string[] = [
    "Admin",
    "Price Planner",
    "Sales Planner",
    "Cost Planner",
  ];

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, removeSpaces]),
    email: new FormControl("", [
      Validators.required,
      Validators.email,
      removeSpaces,
    ]),
    departmentId: new FormControl("", [Validators.required]),
    subDepartmentId: new FormControl(""),
    businessUnitId: new FormControl(""),
    productFamilyId: new FormControl(""),
    selectedProducts: new FormControl(""),
    selectedRoles: new FormControl("", [Validators.required]),
    selectedCentralCosts: new FormControl(""),
  });
  options: FormGroup;

  constructor(
    public productsService: ProductsService,
    public toastrService: ToastrService,
    public fb: FormBuilder,
    private businessUnitService: BusinessUnitService,
    private productFamilyService: ProductFamilyService,
    private costtypeService: CostService,
    public accountsService: AccountsService,
    private roleService: RoleService,
    public authService: AuthService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<UsersAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Users
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
    this.companyPackage = this.authService.getCompanyPackage();
  }

  ngOnInit() {
    this.accountsService.listDepartments().subscribe((resultList) => {
      this.departmentList = resultList;
    });
    this.accountsService.listSubDepartments().subscribe((resultList) => {
      this.subdepartmentList = resultList;
    });
    this.productsService.listAll().subscribe((resultList) => {
      this.productList = resultList;
    });
    this.roleService.list().subscribe((resultList) => {
      this.roleList = [];
      resultList.forEach((element) => {
        if (element.name != "Super Admin") {
          if (this.companyPackage == "premium") {
            this.roleList.push(element);
          } else if (this.basicRoles.includes(element.name)) {
            this.roleList.push(element);
          }
        }
      });
    });
    this.productFamilyService.listAll().subscribe((result: any) => {
      this.productFamilyList = result;
    });
    this.businessUnitService.listAll().subscribe((result: any) => {
      this.businessUnitList = result;
    });
    this.costtypeService.listAll().subscribe((result: any) => {
      this.costTypeList = result;
      this.centralCostList = [...result];
      this.setCentral();
    });
    this.form.patchValue({ selectedProducts: [] });
    this.form.patchValue({ selectedCentralCosts: [] });
    this.form.patchValue({ productFamilyId: [] });
    this.form.patchValue({ businessUnitId: [] });
  }

  //product
  selectAll(select: MatSelect) {
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    this.form.controls["selectedProducts"].setValue(selectedIds);
    this.allProducts = true;
  }

  selectOne(id: number) {
    if (this.allProducts == true) {
      this.allProducts = false;
      let selectedId = [id];
      this.form.controls["selectedProducts"].setValue(selectedId);
    }
  }

  deselectOne(id: number, select: MatSelect) {
    if (this.allProducts == true) {
      this.allProducts = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && id != element.value.id) {
          selectedIds.push(element.value);
        }
      });
      this.form.controls["selectedProducts"].setValue(selectedIds);
    }
  }

  deselectAll() {
    this.allProducts = false;
    let selectedIds = [];
    this.form.controls["selectedProducts"].setValue(selectedIds);
  }

  //bu
  selectAllBu(select: MatSelect) {
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    this.form.controls["businessUnitId"].setValue(selectedIds);
    this.allBu = true;
    this.ProductFamilySet();
  }

  selectOneBu(bu: BusinessUnit) {
    // kullanma.
    if (this.allBu == true) {
      this.allBu = false;
      let selected = [bu];
      this.form.controls["businessUnitId"].setValue(selected);
      this.ProductFamilySet();
    }
  }

  deselectOneBu(bu: BusinessUnit, select: MatSelect) {
    if (this.allBu == true) {
      this.allBu = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && bu.id != element.value.id) {
          selectedIds.push(element.value);
        }
      });
      this.form.controls["businessUnitId"].setValue(selectedIds);
      this.ProductFamilySet();
    }
  }

  deselectAllBu() {
    this.allBu = false;
    let selectedIds = [];
    this.form.controls["businessUnitId"].setValue(selectedIds);
    this.ProductFamilySet();
  }

  //pf
  selectAllPf(select: MatSelect) {
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    this.form.controls["productFamilyId"].setValue(selectedIds);
    this.allPf = true;
    this.ProductSet();
  }

  selectOnePf(pf: ProductFamily) {
    if (this.allPf == true) {
      this.allPf = false;
      let selected = [pf];
      this.form.controls["productFamilyId"].setValue(selected);
      this.ProductSet();
    }
  }

  deselectOnePf(pf: ProductFamily, select: MatSelect) {
    if (this.allPf == true) {
      this.allPf = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && pf.id != element.value.id) {
          selectedIds.push(element.value);
        }
      });
      this.form.controls["productFamilyId"].setValue(selectedIds);
      this.ProductSet();
    }
  }

  deselectAllPf() {
    this.allPf = false;
    let selectedIds = [];
    this.form.controls["productFamilyId"].setValue(selectedIds);
    this.ProductSet();
  }

  setCentral() {
    this.centralCostList.length = 0;
    for (let ct of Object.keys(this.costTypeList)) {
      if (this.costTypeList[ct].costType === "CENTRAL") {
        this.centralCostList.push(this.costTypeList[ct]);
      }
    }
  }

  save() {
    if (this.companyPackage == "basic") {
      this.form.controls["departmentId"].setValue(this.departmentList[0].id);
      this.form.controls["subDepartmentId"].setValue(
        this.subdepartmentList[0].id
      );
    }

    if (this.form.valid) {
      this.user = this.form.value;

      if (this.getSupportFunction(this.user.selectedRoles)) {
        if (!this.user.subDepartmentId) {
          this.translate.get(`users.spf`).subscribe((data: any) => {
            this.toastrService.error(data);
          });
          return;
        }
      }

      var isSalesPlannerSelected = false;
      this.roleList.forEach((r) => {
        if (
          r.name == "Sales Planner" &&
          this.user.selectedRoles.includes(r.id)
        ) {
          isSalesPlannerSelected = true;
        }
      });

      if (isSalesPlannerSelected && !this.user.selectedProducts[0]) {
        this.translate.get(`users.sp`).subscribe((data: any) => {
          this.toastrService.error(data);
        });
        return;
      }

      this.accountsService.add(this.user).subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.accountsService.onUsersReload.emit();
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.cancelClick();
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancelClick() {
    this.dialogRef.close();
  }

  subdeptSet() {
    this.department = this.form.get("departmentId").value;
    this.subdeptDepList.length = 0;
    for (let dp of Object.keys(this.subdepartmentList)) {
      if (this.department == this.subdepartmentList[dp].departmentId) {
        this.subdeptDepList.push(this.subdepartmentList[dp]);
      }
    }
  }

  ProductFamilySet() {
    this.businessUnit = this.form.get("businessUnitId").value;
    this.productFamilyBuList.length = 0;
    for (let bu of this.businessUnit) {
      for (let pf of Object.keys(this.productFamilyList)) {
        if (bu.id === this.productFamilyList[pf].businessUnitId) {
          this.productFamilyBuList.push(this.productFamilyList[pf]);
        }
      }
    }
  }

  ProductSet() {
    this.productFamily = this.form.get("productFamilyId").value;
    this.productFamList.length = 0;
    for (let pfl of this.productFamily) {
      for (let pf of Object.keys(this.productList)) {
        if (pfl.id === this.productList[pf].productFamilyId) {
          this.productFamList.push(this.productList[pf]);
        }
        this.form.patchValue({ product: this.productFamList });
      }
    }
  }

  roleSet() {
    this.central = false;
    this.roles = this.form.get("selectedRoles").value;
    for (let rl of this.roles) {
      for (let role of this.roleList) {
        if (rl === role.id && role.name == "Central Cost Planner") {
          this.central = true;
        }
      }
    }
  }

  roleSetProduct() {
    this.product = false;
    this.roles = this.form.get("selectedRoles").value;
    for (let rl of this.roles) {
      for (let role of this.roleList) {
        if (
          rl === role.id &&
          (role.name == "Sales Planner" ||
            role.name == "Sales Approver" ||
            role.name == "Price Planner" ||
            role.name == "Admin")
        ) {
          this.product = true;
        }
      }
    }
  }

  getSupportFunction(roles: any) {
    for (let rl of roles) {
      for (let role of this.roleList) {
        if (
          rl === role.id &&
          (role.name == "Cost Planner" || role.name == "Cost Approver")
        ) {
          return true;
        }
      }
    }
  }
}
