import { NgModule } from "@angular/core";
import { PipesModule } from "@planard/@core/pipes/pipes.module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CommonModule, DatePipe } from "@angular/common";
import { DemoMaterialModule } from "@planard/common/demo-material-module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { CompanyApi } from "@planard/@core/backend/api/company.api";
import { CompanySetupService } from "@planard/@core/backend/service/company-setup.service";
import { CompanySetupComponent } from "@planard/admin/super/companies/company-setup.component";
import { CompanySetupAddComponent } from "@planard/admin/super/companies/add/company-setup-add.component";
import { CompanySetupUpdateComponent } from "@planard/admin/super/companies/update/company-setup-update.component";
import { AdminSuperRoutes } from "@planard/admin/super/admin-super.routing";
import { MenuItems } from "@planard/shared/menu-items/menu-items";
import { MapleModule } from "@planard/maple/maple.module";
import { SettingsComponent } from "./settings/settings.component";
import { SettingsAddComponent } from "./settings/add/settings-add.component";
import { SupersettingsService } from "@planard/@core/backend/service/supersettings.service";
import { SupersettingsApi } from "@planard/@core/backend/api/supersettings.api";
import { TranslateModule } from "@ngx-translate/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from "@angular/material-moment-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_DATE_LOCALE } from "@angular/material/core";

const API = [HttpService, CompanyApi, SupersettingsApi];

const SERVICES = [CompanySetupService, SupersettingsService];

@NgModule({
  imports: [
    PipesModule,
    MapleModule,
    NgxDatatableModule,
    CommonModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    TranslateModule,
    RouterModule.forChild(AdminSuperRoutes),
  ],
  declarations: [
    CompanySetupComponent,
    CompanySetupAddComponent,
    CompanySetupUpdateComponent,
    SettingsComponent,
    SettingsAddComponent,
  ],
  providers: [
    MenuItems,
    DatePipe,
    ...API,
    ...SERVICES,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class AdminSuperModule {}
