import { Component } from "@angular/core";

@Component({
  selector: "loading",
  template: ` <div
    id="pause"
    class="d-flex align-items-center justify-content-center"
  >
    <div id="spinner"></div>
  </div>`,
  styles: [
    `
      #spinner {
        -webkit-animation: frames 1s infinite linear;
        animation: frames 1s infinite linear;
        background: transparent;
        border: 1vw solid #fff;
        border-radius: 100%;
        border-top-color: #223297;
        width: 5vw;
        height: 5vw;
        opacity: 0.6;
        padding: 0;
        top: 45%;
        left: 45%;
        position: fixed;
        z-index: 999;
      }
      @keyframes frames {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(359deg);
          transform: rotate(359deg);
        }
      }

      #pause {
        display: block;
        background: rgba(0, 0, 0, 0.66) no-repeat 0 0;
        width: 100%;
        height: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1000;
      }
    `,
  ],
})
export class LoadingComponent {}
