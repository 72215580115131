<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 55%">
            <mat-card-title>{{
              "costNarrative.title" | translate
            }}</mat-card-title>
          </div>

          <div class="div" style="width: 20%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="requestId"
                placeholder="{{ 'costNarrative.narrativeRequest' | translate }}"
              >
                <mat-option
                  *ngFor="let cost of costRequestList"
                  [value]="cost.id"
                  value="option"
                >
                  <strong>{{ cost.firstPlanningCycleName }}</strong
                  >&nbsp;{{ cost.firstYear }}
                  &nbsp;&nbsp;&nbsp;vs&nbsp;&nbsp;&nbsp;
                  <strong>{{ cost.secondPlanningCycleName }}</strong
                  >&nbsp;{{ cost.secondYear }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="div" style="width: 20%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="categoryId"
                placeholder="{{ 'costNarrative.costCategory' | translate }}"
              >
                <mat-option
                  *ngFor="let o of costCategoryList"
                  [value]="o.id"
                  value="option"
                >
                  <span *ngIf="'category.' + o.name as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="div" style="width: 5%">
            <div style="width: 85%">
              <button color="accent" mat-button (click)="getTableData()">
                {{ "costNarrative.run" | translate }}
              </button>
            </div>
          </div>
        </div>

        <div style="width: 45%" *ngIf="selected == true">
          <mat-card style="background: transparent">
            <mat-card-content>
              <span style="font-size: smaller"
                >{{ "costNarrative.amountToExplain" | translate }} :
                {{ formatNumber(remaining, decimalFormat, numberFormat) }}
                TL</span
              ><br />
              <!--<span style="font-size: smaller;">{{ 'costNarrative.amountExplainedRequest' | translate }} : {{formatNumber(genelRem, decimalFormat, numberFormat)}} TL</span><br>-->
              <span style="font-size: smaller"
                >{{ "costNarrative.amountExplainedProduct" | translate }} :
                {{ formatNumber(dataRemaining, decimalFormat, numberFormat) }}
                TL</span
              >
            </mat-card-content>
          </mat-card>
        </div>

        <div class="responsive-table">
          <mat-table
            [dataSource]="dataSource"
            matSort
            *ngIf="isSubmit == false && isScreenValid == true"
          >
            <ng-container matColumnDef="amount">
              <mat-header-cell *matHeaderCellDef mat-sort-header="amount">
                {{ "costNarrative.amount" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'amount')">
                  <ng-template viewMode>
                    {{
                      formatNumber(element.amount, decimalFormat, numberFormat)
                    }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [formControl]="getControl(element.id, 'amount')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="narrativeCategory">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="narrativeCategory"
              >
                {{ "costNarrative.narrativeCategory" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable
                  (update)="updateField(element.id, 'narrativeCategory')"
                >
                  <ng-template viewMode>
                    <span
                      *ngIf="
                        'costNarrativeCategories.' +
                        element.narrativeCategory as variable
                      "
                      >{{ variable | translate }}</span
                    >
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <mat-select
                        [formControl]="
                          getControl(element.id, 'narrativeCategory')
                        "
                      >
                        <mat-option *ngFor="let o of categoryList" [value]="o">
                          <span
                            *ngIf="'costNarrativeCategories.' + o as variable"
                            >{{ variable | translate }}</span
                          >
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header="description">
                {{ "costNarrative.description" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'description')">
                  <ng-template viewMode>
                    {{ element.description }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [formControl]="getControl(element.id, 'description')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
                {{ "costNarrative.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
                <button
                  (click)="onDelete(row)"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-table
            [dataSource]="dataSource"
            matSort
            *ngIf="isSubmit == true || isScreenValid == false"
          >
            <ng-container matColumnDef="amount">
              <mat-header-cell *matHeaderCellDef mat-sort-header="amount">
                {{ "costNarrative.amount" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ formatNumber(element.amount, decimalFormat, numberFormat) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="narrativeCategory">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="narrativeCategory"
              >
                {{ "costNarrative.narrativeCategory" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span
                  *ngIf="
                    'costNarrativeCategories.' +
                    element.narrativeCategory as variable
                  "
                  >{{ variable | translate }}</span
                >
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header="description">
                {{ "costNarrative.description" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.description }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
                {{ "costNarrative.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
                <button
                  *ngIf="isSubmit == true && isScreenValid == true"
                  (click)="
                    toastrService.warning(
                      'This Narrative Request is already Submitted.'
                    )
                  "
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>

                <button
                  *ngIf="isScreenValid == false"
                  (click)="
                    toastrService.warning('Sales Narrative is out of timeline.')
                  "
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <table *ngIf="selected == false" style="min-height: 48px">
            <tr>
              <td
                style="text-align: center"
                [attr.colspan]="displayedColumns.length"
              >
                {{ "costNarrative.must" | translate }}
              </td>
            </tr>
          </table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>

        <mat-card-actions>
          <button
            *ngIf="isSubmit == false && isScreenValid == true"
            (click)="addDialog()"
            color="accent"
            mat-raised-button
          >
            {{ "costNarrative.add" | translate }}
          </button>
          <button
            *ngIf="isSubmit == false && isScreenValid == true"
            (click)="submit()"
            color="accent"
            mat-raised-button
          >
            {{ "costNarrative.submit" | translate }}
          </button>

          <button
            *ngIf="isSubmit == true && isScreenValid == true"
            (click)="outToastr('salesNarrative.submitted')"
            color="accent"
            mat-raised-button
          >
            {{ "costNarrative.add" | translate }}
          </button>
          <button
            *ngIf="isSubmit == true && isScreenValid == true"
            (click)="outToastr('salesNarrative.submitted')"
            color="accent"
            mat-raised-button
          >
            {{ "costNarrative.submit" | translate }}
          </button>

          <button
            *ngIf="isScreenValid == false"
            (click)="outToastr('entryScreens.costNarrativeTask')"
            color="accent"
            mat-raised-button
          >
            {{ "costNarrative.add" | translate }}
          </button>
          <button
            *ngIf="isScreenValid == false"
            (click)="outToastr('entryScreens.costNarrativeTask')"
            color="accent"
            mat-raised-button
          >
            {{ "costNarrative.submit" | translate }}
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
