<h1 mat-dialog-title>{{ "companiesAdd.title" | translate }}</h1>

<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        tabindex="1"
        formControlName="name"
        matInput
        name="name"
        placeholder="{{ 'companiesAdd.name' | translate }}"
      />
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="2"
        formControlName="companyCode"
        matInput
        name="companyCode"
        placeholder="{{ 'companiesAdd.code' | translate }}"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="3"
        formControlName="sector"
        name="sector"
        placeholder="{{ 'companiesAdd.sector' | translate }}"
      >
        <mat-option *ngFor="let o of sectorList" [value]="o" value="option">
          <span *ngIf="'enums.sector.' + o as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="4"
        type="email"
        formControlName="contactEmail"
        matInput
        name="contactEmail"
        placeholder="{{ 'companiesAdd.adminEmail' | translate }}"
      />
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="5"
        formControlName="contactName"
        matInput
        name="contactName"
        placeholder="{{ 'companiesAdd.adminName' | translate }}"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="6"
        (selectionChange)="setUser()"
        [(ngModel)]="package"
        formControlName="package"
        name="package"
        placeholder="{{ 'companies.package' | translate }}"
      >
        <mat-option [value]="'premium'" value="option">{{
          "companySetup.premium" | translate
        }}</mat-option>
        <mat-option [value]="'basic'" value="option">{{
          "companySetup.basic" | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="7"
        formControlName="language"
        name="language"
        placeholder="{{ 'companySetup.language' | translate }}"
      >
        <mat-option [value]="'tr'" value="option">{{
          "companySetup.tr" | translate
        }}</mat-option>
        <mat-option [value]="'en'" value="option">{{
          "companySetup.en" | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="package == 'premium'">
      <input
        tabindex="8"
        [(ngModel)]="userCount"
        formControlName="userCount"
        type="number"
        matInput
        name="userCount"
        placeholder="{{ 'companies.userCount' | translate }}"
      />
    </mat-form-field>

    <div mat-dialog-actions>
      <button mat-button tabindex="9" type="submit">
        {{ "companiesAdd.save" | translate }}
      </button>
      <button (click)="cancel()" mat-button tabindex="10" type="button">
        {{ "companiesAdd.cancel" | translate }}
      </button>
    </div>
  </div>
</form>
