<h1 mat-dialog-title>Add Approver</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <mat-select
        tabindex="1"
        formControlName="approverId"
        name="approverId"
        placeholder="Approver"
      >
        <mat-option
          *ngFor="let o of approverList"
          [value]="o.id"
          value="option"
        >
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="2" type="submit">Save</button>
    <button (click)="cancelClick()" mat-button tabindex="3" type="button">
      Cancel
    </button>
  </div>
</form>
