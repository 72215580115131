import { EventEmitter, Injectable } from "@angular/core";
import { ActualsData } from "@planard/@core/entities/interface/actuals";
import { ActualsApi } from "@planard/@core/backend/api/actuals.api";
import { Actuals } from "@planard/@core/entities/actuals";

@Injectable()
export class ActualsService extends ActualsData {
  onReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ActualsApi) {
    super();
  }

  listAllFiles() {
    return this.api.listAllFiles<Actuals[]>();
  }

  listFilePeriod(type: any) {
    return this.api.listFilePeriod<any>(type);
  }

  uploadPrice(data: any) {
    return this.api.uploadPrice<any>(data);
  }

  uploadForecast(data: any) {
    return this.api.uploadForecast<any>(data);
  }

  uploadOtherForecast(data: any) {
    return this.api.uploadOtherForecast<any>(data);
  }

  uploadCost(data: any) {
    return this.api.uploadCost<any>(data);
  }

  uploadCentralCost(data: any) {
    return this.api.uploadCentralCost<any>(data);
  }

  delete(fileId: number) {
    return this.api.delete<any>(fileId);
  }
}
