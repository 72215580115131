import { EventEmitter, Injectable } from "@angular/core";
import { Company } from "../../entities/company";
import { CompanyApi } from "../api/company.api";
import { CompanyData } from "../../entities/interface/company";
import { BusinessUnit } from "../../entities/businessUnit";
import { Observable } from "rxjs";

@Injectable()
export class CompanySetupService extends CompanyData {
  onCompanySetupReload: EventEmitter<any> = new EventEmitter<any>();
  onReload: EventEmitter<any> = new EventEmitter<any>();
  constructor(private api: CompanyApi) {
    super();
  }

  get() {
    return this.api.get<Company>();
  }

  list() {
    return this.api.listAll<Company[]>();
  }

  add(company: Company) {
    return this.api.add<any>(company);
  }

  update(company: Company) {
    return this.api.update<any>(company);
  }

  updatePackage(body: any) {
    return this.api.updatePackage<any>(body);
  }

  listAllBusinessUnit(): Observable<BusinessUnit[]> {
    return this.api.listAllBU();
  }

  delete(companyId: number) {
    return this.api.delete<any>(companyId);
  }
}
