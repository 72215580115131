import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { EnumTask } from "@planard/@core/enums/EnumTask";
import { Process } from "@planard/@core/entities/process";
import { Role } from "@planard/@core/entities/role";
import { TimelineService } from "@planard/@core/backend/service/timeline.service";
import { RoleService } from "@planard/@core/backend/service/role.service";
import { TimelineAddComponent } from "./add/timeline-add.component";
import { MatSelect } from "@angular/material/select";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { Guidance } from "@planard/@core/entities/guidance";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./timeline-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 7%;
      }
      .toRightM {
        flex: 0 0 8%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
  //::ng-deep .mat-option:first-child .mat-pseudo-checkbox{ display: none; }
})
export class TimelineNewComponent implements OnInit {
  displayedColumns: string[] = [
    "sequence",
    "task",
    "selectedProcessRoles",
    "startDate",
    "endDate",
    "dependentProcessId",
    "daysToExecute",
    "actions",
  ];
  dataSource: MatTableDataSource<Process>;
  controls: any[];

  taskList: EnumTask[] = EnumTask.values;
  dependencyList: Process[];
  dependencyListVal: Process[] = [];
  roleList: Role[];
  isStartValid: boolean;
  sequenceList: any[] = [];
  allRoles: boolean;
  guidance: Guidance;
  guidanceStart: boolean;
  today;
  isRoleValid: boolean;
  companyPackage: string;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public roleService: RoleService,
    public guidanceService: GuidanceService,
    public timelineService: TimelineService,
    //private dateAdapter: DateAdapter<Date>,
    public authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    public toastrService: ToastrService
  ) {
    this.isRoleValid = this.authService.isAdmin();
    this.companyPackage = this.authService.getCompanyPackage();
    this.displayedColumns =
      this.companyPackage == "premium"
        ? [
            "sequence",
            "task",
            "selectedProcessRoles",
            "startDate",
            "endDate",
            "dependentProcessId",
            "daysToExecute",
            "actions",
          ]
        : ["sequence", "task", "startDate", "dependentProcessId"];
    if (this.isRoleValid == false) this.router.navigate(["auth/404"]);

    translate.onLangChange.subscribe((lang) => {
      //roller tekrar yüklensin diye
      this.ngOnInit();
    });

    timelineService.getCurrentTime().subscribe((result) => {
      let date = result;
      this.today = new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          { timeZone: "Europe/Berlin" }
        )
      );
    });
  }

  ngOnInit() {
    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidance = result;
      if (this.guidance.start != null) {
        this.guidanceStart = true;
      } else {
        this.guidanceStart = false;
      }
    });
    this.roleService.list().subscribe((resultList) => {
      this.roleList = [];
      resultList.forEach((element) => {
        if (element.name != "Super Admin") {
          this.roleList.push(element);
        }
      });
    });
    this.timelineService.listAll().subscribe((data) => {
      this.sequenceList = [];
      var Arr = data.sort(
        (a, b) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      ); //start date e göre önce olandan sonraya göre
      var nbr = 0;
      Arr.forEach((element) => {
        nbr = nbr + 1;
        let ele = { sequence: nbr, id: element.id };
        this.sequenceList.push(ele);
      });

      this.dataSource = new MatTableDataSource(data);
      this.dependencyList = data;

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "startDate", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              task: new FormControl(EnumTask[`${entity.task}`]),
              customTask: new FormControl(entity.customTask),
              selectedProcessRoles: new FormControl(
                entity.selectedProcessRoles,
                [Validators.required]
              ),
              startDate: new FormControl(entity.startDate, [
                Validators.required,
              ]),
              endDate: new FormControl(entity.endDate, [Validators.required]),
              dependentProcessId: new FormControl(entity.dependentProcessId),
              daysToExecute: new FormControl(entity.daysToExecute, [
                Validators.required,
                Validators.min(0),
              ]),
            },
            { updateOn: "change" }
          ),
        };
      });
    });
  }

  selectAll(index, select: MatSelect) {
    var control = this.getControl(index, "selectedProcessRoles");
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    control.setValue(selectedIds);
    this.allRoles = true;
  }

  isAll(roles: any) {
    if (roles.length == this.roleList.length) {
      return true;
    } else {
      return false;
    }
  }

  selectOne(index, id: number) {
    if (this.allRoles == true) {
      var control = this.getControl(index, "selectedProcessRoles");
      this.allRoles = false;
      let selectedId = [id];
      control.setValue(selectedId);
    }
  }

  deselectAll(index) {
    this.allRoles = false;
    var control = this.getControl(index, "selectedProcessRoles");
    let selectedId = [];
    control.setValue(selectedId);
  }

  deselectOne(id: number, select: MatSelect) {
    var control = this.getControl(id, "selectedProcessRoles");
    if (this.isAll(control) == true) {
      this.allRoles = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && id != element.value.id) {
          selectedIds.push(element.value);
        }
      });
      control.setValue(selectedIds);
    }
  }

  getSequence(id: number) {
    for (let index in this.sequenceList) {
      if (id == this.sequenceList[index].id) {
        return this.sequenceList[index].sequence;
      }
    }
  }

  setDaysByStart(index) {
    var control = this.getControl(index, "daysToExecute");
    var process = this.getElement(index);

    var endD: any = new Date(process.endDate);
    var startD: any = new Date(process.start);
    var days: any = Math.floor((endD - startD) / (1000 * 60 * 60 * 24));
    process.daysToExecute = days;
    control.setValue(days);
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    var process = this.getElement(index);

    if (
      this.guidanceStart == true &&
      new Date(process.startDate) < new Date()
    ) {
      this.ngOnInit();
      this.translate.get("timeline.msg1").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    }

    process[field] = control.value;

    if (new Date(process.endDate) < new Date(process.startDate)) {
      this.ngOnInit();
      this.translate.get("timeline.msg2").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }

    if (control.valid) {
      if (field == "daysToExecute") {
        var days = process.daysToExecute;
        var startDate = new Date(process.startDate);
        var end_date = new Date().setDate(startDate.getDate() + days);
        process.endDate = new Date(end_date);
      } else if (field == "endDate" || field == "startDate") {
        var endD: any = new Date(process.endDate);
        var startD: any = new Date(process.startDate);
        var days: any = Math.round(
          /*floor*/ (endD - startD) / (1000 * 60 * 60 * 24)
        );
        process.daysToExecute = days;
      }

      this.setStart(process.startDate, process.dependentProcessId, process.id);

      if (this.isStartValid == true) {
        this.timelineService.update(process).subscribe((result) => {
          if (result.isSuccess) {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.success(data);
              });
            this.ngOnInit();
            this.isStartValid = false;
          } else {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.error(data);
              });
            this.ngOnInit();
          }
        });
      } else {
        this.translate.get("timeline.msg3").subscribe((data: any) => {
          this.toastrService.error(data);
        });
        this.ngOnInit();
      }
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      this.ngOnInit();
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    const dialogRef = this.dialog.open(TimelineAddComponent, {
      width: "500px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataSource.data.push(result);
        this.ngOnInit();
        if (this.guidanceStart) {
          this.translate.get(`guidance.msg2`).subscribe((data: any) => {
            this.toastrService.warning(data);
          });
        }
      }
      this.ngOnInit();
    });
  }

  onDelete(row: any) {
    this.translate.get("delete.process").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          let valid;

          this.dependencyList.forEach((element) => {
            if (row.id == element.dependentProcessId) {
              valid = false;
              this.translate.get(`timeline.msg4`).subscribe((data: any) => {
                this.toastrService.error(data);
              });
              return;
            }
          });

          setTimeout(() => {
            if (valid != false) {
              this.timelineService.delete(row.id).subscribe((result) => {
                if (result.isSuccess) {
                  this.translate
                    .get(`messages.${result.messageId}`)
                    .subscribe((data: any) => {
                      this.toastrService.success(data);
                    });
                  this.ngOnInit();
                } else {
                  this.translate
                    .get(`messages.${result.messageId}`)
                    .subscribe((data: any) => {
                      this.toastrService.error(data);
                    });
                  this.ngOnInit();
                }
              });
            }
          }, 1000);
        }
      });
    });
  }

  getDepent(id: any) {
    if (id != null) {
      for (let dp of Object.keys(this.dependencyList)) {
        if (this.dependencyList[dp].id === id) {
          if (this.dependencyList[dp].customTask == null) {
            return this.dependencyList[dp].task;
          } else {
            return this.dependencyList[dp].customTask;
          }
        }
      }
    }
  }

  getDependencyList(id: any) {
    if (id != null) {
      this.dependencyListVal.length = 0;
      for (let dp of Object.keys(this.dependencyList)) {
        if (this.dependencyList[dp].id < id) {
          this.dependencyListVal.push(this.dependencyList[dp]);
        }
      }
      return this.dependencyListVal;
    }
  }

  getDaysToExecute(startDate, endDate) {
    var startDay: any = new Date(startDate);
    var endDay: any = new Date(endDate);
    var diffDays: any = Math.floor((endDay - startDay) / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  setStart(start: any, depentId: any, id: any) {
    if (depentId == null) {
      this.isStartValid = true;
    } else {
      for (let tk of Object.keys(this.dependencyList)) {
        if (
          depentId == this.dependencyList[tk].id &&
          start > new Date(this.dependencyList[tk].endDate)
        ) {
          this.isStartValid = true;
        }
        // else if ( this.dependencyList[tk].id == id ){
        //   this.isStartValid = true;
        // }
      }
    }
  }

  getRoles(roles) {
    var roleArr = roles.split(", ");
    var newRoles = [];
    roleArr.forEach((element) => {
      this.translate.get(`processRoles.${element}`).subscribe((data: any) => {
        newRoles.push(data);
      });
    });
    return newRoles.join(", ");
  }

  outToastr(msg: string) {
    this.translate.get(`${msg}`).subscribe((data: any) => {
      this.toastrService.error(data);
    });
  }
}
