<mat-progress-spinner
  *ngIf="loadingIndicator2"
  mode="indeterminate"
  style="margin: 0 auto"
  [diameter]="40"
></mat-progress-spinner>

<div fxLayout="row">
  <mat-card fxFlex="100">
    <mat-card-content style="height: 75px">
      <div class="card-body">
        <div fxLayout="row">
          <div fxFlex="40">
            <mat-card-title style="font-size: x-large">{{
              "guidance.title" | translate
            }}</mat-card-title>
          </div>
          <div fxFlex="50">
            <div *ngIf="this.package == 'premium'">
              <mat-form-field style="width: 30%">
                <mat-select
                  (selectionChange)="copyGuidance()"
                  [(ngModel)]="copyGuidanceId"
                  placeholder="{{ 'guidance.copy' | translate }}"
                >
                  <mat-option
                    *ngFor="let guidance of guidanceList"
                    [value]="guidance.id"
                    value="option"
                  >
                    {{ guidance.planningCycleName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="this.package == 'premium'" fxFlex="10">
            <button
              *ngIf="!guidance.start"
              (click)="startCycle()"
              color="primary"
              mat-button
              style="background: white; margin-right: 10px"
              type="button"
            >
              {{ "guidance.startCycle" | translate }}
            </button>
            <button
              *ngIf="guidance.start"
              (click)="endCycle()"
              color="primary"
              mat-button
              style="background: white; margin-right: 10px"
              type="button"
            >
              {{ "guidance.endCycle" | translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div fxLayout="row">
  <!-- PLANNING PERIOD -->
  <mat-card fxFlex="40">
    <mat-card-content style="height: 275px">
      <div class="card-body">
        <div fxLayout="row">
          <div fxFlex="7" style="vertical-align: middle">
            <mat-icon>insert_invitation</mat-icon>
          </div>
          <div fxFlex="73">
            <mat-card-title style="font-size: 100%">{{
              "guidance.planningPeriod.title" | translate
            }}</mat-card-title>
          </div>
          <div fxFlex="20">
            <button
              *ngIf="edit == false"
              (click)="outToastr('guidance.msg1')"
              style="color: gray"
              mat-button
            >
              {{ "guidance.edit" | translate }}<mat-icon>edit</mat-icon>
            </button>
            <button
              *ngIf="edit == true"
              (click)="planningDialog()"
              style="color: gray"
              mat-button
            >
              {{ "guidance.edit" | translate }}<mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
        <br />
        <table style="width: 100%; font-size: smaller">
          <tr>
            <td style="width: 20%">
              <strong>{{ "guidance.planningPeriod.year" | translate }}</strong>
            </td>
            <td style="width: 25%">
              <strong>{{
                "guidance.planningPeriod.planningType" | translate
              }}</strong>
            </td>
            <td style="width: 25%">
              <strong>{{
                "guidance.planningPeriod.startMonth" | translate
              }}</strong>
            </td>
          </tr>
          <tr *ngFor="let period of guidancePlanningPeriodList">
            <td style="width: 20%">{{ period.year }}</td>
            <td style="width: 25%">
              <span *ngIf="'guidance.' + period.planningType as variable">{{
                variable | translate
              }}</span>
            </td>
            <td style="width: 25%">
              <span *ngIf="'monthsNumber.' + period.startMonth as variable">{{
                period.startMonth != 0 ? (variable | translate) : "-"
              }}</span>
            </td>
          </tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- GRANULARITY -->
  <mat-card *ngIf="this.package == 'premium'" fxFlex="30">
    <mat-card-content style="height: 275px">
      <div class="card-body">
        <div fxLayout="row">
          <div fxFlex="8" style="vertical-align: middle">
            <mat-icon>slow_motion_video</mat-icon>
          </div>
          <div fxFlex="67">
            <mat-card-title style="font-size: 100%">{{
              "guidance.granularity.title" | translate
            }}</mat-card-title>
          </div>
          <div fxFlex="25">
            <button
              *ngIf="edit == false"
              (click)="outToastr('guidance.msg1')"
              style="color: gray"
              mat-button
            >
              {{ "guidance.edit" | translate }}<mat-icon>edit</mat-icon>
            </button>
            <button
              *ngIf="edit == true"
              (click)="granularityAddDialog()"
              style="color: gray"
              mat-button
            >
              {{ "guidance.edit" | translate }}<mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
        <br />
        <ul style="font-size: smaller">
          <li *ngIf="guidance.granularityType != null">
            <span class="badge" *ngIf="guidance.granularityType == 0">{{
              "guidance.granularity.product" | translate
            }}</span>
            <span class="badge" *ngIf="guidance.granularityType == 1"
              >{{ "guidance.granularity.product" | translate
              }}<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<mat-icon
                >subdirectory_arrow_right</mat-icon
              >{{ "guidance.granularity.channel" | translate }}</span
            >
            <span class="badge" *ngIf="guidance.granularityType == 2"
              >{{ "guidance.granularity.product" | translate
              }}<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<mat-icon
                >subdirectory_arrow_right</mat-icon
              >{{ "guidance.granularity.form" | translate }}</span
            >
            <span class="badge" *ngIf="guidance.granularityType == 3">
              {{ "guidance.granularity.product" | translate }}
              <br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-icon>subdirectory_arrow_right</mat-icon
              >{{ "guidance.granularity.form" | translate }}
              <br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-icon>subdirectory_arrow_right</mat-icon
              >{{ "guidance.granularity.channel" | translate }}</span
            >
          </li>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>
  <!-- FX RATE -->
  <mat-card fxFlex="30">
    <mat-card-content style="height: 275px">
      <div class="card-body">
        <div fxLayout="row">
          <div fxFlex="10" style="vertical-align: middle">
            <mat-icon>euro_symbol</mat-icon>
          </div>
          <div fxFlex="65">
            <mat-card-title style="font-size: 100%">{{
              "guidance.fxrate.title" | translate
            }}</mat-card-title>
          </div>
          <div fxFlex="25">
            <!-- <button *ngIf="edit==false" (click)="toastrService.error('Guidance has been Started, You Cannot Use This Option!');" style="color: gray;" mat-button>Edit<mat-icon>edit</mat-icon></button>-->
            <button (click)="fxAddDialog()" style="color: gray" mat-button>
              {{ "guidance.edit" | translate }}<mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
        <br />
        <table style="width: 100%; font-size: smaller">
          <tr>
            <td style="width: 20%">
              <strong>{{ "guidance.fxrate.fx" | translate }}</strong>
            </td>
            <td style="width: 10%">
              <strong>{{ "guidance.fxrate.rate" | translate }}</strong>
            </td>
            <td style="width: 50%"></td>
          </tr>
          <tr *ngFor="let fxrate of guidanceFxRateList">
            <td style="width: 20%">
              {{
                fxrate.fxRateId == 1
                  ? "USD"
                  : fxrate.fxRateId == 2
                  ? "EUR"
                  : fxrate.fxRateId == 5
                  ? "GBP"
                  : fxrate.fxRateId == 3
                  ? "TRY"
                  : fxrate.fxRateId == 4
                  ? "JPY"
                  : fxrate.fxRateId == 6
                  ? "AUD"
                  : fxrate.fxRateId == 7
                  ? "CAD"
                  : fxrate.fxRateId == 8
                  ? "CHF"
                  : fxrate.fxRateId == 9
                  ? "CNY"
                  : fxrate.fxRateId == 10
                  ? "HKD"
                  : fxrate.fxRateId == 11
                  ? "NZD"
                  : fxrate.fxRateId == 12
                  ? "SEK"
                  : fxrate.fxRateId == 13
                  ? "KRW"
                  : fxrate.fxRateId == 14
                  ? "SGD"
                  : fxrate.fxRateId == 15
                  ? "NOK"
                  : fxrate.fxRateId == 16
                  ? "MXN"
                  : fxrate.fxRateId == 17
                  ? "INR"
                  : fxrate.fxRateId == 18
                  ? "RUB"
                  : fxrate.fxRateId == 19
                  ? "ZAR"
                  : fxrate.fxRateId == 20
                  ? "BRL"
                  : fxrate.fxRateId == 21
                  ? "AED"
                  : fxrate.fxRateId == 22
                  ? "ILS"
                  : fxrate.fxRateId == 23
                  ? "PLN"
                  : fxrate.fxRateId == 24
                  ? "SAR"
                  : "-"
              }}
            </td>
            <td style="width: 10%">
              {{ formatNumber(fxrate.rate, decimalFormat, numberFormat) }}
            </td>
            <td style="width: 50%">
              {{
                fxrate.fxRateId == 1
                  ? "USD"
                  : fxrate.fxRateId == 2
                  ? "EUR"
                  : fxrate.fxRateId == 5
                  ? "GBP"
                  : fxrate.fxRateId == 3
                  ? "TRY"
                  : fxrate.fxRateId == 4
                  ? "JPY"
                  : fxrate.fxRateId == 6
                  ? "AUD"
                  : fxrate.fxRateId == 7
                  ? "CAD"
                  : fxrate.fxRateId == 8
                  ? "CHF"
                  : fxrate.fxRateId == 9
                  ? "CNY"
                  : fxrate.fxRateId == 10
                  ? "HKD"
                  : fxrate.fxRateId == 11
                  ? "NZD"
                  : fxrate.fxRateId == 12
                  ? "SEK"
                  : fxrate.fxRateId == 13
                  ? "KRW"
                  : fxrate.fxRateId == 14
                  ? "SGD"
                  : fxrate.fxRateId == 15
                  ? "NOK"
                  : fxrate.fxRateId == 16
                  ? "MXN"
                  : fxrate.fxRateId == 17
                  ? "INR"
                  : fxrate.fxRateId == 18
                  ? "RUB"
                  : fxrate.fxRateId == 19
                  ? "ZAR"
                  : fxrate.fxRateId == 20
                  ? "BRL"
                  : fxrate.fxRateId == 21
                  ? "AED"
                  : fxrate.fxRateId == 22
                  ? "ILS"
                  : fxrate.fxRateId == 23
                  ? "PLN"
                  : fxrate.fxRateId == 24
                  ? "SAR"
                  : "-"
              }}
              / {{ currency }}
            </td>
          </tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div fxLayout="row">
  <!-- TARGET -->
  <mat-card fxFlex="{{ this.fxflexvalue }}">
    <mat-card-content style="height: 275px">
      <div class="card-body">
        <div id="main-div">
          <div class="div" style="vertical-align: middle; width: 3%">
            <mat-icon style="size: 50px">flag</mat-icon>
          </div>
          <div class="div" style="width: 85%">
            <mat-card-title style="font-size: 100%">{{
              "guidance.target.title" | translate
            }}</mat-card-title>
          </div>
          <div class="div" style="width: 12%">
            <!--<button *ngIf="edit==false" (click)="toastrService.error('Guidance has been Started, You Cannot Use This Option!');" style="color: gray;" mat-button>Edit<mat-icon>edit</mat-icon></button>-->
            <button (click)="targetDialog()" style="color: gray" mat-button>
              {{ "guidance.edit" | translate }}<mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
        <br />
        <table style="width: 100%; font-size: smaller">
          <tr>
            <td style="width: 20%">
              <strong>{{ "guidance.target.year" | translate }}</strong>
            </td>
            <td style="width: 25%">
              <strong>{{ "guidance.target.salesTarget" | translate }}</strong>
            </td>
            <td style="width: 25%">
              <strong>{{ "guidance.target.costTarget" | translate }}</strong>
            </td>
            <td style="width: 30%">
              <strong>{{ "guidance.target.currency" | translate }}</strong>
            </td>
          </tr>
          <tr *ngFor="let target of guidanceTargetList">
            <td style="width: 20%">{{ target.year }}</td>
            <td style="width: 25%">
              <!--{{ target.salesTarget | number: '1.3-6' }}-->
              {{
                formatNumber(target.salesTarget, decimalFormat, numberFormat)
              }}
            </td>
            <td style="width: 25%">
              <!--{{ target.costTarget | number: '1.3-6' }}-->
              {{ formatNumber(target.costTarget, decimalFormat, numberFormat) }}
            </td>
            <td style="width: 30%">
              {{
                target.fxRateId == 1
                  ? "USD"
                  : target.fxRateId == 2
                  ? "EUR"
                  : target.fxRateId == 5
                  ? "GBP"
                  : target.fxRateId == 3
                  ? "TRY"
                  : target.fxRateId == 4
                  ? "JPY"
                  : target.fxRateId == 6
                  ? "AUD"
                  : target.fxRateId == 7
                  ? "CAD"
                  : target.fxRateId == 8
                  ? "CHF"
                  : target.fxRateId == 9
                  ? "CNY"
                  : target.fxRateId == 10
                  ? "HKD"
                  : target.fxRateId == 11
                  ? "NZD"
                  : target.fxRateId == 12
                  ? "SEK"
                  : target.fxRateId == 13
                  ? "KRW"
                  : target.fxRateId == 14
                  ? "SGD"
                  : target.fxRateId == 15
                  ? "NOK"
                  : target.fxRateId == 16
                  ? "MXN"
                  : target.fxRateId == 17
                  ? "INR"
                  : target.fxRateId == 18
                  ? "RUB"
                  : target.fxRateId == 19
                  ? "ZAR"
                  : target.fxRateId == 20
                  ? "BRL"
                  : target.fxRateId == 21
                  ? "AED"
                  : target.fxRateId == 22
                  ? "ILS"
                  : target.fxRateId == 23
                  ? "PLN"
                  : target.fxRateId == 24
                  ? "SAR"
                  : "-"
              }}
            </td>
          </tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- INFLATION RATE -->
  <mat-card fxFlex="30">
    <mat-card-content style="height: 275px">
      <div class="card-body">
        <div id="main-div">
          <div class="div" style="vertical-align: middle; width: 10%">
            <mat-icon>trending_up</mat-icon>
          </div>
          <div class="div" style="width: 65%">
            <mat-card-title style="font-size: 100%">{{
              "guidance.inflationRate.title" | translate
            }}</mat-card-title>
          </div>
          <div class="div" style="width: 25%">
            <!--<button *ngIf="edit==false" (click)="toastrService.error('Guidance has been Started, You Cannot Use This Option!');" style="color: gray;"  mat-button>Edit<mat-icon>edit</mat-icon></button>-->
            <button
              (click)="inflationrateAddDialog()"
              style="color: gray"
              mat-button
            >
              {{ "guidance.edit" | translate }}<mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
        <br />
        <!--<ul style="font-size: smaller;">
                <li *ngFor="let infrate of guidanceInflationRateList">
                  <div id="main-div">
                  <div class="div" style="width: 45%;">Inflation&nbsp;Rate&nbsp;{{infrate.year}}</div>
                  <div class="div" style="width: 5%;">:</div>
                  <div class="div" style="width: 25%;">%&nbsp;{{infrate.rate}}</div>
                 </div>
                </li>
              </ul>-->
        <table style="width: 100%; font-size: smaller">
          <tr>
            <td style="width: 20%">
              <strong>{{ "guidance.inflationRate.year" | translate }}</strong>
            </td>
            <td style="width: 50%">
              <strong>{{
                "guidance.inflationRate.infrate" | translate
              }}</strong>
            </td>
          </tr>
          <tr *ngFor="let infrate of guidanceInflationRateList">
            <td style="width: 20%">{{ infrate.year }}</td>
            <td style="width: 50%">
              <!--{{ infrate.rate | number: '1.2-2' }}-->{{
                formatNumber(infrate.rate, decimalFormat, numberFormat)
              }}%&nbsp;
            </td>
          </tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div fxLayout="row">
  <!-- KEY CONTACTS -->
  <mat-card *ngIf="this.package == 'premium'" fxFlex="65.7">
    <mat-card-content style="height: 275px">
      <div class="card-body">
        <div fxLayout="row">
          <div fxFlex="3" style="vertical-align: middle">
            <mat-icon>portrait</mat-icon>
          </div>
          <div fxFlex="85">
            <mat-card-title style="font-size: 100%">{{
              "guidance.keyContacts.title" | translate
            }}</mat-card-title>
          </div>
          <div fxFlex="12">
            <!--<button *ngIf="edit==false" (click)="toastrService.error('Guidance has been Started, You Cannot Use This Option!');" style="color: gray;"  mat-button>Edit<mat-icon>edit</mat-icon></button>-->
            <button
              (click)="keycontactsAddDialog()"
              style="color: gray"
              mat-button
            >
              {{ "guidance.edit" | translate }}<mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
        <br />
        <table style="width: 100%; font-size: smaller">
          <tr>
            <td style="width: 23%">
              <strong>{{
                "guidance.keyContacts.department" | translate
              }}</strong>
            </td>
            <td style="width: 23%">
              <strong>{{ "guidance.keyContacts.name" | translate }}</strong>
            </td>
            <td style="width: 23%">
              <strong>{{ "guidance.keyContacts.phone" | translate }}</strong>
            </td>
            <td style="width: 31%">
              <strong>{{ "guidance.keyContacts.email" | translate }}</strong>
            </td>
          </tr>
          <tr *ngFor="let keyContact of guidanceKeyContactList">
            <td style="width: 23%">{{ keyContact.department }}</td>
            <td style="width: 23%">{{ keyContact.name }}</td>
            <td style="width: 23%">{{ keyContact.phone }}</td>
            <td style="width: 31%">
              <a
                style="color: black"
                title="click to send email"
                href="mailto:{{ keyContact.email }}"
                target="_blank"
              >
                <div id="main-div">
                  <div
                    class="div"
                    style="vertical-align: middle; color: steelblue"
                  >
                    <mat-icon [inline]="true">email</mat-icon>
                  </div>
                  &nbsp;
                  <div class="div">{{ keyContact.email }}</div>
                </div>
              </a>
            </td>
          </tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- DEADLINES, PLANNING CYCLE NAME -->
  <div *ngIf="this.package == 'premium'" fxFlex="32">
    <mat-card>
      <mat-card-content style="height: 140px">
        <div class="card-body">
          <div fxLayout="row">
            <div fxFlex="10" style="vertical-align: middle">
              <mat-icon>add_alarm</mat-icon>
            </div>
            <div fxFlex="65">
              <mat-card-title style="font-size: 100%">{{
                "guidance.deadlines.title" | translate
              }}</mat-card-title>
            </div>
            <div fxFlex="25">
              <!--<button *ngIf="edit==false" (click)="toastrService.error('Guidance has been Started, You Cannot Use This Option!');" style="color: gray;"  mat-button>Edit<mat-icon>edit</mat-icon></button>-->
              <button
                (click)="deadlineAddDialog()"
                style="color: gray"
                mat-button
              >
                {{ "guidance.edit" | translate }}<mat-icon>edit</mat-icon>
              </button>
            </div>
          </div>
          <ul style="font-size: smaller">
            <li *ngIf="guidance.preSubmission != null">
              <div id="main-div">
                <div class="div" style="width: 45%">
                  {{ "guidance.deadlines.pre" | translate }}
                </div>
                <div class="div" style="width: 5%">:</div>
                <div class="div" style="width: 35%">
                  {{ guidance.preSubmission | date : "dd/MM/yyyy" }}
                </div>
              </div>
            </li>
            <li *ngIf="guidance.finalSubmission != null">
              <div id="main-div">
                <div class="div" style="width: 45%">
                  {{ "guidance.deadlines.final" | translate }}
                </div>
                <div class="div" style="width: 5%">:</div>
                <div class="div" style="width: 35%">
                  {{ guidance.finalSubmission | date : "dd/MM/yyyy" }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content style="height: 120px">
        <div class="card-body">
          <div fxLayout="row">
            <div fxFlex="10" style="vertical-align: middle">
              <mat-icon>library_books</mat-icon>
            </div>
            <div fxFlex="65">
              <mat-card-title style="font-size: 100%">{{
                "guidance.planningCycleName.title" | translate
              }}</mat-card-title>
            </div>
            <div fxFlex="25">
              <button
                *ngIf="edit == false"
                (click)="outToastr('guidance.msg1')"
                style="color: gray"
                mat-button
              >
                {{ "guidance.edit" | translate }}<mat-icon>edit</mat-icon>
              </button>
              <button
                *ngIf="edit == true"
                (click)="planningCycleNameAddDialog()"
                style="color: gray"
                mat-button
              >
                {{ "guidance.edit" | translate }}<mat-icon>edit</mat-icon>
              </button>
            </div>
          </div>
          <ul style="font-size: smaller">
            <li *ngIf="guidance.planningCycleName != ''">
              <span class="badge">{{ guidance.planningCycleName }}</span>
            </li>
          </ul>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div *ngIf="this.package == 'premium'" fxLayout="row">
  <!-- FAQ -->
  <mat-card fxFlex="100">
    <mat-card-content style="height: 275px">
      <div class="card-body">
        <div fxLayout="row">
          <div fxFlex="3" style="vertical-align: middle">
            <mat-icon>question_answer</mat-icon>
          </div>
          <div fxFlex="87">
            <mat-card-title style="font-size: 100%">{{
              "guidance.faq.title" | translate
            }}</mat-card-title>
          </div>
          <div fxFlex="10">
            <button (click)="faqAddDialog()" style="color: gray" mat-button>
              {{ "guidance.edit" | translate }}<mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
        <ul style="font-size: smaller">
          <li *ngFor="let faq of faqList">
            <span class="badge"
              >{{ faq.question }}<br /><br />{{ faq.answer }}</span
            ><br /><br />
          </li>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>
</div>
