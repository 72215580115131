import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { Guidance } from "@planard/@core/entities/guidance";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./deadline-add.component.html",
})
export class DeadlineAddComponent {
  guidance: Guidance = new Guidance();

  form: FormGroup = new FormGroup({
    preSubmission: new FormControl(""),
    finalSubmission: new FormControl("", [Validators.required]),
  });
  options: FormGroup;
  guidanceId: number;
  guidancePre: Date;
  guidanceFinal: Date;

  constructor(
    public dialogRef: MatDialogRef<DeadlineAddComponent>,
    public guidanceService: GuidanceService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.guidanceId = data.guidanceId;
    this.guidancePre = data.guidancePre;
    this.guidanceFinal = data.guidanceFinal;
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.form.patchValue({ preSubmission: this.guidancePre });
    this.form.patchValue({ finalSubmission: this.guidanceFinal });
  }

  save() {
    if (this.form.valid) {
      this.guidance.preSubmission = this.form.get("preSubmission").value;
      this.guidance.finalSubmission = this.form.get("finalSubmission").value;

      this.guidance.id = this.guidanceId;
      let val1 = new Date(this.guidance.finalSubmission);
      let val2 = new Date(this.guidance.preSubmission);
      if (
        (this.guidance.preSubmission != null && val1 > val2) ||
        (this.guidance.preSubmission == null &&
          this.guidance.finalSubmission != null)
      ) {
        this.guidanceService
          .updateDeadline(this.guidance)
          .subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.dialogRef.close(this.guidance);
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
            }
          });
      } else {
        this.translate
          .get("guidance.deadlines.valid")
          .subscribe((data: any) => {
            this.toastrService.error(data);
          });
      }
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
