import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Profile } from "@planard/@core/entities/profile";
import { ProfileData } from "@planard/@core/entities/interface/profile";
import { ProfileApi } from "../api/profile.api";

@Injectable()
export class ProfileService extends ProfileData {
  constructor(private api: ProfileApi) {
    super();
  }

  getProfile(id: number): Observable<Profile> {
    return this.api.getProfile<Profile>(id);
  }

  update(product: Profile) {
    return this.api.update<any>(product);
  }
}
