import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { CostOverview } from "@planard/@core/entities/costOverview";

@Injectable()
export class CostOverviewApi {
  private readonly apiController: string = "costOverview";

  constructor(private api: HttpService) {}

  listCost<T>(scenarioId: number): Observable<any> {
    return this.api.get(`${this.apiController}/listCost/${scenarioId}`);
  }

  update<T>(cost: CostOverview): Observable<any> {
    return this.api.post(`${this.apiController}/update`, cost);
  }
}
