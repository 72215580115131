<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div>
          <div
            class="div"
            style="width: 40%"
            *ngIf="granularity != 0 && granularity != null"
          >
            <mat-card-title
              >{{ "reports.salesBridge" | translate }} <br />
              <span style="font-size: 15px"
                >{{ companyNumberFormat }} {{ companyCurrency }}</span
              ></mat-card-title
            >
          </div>
          <div
            class="div"
            style="width: 50%"
            *ngIf="granularity == 0 || granularity == null"
          >
            <mat-card-title
              >{{ "reports.salesBridge" | translate }} <br />
              <span style="font-size: 15px"
                >{{ companyNumberFormat }} {{ companyCurrency }}</span
              ></mat-card-title
            >
          </div>

          <div class="div" style="width: 20%">
            <mat-form-field style="width: 90%">
              <mat-select
                [(ngModel)]="requestId"
                [formControl]="requestControl"
                placeholder="{{ 'reports.narrativeRequest' | translate }}"
                required
              >
                <mat-option
                  *ngFor="let sale of saleRequestList"
                  [value]="sale.id"
                  value="option"
                >
                  <strong>{{ sale.firstPlanningCycleName }}</strong
                  >&nbsp;{{ sale.firstYear }}
                  &nbsp;&nbsp;&nbsp;vs&nbsp;&nbsp;&nbsp;
                  <strong>{{ sale.secondPlanningCycleName }}</strong
                  >&nbsp;{{ sale.secondYear }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="requestControl.hasError('required')">{{
                "reports.narrativeRequestChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="granularity"
                name="granularity"
                [formControl]="granularityControl"
                placeholder="{{ 'reports.granularity' | translate }}"
                required
              >
                <mat-option
                  *ngFor="let o of granularityList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="granularityControl.hasError('required')">{{
                "reports.granularity" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%" *ngIf="granularity == 1">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="productFamilyId"
                name="productFamilyId"
                [formControl]="pfControl"
                placeholder="{{ 'reports.productFamily' | translate }}"
                required
              >
                <mat-option
                  *ngFor="let o of productFamilyList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="pfControl.hasError('required')">{{
                "reports.productFamilyChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%" *ngIf="granularity == 2">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="businessUnitId"
                name="businessUnitId"
                [formControl]="buControl"
                placeholder="{{ 'reports.businessUnit' | translate }}"
                required
              >
                <mat-option
                  *ngFor="let o of businessUnitList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="buControl.hasError('required')">{{
                "reports.businessUnitChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%" *ngIf="granularity == 3">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="productId"
                name="productId"
                [formControl]="productControl"
                placeholder="{{ 'reports.product' | translate }}"
                required
              >
                <mat-option
                  *ngFor="let o of productList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="productControl.hasError('required')">{{
                "reports.productChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="currency"
                placeholder="{{ 'reports.currency' | translate }}"
                [formControl]="currencyControl"
                required
              >
                <mat-option [value]="0" value="option">{{
                  "reports.defaultCurrency" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of guidanceFxRateList"
                  [value]="o.fxRateId"
                  value="option"
                >
                  {{ getFxRate(o.fxRateId) }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="currencyControl.hasError('required')">{{
                "reports.currencyChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <div style="width: 85%">
              <button color="accent" (click)="isValid()" mat-button>
                {{ "reports.run" | translate }}
              </button>
            </div>
          </div>
        </div>

        <br />
        <br />

        <div>
          <div class="div" style="width: 15%"></div>

          <div class="div" style="width: 70%" #screen>
            <canvas
              style="min-height: 110% !important"
              *ngIf="isRun == true"
              baseChart
              [datasets]="barChartData"
              [plugins]="waterFallPlugin"
              [labels]="reportData.chartLabels"
              [options]="barChartOptions"
              [chartType]="barChartType"
              [colors]="barChartColors"
            >
            </canvas>
          </div>
        </div>
        <span *ngIf="isRun != true" style="width: 60%; float: right">{{
          "reports.seeChart" | translate
        }}</span>

        <br />
        <br />
        <br />

        <button (click)="take()" color="accent" mat-raised-button>
          <mat-icon>camera_alt</mat-icon>&nbsp;{{
            "reports.take" | translate
          }}</button
        >&nbsp;
        <button *ngIf="img" (click)="reset()" color="accent" mat-raised-button>
          <mat-icon>delete_sweep</mat-icon>&nbsp;{{
            "reports.reset" | translate
          }}</button
        >&nbsp;
        <button *ngIf="img" (click)="save()" color="accent" mat-raised-button>
          <mat-icon>save</mat-icon>&nbsp;{{ "reports.save" | translate }}
        </button>

        <br />
        <br />
        <img *ngIf="img" src="{{ img }}" style="width: 50%; height: 50%" />
      </mat-card-content>
    </mat-card>

    <!--<mat-card *ngIf="guidanceIsStart==null">
          <mat-card-content style="min-height: 200px;">
            <div>
              <div class="div" style="width: 50%;">
                <mat-card-title>Sales Bridge</mat-card-title>
              </div>
            </div>
            <br>
            <br>
            <span *ngIf="isRun!=true" style="width: 60%; float: right;">Guidance not started yet.</span>
          </mat-card-content>
      </mat-card>-->
  </div>
</div>
