<h1 mat-dialog-title>Add Product Channel</h1>
<form (ngSubmit)="save()" [formGroup]="pcForm">
  <div mat-dialog-content>
    <mat-form-field>
      <mat-select
        formControlName="channelId"
        name="channelId"
        placeholder="Channels"
      >
        <mat-option *ngFor="let channel of channelList" [value]="channel.id">{{
          channel.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="2">Save</button>
    <button (click)="cancelClick()" mat-button tabindex="-1" type="button">
      Cancel
    </button>
  </div>
</form>
