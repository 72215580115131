<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 85%">
            <mat-card-title>{{ "approvals.cost" | translate }}</mat-card-title>
          </div>
          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>
        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef mat-sort-header="type">
                {{ "approvals.approvalType" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{
                  element.type == "2"
                    ? ("approvals.costApproval" | translate)
                    : element.type == "3"
                    ? ("approvals.centralApproval" | translate)
                    : "-"
                }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="scenarioStr">
              <mat-header-cell *matHeaderCellDef mat-sort-header="scenarioStr">
                {{ "approvals.scenarioStr" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.scenarioStr }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="submitUser">
              <mat-header-cell *matHeaderCellDef mat-sort-header="submitUser">
                {{ "approvals.submitter" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.submitUser }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="submitDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header="createdDate">
                {{ "approvals.submitDate" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.createdDate | date : "dd/MM/yyyy" }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="approveUser">
              <mat-header-cell *matHeaderCellDef mat-sort-header="approveUser">
                {{ "approvals.lastApprover" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.lastUpdatedUser ? element.lastUpdatedUser : "-" }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ApproveDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header="ApproveDate">
                {{ "approvals.approveDate" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{
                  element.status == "Completed"
                    ? (element.updatedDate | date : "dd/MM/yyyy")
                    : "-"
                }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="state">
              <mat-header-cell *matHeaderCellDef mat-sort-header="state">
                {{ "approvals.state" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span
                  *ngIf="element.status == 'In Progress'"
                  style="color: rgb(255, 165, 0)"
                  >{{ "approvals.inprogress" | translate }}</span
                >
                <span
                  *ngIf="element.status == 'Completed'"
                  style="color: green"
                  >{{ "approvals.completed" | translate }}</span
                >
                <span
                  *ngIf="element.status == 'Rejected'"
                  style="color: tomato"
                  >{{ "approvals.rejected" | translate }}</span
                >
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
                &nbsp; &nbsp; &nbsp; {{ "approvals.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element" [ngClass]="'toRight'">
                <a
                  *ngIf="element.type == '2' && element.status != 'Rejected'"
                  style="color: cornflowerblue; font-size: 11.5px"
                  href="#/planning-costs/plan-approver/{{ element.id }}"
                >
                  {{ "approvals.go" | translate }} &nbsp;
                  <mat-icon style="vertical-align: middle">shortcut</mat-icon>
                </a>
                <a
                  *ngIf="element.type == '3' && element.status != 'Rejected'"
                  style="color: cornflowerblue; font-size: 11.5px"
                  href="#/planning-costs/centralPlan-approver/{{ element.id }}"
                >
                  {{ "approvals.go" | translate }} &nbsp;
                  <mat-icon style="vertical-align: middle">shortcut</mat-icon>
                </a>
                <a
                  *ngIf="element.status == 'Rejected'"
                  style="color: rgb(134, 137, 143); font-size: 11.5px"
                  disabled
                >
                  {{ "approvals.go" | translate }} &nbsp;
                  <mat-icon style="vertical-align: middle">shortcut</mat-icon>
                </a>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>

        <mat-card-actions> </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
