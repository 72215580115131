<h1 mat-dialog-title>{{ "timelineAdd.title" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <mat-select
        [(ngModel)]="task"
        *ngIf="customTask == ''"
        tabindex="1"
        formControlName="task"
        name="task"
        placeholder="{{ 'timelineAdd.task' | translate }}"
      >
        <mat-option *ngFor="let o of taskList" [value]="o" value="option">
          <span *ngIf="'enums.tasks.' + o as variable">{{
            variable | translate
          }}</span>
        </mat-option>
        <mat-option [value]="null" value="option">{{
          "timelineAdd.custom" | translate
        }}</mat-option>
      </mat-select>
      <mat-select
        [(ngModel)]="task"
        *ngIf="customTask != ''"
        tabindex="1"
        formControlName="task"
        name="task"
        placeholder="{{ 'timelineAdd.task' | translate }}"
        disabled
        [value]="none"
      >
        <mat-option *ngFor="let o of taskList" [value]="o" value="option">
          <span *ngIf="'enums.tasks.' + o as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="task == null">
      <input
        tabindex="2"
        [(ngModel)]="customTask"
        formControlName="customTask"
        matInput
        name="customTask"
        placeholder="{{ 'timelineAdd.custom' | translate }}"
      />
    </mat-form-field>

    <mat-form-field *ngIf="companyPackage == 'premium'">
      <mat-select
        #roleSelect
        tabindex="3"
        formControlName="selectedProcessRoles"
        name="selectedProcessRoles"
        placeholder="{{ 'timelineAdd.responsible' | translate }}"
        multiple
      >
        <mat-option (click)="selectAll(roleSelect)">All</mat-option>
        <mat-option
          *ngFor="let o of responsibleList"
          [value]="o.id"
          value="option"
          (click)="deselectOne(o.id, roleSelect)"
        >
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="4"
        formControlName="dependentProcessId"
        name="dependentProcessId"
        placeholder="{{ 'timelineAdd.dependency' | translate }}"
      >
        <mat-option
          *ngFor="let o of dependencyList"
          [value]="o.id"
          value="option"
        >
          <p *ngIf="o.customTask == null">
            {{ o.task | enumDisplayValue : "EnumTask" }}
          </p>
          <p *ngIf="o.customTask != null">{{ o.customTask }}</p>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        [min]="today"
        tabindex="5"
        [matDatepicker]="spicker"
        [(ngModel)]="startDate"
        (ngModelChange)="daysToExecuteSet(); endDateSet()"
        formControlName="startDate"
        matInput
        name="startDate"
        placeholder="{{ 'timelineAdd.startDate' | translate }}"
      />
      <mat-datepicker-toggle [for]="spicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #spicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <input
        [min]="today"
        tabindex="6"
        [matDatepicker]="epicker"
        [(ngModel)]="endDate"
        (ngModelChange)="daysToExecuteSet()"
        formControlName="endDate"
        matInput
        name="endDate"
        placeholder="{{ 'timelineAdd.endDate' | translate }}"
      />
      <mat-datepicker-toggle [for]="epicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #epicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="7"
        [(ngModel)]="diffDays"
        (ngModelChange)="endDateSet()"
        formControlName="daysToExecute"
        matInput
        name="daysToExecute"
        type="number"
        placeholder="{{ 'timelineAdd.days' | translate }}"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="8">
      {{ "timelineAdd.save" | translate }}
    </button>
    <button (click)="cancelClick()" mat-button tabindex="9" type="button">
      {{ "timelineAdd.cancel" | translate }}
    </button>
  </div>
</form>
