import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "@planard/auth/services/auth.service";

@Component({
  templateUrl: "./planning-add.component.html",
})
export class PlanningAddComponent {
  period: GuidancePlanningPeriod;
  package: string;
  planningType: any;
  monthly: boolean;

  currentYear = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  yearList = [
    this.currentYear,
    this.currentYear + 1,
    this.currentYear + 2,
    this.currentYear + 3,
    this.currentYear + 4,
    this.currentYear + 5,
    this.currentYear + 6,
    this.currentYear + 7,
    this.currentYear + 8,
    this.currentYear + 9,
  ];

  monthList = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  granularityList = [
    { value: "Product", label: "Product" },
    { value: "Product-Channel", label: "Product-Channel" },
    { value: "Product-Form", label: "Product-Form" },
    { value: "Product-Channel-Form", label: "Product-Channel-Form" },
  ];

  form: FormGroup = new FormGroup({
    year: new FormControl("", [Validators.required]),
    planningType: new FormControl("", [Validators.required]),
    startMonth: new FormControl(""),
  });
  options: FormGroup;
  guidanceId: number;

  constructor(
    public dialogRef: MatDialogRef<PlanningAddComponent>,
    public guidanceService: GuidanceService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public authService: AuthService,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.guidanceId = data.guidanceId;
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    //this.form.patchValue({startMonth: 0});
    setTimeout(() => {
      this.package = this.authService.getCompanyPackage();
    }, 150);
  }

  save() {
    if (this.form.valid) {
      this.period = this.form.value;
      if (this.period.planningType == "Yearly") {
        this.period.startMonth = 0;
      }
      this.period.guidanceId = this.guidanceId;
      this.guidanceService.addPeriod(this.period).subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);

              if (this.package == "basic") this.startCycleAuto();
            });
          this.dialogRef.close(this.period);
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  typeSet() {
    this.planningType = this.form.get("planningType").value;
    if (this.planningType == "Monthly") {
      this.monthly = true;
    } else {
      this.monthly = false;
    }
  }
  startCycleAuto() {
    let body = { guidanceId: this.guidanceId };
    this.guidanceService.startGuidance(body).subscribe();
  }
}
