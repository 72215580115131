<div fxLayout="row wrap">
  <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.gt-xs="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 85%">
            <mat-card-title>{{ "product.title" | translate }}</mat-card-title>
          </div>
          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>
        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header="name">{{
                "product.name" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'name')">
                  <ng-template viewMode>
                    {{ element.name }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [value]="element.name"
                        [formControl]="getControl(element.id, 'name')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="businessUnitId">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                "product.businessUnit" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable2 (update)="updateField(element.id, 'businessUnitId')">
                  <ng-template viewMode2>
                    {{ element.businessUnitStr }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field>
                      <mat-select
                        (selectionChange)="
                          productFamilySet($event.value);
                          element.productFamilyId = null
                        "
                        [formControl]="getControl(element.id, 'businessUnitId')"
                        placeholder="{{ element.businessUnitStr }}"
                        [(ngModel)]="element.businessUnitId"
                      >
                        <mat-option
                          *ngFor="let o of businessUnitList"
                          [value]="o.id"
                        >
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-select
                        (click)="productFamilySet(element.businessUnitId)"
                        [formControl]="
                          getControl(element.id, 'productFamilyId')
                        "
                        [(ngModel)]="element.productFamilyId"
                      >
                        <mat-option
                          *ngFor="let o of productFamilyBuList"
                          [value]="o.id"
                        >
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="productFamilyId">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="productFamilyId"
              >
                {{ "product.productFamily" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element" [style.color]="element.color">
                <editable (update)="updateField(element.id, 'productFamilyId')">
                  <ng-template viewMode>
                    {{ element.productFamilyStr }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field
                      (click)="productFamilySet(element.businessUnitId)"
                    >
                      <mat-select
                        [formControl]="
                          getControl(element.id, 'productFamilyId')
                        "
                        [(ngModel)]="element.productFamilyId"
                      >
                        <mat-option
                          *ngFor="let o of productFamilyBuList"
                          [value]="o.id"
                        >
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="channels">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                "product.channels" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element" [style.color]="element.color">
                <editable2 (update)="updateField(element.id, 'channels')">
                  <ng-template viewMode2>
                    {{
                      isAll(element.channels)
                        ? "All"
                        : element.channelsStr
                        ? element.channelsStr
                        : "-"
                    }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field>
                      <mat-select
                        #channelSelect
                        [formControl]="getControl(element.id, 'channels')"
                        multiple
                        [(ngModel)]="element.selectedChannels"
                      >
                        <mat-option disabled="disabled" class="filter-option">
                          <button
                            mat-raised-button
                            class="mat-primary fill text-sm"
                            (click)="selectAll(element.id, channelSelect)"
                          >
                            Select All
                          </button>
                          &nbsp;
                          <button
                            mat-raised-button
                            class="mat-accent fill text-sm"
                            (click)="deselectAll(element.id)"
                          >
                            Deselect All
                          </button>
                        </mat-option>
                        <mat-option
                          *ngFor="let o of channelList"
                          [value]="o.id"
                        >
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="forms">
              <mat-header-cell *matHeaderCellDef>{{
                "product.forms" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let row" [style.color]="row.color">
                <button
                  (click)="openForm(row)"
                  autofocus
                  color="accent"
                  mat-icon-button
                >
                  <mat-icon>add_to_photos</mat-icon>
                </button>
                <span>
                  {{ row.formsStr }}
                </span>
              </mat-cell>
            </ng-container>
            <!-- Color Column -->
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRightReverse'">{{
                "product.actions" | translate
              }}</mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                [style.color]="row.color"
                [ngClass]="'toRightReverse'"
              >
                <button
                  (click)="onDelete(row.id)"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>
        <mat-card-actions>
          <button (click)="onAdd()" color="accent" mat-raised-button>
            {{ "product.add" | translate }}
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
