import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  Inject,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { KeyContactsAddComponent } from "./add/key-contacts-add.component";
import { GuidanceKeyContact } from "@planard/@core/entities/guidanceKeyContact";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./key-contact-edit-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 10%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class KeyContactEditNewComponent implements OnInit {
  displayedColumns: string[] = [
    "department",
    "name",
    "phone",
    "email",
    "actions",
  ];
  dataSource: MatTableDataSource<GuidanceKeyContact>;
  controls: any[];

  guidanceId: number;
  IsEmpty: boolean;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<KeyContactEditNewComponent>,
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    private guidanceService: GuidanceService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.guidanceId = data.guidanceId;
  }

  ngOnInit() {
    this.guidanceService.getKeyContact(this.guidanceId).subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);

      if (data != null) {
        this.IsEmpty = false;
      } else {
        this.IsEmpty = true;
      }

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "department", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              department: new FormControl(entity.department, [
                Validators.required,
                removeSpaces,
              ]),
              name: new FormControl(entity.name, [
                Validators.required,
                removeSpaces,
              ]),
              phone: new FormControl(entity.phone, [
                Validators.required,
                Validators.pattern("[- +()0-9]+"),
                removeSpaces,
              ]),
              email: new FormControl(entity.email, [
                Validators.required,
                Validators.email,
                removeSpaces,
              ]),
            },
            { updateOn: "change" }
          ),
        };
      });
    });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var contact = this.getElement(index);
      contact[field] = control.value;
      this.guidanceService.updateContact(contact).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.ngOnInit();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.ngOnInit();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      this.ngOnInit();
    }
  }
  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    const dialogRef = this.dialog.open(KeyContactsAddComponent, {
      width: "500px",
      data: {
        guidanceId: this.guidanceId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  onDelete(row: any) {
    this.translate.get("delete.contact").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.guidanceService.deleteKeyContact(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.ngOnInit();
            }
          });
        }
      });
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
