import { Injectable } from "@angular/core";
import { GuidanceService } from "./guidance.service";
import { ActualsService } from "./actuals.service";

@Injectable()
export class GetPeriodGroupService {
  actualsList: any[] = [];
  currentPlanPeriodList: any[] = [];

  constructor(
    private guidanceService: GuidanceService,
    private actualsService: ActualsService
  ) {}

  getPrice() {
    this.actualsService.listAllFiles().subscribe((data) => {
      data.forEach((element) => {
        if (element.type == "Price" || element.type == "Discount") {
          let ele = {
            value: element.period,
            label: `A'${element.period}`,
            isActuals: true,
          };
          this.actualsList.push(ele);
        }
      });
      return this.actualsList;
    });
  }

  getCurrent() {
    this.guidanceService.getGuidance().subscribe((data: any) => {
      var result = data.result;
      var guidanceName = result.planningCycleName;
      this.guidanceService
        .getPlanningRecord(result.id)
        .subscribe((result: any) => {
          result.forEach((element) => {
            let ele = {
              value: element.id,
              label: `${guidanceName}'${element.year}`,
              isActuals: false,
            };
            this.currentPlanPeriodList.push(ele);
          });
        });
      return this.currentPlanPeriodList;
    });
  }
}
