<h1 mat-dialog-title>{{ "productAdd.title" | translate }}</h1>
<form [formGroup]="form" (ngSubmit)="save()">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        matInput
        tabindex="1"
        placeholder="{{ 'productAdd.name' | translate }}"
        name="productName"
        formControlName="name"
      />
    </mat-form-field>

    <div fxFlex="50" fxFlex.gt-sm="50">
      <mat-form-field>
        <mat-select
          tabindex="2"
          (selectionChange)="ProductFamilySet()"
          placeholder="{{ 'productAdd.businessUnit' | translate }}"
          name="businessUnitId"
          formControlName="businessUnitId"
        >
          <mat-option
            *ngFor="let o of businessUnitList"
            [value]="o.id"
            value="option"
          >
            {{ o.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          tabindex="3"
          placeholder="{{ 'productAdd.productFamily' | translate }}"
          name="productFamilyId"
          formControlName="productFamilyId"
        >
          <mat-option
            *ngFor="let o of productFamilyBuList"
            [value]="o.id"
            value="option"
          >
            {{ o.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          #channelSelect
          tabindex="4"
          formControlName="channels"
          name="channels"
          placeholder="{{ 'productAdd.channels' | translate }}"
          multiple
        >
          <mat-option disabled="disabled" class="filter-option">
            <button
              mat-raised-button
              class="mat-primary fill text-sm"
              (click)="selectAll(channelSelect)"
            >
              Select All
            </button>
            &nbsp;
            <button
              mat-raised-button
              class="mat-accent fill text-sm"
              (click)="deselectAll()"
            >
              Deselect All
            </button>
          </mat-option>

          <mat-option *ngFor="let o of channelList" [value]="o">
            {{ o.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button tabindex="5">{{ "productAdd.save" | translate }}</button>
    <button mat-button type="button" (click)="cancelClick()" tabindex="6">
      {{ "productAdd.cancel" | translate }}
    </button>
  </div>
</form>
