import { Observable } from "rxjs";
import { Actuals } from "@planard/@core/entities/actuals";

export abstract class ActualsData {
  abstract listAllFiles(): Observable<Actuals[]>;

  abstract listFilePeriod(type: any): Observable<any>;

  abstract uploadPrice(data: any): Observable<any>;

  abstract uploadForecast(data: any): Observable<any>;

  abstract uploadOtherForecast(data: any): Observable<any>;

  abstract uploadCost(data: any): Observable<any>;

  abstract uploadCentralCost(data: any): Observable<any>;

  abstract delete(fileId: number): Observable<any>;
}
