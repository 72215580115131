import { BaseEntity } from "@planard/@core/base/BaseEntity";
import { EnumCostType } from "@planard/@core/enums/EnumCostType";
import { Category } from "@planard/@core/entities/category";
import { SubCategory } from "@planard/@core/entities/subcategory";

export class CostType extends BaseEntity {
  name?: string;
  costType?: EnumCostType;
  categoryId?: Category;
  subCategoryId?: SubCategory;
  lifeSpan?: number;
  subCategory?: string;
}
