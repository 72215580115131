import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { Process } from "@planard/@core/entities/process";

@Injectable()
export class TimelineApi {
  private readonly apiController: string = "timeline";

  constructor(private api: HttpService) {}

  listAll<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAll`);
  }

  getCurrentTime<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/getCurrentTime`);
  }

  listUserProcesses<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listUserProcesses`);
  }

  listProcessesTime<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listProcessesTime`);
  }

  listResponsibles<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listResponsibles`);
  }

  add<T>(process: Process): Observable<any> {
    return this.api.post(`${this.apiController}/addProcess`, process);
  }

  update<T>(process: Process): Observable<any> {
    return this.api.post(`${this.apiController}/updateProcess`, process);
  }

  delete<T>(processId: any): Observable<any> {
    var json = { id: processId };
    const endPointUrl = `${this.apiController}/deleteProcess`;
    return this.api.post(endPointUrl, json);
  }
}
