import { EventEmitter, Injectable } from "@angular/core";
import { ReportsData } from "@planard/@core/entities/interface/reports";
import { ReportsApi } from "../api/reports.api";

@Injectable()
export class ReportsService extends ReportsData {
  onPriceReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ReportsApi) {
    super();
  }

  listChannels(
    scenarioId: number,
    granularityId: number,
    actuals: any,
    periodId: any,
    currencyId: number,
    indicatorId: number
  ) {
    return this.api.listChannels<any>(
      scenarioId,
      granularityId,
      actuals,
      periodId,
      currencyId,
      indicatorId
    );
  }

  listSales(
    scenarioId: number,
    granularityId: number,
    actuals: any,
    periodId: any,
    currencyId: number,
    indicatorId: number
  ) {
    return this.api.listSales<any>(
      scenarioId,
      granularityId,
      actuals,
      periodId,
      currencyId,
      indicatorId
    );
  }

  listProduct(
    scenarioId: number,
    granularityId: number,
    actuals: any,
    periodId: any,
    currencyId: number,
    indicatorId: number
  ) {
    return this.api.listProduct<any>(
      scenarioId,
      granularityId,
      actuals,
      periodId,
      currencyId,
      indicatorId
    );
  }

  scenarioComp(
    scenarioId: any,
    granularityId: number,
    categoryId: number,
    indicatorId: number,
    timescale: number,
    actuals: number,
    periodId: number,
    currencyId: number
  ) {
    return this.api.scenarioComp<any>(
      scenarioId,
      granularityId,
      categoryId,
      indicatorId,
      timescale,
      actuals,
      periodId,
      currencyId
    );
  }

  priceVolume(
    productId: number,
    scenarioId: number,
    startActuals: number,
    startPeriods: number,
    endActuals: number,
    endPeriods: number,
    currency: number
  ) {
    return this.api.priceVolume<any>(
      productId,
      scenarioId,
      startActuals,
      startPeriods,
      endActuals,
      endPeriods,
      currency
    );
  }

  listIncomeStatement(
    scenarioId: number,
    guidanceId: number,
    actuals: any,
    periodId: any,
    currency: number
  ) {
    return this.api.listIncomeStatement<any>(
      scenarioId,
      guidanceId,
      actuals,
      periodId,
      currency
    );
  }

  listActualPlan(
    indicator: number,
    granularity: number,
    year: number,
    month: number,
    scenario_id: number,
    currency_id: number
  ) {
    return this.api.listActualPlan<any>(
      indicator,
      granularity,
      year,
      month,
      scenario_id,
      currency_id
    );
  }
}
