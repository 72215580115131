import { EventEmitter, Injectable } from "@angular/core";
import { CentralPlanData } from "@planard/@core/entities/interface/centralPlan";
import { CentralPlanApi } from "@planard/@core/backend/api/centralPlan.api";
import { CentralPlan } from "@planard/@core/entities/centralPlan";

@Injectable()
export class CentralPlanService extends CentralPlanData {
  onPriceReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: CentralPlanApi) {
    super();
  }

  listCost(scenarioId: number, approveRequestId?: number) {
    return this.api.listCost<any>(scenarioId, approveRequestId);
  }

  update(plan: CentralPlan) {
    return this.api.update<any>(plan);
  }
}
