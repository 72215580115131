import { Routes, ExtraOptions, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthService } from "./auth/services/auth.service";
import { AuthGuardService } from "./auth/services/auth-guard.service";
import { LocalStorageService } from "./auth/services/storage.service";

export const AppRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "auth",
        loadChildren: () =>
          import("./auth/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
      {
        path: "",
        // canLoad: [LoggedinGuard],
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import("../app/layouts/full/full-component.module").then(
            (m) => m.FullComponentModule
          ),
      },
    ],
  },
];
const config: ExtraOptions = {
  useHash: true,
  enableTracing: false,
};

export const authProviders = [
  AuthGuardService,
  AuthService,
  LocalStorageService,
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
