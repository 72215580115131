<h1 mat-dialog-title>{{ "scenarioAdd.title" | translate }}</h1>

<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        tabindex="1"
        formControlName="name"
        matInput
        name="name"
        placeholder="{{ 'scenarioAdd.name' | translate }}"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-select
        #productSelect
        tabindex="2"
        formControlName="impactedProducts"
        multiple
        name="impactedProducts"
        placeholder="{{ 'scenarioAdd.impactedProducts' | translate }}"
      >
        <mat-option disabled="disabled" class="hide-checkbox">
          <button
            mat-raised-button
            class="mat-primary fill text-sm"
            (click)="selectAll(productSelect)"
          >
            Select All
          </button>
          &nbsp;
          <button
            mat-raised-button
            class="mat-accent fill text-sm"
            (click)="deselectAll()"
          >
            Deselect All
          </button>
        </mat-option>
        <mat-option
          *ngFor="let product of productList"
          [value]="product"
          value="option"
        >
          {{ product.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="3"
        formControlName="impactArea"
        name="impactArea"
        placeholder="{{ 'scenarioAdd.impactArea' | translate }}"
        multiple
      >
        <mat-option *ngFor="let o of impactAreaList" [value]="o">
          <span *ngIf="'impactArea.' + o as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="4"
        formControlName="description"
        matInput
        name="description"
        placeholder="{{ 'scenarioAdd.description' | translate }}"
      />
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-button tabindex="5" type="submit">
      {{ "scenarioAdd.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="6" type="button">
      {{ "scenarioAdd.cancel" | translate }}
    </button>
  </div>
</form>
