import { BaseEntity } from "@planard/@core/base/BaseEntity";
import { EnumStatus } from "@planard/@core/enums/EnumStatus";

export class UserTask extends BaseEntity {
  companyId?: number;
  userName: string;
  name: string;
  startDate: Date;
  endDate: Date;
  status?: EnumStatus;
  lockDate?: Date;
  unlockDate?: Date;
}
