import { Observable } from "rxjs";
import { Products } from "../product";

export abstract class ProductsData {
  abstract listAll(): Observable<any[]>;

  abstract listUserProducts(): Observable<any[]>;

  abstract listAllForms(productId: number): Observable<any[]>;

  abstract listAllChannels(productId: number): Observable<any[]>;

  abstract add(product: Products);

  abstract addForm(product: Products);

  abstract addChannel(product: Products);

  abstract update(product: Products);

  abstract updateForm(product: Products);

  abstract updateChannel(product: Products);

  abstract delete(id: number);

  abstract deleteForm(id: number);

  abstract deleteChannel(id: number);
}
