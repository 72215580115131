import { Component, Inject, OnInit } from "@angular/core";
import { RiskOpportunitiesService } from "@planard/@core/backend/service/risk-opportunities.service";
import { RiskOpportunities } from "@planard/@core/entities/riskOpportunities";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProcessResult } from "@planard/@core/entities/process-result";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { EnumImpactArea } from "@planard/@core/enums/EnumImpactArea";
import { Products } from "@planard/@core/entities/product";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./add-risk-opportunities.component.html",
})
export class AddRiskOpportunitiesComponent implements OnInit {
  productList: Products[];
  typeOfImpactList = ["Risk", "Opportunity"];
  riskOpportunities: RiskOpportunities;
  processResult = new ProcessResult();
  impactAreaList = ["P", "V", "C"];
  year: number = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();
  monthList = [
    { value: "Jan" + this.year, label: "Jan" + this.year },
    { value: "Feb" + this.year, label: "Feb" + this.year },
    { value: "Mar" + this.year, label: "Mar" + this.year },
    { value: "Apr" + this.year, label: "Apr" + this.year },
    { value: "May" + this.year, label: "May" + this.year },
    { value: "Jun" + this.year, label: "Jun" + this.year },
    { value: "Jul" + this.year, label: "Jul" + this.year },
    { value: "Aug" + this.year, label: "Aug" + this.year },
    { value: "Sept" + this.year, label: "Sep" + this.year },
    { value: "Oct" + this.year, label: "Oct" + this.year },
    { value: "Nov" + this.year, label: "Nov" + this.year },
    { value: "Dec" + this.year, label: "Dec" + this.year },
  ];

  form: FormGroup = new FormGroup({
    description: new FormControl("", [Validators.required]),
    impactArea: new FormControl("", [Validators.required]),
    typeOfImpact: new FormControl("", [Validators.required]),
    impactAmount: new FormControl("", [Validators.required, Validators.min(0)]),
    likeliHood: new FormControl("", [Validators.required]),
    dayOfCertainty: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  constructor(
    public fb: FormBuilder,
    public productsService: ProductsService,
    public riskOpportunitiesService: RiskOpportunitiesService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<AddRiskOpportunitiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RiskOpportunities
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.productsService.listAll().subscribe((data) => {
      this.productList = data;
    });
  }

  save() {
    if (this.form.valid) {
      this.riskOpportunities = this.form.value;

      var price = false;
      var volume = false;
      var cost = false;

      if (this.riskOpportunities.impactArea.includes("P")) price = true;
      if (this.riskOpportunities.impactArea.includes("V")) volume = true;
      if (this.riskOpportunities.impactArea.includes("C")) cost = true;

      var newImpactArea;
      if (price && volume && cost) newImpactArea = "PVC";
      if (price && volume && !cost) newImpactArea = "PV";
      if (price && !volume && cost) newImpactArea = "PC";
      if (!price && volume && cost) newImpactArea = "VC";
      if (price && !volume && !cost) newImpactArea = "P";
      if (!price && volume && !cost) newImpactArea = "V";
      if (!price && !volume && cost) newImpactArea = "C";

      setTimeout(() => {
        this.riskOpportunities.impactArea = newImpactArea;
        this.riskOpportunities.dayOfCertainty = new Date(
          (typeof this.riskOpportunities.dayOfCertainty === "string"
            ? new Date(this.riskOpportunities.dayOfCertainty)
            : this.riskOpportunities.dayOfCertainty
          ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
        );
        this.riskOpportunitiesService
          .add(this.riskOpportunities)
          .subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.dialogRef.close(this.riskOpportunities);
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
            }
          });
      }, 500);
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
