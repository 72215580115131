<div fxLayout="row wrap">
  <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.gt-xs="100">
    <mat-card>
      <mat-card-content>
        <mat-card-title>{{ "roles.title" | translate }}</mat-card-title>
        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="name"
                [ngClass]="'MakeSmaller'"
                >{{ "roles.name" | translate }}</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let rowIndex = index"
                [ngClass]="'MakeSmaller'"
              >
                <span *ngIf="row.name == 'Admin'">{{
                  "roles.admin.name" | translate
                }}</span>
                <span *ngIf="row.name == 'Price Planner'">{{
                  "roles.pricePlanner.name" | translate
                }}</span>
                <span *ngIf="row.name == 'Central Cost Planner'">{{
                  "roles.centralCostPlanner.name" | translate
                }}</span>
                <span *ngIf="row.name == 'Sales Planner'">{{
                  "roles.businessUnitPlanner.name" | translate
                }}</span>
                <span *ngIf="row.name == 'Sales Approver'">{{
                  "roles.businessUnitApprover.name" | translate
                }}</span>
                <span *ngIf="row.name == 'Cost Planner'">{{
                  "roles.supportFunctionPlanner.name" | translate
                }}</span>
                <span *ngIf="row.name == 'Cost Approver'">{{
                  "roles.supportFunctionApprover.name" | translate
                }}</span>
              </mat-cell>
            </ng-container>

            <!-- Responsibility Column -->
            <ng-container matColumnDef="responsibility">
              <mat-header-cell *matHeaderCellDef>{{
                "roles.resp" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let row; let rowIndex = index">
                <span *ngIf="row.name == 'Admin'">{{
                  "roles.admin.resp" | translate
                }}</span>
                <span *ngIf="row.name == 'Price Planner'">{{
                  "roles.pricePlanner.resp" | translate
                }}</span>
                <span *ngIf="row.name == 'Central Cost Planner'">{{
                  "roles.centralCostPlanner.resp" | translate
                }}</span>
                <span *ngIf="row.name == 'Sales Planner'">{{
                  "roles.businessUnitPlanner.resp" | translate
                }}</span>
                <span *ngIf="row.name == 'Sales Approver'">{{
                  "roles.businessUnitApprover.resp" | translate
                }}</span>
                <span *ngIf="row.name == 'Cost Planner'">{{
                  "roles.supportFunctionPlanner.resp" | translate
                }}</span>
                <span *ngIf="row.name == 'Cost Approver'">{{
                  "roles.supportFunctionApprover.resp" | translate
                }}</span>
              </mat-cell>
            </ng-container>

            <!-- Access Column -->
            <ng-container matColumnDef="reportaccess">
              <mat-header-cell *matHeaderCellDef>{{
                "roles.report" | translate
              }}</mat-header-cell>
              <mat-cell
                *matCellDef="let row; let rowIndex = index"
                [style.color]="row.color"
                style="white-space: initial"
              >
                <span *ngIf="row.name == 'Admin'">
                  {{ "roles.admin.reportABase" | translate }}
                  <span *ngIf="!isCompanyPackageBasic">{{
                    "roles.admin.reportAPremium" | translate
                  }}</span>
                </span>
                <span *ngIf="row.name == 'Price Planner'">
                  {{ "roles.pricePlanner.reportABase" | translate }}
                  <span *ngIf="!isCompanyPackageBasic">{{
                    "roles.pricePlanner.reportAPremium" | translate
                  }}</span>
                </span>
                <span *ngIf="row.name == 'Central Cost Planner'">
                  {{ "roles.centralCostPlanner.reportABase" | translate }}
                  <span *ngIf="!isCompanyPackageBasic">{{
                    "roles.centralCostPlanner.reportAPremium" | translate
                  }}</span>
                </span>
                <span *ngIf="row.name == 'Sales Planner'">
                  {{ "roles.businessUnitPlanner.reportABase" | translate }}
                  <span *ngIf="!isCompanyPackageBasic">{{
                    "roles.businessUnitPlanner.reportAPremium" | translate
                  }}</span>
                </span>
                <span *ngIf="row.name == 'Sales Approver'">
                  {{ "roles.businessUnitApprover.reportABase" | translate }}
                  <span *ngIf="!isCompanyPackageBasic">{{
                    "roles.businessUnitApprover.reportAPremium" | translate
                  }}</span>
                </span>
                <span *ngIf="row.name == 'Cost Planner'">
                  {{ "roles.supportFunctionPlanner.reportABase" | translate }}
                  <span *ngIf="!isCompanyPackageBasic">{{
                    "roles.supportFunctionPlanner.reportAPremium" | translate
                  }}</span>
                </span>
                <span *ngIf="row.name == 'Cost Approver'">
                  {{ "roles.supportFunctionApprover.reportABase" | translate }}
                  <span *ngIf="!isCompanyPackageBasic">{{
                    "roles.supportFunctionApprover.reportAPremium" | translate
                  }}</span>
                </span>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
