import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ClassicReportData } from "@planard/@core/entities/reports/classicReportData";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { Products } from "@planard/@core/entities/product";
import { Scenario } from "@planard/@core/entities/scenario";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";
import { waterFallPlugin } from "chartjs-plugin-waterfall";
import { ForecastService } from "@planard/@core/backend/service/forecast.service";
import { FxRate } from "@planard/@core/entities/fxRate";
import { ReportsService } from "@planard/@core/backend/service/reports.service";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
@Component({
  templateUrl: "./price-volume.component.html",
  styles: [
    `
    .div {
      display: inline-block;
    }"
  `,
  ],
  providers: [NumberDecimalPipe],
})
export class PriceVolumeComponent implements OnInit {
  @ViewChild("screen", { static: true }) screen: any;

  public barChartOptions: any = {};
  public barChartType = "bar";
  public barChartColors: Array<any> = [];

  waterFallPlugin: waterFallPlugin;
  reportData: ClassicReportData = new ClassicReportData();
  barChartData: any[] = [];
  currencyList: EnumCurrency[] = EnumCurrency.values;
  productList: Products[];
  scenarioList: Scenario[];
  planningPeriodList: GuidancePlanningPeriod[];
  indicatorList = ["Gross Price", "Discount", "Net Price"];
  periodList = ["All"];
  scenarioName: string;
  productName: string;
  currencyName: string;
  guidanceId: number;
  currency: number = 0;
  scenarioId: number;
  productId: number = 0;
  period: number[] = [];
  isRun: boolean;
  periodCompList: any[] = [];
  years: number[];
  forecastScenarioId: number;
  ctx: any;
  reportScreenshot: ReportScreenshot = {};
  img = "";
  chart: any;
  fxrateList: FxRate[];
  guidanceFxRateList: any[];
  guidanceIsStart: boolean = null;
  startYear: any;
  endYear: any;
  actualsList: any[] = [];
  currentPlanPeriodList: any[] = [];
  periodGroup: any;
  chartData: any;
  isRoleValid: boolean;
  companyPackage: string;

  actualsName;
  currentPlanName;

  isCurrenctYearExist = false;
  companyCurrency: string;
  companyNumberFormat: string;

  price;
  volume;
  mix;

  fullYear = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  scenarioControl = new FormControl("", [Validators.required]);
  productControl = new FormControl("", [Validators.required]);
  period1Control = new FormControl("", [Validators.required]);
  period2Control = new FormControl("", [Validators.required]);
  currencyControl = new FormControl("", [Validators.required]);

  numberFormat: string;
  decimalFormat: string;

  constructor(
    public toastr: ToastrService,
    public scenarioService: ScenarioService,
    public guidanceService: GuidanceService,
    public reportsService: ReportsService,
    public forecastService: ForecastService,
    public actualsService: ActualsService,
    public authService: AuthService,
    public translate: TranslateService,
    private numberDecimal: NumberDecimalPipe,
    public reportScreenshotService: ReportScreenshotService,
    private captureService: NgxCaptureService,
    private router: Router,
    public productsService: ProductsService
  ) {
    var companyPackage = this.authService.getCompanyPackage();
    if (companyPackage == "basic") this.isRoleValid = false;
    if (companyPackage == "premium")
      this.isRoleValid =
        this.authService.isAdmin() ||
        this.authService.isBusinessUnitApprover() ||
        this.authService.isBusinessUnitPlanner();

    if (this.isRoleValid == false || this.companyPackage == "basic")
      this.router.navigate(["auth/404"]);
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();
    this.companyNumberFormat =
      this.authService.getNumberFormat() == "noScale"
        ? ""
        : this.authService.getNumberFormat() == "thousands"
        ? "k"
        : this.authService.getNumberFormat() == "millions"
        ? "m"
        : "n/a";
    this.companyCurrency = this.authService.getCompanyCurrency();
  }

  ngOnInit() {
    this.guidanceService.getGuidance().subscribe((result: any) => {
      var guidanceName = result.planningCycleName;
      this.guidanceId = result.id;
      this.guidanceIsStart = result.start ? true : false;
      if (this.guidanceIsStart) {
        this.guidanceService
          .getPlanningRecord(result.id)
          .subscribe((result2: any) => {
            this.planningPeriodList = result2;
            result2.forEach((element) => {
              if (element.year == this.fullYear)
                this.isCurrenctYearExist = true;
              let ele = {
                value: element.id,
                label: `${guidanceName}'${element.year}`,
                isActuals: false,
              };
              this.currentPlanPeriodList.push(ele);
            });
            this.setYears();
          });
        this.guidanceService.getFxRate(this.guidanceId).subscribe((data) => {
          this.guidanceFxRateList = data;
        });
      }
    });

    this.guidanceService.listAllFxRates().subscribe((result: any) => {
      this.fxrateList = result;
    });
    this.scenarioService.listAll().subscribe((result: any) => {
      this.scenarioList = result;
    });
  }

  setYears() {
    this.scenarioService
      //todo - tüm scenariolar ve tüm productları istedikleri için açıldı
      .listAll() /*WithSales()*/
      .subscribe((result) => {
        this.scenarioList = result;
        result.forEach((element) => {
          if (element.isBase == true) {
            this.forecastScenarioId = element.id;
            this.scenarioId = element.id;
            this.setScenarioLists();
          }
        });
      });
    //actuals yılları
    this.actualsService.listFilePeriod("forecast").subscribe((data) => {
      data.forEach((element) => {
        //if ( element.period == this.fullYear  && this.isCurrenctYearExist ) return;
        let ele = {
          value: element.period,
          label: `A'${element.period}`,
          isActuals: true,
        };
        this.actualsList.push(ele);
      });
    });

    //translate
    this.translate.get("reports.Actuals").subscribe((data: any) => {
      this.actualsName = data;
    });
    this.translate.get("reports.Current Plan").subscribe((data: any) => {
      this.currentPlanName = data;
    });
    this.translate.get("reports.Price Impact").subscribe((data: any) => {
      this.price = data;
    });
    this.translate.get("reports.Volume Impact").subscribe((data: any) => {
      this.volume = data;
    });
    this.translate.get("reports.Mix Impact").subscribe((data: any) => {
      this.mix = data;
    });

    this.translate.onLangChange.subscribe((lang) => {
      this.translate.get("reports.Actuals").subscribe((data: any) => {
        this.actualsName = data;
      });
      this.translate.get("reports.Current Plan").subscribe((data: any) => {
        this.currentPlanName = data;
      });
      this.translate.get("reports.Price Impact").subscribe((data: any) => {
        this.price = data;
      });
      this.translate.get("reports.Volume Impact").subscribe((data: any) => {
        this.volume = data;
      });
      this.translate.get("reports.Mix Impact").subscribe((data: any) => {
        this.mix = data;
      });
      this.periodGroup = [
        {
          name: this.actualsName, // value yıl olucak
          period: this.actualsList,
        },
        {
          name: this.currentPlanName, // value plannig id olucak
          period: this.currentPlanPeriodList,
        },
      ];
    });

    setTimeout(() => {
      this.periodGroup = [
        {
          name: this.actualsName, // value yıl olucak
          period: this.actualsList,
        },
        {
          name: this.currentPlanName, // value plannig id olucak
          period: this.currentPlanPeriodList,
        },
      ];
    }, 1000);
  }

  setScenarioLists() {
    //todo - tüm scenariolar ve tüm productları istedikleri için açıldı
    // this.forecastService
    //   .listForecastProducts(this.scenarioId)
    //   .subscribe((result: any) => {
    //     this.productList = result;
    //   });
    this.productsService.listAll().subscribe((result: any) => {
      this.productList = result;
    });
  }

  getFxRate(fxRateId) {
    if (fxRateId == 0) {
      return "Default Currency";
    } else {
      for (let index in this.fxrateList) {
        if (fxRateId == this.fxrateList[index].id) {
          return this.fxrateList[index].name;
        }
      }
    }
  }

  getPlanPeriod(id: number) {
    for (let ele of this.planningPeriodList) {
      if (id == ele.id) {
        return ele.year;
      }
    }
  }

  isValid() {
    if (
      this.scenarioControl.valid == false ||
      this.productControl.valid == false ||
      this.period1Control.valid == false ||
      this.period2Control.valid == false ||
      this.currencyControl.valid == false
    ) {
      this.translate.get("reports.allrequired").subscribe((data: any) => {
        this.toastr.error(data);
      });
      return;
    } else if (
      this.scenarioControl.valid == true &&
      this.productControl.valid == true &&
      this.period1Control.valid == true &&
      this.period2Control.valid == true &&
      this.currencyControl.valid == true
    ) {
      this.run();
    }
  }

  run() {
    this.isRun = false;

    var startactuals: any;
    var startperiodIds: any;

    var endactuals: any;
    var endperiodIds: any;

    if (this.startYear != null) {
      if (this.startYear.isActuals == true) {
        startactuals = this.startYear.value;
        startperiodIds = 0;
      } else {
        startactuals = 0;
        startperiodIds = this.startYear.value;
      }
    }

    if (this.endYear != null) {
      if (this.endYear.isActuals == true) {
        endactuals = this.endYear.value;
        endperiodIds = 0;
      } else {
        endactuals = 0;
        endperiodIds = this.endYear.value;
      }
    }

    //validations
    if (startactuals == 0 && endactuals == 0) {
      var val1 = this.getPlanPeriod(startperiodIds);
      var val2 = this.getPlanPeriod(endperiodIds);
      if (val1 > val2 || val1 == val2) {
        this.translate.get("reports.warning12").subscribe((data: any) => {
          this.toastr.error(data);
        });
        return;
      }
    } else if (startactuals != 0 && endactuals != 0) {
      if (startactuals > endactuals || startactuals == endactuals) {
        this.translate.get("reports.warning12").subscribe((data: any) => {
          this.toastr.error(data);
        });
        return;
      }
    } else if (startactuals != 0 && endactuals == 0) {
      var val2 = this.getPlanPeriod(endperiodIds);
      if (startactuals > val2 || startactuals == val2) {
        this.translate.get("reports.warning12").subscribe((data: any) => {
          this.toastr.error(data);
        });
        return;
      }
    } else if (startactuals == 0 && endactuals != 0) {
      var val1 = this.getPlanPeriod(startperiodIds);
      if (val1 > endactuals || val1 == endactuals) {
        this.translate.get("reports.warning12").subscribe((data: any) => {
          this.toastr.error(data);
        });
        return;
      }
    }

    this.reportData.chartLabels;
    this.barChartOptions = {};
    this.barChartData = [];

    this.reportsService
      .priceVolume(
        this.productId,
        this.guidanceIsStart ? this.scenarioId : 0,
        startactuals,
        startperiodIds,
        endactuals,
        endperiodIds,
        this.currency
      )
      .subscribe((result) => {
        var groupedData = result.data.reduce(function (obj, x) {
          (obj[x["stack"]] = obj[x["stack"]] || []).push(x);
          return obj;
        }, {});

        let start = groupedData[1];
        let priceImpact = groupedData[2];
        let volumeImpact = groupedData[3];
        let mixImpact = groupedData[4];
        let end = groupedData[5];

        // Price Impact
        if (priceImpact[0]["data"] < 0) {
          let colorBar = priceImpact[1].isRed
            ? "red"
            : priceImpact[1].isWhite
            ? "white"
            : "green";

          priceImpact[0]["dataLabel"] = "hidden";
          priceImpact[0]["isWhite"] = false;
          priceImpact[0]["isRed"] = colorBar == "red" ? true : false;

          priceImpact[1]["dataLabel"] = priceImpact[1]["data"];
          priceImpact[1]["data"] =
            priceImpact[1]["data"] + priceImpact[0]["data"];

          priceImpact[1]["percentage"] =
            ((start[0]["data"] -
              priceImpact[1]["dataLabel"] -
              start[0]["data"]) /
              start[0]["data"]) *
            100;
        } else
          priceImpact[1]["percentage"] =
            ((start[0]["data"] - priceImpact[1]["data"] - start[0]["data"]) /
              start[0]["data"]) *
            100;

        if (priceImpact[1]["isRed"] && !priceImpact[1]["isWhite"])
          priceImpact[1]["percentage"] =
            Math.abs(priceImpact[1]["percentage"]) * -1;
        else if (!priceImpact[1]["isRed"] && !priceImpact[1]["isWhite"])
          priceImpact[1]["percentage"] = Math.abs(priceImpact[1]["percentage"]);

        // Volume Impact
        if (volumeImpact[0]["data"] < 0) {
          let colorBar = volumeImpact[1].isRed
            ? "red"
            : volumeImpact[1].isWhite
            ? "white"
            : "green";

          volumeImpact[0]["dataLabel"] = "hidden";
          volumeImpact[0]["isWhite"] = false;
          volumeImpact[0]["isRed"] = colorBar == "red" ? true : false;

          volumeImpact[1]["dataLabel"] = volumeImpact[1]["data"];
          volumeImpact[1]["data"] =
            volumeImpact[1]["data"] + volumeImpact[0]["data"];

          volumeImpact[1]["percentage"] =
            ((start[0]["data"] -
              volumeImpact[1]["dataLabel"] -
              start[0]["data"]) /
              start[0]["data"]) *
            100;
        } else
          volumeImpact[1]["percentage"] =
            ((start[0]["data"] - volumeImpact[1]["data"] - start[0]["data"]) /
              start[0]["data"]) *
            100;

        if (volumeImpact[1]["isRed"] && !volumeImpact[1]["isWhite"])
          volumeImpact[1]["percentage"] =
            Math.abs(volumeImpact[1]["percentage"]) * -1;
        else if (!volumeImpact[1]["isRed"] && !volumeImpact[1]["isWhite"])
          volumeImpact[1]["percentage"] = Math.abs(
            volumeImpact[1]["percentage"]
          );

        // Mix Impact
        if (mixImpact[0]["data"] < 0) {
          let colorBar = mixImpact[1].isRed
            ? "red"
            : mixImpact[1].isWhite
            ? "white"
            : "green";

          mixImpact[0]["dataLabel"] = "hidden";
          mixImpact[0]["isWhite"] = false;
          mixImpact[0]["isRed"] = colorBar == "red" ? true : false;

          mixImpact[1]["dataLabel"] = mixImpact[1]["data"];
          mixImpact[1]["data"] = mixImpact[1]["data"] + mixImpact[0]["data"];

          mixImpact[1]["percentage"] =
            ((start[0]["data"] - mixImpact[1]["dataLabel"] - start[0]["data"]) /
              start[0]["data"]) *
            100;
        } else
          mixImpact[1]["percentage"] =
            ((start[0]["data"] - mixImpact[1]["data"] - start[0]["data"]) /
              start[0]["data"]) *
            100;

        if (mixImpact[1]["isRed"] && !mixImpact[1]["isWhite"])
          mixImpact[1]["percentage"] =
            Math.abs(mixImpact[1]["percentage"]) * -1;
        else if (!mixImpact[1]["isRed"] && !mixImpact[1]["isWhite"])
          mixImpact[1]["percentage"] = Math.abs(mixImpact[1]["percentage"]);

        end[0]["percentage"] =
          ((end[0]["data"] - start[0]["data"]) / start[0]["data"]) * 100;

        var formattedData = [
          ...start,
          ...priceImpact,
          ...volumeImpact,
          ...mixImpact,
          ...end,
        ];

        formattedData.forEach((ele, index: number) => {
          let color;
          if (ele.isWhite == true) color = "white";
          else if (ele.isRed == true) color = "#E74C3C";
          else if (ele.isSolid == true) color = "#B2BABB";
          else if (
            ele.isRed == false &&
            ele.isWhite == false &&
            ele.isSolid == false
          )
            color = "#27AE60";

          if (ele.label == "Price Impact") ele.label = this.price;
          if (ele.label == "Volume Impact") ele.label = this.volume;
          if (ele.label == "Mix Impact") ele.label = this.mix;

          let value = ele.data.toFixed(2);

          this.barChartData.push({
            label: `${
              ele.isWhite == true || ele.dataLabel == "hidden"
                ? "remove"
                : ele.label
            }`,
            data: [value],
            datalabels: {
              formatter: function (value, context) {
                if ("dataLabel" in ele) value = ele.dataLabel;
                return index
                  ? numberDecimal.transform(
                      Number(value),
                      decimalFormat,
                      numberFormat
                    ) +
                      "\n %" +
                      numberDecimal.transform(
                        Number(ele.percentage),
                        decimalFormat,
                        numberFormat
                      )
                  : numberDecimal.transform(
                      Number(value),
                      decimalFormat,
                      numberFormat
                    );
              },
            },
            waterfall: {
              dummyStack: ele.isWhite == true ? true : false,
            },
            backgroundColor: `${color}`,
            hoverBackgroundColor: `${color}`,
            stack: `stack ${ele.stack}`,
          });
        });

        let numberDecimal = this.numberDecimal;
        let decimalFormat = this.decimalFormat;
        let numberFormat = this.numberFormat;

        this.barChartOptions = {
          scaleShowVerticalLines: false,
          responsive: true,
          barThickness: 4,
          tooltips: {
            enabled: false,
            mode: "single",
            callbacks: {
              label: function (tooltipItems, data) {
                if (data.datasets[tooltipItems.datasetIndex].label != "remove")
                  return data.datasets[tooltipItems.datasetIndex].label;
                else return false;
              },
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {},
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
          },
          title: {
            display: false,
            text: "",
            position: "bottom",
          },
          plugins: {
            datalabels: {
              // align: "top",
              display: function (context) {
                if (context.dataset.label == "remove") return false;
                else return true;
              },
              color: function (context) {
                return "black";
              },
            },
            plugins: {
              waterFallPlugin: {
                stepLines: {
                  enabled: true,
                  startColorStop: 0,
                  endColorStop: 0.6,
                  startColor: "rgba(0, 0, 0, 0.55)",
                  endColor: "rgba(0, 0, 0, 0)",
                  diagonalStepLines: true,
                },
              },
            },
          },
          legend: {
            onClick: false,
            labels: {
              filter: function (item, chart) {
                return !item.text.includes("remove");
              },
            },
            position: "bottom",
          },
          filter: function (legendItem, chartData) {
            var currentDataset = chartData[legendItem.datasetIndex];
            return !currentDataset.dummyStack;
          },
        };

        this.isRun = true;
      });
  }

  take() {
    if (this.isRun != true) {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastr.error(data);
      });
      return;
    }
    this.captureService
      .getImage(this.screen.nativeElement, true, 400)
      .pipe(
        tap((img) => {
          this.img = img;
        })
      )
      .subscribe();
  }

  reset() {
    this.img = "";
  }

  save() {
    var array = this.img.split(",");
    this.reportScreenshot.image = array[1];
    this.reportScreenshot.guidanceId = this.guidanceId;
    this.reportScreenshot.name = `${this.setScenario(
      this.scenarioId
    )} / ${this.setProductName(this.productId)} / ${this.startYear.label} vs ${
      this.endYear.label
    } / ${this.getFxRate(this.currency)}`;
    this.reportScreenshot.reportName = "Price & Volume";

    this.reportScreenshotService
      .addReportScreenshot(this.reportScreenshot)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastr.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastr.error(data);
            });
        }
      });
  }

  setScenario(id: number) {
    for (let sc of Object.keys(this.scenarioList)) {
      if (id == this.scenarioList[sc].id) {
        return this.scenarioList[sc].name;
      }
    }
  }

  setProductName(id: number) {
    if (id == 0) {
      return "Total Company";
    }
    for (let sc of Object.keys(this.productList)) {
      if (id == this.productList[sc].id) {
        return this.productList[sc].name;
      }
    }
  }
}
