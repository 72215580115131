import { Observable } from "rxjs";
import { EventEmitter, Injectable } from "@angular/core";
import { LaunchTimelineData } from "@planard/@core/entities/interface/launchTimeline";
import { LaunchTimeline } from "@planard/@core/entities/launchTimeline";
import { LaunchTimelineApi } from "../api/launchTimeline.api";

@Injectable()
export class LaunchTimelineService extends LaunchTimelineData {
  onProductTimelineReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: LaunchTimelineApi) {
    super();
  }

  listAll(): Observable<LaunchTimeline[]> {
    return this.api.listAll<LaunchTimeline[]>();
  }

  list(scenarioId: number): Observable<LaunchTimeline[]> {
    return this.api.list<LaunchTimeline[]>(scenarioId);
  }

  launchTimeline(scenarioId: number, productId: number) {
    return this.api.launchTimeline<any>(scenarioId, productId);
  }

  add(launchTimeline: LaunchTimeline) {
    return this.api.add<any>(launchTimeline);
  }

  update(productTimeline: LaunchTimeline) {
    return this.api.update<any>(productTimeline);
  }

  delete(id: number) {
    return this.api.delete<any>(id);
  }
}
