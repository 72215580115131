<h1 mat-dialog-title>{{ "guidance.fxrate.add" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <mat-select
        tabindex="1"
        formControlName="fxRateId"
        name="fxRateId"
        placeholder="{{ 'guidance.fxrate.title' | translate }}"
      >
        <mat-option *ngFor="let o of fxRateList" [value]="o.id" value="option">
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="2"
        formControlName="rate"
        matInput
        type="number"
        name="rate"
        placeholder="{{ 'guidance.fxrate.rate' | translate }}"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="3" type="submit">
      {{ "guidance.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="4" type="button">
      {{ "guidance.cancel" | translate }}
    </button>
  </div>
</form>
