import { EventEmitter, Injectable } from "@angular/core";
import { PlanData } from "@planard/@core/entities/interface/plan";
import { PlanApi } from "@planard/@core/backend/api/plan.api";
import { Plan } from "@planard/@core/entities/plan";

@Injectable()
export class PlanService extends PlanData {
  onPriceReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: PlanApi) {
    super();
  }

  listPrices(scenarioId: number, approveRequestId?: number) {
    return this.api.listPrices<any>(scenarioId, approveRequestId);
  }

  costTrend(
    scenarioId: number,
    categoryId: number,
    subcategoryId: number,
    timescale: number,
    actuals: number,
    periodId: number,
    currencyId: number,
    subDepartmentId: number,
    costId: number
  ) {
    return this.api.costTrend<any>(
      scenarioId,
      categoryId,
      subcategoryId,
      timescale,
      actuals,
      periodId,
      currencyId,
      subDepartmentId,
      costId
    );
  }

  costOverview(
    scenarioId: number,
    actuals: any,
    periodId: any,
    currencyId: number,
    subDepartmentId: number
  ) {
    return this.api.costOverview<any>(
      scenarioId,
      actuals,
      periodId,
      currencyId,
      subDepartmentId
    );
  }

  update(plan: Plan) {
    return this.api.update<any>(plan);
  }

  listApproverUsersSubDepartments() {
    return this.api.listApproverUsersSubDepartments<any>();
  }
}
