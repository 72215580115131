<div fxLayout="row wrap">
  <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.gt-xs="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 85%">
            <mat-card-title>{{ "users.title" | translate }}</mat-card-title>
          </div>
          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>
        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="name">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="name"
                style="flex: 0 0 9%"
              >
                {{ "users.name" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element" style="flex: 0 0 9%">
                <editable
                  (update)="updateField(element.id, 'name')"
                  style="font-size: 11px"
                >
                  <ng-template viewMode>
                    {{ element.name }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [formControl]="getControl(element.id, 'name')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header="email">{{
                "users.email" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable
                  (update)="updateField(element.id, 'email')"
                  style="font-size: 11px"
                >
                  <ng-template viewMode>
                    {{ element.email }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [formControl]="getControl(element.id, 'email')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="approverUserId">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="approverUserId"
              >
                {{ "users.approver" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element" [style.color]="element.color">
                <editable
                  (update)="updateField(element.id, 'approverUserId')"
                  style="font-size: 11px"
                >
                  <ng-template viewMode>
                    &nbsp;{{
                      element.approversStr != null ? element.approversStr : "-"
                    }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <mat-select
                        [formControl]="getControl(element.id, 'approverUserId')"
                        placeholder="{{ element.approversStr }}"
                      >
                        <mat-option [value]="null"> No approver </mat-option>
                        <mat-option *ngFor="let o of userList" [value]="o.id">
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="departmentId">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                "users.department" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable2
                  (update)="updateField(element.id, 'subDepartmentId')"
                  style="font-size: 11px"
                >
                  <ng-template viewMode2>
                    {{ element.departmentStr ? element.departmentStr : "-" }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field>
                      <mat-select
                        placeholder="{{ element.departmentStr }}"
                        [formControl]="getControl(element.id, 'departmentId')"
                        [(ngModel)]="element.departmentId"
                        (selectionChange)="
                          subDepartmentSet($event.value);
                          element.subDepartmentId = null
                        "
                      >
                        <mat-option
                          *ngFor="let o of departmentList"
                          [value]="o.id"
                        >
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field
                      (click)="subDepartmentSet(element.departmentId)"
                    >
                      <mat-select
                        placeholder="{{ element.subDepartmentStr }}"
                        [(ngModel)]="element.subDepartmentId"
                        [formControl]="
                          getControl(element.id, 'subDepartmentId')
                        "
                      >
                        <mat-option
                          *ngFor="let o of subdepartmentDeptList"
                          [value]="o.id"
                        >
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="subDepartmentId">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="subDepartmentId"
                >{{ "users.subDepartment" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element" [style.color]="element.color">
                <editable
                  (update)="updateField(element.id, 'subDepartmentId')"
                  style="font-size: 11px"
                >
                  <ng-template viewMode>
                    {{
                      element.subDepartmentStr ? element.subDepartmentStr : "-"
                    }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field
                      (click)="subDepartmentSet(element.departmentId)"
                    >
                      <mat-select
                        placeholder="{{ element.subDepartmentStr }}"
                        [(ngModel)]="element.subDepartmentId"
                        [formControl]="
                          getControl(element.id, 'subDepartmentId')
                        "
                      >
                        <mat-option
                          *ngFor="let o of subdepartmentDeptList"
                          [value]="o.id"
                        >
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="selectedRoles">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="selectedRoles"
              >
                {{ "users.role" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element" [style.color]="element.color">
                <editable2
                  (update)="updateField(element.id, 'selectedRoles')"
                  style="font-size: 11px"
                >
                  <ng-template viewMode2>
                    {{
                      element.rolesStr ? getRolesList(element.rolesStr) : "-"
                    }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field>
                      <mat-select
                        [formControl]="getControl(element.id, 'selectedRoles')"
                        multiple
                        [(ngModel)]="element.selectedRoles"
                      >
                        <mat-option style="display: none"></mat-option>
                        <mat-option *ngFor="let o of roleList" [value]="o.id">
                          <span *ngIf="'processRoles.' + o.name as variable">{{
                            variable | translate
                          }}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="selectedCentralCosts">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="selectedCentralCosts"
              >
                {{ "users.centralCost" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <p *ngIf="!getCentral(element.selectedRoles)">-</p>

                <editable2
                  (update)="updateField(element.id, 'selectedCentralCosts')"
                  *ngIf="getCentral(element.selectedRoles)"
                  style="font-size: 11px"
                >
                  <ng-template viewMode2>
                    {{
                      element.centralCostsStr ? element.centralCostsStr : "-"
                    }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field>
                      <mat-select
                        multiple
                        [formControl]="
                          getControl(element.id, 'selectedCentralCosts')
                        "
                        [(ngModel)]="element.selectedCentralCosts"
                      >
                        <mat-option
                          *ngFor="let o of centralCostList"
                          [value]="o.id"
                        >
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="selectedProducts">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="selectedProducts"
              >
                {{ "users.product" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element" [style.color]="element.color">
                <p *ngIf="!getProduct(element.selectedRoles)">-</p>

                <editable2
                  (update)="updateField(element.id, 'selectedProducts')"
                  *ngIf="getProduct(element.selectedRoles)"
                  style="font-size: 11px"
                >
                  <ng-template viewMode2>
                    {{
                      isAll(element.selectedProducts)
                        ? "All"
                        : element.productsStr
                        ? element.productsStr
                        : "-"
                    }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field>
                      <mat-select
                        #productSelect
                        [formControl]="
                          getControl(element.id, 'selectedProducts')
                        "
                        multiple
                        [(ngModel)]="element.selectedProducts"
                      >
                        <mat-option disabled="disabled" class="filter-option">
                          <button
                            mat-raised-button
                            class="mat-primary fill text-sm"
                            (click)="selectAll(element.id, productSelect)"
                          >
                            Select All
                          </button>
                          &nbsp;
                          <button
                            mat-raised-button
                            class="mat-accent fill text-sm"
                            (click)="deselectAll(element.id)"
                          >
                            Deselect All
                          </button>
                        </mat-option>
                        <mat-option
                          *ngFor="let o of productList"
                          [value]="o.id"
                          (click)="deselectOne(element.id, o.id)"
                        >
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="link">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="isPassword"
                [ngClass]="'toRight'"
                >{{ "users.password" | translate }}</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [style.color]="row.color"
                [ngClass]="'toRight'"
              >
                <a
                  *ngIf="row.isPassword == false"
                  style="color: cornflowerblue; font-size: 11.5px"
                  target="_blank"
                  href="http://devacc.mapleyazilim.com/Account/Password?token={{
                    row.id
                  }}&returnUrl=http://dev.planard.com/"
                >
                  {{ "users.set" | translate }} &nbsp;
                </a>

                <a
                  *ngIf="row.isPassword == true"
                  style="cursor: not-allowed; font-size: 11.5px; color: gray"
                >
                  {{ "users.set" | translate }} &nbsp;
                </a>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRightReverse'">{{
                "users.actions" | translate
              }}</mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                [style.color]="row.color"
                [ngClass]="'toRightReverse'"
              >
                <button
                  *ngIf="
                    this.adminCount > 1 || !row.rolesStr?.includes('Admin')
                  "
                  (click)="onDelete(row)"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
                <button
                  *ngIf="this.adminCount < 2 && row.rolesStr?.includes('Admin')"
                  style="cursor: not-allowed; font-size: 11.5px; color: gray"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>

        <mat-card-actions>
          <button (click)="onAdd()" color="accent" mat-raised-button>
            {{ "users.add" | translate }}
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
