import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";

@Injectable()
export class ProfileApi {
  private readonly apiController: string = "profile";

  constructor(private api: HttpService) {}

  getProfile<T>(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/getProfile/${id}`);
  }

  update<T>(profile: any): Observable<any> {
    return this.api.post(`${this.apiController}/updateProfile`, profile);
  }
}
