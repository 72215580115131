<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 60%">
            <mat-card-title>{{
              "salesNarrative.title" | translate
            }}</mat-card-title>
          </div>

          <div class="div" style="width: 20%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="requestId"
                placeholder="{{
                  'salesNarrative.narrativeRequest' | translate
                }}"
              >
                <mat-option
                  *ngFor="let sale of saleRequestList"
                  [value]="sale.id"
                  value="option"
                >
                  <strong>{{ sale.firstPlanningCycleName }}</strong
                  >&nbsp;{{ sale.firstYear }}
                  &nbsp;&nbsp;&nbsp;vs&nbsp;&nbsp;&nbsp;
                  <strong>{{ sale.secondPlanningCycleName }}</strong
                  >&nbsp;{{ sale.secondYear }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="div" style="width: 15%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="productId"
                placeholder="{{ 'salesNarrative.product' | translate }}"
              >
                <mat-option
                  *ngFor="let o of productList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="div" style="width: 5%">
            <div style="width: 85%">
              <button color="accent" mat-button (click)="getTableData()">
                {{ "salesNarrative.run" | translate }}
              </button>
            </div>
          </div>
        </div>

        <div style="width: 45%" *ngIf="selected == true">
          <mat-card style="background: transparent">
            <mat-card-content>
              <span style="font-size: smaller"
                >{{ "salesNarrative.amountToExplain" | translate }} :
                {{ formatNumber(remaining, decimalFormat, numberFormat) }}
                TL</span
              ><br />
              <!--<span style="font-size: smaller;">{{ 'salesNarrative.amountExplainedRequest' | translate }} : {{formatNumber(genelRem, decimalFormat, numberFormat)}} TL</span><br>-->
              <span style="font-size: smaller"
                >{{ "salesNarrative.amountExplainedProduct" | translate }} :
                {{ formatNumber(dataRemaining, decimalFormat, numberFormat) }}
                TL</span
              >
            </mat-card-content>
          </mat-card>
        </div>

        <div class="responsive-table">
          <mat-table
            [dataSource]="dataSource"
            matSort
            *ngIf="isSubmit == false && isScreenValid == true"
          >
            <ng-container matColumnDef="amount">
              <mat-header-cell *matHeaderCellDef mat-sort-header="amount">
                {{ "salesNarrative.amount" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'amount')">
                  <ng-template viewMode>
                    {{
                      formatNumber(element.amount, decimalFormat, numberFormat)
                    }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [formControl]="getControl(element.id, 'amount')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="impactArea">
              <mat-header-cell *matHeaderCellDef mat-sort-header="impactArea">{{
                "salesNarrative.impactArea" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.impactArea }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="category">
              <mat-header-cell *matHeaderCellDef mat-sort-header="category">{{
                "salesNarrative.category" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'category')">
                  <ng-template viewMode>
                    {{ element.category }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <mat-select
                        [(ngModel)]="element.impactArea"
                        [formControl]="getControl(element.id, 'impactArea')"
                      >
                        <mat-option [value]="'Price'">{{
                          "entryScreens.price" | translate
                        }}</mat-option>
                        <mat-option [value]="'Volume'">{{
                          "entryScreens.volume" | translate
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="element.impactArea == 'Price'">
                      <mat-select
                        [formControl]="getControl(element.id, 'category')"
                      >
                        <mat-option *ngFor="let o of priceCatList" [value]="o">
                          <span
                            *ngIf="
                              'salesNarrativePriceCategories.' + o as variable
                            "
                            >{{ variable | translate }}</span
                          >
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="element.impactArea == 'Volume'">
                      <mat-select
                        [formControl]="getControl(element.id, 'category')"
                      >
                        <mat-option *ngFor="let o of volumeCatList" [value]="o">
                          <span
                            *ngIf="
                              'salesNarrativeVolumeCategories.' + o as variable
                            "
                            >{{ variable | translate }}</span
                          >
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="productId">
              <mat-header-cell *matHeaderCellDef mat-sort-header="productId">{{
                "salesNarrative.product" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'productId')">
                  <ng-template viewMode>
                    {{
                      getProduct(element.productId)
                        ? getProduct(element.productId)
                        : "-"
                    }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <mat-select
                        [formControl]="getControl(element.id, 'productId')"
                        [(ngModel)]="element.productId"
                      >
                        <mat-option
                          *ngFor="let o of productsList"
                          [value]="o.id"
                        >
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header="description">
                {{ "salesNarrative.description" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'description')">
                  <ng-template viewMode>
                    {{ element.description }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [formControl]="getControl(element.id, 'description')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
                {{ "salesNarrative.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
                <button
                  (click)="onDelete(row)"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-table
            [dataSource]="dataSource"
            matSort
            *ngIf="isSubmit == true || isScreenValid == false"
          >
            <ng-container matColumnDef="amount">
              <mat-header-cell *matHeaderCellDef mat-sort-header="amount">
                {{ "salesNarrative.amount" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ formatNumber(element.amount, decimalFormat, numberFormat) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="impactArea">
              <mat-header-cell *matHeaderCellDef mat-sort-header="impactArea">{{
                "salesNarrative.impactArea" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.impactArea }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="category">
              <mat-header-cell *matHeaderCellDef mat-sort-header="category">{{
                "salesNarrative.category" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.category }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="productId">
              <mat-header-cell *matHeaderCellDef mat-sort-header="productId">{{
                "salesNarrative.product" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{
                  getProduct(element.productId)
                    ? getProduct(element.productId)
                    : "-"
                }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header="description">
                {{ "salesNarrative.description" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.description }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
                {{ "salesNarrative.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
                <button
                  *ngIf="isSubmit == true && isScreenValid == true"
                  (click)="
                    toastrService.warning(
                      'This Narrative Request is already Submitted.'
                    )
                  "
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>

                <button
                  *ngIf="isScreenValid == false"
                  (click)="
                    toastrService.warning('Sales Narrative is out of timeline.')
                  "
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <table *ngIf="selected == false" style="min-height: 48px">
            <tr>
              <td
                style="text-align: center"
                [attr.colspan]="displayedColumns.length"
              >
                {{ "salesNarrative.must" | translate }}
              </td>
            </tr>
          </table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>

        <mat-card-actions>
          <button
            *ngIf="isSubmit == false && isScreenValid == true"
            (click)="addDialog()"
            color="accent"
            mat-raised-button
          >
            {{ "salesNarrative.add" | translate }}
          </button>
          <button
            *ngIf="isSubmit == false && isScreenValid == true"
            (click)="submit()"
            color="accent"
            mat-raised-button
          >
            {{ "salesNarrative.submit" | translate }}
          </button>

          <button
            *ngIf="isSubmit == true && isScreenValid == true"
            (click)="outToastr('salesNarrative.submitted')"
            color="accent"
            mat-raised-button
          >
            {{ "salesNarrative.add" | translate }}
          </button>
          <button
            *ngIf="isSubmit == true && isScreenValid == true"
            (click)="outToastr('salesNarrative.submitted')"
            color="accent"
            mat-raised-button
          >
            {{ "salesNarrative.submit" | translate }}
          </button>

          <button
            *ngIf="isScreenValid == false"
            (click)="outToastr('entryScreens.salesNarrativeTask')"
            color="accent"
            mat-raised-button
          >
            {{ "salesNarrative.add" | translate }}
          </button>
          <button
            *ngIf="isScreenValid == false"
            (click)="outToastr('entryScreens.salesNarrativeTask')"
            color="accent"
            mat-raised-button
          >
            {{ "salesNarrative.submit" | translate }}
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
