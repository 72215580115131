import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { Faq } from "@planard/@core/entities/faq";
import { FaqService } from "@planard/@core/backend/service/faq.service";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./faq-answer.component.html",
})
export class FaqAnswerComponent {
  period: GuidancePlanningPeriod;

  form: FormGroup = new FormGroup({
    answer: new FormControl("", [Validators.required, removeSpaces]),
  });
  options: FormGroup;
  faq: Faq;

  constructor(
    public dialogRef: MatDialogRef<FaqAnswerComponent>,
    public faqService: FaqService,
    public toastrService: ToastrService,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.faq = data.row;
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {}

  save() {
    if (this.form.valid) {
      this.faq.answer = this.form.get("answer").value;
      this.faqService.update(this.faq).subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.toastrService.success(backendResult.message);
          this.dialogRef.close(this.period);
        } else {
          this.toastrService.error(backendResult.message);
        }
      });
    } else {
      this.toastrService.warning("Please fill all required fields.");
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
