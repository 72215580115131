import { EventEmitter, Injectable } from "@angular/core";
import { Users } from "../../entities/user";
import { ApprovalFlowData } from "../../entities/interface/approval-flow";
import { ApprovalFlowApi } from "../api/approval-flow.api";
import { Observable } from "rxjs";
import { ApprovalFlow } from "@planard/@core/entities/approvalFlow";

@Injectable()
export class ApprovalFlowService extends ApprovalFlowData {
  onApprovalFlowUsersReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ApprovalFlowApi) {
    super();
  }

  update(approvalFlow: ApprovalFlow) {
    return this.api.update<any>(approvalFlow);
  }

  listApprovers(): Observable<Users[]> {
    return this.api.listApprovers<Users[]>();
  }
}
