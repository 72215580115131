import { Directive, HostListener } from "@angular/core";
import { EditableComponent } from "../editable.component";

@Directive({
  selector: "[editableOnEnter]",
})
export class EditableOnEnterDirective {
  constructor(private editable: EditableComponent) {}

  @HostListener("keydown.enter")
  onEnter() {
    //this.editable.update.next(true);
    this.editable.toViewMode();
  }
}
