import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CostType } from "@planard/@core/entities/costType";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { ToastrService } from "ngx-toastr";
import { Company } from "@planard/@core/entities/company";
import { EnumCostType } from "@planard/@core/enums/EnumCostType";
import { SubCategory } from "@planard/@core/entities/subcategory";
import { Category } from "@planard/@core/entities/category";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "@planard/auth/services/auth.service";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { removeSpaces } from "@planard/common/formValidator";
@Component({
  templateUrl: "./cost-type-add.component.html",
})
export class CostTypeAddComponent implements OnInit {
  lifeSpan: boolean = false;
  category: number;
  categoryList: Category[];
  wholesubCList: SubCategory[];
  subCategoryList: SubCategory[] = [];
  costTypeList: EnumCostType[] = EnumCostType.values;
  costType: CostType;
  companies: Company[];
  guidanceId: number;
  package: string;
  cat: any;

  form: FormGroup = new FormGroup({
    name: new FormControl("", [
      Validators.required,
      Validators.maxLength(20),
      removeSpaces,
    ]),
    categoryId: new FormControl("", [Validators.required]),
    subCategoryId: new FormControl("", [Validators.required]),
    costType: new FormControl("", [Validators.required]),
    lifeSpan: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  constructor(
    public costService: CostService,
    public toastrService: ToastrService,
    public fb: FormBuilder,
    public translate: TranslateService,
    public authService: AuthService,
    private guidanceService: GuidanceService,
    public dialogRef: MatDialogRef<CostTypeAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CostType
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
    setTimeout(() => {
      this.package = this.authService.getCompanyPackage();
    }, 150);
  }

  ngOnInit() {
    this.costService.listCategories().subscribe((result) => {
      this.categoryList = result;
    });
    this.costService.listSubcategories().subscribe((result) => {
      this.wholesubCList = result;
    });
    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidanceId = result.id;
    });
    this.form.patchValue({ lifeSpan: 0 });
  }

  save() {
    if (this.package == "basic")
      this.form.controls["costType"].setValue(EnumCostType.DEPARMENTAL);

    if (this.form.valid) {
      this.costType = this.form.value;
      if (this.package != "basic") {
        if (this.costType.costType == null) {
          this.translate.get("common.formNotValid").subscribe((data: any) => {
            this.toastrService.error(data);
          });
          return;
        }
      }
      this.costService.add(this.costType).subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.startCycleAuto();
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.dialogRef.close(this.costType);
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancelClick(): void {
    this.dialogRef.close(false);
  }

  setSubcategory() {
    this.category = this.cat;
    this.subCategoryList.length = 0;
    for (let sb of Object.keys(this.wholesubCList)) {
      if (
        this.wholesubCList[sb].categoryId != null &&
        this.wholesubCList[sb].categoryId === this.category
      ) {
        this.subCategoryList.push(this.wholesubCList[sb]);
      }
    }
  }

  assetsSet() {
    this.costType = this.form.get("costType").value;
    if (this.costType == EnumCostType.ASSETS) {
      this.lifeSpan = true;
      this.cat = 6;
      this.setSubcategory();
    } else {
      this.lifeSpan = false;
      this.cat = null;
      this.setSubcategory();
    }
  }

  startCycleAuto() {
    let body = { guidanceId: this.guidanceId };
    this.guidanceService.startGuidance(body).subscribe((result) => {
      if (result.isSuccess) {
        console.log("guidance started!!!");
      } else {
        console.log("result: " + result.message);
        console.log("guidance cannot started!!!");
      }
    });
  }
}
