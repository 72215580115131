<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 85%">
            <mat-card-title>{{ "task.title" | translate }}</mat-card-title>
          </div>
          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>

        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="userName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "task.user" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let rowIndex = index">
                <span>
                  {{ row.userName }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "task.task" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span *ngIf="'enums.tasks.' + row.name as variable">
                  {{
                    (row.name | enumDisplayValue : "EnumTask")
                      ? (variable | translate)
                      : row.name
                  }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "task.startDate" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span>
                  {{ row.startDate | date : "dd/MM/yyyy" }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "task.endDate" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let rowIndex = index">
                <span>
                  {{ row.endDate | date : "dd/MM/yyyy" }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="stage">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "task.status" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let rowIndex = index">
                <span *ngIf="'enums.status.' + row.status as variable">
                  {{ variable | translate }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="days">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "task.days" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let rowIndex = index">
                <span>
                  {{ getDays(row.endDate) }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "task.actionStatus" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let rowIndex = index">
                <span *ngIf="row.lockDate == null">
                  <!--{{getStage(row.startDate, row.endDate)}}-->
                  -
                </span>
                <span *ngIf="row.lockDate != null">
                  {{ val1 }} {{ row.unlockDate | date : "dd/MM/yyyy" }}
                  {{ val2 }} <br />
                  {{ val3 }} {{ row.lockDate | date : "dd/MM/yyyy" }} {{ val4 }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRightReverse'">
                {{ "task.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'toRightReverse'">
                <!-- UNLOCK -->
                <button
                  *ngIf="
                    (row.status == 'DUE' || row.status == 'COMPLETED') &&
                    (row.lockDate == null || isDueAgain(row.lockDate))
                  "
                  (click)="addLockout(row)"
                  autofocus
                  title="{{ 'task.open' | translate }}"
                  style="color: royalblue"
                  mat-icon-button
                >
                  <mat-icon>lock_open</mat-icon>
                </button>
                <!-- LOCK -->
                <button
                  *ngIf="
                    row.status != 'DUE' &&
                    row.lockDate != null &&
                    !isDueAgain(row.lockDate)
                  "
                  (click)="lock(row)"
                  autofocus
                  title="{{ 'task.lock' | translate }}"
                  style="color: rgb(218, 32, 32)"
                  mat-icon-button
                >
                  <mat-icon>lock_outline</mat-icon>
                </button>
                <!-- DISABLED -->
                <button
                  *ngIf="
                    row.status != 'DUE' &&
                    row.status != 'COMPLETED' &&
                    row.lockDate == null
                  "
                  autofocus
                  style="color: rgb(170, 169, 169)"
                  mat-icon-button
                  disabled
                >
                  <mat-icon>lock_open</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
