import { ProductsApi } from "../api/products.api";
import { Observable } from "rxjs";
import { EventEmitter, Injectable } from "@angular/core";
import { ProductsData } from "../../entities/interface/products";
import { Products } from "../../entities/product";
import { ProductForm } from "@planard/@core/entities/productForm";
import { ProductChannel } from "@planard/@core/entities/productChannel";

@Injectable()
export class ProductsService extends ProductsData {
  onProductReload: EventEmitter<any> = new EventEmitter<any>();
  onProductFormReload: EventEmitter<ProductForm> =
    new EventEmitter<ProductForm>();

  constructor(private api: ProductsApi) {
    super();
  }

  listAll(): Observable<Products[]> {
    return this.api.listAll<Products[]>();
  }

  listUserProducts(): Observable<Products[]> {
    return this.api.listUserProducts<Products[]>();
  }

  add(product: Products) {
    return this.api.add<any>(product);
  }

  update(product: Products) {
    return this.api.update<any>(product);
  }

  delete(id: number) {
    return this.api.delete<any>(id);
  }

  listAllForms(productId: number): Observable<ProductForm[]> {
    return this.api.listAllForms<ProductForm[]>(productId);
  }

  addForm(productForm: ProductForm) {
    return this.api.addForm<any>(productForm);
  }

  updateForm(productForm: ProductForm) {
    return this.api.updateForm<any>(productForm);
  }

  deleteForm(productFormId: number) {
    return this.api.deleteForm<any>(productFormId);
  }

  listAllChannels(productId: number): Observable<ProductChannel[]> {
    return this.api.listAllChannels<ProductChannel[]>(productId);
  }

  addChannel(productChannel: ProductChannel) {
    return this.api.addChannel<any>(productChannel);
  }

  updateChannel(productChannel: ProductChannel) {
    return this.api.updateChannel<any>(productChannel);
  }

  deleteChannel(productChannelId: number) {
    return this.api.deleteChannel<any>(productChannelId);
  }
}
