<h1 mat-dialog-title>{{ "productFormAdd.title" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="pfForm">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        formControlName="form"
        matInput
        name="form"
        placeholder="{{ 'productFormAdd.name' | translate }}"
        tabindex="1"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        formControlName="conversionRate"
        matInput
        min="0"
        name="conversionRate"
        placeholder="{{ 'productFormAdd.conversionRate' | translate }}"
        tabindex="1"
        type="number"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="2">
      {{ "productFormAdd.save" | translate }}
    </button>
    <button (click)="cancelClick()" mat-button tabindex="-1" type="button">
      {{ "productFormAdd.cancel" | translate }}
    </button>
  </div>
</form>
