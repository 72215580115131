import { Component } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { LoaderService } from "./@core/backend/service/loader.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationStart, Router } from "@angular/router";
import { ReloadService } from "./@core/backend/service/reload.service";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "./auth/services/auth.service";

export let browserRefresh = false;
export let windowLocation = null;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  isLoading: BehaviorSubject<boolean> = this.loader.isLoading;
  subscription: Subscription;

  constructor(
    private loader: LoaderService,
    public translate: TranslateService,
    public router: Router,
    public authService: AuthService,
    public reloadService: ReloadService,
    public toastr: ToastrService
  ) {
    let defaultLang = this.authService.getCompanyLanguage()
      ? this.authService.getCompanyLanguage()
      : "en";

    translate.addLangs(["en", "tr"]);
    translate.setDefaultLang(defaultLang);

    this.subscription = router.events.subscribe((event) => {
      if (
        event instanceof NavigationStart &&
        event.url.split("/")[1] != "auth"
      ) {
        browserRefresh = !router.navigated;
        windowLocation = window.location.href;
      }
    });
  }
}
