import { Injectable, EventEmitter } from "@angular/core";
import { DepartmentApi } from "@planard/@core/backend/api/department.api";
import { Department } from "@planard/@core/entities/department";
import { SubDepartment } from "@planard/@core/entities/subDepartment";

@Injectable()
export class DepartmentService {
  onDepartmentReload: EventEmitter<any> = new EventEmitter<any>();
  onSubDepartmentReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: DepartmentApi) {}

  listAll(page?: number, pageSize?: number) {
    return this.api.listAll<Department[]>(page, pageSize);
  }

  add(department: Department) {
    return this.api.add<any>(department);
  }

  update(department: Department) {
    return this.api.update<any>(department);
  }

  delete(departmentId: number) {
    return this.api.delete<any>(departmentId);
  }

  listAllSub() {
    return this.api.listAllSub<SubDepartment[]>();
  }

  addSub(subdepartment: SubDepartment) {
    return this.api.addSub<any>(subdepartment);
  }

  updateSub(subdepartment: SubDepartment) {
    return this.api.updateSub<any>(subdepartment);
  }

  deleteSub(subdepartmentId: number) {
    return this.api.deleteSub<any>(subdepartmentId);
  }
}
