<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card *ngIf="guidanceIsStart == true">
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 85%">
            <mat-card-title>{{ "scenario.title" | translate }}</mat-card-title>
          </div>
          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>

        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header="name">
                {{ "scenario.name" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.name }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="impactedProducts">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="impactedProducts"
                >{{ "scenario.impactedProducts" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                {{
                  element.impactedProductsStr != ""
                    ? element.impactedProductsStr
                    : "-"
                }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="impactArea">
              <mat-header-cell *matHeaderCellDef mat-sort-header="impactArea">{{
                "scenario.impactArea" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="'impactArea.' + element.impactArea as variable">{{
                  element.name == "Base Scenario" ? "-" : (variable | translate)
                }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header="description">
                {{ "scenario.description" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.description }}
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="guidanceIsStart == false">
      <mat-card-content style="min-height: 175px">
        <div>
          <div class="div" style="width: 50%">
            <mat-card-title>{{ "scenario.title" | translate }}</mat-card-title>
          </div>
        </div>
        <span style="width: 60%; float: right; color: rgb(103, 101, 105)">{{
          "dashboard.noGuidance" | translate
        }}</span>
      </mat-card-content>
    </mat-card>
  </div>
</div>
