<h1 mat-dialog-title>{{ "guidance.inflationRate.edit" | translate }}</h1>
<div mat-dialog-content>
  <div class="responsive-table">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="year">
        <mat-header-cell *matHeaderCellDef mat-sort-header="year">
          {{ "guidance.inflationRate.year" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'year')">
            <ng-template viewMode>
              {{ element.year }}
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <mat-select
                  [formControl]="getControl(element.id, 'year')"
                  [(ngModel)]="element.year"
                >
                  <mat-option
                    *ngFor="let o of yearList"
                    [value]="o"
                    value="option"
                  >
                    {{ o }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="rate">
          {{ "guidance.inflationRate.infrate" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'rate')">
            <ng-template viewMode>
              {{ formatNumber(element.rate, decimalFormat, numberFormat) }}%
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <input
                  matInput
                  [formControl]="getControl(element.id, 'rate')"
                  focusable
                  editableOnEnter
                />
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
          {{ "guidance.actions" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
          <button
            (click)="onDelete(row)"
            autofocus
            color="warn"
            mat-icon-button
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>

    <table *ngIf="IsEmpty == true" style="min-height: 48px">
      <tr>
        <td style="text-align: center" [attr.colspan]="displayedColumns.length">
          No Records Found!
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25]"
    ></mat-paginator>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="addDialog()" mat-button tabindex="2">
    {{ "guidance.add" | translate }}
  </button>
  <button (click)="cancel()" mat-button type="button">
    {{ "guidance.close" | translate }}
  </button>
</div>
