import { EventEmitter, Injectable } from "@angular/core";
import { Channel } from "@planard/@core/entities/channel";
import { ChannelApi } from "@planard/@core/backend/api/channel.api";
import { ChannelData } from "@planard/@core/entities/interface/channel";

@Injectable()
export class ChannelService extends ChannelData {
  onChannelReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ChannelApi) {
    super();
  }

  listAll() {
    return this.api.listAll<Channel[]>();
  }

  add(channel: Channel) {
    return this.api.add<any>(channel);
  }

  update(channel: Channel) {
    return this.api.update<any>(channel);
  }

  delete(channelId: number) {
    return this.api.delete<any>(channelId);
  }
}
