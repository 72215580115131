import { Observable } from "rxjs";
import { UserTask } from "../user-task";

export abstract class UserTaskData {
  abstract getUserTasks(): Observable<any[]>;

  abstract getAllUserTasks(): Observable<any[]>;

  abstract update(userTask: UserTask): Observable<any>;
}
