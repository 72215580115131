import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidanceInflationRate } from "@planard/@core/entities/guidanceInflationRate";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./inflation-rate-add.component.html",
})
export class InflationRateAddComponent {
  inflationRate: GuidanceInflationRate;

  yearList = [
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear(),
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 1,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 2,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 3,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 4,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 5,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 6,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 7,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 8,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 9,
  ];

  form: FormGroup = new FormGroup({
    year: new FormControl("", [Validators.required]),
    rate: new FormControl("", [Validators.required]),
  });
  options: FormGroup;
  guidanceId: number;

  constructor(
    public dialogRef: MatDialogRef<InflationRateAddComponent>,
    public guidanceService: GuidanceService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.guidanceId = data.guidanceId;
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {}

  save() {
    if (this.form.valid) {
      this.inflationRate = this.form.value;
      this.inflationRate.guidanceId = this.guidanceId;
      this.guidanceService
        .addInflationRate(this.inflationRate)
        .subscribe((result) => {
          if (result.isSuccess) {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.success(data);
              });
            this.dialogRef.close(this.inflationRate);
          } else {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.error(data);
              });
          }
        });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
