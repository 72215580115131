import { EventEmitter, Injectable } from "@angular/core";
import { ReportScreenshotData } from "@planard/@core/entities/interface/reportScreenshot";
import { ReportScreenshotApi } from "../api/reportScreenshot.api";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";

@Injectable()
export class ReportScreenshotService extends ReportScreenshotData {
  onReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ReportScreenshotApi) {
    super();
  }

  listReportScreenShot(guidanceId: any) {
    return this.api.listReportScreenshot<ReportScreenshot[]>(guidanceId);
  }

  addReportScreenshot(screenshot: ReportScreenshot) {
    return this.api.addReportScreenshot<any>(screenshot);
  }

  delete(screenshotId: number) {
    return this.api.delete<any>(screenshotId);
  }
}
