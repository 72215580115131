<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div>
          <div class="div" style="width: 39%">
            <mat-card-title>
              {{ "reports.priceVolume" | translate }} <br />
              <span style="font-size: 15px"
                >{{ companyNumberFormat }} {{ companyCurrency }}</span
              >
            </mat-card-title>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 75%">
              <mat-select
                (selectionChange)="setScenarioLists()"
                [(ngModel)]="scenarioId"
                [formControl]="scenarioControl"
                placeholder="{{ 'reports.scenario' | translate }}"
                required
              >
                <mat-option
                  *ngFor="let o of scenarioList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="scenarioControl.hasError('required')">{{
                "reports.scenarioChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 75%">
              <mat-select
                [(ngModel)]="productId"
                placeholder="{{ 'reports.product' | translate }}"
                [formControl]="productControl"
                required
              >
                <mat-option [value]="0" value="option">{{
                  "reports.totalCompany" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of productList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="productControl.hasError('required')">{{
                "reports.productChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="startYear"
                placeholder="{{ 'reports.period1' | translate }}"
                [formControl]="period1Control"
                required
              >
                <mat-optgroup
                  *ngFor="let group of periodGroup"
                  [label]="group.name"
                >
                  <mat-option
                    *ngFor="let period of group.period"
                    [value]="period"
                  >
                    {{ period.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-error *ngIf="period1Control.hasError('required')">{{
                "reports.period1Choose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="endYear"
                placeholder="{{ 'reports.period2' | translate }}"
                [formControl]="period2Control"
                required
              >
                <mat-optgroup
                  *ngFor="let group of periodGroup"
                  [label]="group.name"
                >
                  <mat-option
                    *ngFor="let period of group.period"
                    [value]="period"
                  >
                    {{ period.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-error *ngIf="period2Control.hasError('required')">{{
                "reports.period2Choose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="currency"
                placeholder="{{ 'reports.currency' | translate }}"
                [formControl]="currencyControl"
                required
              >
                <mat-option [value]="0" value="option">{{
                  "reports.defaultCurrency" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of guidanceFxRateList"
                  [value]="o.fxRateId"
                  value="option"
                >
                  {{ getFxRate(o.fxRateId) }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="currencyControl.hasError('required')">{{
                "reports.currencyChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <div style="width: 75%">
              <button color="accent" mat-button (click)="isValid()">
                {{ "reports.run" | translate }}
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />

        <div>
          <div class="div" style="width: 15%"></div>
          <div class="div" style="width: 70%" #screen>
            <canvas
              *ngIf="isRun == true"
              baseChart
              [datasets]="barChartData"
              [plugins]="waterFallPlugin"
              [labels]="reportData.chartLabels"
              [options]="barChartOptions"
              [chartType]="barChartType"
              [colors]="barChartColors"
            >
            </canvas>
          </div>
        </div>
        <span *ngIf="isRun != true" style="width: 60%; float: right">{{
          "reports.seeChart" | translate
        }}</span>

        <br />
        <br />
        <br />

        <button (click)="take()" color="accent" mat-raised-button>
          <mat-icon>camera_alt</mat-icon>&nbsp;{{
            "reports.take" | translate
          }}</button
        >&nbsp;
        <button *ngIf="img" (click)="reset()" color="accent" mat-raised-button>
          <mat-icon>delete_sweep</mat-icon>&nbsp;{{
            "reports.reset" | translate
          }}</button
        >&nbsp;
        <button *ngIf="img" (click)="save()" color="accent" mat-raised-button>
          <mat-icon>save</mat-icon>&nbsp;{{ "reports.save" | translate }}
        </button>

        <br />
        <br />
        <img *ngIf="img" src="{{ img }}" style="width: 50%; height: 50%" />
      </mat-card-content>
    </mat-card>

    <!--<mat-card *ngIf="guidanceIsStart==null">
          <mat-card-content style="min-height: 200px;">

            <div>
              <div class="div" style="width: 50%;">
                <mat-card-title> Price & Volume </mat-card-title>
              </div>
            </div>

            <br>
            <br>

            <span *ngIf="isRun!=true" style="width: 60%; float: right;">Guidance not started yet.</span>
          </mat-card-content>
      </mat-card>-->
  </div>
</div>
