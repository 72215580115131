import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { Forecast } from "@planard/@core/entities/forecast";

@Injectable()
export class ForecastApi {
  private readonly apiController: string = "forecast";

  constructor(private api: HttpService) {}

  listPrices<T>(
    scenarioId: number,
    approveRequestId?: number
  ): Observable<any> {
    const endpointUrl = approveRequestId
      ? `${this.apiController}/listPrices/${scenarioId}/?approveRequestId=${approveRequestId}`
      : `${this.apiController}/listPrices/${scenarioId}`;
    return this.api.get(endpointUrl);
  }

  growthDrivers<T>(
    count: number,
    scenarioId: number,
    granularity: number,
    startActuals: number,
    startPeriods: number,
    endActuals: number,
    endPeriods: number,
    indicator: number,
    currencyId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listGrowthDrivers/${count}/${scenarioId}/${granularity}/${startActuals}/${startPeriods}/${endActuals}/${endPeriods}/${indicator}/${currencyId}`
    );
  }

  salesTrend<T>(
    scenarioId: number,
    granularity: number,
    parentId: any,
    subId: number,
    indicator: number,
    timescale: number,
    actuals: number,
    period: number,
    currencyId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listSalesTrend/${scenarioId}/${granularity}/${parentId}/${subId}/${indicator}/${timescale}/${actuals}/${period}/${currencyId}`
    );
  }

  update<T>(forecast: Forecast): Observable<any> {
    return this.api.post(`${this.apiController}/update`, forecast);
  }

  listForecastBusinessUnits<T>(scenarioId: any): Observable<any> {
    return this.api.get(
      `${this.apiController}/listForecastBusinessUnits/${scenarioId}`,
      undefined
    );
  }

  listForecastChannels<T>(scenarioId: any): Observable<any> {
    return this.api.get(
      `${this.apiController}/listForecastChannels/${scenarioId}`,
      undefined
    );
  }

  listForecastProductFamilies<T>(scenarioId: any): Observable<any> {
    return this.api.get(
      `${this.apiController}/listForecastProductFamilies/${scenarioId}`,
      undefined
    );
  }

  listForecastProducts<T>(scenarioId: any): Observable<any> {
    return this.api.get(
      `${this.apiController}/listForecastProducts/${scenarioId}`,
      undefined
    );
  }

  listProductFamiliesByBusinessUnit<T>(
    scenarioId: any,
    businessUnitId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listProductFamiliesByBusinessUnit/${scenarioId}/${businessUnitId}`,
      undefined
    );
  }

  listProductsByProductFamily<T>(
    scenarioId: any,
    productFamilyId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listProductsByProductFamily/${scenarioId}/${productFamilyId}`,
      undefined
    );
  }

  listFormsByProduct<T>(scenarioId: any, productId: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/listFormsByProduct/${scenarioId}/${productId}`,
      undefined
    );
  }

  listBusinessUnitsByChannel<T>(
    scenarioId: any,
    channelId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listBusinessUnitsByChannel/${scenarioId}/${channelId}`,
      undefined
    );
  }
}
