import { Injectable, OnDestroy } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs/Subject";

@Injectable()
export class CustomMatPaginatorIntl
  extends MatPaginatorIntl
  implements OnDestroy
{
  unsubscribe: Subject<void> = new Subject<void>();
  OF_LABEL = "of";

  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.subscribe(() => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getAndInitTranslations() {
    this.translate
      .get([
        "paginator.items_per_page_label",
        "paginator.next_page_label",
        "paginator.previous_page_label",
      ])
      .subscribe((translation) => {
        this.itemsPerPageLabel = translation["paginator.items_per_page_label"];
        this.nextPageLabel = translation["paginator.next_page_label"];
        this.previousPageLabel = translation["paginator.previous_page_label"];
        this.changes.next();
      });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return this.translate.instant("paginator.range_page_label_1", { length });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return this.translate.instant("paginator.range_page_label_2", {
      startIndex: startIndex + 1,
      endIndex,
      length,
    });
  };
}
