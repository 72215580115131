import { BaseEntity } from "@planard/@core/base/BaseEntity";

export class RiskOpportunities extends BaseEntity {
  public description?: string;
  public impactArea?: any;
  public impactAreaStr?: any;
  public typeOfImpact?: string;
  public impactAmount?: number;
  public likeliHood?: string;
  public dayOfCertainty?: Date;
  public guidanceId?: any;
}
