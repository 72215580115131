import { Component, OnInit } from "@angular/core";
import { ProductFamilyService } from "@planard/@core/backend/service/product-family.service";
import { BusinessUnit } from "@planard/@core/entities/businessUnit";
import { MatDialogRef } from "@angular/material/dialog";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";
@Component({
  templateUrl: "./product-family-add.component.html",
})
export class ProductFamilyAddComponent implements OnInit {
  businessUnitList: BusinessUnit[];
  form: FormGroup;

  constructor(
    public fb: FormBuilder,
    public productFamilyService: ProductFamilyService,
    public businessUnitService: BusinessUnitService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<ProductFamilyAddComponent>
  ) {
    this.form = fb.group({
      name: new FormControl("", [Validators.required, removeSpaces]),
      businessUnitId: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    this.businessUnitService.listAll().subscribe((data) => {
      this.businessUnitList = data;
    });
  }

  save() {
    if (this.form.valid) {
      let productFamily = this.form.value;
      this.productFamilyService
        .add(productFamily)
        .subscribe((backendResult) => {
          if (backendResult.isSuccess == true) {
            this.dialogRef.close(productFamily);
            this.translate
              .get(`messages.${backendResult.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.success(data);
              });
          } else {
            this.translate
              .get(`messages.${backendResult.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.error(data);
              });
          }
        });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
