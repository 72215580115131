import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";
import { Products } from "../../entities/product";
import { ProductForm } from "@planard/@core/entities/productForm";
import { ProductChannel } from "@planard/@core/entities/productChannel";

@Injectable()
export class ProductsApi {
  private readonly apiController: string = "product";

  constructor(private api: HttpService) {}

  listAll<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAll`, undefined);
  }

  listUserProducts<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listUserProducts`, undefined);
  }

  add<T>(product: Products): Observable<any> {
    return this.api.post(`${this.apiController}/addProduct`, product);
  }

  update<T>(product: any): Observable<any> {
    return this.api.post(`${this.apiController}/updateProduct`, product);
  }

  delete<T>(id: number): Observable<any> {
    var json = { id: id };
    return this.api.post(`${this.apiController}/deleteProduct`, json);
  }

  listAllForms<T>(productId: number): Observable<any> {
    return this.api.get(`${this.apiController}/listAllForms/${productId}`);
  }

  addForm<T>(productForm: ProductForm): Observable<any> {
    return this.api.post(`${this.apiController}/addForm`, productForm);
  }

  updateForm<T>(productForm: ProductForm): Observable<any> {
    return this.api.post(`${this.apiController}/updateForm`, productForm);
  }

  deleteForm<T>(id: number): Observable<any> {
    var json = { id: id };
    return this.api.post(`${this.apiController}/deleteForm`, json);
  }

  listAllChannels<T>(productId: number): Observable<any> {
    return this.api.get(`${this.apiController}/listAllChannels/${productId}`);
  }

  addChannel<T>(productForm: ProductChannel): Observable<any> {
    return this.api.post(`${this.apiController}/addChannel`, productForm);
  }

  updateChannel<T>(productForm: ProductChannel): Observable<any> {
    return this.api.post(`${this.apiController}/updateChannel`, productForm);
  }

  deleteChannel<T>(id: number): Observable<any> {
    var json = { id: id };
    return this.api.post(`${this.apiController}/deleteChannel`, json);
  }
}
