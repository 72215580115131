import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { Plan } from "@planard/@core/entities/plan";

@Injectable()
export class PlanApi {
  private readonly apiController: string = "costplan";

  constructor(private api: HttpService) {}

  listPrices<T>(
    scenarioId: number,
    approveRequestId?: number
  ): Observable<any> {
    const endpointUrl = approveRequestId
      ? `${this.apiController}/listPrices/${scenarioId}/?approveRequestId=${approveRequestId}`
      : `${this.apiController}/listPrices/${scenarioId}`;
    return this.api.get(endpointUrl);
  }

  costTrend<T>(
    scenarioId: number,
    categoryId: number,
    subcategoryId: number,
    timescale: number,
    actuals: number,
    periodId: number,
    currencyId: number,
    subDepartmentId: number,
    costId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listCostTrend/${scenarioId}/${categoryId}/${subcategoryId}/${timescale}/${actuals}/${periodId}/${currencyId}/${subDepartmentId}/${costId}`
    );
  }

  costOverview<T>(
    scenarioId: number,
    actuals: any,
    periodId: any,
    currencyId: number,
    subDepartmentId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listCostOverview/${scenarioId}/${actuals}/${periodId}/${currencyId}/${subDepartmentId}`
    );
  }

  update<T>(plan: Plan): Observable<any> {
    return this.api.post(`${this.apiController}/update`, plan);
  }

  listApproverUsersSubDepartments<T>(): Observable<any> {
    return this.api.get(
      `${this.apiController}/listApproverUsersSubDepartments`
    );
  }
}
