import { CommonModule, DecimalPipe, DatePipe } from "@angular/common";
import { DemoMaterialModule } from "@planard/common/demo-material-module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { PlanningInstructionsRoutes } from "./planning-instructions.routing";
import { NgModule } from "@angular/core";
import { GuidanceComponent } from "./guidance/guidance.component";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidanceApi } from "@planard/@core/backend/api/guidance.api";
import { ScenarioComponent } from "./scenario/scenario.component";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ScenarioApi } from "@planard/@core/backend/api/scenario.api";
import { TimelineComponent } from "./timeline/timeline.component";
import { ChartModule } from "angular2-chartjs";
import { PipesModule } from "@planard/@core/pipes/pipes.module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { TimelineService } from "@planard/@core/backend/service/timeline.service";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";

const API = [GuidanceApi, ScenarioApi];

const SERVICES = [GuidanceService, ScenarioService, TimelineService];

@NgModule({
  imports: [
    PipesModule,
    CommonModule,
    FlexLayoutModule,
    DemoMaterialModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule.forChild(PlanningInstructionsRoutes),
    ChartModule,
  ],
  declarations: [GuidanceComponent, ScenarioComponent, TimelineComponent],
  providers: [...API, DatePipe, ...SERVICES, DecimalPipe],
})
export class PlanningInstructionsModule {}
