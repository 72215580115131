import { Routes } from "@angular/router";
import { GuidanceComponent } from "./guidance/guidance.component";
import { ScenarioComponent } from "./scenario/scenario.component";
import { TimelineComponent } from "./timeline/timeline.component";

export const PlanningInstructionsRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "guidance",
        component: GuidanceComponent,
        data: {
          title: "Guidance",
          urls: [{ title: "Guidance", url: "guidance" }],
        },
      },
      {
        path: "scenarios",
        component: ScenarioComponent,
        data: {
          title: "Scenarios",
          urls: [{ title: "Scenarios", url: "scenarios" }],
        },
      },
      {
        path: "timeline",
        component: TimelineComponent,
        data: {
          title: "timeline",
          urls: [{ title: "Timeline", url: "timeline" }],
        },
      },
    ],
  },
];
