<h1 mat-dialog-title>{{ "salesNarrativeAdd.title" | translate }}</h1>

<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field *ngIf="!productId">
      <mat-select
        tabindex="1"
        formControlName="productId"
        name="productId"
        placeholder="{{ 'salesNarrativeAdd.product' | translate }}"
      >
        <mat-option
          *ngFor="let o of productsList"
          [value]="o.id"
          value="option"
        >
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="false">
      <mat-select
        tabindex="2"
        formControlName="narrativeRequestId"
        name="narrativeRequestId"
        placeholder="{{ 'salesNarrativeAdd.narrativeRequets' | translate }}"
      >
        <mat-option
          *ngFor="let sale of saleRequestList"
          [value]="sale.id"
          value="option"
        >
          {{ sale.firstPlanningCycleName }}&nbsp;{{ sale.firstYear }}vs{{
            sale.secondPlanningCycleName
          }}&nbsp;{{ sale.secondYear }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="3"
        formControlName="amount"
        type="number"
        matInput
        name="amount"
        placeholder="{{ 'salesNarrativeAdd.amount' | translate }}"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-select
        [(ngModel)]="area"
        tabindex="4"
        formControlName="impactArea"
        name="impactArea"
        placeholder="{{ 'salesNarrativeAdd.impactArea' | translate }}"
      >
        <mat-option value="Volume">Volume</mat-option>
        <mat-option value="Price">Price</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="area == 'Price'">
      <mat-select
        tabindex="5"
        formControlName="category"
        name="category"
        placeholder="{{ 'salesNarrativeAdd.category' | translate }}"
      >
        <mat-option *ngFor="let o of priceCatList" [value]="o" value="option">
          <span *ngIf="'salesNarrativePriceCategories.' + o as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="area == 'Volume'">
      <mat-select
        tabindex="6"
        formControlName="category"
        name="category"
        placeholder="{{ 'salesNarrativeAdd.category' | translate }}"
      >
        <mat-option *ngFor="let o of volumeCatList" [value]="o" value="option">
          <span *ngIf="'salesNarrativeVolumeCategories.' + o as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="7"
        formControlName="description"
        matInput
        maxlength="100"
        name="description"
        placeholder="{{ 'salesNarrativeAdd.description' | translate }}"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="8" type="submit">
      {{ "salesNarrativeAdd.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="9" type="button">
      {{ "salesNarrativeAdd.cancel" | translate }}
    </button>
  </div>
</form>
