import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Company } from "@planard/@core/entities/company";
import { ToastrService } from "ngx-toastr";
import { CompanySetupService } from "@planard/@core/backend/service/company-setup.service";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { EnumSector } from "@planard/@core/enums/EnumSector";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorageService } from "@planard/auth/services/storage.service";
@Component({
  templateUrl: "./settings.component.html",
  styles: [
    `
      #main-div {
      }
      .div {
        display: inline-block;
      }
      .avatar-upload .avatar-preview {
        width: 200px;
        height: 200px;
        position: relative;
        border-radius: 100%;
        overflow: hidden;
        border: 12px solid #fff;
        -webkit-box-shadow: 0 0 45px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 45px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 45px rgba(0, 0, 0, 0.2);
      }
      .avatar-upload .avatar-preview > div {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    `,
  ],
})
export class SettingsComponent implements OnInit {
  sectorList: EnumSector[] = EnumSector.values;
  currencyList: EnumCurrency[] = EnumCurrency.values;
  company: Company;
  sector: EnumSector;
  img = "";
  base64textString: any;
  companyLogo: any = null;
  isRoleValid: boolean;
  numberFormat: string;
  package: string = "";

  myform: FormGroup = new FormGroup({
    name: new FormControl(""),
    code: new FormControl(""),
    sector: new FormControl(""),
    contactEmail: new FormControl(""),
    currency: new FormControl("", [Validators.required]),
    decimal: new FormControl("", [Validators.required]),
    number: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  constructor(
    public fb: FormBuilder,
    public companySetupService: CompanySetupService,
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public storageService: LocalStorageService
  ) {
    this.isRoleValid = this.authService.isAdmin();
    if (this.isRoleValid == false) this.router.navigate(["auth/404"]);

    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit(): void {
    this.companySetupService.get().subscribe((result) => {
      this.company = result;
      this.companyLogo = "data:image/png;base64," + result.logo;
      this.package = result.package;

      this.myform.patchValue({ name: this.company.name });
      this.myform.patchValue({ code: this.company.code });
      this.myform.patchValue({ contactEmail: this.company.contactEmail });

      this.myform.patchValue({
        sector: EnumSector[`${this.company.sector}`].displayValue,
      });
      this.myform.patchValue({
        currency: EnumCurrency[`${this.company.currency}`],
      });
      this.myform.patchValue({ decimal: this.company.decimalFormat });
      this.myform.patchValue({ number: this.company.numberFormat });

      this.storageService.set("companyPackage", result.package);
      this.storageService.set("companyDecimalFormat", result.decimalFormat);
      this.storageService.set("companyNumberFormat", result.numberFormat);
      this.storageService.set("companyUserCount", result.userCount);
      this.storageService.set("companyLanguage", result.language);
      this.storageService.set("companyCurrency", result.currency);
      this.storageService.set("companyName", result.name);
    });
  }

  save() {
    if (this.myform.valid) {
      this.company.currency = this.myform.get("currency").value;
      this.company.numberFormat = this.myform.get("number").value;
      this.company.decimalFormat = this.myform.get("decimal").value;

      if (this.base64textString) {
        this.company.logo = this.base64textString;
      } else {
        this.company.logo = this.company.logo;
      }

      this.companySetupService.update(this.company).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.ngOnInit();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    }
  }

  onImageAdd(event) {
    var files = event.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.img = "data:image/png;base64," + this.base64textString;
  }
}
