import { Observable } from "rxjs";
import { Channel } from "@planard/@core/entities/channel";

export abstract class ChannelData {
  abstract listAll(): Observable<Channel[]>;

  abstract add(channel: Channel): Observable<any>;

  abstract update(channel: Channel): Observable<any>;

  abstract delete(channelId: number): Observable<any>;
}
