import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { Guidance } from "@planard/@core/entities/guidance";
import { GuidanceFxRate } from "@planard/@core/entities/guidanceFxRate";
import { GuidanceInflationRate } from "@planard/@core/entities/guidanceInflationRate";
import { GuidanceTarget } from "@planard/@core/entities/guidanceTarget";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { GuidanceKeyContact } from "@planard/@core/entities/guidanceKeyContact";

@Injectable()
export class GuidanceApi {
  private readonly apiController: string = "guidance";

  constructor(private api: HttpService) {}

  getGuidance<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/getGuidance`);
  }

  getById<T>(guidanceId: number): Observable<any> {
    return this.api.get(`${this.apiController}/getById/${guidanceId}`);
  }

  listAll<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAll`);
  }

  listAllFxRates<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAllFxRates`);
  }

  getPlanningRecord<T>(guidanceId?: number): Observable<any> {
    return this.api.get(`${this.apiController}/listPlanning/${guidanceId}`);
  }

  getTargetRecord<T>(guidanceId: number): Observable<any> {
    return this.api.get(`${this.apiController}/listTarget/${guidanceId}`);
  }

  getKeyContact<T>(guidanceId: number): Observable<any> {
    return this.api.get(`${this.apiController}/listKeyContact/${guidanceId}`);
  }

  getfxRate<T>(guidanceId: number): Observable<any> {
    return this.api.get(`${this.apiController}/listFxRate/${guidanceId}`);
  }

  getInflationRate<T>(guidanceId: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/listInflationRate/${guidanceId}`
    );
  }

  deletePeriod<T>(periodId: any): Observable<any> {
    var json = { id: periodId };
    const endPointUrl = `${this.apiController}/deletePeriod`;
    return this.api.post(endPointUrl, json);
  }

  deleteTarget<T>(targetId: any): Observable<any> {
    var json = { id: targetId };
    const endPointUrl = `${this.apiController}/deleteTarget`;
    return this.api.post(endPointUrl, json);
  }

  deleteFxRate<T>(fxRateId: any): Observable<any> {
    var json = { id: fxRateId };
    const endPointUrl = `${this.apiController}/deleteFxRate`;
    return this.api.post(endPointUrl, json);
  }

  deleteInflationRate<T>(inflationRateId: any): Observable<any> {
    var json = { id: inflationRateId };
    const endPointUrl = `${this.apiController}/deleteInflationRate`;
    return this.api.post(endPointUrl, json);
  }

  deleteKeyContact<T>(keyContactId: any): Observable<any> {
    var json = { id: keyContactId };
    const endPointUrl = `${this.apiController}/deleteKeyContact`;
    return this.api.post(endPointUrl, json);
  }

  addTarget<T>(target: any): Observable<any> {
    return this.api.post(`${this.apiController}/addTarget`, target);
  }

  addPeriod<T>(period: any): Observable<any> {
    return this.api.post(`${this.apiController}/addPeriod`, period);
  }

  addFxRate<T>(fxRate: any): Observable<any> {
    return this.api.post(`${this.apiController}/addFxRate`, fxRate);
  }

  addInflationRate<T>(inflationRate: any): Observable<any> {
    return this.api.post(
      `${this.apiController}/addInflationRate`,
      inflationRate
    );
  }

  addKeyContact<T>(keyContact: any): Observable<any> {
    return this.api.post(`${this.apiController}/addContact`, keyContact);
  }

  updateDeadline<T>(guidance: Guidance): Observable<any> {
    return this.api.post(`${this.apiController}/updateDeadline`, guidance);
  }

  updateCycleName<T>(guidance: Guidance): Observable<any> {
    return this.api.post(`${this.apiController}/updateCycleName`, guidance);
  }

  updateGranularity<T>(guidance: Guidance): Observable<any> {
    return this.api.post(`${this.apiController}/updateGranularity`, guidance);
  }

  updateFxRate<T>(guidanceFxRate: GuidanceFxRate): Observable<any> {
    return this.api.post(`${this.apiController}/updateFxRate`, guidanceFxRate);
  }

  updateInflationRate<T>(
    guidanceInflationRate: GuidanceInflationRate
  ): Observable<any> {
    return this.api.post(
      `${this.apiController}/updateInflationRate`,
      guidanceInflationRate
    );
  }

  updateTarget<T>(guidanceTarget: GuidanceTarget): Observable<any> {
    return this.api.post(`${this.apiController}/updateTarget`, guidanceTarget);
  }

  updatePeriod<T>(
    guidancePlanningPeriod: GuidancePlanningPeriod
  ): Observable<any> {
    return this.api.post(
      `${this.apiController}/updatePeriod`,
      guidancePlanningPeriod
    );
  }

  updateContact<T>(guidanceKeyContact: GuidanceKeyContact): Observable<any> {
    return this.api.post(
      `${this.apiController}/updateContact`,
      guidanceKeyContact
    );
  }

  startGuidance<T>(guidanceId: any): Observable<any> {
    return this.api.post(`${this.apiController}/startGuidance`, guidanceId);
  }

  endGuidance<T>(guidanceId: any): Observable<any> {
    return this.api.post(`${this.apiController}/endGuidance`, guidanceId);
  }

  copyGuidance<T>(guidanceId: any): Observable<any> {
    return this.api.post(`${this.apiController}/copyGuidance`, guidanceId);
  }
}
