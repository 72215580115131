<h1 mat-dialog-title>{{ "productForm.title" | translate }}</h1>
<div mat-dialog-content>
  <div class="responsive-table">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="form">
        <mat-header-cell *matHeaderCellDef mat-sort-header="form">
          {{ "productForm.name" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'form')">
            <ng-template viewMode>
              {{ element.form }}
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <input
                  matInput
                  [formControl]="getControl(element.id, 'form')"
                  focusable
                  editableOnEnter
                />
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="conversionRate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="conversionRate">
          {{ "productForm.conversionRate" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'conversionRate')">
            <ng-template viewMode>
              {{ element.conversionRate }}
              <!--{{formatNumber(element.conversionRate, decimalFormat, numberFormat)}}-->
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  [formControl]="getControl(element.id, 'conversionRate')"
                  focusable
                  editableOnEnter
                />
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
          {{ "productForm.actions" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
          <button
            (click)="onDelete(row)"
            autofocus
            color="warn"
            mat-icon-button
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>

    <table *ngIf="IsEmpty == true" style="min-height: 48px">
      <tr>
        <td style="text-align: center" [attr.colspan]="displayedColumns.length">
          No Records Found!
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 25]"
    ></mat-paginator>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="addDialog()" color="accent" mat-raised-button>
    {{ "productForm.add" | translate }}
  </button>
  <button (click)="cancel()" mat-button type="button">
    {{ "productForm.close" | translate }}
  </button>
</div>
