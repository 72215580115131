import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { AccountsService } from "@planard/@core/backend/service/accounts.service";

@Component({
  selector: "app-auth-logout",
  templateUrl: "./auth-logout.component.html",
  styleUrls: [],
})
export class AuthLogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private accountsService: AccountsService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.completeLogout();
  }
}
