import { Observable } from "rxjs";
import { Guidance } from "@planard/@core/entities/guidance";
import { GuidanceTarget } from "@planard/@core/entities/guidanceTarget";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { GuidanceFxRate } from "../guidanceFxRate";
import { GuidanceInflationRate } from "../guidanceInflationRate";
import { GuidanceKeyContact } from "../guidanceKeyContact";

export abstract class GuidanceData {
  abstract getGuidance(): Observable<Guidance>;

  abstract getById(guidanceId: number): Observable<Guidance>;

  abstract listAll(): Observable<any[]>;

  abstract listAllFxRates(): Observable<any[]>;

  abstract getPlanningRecord(guidanceId?: number): Observable<any[]>;

  abstract getTargetRecord(guidanceId: number): Observable<any[]>;

  abstract getFxRate(guidanceId: number): Observable<any[]>;

  abstract getInflationRate(guidanceId: number): Observable<any[]>;

  abstract getKeyContact(guidanceId: number): Observable<any[]>;

  abstract addTarget(target: GuidanceTarget);

  abstract addPeriod(period: GuidancePlanningPeriod);

  abstract deleteTarget(targetId: number);

  abstract deletePeriod(periodId: number);

  abstract addFxRate(fxRate: GuidanceFxRate);

  abstract addKeyContact(keyContact: GuidanceKeyContact);

  abstract addInflationRate(inflationRate: GuidanceInflationRate);

  abstract deleteFxRate(fxRateId: number);

  abstract deleteKeyContact(keyContactId: number);

  abstract deleteInflationRate(inflationRateId: number);

  abstract updateDeadline(guidance: Guidance);

  abstract updateCycleName(guidance: Guidance);

  abstract updateGranularity(guidance: Guidance);

  abstract updateFxRate(guidanceFxRate: GuidanceFxRate);

  abstract updateInflationRate(guidanceInflationRate: GuidanceInflationRate);

  abstract updateTarget(guidanceTarget: GuidanceTarget);

  abstract updatePeriod(guidancePeriod: GuidancePlanningPeriod);

  abstract updateContact(guidanceKeyContact: GuidanceKeyContact);

  abstract startGuidance(guidanceId: any);

  abstract endGuidance(guidanceId: any);

  abstract copyGuidance(guidanceId: any);
}
