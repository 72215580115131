import { NgModule } from "@angular/core";
import { MenuItems } from "./menu-items/menu-items";
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective,
} from "./accordion";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [TranslateModule],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    TranslateModule,
  ],
  providers: [MenuItems],
})
export class SharedModule {}
