<h1 mat-dialog-title>{{ "guidance.inflationRate.add" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <mat-select
        tabindex="1"
        placeholder="{{ 'guidance.inflationRate.year' | translate }}"
        name="year"
        formControlName="year"
      >
        <mat-option *ngFor="let o of yearList" [value]="o" value="option">
          {{ o }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        tabindex="2"
        placeholder="{{ 'guidance.inflationRate.title' | translate }}"
        type="number"
        name="rate"
        formControlName="rate"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="3" type="submit">
      {{ "guidance.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="4" type="button">
      {{ "guidance.cancel" | translate }}
    </button>
  </div>
</form>
