<mat-card>
  <mat-card-content>
    <mat-card-title>Update Company</mat-card-title>
    <!-- ============================================================== -->
    <!-- column -->
    <!-- ============================================================== -->
    <form (ngSubmit)="save()" [formGroup]="form" class="basic-form">
      <div fxLayout="row wrap">
        <!-- column -->
        <div fxFlex="50" fxFlex.gt-md="50">
          <mat-form-field>
            <input
              formControlName="companyName"
              matInput
              name="companyName"
              placeholder="Company Name"
            />
          </mat-form-field>
        </div>
        <!-- column -->
        <div fxFlex="50" fxFlex.gt-sm="50">
          <mat-form-field>
            <input
              formControlName="companyCode"
              matInput
              name="companyCode"
              placeholder="Company Code"
            />
          </mat-form-field>
        </div>
        <!-- column -->
        <div fxFlex="50" fxFlex.gt-sm="50">
          <mat-form-field>
            <mat-select
              formControlName="sector"
              name="sector"
              placeholder="Sector"
            >
              <mat-option
                *ngFor="let o of sectorList"
                [value]="o"
                value="option"
              >
                {{ o.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!--            <div fxFlex="50" fxFlex.gt-sm="50">-->
        <!--              <mat-form-field>-->
        <!--                <input [(ngModel)]="data.departments" matInput name="department" placeholder="Departments">-->
        <!--              </mat-form-field>-->
        <!--            </div>-->

        <!--            <div fxFlex="50" fxFlex.gt-sm="50">-->
        <!--              <mat-form-field>-->
        <!--                <input [(ngModel)]="data.subdepartments" matInput name="subdepartment" placeholder="Subdepartments">-->
        <!--              </mat-form-field>-->
        <!--            </div>-->

        <div fxFlex="50" fxFlex.gt-sm="50">
          <mat-form-field>
            <mat-select
              formControlName="productFamily"
              multiple
              name="productFamily"
              placeholder="Product Family"
            >
              <mat-option
                *ngFor="let o of productFamilyList"
                [value]="o"
                value="option"
              >
                {{ o.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.gt-sm="50">
          <mat-form-field>
            <mat-select
              formControlName="businessUnit"
              multiple
              placeholder="Business Unit"
            >
              <mat-option
                *ngFor="let o of businessUnitList"
                [value]="o"
                value="option"
              >
                {{ o.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.gt-sm="50">
          <mat-form-field>
            <mat-select
              formControlName="currency"
              name="currency"
              placeholder="Currency"
            >
              <mat-option
                *ngFor="let o of currencyList"
                [value]="o"
                value="option"
              >
                {{ o.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- column -->
        <div mat-dialog-actions>
          <button (click)="cancel()" mat-button tabindex="-1">Cancel</button>
          <button mat-button tabindex="2" type="submit">Update</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
