import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidanceFxRate } from "@planard/@core/entities/guidanceFxRate";
import { FxRate } from "@planard/@core/entities/fxRate";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./fx-rate-add.component.html",
})
export class FxRateAddComponent {
  fxRate: GuidanceFxRate;
  fxRateList: FxRate[];

  form: FormGroup = new FormGroup({
    fxRateId: new FormControl("", [Validators.required]),
    rate: new FormControl("", [Validators.required, Validators.min(0)]),
  });
  options: FormGroup;
  guidanceId: number;

  constructor(
    public dialogRef: MatDialogRef<FxRateAddComponent>,
    public guidanceService: GuidanceService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.guidanceId = data.guidanceId;
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.guidanceService.listAllFxRates().subscribe((data) => {
      this.fxRateList = data;
    });
  }

  save() {
    if (this.form.valid) {
      this.fxRate = this.form.value;
      this.fxRate.guidanceId = this.guidanceId;
      this.guidanceService.addFxRate(this.fxRate).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.dialogRef.close(this.fxRate);
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
