<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 85%">
            <mat-card-title>{{ "timeline.title" | translate }}</mat-card-title>
          </div>
          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>
        <div class="responsive-table">
          <mat-table
            [dataSource]="dataSource"
            matSort
            *ngIf="guidanceStart != true"
          >
            <ng-container matColumnDef="sequence">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="name"
                [ngClass]="'toRight'"
              >
                {{ "timeline.sequence" | translate }}
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let index = index"
                [ngClass]="'toRight'"
              >
                {{ getSequence(element.id) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="task">
              <mat-header-cell *matHeaderCellDef mat-sort-header="task">
                {{ "timeline.task" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <!-- task by enum -->
                <editable
                  (update)="updateField(element.id, 'task')"
                  *ngIf="element.customTask == null"
                >
                  <ng-template viewMode>
                    <span *ngIf="'enums.tasks.' + element.task as variable">{{
                      variable | translate
                    }}</span>
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <mat-select
                        [formControl]="getControl(element.id, 'task')"
                      >
                        <mat-option *ngFor="let o of taskList" [value]="o">
                          <span *ngIf="'enums.tasks.' + o as variable">{{
                            variable | translate
                          }}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>

                <!-- free text task -->
                <editable
                  (update)="updateField(element.id, 'customTask')"
                  *ngIf="element.customTask != null"
                >
                  <ng-template viewMode>
                    {{ element.customTask }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [formControl]="getControl(element.id, 'customTask')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="selectedProcessRoles">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="selectedProcessRoles"
              >
                {{ "timeline.responsible" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable2
                  (update)="updateField(element.id, 'selectedProcessRoles')"
                >
                  <ng-template viewMode2>
                    {{
                      isAll(element.selectedProcessRoles)
                        ? "All"
                        : element.processRolesStr != ""
                        ? getRoles(element.processRolesStr)
                        : "-"
                    }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field>
                      <mat-select
                        #roleSelect
                        multiple
                        [formControl]="
                          getControl(element.id, 'selectedProcessRoles')
                        "
                        [(ngModel)]="element.selectedProcessRoles"
                      >
                        <mat-option (click)="selectAll(element.id, roleSelect)"
                          >All</mat-option
                        >
                        <mat-option
                          *ngFor="let o of roleList"
                          [value]="o.id"
                          (click)="selectOne(element.id, o.id)"
                        >
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header="startDate">
                {{ "timeline.startDate" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable2 (update)="updateField(element.id, 'startDate')">
                  <ng-template viewMode2>
                    {{ element.startDate | date : "dd/MM/yyyy" }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field style="width: 60%">
                      <input
                        [min]="today"
                        matInput
                        [matDatepicker]="spicker"
                        [formControl]="getControl(element.id, 'startDate')"
                        focusable
                      />
                      <mat-datepicker-toggle
                        [for]="spicker"
                        matSuffix
                      ></mat-datepicker-toggle>
                      <mat-datepicker #spicker></mat-datepicker>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header="endDate">
                {{ "timeline.endDate" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable2 (update)="updateField(element.id, 'endDate')">
                  <ng-template viewMode2>
                    {{ element.endDate | date : "dd/MM/yyyy" }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field style="width: 60%">
                      <input
                        [min]="today"
                        matInput
                        [matDatepicker]="epicker"
                        [formControl]="getControl(element.id, 'endDate')"
                        focusable
                        editableOnEnter3
                      />
                      <mat-datepicker-toggle
                        [for]="epicker"
                        matSuffix
                      ></mat-datepicker-toggle>
                      <mat-datepicker #epicker></mat-datepicker>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="dependentProcessId">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="dependentProcessId"
              >
                {{ "timeline.dependency" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="getSequence(element.id) == 1"> - </span>

                <editable
                  (update)="updateField(element.id, 'dependentProcessId')"
                  *ngIf="getSequence(element.id) != 1"
                >
                  <ng-template viewMode>
                    <p *ngIf="element.dependentProcessId == null">-</p>
                    <p *ngIf="element.dependentProcessId != null">
                      {{
                        (getDepent(element.dependentProcessId)
                        | enumDisplayValue : "EnumTask")
                          ? (getDepent(element.dependentProcessId)
                            | enumDisplayValue : "EnumTask")
                          : getDepent(element.dependentProcessId)
                      }}
                    </p>
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <mat-select
                        [formControl]="
                          getControl(element.id, 'dependentProcessId')
                        "
                        [(ngModel)]="element.dependentProcessId"
                      >
                        <mat-option
                          *ngFor="let o of getDependencyList(element.id)"
                          [value]="o.id"
                        >
                          <p *ngIf="o.customTask == null">
                            {{ o.task | enumDisplayValue : "EnumTask" }}
                          </p>
                          <p *ngIf="o.customTask != null">{{ o.customTask }}</p>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="daysToExecute">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="daysToExecute"
              >
                {{ "timeline.days" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'daysToExecute')">
                  <ng-template viewMode>
                    <!--{{getDaysToExecute(element.startDate, element.endDate)}}-->
                    {{ element.daysToExecute }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        type="number"
                        [formControl]="getControl(element.id, 'daysToExecute')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
                {{ "timeline.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
                <button
                  (click)="onDelete(row)"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-table
            [dataSource]="dataSource"
            matSort
            *ngIf="guidanceStart == true"
          >
            <ng-container matColumnDef="sequence">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="name"
                [ngClass]="'toRight'"
              >
                {{ "timeline.sequence" | translate }}
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let index = index"
                [ngClass]="'toRight'"
              >
                {{ getSequence(element.id) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="task">
              <mat-header-cell *matHeaderCellDef mat-sort-header="task">
                {{ "timeline.task" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="'enums.tasks.' + element.task as variable">{{
                  element.customTask == null
                    ? (variable | translate)
                    : element.customTask
                }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="selectedProcessRoles">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="selectedProcessRoles"
              >
                {{ "timeline.responsible" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{
                  isAll(element.selectedProcessRoles)
                    ? "All"
                    : element.processRolesStr != ""
                    ? getRoles(element.processRolesStr)
                    : "-"
                }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header="startDate">
                {{ "timeline.startDate" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable2 (update)="updateField(element.id, 'startDate')">
                  <ng-template viewMode2>
                    {{ element.startDate | date : "dd/MM/yyyy" }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field style="width: 60%">
                      <input
                        [min]="today"
                        matInput
                        [matDatepicker]="spicker"
                        [formControl]="getControl(element.id, 'startDate')"
                        focusable
                      />
                      <mat-datepicker-toggle
                        [for]="spicker"
                        matSuffix
                      ></mat-datepicker-toggle>
                      <mat-datepicker #spicker></mat-datepicker>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header="endDate">
                {{ "timeline.endDate" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable2 (update)="updateField(element.id, 'endDate')">
                  <ng-template viewMode2>
                    {{ element.endDate | date : "dd/MM/yyyy" }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field style="width: 60%">
                      <input
                        [min]="today"
                        matInput
                        [matDatepicker]="epicker"
                        [formControl]="getControl(element.id, 'endDate')"
                        focusable
                      />
                      <mat-datepicker-toggle
                        [for]="epicker"
                        matSuffix
                      ></mat-datepicker-toggle>
                      <mat-datepicker #epicker></mat-datepicker>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="dependentProcessId">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="dependentProcessId"
              >
                {{ "timeline.dependency" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{
                  getDepent(element.dependentProcessId)
                    | enumDisplayValue : "EnumTask"
                }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="daysToExecute">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="daysToExecute"
              >
                {{ "timeline.days" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'daysToExecute')">
                  <ng-template viewMode>
                    <!--{{getDaysToExecute(element.startDate, element.endDate)}}-->
                    {{ element.daysToExecute }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field style="width: 60%">
                      <input
                        matInput
                        type="number"
                        [formControl]="getControl(element.id, 'daysToExecute')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
                {{ "timeline.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
                <button
                  (click)="outToastr('guidance.msg1')"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>

        <mat-card-actions *ngIf="companyPackage == 'premium'">
          <button (click)="addDialog()" color="accent" mat-raised-button>
            {{ "timeline.add" | translate }}
          </button>
          <!--<button *ngIf="guidanceStart==true" (click)="outToastr('guidance.msg1');" color="accent" mat-raised-button>{{ 'timeline.add' | translate }}</button>-->
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
