import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EnumResponsibility } from "@planard/@core/enums/EnumResponsibility";
import { EnumReportAccess } from "@planard/@core/enums/EnumReportAccess";
import { Role } from "@planard/@core/entities/role";
import { RoleService } from "@planard/@core/backend/service/role.service";
import { ToastrService } from "ngx-toastr";
import { MatDialogRef } from "@angular/material/dialog";
import { ProcessResult } from "@planard/@core/entities/process-result";

@Component({
  templateUrl: "./roles-add.component.html",
})
export class RolesAddComponent {
  responsibilityList: EnumResponsibility[] = EnumResponsibility.values;
  reportAccessList: EnumReportAccess[] = EnumReportAccess.values;
  role: Role;
  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required]),
    responsibility: new FormControl("", [Validators.required]),
    reportAccess: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  constructor(
    private fb: FormBuilder,
    public roleService: RoleService,
    public toastrService: ToastrService,
    public dialogRef: MatDialogRef<RolesAddComponent>
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  save() {
    /* if (this.form.valid) {
      this.role = this.form.value;
      this.roleService.add(this.role).subscribe((backendResult: ProcessResult) => {
        if (backendResult.isSuccess) {
          this.toastrService.success(backendResult.message);
          this.dialogRef.close(this.role);
        } else {
          this.toastrService.error(backendResult.message);
        }
      });
    } else {
      this.toastrService.warning("Please fill all required spaces");
    }*/
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
