import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ClassicReportData } from "@planard/@core/entities/reports/classicReportData";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { Products } from "@planard/@core/entities/product";
import { Scenario } from "@planard/@core/entities/scenario";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";
import { FxRate } from "@planard/@core/entities/fxRate";
import { NarrativeService } from "@planard/@core/backend/service/narrative.service";
import { NarrativeRequest } from "@planard/@core/entities/narrativeRequest";
import { BusinessUnit } from "@planard/@core/entities/businessUnit";
import { ProductFamily } from "@planard/@core/entities/productFamily";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import { ProductFamilyService } from "@planard/@core/backend/service/product-family.service";
import { waterFallPlugin } from "chartjs-plugin-waterfall";
import { DecimalPipe } from "@angular/common";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { NumberDecimalReversePipe } from "@planard/@core/pipes/NumberDecimalReversePipe";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";

@Component({
  templateUrl: "./sales-bridge.component.html",
  styles: [
    `
      .div {
        display: inline-block;
      }
    `,
  ],
  providers: [NumberDecimalPipe, NumberDecimalReversePipe],
})
export class SalesBridgeComponent implements OnInit {
  reportData: ClassicReportData = new ClassicReportData();
  barChartData: any[] = [];
  currencyList: EnumCurrency[] = EnumCurrency.values;
  productList: Products[];
  scenarioList: Scenario[];
  planningPeriodList: GuidancePlanningPeriod[];
  indicatorList = ["Gross Price", "Discount", "Net Price"];
  img = "";
  scenarioName: string;
  productName: string;
  currencyName: string;
  guidanceId: number;
  currency: number = 0;
  scenarioId: number;
  productId: number;
  period: number;
  reportScreenshot: ReportScreenshot = {};
  saleRequestList: NarrativeRequest[];
  requestId: number;
  fxrateList: FxRate[];
  guidanceFxRateList: any[];
  isRun: boolean;
  guidanceIsStart: boolean = null;
  granularityList = [
    { value: 0, label: "Total Company" },
    { value: 1, label: "Product Family" },
    { value: 2, label: "Business Unit" },
    { value: 3, label: "Product" },
  ];
  granularity: number = 0;
  businessUnitList: BusinessUnit[];
  productFamilyList: ProductFamily[];
  businessUnitId: number;
  productFamilyId: number;
  isRoleValid: boolean;
  pairCurrencyList = [];
  companyPackage: string;

  numberFormat: string;
  decimalFormat: string;
  companyCurrency: string;
  companyNumberFormat: string;

  public barChartOptions: any = {};
  public barChartType = "bar";
  public barChartColors: Array<any> = [];

  waterFallPlugin: waterFallPlugin;

  @ViewChild("screen", { static: true }) screen: any;

  requestControl = new FormControl("", [Validators.required]);
  granularityControl = new FormControl("", [Validators.required]);
  pfControl = new FormControl("", [Validators.required]);
  buControl = new FormControl("", [Validators.required]);
  productControl = new FormControl("", [Validators.required]);
  currencyControl = new FormControl("", [Validators.required]);

  constructor(
    public toastr: ToastrService,
    public scenarioService: ScenarioService,
    public businessUnitService: BusinessUnitService,
    public productFamilyService: ProductFamilyService,
    public guidanceService: GuidanceService,
    public authService: AuthService,
    public decimalPipe: DecimalPipe,
    public narrativeService: NarrativeService,
    private numberDecimal: NumberDecimalPipe,
    public translate: TranslateService,
    private captureService: NgxCaptureService,
    public reportScreenshotService: ReportScreenshotService,
    private router: Router,
    public productsService: ProductsService
  ) {
    var companyPackage = this.authService.getCompanyPackage();
    if (companyPackage == "basic") this.isRoleValid = false;
    if (companyPackage == "premium")
      this.isRoleValid =
        this.authService.isAdmin() ||
        this.authService.isBusinessUnitApprover() ||
        this.authService.isBusinessUnitPlanner();

    if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();
    this.companyNumberFormat =
      this.authService.getNumberFormat() == "noScale"
        ? ""
        : this.authService.getNumberFormat() == "thousands"
        ? "k"
        : this.authService.getNumberFormat() == "millions"
        ? "m"
        : "n/a";
    this.companyCurrency = this.authService.getCompanyCurrency();
  }

  ngOnInit() {
    this.businessUnitService.listUserBusinessUnits().subscribe((result) => {
      this.businessUnitList = result;
    });
    this.productFamilyService.listUserProductFamilies().subscribe((result) => {
      this.productFamilyList = result;
    });
    this.narrativeService
      .listSalesRequests /*Whole*/
      ()
      .subscribe((result) => {
        this.saleRequestList = result;
      });
    this.productsService.listUserProducts().subscribe((result: any) => {
      this.productList = result;
    });
    this.scenarioService.listAll().subscribe((result: any) => {
      this.scenarioList = result;
    });
    this.guidanceService.getGuidance().subscribe((result: any) => {
      this.guidanceIsStart = result.start;
      this.guidanceId = result.id;
      this.guidanceService
        .getPlanningRecord(result.id)
        .subscribe((result2: any) => {
          this.planningPeriodList = result2;
        });
      this.guidanceService.getFxRate(this.guidanceId).subscribe((data) => {
        this.guidanceFxRateList = data;
      });
    });
    this.guidanceService.listAllFxRates().subscribe((result: any) => {
      this.fxrateList = result;
    });
  }

  getFxRate(fxRateId) {
    if (fxRateId == 0) {
      return "Default Currency";
    }
    for (let index in this.fxrateList) {
      if (fxRateId == this.fxrateList[index].id) {
        return this.fxrateList[index].name;
      }
    }
  }

  isValid() {
    if (
      this.requestControl.valid == false ||
      this.granularityControl.valid == false ||
      this.currencyControl.valid == false
    ) {
      this.translate.get("reports.allrequired").subscribe((data: any) => {
        this.toastr.error(data);
      });
      return;
    } else if (
      this.requestControl.valid == true &&
      this.granularityControl.valid == true &&
      this.currencyControl.valid == true
    ) {
      if (this.granularity == 0) {
        this.run();
      } else if (this.granularity == 1) {
        if (this.pfControl.valid == true) {
          this.run();
        } else {
          this.translate.get("reports.allrequired").subscribe((data: any) => {
            this.toastr.error(data);
          });
          return;
        }
      } else if (this.granularity == 2) {
        if (this.buControl.valid == true) {
          this.run();
        } else {
          this.translate.get("reports.allrequired").subscribe((data: any) => {
            this.toastr.error(data);
          });
          return;
        }
      } else if (this.granularity == 3) {
        if (this.productControl.valid == true) {
          this.run();
        } else {
          this.translate.get("reports.allrequired").subscribe((data: any) => {
            this.toastr.error(data);
          });
          return;
        }
      }
    }
  }

  run() {
    this.isRun = false;

    this.reportData.chartLabels;
    this.barChartOptions = {};
    this.barChartData = [];

    let categoryId;
    if (this.granularity == 0) {
      categoryId = 0;
    } else if (this.granularity == 1) {
      categoryId = this.productFamilyId;
    } else if (this.granularity == 2) {
      categoryId = this.businessUnitId;
    } else if (this.granularity == 3) {
      categoryId = this.productId;
    }

    this.narrativeService
      .salesBridge(this.requestId, this.granularity, categoryId, this.currency)
      .subscribe((result) => {
        result.data.forEach((ele, index: number) => {
          let color;
          if (ele.isWhite == true) {
            color = "white";
          } else if (ele.isRed == true) {
            color = "#E74C3C";
          } else if (ele.isSolid == true) {
            color = "#B2BABB";
          } else if (
            ele.isRed == false &&
            ele.isWhite == false &&
            ele.isSolid == false
          ) {
            color = "#27AE60";
          }

          let value = ele.data.toFixed(2);

          this.barChartData.push({
            label: `${ele.isWhite == true ? "remove" : ele.label}`,
            data: [value],
            waterfall: {
              dummyStack: ele.isWhite == true ? true : false,
            },
            percentage: `${
              ele.isSolid && index == result.data.length - 1
                ? (
                    ((ele.data - result.data[0].data) / result.data[0].data) *
                    100
                  ).toFixed(2)
                : ele.percentage
            }`,
            backgroundColor: `${color}`,
            hoverBackgroundColor: `${color}`,
            stack: `stack ${ele.stack}`,
            showPercentage:
              ele.isSolid && index != result.data.length - 1 ? false : true,
          });
        });

        let numberDecimal = this.numberDecimal;
        let decimalFormat = this.decimalFormat;
        let numberFormat = this.numberFormat;

        this.barChartOptions = {
          scaleShowVerticalLines: false,
          responsive: true,
          barThickness: 4,
          tooltips: {
            enabled: false,
            mode: "single",
            callbacks: {
              label: function (tooltipItems, data) {
                if (
                  data.datasets[tooltipItems.datasetIndex].label != "remove"
                ) {
                  return data.datasets[tooltipItems.datasetIndex].label;
                } else {
                  return false;
                }
              },
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {},
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
          },
          title: {
            display: false,
            text: "",
            position: "bottom",
          },
          plugins: {
            datalabels: {
              display: function (context) {
                if (context.dataset.label == "remove") {
                  return false;
                } else {
                  return true;
                }
              },
              formatter: function (value, context) {
                if (context.dataset.showPercentage) {
                  return (
                    numberDecimal.transform(
                      Number(value),
                      decimalFormat,
                      numberFormat
                    ) +
                    "\n" +
                    "\n" +
                    "%" +
                    context.dataset.percentage
                  );
                } else {
                  return numberDecimal.transform(
                    Number(value),
                    decimalFormat,
                    numberFormat
                  );
                }
              },
              color: function (context) {
                //if (context.dataset.label == 'remove'){
                //return context.dataset.backgroundColor;
                //}else{
                return "black";
                //}
              },
            },
            plugins: {
              waterFallPlugin: {
                stepLines: {
                  enabled: true,
                  startColorStop: 0,
                  endColorStop: 0.6,
                  startColor: "rgba(0, 0, 0, 0.55)",
                  endColor: "rgba(0, 0, 0, 0)",
                  diagonalStepLines: true,
                },
              },
            },
          },
          legend: {
            labels: {
              filter: function (item, chart) {
                // Logic to remove a particular legend item goes here
                return !item.text.includes("remove");
              },
            },
            //onClick: false,
            //display: false,
            position: "bottom",
          },
          layout: {
            padding: {
              top: 30,
            },
          },
          filter: function (legendItem, chartData) {
            var currentDataset = chartData[legendItem.datasetIndex];
            return !currentDataset.dummyStack;
          },
        };

        this.isRun = true;
      });
  }

  take() {
    if (this.isRun != true) {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastr.error(data);
      });
      return;
    }
    this.captureService
      .getImage(this.screen.nativeElement, true, 400)
      .pipe(
        tap((img) => {
          this.img = img;
        })
      )
      .subscribe();
  }

  reset() {
    this.img = "";
  }

  getRequest(id: number) {
    if (id != null) {
      for (let index in this.saleRequestList) {
        if (id == this.saleRequestList[index].id) {
          return (
            this.saleRequestList[index].firstPlanningCycleName +
            " " +
            this.saleRequestList[index].firstYear +
            " " +
            "vs" +
            " " +
            this.saleRequestList[index].secondPlanningCycleName +
            " " +
            this.saleRequestList[index].secondYear
          );
        }
      }
    }
  }

  getCategory() {
    if (this.granularity == 1) {
      for (let index in this.productFamilyList) {
        if (this.productFamilyId == this.productFamilyList[index].id) {
          return this.productFamilyList[index].name;
        }
      }
    } else if (this.granularity == 2) {
      for (let index in this.businessUnitList) {
        if (this.businessUnitId == this.businessUnitList[index].id) {
          return this.businessUnitList[index].name;
        }
      }
    } else if (this.granularity == 3) {
      for (let index in this.productList) {
        if (this.productId == this.productList[index].id) {
          return this.productList[index].name;
        }
      }
    }
  }

  save() {
    var array = this.img.split(",");
    this.reportScreenshot.image = array[1];
    this.reportScreenshot.guidanceId = this.guidanceId;
    this.reportScreenshot.name = `${this.getRequest(this.requestId)} / ${
      this.granularity == 0 ? "Total Company" : this.getCategory()
    } / ${this.currency}`;
    this.reportScreenshot.reportName = "Sales Bridge";
    this.reportScreenshotService
      .addReportScreenshot(this.reportScreenshot)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastr.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastr.error(data);
            });
        }
      });
  }
}
