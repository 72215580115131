import { CommonModule } from "@angular/common";
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from "@angular/core";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { CommonBackendModule } from "./backend/common-backend.module";
import { ProductsService } from "./backend/service/products.service";
import { DemoMaterialModule } from "../common/demo-material-module";
import { TranslateModule } from "@ngx-translate/core";

export const CORE_PROVIDERS = [...CommonBackendModule.forRoot().providers];

export const MODULE_SERVICES = [ProductsService];

@NgModule({
  imports: [CommonModule, DemoMaterialModule, TranslateModule],
  exports: [],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [...CORE_PROVIDERS, ...MODULE_SERVICES],
    };
  }
}
