<div
  class="login-register"
  style="background-image: url(assets/images/background/login-register.jpg)"
>
  <div class="login-register-box">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="text-center">
            <img alt="homepage" src="assets/images/logo-icon.png" />
            <h4 class="m-t-0">Recover Password</h4>
          </div>
          <p class="text-center font-14">
            Enter your email and we'll send you the password reset link.
          </p>

          <div fxLayout="column" fxLayoutAlign="space-around">
            <div class="pb-1">
              <mat-form-field style="width: 100%">
                <input
                  matInput
                  placeholder="Email address"
                  type="email"
                  [formControl]="form.controls['email']"
                />
              </mat-form-field>
              <small
                *ngIf="
                  form.controls['email'].hasError('required') &&
                  form.controls['email'].touched
                "
                class="text-danger support-text"
                >You must include an email address.</small
              >
              <small
                *ngIf="
                  form.controls['email'].errors?.email &&
                  form.controls['email'].touched
                "
                class="text-danger support-text"
                >You must include a valid email address.</small
              >
            </div>
            <button
              mat-raised-button
              color="primary"
              type="submit"
              class="btn-block btn-lg m-t-20"
              [disabled]="!form.valid"
            >
              Submit
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
