import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DemoMaterialModule } from "./common/demo-material-module";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./@core/core.module";
import { HttpInterceptorService } from "./auth/services/http-interceptor.service";
import { PipesModule } from "./@core/pipes/pipes.module";
import { NotificationModule } from "@planard/notification/notification.module";
import { AdminPlanningModule } from "@planard/admin/planning/admin-planning.module";
import { AdminGeneralModule } from "@planard/admin/general/admin-general.module";
import { AdminSuperModule } from "@planard/admin/super/admin-super.module";
import { PlanningInstructionsModule } from "./planning/instructions/planning-instructions.module";
import { SalesReportsModule } from "@planard/reports/sales/sales-reports.module";
import { PlanningCostsModule } from "@planard/planning/costs/planning-costs.module";
import { PlanningSalesModule } from "@planard/planning/sales/planning-sales.module";
import { MatDialogModule } from "@angular/material/dialog";
import { authProviders } from "./app.routing";
import { AuthenticationModule } from "./auth/authentication.module";
import { LoadingComponent } from "./common/loading";
import { LoaderInterceptor } from "./@core/backend/service/loader.interceptor";
import { HttpErrorInterceptor } from "./@core/backend/service/retry.service";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { CustomMatPaginatorIntl } from "./common/paginatori18n";
import { CostReportsModule } from "./reports/cost/cost-reports.module";
import { EconomicReportsModule } from "./reports/economic/economic-reports.module";
import "rxjs/add/observable/fromPromise";
import * as $ from "jquery";

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return Observable.fromPromise(
      /*System.*/ import(`../assets/i18n/${lang}.json`)
    );
  }
}

declare var System: System;
interface System {
  import(request: string): Promise<any>;
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
  declarations: [AppComponent, LoadingComponent],
  imports: [
    AppRoutingModule,
    AdminPlanningModule,
    AdminGeneralModule,
    AdminSuperModule,
    PlanningInstructionsModule,
    PlanningSalesModule,
    PlanningCostsModule,
    SalesReportsModule,
    CostReportsModule,
    EconomicReportsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    PipesModule,
    DemoMaterialModule,
    FormsModule,
    MatDialogModule,
    NotificationModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot(),
    AuthenticationModule.forRoot({ user: null }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    HttpModule,
  ],
  providers: [
    authProviders,
    TranslateService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
