import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { Products } from "@planard/@core/entities/product";
import { Scenario } from "@planard/@core/entities/scenario";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { LaunchTimelineService } from "@planard/@core/backend/service/launchTimeline.service";
import { LaunchTimeline } from "@planard/@core/entities/launchTimeline";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { Guidance } from "@planard/@core/entities/guidance";
@Component({
  templateUrl: "./launch-dates.component.html",
  styles: [
    `
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class LaunchDatesComponent implements OnInit {
  data: LaunchTimeline[] = [];
  guidanceName: string;
  productList: Products[];
  scenarioGuiList: Scenario[];
  guidanceList: Guidance[];
  img = "";
  reportScreenshot: ReportScreenshot = {};
  scenarioName: string;
  productName: string;
  guidanceId: number;
  currency: any;
  scenarioId: number;
  productId: number;
  period: any;
  isRun: boolean;
  launchList: LaunchTimeline[] = [];
  header: LaunchTimeline = new LaunchTimeline();
  data1: any;
  data2: any;
  data3: any;
  data4: any;
  data5: any;
  plantingDays;
  isRoleValid: boolean;
  totalPlanting: any;
  companyPackage: string;
  companyCurrency: string;
  companyNumberFormat: string;
  MS_PER_DAY = 1000 * 60 * 60 * 24;
  chartData;
  options;
  todayS: string;
  days: number;

  scenarioControl = new FormControl("", [Validators.required]);
  guidanceControl = new FormControl("", [Validators.required]);
  @ViewChild("screen", { static: true }) screen: any;

  constructor(
    public toastrService: ToastrService,
    public scenarioService: ScenarioService,
    public launchTimelineService: LaunchTimelineService,
    public guidanceService: GuidanceService,
    public productsService: ProductsService,
    public reportScreenshotService: ReportScreenshotService,
    private router: Router,
    public authService: AuthService,
    public translate: TranslateService,
    private captureService: NgxCaptureService
  ) {
    this.companyPackage = this.authService.getCompanyPackage();

    if (this.companyPackage == "basic") this.isRoleValid = false;

    if (this.companyPackage == "premium")
      this.isRoleValid =
        this.authService.isAdmin() ||
        this.authService.isPricePlanner() ||
        this.authService.isBusinessUnitApprover() ||
        this.authService.isBusinessUnitPlanner();

    if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
    this.companyNumberFormat =
      this.authService.getNumberFormat() == "noScale"
        ? ""
        : this.authService.getNumberFormat() == "thousands"
        ? "k"
        : this.authService.getNumberFormat() == "millions"
        ? "m"
        : "n/a";
    this.companyCurrency = this.authService.getCompanyCurrency();

    this.translate.get("instructionsTimeline.today").subscribe((data: any) => {
      this.todayS = data;
    });

    translate.onLangChange.subscribe((lang) => {
      this.translate
        .get("instructionsTimeline.today")
        .subscribe((data: any) => {
          this.todayS = data;
        });
    });
  }

  ngOnInit() {
    this.guidanceService.getGuidance().subscribe((result: any) => {
      this.guidanceId = result.id;

      this.guidanceService.listAll().subscribe((result) => {
        this.guidanceList = result;
        this.setGuidanceScenario();
      });
    });
    this.productsService.listAll().subscribe((result: any) => {
      this.productList = result;
    });
    this.launchTimelineService.listAll().subscribe((data) => {
      if (data) {
        data.forEach((element) => {
          if (element.isHeader == true) {
            this.header = element;
          }
        });
      } else this.header = null;
    });
  }

  setGuidanceScenario() {
    this.scenarioId = null;
    this.period = [];
    this.scenarioGuiList = [];

    this.guidanceName = this.guidanceList.find(
      (w) => w.id == this.guidanceId
    ).planningCycleName;

    this.scenarioService
      .listScenariosByGuidance(this.guidanceId, 0)
      .subscribe((result) => {
        this.scenarioGuiList = result;
        result.forEach((element) => {
          if (element.isBase == true) {
            this.scenarioId = element.id;
          }
        });
      });
  }

  isValid() {
    if (this.scenarioControl.valid == false) {
      this.translate.get("reports.allrequired").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    } else if (this.scenarioControl.valid == true) {
      this.run();
    }
  }

  run() {
    this.isRun = false;

    this.data1 = [];
    this.data2 = [];
    this.data3 = [];
    this.data4 = [];
    this.data5 = [];

    this.launchTimelineService
      .launchTimeline(this.scenarioId, 0)
      .subscribe((data) => {
        if (data.data1[0].length == 0) {
          this.translate.get("reports.warning2").subscribe((data: any) => {
            this.toastrService.error(data);
          });
          return;
        }

        this.data = data;

        var first: any = data.data1[0][0].StartDate;
        var last: any = data.data1[0][0].EndDate;

        if (data.data1[0][0]) {
          this.data1 = data.data1[0];
          last = data.data1[data.data1.length - 1];
        }
        if (data.data2[0][0]) {
          this.data2 = data.data2[0];
          last = data.data2[data.data2.length - 1];
        }
        if (data.data3[0][0]) {
          this.data3 = data.data3[0];
          last = data.data3[data.data3.length - 1];
        }
        if (data.data4[0][0]) {
          this.data4 = data.data4[0];
          last = data.data4[data.data4.length - 1];
        }
        if (data.data5[0][0]) {
          this.data5 = data.data5[0];
          last = data.data5[data.data5.length - 1];
        }

        this.plantingDays = new Date(first);
        this.days = this.dateDiffInDays(
          new Date(first),
          new Date(last[last.length - 1].EndDate)
        );

        this.createChart();
      });
  }

  take() {
    if (this.isRun != true) {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    }
    this.captureService
      .getImage(this.screen.nativeElement, true, 400)
      .pipe(
        tap((img) => {
          this.img = img;
        })
      )
      .subscribe();
  }

  reset() {
    this.img = "";
  }

  setScenario(id: number) {
    for (let sc of Object.keys(this.scenarioGuiList)) {
      if (id == this.scenarioGuiList[sc].id) {
        return this.scenarioGuiList[sc].name;
      }
    }
  }

  setLaunchList() {
    this.launchList = [];
    this.launchTimelineService.list(this.scenarioId).subscribe((data) => {
      if (data) {
        data.forEach((element) => {
          if (element.isHeader != true) {
            this.launchList.push(element);
          }
        });
      }
    });
  }

  setLaunch(id: number) {
    if (id == 0) return "All Products";
    for (let sc of Object.keys(this.launchList)) {
      if (id == this.launchList[sc].id) return this.launchList[sc].launchName;
    }
  }

  save() {
    var array = this.img.split(",");
    this.reportScreenshot.image = array[1];
    this.reportScreenshot.guidanceId = this.guidanceId;
    this.reportScreenshot.name = `${this.setScenario(this.scenarioId)}}`;
    this.reportScreenshot.reportName = "Launch Dates";

    this.reportScreenshotService
      .addReportScreenshot(this.reportScreenshot)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
  }

  createChart() {
    const that = this;
    this.chartData = {
      labels: this.data1.map((t) => t.LaunchName),
      datasets: [
        {
          data: this.data1.map((t) => {
            return this.dateDiffInDays(
              new Date(this.plantingDays),
              new Date(t.StartDate)
            );
          }),
          label: "",
          datalabels: {
            color: "#025ced",
            formatter: function (value, context) {
              return "";
            },
          },
          backgroundColor: "rgba(63,103,126,0)",
          hoverBackgroundColor: "rgba(63,103,126,0)",
        },
        {
          data: this.data1.map((t) => {
            return this.dateDiffInDays(
              new Date(t.StartDate),
              new Date(t.EndDate)
            );
          }),
          label: `${this.header.field1 ? this.header.field1 : "no field"}`,
          datalabels: {
            color: "#025ced",
            formatter: function (value, context) {
              return "";
            },
          },
          backgroundColor: "darkseagreen",
        },
        {
          data: this.data2.map((t) => {
            return this.dateDiffInDays(
              new Date(t.StartDate),
              new Date(t.EndDate)
            );
          }),
          label: `${this.header.field2 ? this.header.field2 : "no field"}`,
          datalabels: {
            color: "#025ced",
            formatter: function (value, context) {
              return "";
            },
          },
          backgroundColor: "mediumseagreen",
        },
        {
          data: this.data3.map((t) => {
            return this.dateDiffInDays(
              new Date(t.StartDate),
              new Date(t.EndDate)
            );
          }),
          label: `${this.header.field3 ? this.header.field3 : "no field"}`,
          datalabels: {
            color: "#025ced",
            formatter: function (value, context) {
              return "";
            },
          },
          backgroundColor: "seagreen",
        },
        {
          data: this.data4.map((t) => {
            return this.dateDiffInDays(
              new Date(t.StartDate),
              new Date(t.EndDate)
            );
          }),
          label: `${this.header.field4 ? this.header.field4 : "no field"}`,
          datalabels: {
            color: "#025ced",
            formatter: function (value, context) {
              return "";
            },
          },
          backgroundColor: "#2B6C26",
        },
        {
          data: this.data5.map((t) => {
            return this.dateDiffInDays(
              new Date(t.StartDate),
              new Date(t.EndDate)
            );
          }),
          label: `${this.header.field5 ? this.header.field5 : "no field"}`,
          datalabels: {
            color: "#025ced",
            formatter: function (value, context) {
              return "";
            },
          },
          backgroundColor: "#244F22",
        },
        {
          label: `${that.todayS}`,
          xAxisID: "02",
          barPercentage: 0,
          data: [],
          backgroundColor: "red",
          datalabels: {
            color: "#025ced",
            formatter: function (value, context) {
              return "";
            },
          },
        },
      ],
    };

    this.options = {
      maintainAspectRatio: true,
      title: {
        display: true,
        text: "Launch Dates",
      },
      legend: {
        onClick: false,
        position: "bottom",
        labels: {
          filter: function (item, chart) {
            return !item.text.includes("no field");
          },
        },
      },
      tooltips: {
        mode: "index",
        callbacks: {
          label: function (tooltipItem, d) {
            if (d.datasets[tooltipItem.datasetIndex].label != "") {
              let days = 0;
              for (
                var index = 1;
                index < tooltipItem.datasetIndex + 1;
                index++
              ) {
                days +=
                  d.datasets[index].data[tooltipItem.index] *
                  (1000 * 60 * 60 * 24);
              }

              var date = new Date(that.plantingDays);
              var newDate: any = date.getTime() + days;
              var finalDate: Date = new Date(newDate);
              var toDate = that.getDate(finalDate);
              var label =
                d.datasets[tooltipItem.datasetIndex].label + "," + " " + toDate;

              return label;
            }
          },
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true, //timelinelarda bu true olmalı !
            id: "x-axis-0",
            max: this.days,
            ticks: {
              scaleOverride: true,
              beginAtZero: true,
              stepSize: 1,
              max: this.days,
              callback: function (value, index) {
                var date = new Date(that.plantingDays);
                var days: number = value * (1000 * 60 * 60 * 24);
                var newDate: any = date.getTime() + days;
                var finalDate: Date = new Date(newDate);

                var isBreakDate: boolean = index == 0 ? true : false;

                Object.keys(that.data).forEach((key) => {
                  that.data[key][0].forEach((element) => {
                    if (
                      new Date(element.startDate).getDate() ==
                        new Date(finalDate).getDate() &&
                      new Date(element.startDate).getMonth() ==
                        new Date(finalDate).getMonth() &&
                      new Date(element.startDate).getFullYear() ==
                        new Date(finalDate).getFullYear()
                    )
                      isBreakDate = true;

                    if (
                      new Date(element.EndDate).getDate() ==
                        new Date(finalDate).getDate() &&
                      new Date(element.EndDate).getMonth() ==
                        new Date(finalDate).getMonth() &&
                      new Date(element.EndDate).getFullYear() ==
                        new Date(finalDate).getFullYear()
                    )
                      isBreakDate = true;
                  });
                });

                return isBreakDate ? that.getDate(finalDate) : null;
              },
              autoSkip: false,
            },
          },
          {
            id: "02",
            type: "linear",
            gridLines: {
              drawBorder: false,
              color: "red",
            },
            position: "top",
            ticks: {
              scaleOverride: true,
              fontColor: "red",
              beginAtZero: true,
              stepSize: 1,
              max: this.days,
              callback: function (value) {
                var date = new Date(that.plantingDays);
                var days: number = value * (1000 * 60 * 60 * 24);
                var newDate: any = date.getTime() + days;
                var finalDate: Date = new Date(newDate);

                return new Date(finalDate).getDate() == new Date().getDate() &&
                  new Date(finalDate).getMonth() == new Date().getMonth() &&
                  new Date(finalDate).getFullYear() == new Date().getFullYear()
                  ? `${that.todayS}`
                  : null;
              },
              autoSkip: false,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
      },
    };

    this.isRun = true;
  }

  getDate(date) {
    return (
      ("0" + date.getDate()).substr(-2) +
      "." +
      (date.getMonth() == 0
        ? "01"
        : date.getMonth() == 1
        ? "02"
        : date.getMonth() == 2
        ? "03"
        : date.getMonth() == 3
        ? "04"
        : date.getMonth() == 4
        ? "05"
        : date.getMonth() == 5
        ? "06"
        : date.getMonth() == 6
        ? "07"
        : date.getMonth() == 7
        ? "08"
        : date.getMonth() == 8
        ? "09"
        : date.getMonth() == 9
        ? "10"
        : date.getMonth() == 10
        ? "11"
        : date.getMonth() == 11
        ? "12"
        : date.getMonth()) +
      "." +
      date.getFullYear()
    );
  }

  dateDiffInDays(a, b) {
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / this.MS_PER_DAY);
  }

  selectYear(value: any) {}
}
