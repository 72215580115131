import { AbstractEnum } from "../base/AbstractEnum";

export class EnumCurrency extends AbstractEnum {
  static readonly USD = new EnumCurrency("United States Dollar");
  static readonly EUR = new EnumCurrency("Euro");
  static readonly TRY = new EnumCurrency("Turkish Lira");
  static readonly JPY = new EnumCurrency("Japanese Yen");
  static readonly GBP = new EnumCurrency("Pound Sterling");
  static readonly AUD = new EnumCurrency("Australian Dollar");
  static readonly CAD = new EnumCurrency("Canadian Dollar");
  static readonly CHF = new EnumCurrency("Swiss Franc");
  static readonly CNY = new EnumCurrency("Chinese Yuan Renminbi");
  static readonly HKD = new EnumCurrency("Hong Kong Dollar");
  static readonly NZD = new EnumCurrency("New Zealand Dollar");
  static readonly SEK = new EnumCurrency("Swedish Krona");
  static readonly KRW = new EnumCurrency("South Korean Won");
  static readonly SGD = new EnumCurrency("Singapore Dollar");
  static readonly NOK = new EnumCurrency("Norwegian Krone");
  static readonly MXN = new EnumCurrency("Mexican Peso");
  static readonly INR = new EnumCurrency("Indian Rupee");
  static readonly RUB = new EnumCurrency("Russian Ruble");
  static readonly ZAR = new EnumCurrency("South African Rand");
  static readonly BRL = new EnumCurrency("Brazilian Real");
  static readonly AED = new EnumCurrency("UAE Dirham");
  static readonly ILS = new EnumCurrency("Israel Shekel");
  static readonly PLN = new EnumCurrency("Polish Zloty");
  static readonly SAR = new EnumCurrency("Saudi Riyal");

  static values = AbstractEnum.getValues(EnumCurrency);

  private constructor(public readonly displayValue: string) {
    super();
  }
}
