import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { Supersettings } from "@planard/@core/entities/supersettings";

@Injectable()
export class SupersettingsApi {
  private readonly apiController: string = "supersettings";

  constructor(private api: HttpService) {}

  listAll<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAll`);
  }

  getLastMacro<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/getLastMacro`);
  }

  add<T>(supersettings: Supersettings): Observable<any> {
    return this.api.post(`${this.apiController}/add`, supersettings);
  }

  update<T>(supersettings: Supersettings): Observable<any> {
    return this.api.post(`${this.apiController}/update`, supersettings);
  }

  delete<T>(id: number): Observable<any> {
    var json = { id: id };
    return this.api.post(`${this.apiController}/delete`, json);
  }
}
