import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "@planard/@core/backend/api/http.service";

@Injectable()
export class ActualsApi {
  private readonly apiController: string = "actuals";

  constructor(private api: HttpService) {}

  listAllFiles<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAllFiles`);
  }

  listFilePeriod<T>(type: any): Observable<any> {
    return this.api.get(`${this.apiController}/listFilePeriods/${type}`);
  }

  uploadPrice<T>(data: any): Observable<any> {
    return this.api.post(`${this.apiController}/uploadPrice`, data);
  }

  uploadForecast<T>(data: any): Observable<any> {
    return this.api.post(`${this.apiController}/uploadForecast`, data);
  }

  uploadOtherForecast<T>(data: any): Observable<any> {
    return this.api.post(`${this.apiController}/uploadOtherForecast`, data);
  }

  uploadCost<T>(data: any): Observable<any> {
    return this.api.post(`${this.apiController}/uploadCost`, data);
  }

  uploadCentralCost<T>(data: any): Observable<any> {
    return this.api.post(`${this.apiController}/uploadCentralCost`, data);
  }

  delete<T>(fileId: number): Observable<any> {
    var json = { id: fileId };
    return this.api.post(`${this.apiController}/delete`, json);
  }
}
