import { Observable } from "rxjs";
import { CentralPlan } from "@planard/@core/entities/centralPlan";

export abstract class CentralPlanData {
  abstract listCost(
    scenarioId: number,
    approveRequestId?: number
  ): Observable<any>;

  abstract update(centralPlan: CentralPlan): Observable<any>;
}
