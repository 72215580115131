import { Routes } from "@angular/router";
import { EconomicReportsComponent } from "./ecenomic-reports/economic-reports.component";

export const EconomicReportsRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: EconomicReportsComponent,
        data: {
          title: "Economic Reports",
          urls: [{ title: "Economic Reports", url: "" }],
        },
      },
    ],
  },
];
