import { Observable } from "rxjs";
import { Price } from "@planard/@core/entities/price";
import { Products } from "../product";

export abstract class PriceData {
  abstract listPrices(
    scenarioId: number,
    approveRequestId?: number
  ): Observable<any>;

  abstract update(price: Price): Observable<any>;

  abstract listPriceTrend(
    scenarioId: number,
    productId: number,
    formId: number,
    channelId: number,
    actuals: number,
    period: number,
    currencyId: number
  ): Observable<any>;

  abstract listPriceProducts(scenarioId: number): Observable<Products[]>;

  abstract listPriceChannels(
    scenarioId: number,
    productId: number
  ): Observable<Products[]>;

  abstract listPriceForms(
    scenarioId: number,
    productId: number
  ): Observable<Products[]>;
}
