import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AgGridAngular } from "ag-grid-angular";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { Scenario } from "@planard/@core/entities/scenario";
import { MatDialog } from "@angular/material/dialog";
import { Overlay } from "@angular/cdk/overlay";
import { Guidance } from "@planard/@core/entities/guidance";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";
import { NgxCaptureService } from "ngx-capture";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { FxRate } from "@planard/@core/entities/fxRate";
import { tap } from "rxjs/operators";
import { ReportsService } from "@planard/@core/backend/service/reports.service";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import { NumberDecimalReversePipe } from "@planard/@core/pipes/NumberDecimalReversePipe";
import * as XLSX from "xlsx";
@Component({
  selector: "app-actual-plan",
  templateUrl: "./actual-plan.component.html",
  styleUrls: ["./actual-plan.component.scss"],
  providers: [NumberDecimalPipe, NumberDecimalReversePipe],
})
export class ActualPlanComponent implements OnInit {
  @ViewChild("agGrid") agGrid: AgGridAngular;

  private gridApi;
  private gridColumnApi;

  scenarioList: Scenario[];

  guidanceList: Guidance[];
  scenarioGuiList: Scenario[] = [];

  indicatorList = [
    { value: 0, label: "Units" },
    { value: 1, label: "Amount" },
  ];

  granularityList = [
    { value: 0, label: "Business Unit" },
    { value: 1, label: "Product Family" },
    { value: 2, label: "Product" },
  ];

  currentYear = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  years = [
    this.currentYear - 1,
    this.currentYear - 2,
    this.currentYear - 3,
    this.currentYear - 4,
    this.currentYear - 5,
  ];

  months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  currency: any = 0;
  granularity: any = 2;
  indicator: number = 1;
  year: number = this.years[0];
  month: any = this.months[0];
  scenarioId: number;
  guidance: any;
  currentGuidanceId;

  reportScreenshot: ReportScreenshot = {};
  img = "";
  isSet: boolean;
  imgName: any;
  imgDescription: any;
  file_name;

  @ViewChild("screen", { static: true }) screen: any;

  volume: boolean;
  amount: boolean;

  //for exel import and read
  file: File;
  filelist: any;
  arrayBuffer: any;

  fxrateList: FxRate[];
  guidanceFxRateList: any[];

  isRoleValid: boolean;

  defaultColDef = {
    //editable: true, //this.cellEditable,
    sortable: false,
    flex: 1,
    minWidth: 60,
    filter: false,
    resizable: true,
    cellEditor: "numericCellEditor",
  };

  components = { numericCellEditor: this.getNumericCellEditor() };
  columnDefs = [];

  rowData: any;
  fullData: any;

  loadingTemplate: any;
  noRowsTemplate: any;
  isRun: boolean;

  currentplanperiods: GuidancePlanningPeriod[]; //header için
  oldplanperiods: GuidancePlanningPeriod[] = []; //header için
  allPlansPeriods = [];

  exelHeaderList: any[] = [];
  excelGridData = [];
  data2Exel = [];

  companyCurrency: string;
  companyNumberFormat: string;

  numberFormat: string;
  decimalFormat: string;

  scenarioControl = new FormControl("", [Validators.required]);
  indicatorControl = new FormControl("", [Validators.required]);
  granularityControl = new FormControl("", [Validators.required]);
  yearControl = new FormControl("", [Validators.required]);
  monthControl = new FormControl("", [Validators.required]);
  guidanceControl = new FormControl("", [Validators.required]);
  currencyControl = new FormControl("", [Validators.required]);

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public toastrService: ToastrService,
    public reportsService: ReportsService,
    public actualsService: ActualsService,
    public guidanceService: GuidanceService,
    private numberDecimal: NumberDecimalPipe,
    public translate: TranslateService,
    private router: Router,
    public authService: AuthService,
    public reportScreenshotService: ReportScreenshotService,
    private captureService: NgxCaptureService,
    public scenarioService: ScenarioService,
    public dialog: MatDialog
  ) {
    this.translate.get("agGrid.report").subscribe((data: any) => {
      let msg = data;
      this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
    });
    this.translate.get("agGrid.no").subscribe((data: any) => {
      let msg = data;
      this.noRowsTemplate = `"<span">${data}</span>"`;
    });

    translate.onLangChange.subscribe((lang) => {
      this.translate.get("agGrid.report").subscribe((data: any) => {
        let msg = data;
        this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
      });
      this.translate.get("agGrid.no").subscribe((data: any) => {
        let msg = data;
        this.noRowsTemplate = `"<span">${data}</span>"`;
      });
    });

    this.isRoleValid =
      this.authService.isAdmin() ||
      this.authService.isBusinessUnitApprover() ||
      this.authService.isBusinessUnitPlanner();
    if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();
    this.companyNumberFormat =
      this.authService.getNumberFormat() == "noScale"
        ? ""
        : this.authService.getNumberFormat() == "thousands"
        ? "k"
        : this.authService.getNumberFormat() == "millions"
        ? "m"
        : "n/a";
    this.companyCurrency = this.authService.getCompanyCurrency();
  }

  ngOnInit() {
    this.guidanceService.getGuidance().subscribe((result: any) => {
      this.currentGuidanceId = result.id;

      this.guidanceService.getFxRate(result.id).subscribe((data) => {
        this.guidanceFxRateList = data;
      });

      this.guidanceService.listAll().subscribe((result3) => {
        this.guidanceList = result3.sort((a, b) => b.id - a.id);
        this.guidance = this.guidanceList.filter(
          (x) => x.id == this.currentGuidanceId
        )[0];
      });
    });

    this.scenarioService.listScenariosWithSales().subscribe((result) => {
      this.scenarioList = result;
      result.forEach((element) => {
        if (element.isBase == true) {
          this.scenarioId = element.id;
        }
      });
    });
  }

  onPlanChange() {
    this.scenarioService
      .listScenariosByGuidance(this.guidance.id, 1)
      .subscribe((result) => {
        this.scenarioList = result;
        result.forEach((element) => {
          if (element.isBase == true) {
            this.scenarioId = element.id;
          }
        });
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }

  isValid() {
    if (
      this.scenarioControl.valid == false ||
      this.indicatorControl.valid == false ||
      this.granularityControl.valid == false ||
      this.yearControl.valid == false ||
      this.monthControl.valid == false ||
      this.guidanceControl.valid == false ||
      this.currencyControl.valid == false
    ) {
      this.translate.get("reports.allrequired").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    } else if (
      this.scenarioControl.valid == true &&
      this.indicatorControl.valid == true &&
      this.granularityControl.valid == true &&
      this.yearControl.valid == true &&
      this.monthControl.valid == true &&
      this.guidanceControl.valid == true &&
      this.currencyControl.valid == true
    ) {
      this.run();
    }
  }

  run() {
    this.reportsService
      .listActualPlan(
        this.indicator,
        this.granularity,
        this.year,
        this.month.value,
        this.scenarioId,
        this.currency
      )
      .subscribe((result) => {
        if (!result.data[0]) {
          this.translate.get("reports.warning3").subscribe((data: any) => {
            this.toastrService.error(data);
          });
          return;
        }

        this.isRun = true;

        this.fullData = result.data;
        this.rowData = result.data;
        this.change();
        this.setTotalRow();
      });
  }

  getFxRate(fxRateId) {
    if (fxRateId == 0) {
      return "Default Currency";
    }
    for (let index in this.fxrateList) {
      if (fxRateId == this.fxrateList[index].id) {
        return this.fxrateList[index].name;
      }
    }
  }

  setTotalRow() {
    let totalRow: any = [...this.fullData[0]];
    totalRow.name = "TOTAL : ";

    let m_actual = 0;
    let m_plan = 0;
    let m_previous = 0;
    let y_actual = 0;
    let y_plan = 0;
    let y_previous = 0;
    let f_plan = 0;
    let f_previous = 0;

    this.fullData.forEach((row) => {
      m_actual = m_actual + (row.m_actual != null ? row.m_actual : 0);
      m_plan = m_plan + (row.m_plan != null ? row.m_plan : 0);
      m_previous = m_previous + (row.m_previous != null ? row.m_previous : 0);
      y_actual = y_actual + (row.y_actual != null ? row.y_actual : 0);
      y_plan = y_plan + (row.y_plan != null ? row.y_plan : 0);
      y_previous = y_previous + (row.y_previous != null ? row.y_previous : 0);
      f_plan = f_plan + (row.f_plan != null ? row.f_plan : 0);
      f_previous = f_previous + (row.f_previous != null ? row.f_previous : 0);
    });

    totalRow.m_actual = m_actual;
    totalRow.m_plan = m_plan;
    totalRow.m_previous = m_previous;
    totalRow.y_actual = y_actual;
    totalRow.y_plan = y_plan;
    totalRow.y_previous = y_previous;
    totalRow.f_plan = f_plan;
    totalRow.f_previous = f_previous;

    this.gridApi.setPinnedBottomRowData([totalRow]);
  }

  getColDef() {
    return {
      headerName: this.granularityList[this.granularity].label,
      lockPosition: true,
      pinned: "left",
      field: "name",
      editable: this.cellEditable,
      minWidth: 150,
      visible: false,
      cellRenderer: this.productCellRenderer,
    };
  }

  cellEditable(params) {
    return false;
  }

  productCellRenderer(params) {
    let style = "";
    if (params.data.name == "TOTAL : ") {
      // total satırıysa
      return (
        `<span ` +
        style +
        `></span> ` +
        `<span style="color: #ff7500;">` +
        params.value +
        `</span>`
      );
    }
    return params.value;
  }

  getColumn1Def() {
    let numberDecimal = this.numberDecimal;
    let decimalFormat = this.decimalFormat;
    let numberFormat = this.numberFormat;

    let month = this.month;
    let year = this.year;
    let guidance = this.guidance;

    let excelGridData = this.excelGridData;

    return {
      // Group 1
      headerName: `${month.label} ${year}`,
      children: [
        {
          headerName: "Act",
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return params.data["m_actual"];
          },
          cellStyle: function (params) {},
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal = numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            }

            excelGridData.push({
              parentHeader: `${month.label} ${year}`,
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
        {
          headerName: `${guidance.planningCycleName}`,
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return params.data["m_plan"];
          },
          cellStyle: function (params) {},
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal = numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            }

            excelGridData.push({
              parentHeader: `${month.label} ${year}`,
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
        {
          headerName: `% ${guidance.planningCycleName}`,
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            //[(V2-V1)/V1] x 100
            return (
              ((params.data["m_actual"] - params.data["m_plan"]) /
                params.data["m_plan"]) *
              100
            );
          },
          cellStyle: function (params) {
            if (params.value >= 0)
              return { color: "#4CBB17", "font-weight": "600" };
            else return { color: "tomato", "font-weight": "600" };
          },
          valueFormatter: function (params) {
            var returnVal: any;

            if (params.value == -100) params.value = 0;
            if (
              !params.value ||
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal =
                numberDecimal.transform(
                  params.value,
                  decimalFormat,
                  numberFormat
                ) + "%";
            }

            excelGridData.push({
              parentHeader: `${month.label} ${year}`,
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
        {
          headerName: `PY`,
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return params.data["m_previous"];
          },
          cellStyle: function (params) {},
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              !params.value ||
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal = numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            }

            excelGridData.push({
              parentHeader: `${month.label} ${year}`,
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
        {
          headerName: `% PY`,
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return (
              ((params.data["m_actual"] - params.data["m_previous"]) /
                params.data["m_previous"]) *
              100
            );
          },
          cellStyle: function (params) {
            if (params.value >= 0)
              return {
                color: "#4CBB17",
                "border-right": "1px solid rgb(185, 184, 184) !important;",
                "font-weight": "600",
              };
            else
              return {
                color: "tomato",
                "border-right": "1px solid rgb(185, 184, 184) !important;",
                "font-weight": "600",
              };
          },
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              !params.value ||
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal =
                numberDecimal.transform(
                  params.value,
                  decimalFormat,
                  numberFormat
                ) + "%";
            }

            excelGridData.push({
              parentHeader: `${month.label} ${year}`,
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
      ],
    };
  }

  getColumn2Def() {
    let numberDecimal = this.numberDecimal;
    let decimalFormat = this.decimalFormat;
    let numberFormat = this.numberFormat;

    let guidance = this.guidance;

    let excelGridData = this.excelGridData;

    return {
      // Group 2
      headerName: `Year To Date (YTD)`,
      children: [
        {
          headerName: "Act",
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return params.data["y_actual"];
          },
          cellStyle: function (params) {},
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal = numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            }

            excelGridData.push({
              parentHeader: "Year To Date (YTD)",
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
        {
          headerName: `${guidance.planningCycleName}`,
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return params.data["y_plan"];
          },
          cellStyle: function (params) {},
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal = numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            }

            excelGridData.push({
              parentHeader: "Year To Date (YTD)",
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
        {
          headerName: `% ${guidance.planningCycleName}`,
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            //[(V2-V1)/V1] x 100
            return (
              ((params.data["y_actual"] - params.data["y_plan"]) /
                params.data["y_plan"]) *
              100
            );
          },
          cellStyle: function (params) {
            if (params.value >= 0)
              return { color: "#4CBB17", "font-weight": "600" };
            else return { color: "tomato", "font-weight": "600" };
          },
          valueFormatter: function (params) {
            var returnVal: any;

            if (params.value == -100) params.value = 0;
            if (
              !params.value ||
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal =
                numberDecimal.transform(
                  params.value,
                  decimalFormat,
                  numberFormat
                ) + "%";
            }

            excelGridData.push({
              parentHeader: "Year To Date (YTD)",
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
        {
          headerName: `PY`,
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return params.data["y_previous"];
          },
          cellStyle: function (params) {},
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              !params.value ||
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal = numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            }

            excelGridData.push({
              parentHeader: "Year To Date (YTD)",
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
        {
          headerName: `% PY`,
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return (
              ((params.data["y_actual"] - params.data["y_previous"]) /
                params.data["y_previous"]) *
              100
            );
          },
          cellStyle: function (params) {
            if (params.value >= 0)
              return {
                color: "#4CBB17",
                "border-right": "1px solid rgb(185, 184, 184) !important;",
                "font-weight": "600",
              };
            else
              return {
                color: "tomato",
                "border-right": "1px solid rgb(185, 184, 184) !important;",
                "font-weight": "600",
              };
          },
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              !params.value ||
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal =
                numberDecimal.transform(
                  params.value,
                  decimalFormat,
                  numberFormat
                ) + "%";
            }

            excelGridData.push({
              parentHeader: "Year To Date (YTD)",
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
      ],
    };
  }

  getColumn3Def() {
    var indicator = this.indicator;
    let numberDecimal = this.numberDecimal;
    let decimalFormat = this.decimalFormat;
    let numberFormat = this.numberFormat;

    let guidance = this.guidance;

    let excelGridData = this.excelGridData;

    return {
      // Group 2
      headerName: `Full Year (FY)`,
      borders: true,
      children: [
        {
          headerName: `${guidance.planningCycleName}`,
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return params.data["f_plan"];
          },
          cellStyle: function (params) {},
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal = numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            }

            excelGridData.push({
              parentHeader: "Full Year (FY)",
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
        {
          headerName: `PY`,
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return params.data["f_previous"];
          },
          cellStyle: function (params) {},
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal = numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            }

            excelGridData.push({
              parentHeader: "Full Year (FY)",
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
        {
          headerName: `${guidance.planningCycleName} / PY`,
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return (
              ((params.data["f_plan"] - params.data["f_previous"]) /
                params.data["f_previous"]) *
              100
            );
          },
          cellStyle: function (params) {
            if (params.value >= 0)
              return {
                color: "#4CBB17",
                "border-right": "1px solid rgb(185, 184, 184) !important;",
                "font-weight": "600",
              };
            else
              return {
                color: "tomato",
                "border-right": "1px solid rgb(185, 184, 184) !important;",
                "font-weight": "600",
              };
          },
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              !params.value ||
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal =
                numberDecimal.transform(
                  params.value,
                  decimalFormat,
                  numberFormat
                ) + "%";
            }

            excelGridData.push({
              parentHeader: "Full Year (FY)",
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
      ],
    };
  }

  getColumn4Def() {
    let numberDecimal = this.numberDecimal;
    let decimalFormat = this.decimalFormat;
    let numberFormat = this.numberFormat;
    let month = this.month;
    let year = this.year;
    let excelGridData = this.excelGridData;

    return {
      // Group 1
      headerName: `Monthly Avarage`,
      children: [
        {
          headerName: "YTD",
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return params.data["y_actual"] / month.value;
          },
          cellStyle: function (params) {},
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              !params.value ||
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal = numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            }

            excelGridData.push({
              parentHeader: "Monthly Avarage",
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
        {
          headerName: `To Go`,
          editable: false,
          columnGroupShow: "closed",
          type: "rightAligned",
          valueGetter: function (params) {
            return (
              (params.data["f_plan"] - params.data["y_actual"]) /
              (12 - month.value)
            );
          },
          cellStyle: function (params) {},
          valueFormatter: function (params) {
            var returnVal: any;

            if (
              !params.value ||
              params.value == undefined ||
              params.value == "NaN" ||
              Number.isNaN(params.value) ||
              params.value == "null" ||
              params.value == null
            )
              returnVal = "n/a";
            else {
              returnVal = numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            }

            excelGridData.push({
              parentHeader: "Monthly Avarage",
              rowName: params.data.name,
              headerName: params.colDef.headerName,
              value: returnVal,
            });

            return returnVal;
          },
        },
      ],
    };
  }

  getSelectedRows() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const selectedDataStringPresentation = selectedData
      .map((node) => node.make + " " + node.model)
      .join(", ");
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  }

  change() {
    this.columnDefs = [];
    this.columnDefs.push(this.getColDef());
    this.columnDefs.push(this.getColumn1Def());
    this.columnDefs.push(this.getColumn2Def());
    this.columnDefs.push(this.getColumn3Def());
    this.columnDefs.push(this.getColumn4Def());
    this.gridApi.setColumnDefs(this.columnDefs);
  }

  setScenario() {
    for (let sc of Object.keys(this.scenarioList)) {
      if (this.scenarioId == this.scenarioList[sc].id) {
        this.file_name =
          "Actuals vs Plan -" + this.scenarioList[sc].name + ".xlsx";
        return this.scenarioList[sc].name;
      }
    }
  }

  setExportData() {
    let header = [];
    let header2 = this.gridColumnApi
      .getAllDisplayedColumns()
      .map(function (col) {
        return col.userProvidedColDef.headerName;
      });

    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));

    for (let i = 1; i < this.columnDefs.length; i++) {
      header.push(this.columnDefs[i].headerName);
    }

    this.data2Exel = [];
    this.data2Exel.push([
      " ",
      header[0],
      "",
      "",
      "",
      "",
      header[1],
      "",
      "",
      "",
      "",
      header[2],
      "",
      "",
      header[3],
      "",
    ]);
    this.data2Exel.push([]);
    this.data2Exel.push(header2);
    this.data2Exel.push([]);

    this.fullData.forEach((row) => {
      let e_row = [row.name];
      this.columnDefs.forEach((def) => {
        // def.headerName -> parent name
        if (def.headerName != "Product") {
          def.children.forEach((child) => {
            // child.headerName -> header name
            for (let i = 0; i < this.excelGridData.length; i++) {
              if (
                this.excelGridData[i].rowName == row.name &&
                this.excelGridData[i].headerName == child.headerName &&
                this.excelGridData[i].parentHeader == def.headerName
              ) {
                e_row.push(this.excelGridData[i].value);
              }
            }
          });
        }
      });
      this.data2Exel.push(e_row);
    });
    this.export();
  }

  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
  fileName: string = "actualsvsplan-planard.xlsx";

  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2Exel, {
      cellStyles: true,
    });

    ws["!cols"] = [];
    ws["!cols"][0] = { wpx: 120 };

    for (let i = 1; i < 16; i++) {
      ws["!cols"][i] = { wpx: 85 };
    }

    ws["!rows"] = [];
    ws["!rows"][0] = { hpx: 30 };
    ws["!rows"][2] = { hpx: 30 };

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, this.fileName);
  }

  take() {
    if (this.isRun != true) {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    }
    this.captureService
      .getImage(this.screen.nativeElement, true, 400)
      .pipe(
        tap((img) => {
          this.img = img;
        })
      )
      .subscribe();
  }

  reset() {
    this.img = "";
  }

  save() {
    var array = this.img.split(",");
    this.reportScreenshot.image = array[1];
    this.reportScreenshot.guidanceId = this.guidance.id;
    this.reportScreenshot.name = `${this.setScenario()} / ${
      this.indicatorList[this.indicator].label
    } / ${this.getFxRate(this.currency)}`;
    this.reportScreenshot.reportName = "Actual vs Plan";

    this.reportScreenshotService
      .addReportScreenshot(this.reportScreenshot)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
  }

  onCellClicked(event) {
    if (event.column.colId == "channel" || event.column.colId == "channel_1") {
      var parentId = event.data.id;
      var rows = [...this.fullData];

      let currentRow = rows.filter((row) => row.id == parentId)[0];
      let isOpen = currentRow.selected;

      let currentRows = rows.filter((row) => row.parentId == parentId);
      currentRows.forEach(function (row) {
        row.isVisible = !isOpen;
        if (isOpen) {
          row.selected = false;
          let subRows = rows.filter((r) => r.parentId == row.id);
          subRows.forEach(function (row) {
            row.isVisible = false;
          });
        }
      });
      currentRow.selected = !isOpen;
      rows = rows.filter((row) => row.isVisible || row.parentId == 0);
      this.gridApi.setRowData(rows);
    } else {
      return;
    }
  }

  openRows() {
    // rowların hepsini açma
    var rows = [...this.fullData];
    rows.forEach(function (row) {
      if (row.parentId != 0) {
        row.isVisible = !row.isVisible;
        row.selected = !row.selected;
      } else {
        row.selected = !row.selected;
      }
    });
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
  }

  currentContextEvent: any;
  onCellContextMenu(event) {
    this.currentContextEvent = event;
  }

  getNumericCellEditor() {
    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }
    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr);
    }
    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === "undefined" ? event.keyCode : event.which;
    }
    function NumericCellEditor() {}
    NumericCellEditor.prototype.init = function (params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement("input");
      this.eInput.style.width = "100%";
      this.eInput.style.height = "100%";
      this.eInput.value = isCharNumeric(params.charPress)
        ? params.charPress
        : params.value;
      var that = this;
      this.eInput.addEventListener("keypress", function (event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };
    NumericCellEditor.prototype.getGui = function () {
      return this.eInput;
    };
    NumericCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        this.eInput.select();
      }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function () {
      return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.isCancelAfterEnd = function () {};
    NumericCellEditor.prototype.getValue = function () {
      return this.eInput.value;
    };
    NumericCellEditor.prototype.focusIn = function () {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
      console.log("NumericCellEditor.focusIn()");
    };
    NumericCellEditor.prototype.focusOut = function () {
      console.log("NumericCellEditor.focusOut()");
    };
    return NumericCellEditor;
  }
}
