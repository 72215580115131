import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";
import { Scenario } from "@planard/@core/entities/scenario";

@Injectable()
export class ScenarioApi {
  private readonly apiController: string = "scenario";
  constructor(private api: HttpService) {}

  listAll<T>(page?: number, pageSize?: number): Observable<any> {
    const endpointUrl =
      page && pageSize
        ? `${this.apiController}/listAll/${page}/${pageSize}`
        : `${this.apiController}/listAll`;
    return this.api.get(endpointUrl);
  }

  listScenariosWithImpact<T>(impactArea: string): Observable<any> {
    return this.api.get(
      `${this.apiController}/listScenariosWithImpact/${impactArea}`
    );
  }

  listScenariosWithSales<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listScenariosWithSales`);
  }

  listScenariosByGuidance<T>(
    guidanceId: number,
    type: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listScenariosByGuidance/${guidanceId}/${type}`
    );
  }

  listScenariosWithImpactWhole<T>(impactArea: string): Observable<any> {
    return this.api.get(
      `${this.apiController}/listScenariosWithImpactWhole/${impactArea}`
    );
  }

  listWhole<T>(page?: number, pageSize?: number): Observable<any> {
    const endpointUrl =
      page && pageSize
        ? `${this.apiController}/listWhole/${page}/${pageSize}`
        : `${this.apiController}/listWhole`;
    return this.api.get(endpointUrl);
  }

  add<T>(scenario: Scenario): Observable<any> {
    return this.api.post(`${this.apiController}/addScenario`, scenario);
  }

  update<T>(scenario: Scenario): Observable<any> {
    return this.api.post(`${this.apiController}/updateScenario`, scenario);
  }

  delete<T>(scenarioId: any): Observable<any> {
    var json = { id: scenarioId };
    const endPointUrl = `${this.apiController}/deleteScenario`;
    return this.api.post(endPointUrl, json);
  }
}
