import { NarrativeRequest } from "../../entities/narrativeRequest";
import { NarrativeApi } from "../api/narrative.api";
import { Injectable, EventEmitter } from "@angular/core";
import { SaleNarrative } from "@planard/@core/entities/saleNarrative";
import { CostNarrative } from "@planard/@core/entities/costNarrative";

@Injectable()
export class NarrativeService {
  onNarrativeReload: EventEmitter<any> = new EventEmitter<any>();
  onRequestReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: NarrativeApi) {}

  addSale(sale: SaleNarrative) {
    return this.api.addSale<any>(sale);
  }

  addCost(cost: CostNarrative) {
    return this.api.addCost<any>(cost);
  }

  addRequest(narrativeRequest: NarrativeRequest) {
    return this.api.addRequest<any>(narrativeRequest);
  }

  listAllSale(id: number, productId: any) {
    return this.api.listAllSale<SaleNarrative[]>(id, productId);
  }

  listAllCost(id: number, categoryId: any) {
    return this.api.listAllCost<CostNarrative[]>(id, categoryId);
  }

  listSalesRequests() {
    return this.api.listSalesRequests<NarrativeRequest[]>();
  }

  listCostRequests() {
    return this.api.listCostRequests<NarrativeRequest[]>();
  }

  listSalesRequestsWhole() {
    return this.api.listSalesRequestsWhole<NarrativeRequest[]>();
  }

  listCostRequestsWhole() {
    return this.api.listCostRequestsWhole<NarrativeRequest[]>();
  }

  updateSale(sale: SaleNarrative) {
    return this.api.updateSale<any>(sale);
  }

  updateCost(cost: CostNarrative) {
    return this.api.updateCost<any>(cost);
  }

  deleteSale(saleId: number) {
    return this.api.deleteSale<any>(saleId);
  }

  deleteCost(costId: number) {
    return this.api.deleteCost<any>(costId);
  }

  deleteRequest(requestId: number) {
    return this.api.deleteRequest<any>(requestId);
  }

  updateRequest(request: NarrativeRequest) {
    return this.api.updateRequest<any>(request);
  }

  salesBridge(
    requestId: number,
    granularity: number,
    category: any,
    currency: any
  ) {
    return this.api.salesBridge<any>(
      requestId,
      granularity,
      category,
      currency
    );
  }

  costBridge(
    requestId: number,
    categoryId: number,
    granularity: number,
    category: any,
    currency: any
  ) {
    return this.api.costBridge<any>(
      requestId,
      categoryId,
      granularity,
      category,
      currency
    );
  }

  submit(request: any) {
    return this.api.submit<any>(request);
  }
}
