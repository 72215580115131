import { Observable } from "rxjs";
import { Faq } from "../faq";

export abstract class FaqData {
  abstract listAll(): Observable<any[]>;

  abstract add(faq: Faq);

  abstract update(faq: Faq);

  abstract delete(faqId: number);
}
