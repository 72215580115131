import { AbstractEnum } from "../base/AbstractEnum";

export class EnumStatus extends AbstractEnum {
  static readonly NOTSTARTED = new EnumStatus("Not Started");
  static readonly INPROGRESS = new EnumStatus("In Progress");
  static readonly COMPLETED = new EnumStatus("Completed"); //user task da kapandıysa
  static readonly DUE = new EnumStatus("Due"); //user taskta kapanmadıysa

  static values = AbstractEnum.getValues(EnumStatus);

  private constructor(public readonly displayValue: string) {
    super();
  }
}
