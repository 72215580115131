import { EventEmitter, Injectable } from "@angular/core";
import { TimelineApi } from "@planard/@core/backend/api/timeline.api";
import { Observable } from "rxjs";
import { Process } from "@planard/@core/entities/process";

@Injectable()
export class TimelineService {
  onProcessReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: TimelineApi) {}

  listAll(): Observable<Process[]> {
    return this.api.listAll<Process[]>();
  }

  getCurrentTime(): Observable<any> {
    return this.api.getCurrentTime<any>();
  }

  listUserProcesses(): Observable<Process[]> {
    return this.api.listUserProcesses<Process[]>();
  }

  listProcessesTime(): Observable<any> {
    return this.api.listProcessesTime<any>();
  }

  listResponsibles(): Observable<string[]> {
    return this.api.listResponsibles<string[]>();
  }

  add(process: Process) {
    return this.api.add<any>(process);
  }

  update(process: Process) {
    return this.api.update<any>(process);
  }

  delete(processId: number): Observable<any> {
    return this.api.delete<any>(processId);
  }
}
