<h1 mat-dialog-title>Add Lock Date</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        [min]="today"
        [matDatepicker]="spicker"
        formControlName="lockDate"
        matInput
        name="lockDate"
        placeholder="Lock Date"
      />
      <mat-datepicker-toggle [for]="spicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #spicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="2" type="submit">Save</button>
    <button (click)="cancel()" mat-button tabindex="-1" type="button">
      Cancel
    </button>
  </div>
</form>
