import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { Guidance } from "@planard/@core/entities/guidance";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./planning-cycle-name-add.component.html",
})
export class PlanningCycleNameAddComponent {
  guidance: Guidance = new Guidance();

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, removeSpaces]),
  });
  options: FormGroup;
  guidanceId: number;
  guidanceName: string;

  constructor(
    public dialogRef: MatDialogRef<PlanningCycleNameAddComponent>,
    public guidanceService: GuidanceService,
    public toastrService: ToastrService,
    public fb: FormBuilder,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.guidanceId = data.guidanceId;
    this.guidanceName = data.guidanceName;
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.form.patchValue({ name: this.guidanceName });
  }

  save() {
    if (this.form.valid) {
      this.guidance.planningCycleName = this.form.get("name").value;
      this.guidance.id = this.guidanceId;
      this.guidanceService
        .updateCycleName(this.guidance)
        .subscribe((result) => {
          if (result.isSuccess) {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.success(data);
              });
            this.dialogRef.close(this.guidance);
          } else {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.error(data);
              });
          }
        });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
