import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { SupersettingsService } from "@planard/@core/backend/service/supersettings.service";
import { DomSanitizer } from "@angular/platform-browser";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";

@Component({
  templateUrl: "./economic-reports.component.html",
  styles: [
    `
      .toRightReverse {
        flex: 0 0 10%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class EconomicReportsComponent implements OnInit {
  isRoleValid: boolean = false;
  desc;
  file;
  fileUrl = null;
  companyPackage: string;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public supersettingsService: SupersettingsService,
    public authService: AuthService,
    private sanitizer: DomSanitizer,
    private router: Router,
    public toastr: ToastrService
  ) {
    this.isRoleValid = this.authService.isAdmin();

    this.companyPackage = this.authService.getCompanyPackage();

    if (this.companyPackage == "basic")
      this.isRoleValid =
        this.authService.isAdmin() ||
        this.authService.isPricePlanner() ||
        this.authService.isBusinessUnitPlanner() ||
        this.authService.isSupportFunctionPlanner();
    if (this.companyPackage == "premium") this.isRoleValid = true;

    if (!this.isRoleValid) this.router.navigate(["auth/404"]);
  }

  displayedColumns = [
    "description",
    "type",
    "createdDate",
    "country",
    "actions",
  ];

  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.supersettingsService.listAll().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  getUrl(b64Data) {
    const byteCharacters = atob(b64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(blob)
    );
  }
}
