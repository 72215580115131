import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { Products } from "@planard/@core/entities/product";
import { ProductsAddComponent } from "./add/products-add.component";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ToastrService } from "ngx-toastr";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { ProductFamilyService } from "@planard/@core/backend/service/product-family.service";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { ProductFamily } from "@planard/@core/entities/productFamily";
import { BusinessUnit } from "@planard/@core/entities/businessUnit";
import { Channel } from "@planard/@core/entities/channel";
import { MatSort, Sort } from "@angular/material/sort";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ProductForm } from "@planard/@core/entities/productForm";
import { ProductFormNewComponent } from "./productForm/productForm-new.component";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { MatSelect } from "@angular/material/select";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { removeSpaces } from "@planard/common/formValidator";
@Component({
  templateUrl: "./products-new.component.html",
  styles: [
    `
      .toRightReverse {
        flex: 0 0 5%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
      ::ng-deep .mat-option:first-child .mat-pseudo-checkbox {
        display: none;
      }
    `,
  ],
})
export class ProductsNewComponent implements OnInit {
  displayedColumns = [
    "name",
    "businessUnitId",
    "productFamilyId",
    "channels",
    "forms",
    "actions",
  ];
  dataSource = new MatTableDataSource<Products>();
  controls: any[];
  isRoleValid: boolean;
  guidanceId: number;
  allChannels: boolean;
  package: string;

  productFamilyList: ProductFamily[];
  businessUnitList: BusinessUnit[];
  channelList: Channel[];

  productFamilyBuList: ProductFamily[] = [];
  productFormList: [];
  businessU: any;

  products: Products;
  productForm: ProductForm;

  loadingIndicator: boolean = true;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public productFamilyService: ProductFamilyService,
    public businessUnitService: BusinessUnitService,
    public channelService: ChannelService,
    public productsService: ProductsService,
    public toastrService: ToastrService,
    public authService: AuthService,
    public guidanceService: GuidanceService,
    public translate: TranslateService,
    private router: Router,
    public changeDetectorRefs: ChangeDetectorRef
  ) {
    this.package = this.authService.getCompanyPackage();
    this.isRoleValid = this.authService.isAdmin();
    if (this.isRoleValid == false) this.router.navigate(["auth/404"]);

    this.productsService.onProductReload.subscribe(() => {
      this.getData();
    });
  }

  ngOnInit() {
    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidanceId = result.id;
    });

    this.productFamilyService.listAll().subscribe((result: any) => {
      this.productFamilyList = result;
      this.productFamilyBuList = [...result];
    });
    this.businessUnitService.listAll().subscribe((result: any) => {
      this.businessUnitList = result;
    });
    this.channelService.listAll().subscribe((result: any) => {
      this.channelList = result;
    });

    this.getData();
  }

  getData() {
    this.productsService.listAll().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data);

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "name", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              name: new FormControl(entity.name, [
                Validators.required,
                removeSpaces,
              ]),
              businessUnitId: new FormControl(entity.businessUnitId, [
                Validators.required,
              ]),
              productFamilyId: new FormControl(entity.productFamilyId, [
                Validators.required,
              ]),
              channels: new FormControl(entity.channels),
            },
            { updateOn: "change" }
          ),
        };
      });
    });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    var entity = this.getElement(index);
    entity[field] = control.value;

    let valid = true;
    let controlList = ["name", "businessUnitId", "productFamilyId"];
    controlList.forEach((element) => {
      if (entity[element] == null || "") valid = false;
    });

    if (valid) {
      this.productsService.update(entity).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          if (this.package == "basic") {
            this.startCycleAuto();
          }

          this.getData();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.getData();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      this.ngOnInit();
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  onAdd() {
    const dialogRef = this.dialog.open(ProductsAddComponent, {
      width: "500px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getData();
      }
    });
  }

  openForm(row: Products) {
    const dialogRef = this.dialog.open(ProductFormNewComponent, {
      width: "800px",
      data: row.id,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getData();
    });
  }

  onDelete(rowId: number) {
    this.translate.get("delete.product").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.productsService.delete(rowId).subscribe((backendResult) => {
            if (backendResult.isSuccess) {
              this.translate
                .get(`messages.${backendResult.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.getData();
            } else {
              this.translate
                .get(`messages.${backendResult.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.getData();
            }
          });
        }
      });
    });
  }

  productFamilySet(businessUnitId: number) {
    if (businessUnitId > 0) {
      this.productFamilyBuList.length = 0;
      for (let pf of Object.keys(this.productFamilyList)) {
        if (businessUnitId === this.productFamilyList[pf].businessUnitId) {
          this.productFamilyBuList.push(this.productFamilyList[pf]);
        }
      }
    }
  }

  selectAll(index, select: MatSelect) {
    var control = this.getControl(index, "channels");
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    control.setValue(selectedIds);
    this.allChannels = true;
  }

  deselectOne(id: number, select: MatSelect) {
    var control = this.getControl(id, "channels");
    if (this.isAll(control) == true) {
      this.allChannels = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && id != element.value.id) {
          selectedIds.push(element.value);
        }
      });
      control.setValue(selectedIds);
    }
  }

  isAll(selectedChannels: any) {
    if (selectedChannels.length == this.channelList.length) return true;
    else return false;
  }

  deselectAll(id) {
    var control = this.getControl(id, "channels");
    let selectedIds = [];
    control.setValue(selectedIds);
  }

  startCycleAuto() {
    let body = { guidanceId: this.guidanceId };
    this.guidanceService.startGuidance(body).subscribe((result) => {
      if (result.isSuccess) {
        console.log("guidance started!!!");
      } else {
        console.log("result: " + result.message);
        console.log("guidance cannot started!!!");
      }
    });
  }
}
