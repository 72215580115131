import { newArray } from "@angular/compiler/src/util";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "NumberDecimalReversePipe" })
export class NumberDecimalReversePipe implements PipeTransform {
  constructor() {}

  transform(value: string, decimal: string, number: string): number {
    if (decimal == "comma") {
      // ,
      if (number == "noScale") {
        //noktadan sonrası küsürat
        var xStr: any = value.split(",")[0];
        var xFrc = value.split(",")[1];
        if (xStr.split("").includes(".")) {
          var newStr = "";
          xStr.split("").forEach((element) => {
            if (element != ".") newStr += element;
          });
          xStr = newStr;
        }
        var xNum = xStr + "." + xFrc;
        return Number(xNum);
      } else if (number == "thousands") {
        return Number(value.replace(",", ".").replace(",", "")) * 1000;
      } else if (number == "millions") {
        return Number(value.replace(",", ".").replace(",", "")) * 1000000;
      }
    } else if (decimal == "dot") {
      // .
      if (number == "noScale") {
        //virgülden sonrası küsürat
        var newStr2 = "";
        if (value.split("").includes(",")) {
          value.split("").forEach((element) => {
            if (element != ",") newStr2 += element;
          });
          if (newStr2.split(".")[1].split("").length < 3) {
            for (let i = newStr2.split(".")[1].split("").length; i == 3; i++) {
              newStr2 += "0";
            }
          }
          return Number(newStr2);
        } else {
          if (value.split(".")[1].split("").length < 3) {
            for (let i = value.split(".")[1].split("").length; i == 3; i++) {
              value += "0";
            }
          }
          return Number(value);
        }
      } else if (number == "thousands") {
        return Number(value.replace(",", "")) * 1000;
      } else if (number == "millions") {
        return Number(value.replace(",", "")) * 1000000;
      }
    }
  }
}
