import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { LocalStorageService } from "@planard/auth/services/storage.service";

@Pipe({ name: "NumberDecimalPipe" })
export class NumberDecimalPipe implements PipeTransform {
  constructor(
    public decimalPipe: DecimalPipe,
    public storageService: LocalStorageService
  ) {}

  transform(value: number, decimal: string, number: string): string {
    if (value == undefined || Number.isNaN(value) || value == null) return "n/a";

    const companyDecimalFormat = this.storageService.get(
      "companyDecimalFormat"
    );
    const companyNumberFormat = this.storageService.get("companyNumberFormat");

    if (!companyDecimalFormat && decimal)
      this.storageService.set("companyDecimalFormat", decimal);
    if (!companyNumberFormat && number)
      this.storageService.set("companyNumberFormat", number);

    if (
      (!decimal && !companyDecimalFormat) ||
      (!number && !companyNumberFormat)
    )
      return value.toFixed(2);

    if (!decimal) decimal = companyDecimalFormat;
    if (!number) number = companyNumberFormat;

    var val;

    if (decimal == "dot") {
      // ,
      if (number == "noScale") {
        let format = "1.2-2";
        val = this.decimalPipe.transform(value, format);
      } else if (number == "thousands") {
        let format = "1.2-2";
        val = this.commaT(this.decimalPipe.transform(value, format));
      } else if (number == "millions") {
        let format = "1.2-2";
        val = this.commaM(this.decimalPipe.transform(value, format));
      }
    } else if (decimal == "comma") {
      // .
      if (number == "noScale") {
        let format = "1.2-2";
        let newV = this.decimalPipe.transform(value, format);
        val = newV;
      } else if (number == "thousands") {
        let format = "1.2-2";
        val = this.dotT(this.decimalPipe.transform(value, format));
      } else if (number == "millions") {
        let format = "1.2-2";
        val = this.dotM(this.decimalPipe.transform(value, format));
      }
    }

    if (val == "null" || val == null) return "n/a";
    return val;
  }

  commaT(nStr) {
    nStr = nStr.split(".")[0];
    var l = nStr.split("");
    var x = nStr.split(",")[0] + "," + nStr.split(",")[1];
    if (l.length >= 7) return x;
    else if (l.length < 4) return String(Number(nStr) / 1000);
    else return nStr;
  }

  commaM(nStr) {
    nStr = nStr.split(".")[0];
    var l = nStr.split("");
    var x = nStr.split(",")[0];
    if (l.length >= 5) return x;
    else if (l.length < 4) return String(Number(nStr) / 1000000);
    else return nStr;
  }

  dotT(nStr) {
    nStr = nStr.split(".")[0];
    var l = nStr.split("");
    var x = nStr.split(",")[0] + "." + nStr.split(",")[1];
    if (l.length >= 7) return x;
    else if (l.length < 4) return String(Number(nStr) / 1000).replace(".", ",");
    else return nStr.replace(",", ".");
  }

  dotM(nStr) {
    nStr = nStr.split(".")[0];
    var l = nStr.split("");
    var x = nStr.split(",")[0];
    if (l.length >= 5) x;
    else if (l.length < 4)
      return String(Number(nStr) / 1000000).replace(".", ",");
    else return nStr.replace(",", ".");
  }

  missingOneDecimalCheck(nStr) {
    nStr += "";
    const x = nStr.split(",")[1];
    if (x && x.length === 1) return true;
    return false;
  }

  missingAllDecimalsCheck(nStr) {
    nStr += "";
    const x = nStr.split(",")[1];
    if (!x) return true;
    return false;
  }

  localeString(nStr) {
    if (nStr === "") return "";

    let x, x1, x2, rgx, y1, y2;

    nStr += "";
    x = nStr.split(".");
    x1 = x[0];
    x2 = x.length > 1 ? "," + x[1] : "";
    rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }

    if (x1.indexOf(",") !== -1) {
      y1 = x1.slice(x1.lastIndexOf(",")).replace(/\./g, "");

      y2 = x1.split(",");
      x = y2[0] + y1;
    } else {
      x = x1 + x2;
      if (this.missingOneDecimalCheck(x)) return (x += "0");
      if (this.missingAllDecimalsCheck(x)) return (x += ",00");
    }

    return x;
  }
}
