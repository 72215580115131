import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Products } from "@planard/@core/entities/product";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { SaleNarrative } from "@planard/@core/entities/saleNarrative";
import { NarrativeRequest } from "@planard/@core/entities/narrativeRequest";
import { NarrativeService } from "@planard/@core/backend/service/narrative.service";
import { AddNarrativesComponent } from "./add/add-narratives.component";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { NumberDecimalReversePipe } from "@planard/@core/pipes/NumberDecimalReversePipe";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./narratives-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 5%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
  providers: [NumberDecimalPipe, NumberDecimalReversePipe],
})
export class NarrativesNewComponent implements OnInit {
  displayedColumns: string[] = [
    "amount",
    "impactArea",
    "category",
    "description",
    "actions",
  ];
  dataSource: MatTableDataSource<SaleNarrative>;
  controls: any[];

  productList: Products[];
  saleRequestList: NarrativeRequest[];
  requestId: number;
  productId: number = null;
  selected: boolean = false;
  remaining: number;
  dataRemaining: number = 0;
  genelRem: number = 0;
  isSubmit: boolean;
  isScreenValid: boolean;
  isRoleValid: boolean;

  numberFormat: string;
  decimalFormat: string;
  companyPackage: string;

  priceCatList = [
    "Currency Impact",
    "Price Increase Due to Inflation",
    "Tax Regulations",
    "Competitor Entry/Exit",
    "Change in Manufacturing Costs",
    "Price Adjustments",
    "Other Price Impacts",
  ];

  volumeCatList = [
    "New Product Launch",
    "Competitor Entry/Exit",
    "Market Growth/Shrink",
    "Macro Factors",
    "Competitive Advantage",
    "Large Scale Agreement",
    "New Channels",
    "Price Change Impact on Volume",
    "Other Volume Impacts",
  ];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public narrativeService: NarrativeService,
    private numberDecimal: NumberDecimalPipe,
    private numberReverseDecimal: NumberDecimalReversePipe,
    public productsService: ProductsService,
    public translate: TranslateService,
    public authService: AuthService,
    private router: Router,
    public toastrService: ToastrService
  ) {
    this.isRoleValid =
      this.authService.isBusinessUnitPlanner() ||
      this.authService.isBusinessUnitApprover();
    this.companyPackage = this.authService.getCompanyPackage();
    if (this.isRoleValid == false || this.companyPackage == "basic")
      this.router.navigate(["auth/404"]);
    this.isScreenValid = this.authService.salesNarratives();
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.salesNarrativeTask")
        .subscribe((data: string) => {
          this.toastrService.error(data);
        });
    }
  }

  formatNumber(value, decimal, number) {
    return this.numberDecimal.transform(value, decimal, number);
  }

  formatNumberReverse(string, decimal, number) {
    return this.numberReverseDecimal.transform(string, decimal, number);
  }

  ngOnInit() {
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();

    this.productsService.listUserProducts().subscribe((data) => {
      this.productList = data;
    });
    this.narrativeService.listSalesRequests().subscribe((result) => {
      this.saleRequestList = result;
    });
  }

  getTableData() {
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();

    if (this.requestId == null || this.productId == null) {
      this.translate.get("salesNarrative.select").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
    }

    this.selected = true;

    this.narrativeService
      .listAllSale(this.requestId, this.productId)
      .subscribe((result) => {
        this.remaining = result.remaining;
        this.isSubmit = result.isSubmit;

        this.dataRemaining = 0;
        result.data.forEach((element) => {
          this.dataRemaining += element.amount;
        });

        let data = result.data;
        this.dataSource = new MatTableDataSource(data);

        if (this.dataSource != null) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
            typeof data[sortHeaderId] === "string"
              ? data[sortHeaderId].toLocaleLowerCase()
              : data[sortHeaderId];
          this.dataSource.sort = this.sort;
        }

        this.controls = data.map((entity) => {
          return {
            data: entity,
            formGroup: new FormGroup(
              {
                amount: new FormControl(
                  entity.amount /*this.formatNumber(entity.amount, this.decimalFormat, this.numberFormat)*/,
                  [Validators.required]
                ),
                impactArea: new FormControl(entity.impactArea, [
                  Validators.required,
                ]),
                category: new FormControl(entity.category, [
                  Validators.required,
                ]),
                productId: new FormControl(entity.productId, [
                  Validators.required,
                ]),
                description: new FormControl(entity.description, [
                  Validators.required,
                ]),
              },
              { updateOn: "change" }
            ),
          };
        });
      });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var sale = this.getElement(index);
      sale[field] = control.value;

      /*if ( field == "amount" ) sale.amount = this.formatNumberReverse(sale.amount, this.decimalFormat, this.numberFormat);*/

      this.narrativeService.updateSale(sale).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.getTableData();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.getTableData();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      this.ngOnInit();
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    if (this.requestId) {
      const dialogRef = this.dialog.open(AddNarrativesComponent, {
        width: "500px",
        data: { requestId: this.requestId, productId: this.productId },
      });
      dialogRef.afterClosed().subscribe((result) => {
        //if (result) {
        //this.dataSource.data.push(result);
        this.getTableData();
        //}
      });
    } else {
      this.translate.get("salesNarrative.select4add").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  submit() {
    this.narrativeService
      .listAllSale(this.requestId, this.productId)
      .subscribe((result) => {
        let genelRem = 0;
        result.data.forEach((element) => {
          genelRem += element.amount;
        });

        //let diff = this.remaining - genelRem;
        // Küsürat farklarından dolayı sıfır elde edemiyorduk, son 2 haneyi kontro ledilecek şekilde ayarlandı -tuba

        if (this.remaining.toFixed(2) == genelRem.toFixed(2)) {
          let body = {
            id: this.requestId,
            remaining: /*diff*/ 0,
            productId: this.productId,
            categoryId: null,
          };
          this.narrativeService.submit(body).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.getTableData();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.getTableData();
            }
          });
        } else {
          this.translate
            .get("salesNarrative.explain")
            .subscribe((data: any) => {
              this.toastrService.warning(data);
            });
        }
      });
  }

  onDelete(row: any) {
    this.translate.get("delete.narrative").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.narrativeService.deleteSale(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.getTableData();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.getTableData();
            }
          });
        }
      });
    });
  }

  getProduct(id: any) {
    if (id != null) {
      for (let pt of this.productList) {
        if (pt.id === id) {
          return pt.name;
        }
      }
    }
  }

  outToastr(msg: string) {
    this.translate.get(`${msg}`).subscribe((data: any) => {
      this.toastrService.error(data);
    });
  }
}
