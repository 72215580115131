import { AbstractEnum } from "../base/AbstractEnum";

export class EnumSector extends AbstractEnum {
  static readonly AGRICULTURE_HUNTING_AND_FISHING = new EnumSector(
    "Agriculture, Hunting and Fishing"
  ); //Tarım, Avcılık ve Balıkçılık
  static readonly AUTOMOTIVE = new EnumSector("Automotive"); //Otomotiv
  static readonly BUSINESS_AND_MANAGEMENT = new EnumSector(
    "Business and Management"
  ); //İş ve Yönetim
  static readonly CHEMICAL_PETROLEUM_RUBBER_AND_PLASTICS = new EnumSector(
    "Chemical, Petroleum, Rubber and Plastics"
  ); //Kimya, Petrol, Lastik ve Plastik
  static readonly COMMUNICATION = new EnumSector("Communication"); //Haberleşme
  static readonly CONSTRUCTION_ARCHITECTURE = new EnumSector(
    "Construction & Architecture"
  ); //İnşaat
  static readonly CULTURE_ART_AND_DESIGN = new EnumSector(
    "Culture, Art and Design"
  ); //Kültür, Sanat ve Tasarım
  static readonly EDUCATION = new EnumSector("Education"); //Eğitim
  static readonly ELECTRIC_AND_ELECTRONIC = new EnumSector(
    "Electric and Electronic"
  ); //Elektrik ve Elektronik
  static readonly ENERGY = new EnumSector("Energy"); //Enerji
  static readonly ENVIRONMENT = new EnumSector("Environment"); //Çevre
  static readonly FINANCE = new EnumSector("Finance"); //Finans
  static readonly FOOD_NUTRITION = new EnumSector("Food & Nutrition"); //Gıda
  static readonly GLASS_CEMENT_AND_SOIL = new EnumSector(
    "Glass, Cement and Soil"
  ); // Cam, Çimento ve Toprak
  static readonly HEALTH_AND_SOCIAL_SERVICES = new EnumSector(
    "Health and Social Services"
  ); //Sağlık ve Sosyal Hizmetler
  static readonly INFORMATION_TECHNOLOGIES = new EnumSector(
    "Information Technologies"
  ); //Bilişim Teknolojileri
  static readonly JUSTICE_SECURITY = new EnumSector("Justice & Security"); //Adalet ve Güvenlik
  static readonly MEDIA_COMMUNICATION_AND_PUBLISHING = new EnumSector(
    "Media, Communication and Publishing"
  ); //Medya, İletişim ve Yayıncılık
  static readonly METAL = new EnumSector("Metal"); //Metal
  static readonly MINING = new EnumSector("Mining"); //Maden
  static readonly PHARMA = new EnumSector("Pharma"); //İlaç
  static readonly SOCIAL_AND_PERSONAL_SERVICES = new EnumSector(
    "Social and Personal Services"
  ); //Toplumsal ve Kişisel Hizmetler
  static readonly SPORTS_AND_RECREATION = new EnumSector(
    "Sports and Recreation"
  ); //Spor ve Rekreasyon
  static readonly TEXTILE_READYTOWEAR_LEATHER = new EnumSector(
    "Textile, Ready-to-Wear, Leather"
  ); // Tekstil, Hazır Giyim, Deri
  static readonly TOURISM_ACCOMMODATION_FOOD_AND_BEVERAGE_SERVICES =
    new EnumSector("Tourism, Accommodation, Food and Beverage Services"); // Turizm, Konaklama, Yiyecel-İçecek Hizmetleri
  static readonly TRADE = new EnumSector("Trade (Sales and Marketing)"); //Ticaret (Satış ve Pazarlama)
  static readonly TRANSPORT_LOGISTICS = new EnumSector("Transport, Logistics"); //Ulaştırma, Logistik
  static readonly WOODWORKING_PAPER_AND_PAPER_PRODUCTS = new EnumSector(
    "Woodworking, Paper and Paper Products"
  ); //Ağaç İşleri, Kağıt ve Kağıt Ürünleri

  static values = AbstractEnum.getValues(EnumSector);

  private constructor(public readonly displayValue: string) {
    super();
  }
}
