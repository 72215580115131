import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { Scenario } from "@planard/@core/entities/scenario";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { Products } from "@planard/@core/entities/product";
import { Channel } from "@planard/@core/entities/channel";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { PriceService } from "@planard/@core/backend/service/price.service";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { Category } from "@planard/@core/entities/category";
import { SubCategory } from "@planard/@core/entities/subcategory";
import { CostType } from "@planard/@core/entities/costType";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { DepartmentService } from "@planard/@core/backend/service/department.service";
import { Department } from "@planard/@core/entities/department";
import { SubDepartment } from "@planard/@core/entities/subDepartment";
import { EnumCostType } from "@planard/@core/enums/EnumCostType";
import { TranslateService } from "@ngx-translate/core";
import * as XLSX from "xlsx";
@Component({
  templateUrl: "./actuals-export.component.html",
  styles: [
    `
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class ActualsExportComponent {
  name = "Angular";
  data2Exel: any;
  headData: any; // excel row header
  fullData: any;

  startMonth = new Date().getMonth() + 1;
  currentYear: number = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();
  yearList = [
    this.currentYear,
    this.currentYear - 1,
    this.currentYear - 2,
    this.currentYear - 3,
    this.currentYear - 4,
    this.currentYear - 5,
  ];

  scenarioList: Scenario[];
  productList: Products[];
  channelList: Channel[];

  categoryList: Category[];
  subcategoryList: SubCategory[];

  categoryListFiltered: Category[] = [];
  subcategoryListFiltered: SubCategory[] = [];

  OtherCostList: CostType[] = [];
  CentralCostList: CostType[] = [];

  scenarioId: any;
  file_name: string = "";
  type: any;
  year: number;
  exelName: string;
  productId: number;
  formId: number;
  channelId: number;
  categoryId: number;
  subcategoryId: number;
  costId: number;
  departmentId: number;
  subDepartmentId: number;
  fileNameList: any = [];
  departmentList: Department[];
  subDepartmentList: SubDepartment[];
  loadingIndicator: boolean = false;

  form: FormGroup = new FormGroup({
    type: new FormControl("", [Validators.required]),
    period: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ActualsExportComponent>,
    public guidanceService: GuidanceService,
    public scenarioService: ScenarioService,
    public priceService: PriceService,
    public costService: CostService,
    public departmentService: DepartmentService,
    public actualsService: ActualsService,
    public productService: ProductsService,
    public channelService: ChannelService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public fb: FormBuilder
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.costService.listAll().subscribe((result) => {
      result.forEach((element) => {
        if (element.costType == EnumCostType.CENTRAL) {
          this.CentralCostList.push(element);
        } else {
          this.OtherCostList.push(element);
        }
      });

      this.costService.listCategories().subscribe((result) => {
        this.categoryList = result;

        this.OtherCostList.forEach((ele) => {
          this.categoryList.forEach((element) => {
            if (ele.categoryId == element.id) {
              this.categoryListFiltered.push(element);
            }
          });
        });

        //make the list unique
        let newArr = [];
        this.categoryListFiltered.forEach((item, index) => {
          if (newArr.findIndex((i) => i.id == item.id) === -1) {
            newArr.push(item);
          }
        });
        this.categoryListFiltered = newArr;
      });

      this.costService.listSubcategories().subscribe((result) => {
        this.subcategoryList = result;

        this.OtherCostList.forEach((ele) => {
          this.subcategoryList.forEach((element) => {
            if (ele.subCategoryId == element.id) {
              this.subcategoryListFiltered.push(element);
            }
          });
        });

        //make the list unique
        let newArr2 = [];
        this.subcategoryListFiltered.forEach((item, index) => {
          if (newArr2.findIndex((i) => i.id == item.id) === -1) {
            newArr2.push(item);
          }
        });
        this.subcategoryListFiltered = newArr2;
      });
    });
    this.scenarioService.listAll().subscribe((result) => {
      this.scenarioList = result;
    });
    this.productService.listAll().subscribe((result) => {
      this.productList = result;
    });
    this.channelService.listAll().subscribe((result) => {
      this.channelList = result;
    });
    this.departmentService.listAll().subscribe((result) => {
      this.departmentList = result;
    });
    this.departmentService.listAllSub().subscribe((result) => {
      this.subDepartmentList = result;
    });
    this.actualsService.listAllFiles().subscribe((result) => {
      result.forEach((file) => {
        this.fileNameList.push(file.filename);
      });
    });
  }

  sortLists() {
    this.CentralCostList = this.CentralCostList.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    this.OtherCostList = this.OtherCostList.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    this.categoryListFiltered = this.categoryListFiltered.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    this.subcategoryListFiltered = this.subcategoryListFiltered.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    this.productList = this.productList.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    this.channelList = this.channelList.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    this.departmentList = this.departmentList.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    this.subDepartmentList = this.subDepartmentList.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  setScenario(id: number) {
    for (let sc of Object.keys(this.scenarioList)) {
      if (id == this.scenarioList[sc].id) {
        return this.scenarioList[sc].name;
      }
    }
  }

  getChannel(id: any) {
    for (let cn of Object.keys(this.channelList)) {
      if (id == this.channelList[cn].id) {
        return this.channelList[cn].name;
      }
    }
  }

  isHere(type2type, vol: number) {
    if (
      this.fileNameList.includes(`${type2type}_${this.year}_vol_${vol}.xlsx`)
    ) {
      vol += 1;
      this.isHere(type2type, vol);
    } else {
      this.file_name = `${type2type}_${this.year}_vol_${vol}.xlsx`;
      return vol;
    }
  }

  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
  save() {
    if (this.form.valid) {
      this.loadingIndicator = true;
      this.type = this.form.get("type").value;
      this.year = this.form.get("period").value;

      let type2type;
      if (this.type == "Price(Price)") type2type = "Price";
      else if (this.type == "Price(Discount)") type2type = "Discount";
      else if (this.type == "Forecast(Amount)") type2type = "Amount";
      else if (this.type == "Forecast(Volume)") type2type = "Volume";
      else if (this.type == "OtherForecast") type2type = "OtherForecast";
      else if (this.type == "Cost") type2type = "Cost";
      else if (this.type == "CentralCost") type2type = "CentralCost";

      this.isHere(type2type, 1);

      if (this.type == "Price(Price)") {
        let emptyRow: any[];
        let yearRow: any[] = [null, null, null, null, null, null, this.year]; //okurken buralardan oku
        let guideRow: any[] = [
          null,
          null,
          null,
          null,
          null,
          null,
          "Excel User Guide : Do not remove any column/row and do not change their names.",
        ];

        let header = [
          "productId",
          "productFormId",
          "productChannelId",
          "Filename",
          "Year",
          "Type",
          "Name",
          "Jan Price",
          "Feb Price",
          "Mar Price",
          "Apr Price",
          "May Price",
          "Jun Price",
          "Jul Price",
          "Aug Price",
          "Sep Price",
          "Oct Price",
          "Nov Price",
          "Dec Price",
          "Jan Discount",
          "Feb Discount",
          "Mar Discount",
          "Apr Discount",
          "May Discount",
          "Jun Discount",
          "Jul Discount",
          "Aug Discount",
          "Sep Discount",
          "Oct Discount",
          "Nov Discount",
          "Dec Discount",
          "Jan Net Price",
          "Feb Net Price",
          "Mar Net Price",
          "Apr Net Price",
          "May Net Price",
          "Jun Net Price",
          "Jul Net Price",
          "Aug Net Price",
          "Sep Net Price",
          "Oct Net Price",
          "Nov Net Price",
          "Dec Net Price",
        ];

        this.data2Exel = [];
        this.data2Exel.push(header);

        this.productList.forEach((product) => {
          this.productService.listAllForms(product.id).subscribe((forms) => {
            this.productService
              .listAllChannels(product.id)
              .subscribe((channels) => {
                this.exelName = product.name;
                this.productId = product.id;
                let row: any[] = [
                  this.productId,
                  0,
                  0,
                  this.file_name,
                  this.year,
                  type2type,
                  this.exelName,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                ];
                this.data2Exel.push(row);
                if (forms.length != 0) {
                  forms = forms.sort((a, b) => a.form.localeCompare(b.form));
                  forms.forEach((form) => {
                    this.exelName = " " + " " + " " + form.form;
                    this.formId = form.id;
                    let rowForm: any[] = [
                      this.productId,
                      this.formId,
                      0,
                      this.file_name,
                      this.year,
                      type2type,
                      this.exelName,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                    ];
                    this.data2Exel.push(rowForm);
                    channels = channels.sort((a, b) =>
                      a.channelName.localeCompare(b.channelName)
                    );
                    channels.forEach((channel) => {
                      this.exelName =
                        " " +
                        " " +
                        " " +
                        " " +
                        " " +
                        " " +
                        " " +
                        channel.channelName;
                      this.channelId = channel.id;
                      let rowChannel: any[] = [
                        this.productId,
                        this.formId,
                        this.channelId,
                        this.file_name,
                        this.year,
                        type2type,
                        this.exelName,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                      ];
                      this.data2Exel.push(rowChannel);
                    });
                  });
                } else {
                  channels = channels.sort((a, b) =>
                    a.channelName.localeCompare(b.channelName)
                  );
                  channels.forEach((channel) => {
                    this.exelName =
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      channel.channelName;
                    this.channelId = channel.id;
                    let rowChannel: any[] = [
                      this.productId,
                      this.formId,
                      this.channelId,
                      this.file_name,
                      this.year,
                      type2type,
                      this.exelName,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                    ];
                    this.data2Exel.push(rowChannel);
                  });
                }
              });
          });
        });

        setTimeout(() => {
          this.data2Exel.push(emptyRow);
          this.data2Exel.push(yearRow);
          this.data2Exel.push(guideRow);
          this.export2price();
        }, 2000);
      } else if (this.type == "Price(Discount)") {
        let emptyRow: any[];
        let yearRow: any[] = [null, null, null, null, null, null, this.year]; //okurken buralardan oku
        let guideRow: any[] = [
          null,
          null,
          null,
          null,
          null,
          null,
          "Excel User Guide : Do not remove any column/row and do not change their names.",
        ];

        let header = [
          "productId",
          "productFormId",
          "productChannelId",
          "Filename",
          "Year",
          "Type",
          "Name",
          "Jan Discount",
          "Feb Discount",
          "Mar Discount",
          "Apr Discount",
          "May Discount",
          "Jun Discount",
          "Jul Discount",
          "Aug Discount",
          "Sep Discount",
          "Oct Discount",
          "Nov Discount",
          "Dec Discount",
          "Jan Price",
          "Feb Price",
          "Mar Price",
          "Apr Price",
          "May Price",
          "Jun Price",
          "Jul Price",
          "Aug Price",
          "Sep Price",
          "Oct Price",
          "Nov Price",
          "Dec Price",
          "Jan Net Price",
          "Feb Net Price",
          "Mar Net Price",
          "Apr Net Price",
          "May Net Price",
          "Jun Net Price",
          "Jul Net Price",
          "Aug Net Price",
          "Sep Net Price",
          "Oct Net Price",
          "Nov Net Price",
          "Dec Net Price",
        ];
        this.data2Exel = [];
        this.data2Exel.push(header);

        this.productList.forEach((product) => {
          this.productService.listAllForms(product.id).subscribe((forms) => {
            this.productService
              .listAllChannels(product.id)
              .subscribe((channels) => {
                this.exelName = product.name;
                this.productId = product.id;
                let row: any[] = [
                  this.productId,
                  0,
                  0,
                  this.file_name,
                  this.year,
                  type2type,
                  this.exelName,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                ];
                this.data2Exel.push(row);
                if (forms.length != 0) {
                  forms = forms.sort((a, b) => a.form.localeCompare(b.form));
                  forms.forEach((form) => {
                    this.exelName = " " + " " + " " + form.form;
                    this.formId = form.id;
                    let rowForm: any[] = [
                      this.productId,
                      this.formId,
                      0,
                      this.file_name,
                      this.year,
                      type2type,
                      this.exelName,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                    ];
                    this.data2Exel.push(rowForm);
                    channels = channels.sort((a, b) =>
                      a.channelName.localeCompare(b.channelName)
                    );
                    channels.forEach((channel) => {
                      this.exelName =
                        " " +
                        " " +
                        " " +
                        " " +
                        " " +
                        " " +
                        " " +
                        channel.channelName;
                      this.channelId = channel.id;
                      let rowChannel: any[] = [
                        this.productId,
                        this.formId,
                        this.channelId,
                        this.file_name,
                        this.year,
                        type2type,
                        this.exelName,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                      ];
                      this.data2Exel.push(rowChannel);
                    });
                  });
                } else {
                  channels = channels.sort((a, b) =>
                    a.channelName.localeCompare(b.channelName)
                  );
                  channels.forEach((channel) => {
                    this.exelName =
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      channel.channelName;
                    this.channelId = channel.id;
                    let rowChannel: any[] = [
                      this.productId,
                      this.formId,
                      this.channelId,
                      this.file_name,
                      this.year,
                      type2type,
                      this.exelName,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                    ];
                    this.data2Exel.push(rowChannel);
                  });
                }
              });
          });
        });
        setTimeout(() => {
          this.data2Exel.push(emptyRow);
          this.data2Exel.push(yearRow);
          this.data2Exel.push(guideRow);
          this.export2price();
        }, 2000);
      } else if (this.type == "Forecast(Amount)") {
        let emptyRow: any[];
        let yearRow: any[] = [null, null, null, null, null, null, this.year]; //okurken buralardan oku
        let guideRow: any[] = [
          null,
          null,
          null,
          null,
          null,
          null,
          "Excel User Guide : Do not remove any column/row and do not change their names.",
        ];

        let header = [
          "productId",
          "productFormId",
          "productChannelId",
          "Filename",
          "Year",
          "Type",
          "Name",
          "Jan Amount",
          "Feb Amount",
          "Mar Amount",
          "Apr Amount",
          "May Amount",
          "Jun Amount",
          "Jul Amount",
          "Aug Amount",
          "Sep Amount",
          "Oct Amount",
          "Nov Amount",
          "Dec Amount",
          "Jan Volume",
          "Feb Volume",
          "Mar Volume",
          "Apr Volume",
          "May Volume",
          "Jun Volume",
          "Jul Volume",
          "Aug Volume",
          "Sep Volume",
          "Oct Volume",
          "Nov Volume",
          "Dec Volume",
        ];
        this.data2Exel = [];
        this.data2Exel.push(header);
        this.productList.forEach((product) => {
          this.productService.listAllForms(product.id).subscribe((forms) => {
            this.productService
              .listAllChannels(product.id)
              .subscribe((channels) => {
                this.exelName = product.name;
                this.productId = product.id;
                let row: any[] = [
                  this.productId,
                  0,
                  0,
                  this.file_name,
                  this.year,
                  type2type,
                  this.exelName,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                ];
                this.data2Exel.push(row);
                if (forms.length != 0) {
                  forms = forms.sort((a, b) => a.form.localeCompare(b.form));
                  forms.forEach((form) => {
                    this.exelName = " " + " " + " " + form.form;
                    this.formId = form.id;
                    let rowForm: any[] = [
                      this.productId,
                      this.formId,
                      0,
                      this.file_name,
                      this.year,
                      type2type,
                      this.exelName,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                    ];
                    this.data2Exel.push(rowForm);
                    channels = channels.sort((a, b) =>
                      a.channelName.localeCompare(b.channelName)
                    );
                    channels.forEach((channel) => {
                      this.exelName =
                        " " +
                        " " +
                        " " +
                        " " +
                        " " +
                        " " +
                        " " +
                        channel.channelName;
                      this.channelId = channel.id;
                      let rowChannel: any[] = [
                        this.productId,
                        this.formId,
                        this.channelId,
                        this.file_name,
                        this.year,
                        type2type,
                        this.exelName,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                      ];
                      this.data2Exel.push(rowChannel);
                    });
                  });
                } else {
                  channels = channels.sort((a, b) =>
                    a.channelName.localeCompare(b.channelName)
                  );
                  channels.forEach((channel) => {
                    this.exelName =
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      channel.channelName;
                    this.channelId = channel.id;
                    let rowChannel: any[] = [
                      this.productId,
                      this.formId,
                      this.channelId,
                      this.file_name,
                      this.year,
                      type2type,
                      this.exelName,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                    ];
                    this.data2Exel.push(rowChannel);
                  });
                }
              });
          });
        });
        setTimeout(() => {
          this.data2Exel.push(emptyRow);
          this.data2Exel.push(yearRow);
          this.data2Exel.push(guideRow);
          this.export2forecast();
        }, 2000);
      } else if (this.type == "Forecast(Volume)") {
        let emptyRow: any[];
        let yearRow: any[] = [null, null, null, null, null, null, this.year]; //okurken buralardan oku
        let guideRow: any[] = [
          null,
          null,
          null,
          null,
          null,
          null,
          "Excel User Guide : Do not remove any column/row and do not change their names.",
        ];

        let header = [
          "productId",
          "productFormId",
          "productChannelId",
          "Filename",
          "Year",
          "Type",
          "Name",
          "Jan Volume",
          "Feb Volume",
          "Mar Volume",
          "Apr Volume",
          "May Volume",
          "Jun Volume",
          "Jul Volume",
          "Aug Volume",
          "Sep Volume",
          "Oct Volume",
          "Nov Volume",
          "Dec Volume",
          "Jan Amount",
          "Feb Amount",
          "Mar Amount",
          "Apr Amount",
          "May Amount",
          "Jun Amount",
          "Jul Amount",
          "Aug Amount",
          "Sep Amount",
          "Oct Amount",
          "Nov Amount",
          "Dec Amount",
        ];
        this.data2Exel = [];
        this.data2Exel.push(header);
        this.productList.forEach((product) => {
          this.productService.listAllForms(product.id).subscribe((forms) => {
            this.productService
              .listAllChannels(product.id)
              .subscribe((channels) => {
                this.exelName = product.name;
                this.productId = product.id;
                let row: any[] = [
                  this.productId,
                  0,
                  0,
                  this.file_name,
                  this.year,
                  type2type,
                  this.exelName,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                ];
                this.data2Exel.push(row);
                if (forms.length != 0) {
                  forms = forms.sort((a, b) => a.form.localeCompare(b.form));
                  forms.forEach((form) => {
                    this.exelName = " " + " " + " " + form.form;
                    this.formId = form.id;
                    let rowForm: any[] = [
                      this.productId,
                      this.formId,
                      0,
                      this.file_name,
                      this.year,
                      type2type,
                      this.exelName,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                    ];
                    this.data2Exel.push(rowForm);
                    channels = channels.sort((a, b) =>
                      a.channelName.localeCompare(b.channelName)
                    );
                    channels.forEach((channel) => {
                      this.exelName =
                        " " + " " + " " + " " + " " + " " + channel.channelName;
                      this.channelId = channel.id;
                      let rowChannel: any[] = [
                        this.productId,
                        this.formId,
                        this.channelId,
                        this.file_name,
                        this.year,
                        type2type,
                        this.exelName,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                      ];
                      this.data2Exel.push(rowChannel);
                    });
                  });
                } else {
                  channels = channels.sort((a, b) =>
                    a.channelName.localeCompare(b.channelName)
                  );
                  channels.forEach((channel) => {
                    this.exelName =
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      channel.channelName;
                    this.channelId = channel.id;
                    let rowChannel: any[] = [
                      this.productId,
                      this.formId,
                      this.channelId,
                      this.file_name,
                      this.year,
                      type2type,
                      this.exelName,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                    ];
                    this.data2Exel.push(rowChannel);
                  });
                }
              });
          });
        });
        setTimeout(() => {
          this.data2Exel.push(emptyRow);
          this.data2Exel.push(yearRow);
          this.data2Exel.push(guideRow);
          this.export2forecast();
        }, 2000);
      } else if (this.type == "OtherForecast") {
        let emptyRow: any[];
        let yearRow: any[] = [null, null, null, null, this.year]; //okurken buralardan oku
        let guideRow: any[] = [
          null,
          null,
          null,
          null,
          "Excel User Guide : Do not remove any column/row and do not change their names.",
        ];

        let header = [
          "type",
          "Filename",
          "Year",
          "Type",
          "Name",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "Jan Volume",
          "Feb Volume",
          "Mar Volume",
          "Apr Volume",
          "May Volume",
          "Jun Volume",
          "Jul Volume",
          "Aug Volume",
          "Sep Volume",
          "Oct Volume",
          "Nov Volume",
          "Dec Volume",
        ];
        this.data2Exel = [];
        this.data2Exel.push(header);
        let row: any[] = [
          0,
          this.file_name,
          this.year,
          this.type,
          "Financial Income",
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ];
        let row2: any[] = [
          1,
          this.file_name,
          this.year,
          this.type,
          "Income From Assets",
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ];
        this.data2Exel.push(row);
        this.data2Exel.push(row2);
        setTimeout(() => {
          this.data2Exel.push(emptyRow);
          this.data2Exel.push(yearRow);
          this.data2Exel.push(guideRow);
          this.exportOtherforecast();
        }, 2000);
      } else if (this.type == "CentralCost") {
        let emptyRow: any[];
        let yearRow: any[] = [null, null, null, null, null, null, this.year]; //okurken buralardan oku
        let guideRow: any[] = [
          null,
          null,
          null,
          null,
          null,
          null,
          "Excel User Guide : Do not remove any column/row and do not change their names.",
        ];

        let header = [
          "costId",
          "departmentId",
          "subDepartmentId",
          "Filename",
          "Year",
          "Type",
          "Name",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        this.data2Exel = [];
        this.data2Exel.push(header);

        this.CentralCostList.forEach((cost) => {
          this.exelName = cost.name;
          this.costId = cost.id;
          let row: any[] = [
            this.costId,
            0,
            0,
            this.file_name,
            this.year,
            type2type,
            this.exelName,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ];
          this.data2Exel.push(row);

          this.departmentList.forEach((department) => {
            this.exelName = " " + " " + " " + " " + " " + department.name;
            this.departmentId = department.id;
            let rowSub: any[] = [
              this.costId,
              this.departmentId,
              0,
              this.file_name,
              this.year,
              type2type,
              this.exelName,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ];
            this.data2Exel.push(rowSub);

            this.subDepartmentList.forEach((subDept) => {
              if (subDept.departmentId == department.id) {
                this.exelName =
                  " " +
                  " " +
                  " " +
                  " " +
                  " " +
                  " " +
                  " " +
                  " " +
                  " " +
                  " " +
                  subDept.name;
                this.subDepartmentId = subDept.id;
                let rowCost: any[] = [
                  this.costId,
                  this.departmentId,
                  this.subDepartmentId,
                  this.file_name,
                  this.year,
                  type2type,
                  this.exelName,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                ];
                this.data2Exel.push(rowCost);
              }
            });
          });
        });
        setTimeout(() => {
          this.data2Exel.push(emptyRow);
          this.data2Exel.push(yearRow);
          this.data2Exel.push(guideRow);
          this.export2cost();
        }, 2000);
      } else if (this.type == "Cost") {
        let emptyRow: any[];
        let yearRow: any[] = [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          this.year,
        ]; //okurken buralardan oku
        let guideRow: any[] = [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "Excel User Guide : Do not remove any column/row and do not change their names.",
        ];

        let header = [
          "categoryId",
          "subCategoryId",
          "costId",
          "departmentId",
          "subDepartmentId",
          "Filename",
          "Year",
          "Type",
          "Name",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        this.data2Exel = [];
        this.data2Exel.push(header);

        this.categoryListFiltered.forEach((category) => {
          this.exelName = category.name;
          this.categoryId = category.id;
          let row: any[] = [
            this.categoryId,
            0,
            0,
            0,
            0,
            this.file_name,
            this.year,
            type2type,
            this.exelName,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ];
          this.data2Exel.push(row);

          this.subcategoryListFiltered.forEach((subcategory) => {
            if (subcategory.categoryId == this.categoryId) {
              this.exelName = " " + " " + " " + " " + subcategory.name;
              this.subcategoryId = subcategory.id;
              let rowSub: any[] = [
                this.categoryId,
                this.subcategoryId,
                0,
                0,
                0,
                this.file_name,
                this.year,
                type2type,
                this.exelName,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
              ];
              this.data2Exel.push(rowSub);

              this.OtherCostList.forEach((cost) => {
                if (
                  cost.categoryId == this.categoryId &&
                  cost.subCategoryId == this.subcategoryId
                ) {
                  this.exelName =
                    " " + " " + " " + " " + " " + " " + " " + " " + cost.name;
                  this.costId = cost.id;
                  let rowCost: any[] = [
                    this.categoryId,
                    this.subcategoryId,
                    this.costId,
                    0,
                    0,
                    this.file_name,
                    this.year,
                    type2type,
                    this.exelName,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ];
                  this.data2Exel.push(rowCost);

                  this.departmentList.forEach((department) => {
                    this.exelName =
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      " " +
                      department.name;
                    this.departmentId = department.id;
                    let rowSub: any[] = [
                      this.categoryId,
                      this.subcategoryId,
                      this.costId,
                      this.departmentId,
                      0,
                      this.file_name,
                      this.year,
                      type2type,
                      this.exelName,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                    ];
                    this.data2Exel.push(rowSub);

                    this.subDepartmentList.forEach((subDept) => {
                      if (subDept.departmentId == department.id) {
                        this.exelName =
                          " " +
                          " " +
                          " " +
                          " " +
                          " " +
                          " " +
                          " " +
                          " " +
                          " " +
                          " " +
                          " " +
                          " " +
                          " " +
                          " " +
                          " " +
                          " " +
                          subDept.name;
                        this.subDepartmentId = subDept.id;
                        let rowCost: any[] = [
                          this.categoryId,
                          this.subcategoryId,
                          this.costId,
                          this.departmentId,
                          this.subDepartmentId,
                          this.file_name,
                          this.year,
                          type2type,
                          this.exelName,
                          0,
                          0,
                          0,
                          0,
                          0,
                          0,
                          0,
                          0,
                          0,
                          0,
                          0,
                          0,
                        ];
                        this.data2Exel.push(rowCost);
                      }
                    });
                  });
                }
              });
            }
          });
        });
        setTimeout(() => {
          this.data2Exel.push(emptyRow);
          this.data2Exel.push(yearRow);
          this.data2Exel.push(guideRow);
          this.export2costMix();
        }, 2000);
      }
    } else {
      this.toastrService.error("Please Fill All Required Places.");
    }
  }

  export2price() {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2Exel, {
      cellStyles: true,
    });
    /*hide salesPriceID column*/
    ws["!cols"] = [];
    ws["!cols"][0] = { hidden: true }; //scenarioId
    ws["!cols"][1] = { hidden: true }; //productId
    ws["!cols"][2] = { hidden: true }; //formId
    ws["!cols"][3] = { hidden: true }; //channelId
    ws["!cols"][4] = { hidden: true }; //filename
    ws["!cols"][5] = { hidden: true }; //year

    if (this.year == new Date().getFullYear()) {
      if (this.startMonth <= 2) ws["!cols"][8] = { hidden: true }; // feb
      if (this.startMonth <= 3) ws["!cols"][9] = { hidden: true }; // mar
      if (this.startMonth <= 4) ws["!cols"][10] = { hidden: true }; // april
      if (this.startMonth <= 5) ws["!cols"][11] = { hidden: true }; // may
      if (this.startMonth <= 6) ws["!cols"][12] = { hidden: true }; // jun
      if (this.startMonth <= 7) ws["!cols"][13] = { hidden: true }; // jul
      if (this.startMonth <= 8) ws["!cols"][14] = { hidden: true }; // aug
      if (this.startMonth <= 9) ws["!cols"][15] = { hidden: true }; // sep
      if (this.startMonth <= 10) ws["!cols"][16] = { hidden: true }; // nov
      if (this.startMonth <= 11) ws["!cols"][17] = { hidden: true }; // oct
      if (this.startMonth <= 12) ws["!cols"][18] = { hidden: true }; // dec
    }

    ws["!cols"][19] = { hidden: true }; //year
    ws["!cols"][20] = { hidden: true }; //discount & net price
    ws["!cols"][21] = { hidden: true }; //discount & net price
    ws["!cols"][22] = { hidden: true }; //discount & net price
    ws["!cols"][23] = { hidden: true }; //discount & net price
    ws["!cols"][24] = { hidden: true }; //discount & net price
    ws["!cols"][25] = { hidden: true }; //discount & net price
    ws["!cols"][26] = { hidden: true }; //discount & net price
    ws["!cols"][27] = { hidden: true }; //discount & net price
    ws["!cols"][28] = { hidden: true }; //discount & net price
    ws["!cols"][29] = { hidden: true }; //discount & net price
    ws["!cols"][30] = { hidden: true }; //discount & net price
    ws["!cols"][31] = { hidden: true }; //discount & net price
    ws["!cols"][32] = { hidden: true }; //discount & net price
    ws["!cols"][33] = { hidden: true }; //discount & net price
    ws["!cols"][34] = { hidden: true }; //discount & net price
    ws["!cols"][35] = { hidden: true }; //discount & net price
    ws["!cols"][36] = { hidden: true }; //discount & net price
    ws["!cols"][37] = { hidden: true }; //discount & net price
    ws["!cols"][38] = { hidden: true }; //discount & net price
    ws["!cols"][39] = { hidden: true }; //discount & net price
    ws["!cols"][40] = { hidden: true }; //discount & net price
    ws["!cols"][41] = { hidden: true }; //discount & net price
    ws["!cols"][42] = { hidden: true }; //discount & net price
    ws["!cols"][43] = { hidden: true }; //discount & net price
    ws["!cols"][44] = { hidden: true }; //discount & net price

    ws["!cols"][6] = { wpx: 200 }; //name

    ws["!rows"] = [];
    ws["!rows"][0] = { hpx: 35 }; //header

    ws["!rows"][this.data2Exel.length - 1] = { hpx: 25 };
    ws["!rows"][this.data2Exel.length - 2] = { hpx: 25 };
    //ws['!rows'][this.data2Exel.length-3] = {hpx: 25};

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.type);
    /* save to file */

    this.loadingIndicator = false;

    XLSX.writeFile(wb, this.file_name);
    this.dialogRef.close(this.data2Exel);
  }

  export2forecast() {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2Exel, {
      cellStyles: true,
    });
    /*hide salesPriceID column*/
    ws["!cols"] = [];
    ws["!cols"][0] = { hidden: true }; //scenarioId
    ws["!cols"][1] = { hidden: true }; //productId
    ws["!cols"][2] = { hidden: true }; //formId
    ws["!cols"][3] = { hidden: true }; //channelId
    ws["!cols"][4] = { hidden: true }; //filename
    ws["!cols"][5] = { hidden: true }; //year

    if (this.year == new Date().getFullYear()) {
      if (this.startMonth <= 2) ws["!cols"][8] = { hidden: true }; // feb
      if (this.startMonth <= 3) ws["!cols"][9] = { hidden: true }; // mar
      if (this.startMonth <= 4) ws["!cols"][10] = { hidden: true }; // april
      if (this.startMonth <= 5) ws["!cols"][11] = { hidden: true }; // may
      if (this.startMonth <= 6) ws["!cols"][12] = { hidden: true }; // jun
      if (this.startMonth <= 7) ws["!cols"][13] = { hidden: true }; // jul
      if (this.startMonth <= 8) ws["!cols"][14] = { hidden: true }; // aug
      if (this.startMonth <= 9) ws["!cols"][15] = { hidden: true }; // sep
      if (this.startMonth <= 10) ws["!cols"][16] = { hidden: true }; // nov
      if (this.startMonth <= 11) ws["!cols"][17] = { hidden: true }; // oct
      if (this.startMonth <= 12) ws["!cols"][18] = { hidden: true }; // dec
    }

    ws["!cols"][19] = { hidden: true }; //year
    ws["!cols"][20] = { hidden: true }; //discount & net price
    ws["!cols"][21] = { hidden: true }; //discount & net price
    ws["!cols"][22] = { hidden: true }; //discount & net price
    ws["!cols"][23] = { hidden: true }; //discount & net price
    ws["!cols"][24] = { hidden: true }; //discount & net price
    ws["!cols"][25] = { hidden: true }; //discount & net price
    ws["!cols"][26] = { hidden: true }; //discount & net price
    ws["!cols"][27] = { hidden: true }; //discount & net price
    ws["!cols"][28] = { hidden: true }; //discount & net price
    ws["!cols"][29] = { hidden: true }; //discount & net price
    ws["!cols"][30] = { hidden: true }; //discount & net price
    ws["!cols"][31] = { hidden: true }; //discount & net price
    ws["!cols"][32] = { hidden: true }; //discount & net price

    ws["!cols"][6] = { wpx: 200 }; //name

    ws["!rows"] = [];
    ws["!rows"][0] = { hpx: 35 }; //header

    ws["!rows"][this.data2Exel.length - 1] = { hpx: 25 };
    ws["!rows"][this.data2Exel.length - 2] = { hpx: 25 };

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.type);
    /* save to file */
    this.loadingIndicator = false;

    XLSX.writeFile(wb, this.file_name);
    this.dialogRef.close(this.data2Exel);
  }

  exportOtherforecast() {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2Exel, {
      cellStyles: true,
    });
    /*hide salesPriceID column*/
    ws["!cols"] = [];
    ws["!cols"][0] = { hidden: true }; //type
    ws["!cols"][1] = { hidden: true }; //filename
    ws["!cols"][2] = { hidden: true }; //year
    ws["!cols"][3] = { hidden: true }; //type

    if (this.year == new Date().getFullYear()) {
      if (this.startMonth <= 2) ws["!cols"][6] = { hidden: true }; // feb
      if (this.startMonth <= 3) ws["!cols"][7] = { hidden: true }; // mar
      if (this.startMonth <= 4) ws["!cols"][8] = { hidden: true }; // april
      if (this.startMonth <= 5) ws["!cols"][9] = { hidden: true }; // may
      if (this.startMonth <= 6) ws["!cols"][10] = { hidden: true }; // jun
      if (this.startMonth <= 7) ws["!cols"][11] = { hidden: true }; // jul
      if (this.startMonth <= 8) ws["!cols"][12] = { hidden: true }; // aug
      if (this.startMonth <= 9) ws["!cols"][13] = { hidden: true }; // sep
      if (this.startMonth <= 10) ws["!cols"][14] = { hidden: true }; // nov
      if (this.startMonth <= 11) ws["!cols"][15] = { hidden: true }; // oct
      if (this.startMonth <= 12) ws["!cols"][16] = { hidden: true }; // dec
    }

    ws["!cols"][17] = { hidden: true }; //year
    ws["!cols"][18] = { hidden: true }; //discount & net price
    ws["!cols"][19] = { hidden: true }; //discount & net price
    ws["!cols"][20] = { hidden: true }; //discount & net price
    ws["!cols"][21] = { hidden: true }; //discount & net price
    ws["!cols"][22] = { hidden: true }; //discount & net price
    ws["!cols"][23] = { hidden: true }; //discount & net price
    ws["!cols"][24] = { hidden: true }; //discount & net price
    ws["!cols"][25] = { hidden: true }; //discount & net price
    ws["!cols"][26] = { hidden: true }; //discount & net price
    ws["!cols"][27] = { hidden: true }; //discount & net price
    ws["!cols"][28] = { hidden: true }; //discount & net price
    ws["!cols"][29] = { hidden: true }; //discount & net price
    ws["!cols"][30] = { hidden: true }; //discount & net price

    ws["!cols"][4] = { wpx: 200 }; //name

    ws["!rows"] = [];
    ws["!rows"][0] = { hpx: 35 }; //header

    ws["!rows"][this.data2Exel.length - 1] = { hpx: 25 };
    ws["!rows"][this.data2Exel.length - 2] = { hpx: 25 };

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.type);
    /* save to file */
    this.loadingIndicator = false;

    XLSX.writeFile(wb, this.file_name);
    this.dialogRef.close(this.data2Exel);
  }

  export2cost() {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2Exel, {
      cellStyles: true,
    });
    /*hide salesPriceID column*/
    ws["!cols"] = [];
    ws["!cols"][0] = { hidden: true }; //scenarioId
    ws["!cols"][1] = { hidden: true }; //productId
    ws["!cols"][2] = { hidden: true }; //formId
    ws["!cols"][3] = { hidden: true }; //channelId
    ws["!cols"][4] = { hidden: true }; //filename
    ws["!cols"][5] = { hidden: true }; //year
    //ws['!cols'][6] = { hidden: true }; //year

    if (this.year == new Date().getFullYear()) {
      if (this.startMonth <= 2) ws["!cols"][8] = { hidden: true }; // feb
      if (this.startMonth <= 3) ws["!cols"][9] = { hidden: true }; // mar
      if (this.startMonth <= 4) ws["!cols"][10] = { hidden: true }; // april
      if (this.startMonth <= 5) ws["!cols"][11] = { hidden: true }; // may
      if (this.startMonth <= 6) ws["!cols"][12] = { hidden: true }; // jun
      if (this.startMonth <= 7) ws["!cols"][13] = { hidden: true }; // jul
      if (this.startMonth <= 8) ws["!cols"][14] = { hidden: true }; // aug
      if (this.startMonth <= 9) ws["!cols"][15] = { hidden: true }; // sep
      if (this.startMonth <= 10) ws["!cols"][16] = { hidden: true }; // nov
      if (this.startMonth <= 11) ws["!cols"][17] = { hidden: true }; // oct
      if (this.startMonth <= 12) ws["!cols"][18] = { hidden: true }; // dec
    }

    ws["!cols"][6] = { wpx: 200 }; //name

    ws["!rows"] = [];
    ws["!rows"][0] = { hpx: 35 }; //header

    ws["!rows"][this.data2Exel.length - 1] = { hpx: 25 };
    ws["!rows"][this.data2Exel.length - 2] = { hpx: 25 };

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.type);
    /* save to file */
    this.loadingIndicator = false;

    XLSX.writeFile(wb, this.file_name);
    this.dialogRef.close(this.data2Exel);
  }

  export2costMix() {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2Exel, {
      cellStyles: true,
    });
    /*hide salesPriceID column*/
    ws["!cols"] = [];
    ws["!cols"][0] = { hidden: true }; //scenarioId
    ws["!cols"][1] = { hidden: true }; //productId
    ws["!cols"][2] = { hidden: true }; //formId
    ws["!cols"][3] = { hidden: true }; //channelId
    ws["!cols"][4] = { hidden: true }; //filename
    ws["!cols"][5] = { hidden: true }; //year
    ws["!cols"][6] = { hidden: true }; //filename
    ws["!cols"][7] = { hidden: true }; //year
    //ws['!cols'][6] = { hidden: true }; //year

    if (this.year == new Date().getFullYear()) {
      if (this.startMonth <= 2) ws["!cols"][10] = { hidden: true }; // feb
      if (this.startMonth <= 3) ws["!cols"][11] = { hidden: true }; // mar
      if (this.startMonth <= 4) ws["!cols"][12] = { hidden: true }; // april
      if (this.startMonth <= 5) ws["!cols"][13] = { hidden: true }; // may
      if (this.startMonth <= 6) ws["!cols"][14] = { hidden: true }; // jun
      if (this.startMonth <= 7) ws["!cols"][15] = { hidden: true }; // jul
      if (this.startMonth <= 8) ws["!cols"][16] = { hidden: true }; // aug
      if (this.startMonth <= 9) ws["!cols"][17] = { hidden: true }; // sep
      if (this.startMonth <= 10) ws["!cols"][18] = { hidden: true }; // nov
      if (this.startMonth <= 11) ws["!cols"][19] = { hidden: true }; // oct
      if (this.startMonth <= 12) ws["!cols"][20] = { hidden: true }; // dec
    }

    ws["!cols"][8] = { wpx: 200 }; //name

    ws["!rows"] = [];
    ws["!rows"][0] = { hpx: 35 }; //header

    ws["!rows"][this.data2Exel.length - 1] = { hpx: 25 };
    ws["!rows"][this.data2Exel.length - 2] = { hpx: 25 };

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.type);
    /* save to file */
    this.loadingIndicator = false;

    XLSX.writeFile(wb, this.file_name);
    this.dialogRef.close(this.data2Exel);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
