import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";
import { LaunchTimeline } from "@planard/@core/entities/launchTimeline";

@Injectable()
export class LaunchTimelineApi {
  private readonly apiController: string = "launchTimeline";

  constructor(private api: HttpService) {}

  listAll<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAll`);
  }

  list<T>(scenarioId: number): Observable<any> {
    return this.api.get(`${this.apiController}/list/${scenarioId}`);
  }

  launchTimeline<T>(scenarioId: number, productId: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/listLaunchTimelineReport/${scenarioId}/${productId}`
    );
  }

  add<T>(launchTimeline: LaunchTimeline): Observable<any> {
    return this.api.post(`${this.apiController}/add`, launchTimeline);
  }

  update<T>(launchTimeline: LaunchTimeline): Observable<any> {
    return this.api.post(`${this.apiController}/update`, launchTimeline);
  }

  delete<T>(id: number): Observable<any> {
    var json = { id: id };
    return this.api.post(`${this.apiController}/delete`, json);
  }
}
