<h1 mat-dialog-title>{{ "guidance.faq.add" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        tabindex="1"
        formControlName="question"
        matInput
        name="question"
        placeholder="{{ 'guidance.faq.question' | translate }}"
      />
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="2"
        formControlName="answer"
        matInput
        name="answer"
        placeholder="{{ 'guidance.faq.answer' | translate }}"
      />
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-button tabindex="3" type="submit">
      {{ "guidance.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="4" type="button">
      {{ "guidance.cancel" | translate }}
    </button>
  </div>
</form>
