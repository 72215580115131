import { NgModule } from "@angular/core";
import { EnumDisplayValuePipe } from "../../@core/pipes/EnumDisplayValuePipe";
import { NumberDecimalPipe } from "./NumberDecimalPipe";
import { NumberDecimalReversePipe } from "./NumberDecimalReversePipe";
@NgModule({
  declarations: [
    EnumDisplayValuePipe,
    NumberDecimalPipe,
    NumberDecimalReversePipe,
  ],
  exports: [EnumDisplayValuePipe, NumberDecimalPipe, NumberDecimalReversePipe],
})
export class PipesModule {}
