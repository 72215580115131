<div fxLayout="row wrap">
  <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.gt-xs="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 85%">
            <mat-card-title>{{
              "reports.economicReport" | translate
            }}</mat-card-title>
          </div>
          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>

        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <!-- Name Column -->
            <ng-container matColumnDef="description">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="description"
                >{{ "reports.description" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                {{ element.value }}
              </mat-cell>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef mat-sort-header="type">{{
                "economicReports.type" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="element.type == 1">{{
                  "economicReports.macroT" | translate
                }}</span>
                <span *ngIf="element.type != 1">{{
                  "economicReports.ecoT" | translate
                }}</span>
              </mat-cell>
            </ng-container>

            <!-- Country Column -->
            <ng-container matColumnDef="country">
              <mat-header-cell *matHeaderCellDef mat-sort-header="country">{{
                "economicReports.country" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.country ? element.country : "-" }}
              </mat-cell>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="createdDate">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="createdDate"
                >{{ "economicReports.date" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                {{ element.createdDate | date : "dd/MM/yyyy" }}
              </mat-cell>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRightReverse'"
                ><span style="width: 10%">&nbsp;</span
                >{{ "reports.actions" | translate }}</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [style.color]="row.color"
                [ngClass]="'toRightReverse'"
              >
                <button autofocus color="accent" mat-button type="button">
                  <!-- export -->
                  <a
                    [href]="getUrl(row.blob)"
                    download="{{ row.key }}"
                    style="color: rgb(41, 21, 153)"
                  >
                    {{ "reports.export" | translate }}
                  </a>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
