import { EventEmitter, Injectable } from "@angular/core";
import { SupersettingsData } from "@planard/@core/entities/interface/supersettings";
import { Supersettings } from "@planard/@core/entities/supersettings";
import { SupersettingsApi } from "../api/supersettings.api";

@Injectable()
export class SupersettingsService extends SupersettingsData {
  onFaqReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: SupersettingsApi) {
    super();
  }

  listAll() {
    return this.api.listAll<any[]>();
  }

  getMacro() {
    return this.api.getLastMacro<any[]>();
  }

  add(supersettings: Supersettings) {
    return this.api.add<any>(supersettings);
  }

  update(supersettings: Supersettings) {
    return this.api.update<any>(supersettings);
  }

  delete(id: number) {
    return this.api.delete<any>(id);
  }
}
