import { Observable } from "rxjs";
import { ReportScreenshot } from "../reportScreenshot";

export abstract class ReportScreenshotData {
  abstract listReportScreenShot(
    guidanceId: any
  ): Observable<ReportScreenshot[]>;

  abstract addReportScreenshot(data: ReportScreenshot): Observable<any>;

  abstract delete(screenshotId: any): Observable<any>;
}
