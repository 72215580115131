import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidanceKeyContact } from "@planard/@core/entities/guidanceKeyContact";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./key-contacts-add.component.html",
})
export class KeyContactsAddComponent {
  keyContact: GuidanceKeyContact;

  form: FormGroup = new FormGroup({
    department: new FormControl("", [Validators.required, removeSpaces]),
    name: new FormControl("", [Validators.required, removeSpaces]),
    phone: new FormControl("", [
      Validators.required,
      Validators.pattern("[- +()0-9]+"),
      removeSpaces,
    ]),
    email: new FormControl("", [
      Validators.required,
      Validators.email,
      removeSpaces,
    ]),
  });
  options: FormGroup;
  guidanceId: number;

  constructor(
    public dialogRef: MatDialogRef<KeyContactsAddComponent>,
    public guidanceService: GuidanceService,
    public toastrService: ToastrService,
    public fb: FormBuilder,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.guidanceId = data.guidanceId;
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {}

  save() {
    if (this.form.valid) {
      this.keyContact = this.form.value;
      this.keyContact.guidanceId = this.guidanceId;
      this.guidanceService
        .addKeyContact(this.keyContact)
        .subscribe((result) => {
          if (result.isSuccess) {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.success(data);
              });
            this.dialogRef.close(this.keyContact);
          } else {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.error(data);
              });
          }
        });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
