import { AbstractEnum } from "@planard/@core/base/AbstractEnum";

export class EnumRole extends AbstractEnum {
  static readonly ADMIN = new EnumRole("Admin");
  // static readonly PLANNER_SF = new EnumRole("Support Function Planner");
  // static readonly APPROVER_SF = new EnumRole("Support Function Approver");
  // static readonly PLANNER_BU = new EnumRole("Business Unit Planner");
  // static readonly APPROVER_BU = new EnumRole("Business Unit Approver");
  static readonly PLANNER_SF = new EnumRole("Cost Planner");
  static readonly APPROVER_SF = new EnumRole("Cost Approver");
  static readonly PLANNER_BU = new EnumRole("Sales Planner");
  static readonly APPROVER_BU = new EnumRole("Sales Approver");
  static readonly PRICE_PLANNER = new EnumRole("Price Planner");
  static readonly CENTRAL_COST_PLANNER = new EnumRole("Central Cost Planner");
  static readonly SUPER_ADMIN = new EnumRole("Super Admin");

  static values = AbstractEnum.getValues(EnumRole);

  private constructor(public readonly displayValue: string) {
    super();
  }
}
