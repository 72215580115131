import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { CentralPlan } from "@planard/@core/entities/centralPlan";

@Injectable()
export class CentralPlanApi {
  private readonly apiController: string = "costcentralplan";

  constructor(private api: HttpService) {}

  listCost<T>(scenarioId: number, approveRequestId?: number): Observable<any> {
    const endpointUrl = approveRequestId
      ? `${this.apiController}/listPrices/${scenarioId}/?approveRequestId=${approveRequestId}`
      : `${this.apiController}/listPrices/${scenarioId}`;
    return this.api.get(endpointUrl);
  }

  update<T>(centralPlan: CentralPlan): Observable<any> {
    return this.api.post(`${this.apiController}/update`, centralPlan);
  }
}
