import { AbstractEnum } from "../base/AbstractEnum";

export class EnumResponsibility extends AbstractEnum {
  static readonly PLAN_DEPARTMENTAL_COSTS = new EnumResponsibility(
    "Plan departmental costs"
  );
  static readonly PLAN_DEPARTMENTAL_AND_PRODUCT_COSTS = new EnumResponsibility(
    "Plan departmental and product costs"
  );
  static readonly PLAN_SALES = new EnumResponsibility("Plan sales");
  static readonly RESOURCE_ALLOCATION = new EnumResponsibility(
    "Resource Allocation"
  );
  static readonly DASHBOARD = new EnumResponsibility("Dashboard");
  static readonly PLAN_HEADCOUNT_COSTS = new EnumResponsibility(
    "Plan headcount costs"
  );
  static readonly ENTER_PRICES_TO_SYSTEM = new EnumResponsibility(
    "Enter prices to the system"
  );
  static readonly ALL = new EnumResponsibility("All");

  static values = AbstractEnum.getValues(EnumResponsibility);

  private constructor(public readonly displayValue: string) {
    super();
  }
}
