import { Injectable, EventEmitter } from "@angular/core";
import { BusinessUnitApi } from "../api/business-unit.api";
import { BusinessUnit } from "../../entities/businessUnit";

@Injectable()
export class BusinessUnitService {
  onBusinessUnitReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: BusinessUnitApi) {}

  listAll(page?: number, pageSize?: number) {
    return this.api.listAll<BusinessUnit[]>(page, pageSize);
  }

  listUserBusinessUnits(page?: number, pageSize?: number) {
    return this.api.listUserBusinessUnits<BusinessUnit[]>(page, pageSize);
  }

  businessUnitsByScenario(scenarioId: any) {
    return this.api.businessUnitsByScenario<BusinessUnit[]>(scenarioId);
  }

  add(businessUnit: BusinessUnit) {
    return this.api.add<any>(businessUnit);
  }

  update(businessUnit: BusinessUnit) {
    return this.api.update<any>(businessUnit);
  }

  delete(businessUnitId: number) {
    return this.api.delete<any>(businessUnitId);
  }
}
