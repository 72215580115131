import {
  ModuleWithProviders,
  NgModule,
  SkipSelf,
  Optional,
} from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";

import { AuthenticationRoutes } from "./authentication.routing";
import { AuthCallbackComponent } from "./callback/auth-callback.component";
import { AuthLogoutComponent } from "./logout/auth-logout.component";
import { ErrorComponent } from "./error/error.component";
import { ForgotComponent } from "./forgot/forgot.component";
import { LockscreenComponent } from "./lockscreen/lockscreen.component";
import { RegisterComponent } from "./register/register.component";
import { HttpModule } from "@angular/http";
import { CustomFormsModule } from "ngx-custom-validators";
import { UserServiceConfig } from "./services/auth.service";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AuthenticationRoutes),
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    CustomFormsModule,
  ],
  declarations: [
    AuthCallbackComponent,
    AuthLogoutComponent,
    ErrorComponent,
    ForgotComponent,
    LockscreenComponent,
    RegisterComponent,
  ],
  providers: [],
})
export class AuthenticationModule {
  constructor(@Optional() @SkipSelf() parentModule?: AuthenticationModule) {
    if (parentModule) {
      console.log(
        "AuthenticationModule is already loaded. Import it in the AppModule only"
      );
    }
  }

  static forRoot(
    config: UserServiceConfig
  ): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [{ provide: UserServiceConfig, useValue: config }],
    };
  }
}
