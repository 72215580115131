import { AbstractEnum } from "@planard/@core/base/AbstractEnum";
export class EnumTask extends AbstractEnum {
  static readonly PRICE_PLANNING = new EnumTask("Price Planning");
  static readonly FORECAST_PLANNING = new EnumTask("Forecast Planning");
  static readonly COST_PLANNING = new EnumTask(
    "Cost Planning (Departmental, Product, Asset)"
  );
  static readonly CENTRAL_COST_PLANNING = new EnumTask("Central Cost Planning");

  static readonly RISK_OPPORTUNITIES = new EnumTask("Risk & Opportunities");
  static readonly SALES_NARRATIVES = new EnumTask("Sales Narratives");
  static readonly COST_NARRATIVES = new EnumTask("Cost Narratives");

  static readonly PRICE_APPROVAL = new EnumTask("Price Approval");
  static readonly FORECAST_APPROVAL = new EnumTask("Forecast Approval");
  static readonly COST_APPROVAL = new EnumTask("Cost Approval");
  static readonly CENTRAL_COST_APPROVAL = new EnumTask("Central Cost Approval");

  static values = AbstractEnum.getValues(EnumTask);

  private constructor(public readonly displayValue: string) {
    super();
  }
}
