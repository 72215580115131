import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";
import { SubDepartment } from "@planard/@core/entities/subDepartment";

@Injectable()
export class DepartmentApi {
  private readonly apiController: string = "department";

  constructor(private api: HttpService) {}

  listAll<T>(page?: number, pageSize?: number): Observable<any> {
    const endpointUrl =
      page && pageSize
        ? `${this.apiController}/listAll/${page}/${pageSize}`
        : `${this.apiController}/listAll`;
    return this.api.get(endpointUrl);
  }

  add<T>(department: any): Observable<any> {
    return this.api.post(`${this.apiController}/add`, department);
  }

  update<T>(department: any): Observable<any> {
    return this.api.post(`${this.apiController}/update`, department);
  }

  delete<T>(departmentId: any): Observable<any> {
    var json = { id: departmentId };
    const endPointUrl = `${this.apiController}/delete`;
    return this.api.post(endPointUrl, json);
  }

  listAllSub<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAllSub`, undefined);
  }

  addSub<T>(subdepartment: SubDepartment): Observable<any> {
    return this.api.post(`${this.apiController}/addSub`, subdepartment);
  }

  updateSub<T>(subdepartment: SubDepartment): Observable<any> {
    return this.api.post(`${this.apiController}/updateSub`, subdepartment);
  }

  deleteSub<T>(subdepartmentId: number): Observable<any> {
    var json = { id: subdepartmentId };
    return this.api.post(`${this.apiController}/deleteSub`, json);
  }
}
