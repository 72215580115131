<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div>
          <div class="div" style="width: 60%">
            <mat-card-title
              >{{ "reports.launchTimeline" | translate }} <br />
              <span style="font-size: 15px"
                >{{ companyNumberFormat }} {{ companyCurrency }}</span
              ></mat-card-title
            >
          </div>

          <!-- GUIDANCE -->
          <div class="div" style="width: 15%">
            <mat-form-field style="width: 85%">
              <mat-select
                (selectionChange)="setGuidanceScenario()"
                [(ngModel)]="guidanceId"
                placeholder="{{ 'reports.planningCycle' | translate }}"
                [formControl]="guidanceControl"
                required
              >
                <mat-option
                  *ngFor="let o of guidanceList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.planningCycleName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="guidanceControl.hasError('required')">{{
                "reports.planningCycleChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- SCENARIO -->
          <div class="div" style="width: 15%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="scenarioId"
                placeholder="{{ 'reports.scenario' | translate }}"
                [formControl]="scenarioControl"
                required
              >
                <mat-option
                  *ngFor="let o of scenarioGuiList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="scenarioControl.hasError('required')">{{
                "reports.scenarioChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <div style="width: 85%">
              <button color="accent" (click)="isValid()" mat-button>
                {{ "reports.run" | translate }}
              </button>
            </div>
          </div>
        </div>
        <br />
        <div>
          <div class="div" style="width: 15%"></div>
          <div class="div" style="width: 70%; margin-bottom: 50px" #screen>
            <chart
              *ngIf="isRun == true"
              class="chart"
              type="horizontalBar"
              [data]="chartData"
              [options]="options"
            ></chart>
          </div>
        </div>

        <span *ngIf="isRun != true" style="width: 60%; float: right">{{
          "reports.seeChart" | translate
        }}</span>

        <mat-card-actions>
          <button (click)="take()" color="accent" mat-raised-button>
            <mat-icon>camera_alt</mat-icon>&nbsp;{{
              "reports.take" | translate
            }}</button
          >&nbsp;
          <button
            *ngIf="img"
            (click)="reset()"
            color="accent"
            mat-raised-button
          >
            <mat-icon>delete_sweep</mat-icon>&nbsp;{{
              "reports.reset" | translate
            }}</button
          >&nbsp;
          <button *ngIf="img" (click)="save()" color="accent" mat-raised-button>
            <mat-icon>save</mat-icon>&nbsp;{{ "reports.save" | translate }}
          </button>

          <br />
          <br />

          <img *ngIf="img" src="{{ img }}" style="width: 50%; height: 50%" />
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
