import { Injectable, EventEmitter } from "@angular/core";
import { ProductFamilyApi } from "../api/product-family.api";
import { ProductFamily } from "../../entities/productFamily";

@Injectable()
export class ProductFamilyService {
  onProductFamilyReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ProductFamilyApi) {}

  listAll(page?: number, pageSize?: number) {
    return this.api.listAll<ProductFamily[]>(page, pageSize);
  }

  listUserProductFamilies(page?: number, pageSize?: number) {
    return this.api.listUserProductFamilies<ProductFamily[]>(page, pageSize);
  }

  add(productFamily: ProductFamily) {
    return this.api.add<any>(productFamily);
  }

  update(productFamily: ProductFamily) {
    return this.api.update<any>(productFamily);
  }

  delete(productFamilyId: number) {
    return this.api.delete<any>(productFamilyId);
  }
}
