import { Component, Inject, OnInit } from "@angular/core";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { Scenario } from "@planard/@core/entities/scenario";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProcessResult } from "@planard/@core/entities/process-result";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { Products } from "@planard/@core/entities/product";
import { TranslateService } from "@ngx-translate/core";
import { MatSelect } from "@angular/material/select";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./add-scenario.component.html",
  styles: [``],
})
export class AddScenarioComponent implements OnInit {
  productList: Products[];
  scenario: Scenario;
  processResult = new ProcessResult();
  guidanceId: number;
  impactAreaList = ["P", "V", "C"];

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, removeSpaces]),
    impactedProducts: new FormControl("", [Validators.required]),
    impactArea: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required, removeSpaces]),
  });
  options: FormGroup;

  allProducts: boolean;

  constructor(
    public fb: FormBuilder,
    public productsService: ProductsService,
    public scenarioService: ScenarioService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    private guidanceService: GuidanceService,
    public dialogRef: MatDialogRef<AddScenarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Scenario
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.productsService.listAll().subscribe((data) => {
      this.productList = data;
    });

    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidanceId = result.id;
    });
  }

  save() {
    if (this.form.valid) {
      this.scenario = this.form.value;

      var price = false;
      var volume = false;
      var cost = false;

      if (this.scenario.impactArea.includes("P")) price = true;
      if (this.scenario.impactArea.includes("V")) volume = true;
      if (this.scenario.impactArea.includes("C")) cost = true;

      var newImpactArea;
      if (price && volume && cost) newImpactArea = "PVC";
      if (price && volume && !cost) newImpactArea = "PV";
      if (price && !volume && cost) newImpactArea = "PC";
      if (!price && volume && cost) newImpactArea = "VC";
      if (price && !volume && !cost) newImpactArea = "P";
      if (!price && volume && !cost) newImpactArea = "V";
      if (!price && !volume && cost) newImpactArea = "C";

      setTimeout(() => {
        this.scenario.impactArea = newImpactArea;
        this.scenarioService.add(this.scenario).subscribe((backendResult) => {
          if (backendResult.isSuccess) {
            this.translate
              .get(`messages.${backendResult.messageId}`)
              .subscribe((data: any) => {
                this.startCycleAuto();
                this.toastrService.success(data);
              });
            this.dialogRef.close(this.scenario);
          } else {
            this.translate
              .get(`messages.${backendResult.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.error(data);
              });
          }
        });
      }, 500);
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  //product
  selectAll(select: MatSelect) {
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    this.form.controls["impactedProducts"].setValue(selectedIds);
    this.allProducts = true;
  }

  selectOne(id: number) {
    if (this.allProducts == true) {
      this.allProducts = false;
      let selectedId = [id];
      this.form.controls["impactedProducts"].setValue(selectedId);
    }
  }

  deselectOne(id: number, select: MatSelect) {
    if (this.allProducts == true) {
      this.allProducts = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && id != element.value.id) {
          selectedIds.push(element.value);
        }
      });
      this.form.controls["impactedProducts"].setValue(selectedIds);
    }
  }

  deselectAll() {
    this.allProducts = false;
    let selectedIds = [];
    this.form.controls["impactedProducts"].setValue(selectedIds);
  }

  startCycleAuto() {
    let body = { guidanceId: this.guidanceId };
    this.guidanceService.startGuidance(body).subscribe();
  }
}
