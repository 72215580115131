import { EventEmitter, Injectable } from "@angular/core";
import { PriceData } from "@planard/@core/entities/interface/price";
import { PriceApi } from "@planard/@core/backend/api/price.api";
import { Price } from "@planard/@core/entities/price";
import { Products } from "@planard/@core/entities/product";

@Injectable()
export class PriceService extends PriceData {
  onPriceReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: PriceApi) {
    super();
  }

  listPrices(scenarioId: number, approveRequestId?: number) {
    return this.api.listPrices<any>(scenarioId, approveRequestId);
  }

  update(price: Price) {
    return this.api.update<any>(price);
  }

  listPriceTrend(
    scenarioId: number,
    productId: number,
    formId: number,
    channelId: number,
    actuals: number,
    period: number,
    currencyId: number
  ) {
    return this.api.listPriceTrend<any>(
      scenarioId,
      productId,
      formId,
      channelId,
      actuals,
      period,
      currencyId
    );
  }

  listPriceProducts(scenarioId: number) {
    return this.api.listPriceProducts<Products[]>(scenarioId);
  }

  listPriceChannels(scenarioId: number, productId: number) {
    return this.api.listPriceChannels<Products[]>(scenarioId, productId);
  }

  listPriceForms(scenarioId: number, productId: number) {
    return this.api.listPriceForms<Products[]>(scenarioId, productId);
  }
}
