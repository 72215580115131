import { Scenario } from "../../entities/scenario";
import { ScenarioApi } from "../api/scenario.api";
import { Injectable, EventEmitter } from "@angular/core";

@Injectable()
export class ScenarioService {
  onScenarioReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ScenarioApi) {}

  listAll(page?: number, pageSize?: number) {
    return this.api.listAll<Scenario[]>(page, pageSize);
  }

  listScenariosWithImpact(impactArea: string) {
    return this.api.listScenariosWithImpact<Scenario[]>(impactArea);
  }

  listScenariosWithSales() {
    return this.api.listScenariosWithSales<Scenario[]>();
  }

  listScenariosByGuidance(guidanceId: number, type: number) {
    return this.api.listScenariosByGuidance<Scenario[]>(guidanceId, type);
  }

  listScenariosWithImpactWhole(impactArea: string) {
    return this.api.listScenariosWithImpactWhole<Scenario[]>(impactArea);
  }

  listWhole(page?: number, pageSize?: number) {
    return this.api.listWhole<Scenario[]>(page, pageSize);
  }

  add(scenario: Scenario) {
    return this.api.add<any>(scenario);
  }

  update(scenario: Scenario) {
    return this.api.update<any>(scenario);
  }

  delete(scenarioId: number) {
    return this.api.delete<any>(scenarioId);
  }
}
