<h1 mat-dialog-title>{{ "guidance.deadlines.edit" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        tabindex="1"
        [matDatepicker]="spicker"
        formControlName="preSubmission"
        matInput
        name="preSubmission"
        placeholder="{{ 'guidance.deadlines.pre' | translate }}"
      />
      <mat-datepicker-toggle [for]="spicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #spicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="2"
        [matDatepicker]="epicker"
        formControlName="finalSubmission"
        matInput
        name="finalSubmission"
        placeholder="{{ 'guidance.deadlines.final' | translate }}"
      />
      <mat-datepicker-toggle [for]="epicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #epicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="3" type="submit">
      {{ "guidance.add" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="4" type="button">
      {{ "guidance.cancel" | translate }}
    </button>
  </div>
</form>
