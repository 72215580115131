<h1 mat-dialog-title>{{ "actualsImport.title" | translate }}</h1>
<form [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <mat-select
        tabindex="2"
        formControlName="type"
        name="type"
        placeholder="{{ 'actualsImport.type' | translate }}"
      >
        <mat-option value="Price(Price)">{{
          "actuals.Price(Price)" | translate
        }}</mat-option>
        <mat-option value="Price(Discount)">{{
          "actuals.Price(Discount)" | translate
        }}</mat-option>
        <mat-option value="Forecast(Volume)">{{
          "actuals.Forecast(Volume)" | translate
        }}</mat-option>
        <mat-option value="Forecast(Amount)">{{
          "actuals.Forecast(Amount)" | translate
        }}</mat-option>
        <mat-option value="OtherForecast">{{
          "actuals.OtherForecast" | translate
        }}</mat-option>
        <mat-option value="Cost">{{ "actuals.Cost" | translate }}</mat-option>
        <mat-option value="CentralCost">{{
          "actuals.CentralCost" | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="3"
        placeholder="{{ 'actualsImport.period' | translate }}"
        name="period"
        formControlName="period"
      >
        <mat-option *ngFor="let o of yearList" [value]="o" value="option">
          {{ o }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <input
      style="display: none"
      type="file"
      (change)="upload($event)"
      #hiddenfileinput
    />
    <button (click)="hiddenfileinput.click()" color="accent" mat-raised-button>
      <mat-icon>cloud_download</mat-icon>&nbsp;{{
        "actualsImport.select" | translate
      }}&nbsp;&nbsp;</button
    >&nbsp;{{ exelFileName }}
  </div>
  <div mat-dialog-actions>
    <button
      *ngIf="isSpreadDone == true"
      mat-button
      tabindex="4"
      (click)="save()"
    >
      {{ "actualsImport.upload" | translate }}
    </button>
    <button *ngIf="isSpreadDone != true" mat-button tabindex="4" disabled>
      {{ "actualsImport.upload" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="5" type="button">
      {{ "actualsImport.cancel" | translate }}
    </button>
  </div>
</form>
