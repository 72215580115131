import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { EnumImpactArea } from "@planard/@core/enums/EnumImpactArea";
import { Products } from "@planard/@core/entities/product";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { RiskOpportunities } from "@planard/@core/entities/riskOpportunities";
import { RiskOpportunitiesService } from "@planard/@core/backend/service/risk-opportunities.service";
import { AddRiskOpportunitiesComponent } from "./add/add-risk-opportunities.component";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { NumberDecimalReversePipe } from "@planard/@core/pipes/NumberDecimalReversePipe";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import { TranslateService } from "@ngx-translate/core";
@Component({
  templateUrl: "./risk-opportunities-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 5%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
      ::ng-deep .mat-option:first-child .mat-pseudo-checkbox {
        display: initial;
      }
    `,
  ],
  providers: [NumberDecimalPipe, NumberDecimalReversePipe],
})
export class RiskOpportunitiesNewComponent implements OnInit {
  displayedColumns: string[] = [
    "description",
    "impactArea",
    "typeOfImpact",
    "impactAmount",
    "likeliHood",
    "dayOfCertainty",
    "actions",
  ];
  dataSource: MatTableDataSource<RiskOpportunities>;
  controls: any[];
  isScreenValid: boolean;
  isRoleValid: boolean;
  typeOfImpactList = ["Risk", "Opportunity"];
  productList: Products[];
  guidanceId: number;
  currentGuidanceId: number;
  guidanceList: [];
  guidanceGroup: any;
  currenctguidance = [];
  oldguidance = [];
  year: number = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();
  numberFormat: string;
  decimalFormat: string;
  companyPackage: string;
  impactAreaList = ["P", "V", "C"];
  lang;

  monthList = [
    { value: "Jan" + this.year, label: "Jan" + this.year },
    { value: "Feb" + this.year, label: "Feb" + this.year },
    { value: "Mar" + this.year, label: "Mar" + this.year },
    { value: "Apr" + this.year, label: "Apr" + this.year },
    { value: "May" + this.year, label: "May" + this.year },
    { value: "Jun" + this.year, label: "Jun" + this.year },
    { value: "Jul" + this.year, label: "Jul" + this.year },
    { value: "Aug" + this.year, label: "Aug" + this.year },
    { value: "Sept" + this.year, label: "Sep" + this.year },
    { value: "Oct" + this.year, label: "Oct" + this.year },
    { value: "Nov" + this.year, label: "Nov" + this.year },
    { value: "Dec" + this.year, label: "Dec" + this.year },
  ];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public riskOpportunitiesService: RiskOpportunitiesService,
    public productsService: ProductsService,
    //private dateAdapter: DateAdapter<Date>,
    public authService: AuthService,
    public translate: TranslateService,
    private numberDecimal: NumberDecimalPipe,
    private numberReverseDecimal: NumberDecimalReversePipe,
    public guidanceService: GuidanceService,
    private router: Router,
    public toastrService: ToastrService
  ) {
    //this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy

    this.isRoleValid =
      this.authService.isBusinessUnitPlanner() ||
      this.authService.isBusinessUnitApprover() ||
      this.authService.isPricePlanner() ||
      this.authService.isSupportFunctionPlanner() ||
      this.authService.isSupportFunctionApprover();
    this.companyPackage = this.authService.getCompanyPackage();
    if (this.isRoleValid == false || this.companyPackage == "basic")
      this.router.navigate(["auth/404"]);
    this.isScreenValid = true; //this.authService.riskOpp();
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();
    // if (this.isScreenValid == false) {
    //   this.translate.get("entryScreens.riskOppsTask").subscribe((data: any) => {
    //     this.toastrService.error(data);
    //   });
    // }

    this.lang = this.translate.currentLang;
    translate.onLangChange.subscribe((lang) => {
      this.lang = this.translate.currentLang;
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();

    this.currenctguidance = [];
    this.oldguidance = [];
    this.productsService.listAll().subscribe((result) => {
      this.productList = result;
    });
    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidanceId = result.id;
      this.currentGuidanceId = result.id;

      this.guidanceService.listAll().subscribe((result) => {
        this.guidanceList = result;
        result.forEach((element) => {
          if (element.id == this.guidanceId) {
            this.currenctguidance.push({
              value: element.id,
              label: element.planningCycleName,
            });
          } else {
            this.oldguidance.push({
              value: element.id,
              label: element.planningCycleName,
            });
          }
        });
      });
    });

    setTimeout(() => {
      this.guidanceGroup = [
        {
          name: "Current Plan", // value yıl olucak
          guidance: this.currenctguidance,
        },
        {
          name: "Old Plans", // value plannig id olucak
          guidance: this.oldguidance,
        },
      ];
    }, 1000);

    this.riskOpportunitiesService.listAll().subscribe((data) => {
      data.forEach((element) => {
        if (this.lang == "tr") {
          if (element.impactArea == "PVC")
            element.imapctAreaStr = "Fiyat, Hacim, Masraf";
          if (element.impactArea == "PV")
            element.imapctAreaStr = "Fiyat, Hacim";
          if (element.impactArea == "PC")
            element.imapctAreaStr = "Fiyat, Masraf";
          if (element.impactArea == "VC")
            element.imapctAreaStr = "Hacim, Masraf";
          if (element.impactArea == "P") element.imapctAreaStr = "Fiyat";
          if (element.impactArea == "V") element.imapctAreaStr = "Hacim";
          if (element.impactArea == "C") element.imapctAreaStr = "Masraf";
        } else {
          if (element.impactArea == "PVC")
            element.imapctAreaStr = "Price, Volume, Cost";
          if (element.impactArea == "PV")
            element.imapctAreaStr = "Price, Volume";
          if (element.impactArea == "PC") element.imapctAreaStr = "Price, Cost";
          if (element.impactArea == "VC")
            element.imapctAreaStr = "Volume, Cost";
          if (element.impactArea == "P") element.imapctAreaStr = "Price";
          if (element.impactArea == "V") element.imapctAreaStr = "Volume";
          if (element.impactArea == "C") element.imapctAreaStr = "Cost";
        }

        element.impactArea = this.getImpactAreaEdit(element.impactArea);
      });

      this.dataSource = new MatTableDataSource(data);

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "description", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              description: new FormControl(entity.description, [
                Validators.required,
              ]),
              impactArea: new FormControl(entity.impactArea, [
                Validators.required,
              ]),
              typeOfImpact: new FormControl(entity.typeOfImpact, [
                Validators.required,
              ]),
              impactAmount: new FormControl(
                entity.impactAmount /*this.formatNumber(entity.impactAmount, this.decimalFormat, this.numberFormat)*/,
                [Validators.required, Validators.min(0)]
              ),
              likeliHood: new FormControl(entity.likeliHood, [
                Validators.required,
              ]),
              dayOfCertainty: new FormControl(entity.dayOfCertainty, [
                Validators.required,
              ]),
            },
            { updateOn: "change" }
          ),
        };
      });
    });
  }

  formatNumber(value, decimal, number) {
    return this.numberDecimal.transform(value, decimal, number);
  }

  formatNumberReverse(string, decimal, number) {
    return this.numberReverseDecimal.transform(string, decimal, number);
  }

  getGuidanceData() {
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();

    this.riskOpportunitiesService
      .listAllByGuidanceId(this.guidanceId)
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);

        if (this.dataSource != null) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          const sortState: Sort = { active: "description", direction: "asc" };
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState);
        }

        this.controls = data.map((entity) => {
          return {
            data: entity,
            formGroup: new FormGroup(
              {
                description: new FormControl(entity.description, [
                  Validators.required,
                ]),
                impactArea: new FormControl(entity.impactArea, [
                  Validators.required,
                ]),
                typeOfImpact: new FormControl(entity.typeOfImpact, [
                  Validators.required,
                ]),
                impactAmount: new FormControl(
                  this.formatNumber(
                    entity.impactAmount,
                    this.decimalFormat,
                    this.numberFormat
                  ),
                  [Validators.required, Validators.min(0)]
                ),
                likeliHood: new FormControl(entity.likeliHood, [
                  Validators.required,
                ]),
                dayOfCertainty: new FormControl(entity.dayOfCertainty, [
                  Validators.required,
                ]),
              },
              { updateOn: "blur" }
            ),
          };
        });
      });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var risk = this.getElement(index);
      risk[field] = control.value;

      if (control.value == null) {
        this.translate.get("riskOpps.value").subscribe((data: any) => {
          this.toastrService.success(data);
        });
        return;
      }

      console.log(risk);

      if (typeof risk.impactArea === "string") {
        this.riskOpportunitiesService.update(risk).subscribe((result) => {
          if (result.isSuccess) {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.success(data);
              });
            this.ngOnInit();
          } else {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.error(data);
              });
            this.ngOnInit();
          }
        });
      } else {
        var price = false;
        var volume = false;
        var cost = false;

        if (risk.impactArea.includes("P")) price = true;
        if (risk.impactArea.includes("V")) volume = true;
        if (risk.impactArea.includes("C")) cost = true;

        var newImpactArea;
        if (price && volume && cost) newImpactArea = "PVC";
        if (price && volume && !cost) newImpactArea = "PV";
        if (price && !volume && cost) newImpactArea = "PC";
        if (!price && volume && cost) newImpactArea = "VC";
        if (price && !volume && !cost) newImpactArea = "P";
        if (!price && volume && !cost) newImpactArea = "V";
        if (!price && !volume && cost) newImpactArea = "C";

        setTimeout(() => {
          risk.impactArea = newImpactArea;
          this.riskOpportunitiesService.update(risk).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.ngOnInit();
            }
          });
        }, 500);
      }
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.success(data);
      });
      this.ngOnInit();
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    const dialogRef = this.dialog.open(AddRiskOpportunitiesComponent, {
      width: "500px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  onDelete(row: any) {
    this.translate.get("delete.riskopp").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.riskOpportunitiesService.delete(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.ngOnInit();
            }
          });
        }
      });
    });
  }

  getModelImpactArea(impact: string) {
    var Arr = impact.split("");
    var impactArea = [];
    Arr.forEach((ele) => {
      if (ele == "P") {
        impactArea.push(EnumImpactArea.PRICE);
      } else if (ele == "V") {
        impactArea.push(EnumImpactArea.VOLUME);
      } else if (ele == "C") {
        impactArea.push(EnumImpactArea.COST);
      }
    });
    return impactArea;
  }

  getImpactArea(impact: string) {
    var Arr = impact.split("");
    var impactArea = [];
    Arr.forEach((ele) => {
      if (ele == "P") {
        impactArea.push("Price");
      } else if (ele == "V") {
        impactArea.push("Volume");
      } else if (ele == "C") {
        impactArea.push("Cost");
      }
    });
    var Str = impactArea.join(", ");
    return impactArea;
  }

  getImpactAreaEdit(impact: string): Array<string> {
    return impact.split("");
  }

  outToastr(msg: string) {
    this.translate.get(`${msg}`).subscribe((data: any) => {
      this.toastrService.error(data);
    });
  }
}
