<h1 mat-dialog-title>{{ "productFamilyAdd.title" | translate }}</h1>

<form [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        tabindex="1"
        matInput
        name="name"
        formControlName="name"
        placeholder="{{ 'productFamilyAdd.name' | translate }}"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="2"
        formControlName="businessUnitId"
        name="businessUnit"
        placeholder="{{ 'productFamilyAdd.businessUnit' | translate }}"
      >
        <mat-option
          *ngFor="let o of businessUnitList"
          [value]="o.id"
          value="option"
        >
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-button tabindex="3" (click)="save()">
      {{ "productFamilyAdd.save" | translate }}
    </button>
    <button mat-button tabindex="4" (click)="cancel()">
      {{ "productFamilyAdd.cancel" | translate }}
    </button>
  </div>
</form>
