<h1 mat-dialog-title>{{ "guidance.faq.edit" | translate }}</h1>
<div mat-dialog-content>
  <div class="responsive-table">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="question">
        <mat-header-cell *matHeaderCellDef mat-sort-header="question">
          {{ "guidance.faq.question" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'question')">
            <ng-template viewMode>
              {{ element.question }}
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <input
                  matInput
                  [formControl]="getControl(element.id, 'question')"
                  focusable
                  editableOnEnter
                />
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="answer">
        <mat-header-cell *matHeaderCellDef mat-sort-header="answer">{{
          "guidance.faq.answer" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'answer')">
            <ng-template viewMode>
              {{ element.answer != "" ? element.answer : "-" }}
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <input
                  matInput
                  [formControl]="getControl(element.id, 'answer')"
                  focusable
                  editableOnEnter
                />
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
          {{ "guidance.actions" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
          <button
            (click)="onDelete(row)"
            autofocus
            color="warn"
            mat-icon-button
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>

    <table *ngIf="IsEmpty == true" style="min-height: 48px">
      <tr>
        <td style="text-align: center" [attr.colspan]="displayedColumns.length">
          No Records Found!
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25]"
    ></mat-paginator>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="addDialog()" mat-button tabindex="2">
    {{ "guidance.add" | translate }}
  </button>
  <button (click)="cancel()" mat-button type="button">
    {{ "guidance.close" | translate }}
  </button>
</div>
