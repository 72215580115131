import { BaseEntity } from "@planard/@core/base/BaseEntity";
import { EnumTask } from "@planard/@core/enums/EnumTask";
import { Role } from "@planard/@core/entities/role";

export class Process extends BaseEntity {
  task?: EnumTask;
  customTask?: string;
  processRolesStr?: string;
  processRoles?: Role[];
  startDate?: Date;
  endDate?: Date;
  dependentProcessId?: number;
  daysToExecute?: number;
  selectedProcessRoles?: number[];
}
