<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div>
          <div class="div" style="width: 10%">
            <mat-card-title
              >{{ "reports.costTrend" | translate }}
              <br />
              <span style="font-size: 15px"
                >{{ companyNumberFormat }} {{ companyCurrency }}</span
              ></mat-card-title
            >
          </div>

          <!-- SCENARIO -->
          <div
            class="div"
            style="width: 9%"
            [ngStyle]="{
              'margin-left': this.package == 'basic' ? '18%' : '0%'
            }"
          >
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="scenarioId"
                placeholder="{{ 'reports.scenario' | translate }}"
                [formControl]="scenarioControl"
                required
              >
                <mat-option
                  *ngFor="let o of scenarioList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="scenarioControl.hasError('required')">{{
                "reports.scenarioChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- CATEGORY -->
          <div class="div" style="width: 9%">
            <mat-form-field style="width: 85%">
              <mat-select
                (selectionChange)="setSubcategoryCat()"
                placeholder="{{ 'reports.category' | translate }}"
                [(ngModel)]="categoryId"
                [formControl]="catControl"
                required
              >
                <mat-option [value]="0" value="option">{{
                  "reports.allcats" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of categoryList"
                  [value]="o.id"
                  value="option"
                >
                  <span *ngIf="'category.' + o.name as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="catControl.hasError('required')">{{
                "reports.categoryChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- SUB CATEGORY -->
          <div class="div" style="width: 9%">
            <mat-form-field style="width: 85%">
              <mat-select
                *ngIf="categoryId != 0"
                (selectionChange)="setCost()"
                placeholder="{{ 'reports.subcategory' | translate }}"
                [(ngModel)]="subcategoryId"
                [formControl]="subControl"
                required
              >
                <mat-option [value]="0" value="option">{{
                  "reports.allSubs" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of subcategoryCatList"
                  [value]="o.id"
                  value="option"
                >
                  <span *ngIf="'subcategory.' + o.name as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-select
                *ngIf="categoryId == 0"
                (selectionChange)="setCost()"
                placeholder="{{ 'reports.subcategory' | translate }}"
                [(ngModel)]="subcategoryId"
                [formControl]="subControl"
                required
                disabled
              >
                <mat-option [value]="0" value="option">{{
                  "reports.allSubs" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of subcategoryCatList"
                  [value]="o.id"
                  value="option"
                >
                  <span *ngIf="'subcategory.' + o.name as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="subControl.hasError('required')">{{
                "reports.subcategoryChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- COST -->
          <div
            class="div"
            style="width: 9%"
            *ngIf="subcategoryId != 0 && categoryId != 0"
          >
            <mat-form-field style="width: 85%">
              <mat-select
                placeholder="{{ 'reports.cost' | translate }}"
                [(ngModel)]="costId"
                [formControl]="costControl"
                required
              >
                <mat-option
                  *ngFor="let o of costFilteredList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="costControl.hasError('required')">{{
                "reports.costChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- COST -->
          <div
            class="div"
            style="width: 9%"
            *ngIf="subcategoryId == 0 || categoryId == 0"
          >
            <mat-form-field style="width: 85%">
              <mat-select
                placeholder="{{ 'reports.cost' | translate }}"
                [(ngModel)]="costId"
                [formControl]="costControl"
                disabled
              >
                <mat-option
                  *ngFor="let o of costFilteredList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="costControl.hasError('required')">{{
                "reports.costChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- GRANULARITY -->
          <div class="div" style="width: 9%" *ngIf="this.package == 'premium'">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="granularity"
                name="granularity"
                placeholder="{{ 'reports.granularity' | translate }}"
                [formControl]="granularityControl"
                required
              >
                <mat-option
                  *ngFor="let o of granularityList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="granularityControl.hasError('required')">{{
                "reports.granularityChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- SUBDEPARTMENT -->
          <div
            class="div"
            style="width: 9%"
            *ngIf="granularity == 0 && !isApprover && this.package == 'premium'"
          >
            <mat-form-field style="width: 85%">
              <mat-select
                multiple
                [(ngModel)]="subDepartmentId"
                placeholder="{{ 'reports.subdepartment' | translate }}"
                [formControl]="subDeptControl"
                disabled
              >
                <mat-option
                  *ngFor="let o of subDeptList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- SUBDEPARTMENT -->
          <div
            class="div"
            style="width: 9%"
            *ngIf="granularity != 0 && this.package == 'premium'"
          >
            <mat-form-field style="width: 85%">
              <mat-select
                multiple
                [(ngModel)]="subDepartmentId"
                placeholder="{{ 'reports.subdepartment' | translate }}"
                [formControl]="subDeptControl"
                required
              >
                <mat-option
                  *ngFor="let o of subDeptList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="periodControl.hasError('required')">{{
                "reports.subdepartmentChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 9%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="timescale"
                placeholder="{{ 'reports.timeScale' | translate }}"
                [formControl]="scaleControl"
                required
              >
                <mat-option
                  *ngFor="let o of timescaleList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="scaleControl.hasError('required')">{{
                "reports.timeScaleChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 9%">
            <mat-form-field style="width: 85%">
              <mat-select
                *ngIf="timescale == 0"
                [(ngModel)]="period"
                placeholder="{{ 'reports.period' | translate }}"
                [formControl]="periodControl"
                required
              >
                <mat-optgroup
                  *ngFor="let group of periodGroup"
                  [label]="group.name"
                >
                  <mat-option
                    *ngFor="let period of group.period"
                    [value]="period"
                  >
                    {{ period.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-select
                *ngIf="timescale == 1"
                [(ngModel)]="period"
                placeholder="{{ 'reports.period' | translate }}"
              >
                <mat-optgroup
                  *ngFor="let group of periodGroup"
                  [label]="group.name"
                  disabled
                >
                  <mat-option
                    *ngFor="let period of group.period"
                    [value]="period"
                  >
                    {{ period.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-error
                *ngIf="periodControl.hasError('required') && timescale == 0"
                >{{ "reports.periodChoose" | translate }}</mat-error
              >
            </mat-form-field>
          </div>

          <div class="div" style="width: 9%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="currency"
                placeholder="{{ 'reports.currency' | translate }}"
                [formControl]="currencyControl"
                required
              >
                <mat-option [value]="0" value="option">{{
                  "reports.defaultCurrency" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of guidanceFxRateList"
                  [value]="o.fxRateId"
                  value="option"
                >
                  {{ getFxRate(o.fxRateId) }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="currencyControl.hasError('required')">{{
                "reports.currencyChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 9%">
            <div style="width: 85%">
              <button color="accent" (click)="isValid()" mat-button>
                {{ "reports.run" | translate }}
              </button>
            </div>
          </div>
        </div>
        <br />

        <div>
          <div class="div" style="width: 10%"></div>

          <div class="div" style="width: 80%" #screen>
            <canvas
              *ngIf="isRun == true"
              baseChart
              [datasets]="barChartData"
              [labels]="reportData.chartLabels"
              [options]="barChartOptions"
              [chartType]="barChartType"
              [colors]="barChartColors"
            >
            </canvas>
            <!-- (chartHover)="return;" -->
            <!-- (chartClick)="return;" -->
          </div>
        </div>
        <span *ngIf="isRun != true" style="width: 60%; float: right">{{
          "reports.seeChart" | translate
        }}</span>
        <br />
        <br />
        <br />

        <button (click)="take()" color="accent" mat-raised-button>
          <mat-icon>camera_alt</mat-icon>&nbsp;{{
            "reports.take" | translate
          }}</button
        >&nbsp;
        <button *ngIf="img" (click)="reset()" color="accent" mat-raised-button>
          <mat-icon>delete_sweep</mat-icon>&nbsp;{{
            "reports.reset" | translate
          }}</button
        >&nbsp;
        <button *ngIf="img" (click)="save()" color="accent" mat-raised-button>
          <mat-icon>save</mat-icon>&nbsp;{{ "reports.save" | translate }}
        </button>

        <br />
        <br />
        <img *ngIf="img" src="{{ img }}" style="width: 50%; height: 50%" />
      </mat-card-content>
    </mat-card>

    <!--<mat-card *ngIf="guidanceIsStart==null">
          <mat-card-content style="min-height: 200px;">
            <div>
              <div class="div" style="width: 50%;">
                <mat-card-title>Cost Trend</mat-card-title>
              </div>
            </div>
            <br>
            <br>
            <span *ngIf="isRun!=true" style="width: 60%; float: right;">Guidance not started yet.</span>
          </mat-card-content>
      </mat-card>-->
  </div>
</div>
