import { EventEmitter, Injectable } from "@angular/core";
import { Users } from "../../entities/user";
import { ApplicationUser } from "../../entities/applicationUser";
import { AccountsData } from "../../entities/interface/accounts";
import { AccountsApi } from "../api/accounts.api";
import { Department } from "@planard/@core/entities/department";
import { SubDepartment } from "@planard/@core/entities/subDepartment";
import { Observable } from "rxjs";

@Injectable()
export class AccountsService extends AccountsData {
  onUsersReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: AccountsApi) {
    super();
  }

  getUser(): Observable<ApplicationUser> {
    return this.api.getUser<ApplicationUser>();
  }

  getSignedUser() {
    return this.api.getSignedUser<any>();
  }

  isApprover(userId: any) {
    return this.api.isApprover<any>(userId);
  }

  listUsers() {
    return this.api.listUsers<Users[]>();
  }

  add(user: Users) {
    return this.api.add<any>(user);
  }

  changePassword(password: any) {
    return this.api.changePassword<any>(password);
  }

  listDepartments(): Observable<Department[]> {
    return this.api.listDepartments<Department[]>();
  }

  listSubDepartments(): Observable<SubDepartment[]> {
    return this.api.listSubDepartments<SubDepartment[]>();
  }

  updateUser(user: Users): Observable<any> {
    return this.api.updateUser<any>(user);
  }

  deleteUser(rowId: number): Observable<any> {
    return this.api.deleteUser<any>(rowId);
  }
}
