<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div>
          <div class="div" style="width: 40%; vertical-align: top">
            <br />
            <mat-card-title>{{
              "companySetup.title" | translate
            }}</mat-card-title>
          </div>
          <div class="div" style="width: 50%; vertical-align: top">
            <br />
            <mat-card-title
              *ngIf="package == 'premium'"
              style="color: rgb(255, 160, 52)"
              >{{ "companySetup.premium" | translate }}</mat-card-title
            >
            <mat-card-title
              *ngIf="package == 'basic'"
              style="color: rgb(84, 137, 236)"
              >{{ "companySetup.basic" | translate }}</mat-card-title
            >
          </div>
          <div class="div" style="width: 10%">
            <img
              *ngIf="companyLogo != null"
              src="{{ companyLogo }}"
              onerror="this.onerror=null;this.src='/assets/images/defaultlogo.png';"
              style="
                max-width: 100px;
                max-height: 100px;
                min-width: 100px;
                object-fit: cover;
              "
            />
          </div>
        </div>
        <br />

        <form [formGroup]="myform">
          <mat-dialog-content>
            <mat-form-field>
              <input
                style="color: grey"
                tabindex="1"
                matInput
                formControlName="name"
                name="name"
                placeholder="{{ 'companySetup.company' | translate }}"
                readonly
              />
            </mat-form-field>

            <mat-form-field>
              <input
                style="color: grey"
                tabindex="2"
                matInput
                formControlName="code"
                name="code"
                placeholder="{{ 'companySetup.code' | translate }}"
                readonly
              />
            </mat-form-field>

            <mat-form-field readonly>
              <input
                style="color: grey"
                tabindex="3"
                matInput
                formControlName="sector"
                name="sector"
                placeholder="{{ 'companySetup.sector' | translate }}"
                readonly
              />
            </mat-form-field>

            <mat-form-field readonly>
              <input
                style="color: grey"
                tabindex="4"
                matInput
                formControlName="contactEmail"
                name="contactEmail"
                placeholder="{{ 'companySetup.email' | translate }}"
                readonly
              />
            </mat-form-field>

            <mat-form-field>
              <mat-select
                tabindex="5"
                formControlName="currency"
                name="currency"
                placeholder="{{ 'companySetup.currency' | translate }}"
              >
                <mat-option
                  *ngFor="let o of currencyList"
                  [value]="o"
                  value="option"
                >
                  <span *ngIf="'enums.currency.' + o as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-select
                tabindex="6"
                formControlName="decimal"
                name="decimal"
                placeholder="{{ 'companySetup.decimalFormat' | translate }}"
              >
                <mat-option value="comma">{{
                  "companySetup.comma" | translate
                }}</mat-option>
                <mat-option value="dot">{{
                  "companySetup.dot" | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-select
                tabindex="7"
                formControlName="number"
                name="number"
                placeholder="{{ 'companySetup.numberFormat' | translate }}"
              >
                <mat-option value="noScale">{{
                  "companySetup.noScale" | translate
                }}</mat-option>
                <mat-option value="thousands">{{
                  "companySetup.thousands" | translate
                }}</mat-option>
                <mat-option value="millions">{{
                  "companySetup.millions" | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-dialog-content>

          <mat-dialog-actions>
            <button
              tabindex="8"
              mat-raised-button
              color="accent"
              cdkFocusInitial
              (click)="save()"
            >
              {{ "companySetup.save" | translate }}</button
            >&nbsp;
            <input
              accept="image/*"
              style="display: none"
              type="file"
              (change)="onImageAdd($event)"
              #hiddenfileinput
            />
            <button
              tabindex="9"
              (click)="hiddenfileinput.click()"
              color="accent"
              mat-raised-button
            >
              {{ "companySetup.logo" | translate }}</button
            >&nbsp;
          </mat-dialog-actions>
        </form>

        <br *ngIf="img" />
        <span *ngIf="img" style="font-size: small"
          >{{ "companySetup.selected" | translate }}:</span
        ><br *ngIf="img" />
        <img
          *ngIf="img"
          src="{{ img }}"
          style="
            max-width: 200px;
            max-height: 200px;
            min-width: 200px;
            object-fit: cover;
          "
        />
      </mat-card-content>
    </mat-card>
  </div>
</div>
