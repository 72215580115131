import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { Faq } from "@planard/@core/entities/faq";

@Injectable()
export class FaqApi {
  private readonly apiController: string = "faqs";

  constructor(private api: HttpService) {}

  listAll<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAll`);
  }

  add<T>(faq: any): Observable<any> {
    return this.api.post(`${this.apiController}/add`, faq);
  }

  update<T>(faq: Faq): Observable<any> {
    return this.api.post(`${this.apiController}/update`, faq);
  }

  delete<T>(faqId: any): Observable<any> {
    var json = { id: faqId };
    const endPointUrl = `${this.apiController}/delete`;
    return this.api.post(endPointUrl, json);
  }
}
