import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";
import { NarrativeRequest } from "@planard/@core/entities/narrativeRequest";

@Injectable()
export class NarrativeApi {
  private readonly apiController: string = "narrative";

  constructor(private api: HttpService) {}

  addSale<T>(sale: any): Observable<any> {
    return this.api.post(`${this.apiController}/addSale`, sale);
  }

  addCost<T>(cost: any): Observable<any> {
    return this.api.post(`${this.apiController}/addCost`, cost);
  }

  addRequest<T>(request: any): Observable<any> {
    return this.api.post(`${this.apiController}/addRequest`, request);
  }

  listAllSale<T>(id: number, productId: number): Observable<any> {
    return this.api.get(`${this.apiController}/listAllSale/${id}/${productId}`);
  }

  listAllCost<T>(id: number, categoryId: string): Observable<any> {
    return this.api.get(
      `${this.apiController}/listAllCost/${id}/${categoryId}`
    );
  }

  listSalesRequests<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listSalesRequests`, undefined);
  }

  listCostRequests<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listCostRequests`, undefined);
  }

  listSalesRequestsWhole<T>(): Observable<any> {
    return this.api.get(
      `${this.apiController}/listSalesRequestsWhole`,
      undefined
    );
  }

  listCostRequestsWhole<T>(): Observable<any> {
    return this.api.get(
      `${this.apiController}/listCostRequestsWhole`,
      undefined
    );
  }

  updateSale<T>(sale: any): Observable<any> {
    return this.api.post(`${this.apiController}/updateSale`, sale);
  }

  updateCost<T>(cost: any): Observable<any> {
    return this.api.post(`${this.apiController}/updateCost`, cost);
  }

  deleteSale<T>(saleId: number): Observable<any> {
    var json = { id: saleId };
    return this.api.post(`${this.apiController}/deleteSale`, json);
  }

  deleteCost<T>(costId: number): Observable<any> {
    var json = { id: costId };
    return this.api.post(`${this.apiController}/deleteCost`, json);
  }

  deleteRequest<T>(requestId: number): Observable<any> {
    var json = { id: requestId };
    return this.api.post(`${this.apiController}/deleteRequest`, json);
  }

  updateRequest<T>(request: NarrativeRequest): Observable<any> {
    return this.api.post(`${this.apiController}/updateRequest`, request);
  }

  salesBridge<T>(
    requestId: number,
    granularity: number,
    category: any,
    currency: any
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listSalesBridge/${requestId}/${granularity}/${category}/${currency}`
    );
  }

  costBridge<T>(
    requestId: number,
    categoryId: number,
    granularity: number,
    category: any,
    currency: any
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listCostBridgeV2/${requestId}/${categoryId}/${granularity}/${category}/${currency}`
    );
  }

  submit<T>(request: any): Observable<any> {
    return this.api.post(`${this.apiController}/submit`, request);
  }
}
