<h1 mat-dialog-title>{{ "guidance.planningPeriod.edit" | translate }}</h1>
<div mat-dialog-content>
  <div class="responsive-table">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="year">
        <mat-header-cell *matHeaderCellDef mat-sort-header="year">
          {{ "guidance.planningPeriod.year" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'year')">
            <ng-template viewMode>
              {{ element.year }}
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <mat-select
                  [formControl]="getControl(element.id, 'year')"
                  [(ngModel)]="element.year"
                >
                  <mat-option
                    *ngFor="let o of yearList"
                    [value]="o"
                    value="option"
                  >
                    {{ o }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="planningType">
        <mat-header-cell *matHeaderCellDef mat-sort-header="planningType">{{
          "guidance.planningPeriod.planningType" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable2 (update)="updateField(element.id, 'planningType')">
            <ng-template viewMode2>
              <!--<span *ngIf=" 'guidance.'+element.planningType as variable">{{ variable | translate }}</span>-->
              {{ getPlanningType(element.planningType) }}
            </ng-template>
            <ng-template editMode2>
              <mat-form-field>
                <mat-select
                  (selectionChange)="setMonth(element.id, $event.target.value)"
                  [formControl]="getControl(element.id, 'planningType')"
                  [(ngModel)]="element.planningType"
                >
                  <mat-option value="Yearly">{{
                    "guidance.Yearly" | translate
                  }}</mat-option>
                  <mat-option value="Monthly">{{
                    "guidance.Monthly" | translate
                  }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-select
                  *ngIf="element.planningType != 'Yearly'"
                  [formControl]="getControl(element.id, 'startMonth')"
                  [(ngModel)]="element.startMonth"
                >
                  <mat-option
                    *ngFor="let o of monthList"
                    [value]="o.value"
                    value="option"
                  >
                    <span *ngIf="'months.' + o.label as variable">{{
                      variable | translate
                    }}</span>
                  </mat-option>
                </mat-select>
                <mat-select
                  *ngIf="element.planningType == 'Yearly'"
                  [formControl]="getControl(element.id, 'startMonth')"
                  [(ngModel)]="element.startMonth"
                  disabled
                >
                  <mat-option
                    *ngFor="let o of monthList"
                    [value]="o.value"
                    value="option"
                  >
                    <span *ngIf="'months.' + o.label as variable">{{
                      variable | translate
                    }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </editable2>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="startMonth">
        <mat-header-cell *matHeaderCellDef mat-sort-header="startMonth">{{
          "guidance.planningPeriod.startMonth" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'startMonth')">
            <ng-template viewMode>
              {{
                element.planningType == "Monthly"
                  ? getMonth(element.startMonth)
                  : "-"
              }}
            </ng-template>
            <ng-template editMode *ngIf="element.planningType == 'Monthly'">
              <mat-form-field>
                <mat-select
                  [formControl]="getControl(element.id, 'startMonth')"
                  [(ngModel)]="element.startMonth"
                >
                  <mat-option
                    *ngFor="let o of monthList"
                    [value]="o.value"
                    value="option"
                  >
                    {{ o.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="granularity">
      <mat-header-cell *matHeaderCellDef mat-sort-header="granularity">Granularity</mat-header-cell>
      <mat-cell *matCellDef="let element;">

        <editable (update)="updateField(element.id, 'granularity')">
          <ng-template viewMode>
            {{element.granularity}}
          </ng-template>
          <ng-template editMode>
            <mat-form-field>
              <mat-select [formControl]="getControl(element.id, 'granularity')" [(ngModel)]="element.granularity">
                <mat-option *ngFor="let o of granularityList" [value]="o.value" value="option">
                  {{o.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>
        </editable>

      </mat-cell>
    </ng-container>-->

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
          {{ "guidance.actions" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
          <button
            (click)="onDelete(row)"
            autofocus
            color="warn"
            mat-icon-button
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>

    <table *ngIf="IsEmpty == true" style="min-height: 48px">
      <tr>
        <td style="text-align: center" [attr.colspan]="displayedColumns.length">
          No Records Found!
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25]"
    ></mat-paginator>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="addDialog()" mat-button tabindex="2">
    {{ "guidance.add" | translate }}
  </button>
  <button (click)="cancel()" mat-button type="button">
    {{ "guidance.close" | translate }}
  </button>
</div>
