import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { OtherForecast } from "@planard/@core/entities/otherForecast";

@Injectable()
export class OtherForecastApi {
  private readonly apiController: string = "otherforecast";

  constructor(private api: HttpService) {}

  listPrices<T>(
    scenarioId: number,
    approveRequestId?: number
  ): Observable<any> {
    const endpointUrl = approveRequestId
      ? `${this.apiController}/listPrices/${scenarioId}/?approveRequestId=${approveRequestId}`
      : `${this.apiController}/listPrices/${scenarioId}`;
    return this.api.get(endpointUrl);
  }

  update<T>(otherForecast: OtherForecast): Observable<any> {
    return this.api.post(`${this.apiController}/update`, otherForecast);
  }
}
