import { EventEmitter, Injectable } from "@angular/core";
import { ForecastData } from "@planard/@core/entities/interface/forecast";
import { ForecastApi } from "@planard/@core/backend/api/forecast.api";
import { Forecast } from "@planard/@core/entities/forecast";
import { Channel } from "@planard/@core/entities/channel";
import { BusinessUnit } from "@planard/@core/entities/businessUnit";
import { ProductFamily } from "@planard/@core/entities/productFamily";
import { Products } from "@planard/@core/entities/product";

@Injectable()
export class ForecastService extends ForecastData {
  onForecastReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ForecastApi) {
    super();
  }

  listPrices(scenarioId: number, approveRequestId?: number) {
    return this.api.listPrices<any>(scenarioId, approveRequestId);
  }

  growthDrivers(
    count: number,
    scenarioId: number,
    granularity: number,
    startActuals: number,
    startPeriods: number,
    endActuals: number,
    endPeriods: number,
    indicator: number,
    currency: number
  ) {
    return this.api.growthDrivers<any>(
      count,
      scenarioId,
      granularity,
      startActuals,
      startPeriods,
      endActuals,
      endPeriods,
      indicator,
      currency
    );
  }

  salesTrend(
    scenarioId: number,
    granularity: number,
    parentId: any,
    subId: number,
    indicator: number,
    timescale: number,
    actuals: number,
    period: number,
    currency: number
  ) {
    return this.api.salesTrend<any>(
      scenarioId,
      granularity,
      parentId,
      subId,
      indicator,
      timescale,
      actuals,
      period,
      currency
    );
  }

  update(forecast: Forecast) {
    return this.api.update<any>(forecast);
  }

  listForecastBusinessUnits(scenarioId: any) {
    return this.api.listForecastBusinessUnits<BusinessUnit[]>(scenarioId);
  }

  listForecastChannels(scenarioId: any) {
    return this.api.listForecastChannels<Channel[]>(scenarioId);
  }

  listForecastProductFamilies(scenarioId: any) {
    return this.api.listForecastProductFamilies<ProductFamily[]>(scenarioId);
  }

  listForecastProducts(scenarioId: any) {
    return this.api.listForecastProducts<Products[]>(scenarioId);
  }

  listProductFamiliesByBusinessUnit(scenarioId: any, businessUnitId: number) {
    return this.api.listProductFamiliesByBusinessUnit<BusinessUnit[]>(
      scenarioId,
      businessUnitId
    );
  }

  listProductsByProductFamily(scenarioId: any, productFamilyId: number) {
    return this.api.listProductsByProductFamily<Channel[]>(
      scenarioId,
      productFamilyId
    );
  }

  listFormsByProduct(scenarioId: any, productId: number) {
    return this.api.listFormsByProduct<ProductFamily[]>(scenarioId, productId);
  }

  listBusinessUnitsByChannel(scenarioId: any, channelId: number) {
    return this.api.listBusinessUnitsByChannel<Products[]>(
      scenarioId,
      channelId
    );
  }
}
