<div id="main-div">
  <div class="div" style="width: 70%">
    <h1 mat-dialog-title>{{ "actualsExport.title" | translate }}</h1>
  </div>
  <div class="div" style="width: 20%">
    <mat-progress-spinner
      *ngIf="loadingIndicator"
      mode="indeterminate"
      style="margin: 0 auto"
      [diameter]="30"
    >
    </mat-progress-spinner>
  </div>

  <form [formGroup]="form">
    <div mat-dialog-content>
      <mat-form-field>
        <mat-select
          tabindex="2"
          formControlName="type"
          name="type"
          placeholder="{{ 'actualsExport.type' | translate }}"
        >
          <mat-option value="Price(Price)">{{
            "actuals.Price(Price)" | translate
          }}</mat-option>
          <mat-option value="Price(Discount)">{{
            "actuals.Price(Discount)" | translate
          }}</mat-option>
          <mat-option value="Forecast(Volume)">{{
            "actuals.Forecast(Volume)" | translate
          }}</mat-option>
          <mat-option value="Forecast(Amount)">{{
            "actuals.Forecast(Amount)" | translate
          }}</mat-option>
          <mat-option value="OtherForecast">{{
            "actuals.OtherForecast" | translate
          }}</mat-option>
          <mat-option value="Cost">{{ "actuals.Cost" | translate }}</mat-option>
          <mat-option value="CentralCost">{{
            "actuals.CentralCost" | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          tabindex="3"
          placeholder="{{ 'actualsExport.period' | translate }}"
          name="period"
          formControlName="period"
        >
          <mat-option *ngFor="let o of yearList" [value]="o" value="option">
            {{ o }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button tabindex="4" (click)="save()">
        {{ "actualsExport.dowland" | translate }}
      </button>
      <button (click)="cancel()" mat-button tabindex="6" type="button">
        {{ "actualsExport.cancel" | translate }}
      </button>
    </div>
  </form>
</div>
