import { Observable } from "rxjs";
import { Supersettings } from "../supersettings";

export abstract class SupersettingsData {
  abstract listAll(): Observable<any[]>;

  abstract add(setting: Supersettings);

  abstract update(setting: Supersettings);

  abstract delete(id: number): Observable<any>;
}
