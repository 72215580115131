import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { UserTask } from "@planard/@core/entities/user-task";
import { UserTaskService } from "@planard/@core/backend/service/user-task.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./usertask-update.component.html",
})
export class UserTaskUpdateComponent {
  userTask: UserTask = new UserTask();

  form: FormGroup = new FormGroup({
    lockDate: new FormControl("", [Validators.required]),
  });
  options: FormGroup;
  row: any;

  today = new Date();

  constructor(
    public dialogRef: MatDialogRef<UserTaskUpdateComponent>,
    public userTaskService: UserTaskService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.row = data;
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {}

  save() {
    if (this.form.valid) {
      let date = new Date();
      var current: any = new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          { timeZone: "Europe/Berlin" }
        )
      );
      this.userTask = this.row;
      this.userTask.unlockDate = current;
      this.userTask.lockDate = this.form.get("lockDate").value;
      this.userTaskService.update(this.row).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.dialogRef.close(this.row);
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
