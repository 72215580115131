import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { SupersettingsService } from "@planard/@core/backend/service/supersettings.service";
import { Supersettings } from "@planard/@core/entities/supersettings";
import { TranslateService } from "@ngx-translate/core";
import { countries, Country } from "@planard/common/country";

@Component({
  templateUrl: "./settings-add.component.html",
})
export class SettingsAddComponent implements OnInit {
  fileName;
  fileUrl;

  file = null;
  type = null;

  country_list: Country[] = countries;

  form: FormGroup = new FormGroup({
    description: new FormControl("", [Validators.required]),
    type: new FormControl("", [Validators.required]),
    country: new FormControl("", [Validators.required]),
  });

  options: FormGroup;

  constructor(
    public fb: FormBuilder,
    public supersettingsService: SupersettingsService,
    public toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<SettingsAddComponent>
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {}

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async upload(event: any) {
    let file = event.target.files[0];
    this.fileName = event.target.files[0].name;

    let doc = await this.toBase64(file);
    let fil = `${doc}`;
    this.file = fil.split(",")[1]; //api ye gidecek
  }

  export(b64Data) {
    const byteCharacters = atob(b64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(blob)
    );
  }

  save() {
    if (this.form.valid && this.file != null) {
      let supersettings = new Supersettings();

      supersettings.blob = this.file;
      supersettings.key = this.fileName; // ilerde file name olabilir
      supersettings.value = this.form.value.description;
      supersettings.type = this.form.value.type;
      supersettings.country = this.form.value.country;

      this.dialogRef.close(supersettings);
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
