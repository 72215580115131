import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";

@Injectable()
export class SalesApi {
  private readonly apiController: string = "sales";

  constructor(private api: HttpService) {}

  listPrices<T>(scenarioId: number): Observable<any> {
    return this.api.get(`${this.apiController}/listPrices/${scenarioId}`);
  }

  add<T>(sales: any): Observable<any> {
    return this.api.post(`${this.apiController}/add`, sales);
  }

  add4Excel<T>(excelFile: any): Observable<any> {
    return this.api.post(`${this.apiController}/add4Excel`, excelFile);
  }

  update<T>(sales: any): Observable<any> {
    return this.api.post(`${this.apiController}/update`, sales);
  }

  getTemplate<Blob>(): Observable<Blob> {
    return this.api.get(`${this.apiController}/getTemplate`);
  }
}
