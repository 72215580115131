import { NgModule } from "@angular/core";
import { EditableComponent } from "./editable/editable.component";
import { ViewModeDirective } from "./editable/directives/view-mode.directive";
import { EditModeDirective } from "./editable/directives/edit-mode.directive";
import { EditableOnEnterDirective } from "./editable/directives/edit-on-enter.directive";
import { CommonModule } from "@angular/common";
import { Editable2Component } from "./editable2/editable2.component";
import { ViewMode2Directive } from "./editable2/directives/view-mode2.directive";
import { EditMode2Directive } from "./editable2/directives/edit-mode2.directive";
import { EditableOnEnter2Directive } from "./editable2/directives/edit-on-enter2.directive";
@NgModule({
  imports: [CommonModule],
  entryComponents: [],
  declarations: [
    EditableComponent,
    ViewModeDirective,
    EditModeDirective,
    EditableOnEnterDirective,
    Editable2Component,
    ViewMode2Directive,
    EditMode2Directive,
    EditableOnEnter2Directive,
  ],
  exports: [
    EditableComponent,
    ViewModeDirective,
    EditModeDirective,
    EditableOnEnterDirective,
    Editable2Component,
    ViewMode2Directive,
    EditMode2Directive,
    EditableOnEnter2Directive,
  ],
  bootstrap: [],
  providers: [],
})
export class MapleModule {}
