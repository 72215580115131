import { EventEmitter, Injectable } from "@angular/core";
import { SettingData } from "@planard/@core/entities/interface/setting";
import { Setting } from "@planard/@core/entities/setting";
import { SettingApi } from "../api/setting.api";

@Injectable()
export class SettingService extends SettingData {
  onFaqReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: SettingApi) {
    super();
  }

  listAll() {
    return this.api.listAll<any[]>();
  }

  update(setting: Setting) {
    return this.api.update<any>(setting);
  }
}
