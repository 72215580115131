import { RiskOpportunities } from "../../entities/riskOpportunities";
import { RiskOpportunitiesApi } from "../api/risk-opportunities.api";
import { Injectable, EventEmitter } from "@angular/core";

@Injectable()
export class RiskOpportunitiesService {
  onRiskOpportunitiesReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: RiskOpportunitiesApi) {}

  listAll(page?: number, pageSize?: number) {
    return this.api.listAll<RiskOpportunities[]>(page, pageSize);
  }

  listAllByGuidanceId(guidanceId: number) {
    return this.api.listAllByGuidanceId<RiskOpportunities[]>(guidanceId);
  }

  report(
    guidanceId: number,
    impactArea: any,
    typeOfImpact: any,
    likeliHood: any
  ) {
    return this.api.report<RiskOpportunities[]>(
      guidanceId,
      impactArea,
      typeOfImpact,
      likeliHood
    );
  }

  add(riskOpportunities: RiskOpportunities) {
    return this.api.add<any>(riskOpportunities);
  }

  update(riskOpportunities: RiskOpportunities) {
    return this.api.update<any>(riskOpportunities);
  }

  delete(riskOpportunitiesId: number) {
    return this.api.delete<any>(riskOpportunitiesId);
  }
}
