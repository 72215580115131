import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProcessResult } from "@planard/@core/entities/process-result";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NarrativeService } from "@planard/@core/backend/service/narrative.service";
import { CostNarrative } from "@planard/@core/entities/costNarrative";
import { NarrativeRequest } from "@planard/@core/entities/narrativeRequest";
import { Scenario } from "@planard/@core/entities/scenario";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { Category } from "@planard/@core/entities/category";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  templateUrl: "./add-narratives.component.html",
})
export class AddNarrativesComponent implements OnInit {
  cost: CostNarrative;
  processResult = new ProcessResult();
  scenarioList: Scenario[];
  costRequestList: NarrativeRequest[];
  requestId: number;
  categoryId: any;
  costCategoryList: Category[];

  categoryList = [
    "Cost Increase Due to Inflation",
    "Increase/Decrease in Headcount",
    "Offboarding",
    "Facility move/renovation",
    "Marketing Campaings",
    "New Market Research",
    "Business Scope Extend",
    "Major Events",
    "Change in Fleet",
    "New Assets",
    "New Products",
    "New R&D Activities",
    "New Audit",
    "New Consultancy",
    "Currency Impact",
    "Price Adjustments",
    "New Supplier",
    "Fines & Suits",
    "Other Impacts on Cost",
    "Organizational Re-structuring",
  ];

  form: FormGroup = new FormGroup({
    narrativeRequestId: new FormControl("", [Validators.required]),
    amount: new FormControl("", [Validators.required]),
    categoryId: new FormControl("", [Validators.required]),
    narrativeCategory: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  constructor(
    public fb: FormBuilder,
    public narrativeService: NarrativeService,
    public scenarioService: ScenarioService,
    public toastrService: ToastrService,
    public costService: CostService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<AddNarrativesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.requestId = data.requestId;
    this.categoryId = data.categoryId;
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.scenarioService.listAll().subscribe((data) => {
      this.scenarioList = data;
    });
    this.narrativeService.listCostRequests().subscribe((result) => {
      this.costRequestList = result;
    });
    this.costService.listCategories().subscribe((result) => {
      this.costCategoryList = result;
    });
    this.form.patchValue({ narrativeRequestId: this.requestId });
    if (this.categoryId != null) {
      this.form.patchValue({ categoryId: this.categoryId });
    }
  }

  save() {
    if (this.form.valid) {
      this.cost = this.form.value;
      this.narrativeService.addCost(this.cost).subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.dialogRef.close(this.cost);
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
