import { EventEmitter, Injectable } from "@angular/core";
import { SalesData } from "@planard/@core/entities/interface/sales";
import { SalesApi } from "@planard/@core/backend/api/sales.api";
import { Sales } from "@planard/@core/entities/planning/sales";

@Injectable()
export class SalesService extends SalesData {
  onSalesReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: SalesApi) {
    super();
  }

  listPrices(scenarioId: number) {
    return this.api.listPrices<Sales[]>(scenarioId);
  }

  add(sales: Sales) {
    return this.api.add<any>(sales);
  }

  add4Excel(excelFile: any) {
    return this.api.add4Excel<any>(excelFile);
  }

  update(sales: Sales) {
    return this.api.update<any>(sales);
  }

  getTemplate() {
    return this.api.getTemplate<Blob>();
  }
}
