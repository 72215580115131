<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 20%">
            <mat-card-title
              >{{ "reports.actualvsplan" | translate }} <br />
              <span style="font-size: 15px"
                >{{ companyNumberFormat }} {{ companyCurrency }}</span
              ></mat-card-title
            >
          </div>

          <!-- INDICATOR -->
          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                placeholder="{{ 'reports.indicator' | translate }}"
                [(ngModel)]="indicator"
                [formControl]="indicatorControl"
                required
              >
                <mat-option
                  *ngFor="let o of indicatorList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="indicatorControl.hasError('required')">{{
                "reports.indicatorChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- GRANULARITY -->
          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                placeholder="{{ 'reports.granularity' | translate }}"
                [(ngModel)]="granularity"
                [formControl]="granularityControl"
                required
              >
                <mat-option
                  *ngFor="let o of granularityList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="granularityControl.hasError('required')">{{
                "reports.granularityChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- YEAR -->
          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                placeholder="{{ 'reports.year' | translate }}"
                [(ngModel)]="year"
                [formControl]="yearControl"
                required
              >
                <mat-option *ngFor="let o of years" [value]="o" value="option">
                  {{ o }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="yearControl.hasError('required')">{{
                "reports.yearChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- MONTH -->
          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                placeholder="{{ 'reports.month' | translate }}"
                [(ngModel)]="month"
                [formControl]="monthControl"
                required
              >
                <mat-option *ngFor="let o of months" [value]="o" value="option">
                  {{ o.label }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="monthControl.hasError('required')">{{
                "reports.monthChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- PlAN -->
          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                (selectionChange)="onPlanChange()"
                [(ngModel)]="guidance"
                placeholder="{{ 'reports.planningCycle' | translate }}"
                [formControl]="guidanceControl"
                required
              >
                <mat-option
                  *ngFor="let o of guidanceList"
                  [value]="o"
                  value="option"
                >
                  {{ o.planningCycleName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="guidanceControl.hasError('required')">{{
                "reports.planningCycleChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- SCENARIO -->
          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="scenarioId"
                placeholder="{{ 'reports.scenario' | translate }}"
                [formControl]="scenarioControl"
                required
              >
                <mat-option
                  *ngFor="let o of scenarioList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="scenarioControl.hasError('required')">{{
                "reports.scenarioChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- CURRENCY -->
          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="currency"
                placeholder="{{ 'reports.currency' | translate }}"
                [formControl]="currencyControl"
                required
              >
                <mat-option [value]="0" value="option">{{
                  "reports.defaultCurrency" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of guidanceFxRateList"
                  [value]="o.fxRateId"
                  value="option"
                >
                  {{ o.fxRate?.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="currencyControl.hasError('required')">{{
                "reports.currencyChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <div style="width: 85%">
              <button color="accent" mat-button (click)="isValid()">
                {{ "reports.run" | translate }}
              </button>
            </div>
          </div>
        </div>

        <br />

        <div class="responsive-table" style="width: auto" #screen>
          <ag-grid-angular
            #agGrid
            style="width: 100%; height: 100%"
            class="ag-theme-material"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [components]="components"
            [enableBrowserTooltips]="true"
            [enterMovesDownAfterEdit]="true"
            rowSelection="multiple"
            (gridReady)="onGridReady($event)"
            [suppressRowClickSelection]="true"
            [overlayLoadingTemplate]="loadingTemplate"
            [overlayNoRowsTemplate]="noRowsTemplate"
            (cellClicked)="onCellClicked($event)"
          >
          </ag-grid-angular>
        </div>

        <br />

        <mat-card-actions>
          <button
            color="accent"
            mat-raised-button
            type="button"
            (click)="setExportData()"
          >
            <mat-icon>cloud_upload</mat-icon>&nbsp;{{
              "reports.export" | translate
            }}&nbsp;&nbsp;</button
          >&nbsp;
          <button (click)="take()" color="accent" mat-raised-button>
            <mat-icon>camera_alt</mat-icon>&nbsp;{{
              "reports.take" | translate
            }}</button
          >&nbsp;
          <button
            *ngIf="img"
            (click)="reset()"
            color="accent"
            mat-raised-button
          >
            <mat-icon>delete_sweep</mat-icon>&nbsp;{{
              "reports.reset" | translate
            }}</button
          >&nbsp;
          <button *ngIf="img" (click)="save()" color="accent" mat-raised-button>
            <mat-icon>save</mat-icon>&nbsp;{{ "reports.save" | translate }}
          </button>
        </mat-card-actions>
        <br />
        <br />
        <img
          *ngIf="img"
          src="{{ img }}"
          style="width: 50%; height: 50%; border: solid; border-color: gold"
        />
      </mat-card-content>
    </mat-card>
  </div>
</div>
