import { EventEmitter, Injectable } from "@angular/core";
import { FaqApi } from "@planard/@core/backend/api/faq.api";
import { FaqData } from "@planard/@core/entities/interface/faq";
import { Faq } from "@planard/@core/entities/faq";

@Injectable()
export class FaqService extends FaqData {
  onFaqReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: FaqApi) {
    super();
  }

  listAll() {
    return this.api.listAll<any[]>();
  }

  add(faq: Faq) {
    return this.api.add<any>(faq);
  }

  update(faq: Faq) {
    return this.api.update<any>(faq);
  }

  delete(faqId: number) {
    return this.api.delete<any>(faqId);
  }
}
