import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Company } from "../../entities/company";
import { Observable } from "rxjs";

@Injectable()
export class CompanyApi {
  private readonly apiController: string = "company";

  constructor(private api: HttpService) {}

  listAll<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAll`);
  }

  get<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/get`);
  }

  add<T>(company: Company): Observable<any> {
    return this.api.post(`${this.apiController}/addCompany`, company);
  }

  update<T>(company: Company): Observable<any> {
    return this.api.post(`${this.apiController}/updateCompany`, company);
  }

  updatePackage<T>(body: any): Observable<any> {
    return this.api.post(`${this.apiController}/updatePackage`, body);
  }

  listAllBU<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAllBU`);
  }

  delete<T>(companyId: number): Observable<any> {
    var json = { id: companyId };
    return this.api.post(`${this.apiController}/delete`, json);
  }
}
