<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 85%">
            <mat-card-title>{{ "scenario.title" | translate }}</mat-card-title>
          </div>
          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>
        <div class="responsive-table">
          <!-- edit yapılabilen alan -->
          <mat-table
            [dataSource]="dataSource"
            *ngIf="guidanceStart != true || this.companyPackage == 'basic'"
            matSort
          >
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header="name">
                {{ "scenario.name" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="element.name == 'Base Scenario'">{{
                  element.name
                }}</span>

                <editable
                  (update)="updateField(element.id, 'name')"
                  *ngIf="element.name != 'Base Scenario'"
                >
                  <ng-template viewMode>
                    {{ element.name }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [formControl]="getControl(element.id, 'name')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="impactedProducts">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="impactedProducts"
                >{{ "scenario.impactedProducts" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                <!-- <span *ngIf="!getProduct(element.impactArea) || element.isBase==true">{{element.impactedProductsStr != "" ? element.impactedProductsStr : "-"}}</span> -->
                <span
                  *ngIf="
                    !getProduct(element.impactArea) || element.isBase == true
                  "
                  >-</span
                >

                <editable2
                  (update)="updateField(element.id, 'impactedProducts')"
                  *ngIf="
                    element.isBase == false && getProduct(element.impactArea)
                  "
                >
                  <ng-template viewMode2>
                    {{
                      element.impactedProductsStr != ""
                        ? element.impactedProductsStr
                        : "-"
                    }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field>
                      <mat-select
                        #productSelect
                        multiple
                        [formControl]="
                          getControl(element.id, 'impactedProducts')
                        "
                        [(ngModel)]="element.selectedImpactedProducts"
                      >
                        <mat-option disabled="disabled" class="filter-option">
                          <button
                            mat-raised-button
                            class="mat-primary fill text-sm"
                            (click)="selectAll(element.id, productSelect)"
                          >
                            Select All
                          </button>
                          &nbsp;
                          <button
                            mat-raised-button
                            class="mat-accent fill text-sm"
                            (click)="deselectAll(element.id)"
                          >
                            Deselect All
                          </button>
                        </mat-option>
                        <mat-option
                          *ngFor="let o of productList"
                          [value]="o.id"
                        >
                          {{ o.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="impactArea">
              <mat-header-cell *matHeaderCellDef mat-sort-header="impactArea">{{
                "scenario.impactArea" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="element.isBase == true">-</span>

                <editable2
                  (update)="updateField(element.id, 'impactArea')"
                  *ngIf="element.isBase == false"
                >
                  <ng-template viewMode2>
                    {{ element.imapctAreaStr }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field>
                      <mat-select
                        multiple
                        [formControl]="getControl(element.id, 'impactArea')"
                        [(ngModel)]="element.impactArea"
                      >
                        <mat-option
                          *ngFor="let o of impactAreaList"
                          [value]="o"
                        >
                          <span *ngIf="'impactArea.' + o as variable">{{
                            variable | translate
                          }}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header="description">
                {{ "scenario.description" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="element.isBase == true">{{
                  element.description
                }}</span>

                <editable
                  (update)="updateField(element.id, 'description')"
                  *ngIf="element.isBase == false"
                >
                  <ng-template viewMode>
                    {{ element.description }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [formControl]="getControl(element.id, 'description')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
                {{ "scenario.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
                <button
                  *ngIf="row.name != 'Base Scenario'"
                  (click)="onDelete(row)"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>

                <button
                  *ngIf="row.name == 'Base Scenario'"
                  style="cursor: not-allowed; font-size: 11.5px; color: gray"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>
          <!-- /edit yapılan alan -->

          <!-- edit yapılmayan alan -->
          <mat-table
            [dataSource]="dataSource"
            matSort
            *ngIf="guidanceStart == true && this.companyPackage == 'premium'"
          >
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header="name">
                {{ "scenario.name" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.name }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="impactedProducts">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="impactedProducts"
                >{{ "scenario.impactedProducts" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                {{
                  element.impactedProductsStr != ""
                    ? element.impactedProductsStr
                    : "-"
                }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="impactArea">
              <mat-header-cell *matHeaderCellDef mat-sort-header="impactArea">{{
                "scenario.impactArea" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <!--<span *ngIf=" 'impactArea.'+element.impactArea as variable">{{ element.name == 'Base Scenario' ? '-' : variable | translate }}</span>-->
                {{ element.imapctAreaStr }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header="description">
                {{ "scenario.description" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.description }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
                {{ "scenario.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
                <button
                  *ngIf="this.companyPackage == 'premium'"
                  (click)="outToastr('guidance.msg1')"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>

                <button
                  *ngIf="
                    this.companyPackage == 'basic' &&
                    row.name != 'Base Scenario'
                  "
                  (click)="onDelete(row)"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>

                <button
                  *ngIf="
                    this.companyPackage == 'basic' &&
                    row.name == 'Base Scenario'
                  "
                  style="cursor: not-allowed; font-size: 11.5px; color: gray"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>
          <!-- /edit yapılmayan alan -->
          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>

        <mat-card-actions>
          <button
            *ngIf="guidanceStart != true || this.companyPackage == 'basic'"
            (click)="addDialog()"
            color="accent"
            mat-raised-button
          >
            {{ "scenario.add" | translate }}
          </button>
          <button
            *ngIf="guidanceStart == true && this.companyPackage == 'premium'"
            (click)="outToastr('guidance.msg1')"
            color="accent"
            mat-raised-button
          >
            {{ "scenario.add" | translate }}
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
