import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from "@angular/common/http";
import { LoaderService } from "./loader.service";
import { finalize } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoaderInterceptor implements HttpInterceptor {
  reportsList = [
    "listSalesOverview",
    "listProductsOverview",
    "listProductsOverview-V2",
    "listChannels",
    "listGrowthDrivers",
    "listSalesTrend",
    "listSalesBridge",
    "listCostBridge",
    "listPriceVolume",
    "listLaunchTimelineReport",
    "listPriceTrend",
    "listCostOverview",
    "listCostTrend",
    "listScenarioComp",
    "listIncomeStatement",
    "listActualvsPlan",
  ];

  screenList = [
    "hrPersonnelCosts",
    "price",
    "forecast",
    "costplan",
    "centralcostplan",
  ];

  constructor(private loader: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url == "/assets/i18n/en.json") return;
    if (req.url == "/assets/i18n/tr.json") return;

    if (
      req.method != "GET" ||
      this.reportsList.includes(req.url.split("/api/")[1].split("/")[1]) ||
      this.screenList.includes(req.url.split("/api/")[1].split("/")[0])
    ) {
      this.loader.show();
    }
    return next
      .handle(req)
      .pipe(
        finalize(() =>
          req.method != "GET" ||
          this.reportsList.includes(req.url.split("/api/")[1].split("/")[1]) ||
          this.screenList.includes(req.url.split("/api/")[1].split("/")[0])
            ? this.loader.hide()
            : (this.loader = this.loader)
        )
      );
  }
}
