<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div>
          <div class="div" style="width: 20%">
            <mat-card-title
              >{{ "reports.growthDrivers" | translate }} <br />
              <span style="font-size: 15px"
                >{{ companyNumberFormat }} {{ companyCurrency }}</span
              ></mat-card-title
            >
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                placeholder="{{ 'reports.topbottom' | translate }}"
                [(ngModel)]="count"
                [formControl]="countControl"
                required
              >
                <mat-option
                  *ngFor="let o of countList"
                  [value]="o"
                  value="option"
                >
                  {{ o }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="countControl.hasError('required')">{{
                "reports.topbottomChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                (selectionChange)="setPeriod()"
                [(ngModel)]="indicator"
                placeholder="{{ 'reports.indicator' | translate }}"
                [formControl]="indicatorControl"
                required
              >
                <mat-option
                  *ngFor="let o of indicatorList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="indicatorControl.hasError('required')">{{
                "reports.indicatorChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="scenarioId"
                placeholder="{{ 'reports.scenario' | translate }}"
                [formControl]="scenarioControl"
                required
              >
                <mat-option
                  *ngFor="let o of scenarioList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="scenarioControl.hasError('required')">{{
                "reports.scenarioChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="granularity"
                placeholder="{{ 'reports.granularity' | translate }}"
                [formControl]="granularityControl"
                required
              >
                <mat-option
                  *ngFor="let o of granularityList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="granularityControl.hasError('required')">{{
                "reports.granularityChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="startYear"
                placeholder="{{ 'reports.period1' | translate }}"
                [formControl]="period1Control"
                required
              >
                <mat-optgroup
                  *ngFor="let group of periodGroup"
                  [label]="group.name"
                >
                  <mat-option
                    *ngFor="let period of group.period"
                    [value]="period"
                  >
                    {{ period.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-error *ngIf="period1Control.hasError('required')">{{
                "reports.period1Choose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="endYear"
                placeholder="{{ 'reports.period2' | translate }}"
                [formControl]="period2Control"
                required
              >
                <mat-optgroup
                  *ngFor="let group of periodGroup"
                  [label]="group.name"
                >
                  <mat-option
                    *ngFor="let period of group.period"
                    [value]="period"
                  >
                    {{ period.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-error *ngIf="period2Control.hasError('required')">{{
                "reports.period2Choose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                *ngIf="indicator != 0"
                [(ngModel)]="currency"
                placeholder="{{ 'reports.currency' | translate }}"
                [formControl]="currencyControl"
                required
              >
                <mat-option [value]="0" value="option">{{
                  "reports.defaultCurrency" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of guidanceFxRateList"
                  [value]="o.fxRateId"
                  value="option"
                >
                  {{ getFxRate(o.fxRateId) }}
                </mat-option>
              </mat-select>
              <mat-select
                *ngIf="indicator == 0"
                [(ngModel)]="currency"
                placeholder="{{ 'reports.currency' | translate }}"
                [formControl]="currencyControl"
                required
                disabled
              >
                <mat-option [value]="0" value="option">{{
                  "reports.defaultCurrency" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of guidanceFxRateList"
                  [value]="o.fxRateId"
                  value="option"
                >
                  {{ getFxRate(o.fxRateId) }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="currencyControl.hasError('required')">{{
                "reports.currencyChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <div style="width: 85%">
              <button color="accent" (click)="isValid()" mat-button>
                {{ "reports.run" | translate }}
              </button>
            </div>
          </div>
        </div>

        <br />

        <div #screen>
          <div class="div" style="width: 50%">
            <chart
              *ngIf="isRun == true"
              style="height: 400px"
              class="chart"
              type="horizontalBar"
              [data]="chartData"
              [options]="options"
            ></chart>
          </div>
          <div class="div" style="width: 50%">
            <chart
              *ngIf="isRun == true"
              style="height: 400px"
              class="chart"
              type="horizontalBar"
              [data]="chartDataBottom"
              [options]="optionsBottom"
            ></chart>
          </div>
        </div>
        <span *ngIf="isRun != true" style="width: 60%; float: right">{{
          "reports.seeChart" | translate
        }}</span>

        <br />
        <br />
        <br />

        <button (click)="take()" color="accent" mat-raised-button>
          <mat-icon>camera_alt</mat-icon>&nbsp;{{
            "reports.take" | translate
          }}</button
        >&nbsp;
        <button *ngIf="img" (click)="reset()" color="accent" mat-raised-button>
          <mat-icon>delete_sweep</mat-icon>&nbsp;{{
            "reset.topbottom" | translate
          }}</button
        >&nbsp;
        <button *ngIf="img" (click)="save()" color="accent" mat-raised-button>
          <mat-icon>save</mat-icon>&nbsp;{{ "save.topbottom" | translate }}
        </button>

        <br />
        <br />
        <img *ngIf="img" src="{{ img }}" style="width: 50%; height: 50%" />
      </mat-card-content>
    </mat-card>

    <!--<mat-card *ngIf="guidanceIsStart==null">
          <mat-card-content style="min-height: 200px;">
            <div>
              <div class="div" style="width: 50%;">
                <mat-card-title>Sales Drivers</mat-card-title>
              </div>
            </div>
            <br>
            <br>
            <span *ngIf="isRun!=true" style="width: 60%; float: right;">Guidance not started yet.</span>
          </mat-card-content>
      </mat-card>-->
  </div>
</div>
