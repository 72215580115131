import { NgModule } from "@angular/core";
import { DemoMaterialModule } from "../common/demo-material-module";
import { NotificationComponent } from "@planard/notification/notification.component";
import { NotificationRoutes } from "@planard/notification/notification.routing";
import { RouterModule } from "@angular/router";

@NgModule({
  imports: [DemoMaterialModule, RouterModule.forChild(NotificationRoutes)],
  declarations: [NotificationComponent],
})
export class NotificationModule {}
