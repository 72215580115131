<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 70%">
            <mat-card-title>{{ "riskOpps.title" | translate }}</mat-card-title>
          </div>

          <div class="div" style="width: 15%">
            <mat-form-field style="width: 85%">
              <mat-select
                (selectionChange)="getGuidanceData()"
                [(ngModel)]="guidanceId"
                placeholder="{{ 'riskOpps.planningCycle' | translate }}"
              >
                <mat-optgroup
                  *ngFor="let group of guidanceGroup"
                  [label]="group.name"
                >
                  <mat-option
                    *ngFor="let guidance of group.guidance"
                    [value]="guidance.value"
                  >
                    {{ guidance.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>
        <div class="responsive-table">
          <mat-table
            [dataSource]="dataSource"
            matSort
            *ngIf="isScreenValid == true && guidanceId == currentGuidanceId"
          >
            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header="description">
                {{ "riskOpps.description" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'description')">
                  <ng-template viewMode>
                    {{ element.description }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [formControl]="getControl(element.id, 'description')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="impactArea">
              <mat-header-cell *matHeaderCellDef mat-sort-header="impactArea">{{
                "riskOpps.impactArea" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable2 (update)="updateField(element.id, 'impactArea')">
                  <ng-template viewMode2>
                    {{ element.imapctAreaStr }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field>
                      <mat-select
                        [formControl]="getControl(element.id, 'impactArea')"
                        [(ngModel)]="element.impactArea"
                        multiple
                      >
                        <mat-option
                          *ngFor="let o of impactAreaList"
                          [value]="o"
                        >
                          <span *ngIf="'impactArea.' + o as variable">{{
                            variable | translate
                          }}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="typeOfImpact">
              <mat-header-cell *matHeaderCellDef mat-sort-header="typeOfImpact">
                {{ "riskOpps.typeImpact" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'typeOfImpact')">
                  <ng-template viewMode>
                    <span
                      *ngIf="'riskOpps.' + element.typeOfImpact as variable"
                      >{{ variable | translate }}</span
                    >
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <mat-select
                        [formControl]="getControl(element.id, 'typeOfImpact')"
                        [(ngModel)]="element.typeOfImpact"
                      >
                        <mat-option value="Risk">{{
                          "riskOpps.Risk" | translate
                        }}</mat-option>
                        <mat-option value="Opportunity">{{
                          "riskOpps.Opportunity" | translate
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="impactAmount">
              <mat-header-cell *matHeaderCellDef mat-sort-header="impactAmount">
                {{ "riskOpps.impactAmount" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'impactAmount')">
                  <ng-template viewMode>
                    {{
                      formatNumber(
                        element.impactAmount,
                        decimalFormat,
                        numberFormat
                      )
                    }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [formControl]="getControl(element.id, 'impactAmount')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="likeliHood">
              <mat-header-cell *matHeaderCellDef mat-sort-header="likeliHood">{{
                "riskOpps.likelihood" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'likeliHood')">
                  <ng-template viewMode>
                    <span
                      *ngIf="'riskOpps.' + element.likeliHood as variable"
                      >{{ variable | translate }}</span
                    >
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <mat-select
                        [formControl]="getControl(element.id, 'likeliHood')"
                        [(ngModel)]="element.likeliHood"
                      >
                        <mat-option value="Low">{{
                          "riskOpps.Low" | translate
                        }}</mat-option>
                        <mat-option value="Medium">{{
                          "riskOpps.Medium" | translate
                        }}</mat-option>
                        <mat-option value="High">{{
                          "riskOpps.High" | translate
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="dayOfCertainty">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="dayOfCertainty"
                >{{ "riskOpps.date" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                <editable2 (update)="updateField(element.id, 'dayOfCertainty')">
                  <ng-template viewMode2>
                    {{ element.dayOfCertainty | date : "dd/MM/yyyy" }}
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field style="width: 50%">
                      <input
                        matInput
                        [matDatepicker]="spicker"
                        [formControl]="getControl(element.id, 'dayOfCertainty')"
                        focusable
                      />
                      <mat-datepicker-toggle
                        [for]="spicker"
                        matSuffix
                      ></mat-datepicker-toggle>
                      <mat-datepicker #spicker></mat-datepicker>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
                {{ "riskOpps.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
                <button
                  (click)="onDelete(row)"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-table
            [dataSource]="dataSource"
            matSort
            *ngIf="isScreenValid == false || guidanceId != currentGuidanceId"
          >
            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header="description">
                {{ "riskOpps.description" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.description }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="impactArea">
              <mat-header-cell *matHeaderCellDef mat-sort-header="impactArea">{{
                "riskOpps.impactArea" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.imapctAreaStr }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="typeOfImpact">
              <mat-header-cell *matHeaderCellDef mat-sort-header="typeOfImpact">
                {{ "riskOpps.typeImpact" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="'riskOpps.' + element.typeOfImpact as variable">{{
                  variable | translate
                }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="impactAmount">
              <mat-header-cell *matHeaderCellDef mat-sort-header="impactAmount">
                {{ "riskOpps.impactAmount" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                {{
                  formatNumber(
                    element.impactAmount,
                    decimalFormat,
                    numberFormat
                  )
                }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="likeliHood">
              <mat-header-cell *matHeaderCellDef mat-sort-header="likeliHood">{{
                "riskOpps.likelihood" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="'riskOpps.' + element.likeliHood as variable">{{
                  variable | translate
                }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="dayOfCertainty">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="dayOfCertainty"
                >{{ "riskOpps.date" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                {{ element.dayOfCertainty | date : "dd/MM/yyyy" }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
                {{ "riskOpps.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
                <button
                  (click)="outToastr('entryScreens.riskOppsTask')"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>

        <mat-card-actions>
          <button
            *ngIf="isScreenValid == true && guidanceId == currentGuidanceId"
            (click)="addDialog()"
            color="accent"
            mat-raised-button
          >
            {{ "riskOpps.add" | translate }}
          </button>
          <button
            *ngIf="isScreenValid == false || guidanceId != currentGuidanceId"
            (click)="outToastr('entryScreens.riskOppsTask')"
            color="accent"
            mat-raised-button
          >
            {{ "riskOpps.add" | translate }}
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
