import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { MatTableDataSource } from "@angular/material/table";
import { Company } from "@planard/@core/entities/company";
import { CompanySetupService } from "@planard/@core/backend/service/company-setup.service";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { CompanySetupAddComponent } from "@planard/admin/super/companies/add/company-setup-add.component";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { EnumSector } from "@planard/@core/enums/EnumSector";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./company-setup.component.html",
  styles: [
    `
      .toRightReverse {
        flex: 0 0 5%;
      }
      .toRight {
        flex: 0 0 10%;
      }
      .toRight15 {
        flex: 0 0 15%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class CompanySetupComponent implements OnInit {
  edit: boolean;
  sectorList: EnumSector[] = EnumSector.values;
  currencyList: EnumCurrency[] = EnumCurrency.values;
  editing = {};
  rows = [];

  loadingIndicator = true;
  company: Company;
  isRoleValid: boolean = false;
  controls: any[];

  displayedColumns = [
    "name",
    "code",
    "sector",
    "contactName",
    "contactEmail",
    "package",
    "userCount",
    "language",
    "isActive",
    "actions",
  ];

  dataSource = new MatTableDataSource<Company>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public companySetupService: CompanySetupService,
    public authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    public toastr: ToastrService
  ) {
    this.isRoleValid = this.authService.isSuperAdmin();
    if (this.isRoleValid == false) this.router.navigate(["auth/404"]);

    this.companySetupService.onReload.subscribe(() => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.companySetupService.list().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "name", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              name: new FormControl(entity.name, [
                Validators.required,
                removeSpaces,
              ]),
              code: new FormControl(entity.code, [
                Validators.required,
                removeSpaces,
              ]),
              sector: new FormControl(
                EnumSector[`${entity.sector}`],
                Validators.required
              ),
              contactName: new FormControl(
                EnumSector[entity.contactName],
                Validators.required
              ),
              package: new FormControl(entity.package, Validators.required),
              userCount: new FormControl(entity.userCount, Validators.required),
              contactEmail: new FormControl(entity.contactEmail, [
                Validators.required,
                removeSpaces,
              ]),
              status: new FormControl(entity.status, Validators.required),
              language: new FormControl(entity.language, Validators.required),
            },
            { updateOn: "change" }
          ),
        };
      });
    });
  }

  select(index, select: MatSelect) {
    console.log(select);
  }

  getSector(index) {
    var sector = this.controls.find((group) => group.data.id == index)["data"]
      .sector;
    return sector;
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    const dialogRef = this.dialog.open(CompanySetupAddComponent, {
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataSource.data.push(result);
        this.refresh();
      }
    });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var company = this.getElement(index);
      company[field] = control.value;

      this.companySetupService.update(company).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastr.success(data);
            });
          this.ngOnInit();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastr.error(data);
            });
          this.ngOnInit();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastr.error(data);
      });
      this.ngOnInit();
    }
  }

  onDelete(row: any) {
    this.translate
      .get(row.isActive ? "delete.companyD" : "delete.companyA")
      .subscribe((data: any) => {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: "350px",
          data: data,
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.companySetupService.delete(row.id).subscribe((result) => {
              if (result.isSuccess) {
                this.translate
                  .get(`messages.${result.messageId}`)
                  .subscribe((data: any) => {
                    this.toastr.success(data);
                  });
                this.refresh();
              } else {
                this.translate
                  .get(`messages.${result.messageId}`)
                  .subscribe((data: any) => {
                    this.toastr.error(data);
                  });
              }
            });
          }
        });
      });
  }

  changePackage(company) {
    let val1 = this.translate.instant("companies.change1");
    let val2 = this.translate.instant("companies.change2");

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data:
        val1 + `${company.package == "premium" ? "Basic" : "Premium"}` + val2,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let body = {
          id: company.id,
          package: company.package == "premium" ? "basic" : "premium",
        };
        this.companySetupService.updatePackage(body).subscribe((result) => {
          if (result.isSuccess) {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastr.success(data);
              });
            this.ngOnInit();
          } else {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastr.error(data);
              });
          }
        });
      }
    });
  }

  refresh() {
    this.companySetupService.onReload.emit();
    this.changeDetectorRefs.detectChanges();
    this.dataSource.data = [...this.dataSource.data];
    this.rows = [...this.rows];
    this.ngOnInit();
  }
}
