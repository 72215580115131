<h1 mat-dialog-title>{{ "costNarrativeAdd.title" | translate }}</h1>

<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field *ngIf="!categoryId">
      <mat-select
        tabindex="1"
        formControlName="categoryId"
        name="categoryId"
        placeholder="{{ 'costNarrativeAdd.costCategory' | translate }}"
      >
        <mat-option
          *ngFor="let cost of costCategoryList"
          [value]="cost.id"
          value="option"
        >
          <span *ngIf="'category.' + o.name as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="false">
      <mat-select
        tabindex="1"
        formControlName="narrativeRequestId"
        name="narrativeRequestId"
        placeholder="{{ 'costNarrativeAdd.narrativeRequest' | translate }}"
      >
        <mat-option
          *ngFor="let cost of costRequestList"
          [value]="cost.id"
          value="option"
        >
          {{ cost.firstPlanningCycleName }}&nbsp;{{ cost.firstYear }}vs{{
            cost.secondPlanningCycleName
          }}&nbsp;{{ cost.secondYear }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="2"
        formControlName="amount"
        type="number"
        matInput
        name="amount"
        placeholder="{{ 'costNarrativeAdd.amount' | translate }}"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="3"
        formControlName="narrativeCategory"
        name="narrativeCategory"
        placeholder="{{ 'costNarrativeAdd.narrativeCategory' | translate }}"
      >
        <mat-option *ngFor="let o of categoryList" [value]="o" value="option">
          <span *ngIf="'costNarrativeCategories.' + o as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="4"
        formControlName="description"
        matInput
        maxlength="100"
        name="description"
        placeholder="{{ 'costNarrativeAdd.description' | translate }}"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="5" type="submit">
      {{ "costNarrativeAdd.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="6" type="button">
      {{ "costNarrativeAdd.cancel" | translate }}
    </button>
  </div>
</form>
