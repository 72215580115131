<h1 mat-dialog-title>{{ "guidance.fxrate.edit" | translate }}</h1>
<div mat-dialog-content>
  <div class="responsive-table">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="fxRateId">
        <mat-header-cell *matHeaderCellDef mat-sort-header="fxRateId">{{
          "guidance.fxrate.title" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'fxRateId')">
            <ng-template viewMode>
              {{
                element.fxRateId == 1
                  ? "USD"
                  : element.fxRateId == 2
                  ? "EUR"
                  : element.fxRateId == 5
                  ? "GBP"
                  : element.fxRateId == 3
                  ? "TRY"
                  : element.fxRateId == 4
                  ? "JPY"
                  : element.fxRateId == 6
                  ? "AUD"
                  : element.fxRateId == 7
                  ? "CAD"
                  : element.fxRateId == 8
                  ? "CHF"
                  : element.fxRateId == 9
                  ? "CNY"
                  : element.fxRateId == 10
                  ? "HKD"
                  : element.fxRateId == 11
                  ? "NZD"
                  : element.fxRateId == 12
                  ? "SEK"
                  : element.fxRateId == 13
                  ? "KRW"
                  : element.fxRateId == 14
                  ? "SGD"
                  : element.fxRateId == 15
                  ? "NOK"
                  : element.fxRateId == 16
                  ? "MXN"
                  : element.fxRateId == 17
                  ? "INR"
                  : element.fxRateId == 18
                  ? "RUB"
                  : element.fxRateId == 19
                  ? "ZAR"
                  : element.fxRateId == 20
                  ? "BRL"
                  : element.fxRateId == 21
                  ? "AED"
                  : element.fxRateId == 22
                  ? "ILS"
                  : element.fxRateId == 23
                  ? "PLN"
                  : element.fxRateId == 24
                  ? "SAR"
                  : "-"
              }}
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <mat-select
                  [formControl]="getControl(element.id, 'fxRateId')"
                  [(ngModel)]="element.fxRateId"
                >
                  <mat-option
                    *ngFor="let o of fxRateList"
                    [value]="o.id"
                    value="option"
                  >
                    {{ o.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="rate">{{
          "guidance.fxrate.rate" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'rate')">
            <ng-template viewMode>
              {{ formatNumber(element.rate, decimalFormat, numberFormat) }}
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <input
                  matInput
                  [formControl]="getControl(element.id, 'rate')"
                  focusable
                  editableOnEnter
                />
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
          {{ "guidance.actions" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
          <button
            (click)="onDelete(row)"
            autofocus
            color="warn"
            mat-icon-button
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>

    <table *ngIf="IsEmpty == true" style="min-height: 48px">
      <tr>
        <td style="text-align: center" [attr.colspan]="displayedColumns.length">
          No Records Found!
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25]"
    ></mat-paginator>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="addDialog()" mat-button tabindex="2">
    {{ "guidance.add" | translate }}
  </button>
  <button (click)="cancel()" mat-button type="button">
    {{ "guidance.close" | translate }}
  </button>
</div>
