import { Directive, HostListener } from "@angular/core";
import { Editable2Component } from "../editable2.component";

@Directive({
  selector: "[editableOnEnter2]",
})
export class EditableOnEnter2Directive {
  constructor(private editable2: Editable2Component) {}

  @HostListener("keydown.enter")
  onEnter() {
    //this.editable2.update.next(true);
    this.editable2.toViewMode();
  }
}
