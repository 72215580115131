import { Pipe, PipeTransform } from "@angular/core";
import { EnumRegistry } from "../base/EnumRegistry";

@Pipe({ name: "enumDisplayValue" })
export class EnumDisplayValuePipe implements PipeTransform {
  transform(value: string, enumClassName: string): any {
    let enumClass: any;
    enumClass = EnumRegistry[enumClassName];
    return enumClass[value] ? enumClass[value].displayValue : undefined;
  }
}
