import { Component, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnInit {
  isSuper: boolean;

  constructor(public authService: AuthService) {
    this.isSuper = this.authService.isSuperAdmin();
  }

  ngOnInit() {}
}
