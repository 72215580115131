import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { Guidance } from "@planard/@core/entities/guidance";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./granularity-add.component.html",
})
export class GranularityAddComponent {
  guidance: Guidance = new Guidance();

  granularityList = [
    { value: 0, label: "Product" },
    { value: 1, label: "Product-Channel" },
    { value: 2, label: "Product-Form" },
    { value: 3, label: "Product-Form-Channel" },
  ];

  form: FormGroup = new FormGroup({
    granularityType: new FormControl("", [Validators.required]),
  });
  options: FormGroup;
  guidanceId: number;
  guidanceGranularity: number;

  constructor(
    public dialogRef: MatDialogRef<GranularityAddComponent>,
    public guidanceService: GuidanceService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.guidanceId = data.guidanceId;
    this.guidanceGranularity = data.granularity;
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.form.patchValue({ granularityType: this.guidanceGranularity });
  }

  save() {
    if (this.form.valid) {
      this.guidance.granularityType = this.form.get("granularityType").value;
      this.guidance.id = this.guidanceId;
      this.guidanceService
        .updateGranularity(this.guidance)
        .subscribe((result) => {
          if (result.isSuccess) {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.success(data);
              });
            this.dialogRef.close(this.guidance);
          } else {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.error(data);
              });
          }
        });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
