import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { Setting } from "@planard/@core/entities/setting";

@Injectable()
export class SettingApi {
  private readonly apiController: string = "settings";

  constructor(private api: HttpService) {}

  listAll<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAll`);
  }

  update<T>(setting: Setting): Observable<any> {
    return this.api.post(`${this.apiController}/update`, setting);
  }
}
