import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { ApproverRequest } from "@planard/@core/entities/approverRequest";

@Injectable()
export class ApproverRequestApi {
  private readonly apiController: string = "approve";

  constructor(private api: HttpService) {}

  submit<T>(approverRequest: ApproverRequest): Observable<any> {
    return this.api.post(`${this.apiController}/submit`, approverRequest);
  }

  requestApprove<T>(approverRequest: any): Observable<any> {
    return this.api.post(
      `${this.apiController}/requestApprove`,
      approverRequest
    );
  }

  requestReject<T>(approverRequest: any): Observable<any> {
    return this.api.post(
      `${this.apiController}/requestReject`,
      approverRequest
    );
  }

  listAllSales<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAllSales`);
  }

  listAllCosts<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAllCosts`);
  }
}
