import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  Inject,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Faq } from "@planard/@core/entities/faq";
import { FaqService } from "@planard/@core/backend/service/faq.service";
import { FaqAddComponent } from "./add/faq-add.component";
import { FaqAnswerComponent } from "./answer/faq-answer.component";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./faq-edit-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 10%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class FaqEditNewComponent implements OnInit {
  displayedColumns: string[] = ["question", "answer", "actions"];
  dataSource: MatTableDataSource<Faq>;
  controls: any[];

  guidanceId: number;
  IsEmpty: boolean;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<FaqEditNewComponent>,
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    private faqService: FaqService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.guidanceId = data.guidanceId;
  }

  ngOnInit() {
    this.faqService.listAll().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);

      if (data != null) {
        this.IsEmpty = false;
      } else {
        this.IsEmpty = true;
      }

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "question", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              question: new FormControl(entity.question, [
                Validators.required,
                removeSpaces,
              ]),
              answer: new FormControl(entity.answer, [
                Validators.required,
                removeSpaces,
              ]),
            },
            { updateOn: "change" }
          ),
        };
      });
    });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var faq = this.getElement(index);
      faq[field] = control.value;
      this.faqService.update(faq).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.ngOnInit();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.ngOnInit();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    const dialogRef = this.dialog.open(FaqAddComponent, {
      width: "500px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  onAnswer(row: any) {
    const dialogRef = this.dialog.open(FaqAnswerComponent, {
      width: "600px",
      data: { row: row },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataSource.data.push(result);
        this.ngOnInit();
      }
    });
  }

  onDelete(row: any) {
    this.translate.get("delete.faq").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.faqService.delete(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.ngOnInit();
            }
          });
        }
      });
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
