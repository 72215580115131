import { BaseEntity } from "../base/BaseEntity";
import { EnumSector } from "../enums/EnumSector";
import { EnumCurrency } from "../enums/EnumCurrency";

export class Company extends BaseEntity {
  name?: string;
  code?: string;
  sector?: EnumSector;
  currency?: EnumCurrency;
  decimalFormat?: string;
  numberFormat?: string;
  logo?: any;
  contactEmail?: string;
  contactName?: string;
  package?: string;
  userCount?: number;
  language?: string;
}
