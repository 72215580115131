<div>
  <div *ngIf="income == 0">
    <forecast></forecast>
  </div>

  <div *ngIf="income == 1">
    <div (contextmenu)="addOnClick($event)">
      <mat-card>
        <mat-card-content>
          <div id="main-div">
            <div class="div" style="width: 15%">
              <mat-card-title>{{
                "entryScreens.incomeForecast" | translate
              }}</mat-card-title>
            </div>

            <div class="div" style="width: 20%">
              <span
                *ngIf="status == 'In Progress'"
                style="font-size: medium; font-weight: 500"
              >
                <mat-icon style="color: orange; vertical-align: middle"
                  >assignment_ind</mat-icon
                >
                {{ "entryScreens.inProgress" | translate }}
              </span>
              <span
                *ngIf="status == 'Completed'"
                style="font-size: medium; font-weight: 500"
              >
                <mat-icon
                  style="color: rgb(68, 202, 135); vertical-align: middle"
                  >assignment_turned_in</mat-icon
                >
                {{ "entryScreens.approved" | translate }}
              </span>
              <span
                *ngIf="status == 'Rejected'"
                style="font-size: medium; font-weight: 500"
                title="{{ rejectReason }}"
              >
                <mat-icon style="color: tomato; vertical-align: middle"
                  >assignment_late</mat-icon
                >
                {{ "entryScreens.rejected" | translate }}
              </span>
            </div>

            <div class="div" style="width: 20%">
              <mat-form-field style="width: 85%">
                <mat-select
                  [(ngModel)]="income"
                  placeholder="{{ 'entryScreens.incometype' | translate }}"
                >
                  <mat-option
                    *ngFor="let o of incomeList"
                    [value]="o.value"
                    value="option"
                  >
                    {{ o.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="div" style="width: 15%">
              <!--<button (click)="changeVolume()" color="primary" mat-button style="background: white;margin-right: 10px;"
                type="button">Volume
              </button>
              <button (click)="changeAmount()" color="primary" mat-button style="background: white;margin-right: 10px;"
                      type="button">Amount
              </button>-->
            </div>

            <div class="div" style="width: 15%">
              <mat-form-field style="width: 85%">
                <mat-select
                  (selectionChange)="setGuidanceScenario()"
                  [(ngModel)]="guidanceId"
                  placeholder="{{ 'entryScreens.guidance' | translate }}"
                >
                  <mat-optgroup
                    *ngFor="let group of guidanceGroup"
                    [label]="group.name"
                  >
                    <mat-option
                      *ngFor="let guidance of group.guidance"
                      [value]="guidance.value"
                    >
                      {{ guidance.label }}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="div" style="width: 15%">
              <mat-form-field>
                <mat-select
                  (selectionChange)="getForecast()"
                  [(ngModel)]="scenarioId"
                  placeholder="{{ 'entryScreens.scenario' | translate }}"
                >
                  <mat-option
                    *ngFor="let o of scenarioList"
                    [value]="o.id"
                    value="option"
                  >
                    {{ o.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <br />

          <div class="responsive-table" style="width: auto; height: 600px">
            <ag-grid-angular
              #agGrid
              style="width: 100%; height: 100%"
              class="ag-theme-material"
              [rowData]="rowData"
              [columnDefs]="columnDefs"
              [defaultColDef]="defaultColDef"
              [components]="components"
              [enableBrowserTooltips]="true"
              [enterMovesDown]="true"
              [enterMovesDownAfterEdit]="true"
              rowSelection="multiple"
              (gridReady)="onGridReady($event)"
              (contextmenu)="open($event, null); $event.preventDefault()"
              [suppressRowClickSelection]="true"
              (cellClicked)="onCellClicked($event)"
              [overlayLoadingTemplate]="loadingTemplate"
              [overlayNoRowsTemplate]="noRowsTemplate"
              (cellValueChanged)="onColumnSpread($event); autoSizeAll(true)"
              (cellContextMenu)="onCellContextMenu($event)"
            >
            </ag-grid-angular>
          </div>

          <br />

          <mat-card-actions>
            <button
              color="accent"
              mat-raised-button
              type="button"
              (click)="setExportData()"
            >
              <mat-icon>cloud_download</mat-icon>&nbsp;{{
                "entryScreens.export" | translate
              }}&nbsp;&nbsp;</button
            >&nbsp;

            <input
              style="display: none"
              type="file"
              (change)="onFileAdd($event)"
              #hiddenfileinput
            />
            <button
              *ngIf="guidanceId == currentGuidanceId && isScreenValid"
              (click)="hiddenfileinput.click()"
              color="accent"
              mat-raised-button
            >
              <mat-icon>cloud_upload</mat-icon>&nbsp;{{
                "entryScreens.import" | translate
              }}&nbsp;&nbsp;
            </button>
            <button
              *ngIf="guidanceId != currentGuidanceId || !isScreenValid"
              (click)="hiddenfileinput.click()"
              color="accent"
              mat-raised-button
              disabled
            >
              <mat-icon>cloud_upload</mat-icon>&nbsp;{{
                "entryScreens.import" | translate
              }}&nbsp;&nbsp;
            </button>

            <button
              *ngIf="guidanceId == currentGuidanceId && isScreenValid"
              (click)="save()"
              color="accent"
              mat-raised-button
              type="button"
              style="float: right"
            >
              {{ "entryScreens.save" | translate }}
            </button>
            <button
              *ngIf="guidanceId != currentGuidanceId || !isScreenValid"
              (click)="save()"
              color="accent"
              mat-raised-button
              type="button"
              style="float: right"
              disabled
            >
              {{ "entryScreens.save" | translate }}
            </button>

            <button
              *ngIf="guidanceId == currentGuidanceId && isScreenValid"
              (click)="onSubmit()"
              color="accent"
              mat-raised-button
              type="button"
              style="float: right"
            >
              {{ "entryScreens.submit" | translate }}
            </button>
            <button
              *ngIf="guidanceId != currentGuidanceId || !isScreenValid"
              color="accent"
              mat-raised-button
              type="button"
              style="float: right"
              disabled
            >
              {{ "entryScreens.submit" | translate }}
            </button>
          </mat-card-actions>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <ng-template #userMenu let-row>
    <div class="ag-theme-material ag-popup">
      <div
        class="ag-menu ag-ltr ag-popup-child ag-keyboard-focus"
        role="presentation"
      >
        <div class="ag-menu-list ag-focus-managed" role="tree">
          <div
            class="ag-tab-guard ag-tab-guard-top"
            role="presentation"
            tabindex="0"
          ></div>

          <!-- COPY -->
          <div
            *ngIf="copyClicked != true"
            class="ag-menu-option"
            tabindex="-1"
            role="treeitem"
            aria-level="1"
            (mouseover)="setClick(); copyClicked = true"
          >
            <span
              ref="eIcon"
              class="ag-menu-option-part ag-menu-option-icon"
              role="presentation"
            >
              <span
                class="ag-icon ag-icon-copy"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text">{{
              "rightClickMenu.copy" | translate
            }}</span>
            <span
              ref="eShortcut"
              class="ag-menu-option-part ag-menu-option-shortcut"
              style="color: grey"
              >Ctrl+C</span
            >
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
            ></span>
          </div>

          <div
            *ngIf="copyClicked == true"
            class="ag-menu-option"
            tabindex="-1"
            style="background: rgb(235, 235, 235)"
            role="treeitem"
            aria-level="1"
            (mouseleave)="copyClicked = false"
            (click)="copyClicked = false; onCopy()"
          >
            <span
              ref="eIcon"
              class="ag-menu-option-part ag-menu-option-icon"
              role="presentation"
            >
              <span
                class="ag-icon ag-icon-copy"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text">{{
              "rightClickMenu.copy" | translate
            }}</span>
            <span
              ref="eShortcut"
              class="ag-menu-option-part ag-menu-option-shortcut"
              >Ctrl+C</span
            >
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
            ></span>
          </div>
          <!-- /COPY -->

          <!-- PASTE -->
          <div
            *ngIf="pasteClicked != true"
            class="ag-menu-option"
            tabindex="-1"
            role="treeitem"
            aria-disabled="true"
            aria-level="1"
            (mouseover)="setClick(); pasteClicked = true"
          >
            <span
              ref="eIcon"
              class="ag-menu-option-part ag-menu-option-icon"
              role="presentation"
            >
              <span
                class="ag-icon ag-icon-paste"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text">{{
              "rightClickMenu.paste" | translate
            }}</span>
            <span
              ref="eShortcut"
              class="ag-menu-option-part ag-menu-option-shortcut"
              style="color: grey"
              >Ctrl+V</span
            >
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
            ></span>
          </div>

          <div
            *ngIf="pasteClicked == true"
            class="ag-menu-option"
            tabindex="-1"
            style="background: rgb(235, 235, 235)"
            role="treeitem"
            aria-disabled="true"
            aria-level="1"
            (mouseleave)="pasteClicked = false"
            (click)="pasteClicked = false; onPaste()"
          >
            <span
              ref="eIcon"
              class="ag-menu-option-part ag-menu-option-icon"
              role="presentation"
            >
              <span
                class="ag-icon ag-icon-paste"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text">{{
              "rightClickMenu.paste" | translate
            }}</span>
            <span
              ref="eShortcut"
              class="ag-menu-option-part ag-menu-option-shortcut"
              >Ctrl+V</span
            >
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
            ></span>
          </div>
          <!-- /PASTE -->

          <!-- SEPARATOR -->
          <div
            class="ag-menu-option ag-menu-option-disabled"
            tabindex="-1"
            role="treeitem"
            aria-disabled="true"
            aria-level="1"
          >
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
              ><mat-divider></mat-divider
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
              ><mat-divider></mat-divider
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
              ><mat-divider></mat-divider
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
              ><mat-divider></mat-divider
            ></span>
          </div>

          <!-- DISTRIBUTION RULES -->
          <div
            *ngIf="rulesClicked != true"
            class="ag-menu-option"
            tabindex="-1"
            role="treeitem"
            (mouseover)="setClick(); rulesClicked = true"
            aria-expanded="false"
            aria-level="1"
          >
            <span
              ref="eIcon"
              class="ag-menu-option-part ag-menu-option-icon"
              role="presentation"
            >
              <span
                class="ag-icon ag-icon-pivot"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text">{{
              "rightClickMenu.distributionRules" | translate
            }}</span>
            <span
              ref="eShortcut"
              class="ag-menu-option-part ag-menu-option-shortcut"
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
            >
              <span
                class="ag-icon ag-icon-small-right"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
          </div>

          <div
            *ngIf="rulesClicked == true"
            class="ag-menu-option ag-menu-option-active"
            tabindex="-1"
            role="treeitem"
            style="background: rgb(235, 235, 235)"
            (mouseleave)="rulesClicked = false"
            (mouseover)="
              rulesPopup = true; colorPopup = false; notesPopup = false
            "
            aria-expanded="true"
            aria-level="1"
          >
            <span
              ref="eIcon"
              class="ag-menu-option-part ag-menu-option-icon"
              role="presentation"
            >
              <span
                class="ag-icon ag-icon-pivot"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text">{{
              "rightClickMenu.distributionRules" | translate
            }}</span>
            <span
              ref="eShortcut"
              class="ag-menu-option-part ag-menu-option-shortcut"
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
            >
              <span
                class="ag-icon ag-icon-small-right"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
          </div>
          <!-- /DISTRIBUTION RULES -->

          <!-- SEPARATOR -->
          <div
            class="ag-menu-option ag-menu-option-disabled"
            tabindex="-1"
            role="treeitem"
            aria-disabled="true"
            aria-level="1"
          >
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
              ><mat-divider></mat-divider
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
              ><mat-divider></mat-divider
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
              ><mat-divider></mat-divider
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
              ><mat-divider></mat-divider
            ></span>
          </div>

          <!-- NOTES -->
          <div
            *ngIf="notesClicked != true"
            class="ag-menu-option"
            tabindex="-1"
            role="treeitem"
            (mouseover)="setClick(); notesClicked = true"
            aria-expanded="false"
            aria-level="1"
          >
            <span
              ref="eIcon"
              class="ag-menu-option-part ag-menu-option-icon"
              role="presentation"
            >
              <span
                class="ag-icon ag-icon-pin"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text">{{
              "rightClickMenu.editNotes" | translate
            }}</span>
            <span
              ref="eShortcut"
              class="ag-menu-option-part ag-menu-option-shortcut"
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
            >
              <span
                class="ag-icon ag-icon-small-right"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
          </div>

          <div
            *ngIf="notesClicked == true"
            class="ag-menu-option ag-menu-option-active"
            tabindex="-1"
            role="treeitem"
            style="background: rgb(235, 235, 235)"
            (mouseleave)="notesClicked = false"
            (mouseover)="
              notesPopup = true; rulesPopup = false; colorPopup = false
            "
            aria-expanded="true"
            aria-level="1"
          >
            <span
              ref="eIcon"
              class="ag-menu-option-part ag-menu-option-icon"
              role="presentation"
            >
              <span
                class="ag-icon ag-icon-pin"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text">{{
              "rightClickMenu.editNotes" | translate
            }}</span>
            <span
              ref="eShortcut"
              class="ag-menu-option-part ag-menu-option-shortcut"
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
            >
              <span
                class="ag-icon ag-icon-small-right"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
          </div>
          <!-- /NOTES -->

          <!-- SEPARATOR -->
          <div
            class="ag-menu-option ag-menu-option-disabled"
            tabindex="-1"
            role="treeitem"
            aria-disabled="true"
            aria-level="1"
          >
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
              ><mat-divider></mat-divider
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
              ><mat-divider></mat-divider
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
              ><mat-divider></mat-divider
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
              ><mat-divider></mat-divider
            ></span>
          </div>

          <!-- COLOR RATE -->
          <div
            *ngIf="colorClicked != true"
            class="ag-menu-option"
            tabindex="-1"
            role="treeitem"
            (mouseover)="setClick(); colorClicked = true"
            aria-expanded="false"
            aria-level="1"
          >
            <span
              ref="eIcon"
              class="ag-menu-option-part ag-menu-option-icon"
              role="presentation"
            >
              <span
                class="ag-icon ag-icon-color-picker"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text">{{
              "rightClickMenu.setColoring" | translate
            }}</span>
            <span
              ref="eShortcut"
              class="ag-menu-option-part ag-menu-option-shortcut"
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
            >
              <span
                class="ag-icon ag-icon-small-right"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
          </div>

          <div
            *ngIf="colorClicked == true"
            class="ag-menu-option ag-menu-option-active"
            tabindex="-1"
            role="treeitem"
            style="background: rgb(235, 235, 235)"
            (mouseleave)="colorClicked = false"
            (mouseover)="
              colorPopup = true; rulesPopup = false; notesPopup = false
            "
            aria-expanded="true"
            aria-level="1"
          >
            <span
              ref="eIcon"
              class="ag-menu-option-part ag-menu-option-icon"
              role="presentation"
            >
              <span
                class="ag-icon ag-icon-color-picker"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text">{{
              "rightClickMenu.setColoring" | translate
            }}</span>
            <span
              ref="eShortcut"
              class="ag-menu-option-part ag-menu-option-shortcut"
            ></span>
            <span
              ref="ePopupPointer"
              class="ag-menu-option-part ag-menu-option-popup-pointer"
            >
              <span
                class="ag-icon ag-icon-small-right"
                unselectable="on"
                role="presentation"
              ></span>
            </span>
          </div>
          <!-- /COLOR RATE -->

          <div
            class="ag-tab-guard ag-tab-guard-bottom"
            role="presentation"
            tabindex="0"
          ></div>
        </div>
      </div>

      <!-- COLOR RATE POP UP -->
      <div
        *ngIf="colorPopup == true"
        (mouseleave)="colorPopup = false"
        class="ag-theme-material ag-popup"
      >
        <div
          class="ag-menu ag-ltr ag-popup-child"
          role="presentation"
          style="min-width: 176px; position: fixed"
          [style.left.px]="x"
          [style.top.px]="y4c"
        >
          <div class="ag-menu-list ag-focus-managed" role="tree">
            <div
              class="ag-tab-guard ag-tab-guard-top"
              role="presentation"
              tabindex="0"
            ></div>

            <!-- COLOR RATE -->
            <div
              *ngIf="changeColorClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); changeColorClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span role="presentation">(%)</span>
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.setRate" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="changeColorClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="changeColorClicked = false; notes = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span role="presentation">(%)</span>
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.setRate" | translate }}
                <br *ngIf="changeColorClicked == true" />
                <input
                  *ngIf="changeColorClicked == true"
                  type="number"
                  min="0"
                  max="100"
                  placeholder="%"
                  [(ngModel)]="percent2coloring"
                />&nbsp;
                <mat-icon
                  *ngIf="changeColorClicked == true"
                  (click)="changeColor(); changeColorClicked = false"
                  style="vertical-align: middle"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /COLOR RATE -->

            <div
              class="ag-tab-guard ag-tab-guard-bottom"
              role="presentation"
              tabindex="0"
            ></div>
          </div>
        </div>
      </div>
      <!-- /COLOR RATE POP UP -->

      <!-- DISTRIBUTION RULES POP UP -->
      <div
        *ngIf="rulesPopup == true"
        (mouseleave)="rulesPopup = false"
        class="ag-theme-material ag-popup"
      >
        <div
          class="ag-menu ag-ltr ag-popup-child"
          role="presentation"
          style="width: 300px !important; position: fixed"
          [style.left.px]="x"
          [style.top.px]="y4d"
        >
          <div class="ag-menu-list ag-focus-managed" role="tree">
            <div
              class="ag-tab-guard ag-tab-guard-top"
              role="presentation"
              tabindex="0"
            ></div>

            <!-- CARRY FORWARD -->
            <div
              *ngIf="carryForwardClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); carryForwardClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="rulesPopup = false; onCarryForward()"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-right" role="presentation"
                  >..</span
                >
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.carryForward" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="carryForwardClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="carryForwardClicked = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="rulesPopup = false; onCarryForward()"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-right" role="presentation"
                  >..</span
                >
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.carryForward" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /CARRY FORWARD -->

            <!-- SEPARATOR -->
            <div
              class="ag-menu-option ag-menu-option-disabled"
              tabindex="-1"
              role="treeitem"
              aria-disabled="true"
              aria-level="1"
            >
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
                ><mat-divider></mat-divider
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
                ><mat-divider></mat-divider
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
                ><mat-divider></mat-divider
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
                ><mat-divider></mat-divider
              ></span>
            </div>

            <!-- INCREASE MONTHLY -->
            <div
              *ngIf="increaseMonthlyClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); increaseMonthlyClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-aggregation" role="presentation"
                  >+</span
                >
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.increaseMonthly" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="increaseMonthlyClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="increaseMonthlyClicked = false; input = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="input = true"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-aggregation" role="presentation"
                  >+</span
                >
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.increaseMonthly" | translate }}
                <br *ngIf="input == true" />
                <input
                  *ngIf="input == true"
                  placeholder="Amount.."
                  type="number"
                  min="0"
                  [(ngModel)]="increaseValue"
                  style="width: 100px"
                />&nbsp;
                <mat-icon
                  *ngIf="input == true"
                  (click)="onIncreaseMonthly(0); input = false"
                  style="vertical-align: middle; cursor: pointer"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /INCREASE MONTHLY -->

            <!-- DECREASE MONTHLY -->
            <div
              *ngIf="decreaseMonthlyClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); decreaseMonthlyClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-aggregation" role="presentation"
                  >_</span
                >
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.decreaseMonthly" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="decreaseMonthlyClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="decreaseMonthlyClicked = false; input = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="input = true"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-aggregation" role="presentation"
                  >_</span
                >
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.decreaseMonthly" | translate }}
                <br *ngIf="input == true" />
                <input
                  *ngIf="input == true"
                  placeholder="Amount.."
                  type="number"
                  min="0"
                  [(ngModel)]="decreaseValue"
                  style="width: 100px"
                />&nbsp;
                <mat-icon
                  *ngIf="input == true"
                  (click)="onDecreaseMonthly(0); input = false"
                  style="vertical-align: middle"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /DECREASE MONTHLY -->

            <!-- INCREASE MONTHLY % -->
            <div
              *ngIf="increaseMonthlyPClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); increaseMonthlyPClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-aggregation" role="presentation"
                  >+</span
                >
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.increaseMonthlyP" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="increaseMonthlyPClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="increaseMonthlyPClicked = false; input = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="input = true"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-aggregation" role="presentation"
                  >+</span
                >
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.increaseMonthlyP" | translate }}
                <br *ngIf="input == true" />
                <input
                  *ngIf="input == true"
                  placeholder="Amount.."
                  type="number"
                  min="0"
                  [(ngModel)]="increaseValueP"
                  style="width: 100px"
                />&nbsp;
                <mat-icon
                  *ngIf="input == true"
                  (click)="onIncreaseMonthly(1); input = false"
                  style="vertical-align: middle; cursor: pointer"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /INCREASE MONTHLY % -->

            <!-- DECREASE MONTHLY % -->
            <div
              *ngIf="decreaseMonthlyPClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); decreaseMonthlyPClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-aggregation" role="presentation"
                  >_</span
                >
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.decreaseMonthlyP" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="decreaseMonthlyPClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="decreaseMonthlyPClicked = false; input = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="input = true"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-aggregation" role="presentation"
                  >_</span
                >
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.decreaseMonthlyP" | translate }}
                <br *ngIf="input == true" />
                <input
                  *ngIf="input == true"
                  placeholder="Amount.."
                  type="number"
                  min="0"
                  [(ngModel)]="decreaseValueP"
                  style="width: 100px"
                />&nbsp;
                <mat-icon
                  *ngIf="input == true"
                  (click)="onDecreaseMonthly(1); input = false"
                  style="vertical-align: middle"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /DECREASE MONTHLY %-->

            <!-- SEPARATOR -->
            <div
              class="ag-menu-option ag-menu-option-disabled"
              tabindex="-1"
              role="treeitem"
              aria-disabled="true"
              aria-level="1"
            >
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
                ><mat-divider></mat-divider
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
                ><mat-divider></mat-divider
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
                ><mat-divider></mat-divider
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
                ><mat-divider></mat-divider
              ></span>
            </div>

            <!-- SUSTAIN TREND -->
            <!-- <div *ngIf="sustainTrendClicked!=true" class="ag-menu-option" (mouseover)="setClick(); sustainTrendClicked=true" tabindex="-1" role="treeitem" aria-level="1" (click)="rulesPopup=false;sustainTrend();">
          <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation">
            <span class="ag-icon ag-icon-chart" role="presentation"></span>
          </span>
          <span ref="eName" class="ag-menu-option-part ag-menu-option-text">{{ 'rightClickMenu.sustainTrend' | translate }}</span>
          <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
          <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
        </div>

        <div *ngIf="sustainTrendClicked==true" style="background: rgb(235, 235, 235);" class="ag-menu-option"
        (mouseleave)="sustainTrendClicked=false; input=false;" tabindex="-1" role="treeitem" aria-level="1" (click)="rulesPopup=false;sustainTrend();">
          <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation">
            <span class="ag-icon ag-icon-chart" role="presentation"></span>
          </span>
          <span ref="eName" class="ag-menu-option-part ag-menu-option-text">{{ 'rightClickMenu.sustainTrend' | translate }}</span>
          <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
          <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
        </div> -->
            <!-- /SUSTAIN TREND -->

            <!-- INCREASE ALL MONTHLY -->
            <div
              *ngIf="increaseAllMonthlyClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); increaseAllMonthlyClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-columns" role="presentation"
                  >+</span
                >
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.increaseAllMonthly" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="increaseAllMonthlyClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="increaseAllMonthlyClicked = false; input = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="input = true"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-columns" role="presentation"
                  >+</span
                >
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.increaseAllMonthly" | translate }}
                <br *ngIf="input == true" />
                <input
                  *ngIf="input == true"
                  placeholder="Amount.."
                  type="number"
                  min="0"
                  [(ngModel)]="increaseAllValue"
                  style="width: 100px"
                />&nbsp;
                <mat-icon
                  *ngIf="input == true"
                  (click)="onIncreaseAllMonthly(0); input = false"
                  style="vertical-align: middle; cursor: pointer"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /INCREASE ALL MONTHLY -->

            <!-- DECREASE ALL MONTHLY -->
            <div
              *ngIf="decreaseAllMonthlyClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); decreaseAllMonthlyClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-columns" role="presentation"
                  >_</span
                >
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.decreaseAllMonthly" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="decreaseAllMonthlyClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="decreaseAllMonthlyClicked = false; input = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="input = true"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-columns" role="presentation"
                  >_</span
                >
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.decreaseAllMonthly" | translate }}
                <br *ngIf="input == true" />
                <input
                  *ngIf="input == true"
                  placeholder="Amount.."
                  type="number"
                  min="0"
                  [(ngModel)]="decreaseAllValue"
                  style="width: 100px"
                />&nbsp;
                <mat-icon
                  *ngIf="input == true"
                  (click)="onDecreaseAllMonthly(0); input = false"
                  style="vertical-align: middle"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /DECREASE ALL MONTHLY -->

            <!-- INCREASE ALL MONTHLY % -->
            <div
              *ngIf="increaseAllMonthlyPClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); increaseAllMonthlyPClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-columns" role="presentation"
                  >+</span
                >
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.increaseAllMonthlyP" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="increaseAllMonthlyPClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="increaseAllMonthlyPClicked = false; input = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="input = true"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-columns" role="presentation"
                  >+</span
                >
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.increaseAllMonthlyP" | translate }}
                <br *ngIf="input == true" />
                <input
                  *ngIf="input == true"
                  placeholder="Amount.."
                  type="number"
                  min="0"
                  [(ngModel)]="increaseAllValueP"
                  style="width: 100px"
                />&nbsp;
                <mat-icon
                  *ngIf="input == true"
                  (click)="onIncreaseAllMonthly(1); input = false"
                  style="vertical-align: middle; cursor: pointer"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /INCREASE ALL MONTHLY % -->

            <!-- DECREASE ALL MONTHLY % -->
            <div
              *ngIf="decreaseAllMonthlyPClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); decreaseAllMonthlyPClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-columns" role="presentation"
                  >_</span
                >
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.decreaseAllMonthlyP" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="decreaseAllMonthlyPClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="decreaseAllMonthlyPClicked = false; input = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="input = true"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-columns" role="presentation"
                  >_</span
                >
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.decreaseAllMonthlyP" | translate }}
                <br *ngIf="input == true" />
                <input
                  *ngIf="input == true"
                  placeholder="Amount.."
                  type="number"
                  min="0"
                  [(ngModel)]="decreaseAllValueP"
                  style="width: 100px"
                />&nbsp;
                <mat-icon
                  *ngIf="input == true"
                  (click)="onDecreaseAllMonthly(1); input = false"
                  style="vertical-align: middle"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /DECREASE ALL MONTHLY %-->

            <!-- SEPARATOR -->
            <div
              class="ag-menu-option ag-menu-option-disabled"
              tabindex="-1"
              role="treeitem"
              aria-disabled="true"
              aria-level="1"
            >
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
                ><mat-divider></mat-divider
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
                ><mat-divider></mat-divider
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
                ><mat-divider></mat-divider
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
                ><mat-divider></mat-divider
              ></span>
            </div>

            <!-- COPY ACTUALS -->
            <div
              *ngIf="copyActualsClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); copyActualsClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-copy" role="presentation">A</span>
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.copyActuals" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="copyActualsClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="copyActualsClicked = false; input = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="input = true"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-copy" role="presentation">A</span>
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.copyActuals" | translate }}
                <br *ngIf="input == true" />
                <select
                  *ngIf="input == true"
                  placeholder="Year.."
                  [(ngModel)]="year4actual"
                  style="background-color: white; border: white"
                >
                  <option *ngFor="let o of yearsCopyActual" [value]="o" value="option">
                    {{ o }}
                  </option>
                </select>
                &nbsp;
                <mat-icon
                  *ngIf="input == true"
                  (click)="copyActuals(); input = false"
                  style="vertical-align: middle"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /COPY ACTUALS -->

            <!-- COPY SCENARIO -->
            <div
              *ngIf="copyScenarioClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); copyScenarioClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-copy" role="presentation">S</span>
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.copyScenario" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="copyScenarioClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="copyScenarioClicked = false; input = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="input = true"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-copy" role="presentation">S</span>
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.copyScenario" | translate }}
                <br *ngIf="input == true" />
                <select
                  *ngIf="input == true"
                  placeholder="Scenario.."
                  style="background-color: white; border: white"
                  [(ngModel)]="copyScenarioId"
                >
                  <option
                    *ngFor="let o of scenarioList"
                    [value]="o.id"
                    value="option"
                  >
                    {{ o.name }}
                  </option>
                </select>
                <mat-icon
                  *ngIf="input == true"
                  (click)="copyScenario(); input = false"
                  style="vertical-align: middle"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /COPY SCENARIO -->

            <!-- COPY PLANNING -->
            <div
              *ngIf="copyPlanningClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); copyPlanningClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-copy" role="presentation">P</span>
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.copyPlanning" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="copyPlanningClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="copyPlanningClicked = false; input = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="input = true"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-copy" role="presentation">P</span>
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.copyPlanning" | translate }}
                <br *ngIf="input == true" />
                <p *ngIf="input == true">
                  {{ "rightClickMenu.selectGuidance" | translate }}:
                </p>
                <select
                  *ngIf="input == true"
                  (change)="copyPlanning(); guidanceSet = true"
                  [(ngModel)]="copyPlanningGuidanceId"
                  placeholder="Guidance.."
                  style="background-color: white; border: white; width: 100%"
                >
                  <option
                    *ngFor="let o of guidanceList"
                    [value]="o.id"
                    value="option"
                  >
                    {{ o.planningCycleName }}
                  </option>
                </select>
                <br *ngIf="guidanceSet == true" />
                <p *ngIf="guidanceSet == true">
                  {{ "rightClickMenu.selectScenario" | translate }}:
                </p>
                <select
                  *ngIf="guidanceSet == true"
                  placeholder="Scenario.."
                  style="background-color: white; border: white; width: 100%"
                  [(ngModel)]="copyScenarioId"
                >
                  <option
                    *ngFor="let o of scenarioGuiList"
                    [value]="o.id"
                    value="option"
                  >
                    {{ o.name }}
                  </option>
                </select>
                <br *ngIf="guidanceSet == true" />
                <mat-icon
                  *ngIf="guidanceSet == true"
                  (click)="copyScenario(); input = false"
                  style="vertical-align: middle"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /COPY PLANNING -->

            <div
              class="ag-tab-guard ag-tab-guard-bottom"
              role="presentation"
              tabindex="0"
            ></div>
          </div>
        </div>
      </div>
      <!-- /DISTRIBUTION RULES POP UP -->

      <!-- NOTES POP UP -->
      <div
        *ngIf="notesPopup == true"
        (mouseleave)="notesPopup = false"
        class="ag-theme-material ag-popup"
      >
        <div
          class="ag-menu ag-ltr ag-popup-child"
          role="presentation"
          style="min-width: 176px; position: fixed"
          [style.left.px]="x"
          [style.top.px]="y4n"
        >
          <div class="ag-menu-list ag-focus-managed" role="tree">
            <div
              class="ag-tab-guard ag-tab-guard-top"
              role="presentation"
              tabindex="0"
            ></div>

            <!-- EDIT NOTES -->
            <div
              *ngIf="priceNotesClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); priceNotesClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-paste" role="presentation">+</span>
              </span>
              <span
                ref="eName"
                class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.addNote" | translate }}</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="priceNotesClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="priceNotesClicked = false; notes = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
              (click)="notes = true"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-paste" role="presentation">+</span>
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >{{ "rightClickMenu.addNote" | translate }}
                <br *ngIf="notes == true" />
                <textarea
                  *ngIf="notes == true"
                  placeholder="your note.."
                  [(ngModel)]="noteValue"
                  style="width: 95%"
                  >{{
                    onEditNotesShow() == "no note.." ? "" : onEditNotesShow()
                  }}</textarea
                >&nbsp;
                <mat-icon
                  *ngIf="notes == true"
                  (click)="onEditNotes(); notes = false"
                  style="vertical-align: bottom"
                  title="Apply"
                  >check_circle</mat-icon
                >
              </span>
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /EDIT NOTES -->

            <!-- EDIT NOTES SHOW -->
            <div
              *ngIf="discountNotesClicked != true"
              class="ag-menu-option"
              (mouseover)="setClick(); discountNotesClicked = true"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-eye" role="presentation"></span>
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text">
                &#39; {{ onEditNotesShow() }} &#39;</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>

            <div
              *ngIf="discountNotesClicked == true"
              style="background: rgb(235, 235, 235)"
              class="ag-menu-option"
              (mouseleave)="discountNotesClicked = false"
              tabindex="-1"
              role="treeitem"
              aria-level="1"
            >
              <span
                ref="eIcon"
                class="ag-menu-option-part ag-menu-option-icon"
                role="presentation"
              >
                <span class="ag-icon ag-icon-eye" role="presentation"></span>
              </span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text"
                >&#39; {{ onEditNotesShow() }} &#39;</span
              >
              <span
                ref="eShortcut"
                class="ag-menu-option-part ag-menu-option-shortcut"
              ></span>
              <span
                ref="ePopupPointer"
                class="ag-menu-option-part ag-menu-option-popup-pointer"
              ></span>
            </div>
            <!-- /EDIT NOTES SHOW-->

            <div
              class="ag-tab-guard ag-tab-guard-bottom"
              role="presentation"
              tabindex="0"
            ></div>
          </div>
        </div>
      </div>
      <!-- /NOTES POP UP -->
    </div>
    <!--</section>-->
  </ng-template>
</div>
