import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CompanySetupService } from "@planard/@core/backend/service/company-setup.service";
import { ProductFamilyService } from "@planard/@core/backend/service/product-family.service";
import { EnumSector } from "@planard/@core/enums/EnumSector";
import { Company } from "@planard/@core/entities/company";
import { BusinessUnit } from "@planard/@core/entities/businessUnit";
import { ProductFamily } from "@planard/@core/entities/productFamily";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ProcessResult } from "@planard/@core/entities/process-result";
import { ToastrService } from "ngx-toastr";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";

@Component({
  templateUrl: "./company-setup-update.component.html",
})
export class CompanySetupUpdateComponent implements OnInit {
  sectorList: EnumSector[] = EnumSector.values;
  currencyList: EnumCurrency[] = EnumCurrency.values;
  productFamilyList: ProductFamily[];
  businessUnitList: BusinessUnit[];
  company: Company;
  form: FormGroup = new FormGroup({
    companyName: new FormControl("", [Validators.required]),
    companyCode: new FormControl("", [Validators.required]),
    sector: new FormControl("", [Validators.required]),
    // departments: new FormControl('',[Validators.required]),
    // subdepartments: new FormControl('',[Validators.required]),
    productFamily: new FormControl("", [Validators.required]),
    businessUnit: new FormControl("", [Validators.required]),
    currency: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  constructor(
    public companySetupService: CompanySetupService,
    public fb: FormBuilder,
    public productFamilyService: ProductFamilyService,
    public toastrService: ToastrService,
    public dialogRef: MatDialogRef<CompanySetupUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.productFamilyService.listAll().subscribe((result: any) => {
      this.productFamilyList = result;
    });
    this.companySetupService
      .listAllBusinessUnit()
      .subscribe((result: BusinessUnit[]) => {
        this.businessUnitList = result;
      });
  }

  save() {
    if (this.form.valid) {
      this.company = this.form.value;
      this.companySetupService
        .update(this.company)
        .subscribe((backendResult: ProcessResult) => {
          if (backendResult.isSuccess) {
            this.toastrService.success(backendResult.message);
          } else {
            this.toastrService.error(backendResult.message);
          }
        });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
