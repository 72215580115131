import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
@Injectable()
export class ReportsApi {
  private readonly apiController: string = "report";

  constructor(private api: HttpService) {}

  listChannels<T>(
    scenarioId: number,
    granularityId: number,
    actuals: any,
    periodId: any,
    currencyId: number,
    indicatorId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listChannels/${scenarioId}/${granularityId}/${actuals}/${periodId}/${currencyId}/${indicatorId}`
    );
  }

  listSales<T>(
    scenarioId: number,
    granularityId: number,
    actuals: any,
    periodId: any,
    currencyId: number,
    indicatorId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listSalesOverview/${scenarioId}/${granularityId}/${actuals}/${periodId}/${currencyId}/${indicatorId}`
    );
  }

  listProduct<T>(
    scenarioId: number,
    granularityId: number,
    actuals: any,
    periodId: any,
    currencyId: number,
    indicatorId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listProductsOverview-V2/${scenarioId}/${granularityId}/${actuals}/${periodId}/${currencyId}/${indicatorId}`
    );
  }

  scenarioComp<T>(
    scenarioId: any,
    granularityId: number,
    categoryId: number,
    indicatorId: number,
    timescale: number,
    actuals: number,
    periodId: number,
    currencyId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listScenarioComp/${scenarioId}/${granularityId}/${categoryId}/${indicatorId}/${timescale}/${actuals}/${periodId}/${currencyId}`
    );
  }

  priceVolume<T>(
    productId: number,
    scenarioId: number,
    startActuals: number,
    startPeriods: number,
    endActuals: number,
    endPeriods: number,
    currencyId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listPriceVolume/${productId}/${scenarioId}/${startActuals}/${startPeriods}/${endActuals}/${endPeriods}/${currencyId}`
    );
  }

  listIncomeStatement<T>(
    scenarioId: number,
    guidanceId: number,
    actuals: any,
    periodId: any,
    currencyId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listIncomeStatement/${scenarioId}/${guidanceId}/${actuals}/${periodId}/${currencyId}`
    );
  }

  listActualPlan<T>(
    indicator: number,
    granularity: number,
    year: number,
    month: number,
    scenario_id: number,
    currency_id: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listActualvsPlan/${indicator}/${granularity}/${year}/${month}/${scenario_id}/${currency_id}`
    );
  }
}
