import { Component, Inject, OnInit } from "@angular/core";
import { Process } from "@planard/@core/entities/process";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgModel,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TimelineService } from "@planard/@core/backend/service/timeline.service";
import { EnumTask } from "@planard/@core/enums/EnumTask";
import { Role } from "@planard/@core/entities/role";
import { RoleService } from "@planard/@core/backend/service/role.service";
import { MatSelect } from "@angular/material/select";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./timeline-add.component.html",
  //styles: [`
  //::ng-deep .mat-option:first-child .mat-pseudo-checkbox{ display: none; }
  //`]
})
export class TimelineAddComponent implements OnInit {
  process: Process;
  dependencyList: Process[];
  responsibleList: Role[];
  taskList: EnumTask[] = EnumTask.values;
  isStartValid: boolean;
  allRoles: boolean;
  endDate: any;
  diffDays: any;
  startDate: any;
  today;
  customTask = "";
  task = "";
  companyPackage: string;
  adminRole;

  form: FormGroup = new FormGroup({
    task: new FormControl(""),
    customTask: new FormControl(""),
    selectedProcessRoles: new FormControl(""),
    startDate: new FormControl("", [Validators.required]),
    endDate: new FormControl("", [Validators.required]),
    dependentProcessId: new FormControl(""),
    daysToExecute: new FormControl("", [
      Validators.required,
      Validators.min(0),
    ]),
  });
  options: FormGroup;

  constructor(
    private fb: FormBuilder,
    public timelineService: TimelineService,
    private roleService: RoleService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public authService: AuthService,
    public dialogRef: MatDialogRef<TimelineAddComponent>
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
    this.companyPackage = this.authService.getCompanyPackage();
    let date = new Date();
    this.today = new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: "Europe/Berlin" }
      )
    );
  }

  selectAll(select: MatSelect) {
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    this.form.controls["selectedProcessRoles"].setValue(selectedIds);
    this.allRoles = true;
  }

  selectOne(id: number) {
    if (this.allRoles == true) {
      this.allRoles = false;
      let selectedId = [id];
      this.form.controls["selectedProcessRoles"].setValue(selectedId);
    }
  }

  deselectAll() {
    this.allRoles = false;
    let selectedIds = [];
    this.form.controls["selectedProcessRoles"].setValue(selectedIds);
  }

  deselectOne(id: number, select: MatSelect) {
    if (this.allRoles == true) {
      this.allRoles = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && id != element.value) {
          selectedIds.push(element.value);
        }
      });
      this.form.controls["selectedProcessRoles"].setValue(selectedIds);
    }
  }

  ngOnInit() {
    this.roleService.list().subscribe((resultList) => {
      this.responsibleList = [];
      resultList.forEach((element) => {
        if (element.name != "Super Admin") {
          this.responsibleList.push(element);
        }
        if (element.name == "Admin") this.adminRole = element.id;
      });
    });
    this.timelineService.listAll().subscribe((result) => {
      this.dependencyList = result;
    });
    this.form.patchValue({ dependentProcessId: null });
  }

  save() {
    this.process = this.form.value;
    if (this.companyPackage == "basic") {
      this.process.selectedProcessRoles = [this.adminRole];
      this.process.endDate = new Date("2222-12-31 00:00:00");
    }

    if (new Date(this.process.endDate) < new Date(this.process.startDate)) {
      this.translate.get("timeline.msg3").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    }

    if (this.form.valid) {
      this.setStart(
        this.process.startDate,
        this.process.dependentProcessId,
        this.process.task
      );

      if (this.isStartValid) {
        if (this.process.customTask != "" && this.process.customTask != null)
          this.process.task = null;
        if (this.process.task) this.process.customTask = null;

        if (!this.process.task && !this.process.customTask) {
          this.translate.get("common.formNotValid").subscribe((data: any) => {
            this.toastrService.error(data);
          });
          return;
        }

        this.timelineService.add(this.process).subscribe((backendResult) => {
          if (backendResult.isSuccess) {
            this.translate
              .get(`messages.${backendResult.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.success(data);
              });
            this.dialogRef.close(this.process);
          } else {
            this.translate
              .get(`messages.${backendResult.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.error(data);
              });
          }
        });
      } else {
        this.translate.get("timeline.msg3").subscribe((data: any) => {
          this.toastrService.error(data);
        });
      }
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancelClick() {
    this.dialogRef.close(false);
  }

  endDateSet() {
    if (
      this.form.get("daysToExecute").valid &&
      this.form.get("startDate").valid
    ) {
      var strt = this.form.get("startDate").value;
      var days = this.form.get("daysToExecute").value;
      var StartDate: any = new Date(strt);
      //var Days:any = (days*(1000 * 60 * 60 * 24));
      //var finalDate = (StartDate.getTime() + Days);
      //this.form.patchValue({endDate: EndDate});
      var EndDate: Date = new Date();
      EndDate.setDate(StartDate.getDate() + days);
      this.endDate = EndDate;
    }
  }

  daysToExecuteSet() {
    if (this.form.get("endDate").valid && this.form.get("startDate").valid) {
      var strt = this.form.get("startDate").value;
      var end = this.form.get("endDate").value;
      var StartDate: any = new Date(
        strt /*(typeof strt === "string" ? new Date(strt) : strt).toLocaleString("en-US", {timeZone: "Europe/Berlin"})*/
      );
      var EndDate: any = new Date(
        end /*(typeof end === "string" ? new Date(end) : end).toLocaleString("en-US", {timeZone: "Europe/Berlin"})*/
      );
      var diffDays: any = Math.floor(
        (EndDate - StartDate) / (1000 * 60 * 60 * 24)
      );
      //this.form.patchValue({daysToExecute: diffDays});
      this.diffDays = diffDays;
    }
  }

  setStart(start: any, depentId: any, task: any) {
    if (depentId == null) {
      this.isStartValid = true;
    } else {
      for (let tk of Object.keys(this.dependencyList)) {
        if (
          this.dependencyList[tk].id == depentId &&
          start > new Date(this.dependencyList[tk].endDate)
        ) {
          this.isStartValid = true;
        }
      }
    }
  }
}
