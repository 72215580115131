import { Injectable } from "@angular/core";
import { UserTaskApi } from "@planard/@core/backend/api/user-task.api";
import { UserTaskData } from "@planard/@core/entities/interface/user-tasks";
import { UserTask } from "@planard/@core/entities/user-task";
import { Observable } from "rxjs";

@Injectable()
export class UserTaskService extends UserTaskData {
  constructor(private api: UserTaskApi) {
    super();
  }

  getUserTasks() {
    return this.api.getUserTasks<UserTask[]>();
  }

  getAllUserTasks(): Observable<any[]> {
    return this.api.getAllUserTasks<UserTask[]>();
  }

  update(userTask: UserTask) {
    return this.api.update<any>(userTask);
  }
}
