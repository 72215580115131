import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ProcessResult } from "@planard/@core/entities/process-result";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { UserTaskService } from "@planard/@core/backend/service/user-task.service";
import { UserTask } from "@planard/@core/entities/user-task";
import { EnumStatus } from "@planard/@core/enums/EnumStatus";
import { UserTaskUpdateComponent } from "./update/usertask-update.component";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
@Component({
  templateUrl: "./user-task.component.html",
  styles: [
    `
      .toRightReverse {
        flex: 0 0 5%;
      }
      .fixRight {
        margin-left: auto;
        margin-right: 0px;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class UserTaskComponent implements OnInit {
  userTask: UserTask = new UserTask();
  rows = [];
  statusList: EnumStatus[] = EnumStatus.values;
  isRoleValid: boolean;
  loadingIndicator = true;
  processResult: ProcessResult;
  companyPackage: string;

  val1;
  val2;
  val3;
  val4;

  valx;
  valy;
  valz;

  displayedColumns: string[] = [
    "name",
    "userName",
    "startDate",
    "endDate",
    "stage",
    "days",
    "status",
    "actions",
  ];
  dataSource: MatTableDataSource<UserTask>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public toastrService: ToastrService,
    public authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    public userTaskService: UserTaskService
  ) {
    this.isRoleValid = this.authService.isAdmin();
    this.companyPackage = this.authService.getCompanyPackage();
    if (this.isRoleValid == false || this.companyPackage == "basic")
      this.router.navigate(["auth/404"]);

    this.translate.get("task.opened").subscribe((data: any) => {
      this.val1 = data;
    });
    this.translate.get("task.dan").subscribe((data: any) => {
      this.val2 = data;
    });
    this.translate.get("task.untill").subscribe((data: any) => {
      this.val3 = data;
    });
    this.translate.get("task.kadar").subscribe((data: any) => {
      this.val4 = data;
    });
    this.translate.get("task.geçti").subscribe((data: any) => {
      this.valx = data;
    });
    this.translate.get("task.kaldı").subscribe((data: any) => {
      this.valy = data;
    });
    this.translate.get("task.execute").subscribe((data: any) => {
      this.valz = data;
    });
    this.translate.onLangChange.subscribe((lang) => {
      this.translate.get("task.opened").subscribe((data: any) => {
        this.val1 = data;
      });
      this.translate.get("task.dan").subscribe((data: any) => {
        this.val2 = data;
      });
      this.translate.get("task.untill").subscribe((data: any) => {
        this.val3 = data;
      });
      this.translate.get("task.kadar").subscribe((data: any) => {
        this.val4 = data;
      });
      this.translate.get("task.geçti").subscribe((data: any) => {
        this.valx = data;
      });
      this.translate.get("task.kaldı").subscribe((data: any) => {
        this.valy = data;
      });
      this.translate.get("task.execute").subscribe((data: any) => {
        this.valz = data;
      });
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.getData();
    setTimeout(() => {
      this.loadingIndicator = false;
      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "name", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }
    }, 1500);
  }

  getData() {
    this.userTaskService.getAllUserTasks().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);
      this.rows = data;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  addLockout(row: any) {
    const dialogRef = this.dialog.open(UserTaskUpdateComponent, {
      width: "500px",
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  lock(row: any) {
    this.userTask = row;
    this.userTask.lockDate = null;
    this.userTaskService.update(this.userTask).subscribe((result) => {
      if (result.isSuccess) {
        this.translate
          .get(`messages.${result.messageId}`)
          .subscribe((data: any) => {
            this.toastrService.success(data);
          });
        this.ngOnInit();
      } else {
        this.translate
          .get(`messages.${result.messageId}`)
          .subscribe((data: any) => {
            this.toastrService.error(data);
          });
      }
    });
  }

  setLock(row: any, lockDate: Date) {
    var current: any = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    var lockDay: any = new Date(
      (typeof lockDate === "string"
        ? new Date(lockDate)
        : lockDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (row.status == "NOTSTARTED" && current <= lockDay) {
      this.lock(row);
    }
    return lockDay;
  }

  getDays(endDate: any) {
    var current: any = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    var endDay: any = new Date(
      (typeof endDate === "string"
        ? new Date(endDate)
        : endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    var diffDays: any = Math.floor((endDay - current) / (1000 * 60 * 60 * 24));
    if (diffDays < 0) {
      diffDays = diffDays * -1 + " " + this.valx;
      return diffDays;
    } else if (diffDays > 0) {
      diffDays = diffDays + " " + this.valy;
      return diffDays;
    } else {
      diffDays = this.valz;
      return diffDays;
    }
  }

  getStage(start: Date, end: Date) {
    var current: any = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    var startDay: any = new Date(
      (typeof start === "string" ? new Date(start) : start).toLocaleString(
        "en-US",
        { timeZone: "Europe/Berlin" }
      )
    );
    var endDay: any = new Date(
      (typeof end === "string" ? new Date(end) : end).toLocaleString("en-US", {
        timeZone: "Europe/Berlin",
      })
    );
    if (current < startDay) return this.valx;
    else if (startDay > current > endDay) return this.valy;
    else return this.valz;
  }

  isDueAgain(lockDate) {
    if (new Date(lockDate) < new Date()) return true;
    else return false;
  }
}
