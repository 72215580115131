import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { Price } from "@planard/@core/entities/price";

@Injectable()
export class PriceApi {
  private readonly apiController: string = "price";

  constructor(private api: HttpService) {}

  listPrices<T>(
    scenarioId: number,
    approveRequestId?: number
  ): Observable<any> {
    const endpointUrl = approveRequestId
      ? `${this.apiController}/listPrices/${scenarioId}/?approveRequestId=${approveRequestId}`
      : `${this.apiController}/listPrices/${scenarioId}`;
    return this.api.get(endpointUrl);
  }

  update<T>(price: Price): Observable<any> {
    return this.api.post(`${this.apiController}/update`, price);
  }

  listPriceTrend<T>(
    scenarioId: number,
    productId: number,
    formId: number,
    channelId: number,
    actuals: number,
    period: number,
    currencyId: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/listPriceTrend/${scenarioId}/${productId}/${formId}/${channelId}/${actuals}/${period}/${currencyId}`
    );
  }

  listPriceProducts<T>(scenarioId: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/listPriceProducts/${scenarioId}`,
      undefined
    );
  }

  listPriceChannels<T>(scenarioId: number, productId: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/listPriceChannels/${scenarioId}/${productId}`,
      undefined
    );
  }

  listPriceForms<T>(scenarioId: number, productId: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/listPriceForms/${scenarioId}/${productId}`,
      undefined
    );
  }
}
