import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule, DecimalPipe, DatePipe } from "@angular/common";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { DemoMaterialModule } from "@planard/common/demo-material-module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AdminPlanningRoutes } from "./admin-planning.routing";
import { AddScenarioComponent } from "./scenario/add/add-scenario.component";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductFamilyService } from "@planard/@core/backend/service/product-family.service";
import { ProductsApi } from "@planard/@core/backend/api/products.api";
import { ProductFamilyApi } from "@planard/@core/backend/api/product-family.api";
import { ScenarioApi } from "@planard/@core/backend/api/scenario.api";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { CostsApi } from "@planard/@core/backend/api/costs.api";
import { AccountsApi } from "@planard/@core/backend/api/accounts.api";
import { AccountsService } from "@planard/@core/backend/service/accounts.service";
import { PipesModule } from "@planard/@core/pipes/pipes.module";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import { BusinessUnitApi } from "@planard/@core/backend/api/business-unit.api";
import { GuidanceComponent } from "@planard/admin/planning/guidance/guidance.component";
import { GuidanceApi } from "@planard/@core/backend/api/guidance.api";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { PlanningAddComponent } from "@planard/admin/planning/guidance/planning-edit/add/planning-add.component";
import { TargetAddComponent } from "@planard/admin/planning/guidance/target-edit/add/target-add.component";
import { TimelineApi } from "@planard/@core/backend/api/timeline.api";
import { TimelineService } from "@planard/@core/backend/service/timeline.service";
import { TimelineAddComponent } from "@planard/admin/planning/timeline/add/timeline-add.component";
import { DepartmentService } from "@planard/@core/backend/service/department.service";
import { DepartmentApi } from "@planard/@core/backend/api/department.api";
import { ActualsComponent } from "@planard/admin/planning/actuals/actuals.component";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { ActualsApi } from "@planard/@core/backend/api/actuals.api";
import { FxRateAddComponent } from "./guidance/fx-rate-edit/add/fx-rate-add.component";
import { DeadlineAddComponent } from "./guidance/deadline-edit/add/deadline-add.component";
import { InflationRateAddComponent } from "./guidance/inflation-rate-edit/add/inflation-rate-add.component";
import { PlanningCycleNameAddComponent } from "./guidance/planning-cycle-name-edit/add/planning-cycle-name-add.component";
import { KeyContactsAddComponent } from "./guidance/key-contacts-edit/add/key-contacts-add.component";
import { FaqAddComponent } from "./guidance/faq-edit/add/faq-add.component";
import { FaqAnswerComponent } from "./guidance/faq-edit/answer/faq-answer.component";
import { FaqService } from "@planard/@core/backend/service/faq.service";
import { FaqApi } from "@planard/@core/backend/api/faq.api";
import { ScenarioNewComponent } from "./scenario/scenario-new.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { TimelineNewComponent } from "./timeline/timeline-new.component";
import { MapleModule } from "@planard/maple/maple.module";
import { PlanningEditNewComponent } from "./guidance/planning-edit/planning-edit-new.component";
import { TargetEditNewComponent } from "./guidance/target-edit/target-edit-new.component";
import { KeyContactEditNewComponent } from "./guidance/key-contacts-edit/key-contact-edit-new.component";
import { InflationRateEditNewComponent } from "./guidance/inflation-rate-edit/inflation-rate-edit-new.component";
import { FxRateEditNewComponent } from "./guidance/fx-rate-edit/fx-rate-edit-new.component";
import { FaqEditNewComponent } from "./guidance/faq-edit/faq-edit-new.component";
import { GranularityAddComponent } from "./guidance/granularity/add/granularity-add.component";
import { AgGridModule } from "ag-grid-angular";
import { ActualsExportComponent } from "./actuals/export/actuals-export.component";
import { ActualsImportComponent } from "./actuals/import/actuals-import.component";
import { UserTaskApi } from "@planard/@core/backend/api/user-task.api";
import { UserTaskService } from "@planard/@core/backend/service/user-task.service";
import { UserTaskComponent } from "../planning/user-task/user-task.component";
import { UserTaskUpdateComponent } from "../planning/user-task/update/usertask-update.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { TranslateModule } from "@ngx-translate/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from "@angular/material-moment-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";

const API = [
  ProductsApi,
  ProductFamilyApi,
  ScenarioApi,
  CostsApi,
  HttpService,
  AccountsApi,
  BusinessUnitApi,
  DepartmentApi,
  GuidanceApi,
  TimelineApi,
  ActualsApi,
  FaqApi,
  UserTaskApi,
];

const SERVICES = [
  ProductsService,
  ProductFamilyService,
  ScenarioService,
  CostService,
  AccountsService,
  BusinessUnitService,
  DepartmentService,
  GuidanceService,
  TimelineService,
  ActualsService,
  FaqService,
  UserTaskService,
];

@NgModule({
  imports: [
    PipesModule,
    NgxDatatableModule,
    CommonModule,
    MapleModule,
    ScrollingModule,
    FlexLayoutModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    TranslateModule,
    RouterModule.forChild(AdminPlanningRoutes),
    AgGridModule.withComponents([]),
    MatDatepickerModule,
    MatMomentDateModule,
  ],
  declarations: [
    AddScenarioComponent,
    GuidanceComponent,
    PlanningAddComponent,
    PlanningEditNewComponent,
    TargetAddComponent,
    TargetEditNewComponent,
    TimelineAddComponent,
    TimelineNewComponent,
    ActualsComponent,
    ActualsExportComponent,
    ActualsImportComponent,
    FxRateAddComponent,
    DeadlineAddComponent,
    InflationRateAddComponent,
    PlanningCycleNameAddComponent,
    KeyContactsAddComponent,
    KeyContactEditNewComponent,
    FaqEditNewComponent,
    FaqAddComponent,
    FaqAnswerComponent,
    FxRateEditNewComponent,
    InflationRateEditNewComponent,
    FaqAnswerComponent,
    ScenarioNewComponent,
    GranularityAddComponent,
    UserTaskComponent,
    UserTaskUpdateComponent,
  ],
  providers: [
    ...API,
    ...SERVICES,
    DecimalPipe,
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  entryComponents: [UserTaskUpdateComponent],
})
export class AdminPlanningModule {}
