import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ProcessResult } from "@planard/@core/entities/process-result";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { NotificationService } from "@planard/@core/backend/service/notification.service";
import { Notification } from "@planard/@core/entities/notification";
@Component({
  templateUrl: "./notification.component.html",
})
export class NotificationComponent implements OnInit {
  notification: Notification;
  rows = [];

  loadingIndicator = true;
  processResult: ProcessResult;

  displayedColumns: string[] = ["description", "startDate", "endDate"];
  dataSource: MatTableDataSource<Notification>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public toastrService: ToastrService,
    public notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.getData();
    setTimeout(() => {
      this.loadingIndicator = false;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 1500);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  getData() {
    this.notificationService.getAllNotifications().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);
      this.rows = data;
    });
  }
}
