import { Observable } from "rxjs";
import { Sales } from "@planard/@core/entities/planning/sales";

export abstract class SalesData {
  abstract listPrices(scenarioId: number): Observable<Sales[]>;

  abstract add(sales: Sales): Observable<any>;

  abstract add4Excel(excelFile: any): Observable<any>;

  abstract update(sales: Sales): Observable<any>;

  abstract getTemplate(): Observable<any>;
}
