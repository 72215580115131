import { Routes } from "@angular/router";
import { NarrativesNewComponent } from "./narratives/narratives-new.component";
import { PlanComponent } from "./plan/plan.component";
import { CentralPlanComponent } from "./centralPlan/centralPlan.component";
import { PlanApproverComponent } from "./plan-approver/plan-approver.component";
import { CentralPlanApproverComponent } from "./centralPlan-approver/centralPlan-approver.component";
import { CostApprovalsComponent } from "./cost-approvals/cost-approvals.component";
import { RiskOpportunitiesNewComponent } from "../sales/risk-opportunities/risk-opportunities-new.component";

export const PlanningCostsRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "narratives",
        component: NarrativesNewComponent,
        data: {
          title: "Narratives",
          urls: [{ title: "Narratives", url: "/narratives" }],
        },
      },
      {
        path: "plan",
        component: PlanComponent,
        data: {
          title: "Plan",
          urls: [{ title: "Plan", url: "/plan" }],
        },
      },
      {
        path: "centralPlan",
        component: CentralPlanComponent,
        data: {
          title: "Plan (Central Cost)",
          urls: [{ title: "Plan (Central Cost)", url: "/centralPlan" }],
        },
      },
      {
        path: "cost-approvals",
        component: CostApprovalsComponent,
        data: {
          title: "Approvals",
          urls: [{ title: "Approval", url: "/cost-approvals" }],
        },
      },
      {
        path: "risk-opps",
        component: RiskOpportunitiesNewComponent,
        data: {
          title: "Risk & Opps",
          urls: [{ title: "Risk & Opps", url: "risk-opps" }],
        },
      },      {
        path: "plan-approver/:requestId",
        component: PlanApproverComponent,
      },
      {
        path: "centralPlan-approver/:requestId",
        component: CentralPlanApproverComponent,
      },
    ],
  },
];
