import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Products } from "@planard/@core/entities/product";
import { BusinessUnit } from "@planard/@core/entities/businessUnit";
import { ProductFamilyService } from "@planard/@core/backend/service/product-family.service";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { ProductFamily } from "@planard/@core/entities/productFamily";
import { Channel } from "@planard/@core/entities/channel";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { TranslateService } from "@ngx-translate/core";
import { MatSelect } from "@angular/material/select";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { removeSpaces } from "@planard/common/formValidator";
@Component({
  templateUrl: "./products-add.component.html",
  styles: [
    `
      .toRightReverse {
        flex: 0 0 5%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
      ::ng-deep .mat-option:first-child .mat-pseudo-checkbox {
        display: none;
      }
    `,
  ],
})
export class ProductsAddComponent implements OnInit {
  product: Products;
  productFamilyList: ProductFamily[];
  productFamilyBuList: ProductFamily[] = [];
  businessUnitList: [];
  productFamily: ProductFamily;
  channelList: Channel[];
  guidanceId: number;
  allChannels: boolean;

  businessUnit: BusinessUnit;

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, removeSpaces]),
    businessUnitId: new FormControl("", [Validators.required]),
    productFamilyId: new FormControl("", [Validators.required]),
    channels: new FormControl(""),
  });
  options: FormGroup;

  constructor(
    public fb: FormBuilder,
    public productsService: ProductsService,
    public channelService: ChannelService,
    private businessUnitService: BusinessUnitService,
    private productFamilyService: ProductFamilyService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public guidanceService: GuidanceService,
    public dialogRef: MatDialogRef<ProductsAddComponent>
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.businessUnitService.listAll().subscribe((result: any) => {
      this.businessUnitList = result;
    });
    this.productFamilyService.listAll().subscribe((result: any) => {
      this.productFamilyList = result;
    });
    this.channelService.listAll().subscribe((result) => {
      this.channelList = result;
    });
    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidanceId = result.id;
    });
    this.form.patchValue({ channels: [] });
  }

  save() {
    if (this.form.valid) {
      this.product = this.form.value;
      this.productsService.add(this.product).subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.dialogRef.close(this.product);
          this.startCycleAuto();
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancelClick() {
    this.dialogRef.close(false);
  }

  ProductFamilySet() {
    this.productFamily = this.form.get("businessUnitId").value;
    this.productFamilyBuList.length = 0;
    for (let pf of Object.keys(this.productFamilyList)) {
      if (this.productFamily === this.productFamilyList[pf].businessUnitId) {
        this.productFamilyBuList.push(this.productFamilyList[pf]);
      }
    }
  }

  selectAll(select: MatSelect) {
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    this.form.controls["channels"].setValue(selectedIds);
    this.allChannels = true;
  }

  selectOne(id: number) {
    if (this.allChannels == true) {
      this.allChannels = false;
      let selectedId = [id];
      this.form.controls["channels"].setValue(selectedId);
    }
  }

  deselectOne(id: number, select: MatSelect) {
    if (this.allChannels == true) {
      this.allChannels = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && id != element.value.id) {
          selectedIds.push(element.value);
        }
      });
      this.form.controls["channels"].setValue(selectedIds);
    }
  }

  deselectAll() {
    this.allChannels = false;
    let selectedIds = [];
    this.form.controls["channels"].setValue(selectedIds);
  }

  startCycleAuto() {
    let body = { guidanceId: this.guidanceId };
    this.guidanceService.startGuidance(body).subscribe((result) => {
      if (result.isSuccess) {
        console.log("guidance started!!!");
      } else {
        console.log("result: " + result.message);
        console.log("guidance cannot started!!!");
      }
    });
  }
}
