import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Faq } from "@planard/@core/entities/faq";
import { FaqService } from "@planard/@core/backend/service/faq.service";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./faq-add.component.html",
})
export class FaqAddComponent {
  faq: Faq;

  form: FormGroup = new FormGroup({
    question: new FormControl("", [Validators.required, removeSpaces]),
    answer: new FormControl(""),
  });
  options: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<FaqAddComponent>,
    public faqService: FaqService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public fb: FormBuilder
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {}

  save() {
    if (this.form.valid) {
      this.faq = this.form.value;
      this.faqService.add(this.faq).subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.dialogRef.close(this.faq);
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
