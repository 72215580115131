import { Routes } from "@angular/router";
import { RiskOpportunitiesNewComponent } from "./risk-opportunities/risk-opportunities-new.component";
import { NarrativesNewComponent } from "./narratives/narratives-new.component";
import { PricesNewComponent } from "./prices/prices-new.component";
import { ForecastNewComponent } from "./forecast/forecast-new.component";
import { PricesApproverComponent } from "./prices-approver/prices-approver.component";
import { ForecastApproverComponent } from "./forecast-approver/forecast-approver.component";
import { SalesApprovalsComponent } from "./sales-approvals/sales-approvals.component";
import { ForecastIncomeApproverComponent } from "./forecast-income-approver/forecast-income-approver.component";

export const PlanningSalesRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "prices",
        component: PricesNewComponent,
        data: {
          title: "Prices",
          urls: [{ title: "Prices", url: "prices" }],
        },
      },
      {
        path: "prices-approver",
        component: PricesApproverComponent,
        data: {
          title: "Prices Approver",
          urls: [{ title: "Prices Approver", url: "prices-approver" }],
        },
      },
      {
        path: "forecast",
        component: ForecastNewComponent,
        data: {
          title: "Forecast",
          urls: [{ title: "Forecast", url: "forecast" }],
        },
      },
      {
        path: "forecast-approver",
        component: ForecastApproverComponent,
        data: {
          title: "Forecast Approver",
          urls: [{ title: "Forecast Approver", url: "forecast-approver" }],
        },
      },
      {
        path: "risk-opps",
        component: RiskOpportunitiesNewComponent,
        data: {
          title: "Risk & Opps",
          urls: [{ title: "Risk & Opps", url: "risk-opps" }],
        },
      },
      {
        path: "narratives",
        component: NarrativesNewComponent,
        data: {
          title: "Narratives",
          urls: [{ title: "Narratives", url: "narratives" }],
        },
      },
      {
        path: "sales-approvals",
        component: SalesApprovalsComponent,
        data: {
          title: "Approvals",
          urls: [{ title: "Approvals", url: "sales-approvals" }],
        },
      },
      {
        path: "prices-approver/:requestId",
        component: PricesApproverComponent,
      },
      {
        path: "forecast-approver/:requestId",
        component: ForecastApproverComponent,
      },
      {
        path: "forecast-income-approver/:requestId",
        component: ForecastIncomeApproverComponent,
      },
    ],
  },
];
