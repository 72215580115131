import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AgGridAngular } from "ag-grid-angular";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { Scenario } from "@planard/@core/entities/scenario";
import { MatDialog } from "@angular/material/dialog";
import { TemplatePortal } from "@angular/cdk/portal";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { fromEvent, Subscription } from "rxjs";
import { take, filter } from "rxjs/operators";
import { PriceService } from "@planard/@core/backend/service/price.service";
import { Price } from "@planard/@core/entities/price";
import { Guidance } from "@planard/@core/entities/guidance";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { ApproverRequest } from "@planard/@core/entities/approverRequest";
import { ApproverRequestService } from "@planard/@core/backend/service/approverRequest.service";
import { TranslateService } from "@ngx-translate/core";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import * as XLSX from "xlsx";
@Component({
  templateUrl: "./prices-new.component.html",
  styleUrls: ["./prices-new.component.scss"],
  providers: [NumberDecimalPipe],
})
export class PricesNewComponent implements OnInit {
  //#region
  @ViewChild("agGrid") agGrid: AgGridAngular;
  private gridApi;
  private gridColumnApi;

  scenarioList: Scenario[];
  scenarioList2Base: Scenario[];
  scenarioCopyList: Scenario[];
  months: string[];
  years: number[];
  yearsCopyActual: number[];
  guidanceList: Guidance[];
  scenarioGuiList: Scenario[] = [];
  guidanceScenarioList: Scenario[] = [];
  percent2coloring: Number = 40;
  cellNote: any; //gösterilecek olan not.
  price: boolean;
  discount: boolean;
  netPrice: boolean;
  scenario: Scenario;
  scenarioId: number;
  copyScenarioId: number;
  guidanceId: number;
  copyPlanningGuidanceId: number;
  currentGuidanceId: number;
  carryForwardClicked: boolean;
  increaseMonthlyClicked: boolean;
  decreaseMonthlyClicked: boolean;
  increaseMonthlyPClicked: boolean;
  decreaseMonthlyPClicked: boolean;
  increaseAllMonthlyClicked: boolean;
  decreaseAllMonthlyClicked: boolean;
  increaseAllMonthlyPClicked: boolean;
  decreaseAllMonthlyPClicked: boolean;
  rulesClicked: boolean;
  colorClicked: boolean;
  changeColorClicked: boolean;
  copyClicked: boolean;
  pasteClicked: boolean;
  copyHeadersClicked: boolean;
  notesClicked: boolean;
  priceNotesClicked: boolean;
  discountNotesClicked: boolean;
  copyActualsClicked: boolean;
  copyScenarioClicked: boolean;
  copyPlannngClicked: boolean;
  colorPopup: boolean = false;
  rulesPopup: boolean = false;
  notesPopup: boolean = false;
  input: boolean;
  notes: boolean;
  guidanceSet: boolean;
  exelPriceData: any;
  exelDiscountData: any;
  salesPriceId: number;
  exelYear: number = 2020;
  exelName: string;
  sheetName: string;
  scenarioName: string;
  //for exel import and read
  file: File;
  filelist: any;
  arrayBuffer: any;
  priceLenght: number;
  value2copy: any;
  increaseValue: number;
  decreaseValue: number;
  increaseValueP: number;
  decreaseValueP: number;
  increaseAllValue: number;
  decreaseAllValue: number;
  increaseAllValueP: number;
  decreaseAllValueP: number;
  noteValue: any;
  guidanceName: any;
  //ekranları ayarlama değerleri ikiside olursa adama seçtiricez.
  isApprover: boolean;
  isPlanner: boolean;
  screenSelect: number; //0 --> price  1 --> approve price
  isRejected: boolean = false;
  rowData: any;
  fullData: any;
  fullData4Import: any;
  status: any; // 0 = submit edilmemiş , 1 = submit edilmiş approve/reject bekliyor, 2 = approve edilmiş, 3 = reject edilmiş
  guidancePlanningPeriodList: GuidancePlanningPeriod[];
  rowDataS: any;
  fullDataS: any;
  file_name: string;
  guidanceGroup: any;
  currenctguidance = [];
  oldguidance = [];
  rejectReason: string;
  isScreenValid: boolean;
  name = "Angular";
  data2Exel: any;
  headData: any; // excel row header
  isRoleValid: boolean;
  x: number;
  y4c: number;
  y4d: number;
  y4n: number;
  year4actual: number;
  currentYear = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  defaultColDef = {
    sortable: false,
    flex: 1,
    minWidth: 80,
    filter: false,
    resizable: true,
    cellEditor: "numericCellEditor",
  };

  components = { numericCellEditor: this.getNumericCellEditor() };
  columnDefs = [];
  loadingTemplate;
  noRowsTemplate;

  isImpactArea;
  productName;

  numberFormat;
  decimalFormat;

  isOpen: boolean = false;
  //#endregion

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public toastrService: ToastrService,
    public priceService: PriceService,
    public approverRequestService: ApproverRequestService,
    public guidanceService: GuidanceService,
    public scenarioService: ScenarioService,
    public dialog: MatDialog,
    private numberDecimal: NumberDecimalPipe,
    public translate: TranslateService,
    private router: Router,
    public authService: AuthService
  ) {
    this.translate.get("agGrid.loading").subscribe((data: any) => {
      this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
    });
    this.translate.get("agGrid.no").subscribe((data: any) => {
      this.noRowsTemplate = `"<span">${data}</span>"`;
    });

    if (this.translate.currentLang == "en") this.productName = "Product";
    if (this.translate.currentLang == "tr") this.productName = "Ürün";

    translate.onLangChange.subscribe((lang) => {
      this.translate.get("agGrid.loading").subscribe((data: any) => {
        this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
      });
      this.translate.get("agGrid.no").subscribe((data: any) => {
        this.noRowsTemplate = `"<span">${data}</span>"`;
      });

      if (this.translate.currentLang == "en") this.productName = "Product";
      if (this.translate.currentLang == "tr") this.productName = "Ürün";

      this.changePrice();
    });

    this.months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();
    this.isRoleValid = this.authService.isPricePlanner();
    if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
    this.isScreenValid = this.authService.pricePlanning();
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceTask").subscribe((data: string) => {
        this.toastrService.error(data);
      });
    }
  }

  ngOnInit() {
    this.scenarioService.listAll().subscribe((result) => {
      this.scenarioList = result;
      result.forEach((element) => {
        if (element.isBase == true) {
          this.scenarioId = element.id;
          this.getPrice();
        }
      });
    });

    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidanceName = result.planningCycleName;
      this.guidanceId = result.id;
      this.currentGuidanceId = result.id;
      this.guidanceService.getPlanningRecord(result.id).subscribe((result) => {
        this.guidancePlanningPeriodList = result;
      });

      this.guidanceService.listAll().subscribe((result) => {
        this.guidanceList = result;
        result.forEach((element) => {
          if (element.id == this.guidanceId) {
            this.currenctguidance.push({
              value: element.id,
              label: element.planningCycleName,
            });
          } else {
            this.oldguidance.push({
              value: element.id,
              label: element.planningCycleName,
            });
          }
        });
      });
    });

    setTimeout(() => {
      this.guidanceGroup = [
        {
          name: "Current Plan", // value yıl olucak
          guidance: this.currenctguidance,
        },
        {
          name: "Old Plans", // value plannig id olucak
          guidance: this.oldguidance,
        },
      ];
    }, 1500);
  }

  addOnClick(event) {
    this.x = event.pageX + 75;
    this.y4c = event.pageY + 150;
    this.y4d = event.pageY + 50;
    this.y4n = event.pageY + 100;
  }

  setGuidanceScenario() {
    this.scenarioService
      .listScenariosByGuidance(this.guidanceId, 0)
      .subscribe((result) => {
        this.scenarioList = result;
      });
    this.guidanceService.getById(this.guidanceId).subscribe((result) => {
      this.guidanceName = result.planningCycleName;
    });
    this.guidanceService
      .getPlanningRecord(this.guidanceId)
      .subscribe((result) => {
        this.guidancePlanningPeriodList = result;
      });
  }

  setClick() {
    this.carryForwardClicked = false;
    this.increaseMonthlyClicked = false;
    this.decreaseMonthlyClicked = false;
    this.increaseMonthlyPClicked = false;
    this.decreaseMonthlyPClicked = false;
    this.increaseAllMonthlyClicked = false;
    this.decreaseAllMonthlyClicked = false;
    this.increaseAllMonthlyPClicked = false;
    this.decreaseAllMonthlyPClicked = false;
    this.rulesClicked = false;
    this.copyClicked = false;
    this.pasteClicked = false;
    this.copyHeadersClicked = false;
    this.notesClicked = false;
    this.priceNotesClicked = false;
    this.discountNotesClicked = false;
    this.copyActualsClicked = false;
    this.copyScenarioClicked = false;
    this.copyPlannngClicked = false;
  }

  onSubmit() {
    if (this.scenarioId == undefined) return;

    let approverRequest = new ApproverRequest();
    approverRequest.scenarioId = this.scenarioId;
    approverRequest.type = "0";

    this.approverRequestService
      .submit(approverRequest)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridSet() {
    // setTimeout(() => {
    //   this.autoSizeAll(true);
    // }, 1000);
  }

  getPrice() {
    this.priceService.listPrices(this.scenarioId).subscribe((result) => {
      this.status = result.status;
      this.rejectReason =
        result.rejectReason != null ? result.rejectReason : "";

      this.isImpactArea = result.isImpactArea;

      this.fullData = result.data;
      this.fullData4Import = [...result.data];

      this.rowData = [...result.data].filter((x) => x.isVisible);

      var neg = [];
      var pos = [];

      neg = result.years.filter((x) => x < 0).sort((a, b) => b - a);
      pos = result.years.filter((x) => x > 0).sort((a, b) => a - b);

      this.years = [...neg, ...pos];
      this.yearsCopyActual = [...neg.map((x) => x * -1), ...[this.currentYear]];
      this.year4actual = this.yearsCopyActual[0];
      this.priceLenght = this.fullData.length;

      this.changePrice();
      this.setExpandable();
    });
  }

  setExpandable() {
    for (let line of this.fullData) {
      var id = line.id;
      var isParent = false;
      for (let lin of this.fullData) {
        if (id == lin.parentId) {
          isParent = true;
        }
      }
      line.expandable = isParent;
    }
  }

  getProductDef() {
    return {
      headerName: this.productName,
      field: "product",
      /*lockPosition: true,*/
      pinned: "left",
      editable: this.cellEditable,
      minWidth: 150,
      visible: false,
      cellRenderer: this.productCellRenderer,
    };
  }

  cellEditable(params) {
    return false;
  }

  onColumnSpread(event: any) {
    let data4months = event.data;
    let newValue: number = event.value;

    let type = event.colDef.cellType;
    let year = event.colDef.cellYear;
    let month = event.colDef.cellMonth ? event.colDef.cellMonth : 0;

    if (month != 0) {
      for (let row of Object.keys(this.fullData)) {
        if (
          this.fullData[row].productId == data4months.productId &&
          this.fullData[row].productFormId == data4months.productFormId &&
          this.fullData[row].productChannelId == data4months.productChannelId
        ) {
          this.fullData[row][`${type}col_${year}_${month}`] = newValue;

          this.guidancePlanningPeriodList.forEach((element) => {
            if (element.year == year && element.planningType == "Monthly") {
              var startMonth = element.startMonth;

              let currentYear = new Date(
                new Date().toLocaleString("en-US", {
                  timeZone: "Europe/Berlin",
                })
              ).getFullYear();

              if (year != currentYear) {
                let total = 0;
                for (var mnth = startMonth; mnth < 13; mnth++) {
                  total += Number(
                    this.fullData[row][`${type}col_${year}_${mnth}`]
                  );
                }
                this.fullData[row][`${type}total_${year}`] =
                  total / (12 - (startMonth - 1));

                if (type == "p" || type == "d") {
                  this.fullData[row][`ncol_${year}_${month}`] =
                    this.fullData[row][`pcol_${year}_${month}`] *
                    ((100 - this.fullData[row][`dcol_${year}_${month}`]) / 100);
                  let total = 0;
                  for (var mnth = startMonth; mnth < 13; mnth++) {
                    total += Number(this.fullData[row][`ncol_${year}_${mnth}`]);
                  }
                  this.fullData[row][`ntotal_${year}`] =
                    total / (12 - (startMonth - 1));
                } else {
                  this.fullData[row][`dcol_${year}_${month}`] =
                    (this.fullData[row][`pcol_${year}_${month}`] -
                      this.fullData[row][`ncol_${year}_${month}`]) /
                    this.fullData[row][`pcol_${year}_${month}`];
                  let total = 0;
                  for (var mnth = startMonth; mnth < 13; mnth++) {
                    total += Number(this.fullData[row][`dcol_${year}_${mnth}`]);
                  }
                  this.fullData[row][`dtotal_${year}`] =
                    total / (12 - (startMonth - 1));
                }
                return true;
              } else {
                let total = 0;
                for (let mnth = 1; mnth < 13; mnth++) {
                  total += Number(
                    this.fullData[row][`${type}col_${year}_${mnth}`]
                  );
                }
                this.fullData[row][`${type}total_${year}`] = total / 12;

                if (type == "p" || type == "d") {
                  this.fullData[row][`ncol_${year}_${month}`] =
                    this.fullData[row][`pcol_${year}_${month}`] *
                    ((100 - this.fullData[row][`dcol_${year}_${month}`]) / 100);
                  let total = 0;
                  for (let mnth = 1; mnth < 13; mnth++) {
                    total += Number(this.fullData[row][`ncol_${year}_${mnth}`]);
                  }
                  this.fullData[row][`ntotal_${year}`] = total / 12;
                } else {
                  this.fullData[row][`dcol_${year}_${month}`] =
                    (this.fullData[row][`pcol_${year}_${month}`] -
                      this.fullData[row][`ncol_${year}_${month}`]) /
                    this.fullData[row][`pcol_${year}_${month}`];
                  let total = 0;
                  for (let mnth = 1; mnth < 13; mnth++) {
                    total += Number(this.fullData[row][`dcol_${year}_${mnth}`]);
                  }
                  this.fullData[row][`dtotal_${year}`] = total / 12;
                }
                return true;
              }
            }
          });
        }
      }
      let rows = [...this.fullData];
      rows = rows.filter((row) => row.isVisible || row.parentId == 0);
      this.gridApi.setRowData(rows);
    } else {
      for (let row of Object.keys(this.fullData)) {
        if (
          this.fullData[row].productId == data4months.productId &&
          this.fullData[row].productFormId == data4months.productFormId &&
          this.fullData[row].productChannelId == data4months.productChannelId
        ) {
          this.fullData[row][`${type}total_${year}`] = Number(newValue);

          for (var monthYearly = 1; monthYearly < 13; monthYearly++) {
            this.fullData[row][`${type}col_${year}_${monthYearly}`] =
              Number(newValue);
            if (type == "p" || type == "d") {
              this.fullData[row][`ncol_${year}_${monthYearly}`] =
                (this.fullData[row][`pcol_${year}_${monthYearly}`] *
                  (100 - this.fullData[row][`dcol_${year}_${monthYearly}`])) /
                100;
            } else {
              this.fullData[row][`dcol_${year}_${monthYearly}`] =
                (this.fullData[row][`pcol_${year}_${monthYearly}`] -
                  this.fullData[row][`ncol_${year}_${monthYearly}`]) /
                this.fullData[row][`pcol_${year}_${monthYearly}`];
            }
          }
          if (type == "p" || type == "d") {
            this.fullData[row][`ntotal_${year}`] = 0;
            let total = 0;
            for (var monthYearly = 1; monthYearly < 13; monthYearly++) {
              total += Number(
                this.fullData[row][`ncol_${year}_${monthYearly}`]
              );
            }
            this.fullData[row][`ntotal_${year}`] = total / 12;
          } else {
            this.fullData[row][`dtotal_${year}`] = 0;
            let total = 0;
            for (var monthYearly = 1; monthYearly < 13; monthYearly++) {
              total += this.fullData[row][`dcol_${year}_${monthYearly}`];
            }
            this.fullData[row][`dtotal_${year}`] = total / 12;
          }
          console.log(this.fullData[row]);
        }
      }
      let rows = [...this.fullData];
      rows = rows.filter((row) => row.isVisible || row.parentId == 0);
      this.gridApi.setRowData(rows);
    }
    this.bringFocusBack();
    this.gridApi.refreshCells({
      force: true,
    });
  }

  productCellRenderer(params) {
    let style = "";
    if (params.data.parentId != 0) style = 'style="padding-left: 10px;"';
    if (params.data.expandable && params.data.selected) {
      if (
        params.data[`productChannelId`] == null &&
        params.data[`productFormId`] != null
      ) {
        return (
          `<span class="ag-icon ag-icon-tree-open" ` +
          style +
          `></span> ` +
          `<span style="color: green;">` +
          params.value +
          `</span>` +
          ``
        );
      } else {
        return (
          `<span class="ag-icon ag-icon-tree-open" ` +
          style +
          `></span> ` +
          params.value +
          ``
        );
      }
    } else if (params.data.expandable) {
      if (
        params.data[`productChannelId`] == null &&
        params.data[`productFormId`] != null
      ) {
        return (
          `<span class="ag-icon ag-icon-contracted" ` +
          style +
          `></span> ` +
          `<span style="color: green;">` +
          params.value +
          `</span>` +
          ``
        );
      } else {
        return (
          `<span class="ag-icon ag-icon-contracted" ` +
          style +
          `></span> ` +
          params.value +
          ``
        );
      }
    } else if (!params.data.expandable) {
      if (
        params.data[`productChannelId`] == null &&
        params.data[`productFormId`] == null
      ) {
        // product has no child
        return `<span style="padding-left: 20px;"></span>` + params.value + ``;
      } else if (
        params.data[`productFormId`] != null &&
        params.data[`productChannelId`] == null
      ) {
        // form has no child
        return (
          `<span style="padding-left: 30px;"></span>` +
          `<span style="color: green;">` +
          params.value +
          `</span>` +
          ``
        );
      } else if (params.data[`productChannelId`] != null) {
        return (
          `<span style="padding-left: 40px;"></span>` +
          `<span style="color: blue;">` +
          params.value +
          `</span>`
        );
      }
    }
    return `<span` + style + `></span> ` + params.value + ``;
  }

  getYearColumnDef(type, year) {
    let numberDecimal = this.numberDecimal;
    let numberFormat = this.numberFormat;
    let decimalFormat = this.decimalFormat;

    let rate = Number(this.percent2coloring) / 100;
    let rate2diff = (100 - Number(this.percent2coloring)) / 100;

    var name = this.guidanceName;
    let guidanceId = this.guidanceId;

    let isScreenValid = this.isScreenValid;
    let currentGuidanceId = this.currentGuidanceId;

    var isYearly: boolean = false;
    let isImpactArea = this.isImpactArea;

    let yearIndex;
    let years = this.years;
    let status = this.status;

    for (let index in this.years) {
      if (this.years[index] == year) yearIndex = Number(index);
    }

    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Yearly") {
        isYearly = true;
      }
    });

    if (!isYearly) {
      //monthly
      return {
        headerName: `${year > 0 ? name : ""} ${
          year < 0 ? "A`" + year * -1 : year
        }`,
        minWidth: 150,
        copyHeadersToClipboard: true,
        suppressClipboardPaste: true,
        children: [
          {
            headerName: "Average",
            editable: false,
            columnGroupShow: "closed",
            type: "rightAligned",
            valueGetter: function (params) {
              return Number(params.data[`${type}total_${year}`]).toFixed(2);
            },
            cellStyle: function (params) {
              if (
                Number(params.data[`${type}total_${year}`]) <
                  Number(params.data[`${type}total_${years[yearIndex - 1]}`]) *
                    rate2diff &&
                params.data.expandable != true &&
                params.data.product != "TOTAL : "
              )
                return { color: "black", backgroundColor: "#fff0ee" };
              else if (
                Number(params.data[`${type}total_${year}`]) >
                  Number(
                    params.data[`${type}total_${years[yearIndex - 1]}`] +
                      params.data[`${type}total_${years[yearIndex - 1]}`] * rate
                  ) &&
                params.data.expandable != true &&
                params.data.product != "TOTAL : "
              )
                return { color: "black", backgroundColor: "#f3fff3" };
              if (params.data.expandable == true)
                return {
                  color: "#f6f6f6",
                  backgroundColor: "#f6f6f6",
                  type: "rightAligned",
                };
              if (params.data.isImpactedProduct == false)
                return { backgroundColor: "#f6f6f6" };
              if (isImpactArea == false) return { backgroundColor: "#f6f6f6" };
              return {
                color: "initial",
                backgroundColor: "initial",
                type: "rightAligned",
              };
            },
            valueFormatter: function (params) {
              if (params.value == undefined || params.value == "NaN") {
                return "0.00";
              }
              if (type == "d") {
                return (
                  "%" +
                  numberDecimal.transform(
                    params.value,
                    decimalFormat,
                    numberFormat
                  )
                );
              }
              return numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            },
          },
          this.getMonthColumnDef(type, year, 1),
          this.getMonthColumnDef(type, year, 2),
          this.getMonthColumnDef(type, year, 3),
          this.getMonthColumnDef(type, year, 4),
          this.getMonthColumnDef(type, year, 5),
          this.getMonthColumnDef(type, year, 6),
          this.getMonthColumnDef(type, year, 7),
          this.getMonthColumnDef(type, year, 8),
          this.getMonthColumnDef(type, year, 9),
          this.getMonthColumnDef(type, year, 10),
          this.getMonthColumnDef(type, year, 11),
          this.getMonthColumnDef(type, year, 12),
          {
            headerName: "Average",
            minWidth: 150,
            copyHeadersToClipboard: true,
            suppressClipboardPaste: true,
            editable: false,
            columnGroupShow: "open",
            cellYear: year,
            cellType: type,
            type: "rightAligned",
            valueGetter: function (params) {
              return Number(params.data[`${type}total_${year}`]).toFixed(2);
            },
            cellStyle: function (params) {
              if (params.data.product == "TOTAL : ")
                return { color: "#ff7500" };
              else if (params.data.expandable == true)
                return { color: "#f6f6f6", backgroundColor: "#f6f6f6" };
              else if (params.data.isImpactedProduct == false)
                return { backgroundColor: "#f6f6f6" };
              else if (isImpactArea == false)
                return { backgroundColor: "#f6f6f6" };
              else return { backgroundColor: "#f6f6f6" };
            },
            valueFormatter: function (params) {
              if (params.value == undefined || params.value == "NaN")
                return "0.00";
              if (type == "d")
                return (
                  "%" +
                  numberDecimal.transform(
                    params.value,
                    decimalFormat,
                    numberFormat
                  )
                );
              return numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            },
          },
        ],
      };
    } else {
      //yearly
      return {
        headerName: `${year > 0 ? name : ""} ${
          year < 0 ? "A`" + year * -1 : year
        }`,
        children: [
          {
            headerName: "Average",
            minWidth: 150,
            copyHeadersToClipboard: true,
            suppressClipboardPaste: true,
            type: "rightAligned",
            cellYear: year,
            cellType: type,
            editable: function (params) {
              if (!isImpactArea) return false;
              if (!isScreenValid) return false;
              if (guidanceId != currentGuidanceId) return false;
              if (year < 0) return false;
              if (params.data.expandable) return false;
              if (params.data.isImpactedProduct == false) return false;
              if (type == "n") return false;
              if (params.data.isSubmit == true && status != "Rejected")
                return false;
              if (params.data.product == "TOTAL : ") return false;
              return true;
            },
            columnGroupShow: "closed",
            valueGetter: function (params) {
              return Number(params.data[`${type}total_${year}`]).toFixed(2);
            },
            valueSetter: function (params) {
              params.data[`${type}total_${year}`] = Number(params.newValue);
            },
            cellStyle: function (params) {
              if (
                Number(params.data[`${type}total_${year}`]) <
                  Number(params.data[`${type}total_${years[yearIndex - 1]}`]) *
                    rate2diff &&
                params.data.expandable != true &&
                params.data.product != "TOTAL : "
              )
                return { color: "black", backgroundColor: "#fff0ee" };
              else if (
                Number(params.data[`${type}total_${year}`]) >
                  Number(params.data[`${type}total_${years[yearIndex - 1]}`]) +
                    Number(
                      params.data[`${type}total_${years[yearIndex - 1]}`]
                    ) *
                      rate &&
                params.data.expandable != true &&
                params.data.product != "TOTAL : "
              )
                return { color: "black", backgroundColor: "#f3fff3" };
              if (params.data.isImpactedProduct == false)
                return { backgroundColor: "#f6f6f6" };
              if (isImpactArea == false) return { backgroundColor: "#f6f6f6" };
              if (params.data.expandable == true)
                return { color: "#f6f6f6", backgroundColor: "#f6f6f6" };
              return { color: "initial", backgroundColor: "initial" };
            },
            valueFormatter: function (params) {
              if (params.value == undefined || params.value == "NaN")
                return "0.00";
              if (type == "d")
                return (
                  "%" +
                  numberDecimal.transform(
                    params.value,
                    decimalFormat,
                    numberFormat
                  )
                );
              return numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            },
          },
        ],
      };
    }
  }

  getMonthColumnDef(type, year, month) {
    let numberDecimal = this.numberDecimal;
    let numberFormat = this.numberFormat;
    let decimalFormat = this.decimalFormat;

    let rate = Number(this.percent2coloring) / 100;
    let rate2diff = (100 - Number(this.percent2coloring)) / 100;

    let guidanceId = this.guidanceId;
    let isScreenValid = this.isScreenValid;
    let currentGuidanceId = this.currentGuidanceId;

    var startMonth: any = 1;
    let guidancePlanningPeriodList = this.guidancePlanningPeriodList;

    let status = this.status;
    let isImpactArea = this.isImpactArea;

    guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Monthly") {
        startMonth = element.startMonth;
      }
    });

    return {
      headerName: this.months[month - 1],
      minWidth: 85,
      copyHeadersToClipboard: true,
      suppressClipboardPaste: true,
      type: "rightAligned",
      columnGroupShow: "open",
      tooltipField: `${type}not_${year}_${month}`,
      cellMonth: month,
      cellYear: year,
      cellType: type,
      valueGetter: function (params) {
        return Number(params.data[`${type}col_${year}_${month}`]).toFixed(2);
      },
      valueSetter: function (params) {
        params.data[`${type}col_${year}_${month}`] = Number(params.newValue);
      },
      editable: function (params) {
        if (!isImpactArea) return false;
        if (!isScreenValid) return false;
        if (guidanceId != currentGuidanceId) return false;
        if (year < 0) return false;
        if (month < startMonth) return false;
        if (params.data.expandable) return false;
        if (params.data.isImpactedProduct == false) return false;
        if (type == "n") return false;
        if (params.data.isSubmit == true && status != "Rejected") return false;
        if (params.data.product == "TOTAL : ") return false;
        return true;
      },
      cellStyle: function (params) {
        // düşüş
        if (
          Number(params.data[`${type}col_${year}_${month}`]) <
            Number(params.data[`${type}col_${year}_${month - 1}`]) *
              rate2diff &&
          params.data.expandable != true &&
          month - 1 >= startMonth
        ) {
          if (params.data[`${type}not_${year}_${month}`] != null) {
            return {
              color: "black",
              backgroundColor: "#fff0ee",
              border: "solid",
              borderTopWidth: "0.0px",
              borderRightWidth: "0.0px",
              borderLeftWidth: "0.0px",
              borderBottomWidth: "2px",
              borderColor: "#BB8FCE",
            };
          }
          return { color: "black", backgroundColor: "#fff0ee" };
        }
        // yükselme
        else if (
          Number(params.data[`${type}col_${year}_${month}`]) >
            Number(params.data[`${type}col_${year}_${month - 1}`]) +
              Number(params.data[`${type}col_${year}_${month - 1}`]) * rate &&
          params.data.expandable != true &&
          month - 1 >= startMonth
        ) {
          if (params.data[`${type}not_${year}_${month}`] != null) {
            return {
              color: "black",
              backgroundColor: "#f3fff3",
              border: "solid",
              borderTopWidth: "0.0px",
              borderRightWidth: "0.0px",
              borderLeftWidth: "0.0px",
              borderBottomWidth: "2px",
              borderColor: "#BB8FCE",
            };
          }
          return { color: "black", backgroundColor: "#f3fff3" };
        }
        // note
        else if (
          params.data[`${type}not_${year}_${month}`] != null &&
          params.data.expandable != true
        )
          return {
            border: "solid",
            borderTopWidth: "0.0px",
            borderRightWidth: "0.0px",
            borderLeftWidth: "0.0px",
            borderBottomWidth: "2px",
            borderColor: "#BB8FCE",
          };
        // others
        else if (params.data.expandable == true)
          return { color: "#f6f6f6", backgroundColor: "#f6f6f6" };
        else if (params.data.isImpactedProduct == false)
          return { backgroundColor: "#f6f6f6" };
        else if (
          params.data[`productChannelId`] == null &&
          params.data[`productFormId`] == null &&
          params.data.expandable == true
        )
          return { color: "#f6f6f6", backgroundColor: "#f6f6f6" };
        return { color: "initial", backgroundColor: "initial" };
      },
      valueFormatter: function (params) {
        if (params.value == undefined || params.value == "NaN") {
          return "0.00";
        }
        if (type == "d")
          return (
            "%" +
            numberDecimal.transform(params.value, decimalFormat, numberFormat)
          );
        return numberDecimal.transform(
          params.value,
          decimalFormat,
          numberFormat
        );
      },
    };
  }

  formatNumber(number) {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  getSelectedRows() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const selectedDataStringPresentation = selectedData
      .map((node) => node.make + " " + node.model)
      .join(", ");
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  }

  changeColor() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    let type;

    if (this.price == true) type = "p";
    else if (this.discount == true) type = "d";
    else type = "n";

    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef(type, year));
    });
    this.gridApi.setColumnDefs(this.columnDefs);

    this.translate
      .get("entryScreens.coloringRate")
      .subscribe((data: string) => {
        this.toastrService.warning(data);
      });
  }

  changePrice() {
    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef("p", year));
    });
    this.gridApi.setColumnDefs(this.columnDefs);
    this.price = true;
    this.discount = false;
    this.netPrice = false;
  }

  changeDiscount() {
    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef("d", year));
    });
    this.gridApi.setColumnDefs(this.columnDefs);
    this.discount = true;
    this.price = false;
    this.netPrice = false;
  }

  changeNetPrice() {
    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef("n", year));
    });
    this.gridApi.setColumnDefs(this.columnDefs);
    this.netPrice = true;
    this.price = false;
    this.discount = false;
  }

  save() {
    let status = this.status;
    let data = [];
    let startMonths = [];

    let currentYear = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear();

    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year <= currentYear && element.planningType == "Monthly") {
        startMonths.push({
          startMonth: element.startMonth,
          year: element.year,
        });
      }
    });

    this.fullData.forEach((rowNode) => {
      if (
        (rowNode.isSubmit == false || status == "Rejected") &&
        rowNode.isImpactedProduct == true
      ) {
        startMonths.forEach((ele) => {
          for (let month = 1; month < ele.startMonth; month++) {
            if (rowNode[`pcol_${ele.year}_${month}`])
              rowNode[`pcol_${ele.year}_${month}`] = 0;
            if (rowNode[`dcol_${ele.year}_${month}`])
              rowNode[`dcol_${ele.year}_${month}`] = 0;
            if (rowNode[`ncol_${ele.year}_${month}`])
              rowNode[`ncol_${ele.year}_${month}`] = 0;
          }
        });
        data.push(rowNode);
      }
    });

    let price = new Price();
    price.rows = data;

    this.priceService.update(price).subscribe((backendResult) => {
      if (backendResult.isSuccess) {
        this.translate
          .get(`messages.${backendResult.messageId}`)
          .subscribe((data: any) => {
            this.toastrService.success(data);
          });
      } else {
        this.translate
          .get(`messages.${backendResult.messageId}`)
          .subscribe((data: any) => {
            this.toastrService.error(data);
          });
      }
    });
  }

  isEditable(data, month, year, type): Boolean {
    var startMonth = 0;
    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Monthly") {
        startMonth = element.startMonth;
      }
    });

    if (!this.isImpactArea) return false;
    if (!this.isScreenValid) return false;
    if (this.guidanceId != this.currentGuidanceId) return false;
    if (year < 0) return false;
    if (month < startMonth) return false;
    if (data.expandable) return false;
    if (data.isImpactedProduct == false) return false;
    if (type == "n") return false;
    if (data.isSubmit == true && this.status != "Rejected") return false;
    if (data.product == "TOTAL : ") return false;
    return true;
  }

  private bringFocusBack() {
    let cell = this.gridApi.getFocusedCell();
    if (cell) {
      this.gridApi.setFocusedCell(cell.rowIndex, cell.column);
    }
  }

  setScenario() {
    for (let sc of Object.keys(this.scenarioList)) {
      if (this.scenarioId == this.scenarioList[sc].id) {
        this.file_name = "Price-" + this.scenarioList[sc].name + ".xlsx";
        return this.scenarioList[sc].name;
      }
    }
  }

  setExportData() {
    let type = this.price
      ? "p"
      : this.discount
      ? "d"
      : this.netPrice
      ? "n"
      : " ";

    if (type == "p" || type == "d") {
      let emptyRow: any[];
      let scenario: any[] = ["", "Scenario Name : " + " " + this.setScenario()];
      let guide: any[] = [
        "",
        "Excel User Guide : On This Excel Sheet Change Only Prices/Discounts. Do not Remove Any Column or Row and Do not Change Their Names.",
      ];

      let header = ["SalesPriceId", "Name"];

      if (type == "p") {
        this.sheetName = "Sheet Price";
      } else if (type == "d") {
        this.sheetName = "Sheet Discount";
      }

      for (let year of this.years) {
        if (year > 0) {
          header.push(`${year} Jan`);
          header.push(`${year} Feb`);
          header.push(`${year} Mar`);
          header.push(`${year} Apr`);
          header.push(`${year} May`);
          header.push(`${year} Jun`);
          header.push(`${year} Jul`);
          header.push(`${year} Aug`);
          header.push(`${year} Sep`);
          header.push(`${year} Oct`);
          header.push(`${year} Nov`);
          header.push(`${year} Dec`);
        }
      }

      this.data2Exel = [];
      this.data2Exel.push(header);

      this.fullData.forEach((element) => {
        this.salesPriceId = element.id;

        if (element.productFormId != null && element.productChannelId == null) {
          this.exelName = " " + " " + " " + element.product;
        } else if (element.productChannelId != null) {
          this.exelName = " " + " " + " " + " " + " " + " " + element.product;
        } else {
          this.exelName = element.product;
        }

        let row: any[] = [this.salesPriceId, this.exelName];
        for (let year of this.years) {
          if (year > 0) {
            row.push(element[`${type}col_${year}_1`]);
            row.push(element[`${type}col_${year}_2`]);
            row.push(element[`${type}col_${year}_3`]);
            row.push(element[`${type}col_${year}_4`]);
            row.push(element[`${type}col_${year}_5`]);
            row.push(element[`${type}col_${year}_6`]);
            row.push(element[`${type}col_${year}_7`]);
            row.push(element[`${type}col_${year}_8`]);
            row.push(element[`${type}col_${year}_9`]);
            row.push(element[`${type}col_${year}_10`]);
            row.push(element[`${type}col_${year}_11`]);
            row.push(element[`${type}col_${year}_12`]);
          }
        }
        this.data2Exel.push(row);
      });
      this.data2Exel.push(emptyRow);
      this.data2Exel.push(scenario);
      this.data2Exel.push(guide);

      this.export();
    } else {
      this.translate.get("entryScreens.npAvailable").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };

  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2Exel, {
      cellStyles: true,
    });

    ws["!cols"] = [];
    ws["!cols"][0] = { hidden: true };
    ws["!cols"][1] = { wpx: 200 };

    ws["!rows"] = [];
    ws["!rows"][0] = { hpx: 35 };
    ws["!rows"][this.priceLenght + 2] = { hpx: 35 };
    ws["!rows"][this.priceLenght + 3] = { hpx: 35 };

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.sheetName);
    XLSX.writeFile(wb, this.file_name);
  }

  onFileAdd(event: any) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.filelist = [];

      let type = "";
      if (first_sheet_name == "Sheet Price") type = "p";
      else if (first_sheet_name == "Sheet Discount") type = "d";

      if (
        (arraylist.length - 2) % this.priceLenght === 0 &&
        (first_sheet_name == "Sheet Discount" ||
          first_sheet_name == "Sheet Price")
      ) {
        this.fullData4Import = arraylist;
        for (let row in this.fullData) {
          for (let year of this.years) {
            if (
              year > 0 &&
              this.fullData[row].isImpactedProduct == true &&
              !this.fullData[row].expandable
            ) {
              this.guidancePlanningPeriodList.forEach((element) => {
                if (
                  // year <= currentYear &&
                  element.year == year &&
                  element.planningType == "Monthly"
                ) {
                  //MONTHLY
                  let startMonth = element.startMonth;
                  let ArrMo = [
                    "",
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];

                  for (var month = startMonth; month < 13; month++) {
                    this.fullData[row][`${type}col_${year}_${month}`] =
                      this.fullData4Import[row][`${year} ${ArrMo[month]}`];
                    this.fullData[row][`ncol_${year}_${month}`] =
                      (Number(this.fullData[row][`pcol_${year}_${month}`]) *
                        (100 -
                          Number(
                            this.fullData[row][`dcol_${year}_${month}`]
                          ))) /
                      100;
                  }

                  let total = 0;
                  let totaln = 0;
                  for (var mnth = 1; mnth < 13; mnth++) {
                    total += Number(
                      this.fullData[row][`${type}col_${year}_${mnth}`]
                    );
                    totaln += Number(
                      this.fullData[row][`ncol_${year}_${mnth}`]
                    );
                  }
                  this.fullData[row][`${type}total_${year}`] = total / 12;
                  this.fullData[row][`ntotal_${year}`] = totaln / 12;
                } else if (
                  // year >= currentYear &&
                  element.year == year &&
                  element.planningType == "Yearly"
                ) {
                  //YEARLY
                  let ArrMo = [
                    "",
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];

                  for (var mnthh = 1; mnthh < 13; mnthh++) {
                    this.fullData[row][`${type}col_${year}_${mnthh}`] =
                      this.fullData4Import[row][`${year} ${ArrMo[mnthh]}`];
                    this.fullData[row][`ncol_${year}_${mnthh}`] =
                      (Number(this.fullData[row][`pcol_${year}_${mnthh}`]) *
                        (100 -
                          Number(
                            this.fullData[row][`dcol_${year}_${mnthh}`]
                          ))) /
                      100;
                  }

                  let total = 0;
                  let totaln = 0;
                  for (var mnth = 1; mnth < 13; mnth++) {
                    total += Number(
                      this.fullData[row][`${type}col_${year}_${mnth}`]
                    );
                    totaln += Number(
                      this.fullData[row][`ncol_${year}_${mnth}`]
                    );
                  }
                  this.fullData[row][`${type}total_${year}`] = total / 12;
                  this.fullData[row][`ntotal_${year}`] = totaln / 12;
                }
              });
            }
          }
        }

        this.rowData = this.fullData.filter((x) => x.isVisible);
        this.fullData4Import = [...this.fullData];

        this.setExpandable();

        if (type == "p") this.changePrice();
        else this.changeDiscount();
      } else {
        this.translate.get("entryScreens.corrupted").subscribe((data: any) => {
          this.toastrService.error(data);
        });
      }
    };
  }

  onCellClicked(event) {
    if (event.column.colDef.field != "product") {
      return;
    }

    var parentId = event.data.id;
    var rows = [...this.fullData];

    let currentRow = rows.filter((row) => row.id == parentId)[0];
    let isOpen = currentRow.selected;

    let currentRows = rows.filter((row) => row.parentId == parentId);
    currentRows.forEach(function (row) {
      row.isVisible = !isOpen;
      if (isOpen) {
        row.selected = false;
        let subRows = rows.filter((r) => r.parentId == row.id);
        subRows.forEach(function (row) {
          row.isVisible = false;
        });
      }
    });
    currentRow.selected = !isOpen;
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
  }

  openRows() {
    var rows = [...this.fullData];

    this.isOpen = !this.isOpen;
    var value = this.isOpen;

    rows.forEach(function (row) {
      if (row.parentId != 0) {
        row.isVisible = value;
        row.selected = value;
      } else {
        row.selected = value;
      }
    });
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
  }

  currentContextEvent: any;
  onCellContextMenu(event) {
    this.currentContextEvent = event;
  }

  onCopy() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    this.value2copy = this.currentContextEvent.value;
  }

  onPaste() {
    if (this.value2copy == null || this.value2copy == "NaN") return;
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data2copy = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth
      ? this.currentContextEvent.colDef.cellMonth
      : 0;

    if (type != "n") {
      if (cellMonth != 0) {
        data2copy[`${type}col_${year}_${cellMonth}`] = Number(this.value2copy);

        if (data2copy.expandable == true) return;

        this.guidancePlanningPeriodList.forEach((element) => {
          if (element.year == year && element.planningType == "Monthly") {
            let startMonth = element.startMonth;
            let currentYear = new Date(
              new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
            ).getFullYear();

            if (year != currentYear) {
              let total = 0;
              for (var mnth = startMonth; mnth < 13; mnth++) {
                total += Number(data2copy[`${type}col_${year}_${mnth}`]);
              }
              data2copy[`${type}total_${year}`] =
                total / (12 - (startMonth - 1));

              if (type == "p" || type == "d") {
                data2copy[`ncol_${year}_${cellMonth}`] =
                  data2copy[`pcol_${year}_${cellMonth}`] *
                  ((100 - data2copy[`dcol_${year}_${cellMonth}`]) / 100);
                let total = 0;
                for (var mnth = startMonth; mnth < 13; mnth++) {
                  total += Number(data2copy[`ncol_${year}_${mnth}`]);
                }
                data2copy[`ntotal_${year}`] = total / (12 - (startMonth - 1));
              }
            } else {
              let total = 0;
              for (var mnth = 1; mnth < 13; mnth++) {
                total += Number(data2copy[`${type}col_${year}_${mnth}`]);
              }
              data2copy[`${type}total_${year}`] = total / 12;

              if (type == "p" || type == "d") {
                data2copy[`ncol_${year}_${cellMonth}`] =
                  data2copy[`pcol_${year}_${cellMonth}`] *
                  ((100 - data2copy[`dcol_${year}_${cellMonth}`]) / 100);
                let total = 0;
                for (var mnth = 1; mnth < 13; mnth++) {
                  total += Number(data2copy[`ncol_${year}_${mnth}`]);
                }
                data2copy[`ntotal_${year}`] = total / 12;
              }
            }
          }
        });

        this.gridApi.applyTransaction({ update: [data2copy] });
        this.gridApi.redrawRows();
        this.bringFocusBack();
      } else {
        this.translate
          .get("entryScreens.pasteAvailable")
          .subscribe((data: string) => {
            this.toastrService.warning(data);
          });
        return;
      }
    } else {
      this.translate
        .get("entryScreens.npAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }
  }

  onEditNotes() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let note = this.noteValue;
    let data4note = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data4note, cellMonth, year, type);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth)
      data4note[`${type}not_${year}_1`] =
        note.trim() == "" ? null : note.trim();
    else
      data4note[`${type}not_${year}_${cellMonth}`] =
        note.trim() == "" ? null : note.trim();

    this.gridApi.applyTransaction({ update: [data4note] });
    this.gridApi.redrawRows();
    this.bringFocusBack();

    let data = [];
    this.gridApi.forEachNode(function (rowNode) {
      data.push(rowNode.data);
    });

    let price = new Price();
    price.rows = data;

    this.priceService.update(price).subscribe((backendResult) => {
      if (backendResult.isSuccess) {
        this.translate
          .get(`messages.${backendResult.messageId}`)
          .subscribe((data: any) => {
            this.toastrService.success(data);
          });
      } else {
        this.translate
          .get(`messages.${backendResult.messageId}`)
          .subscribe((data: any) => {
            this.toastrService.error(data);
          });
      }
    });
  }

  onEditNotesShow() {
    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    if (cellMonth) this.cellNote = data[`${type}not_${year}_${cellMonth}`];
    else this.cellNote = data[`${type}not_${year}_1`];

    if (this.cellNote != null) return this.cellNote;
    else return "no note..";
  }

  onCarryForward() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year, type);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (type != "n") {
      for (var month = cellMonth + 1; month < 13; month++) {
        data[`${type}col_${year}_${month}`] = Number(
          data[`${type}col_${year}_${cellMonth}`]
        );
      }

      this.refreshRowTotal(data, type, year, month);

      if (type == "p" || type == "d") {
        data[`ntotal_${year}`] = 0;
        for (let mnth = 1; mnth < 13; mnth++) {
          data[`ncol_${year}_${mnth}`] =
            (data[`pcol_${year}_${mnth}`] *
              (100 - data[`dcol_${year}_${mnth}`])) /
            100;
        }
        this.refreshRowTotal(data, "n", year, month);
      } else {
        data[`dtotal_${year}`] = 0;
        for (let mth = 1; mth < 13; mth++) {
          let discount = 0;
          if (data[`pcol_${year}_${mth}`] > 0) {
            discount =
              ((data[`pcol_${year}_${mth}`] - data[`ncol_${year}_${mth}`]) /
                data[`pcol_${year}_${mth}`]) *
              100;
            data[`dcol_${year}_${mth}`] = discount;
          } else {
            data[`dcol_${year}_${mth}`] = 0;
          }
          data[`dtotal_${year}`] += discount;
        }
        this.refreshRowTotal(data, "d", year, month);
      }

      this.gridApi.applyTransaction({ update: [data] });
      this.bringFocusBack();
      return true;
    } else {
      this.translate
        .get("entryScreens.npAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
    }
  }

  onIncreaseAllMonthly(type_) {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year, type);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (type_ == 0) {
      let value = this.increaseAllValue;

      if (type != "n") {
        if (this.increaseAllValue > -1) {
          for (var month = cellMonth; month < 13; month++) {
            data[`${type}col_${year}_${month}`] = Number(
              Number(data[`${type}col_${year}_${month}`]) + Number(value)
            );
          }
          this.refreshRowTotal(data, type, year, month);

          if (type == "p" || type == "d") {
            data[`ntotal_${year}`] = 0;
            for (var mnth = 1; mnth < 13; mnth++) {
              data[`ncol_${year}_${mnth}`] =
                (data[`pcol_${year}_${mnth}`] *
                  (100 - data[`dcol_${year}_${mnth}`])) /
                100;
              this.refreshRowTotal(data, "n", year, month);
            }
          } else {
            data[`dtotal_${year}`] = 0;
            for (var mon = 1; mon < 13; mon++) {
              let discount = 0;
              if (data[`pcol_${year}_${mon}`] > 0) {
                discount =
                  ((data[`pcol_${year}_${mon}`] - data[`ncol_${year}_${mon}`]) /
                    data[`pcol_${year}_${mon}`]) *
                  100;
                data[`dcol_${year}_${mon}`] = discount;
              } else {
                data[`dcol_${year}_${mon}`] = 0;
              }
            }
            this.refreshRowTotal(data, "d", year, month);
          }
          this.gridApi.applyTransaction({ update: [data] });
          this.bringFocusBack();
          return true;
        } else {
          this.translate
            .get("entryScreens.lower0")
            .subscribe((data: string) => {
              this.toastrService.warning(data);
            });
        }
      } else {
        this.translate
          .get("entryScreens.npAvailable")
          .subscribe((data: string) => {
            this.toastrService.warning(data);
          });
      }
    } else {
      let value = this.increaseAllValueP;

      if (type != "n") {
        if (this.increaseAllValueP > -1) {
          for (var month = cellMonth; month < 13; month++) {
            data[`${type}col_${year}_${month}`] = Number(
              Number(data[`${type}col_${year}_${month}`]) +
                (Number(data[`${type}col_${year}_${month}`]) * Number(value)) /
                  100
            );
          }
          this.refreshRowTotal(data, type, year, month);

          if (type == "p" || type == "d") {
            data[`ntotal_${year}`] = 0;
            for (var mnth = 1; mnth < 13; mnth++) {
              data[`ncol_${year}_${mnth}`] =
                (data[`pcol_${year}_${mnth}`] *
                  (100 - data[`dcol_${year}_${mnth}`])) /
                100;
              this.refreshRowTotal(data, "n", year, month);
            }
          } else {
            data[`dtotal_${year}`] = 0;
            for (var mon = 1; mon < 13; mon++) {
              let discount = 0;
              if (data[`pcol_${year}_${mon}`] > 0) {
                discount =
                  ((data[`pcol_${year}_${mon}`] - data[`ncol_${year}_${mon}`]) /
                    data[`pcol_${year}_${mon}`]) *
                  100;
                data[`dcol_${year}_${mon}`] = discount;
              } else {
                data[`dcol_${year}_${mon}`] = 0;
              }
            }
            this.refreshRowTotal(data, "d", year, month);
          }
          this.gridApi.applyTransaction({ update: [data] });
          this.bringFocusBack();
          return true;
        } else {
          this.translate
            .get("entryScreens.lower0")
            .subscribe((data: string) => {
              this.toastrService.warning(data);
            });
        }
      } else {
        this.translate
          .get("entryScreens.npAvailable")
          .subscribe((data: string) => {
            this.toastrService.warning(data);
          });
      }
    }
  }

  onIncreaseMonthly(type_) {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year, type);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (type_ == 0) {
      let value = this.increaseValue;

      if (type != "n") {
        if (this.increaseValue > -1) {
          for (var month = cellMonth + 1; month < 13; month++) {
            data[`${type}col_${year}_${month}`] = Number(
              Number(data[`${type}col_${year}_${month - 1}`]) + Number(value)
            );
          }
          this.refreshRowTotal(data, type, year, month);

          if (type == "p" || type == "d") {
            data[`ntotal_${year}`] = 0;
            for (var mnth = 1; mnth < 13; mnth++) {
              data[`ncol_${year}_${mnth}`] =
                (data[`pcol_${year}_${mnth}`] *
                  (100 - data[`dcol_${year}_${mnth}`])) /
                100;
              this.refreshRowTotal(data, "n", year, month);
            }
          } else {
            data[`dtotal_${year}`] = 0;
            for (var mon = 1; mon < 13; mon++) {
              let discount = 0;
              if (data[`pcol_${year}_${mon}`] > 0) {
                discount =
                  ((data[`pcol_${year}_${mon}`] - data[`ncol_${year}_${mon}`]) /
                    data[`pcol_${year}_${mon}`]) *
                  100;
                data[`dcol_${year}_${mon}`] = discount;
              } else {
                data[`dcol_${year}_${mon}`] = 0;
              }
            }
            this.refreshRowTotal(data, "d", year, month);
          }
          this.gridApi.applyTransaction({ update: [data] });
          this.bringFocusBack();
          return true;
        } else {
          this.translate
            .get("entryScreens.lower0")
            .subscribe((data: string) => {
              this.toastrService.warning(data);
            });
        }
      } else {
        this.translate
          .get("entryScreens.npAvailable")
          .subscribe((data: string) => {
            this.toastrService.warning(data);
          });
      }
    } else {
      let value = this.increaseValueP;

      if (type != "n") {
        if (this.increaseValueP > -1) {
          for (var month = cellMonth + 1; month < 13; month++) {
            data[`${type}col_${year}_${month}`] = Number(
              Number(data[`${type}col_${year}_${month - 1}`]) +
                (Number(data[`${type}col_${year}_${month - 1}`]) *
                  Number(value)) /
                  100
            );
          }
          this.refreshRowTotal(data, type, year, month);

          if (type == "p" || type == "d") {
            data[`ntotal_${year}`] = 0;
            for (var mnth = 1; mnth < 13; mnth++) {
              data[`ncol_${year}_${mnth}`] =
                (data[`pcol_${year}_${mnth}`] *
                  (100 - data[`dcol_${year}_${mnth}`])) /
                100;
              this.refreshRowTotal(data, "n", year, month);
            }
          } else {
            data[`dtotal_${year}`] = 0;
            for (var mon = 1; mon < 13; mon++) {
              let discount = 0;
              if (data[`pcol_${year}_${mon}`] > 0) {
                discount =
                  ((data[`pcol_${year}_${mon}`] - data[`ncol_${year}_${mon}`]) /
                    data[`pcol_${year}_${mon}`]) *
                  100;
                data[`dcol_${year}_${mon}`] = discount;
              } else {
                data[`dcol_${year}_${mon}`] = 0;
              }
            }
            this.refreshRowTotal(data, "d", year, month);
          }
          this.gridApi.applyTransaction({ update: [data] });
          this.bringFocusBack();
          return true;
        } else {
          this.translate
            .get("entryScreens.lower0")
            .subscribe((data: string) => {
              this.toastrService.warning(data);
            });
        }
      } else {
        this.translate
          .get("entryScreens.npAvailable")
          .subscribe((data: string) => {
            this.toastrService.warning(data);
          });
      }
    }
  }

  onDecreaseAllMonthly(type_) {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year, type);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (type_ == 0) {
      let value = this.decreaseAllValue;

      if (type != "n") {
        if (this.decreaseAllValue > -1) {
          for (var month = cellMonth; month < 13; month++) {
            data[`${type}col_${year}_${month}`] =
              Number(data[`${type}col_${year}_${month}`] - value) > 0
                ? Number(data[`${type}col_${year}_${month}`] - value)
                : 0;
          }
          this.refreshRowTotal(data, type, year, month);

          if (type == "p" || type == "d") {
            data[`ntotal_${year}`] = 0;
            for (var mnth = 1; mnth < 13; mnth++) {
              data[`ncol_${year}_${mnth}`] =
                (data[`pcol_${year}_${mnth}`] *
                  (100 - data[`dcol_${year}_${mnth}`])) /
                100;
            }
            this.refreshRowTotal(data, "n", year, mnth);
          } else {
            data[`dtotal_${year}`] = 0;
            for (var mon = 1; mon < 13; mon++) {
              let discount = 0;
              if (data[`pcol_${year}_${mon}`] > 0) {
                discount =
                  ((data[`pcol_${year}_${mon}`] - data[`ncol_${year}_${mon}`]) /
                    data[`pcol_${year}_${mon}`]) *
                  100;
                data[`dcol_${year}_${mon}`] = discount;
              } else {
                data[`dcol_${year}_${mon}`] = 0;
              }
            }
            this.refreshRowTotal(data, "d", year, month);
          }
          this.gridApi.applyTransaction({ update: [data] });
          this.bringFocusBack();
          this.decreaseValue = null;
          return true;
        } else {
          this.translate
            .get("entryScreens.lower0")
            .subscribe((data: string) => {
              this.toastrService.warning(data);
            });
        }
      } else {
        this.translate
          .get("entryScreens.npAvailable")
          .subscribe((data: string) => {
            this.toastrService.warning(data);
          });
      }
    } else {
      let value = this.decreaseAllValueP;

      if (type != "n") {
        if (this.decreaseAllValueP > -1) {
          for (var month = cellMonth; month < 13; month++) {
            data[`${type}col_${year}_${month}`] =
              Number(
                data[`${type}col_${year}_${month}`] -
                  (Number(data[`${type}col_${year}_${month}`]) *
                    Number(value)) /
                    100
              ) > 0
                ? Number(
                    data[`${type}col_${year}_${month}`] -
                      (Number(data[`${type}col_${year}_${month}`]) *
                        Number(value)) /
                        100
                  )
                : 0;
          }
          this.refreshRowTotal(data, type, year, month);

          if (type == "p" || type == "d") {
            data[`ntotal_${year}`] = 0;
            for (var mnth = 1; mnth < 13; mnth++) {
              data[`ncol_${year}_${mnth}`] =
                (data[`pcol_${year}_${mnth}`] *
                  (100 - data[`dcol_${year}_${mnth}`])) /
                100;
            }
            this.refreshRowTotal(data, "n", year, mnth);
          } else {
            data[`dtotal_${year}`] = 0;
            for (var mon = 1; mon < 13; mon++) {
              let discount = 0;
              if (data[`pcol_${year}_${mon}`] > 0) {
                discount =
                  ((data[`pcol_${year}_${mon}`] - data[`ncol_${year}_${mon}`]) /
                    data[`pcol_${year}_${mon}`]) *
                  100;
                data[`dcol_${year}_${mon}`] = discount;
              } else {
                data[`dcol_${year}_${mon}`] = 0;
              }
            }
            this.refreshRowTotal(data, "d", year, month);
          }
          this.gridApi.applyTransaction({ update: [data] });
          this.bringFocusBack();
          this.decreaseValue = null;
          return true;
        } else {
          this.translate
            .get("entryScreens.lower0")
            .subscribe((data: string) => {
              this.toastrService.warning(data);
            });
        }
      } else {
        this.translate
          .get("entryScreens.npAvailable")
          .subscribe((data: string) => {
            this.toastrService.warning(data);
          });
      }
    }
  }

  onDecreaseMonthly(type_) {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year, type);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (type_ == 0) {
      let value = this.decreaseValue;

      if (type != "n") {
        if (this.decreaseValue > -1) {
          for (var month = cellMonth + 1; month < 13; month++) {
            data[`${type}col_${year}_${month}`] =
              Number(data[`${type}col_${year}_${month - 1}`] - value) > 0
                ? Number(data[`${type}col_${year}_${month - 1}`] - value)
                : 0;
          }
          this.refreshRowTotal(data, type, year, month);

          if (type == "p" || type == "d") {
            data[`ntotal_${year}`] = 0;
            for (var mnth = 1; mnth < 13; mnth++) {
              data[`ncol_${year}_${mnth}`] =
                (data[`pcol_${year}_${mnth}`] *
                  (100 - data[`dcol_${year}_${mnth}`])) /
                100;
            }
            this.refreshRowTotal(data, "n", year, mnth);
          } else {
            data[`dtotal_${year}`] = 0;
            for (var mon = 1; mon < 13; mon++) {
              let discount = 0;
              if (data[`pcol_${year}_${mon}`] > 0) {
                discount =
                  ((data[`pcol_${year}_${mon}`] - data[`ncol_${year}_${mon}`]) /
                    data[`pcol_${year}_${mon}`]) *
                  100;
                data[`dcol_${year}_${mon}`] = discount;
              } else {
                data[`dcol_${year}_${mon}`] = 0;
              }
            }
            this.refreshRowTotal(data, "d", year, month);
          }
          this.gridApi.applyTransaction({ update: [data] });
          this.bringFocusBack();
          this.decreaseValue = null;
          return true;
        } else {
          this.translate
            .get("entryScreens.lower0")
            .subscribe((data: string) => {
              this.toastrService.warning(data);
            });
        }
      } else {
        this.translate
          .get("entryScreens.npAvailable")
          .subscribe((data: string) => {
            this.toastrService.warning(data);
          });
      }
    } else {
      let value = this.decreaseValueP;

      if (type != "n") {
        if (this.decreaseValueP > -1) {
          for (var month = cellMonth + 1; month < 13; month++) {
            data[`${type}col_${year}_${month}`] =
              Number(
                data[`${type}col_${year}_${month - 1}`] -
                  (Number(data[`${type}col_${year}_${month - 1}`]) *
                    Number(value)) /
                    100
              ) > 0
                ? Number(
                    data[`${type}col_${year}_${month - 1}`] -
                      (Number(data[`${type}col_${year}_${month - 1}`]) *
                        Number(value)) /
                        100
                  )
                : 0;
          }
          this.refreshRowTotal(data, type, year, month);

          if (type == "p" || type == "d") {
            data[`ntotal_${year}`] = 0;
            for (var mnth = 1; mnth < 13; mnth++) {
              data[`ncol_${year}_${mnth}`] =
                (data[`pcol_${year}_${mnth}`] *
                  (100 - data[`dcol_${year}_${mnth}`])) /
                100;
            }
            this.refreshRowTotal(data, "n", year, mnth);
          } else {
            data[`dtotal_${year}`] = 0;
            for (var mon = 1; mon < 13; mon++) {
              let discount = 0;
              if (data[`pcol_${year}_${mon}`] > 0) {
                discount =
                  ((data[`pcol_${year}_${mon}`] - data[`ncol_${year}_${mon}`]) /
                    data[`pcol_${year}_${mon}`]) *
                  100;
                data[`dcol_${year}_${mon}`] = discount;
              } else {
                data[`dcol_${year}_${mon}`] = 0;
              }
            }
            this.refreshRowTotal(data, "d", year, month);
          }
          this.gridApi.applyTransaction({ update: [data] });
          this.bringFocusBack();
          this.decreaseValue = null;
          return true;
        } else {
          this.translate
            .get("entryScreens.lower0")
            .subscribe((data: string) => {
              this.toastrService.warning(data);
            });
        }
      } else {
        this.translate
          .get("entryScreens.npAvailable")
          .subscribe((data: string) => {
            this.toastrService.warning(data);
          });
      }
    }
  }

  copyActuals() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year, type);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (type != "n") {
      for (let month = 1; month < 13; month++) {
        if (data[`${type}col_-${this.year4actual}_${month}`]) {
          data[`${type}col_${year}_${month}`] =
            data[`${type}col_-${this.year4actual}_${month}`];
          this.refreshRowTotal(data, "d", year, month);

          if (type == "p" || type == "d") {
            data[`ntotal_${year}`] = 0;
            for (let mnth = 1; mnth < 13; mnth++) {
              data[`ncol_${year}_${mnth}`] =
                (data[`pcol_${year}_${mnth}`] *
                  (100 - data[`dcol_${year}_${mnth}`])) /
                100;
            }
            this.refreshRowTotal(data, "n", year, month);
          } else {
            data[`dtotal_${year}`] = 0;
            for (let mon = 1; mon < 13; mon++) {
              let discount = 0;
              if (data[`pcol_${year}_${mon}`] > 0) {
                discount =
                  ((data[`pcol_${year}_${mon}`] - data[`ncol_${year}_${mon}`]) /
                    data[`pcol_${year}_${mon}`]) *
                  100;
                data[`dcol_${year}_${mon}`] = discount;
              } else {
                data[`dcol_${year}_${month}`] = 0;
              }
            }
            this.refreshRowTotal(data, "d", year, month);
          }
        }
      }
      this.gridApi.applyTransaction({ update: [data] });
      this.bringFocusBack();
      return true;
    } else {
      this.translate
        .get("entryScreens.npAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
    }
  }

  copyScenario() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.netPrice != true) {
      this.priceService.listPrices(this.copyScenarioId).subscribe((result) => {
        if (!result) {
          this.translate
            .get("entryScreens.no4scenario")
            .subscribe((data: string) => {
              this.toastrService.warning(data);
            });
          return;
        }

        this.fullDataS = result.data;
        for (let rowS in this.fullDataS) {
          for (let row in this.fullData) {
            if (
              this.fullDataS[rowS].productId == this.fullData[row].productId &&
              this.fullDataS[rowS].productFormId ==
                this.fullData[row].productFormId &&
              this.fullDataS[rowS].productChannelId ==
                this.fullData[row].productChannelId
            ) {
              let types = ["p", "d", "n"];
              let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
              for (let type of types) {
                for (let year of this.years) {
                  for (let month of months) {
                    this.fullData[row][`${type}col_${year}_${month}`] =
                      this.fullDataS[rowS][`${type}col_${year}_${month}`];
                  }
                  this.fullData[row][`${type}total_${year}`] =
                    this.fullDataS[rowS][`${type}total_${year}`];
                }
              }
            }
          }
        }

        this.rowData = this.fullData.filter((x) => x.isVisible);
        this.fullData4Import = [...this.fullData];

        this.setExpandable();
        this.changePrice();
      });
      return true;
    } else {
      this.translate
        .get("entryScreens.npAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
    }
  }

  copyPlanning() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.priceOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.netPrice != true) {
      this.scenarioService
        .listScenariosByGuidance(this.copyPlanningGuidanceId, 1)
        .subscribe((result) => {
          this.scenarioCopyList = result;
          this.scenarioGuiList = result;
        });
    } else {
      this.translate
        .get("entryScreens.npAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }
  }

  refreshRowTotal(data, type, year, month) {
    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Monthly") {
        let startMonth = element.startMonth;

        let currentYear = new Date(
          new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
        ).getFullYear();

        if (year != currentYear) {
          let total = 0;
          for (var month = startMonth; month < 13; month++) {
            total += data[`${type}col_${year}_${month}`];
          }
          data[`${type}total_${year}`] = total / (12 - (startMonth - 1));
        } else {
          let total = 0;
          for (var month = 1; month < 13; month++) {
            total += data[`${type}col_${year}_${month}`];
          }
          data[`${type}total_${year}`] = total / 12;
        }
      }
    });
    return;
  }

  getNumericCellEditor() {
    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }
    function isCharDecimal(charStr) {
      return ".".indexOf(charStr) === 0;
    }
    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr) || isCharDecimal(charStr);
    }
    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === "undefined" ? event.keyCode : event.which;
    }
    function NumericCellEditor() {}
    NumericCellEditor.prototype.init = function (params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement("input");
      this.eInput.style.width = "100%";
      this.eInput.style.height = "100%";
      this.eInput.value = isCharNumeric(params.charPress)
        ? params.charPress
        : params.value;
      var that = this;
      this.eInput.addEventListener("keypress", function (event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };
    NumericCellEditor.prototype.getGui = function () {
      return this.eInput;
    };
    NumericCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        //this.eInput.select();
      }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function () {
      return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.isCancelAfterEnd = function () {};
    NumericCellEditor.prototype.getValue = function () {
      return this.eInput.value;
    };
    NumericCellEditor.prototype.focusIn = function () {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
      console.log("NumericCellEditor.focusIn()");
    };
    NumericCellEditor.prototype.focusOut = function () {
      console.log("NumericCellEditor.focusOut()");
    };
    return NumericCellEditor;
  }

  @ViewChild("userMenu") userMenu: TemplateRef<any>;
  sub: Subscription;
  overlayRef: OverlayRef | null;

  open({ x, y }: MouseEvent, user) {
    this.close();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top",
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(
      new TemplatePortal(this.userMenu, this.viewContainerRef, {
        $implicit: user,
      })
    );

    this.sub = fromEvent<MouseEvent>(document, "click")
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return (
            !!this.overlayRef &&
            !this.overlayRef.overlayElement.contains(clickTarget)
          );
        }),
        take(1)
      )
      .subscribe(() => this.close());
  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
    this.rulesPopup = false;
    this.notesPopup = false;
    this.colorPopup = false;
  }
}
