import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AgGridAngular } from "ag-grid-angular";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { Scenario } from "@planard/@core/entities/scenario";
import { MatDialog } from "@angular/material/dialog";
import { TemplatePortal } from "@angular/cdk/portal";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { fromEvent, Subscription } from "rxjs";
import { take, filter } from "rxjs/operators";
import { Guidance } from "@planard/@core/entities/guidance";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ProductForm } from "@planard/@core/entities/productForm";
import { Optional } from "ag-grid-community";
import { ForecastNewComponent } from "../forecast/forecast-new.component";
import { OtherForecastService } from "@planard/@core/backend/service/otherForecast.service";
import { OtherForecast } from "@planard/@core/entities/otherForecast";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { ApproverRequest } from "@planard/@core/entities/approverRequest";
import { ApproverRequestService } from "@planard/@core/backend/service/approverRequest.service";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { isNumeric } from "rxjs/internal-compatibility";
import { TranslateService } from "@ngx-translate/core";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import { ForecastService } from "@planard/@core/backend/service/forecast.service";
import * as XLSX from "xlsx";
@Component({
  selector: "income",
  templateUrl: "./forecast-income.component.html",
  styleUrls: ["./forecast-income.component.scss"],
  providers: [NumberDecimalPipe],
})
export class ForecastIncomeComponent implements OnInit {
  //#region
  @ViewChild("agGrid") agGrid: AgGridAngular;
  private gridApi;
  private gridColumnApi;

  numberFormat;
  decimalFormat;

  scenarioList: Scenario[];
  scenarioList2Base: Scenario[];
  scenarioCopyList: Scenario[];
  years: number[];
  yearsCopyActual: number[];
  guidanceList: Guidance[];
  scenarioGuiList: Scenario[] = [];
  formList: ProductForm[];
  incomeList = [
    { value: 0, label: "Operating Income" },
    { value: 1, label: "Non-Operating Income" },
  ];
  months: string[] = [];
  currentYear = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();
  income: any = 1;
  title = "app";
  cellNote: any; //gösterilecek olan not.
  volume: boolean;
  amount: boolean;
  scenario: Scenario;
  scenarioId: number;
  copyScenarioId: number;
  guidanceId: number;
  copyPlanningGuidanceId: number;
  percent2coloring: Number = 40;
  carryForwardClicked: boolean;
  increaseMonthlyClicked: boolean;
  decreaseMonthlyClicked: boolean;
  increaseMonthlyPClicked: boolean;
  decreaseMonthlyPClicked: boolean;
  increaseAllMonthlyClicked: boolean;
  decreaseAllMonthlyClicked: boolean;
  increaseAllMonthlyPClicked: boolean;
  decreaseAllMonthlyPClicked: boolean;
  sustainTrendClicked: boolean;
  rulesClicked: boolean;
  exportClicked: boolean;
  importClicked: boolean;
  exelExportClicked: boolean;
  exelImportClicked: boolean;
  copyClicked: boolean;
  pasteClicked: boolean;
  copyHeadersClicked: boolean;
  notesClicked: boolean;
  priceNotesClicked: boolean;
  discountNotesClicked: boolean;
  copyActualsClicked: boolean;
  copyScenarioClicked: boolean;
  copyPlannngClicked: boolean;
  sheetName: string;
  colorPopup: boolean = false;
  rulesPopup: boolean = false;
  notesPopup: boolean = false;
  increaseValueP: number;
  decreaseValueP: number;
  increaseAllValue: number;
  decreaseAllValue: number;
  increaseAllValueP: number;
  decreaseAllValueP: number;
  input: boolean;
  notes: boolean;
  guidaneSet: boolean;
  currentGuidanceId: number;
  exelPriceData: any;
  exelDiscountData: any;
  salesForecastId: number;
  exelYear: number = 2020;
  exelName: string;
  file: File;
  filelist: any;
  arrayBuffer: any;
  isApprover: boolean;
  isPlanner: boolean;
  screenSelect: number; //0 --> income  1 --> approve income
  isRejected: boolean = true;
  forecastLenght: number;
  value2copy: any;
  increaseValue: number;
  decreaseValue: number;
  noteValue: any;
  file_name: string;
  formConversionRate: number = 1;
  guidanceName: any;
  guidancePlanningPeriodList: GuidancePlanningPeriod[];
  rowData: any;
  fullData: any;
  fullData4Import: any;
  year4actual: number;
  rowDataS: any;
  fullDataS: any;
  guidanceGroup: any;
  currenctguidance = [];
  oldguidance = [];
  status: any; // 0 = submit edilmemiş , 1 = submit edilmiş approve/reject bekliyor, 2 = approve edilmiş, 3 = reject edilmiş
  rejectReason: string;
  isScreenValid: boolean;
  data2Exel: any;
  headData: any; // excel row header
  isRoleValid: boolean;
  x: number;
  y4c: number;
  y4d: number;
  y4n: number;
  isOpen: boolean = false;

  defaultColDef = {
    //editable: true, //this.cellEditable,
    sortable: false,
    flex: 1,
    minWidth: 90,
    filter: false,
    resizable: true,
    cellEditor: "numericCellEditor",
  };

  components = { numericCellEditor: this.getNumericCellEditor() };
  columnDefs = [];
  loadingTemplate;
  noRowsTemplate;

  isImpactArea;
  productName;
  //#endregion

  constructor(
    @Optional() private parent: ForecastNewComponent,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public toastrService: ToastrService,
    public otherForecastService: OtherForecastService,
    public forecastService: ForecastService,
    public productsService: ProductsService,
    public translate: TranslateService,
    public approverRequestService: ApproverRequestService,
    public guidanceService: GuidanceService,
    public scenarioService: ScenarioService,
    private router: Router,
    private numberDecimal: NumberDecimalPipe,
    public authService: AuthService,
    public dialog: MatDialog
  ) {
    this.translate.get("agGrid.loading").subscribe((data: any) => {
      this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
    });
    this.translate.get("agGrid.no").subscribe((data: any) => {
      this.noRowsTemplate = `"<span">${data}</span>"`;
    });

    if (this.translate.currentLang == "en") this.productName = "Type";
    if (this.translate.currentLang == "tr") this.productName = "Tip";

    translate.onLangChange.subscribe((lang) => {
      this.translate.get("agGrid.loading").subscribe((data: any) => {
        this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
      });
      this.translate.get("agGrid.no").subscribe((data: any) => {
        this.noRowsTemplate = `"<span">${data}</span>"`;
      });

      if (this.translate.currentLang == "en") this.productName = "Type";
      if (this.translate.currentLang == "tr") this.productName = "Tip";

      this.changeAmount();
    });

    this.months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();
    // bu kısmı admin olarak değiştirdiler.
    // this.isRoleValid =
    //   this.authService.isBusinessUnitPlanner() ||
    //   this.authService.isBusinessUnitApprover();
    this.isRoleValid = this.authService.isAdmin();
    if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
    this.isScreenValid = this.authService.forecastPlanning();
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.incomeTask")
        .subscribe((data: string) => {
          this.toastrService.error(data);
        });
    }
  }

  ngOnInit() {
    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidanceName = result.planningCycleName;
      this.guidanceId = result.id;
      this.currentGuidanceId = result.id;
      this.guidanceService.getPlanningRecord(result.id).subscribe((result) => {
        this.guidancePlanningPeriodList = result;
      });
      this.guidanceService.listAll().subscribe((result) => {
        this.guidanceList = result;
        result.forEach((element) => {
          if (element.id == this.guidanceId) {
            this.currenctguidance.push({
              value: element.id,
              label: element.planningCycleName,
            });
          } else {
            this.oldguidance.push({
              value: element.id,
              label: element.planningCycleName,
            });
          }
        });
      });
    });

    this.scenarioService.listAll().subscribe((result) => {
      this.scenarioList = result;
      result.forEach((element) => {
        if (element.isBase == true) {
          this.scenarioId = element.id;
          this.getForecast();
        }
      });
    });
    setTimeout(() => {
      this.guidanceGroup = [
        {
          name: "Current Plan", // value yıl olucak
          guidance: this.currenctguidance,
        },
        {
          name: "Old Plans", // value plannig id olucak
          guidance: this.oldguidance,
        },
      ];
    }, 1500);
  }

  addOnClick(event) {
    this.x = event.pageX + 75;
    this.y4c = event.pageY + 150;
    this.y4d = event.pageY + 50;
    this.y4n = event.pageY + 100;
  }

  setGuidanceScenario() {
    this.scenarioService
      .listScenariosByGuidance(this.guidanceId, 0)
      .subscribe((result) => {
        this.scenarioList = result;
      });
    this.guidanceService.getById(this.guidanceId).subscribe((result) => {
      this.guidanceName = result.planningCycleName;
    });
    this.guidanceService
      .getPlanningRecord(this.guidanceId)
      .subscribe((result) => {
        this.guidancePlanningPeriodList = result;
      });
  }

  onSubmit() {
    if (this.scenarioId == undefined) return;

    let approverRequest = new ApproverRequest();
    approverRequest.scenarioId = this.scenarioId;
    approverRequest.type = "4";

    this.approverRequestService
      .submit(approverRequest)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
  }

  setClick() {
    this.carryForwardClicked = false;
    this.increaseMonthlyClicked = false;
    this.decreaseMonthlyClicked = false;
    this.increaseMonthlyPClicked = false;
    this.decreaseMonthlyPClicked = false;
    this.increaseAllMonthlyClicked = false;
    this.decreaseAllMonthlyClicked = false;
    this.increaseAllMonthlyPClicked = false;
    this.decreaseAllMonthlyPClicked = false;
    this.sustainTrendClicked = false;
    this.rulesClicked = false;
    this.exportClicked = false;
    this.importClicked = false;
    this.exelExportClicked = false;
    this.exelImportClicked = false;
    this.copyClicked = false;
    this.pasteClicked = false;
    this.copyHeadersClicked = false;
    this.notesClicked = false;
    this.priceNotesClicked = false;
    this.discountNotesClicked = false;
    this.copyActualsClicked = false;
    this.copyScenarioClicked = false;
    this.copyPlannngClicked = false;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridSet() {
    // setTimeout(() => {
    //   this.autoSizeAll(true);
    // }, 1000);
  }

  autoSizeAll(skipHeader) {
    //this.gridApi.sizeColumnsToFit();
    // var allColumnIds = [];
    // this.gridColumnApi.getAllColumns().forEach(function (column) {
    //   allColumnIds.push(column.colId);
    // });
    // this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  getForecast() {
    this.otherForecastService
      .listPrices(this.scenarioId)
      .subscribe((result) => {
        this.fullData = result.data;
        this.fullData4Import = [...result.data];

        this.isImpactArea = result.isImpactArea;

        this.status = result.status;
        this.rejectReason =
          result.rejectReason != null ? result.rejectReason : "";

        this.rowData = [...result.data].filter((x) => x.isVisible);

        var neg = [];
        var pos = [];

        neg = result.years.filter((x) => x < 0).sort((a, b) => b - a);
        pos = result.years.filter((x) => x > 0).sort((a, b) => a - b);

        this.years = [...neg, ...pos];
        this.yearsCopyActual = [
          ...neg.map((x) => x * -1),
          ...[this.currentYear],
        ];
        this.year4actual = this.yearsCopyActual[0];
        this.forecastLenght = this.fullData.length;

        this.changeAmount();
        this.setTotalRow();
      });
  }

  setExpandable() {
    for (let line of this.fullData) {
      var id = line.id;
      var isParent = false;
      for (let lin of this.fullData) {
        if (id == lin.parentId) {
          isParent = true;
        }
      }
      line.expandable = isParent;
    }
  }

  setTotalRow() {
    let totalRow: any = [...this.fullData[0]];
    totalRow.type = 2;

    let types = ["v", "a"];
    let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    for (let type of types) {
      for (let year of this.years) {
        for (let month of months) {
          let total = 0;
          for (let row of this.fullData) {
            if (row[`${type}col_${year}_${month}`] != undefined)
              total = total + Number(row[`${type}col_${year}_${month}`]);
          }
          totalRow[`${type}col_${year}_${month}`] = total.toFixed(2);
        }
        totalRow[`${type}total_${year}`] = Math.round(
          Number(totalRow[`${type}col_${year}_1`]) +
            Number(totalRow[`${type}col_${year}_2`]) +
            Number(totalRow[`${type}col_${year}_3`]) +
            Number(totalRow[`${type}col_${year}_4`]) +
            Number(totalRow[`${type}col_${year}_5`]) +
            Number(totalRow[`${type}col_${year}_6`]) +
            Number(totalRow[`${type}col_${year}_7`]) +
            Number(totalRow[`${type}col_${year}_8`]) +
            Number(totalRow[`${type}col_${year}_9`]) +
            Number(totalRow[`${type}col_${year}_10`]) +
            Number(totalRow[`${type}col_${year}_11`]) +
            Number(totalRow[`${type}col_${year}_12`])
        );
      }
    }
    this.gridApi.setPinnedBottomRowData([totalRow]);
  }

  getProductDef() {
    return {
      headerName: this.productName,
      field: "type",
      pinned: "left",
      editable: this.cellEditable,
      minWidth: 150,
      visible: false,
      cellRenderer: this.productCellRenderer,
    };
  }

  cellEditable(params) {
    return false;
  }

  productCellRenderer(params) {
    let style = "";
    let name = "";
    if (params.data.parentId != 0) style = 'style="padding-left: 10px;"';
    if (params.data.type == 0) {
      // Financial Income
      name = "Financial Income";
      return `<span ` + style + `></span> ` + name + ``;
    } else if (params.data.type == 1) {
      // Income From Assets
      name = "Income From Assets";
      return `<span ` + style + `></span> ` + name + ``;
    } else if (params.data.type == 2) {
      // Total
      name = "TOTAL :";
      return (
        `<span ` +
        style +
        `></span> ` +
        `<span style="color: #ff7500;">` +
        name +
        `</span>` +
        ``
      );
    }
  }

  getYearColumnDef(type, year) {
    let numberDecimal = this.numberDecimal;
    let numberFormat = this.numberFormat;
    let decimalFormat = this.decimalFormat;

    let rate = Number(this.percent2coloring) / 100;
    let rate2diff = (100 - Number(this.percent2coloring)) / 100;

    var name = this.guidanceName;
    let guidanceId = this.guidanceId;
    let isScreenValid = this.isScreenValid;
    let currentGuidanceId = this.currentGuidanceId;
    var isYearly: boolean = false;
    let isImpactArea = this.isImpactArea;
    let years = this.years;
    let yearIndex;
    let status = this.status;

    for (let index in this.years) {
      if (this.years[index] == year) yearIndex = Number(index);
    }

    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Yearly") {
        isYearly = true;
      }
    });

    if (!isYearly) {
      //monthly
      return {
        headerName: `${year > 0 ? name : ""} ${
          year < 0 ? "A`" + year * -1 : year
        }`,
        minWidth: 150,
        copyHeadersToClipboard: true,
        suppressClipboardPaste: true,
        children: [
          {
            headerName: "Total",
            editable: function (params) {
              if (!isScreenValid) return false;
              if (!isImpactArea) return false;
              if (guidanceId != currentGuidanceId) return false;
              if (year < 0) return false;
              if (params.data.isSubmit == true && status != "Rejected")
                return false;
              if (params.data.product == "TOTAL : ") return false;
              return true;
            },
            columnGroupShow: "closed",
            cellYear: year,
            cellType: type,
            type: "rightAligned",
            valueGetter: function (params) {
              return Number(params.data[`${type}total_${year}`]).toFixed(2);
            },
            cellStyle: function (params) {
              if (
                Number(params.data[`${type}total_${year}`]) <
                  Number(
                    params.data[`${type}total_${years[yearIndex - 1]}`] *
                      rate2diff
                  ) &&
                params.data.expandable != true &&
                params.data.product != "TOTAL : "
              ) {
                return { color: "black", backgroundColor: "#fff0ee" }; //düşüş durumunda
              } else if (
                Number(params.data[`${type}total_${year}`]) >
                  Number(
                    params.data[`${type}total_${years[yearIndex - 1]}`] +
                      params.data[`${type}total_${years[yearIndex - 1]}`] * rate
                  ) &&
                params.data.expandable != true &&
                params.data.product != "TOTAL : "
              ) {
                return { color: "black", backgroundColor: "#f3fff3" }; // yükselme durumunda
              }
              if (params.data.isImpactedProduct == false) {
                return { backgroundColor: "#f6f6f6" };
              }
              if (isImpactArea == false) {
                return { backgroundColor: "#f6f6f6" };
              }
              if (params.data.type == 2) {
                return { color: "#ff7500" };
              }
            },
            valueSetter: function (params) {
              for (var month = 1; month < 13; month++) {
                params.data[`${type}total_${year}`] = Number(params.newValue);
              }
              return true;
            },
            valueFormatter: function (params) {
              if (params.value == undefined || params.value == "NaN") {
                return "0.00";
              }
              return numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            },
          },
          this.getMonthColumnDef(type, year, 1),
          this.getMonthColumnDef(type, year, 2),
          this.getMonthColumnDef(type, year, 3),
          this.getMonthColumnDef(type, year, 4),
          this.getMonthColumnDef(type, year, 5),
          this.getMonthColumnDef(type, year, 6),
          this.getMonthColumnDef(type, year, 7),
          this.getMonthColumnDef(type, year, 8),
          this.getMonthColumnDef(type, year, 9),
          this.getMonthColumnDef(type, year, 10),
          this.getMonthColumnDef(type, year, 11),
          this.getMonthColumnDef(type, year, 12),
          {
            headerName: "Total",
            minWidth: 150,
            copyHeadersToClipboard: true,
            suppressClipboardPaste: true,
            editable: false,
            columnGroupShow: "open",
            cellYear: year,
            cellType: type,
            type: "rightAligned",
            valueGetter: function (params) {
              return Number(params.data[`${type}total_${year}`]).toFixed(2);
            },
            valueSetter: function (params) {
              for (var month = 1; month < 13; month++) {
                params.data[`${type}total_${year}`] = Number(params.newValue);
                params.data[`${type}col_${year}_${month}`] = (
                  params.newValue / 12
                ).toFixed(2);
              }
              return true;
            },
            cellStyle: function (params) {
              if (params.data.type == 2) {
                return { color: "#ff7500" };
              } else if (params.data.isImpactedProduct == false) {
                return { backgroundColor: "#f6f6f6" };
              } else if (isImpactArea == false) {
                return { backgroundColor: "#f6f6f6" };
              }
            },
            valueFormatter: function (params) {
              if (params.value == undefined || params.value == "NaN") {
                return "0.00";
              }
              return numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            },
          },
        ],
      };
    } else {
      //yearly
      return {
        headerName: `${year > 0 ? name : ""} ${year}`,
        children: [
          {
            headerName: "Total",
            minWidth: 90,
            copyHeadersToClipboard: true,
            suppressClipboardPaste: true,
            editable: function (params) {
              if (!isImpactArea) return false;
              if (!isScreenValid) return false;
              if (guidanceId != currentGuidanceId) return false;
              if (year < 0) return false;
              if (params.data.isSubmit == true && status != "Rejected")
                return false;
              if (params.data.product == "TOTAL : ") return false;
              return true;
            },
            columnGroupShow: "closed",
            cellYear: year,
            cellType: type,
            type: "rightAligned",
            valueGetter: function (params) {
              return Number(params.data[`${type}total_${year}`]).toFixed(2);
            },
            valueSetter: function (params) {
              for (var month = 1; month < 13; month++) {
                params.data[`${type}total_${year}`] = Number(params.newValue);
              }
              return true;
            },
            cellStyle: function (params) {
              if (
                Number(params.data[`${type}total_${year}`]) <
                  Number(
                    params.data[`${type}total_${years[yearIndex - 1]}`] *
                      rate2diff
                  ) &&
                params.data.expandable != true &&
                params.data.product != "TOTAL : "
              ) {
                return { color: "black", backgroundColor: "#fff0ee" }; //düşüş durumunda
              } else if (
                Number(params.data[`${type}total_${year}`]) >
                  Number(
                    params.data[`${type}total_${years[yearIndex - 1]}`] +
                      params.data[`${type}total_${years[yearIndex - 1]}`] * rate
                  ) &&
                params.data.expandable != true &&
                params.data.product != "TOTAL : "
              ) {
                return { color: "black", backgroundColor: "#f3fff3" }; // yükselme durumunda
              }
              if (params.data.type == 2) {
                return { color: "#ff7500" };
              }
              if (isImpactArea == false) {
                return { backgroundColor: "#f6f6f6" };
              }
              if (params.data.isImpactedProduct == false) {
                return { backgroundColor: "#f6f6f6" };
              }
            },
            valueFormatter: function (params) {
              if (params.value == undefined || params.value == "NaN") {
                return "0.00";
              }
              return numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            },
          },
        ],
      };
    }
  }

  getMonthColumnDef(type, year, month) {
    let numberDecimal = this.numberDecimal;
    let numberFormat = this.numberFormat;
    let decimalFormat = this.decimalFormat;
    this.percent2coloring;
    let rate = Number(this.percent2coloring) / 100;
    let rate2diff = (100 - Number(this.percent2coloring)) / 100;
    let guidanceId = this.guidanceId;
    let isScreenValid = this.isScreenValid;
    let currentGuidanceId = this.currentGuidanceId;
    var startMonth: any = 1;
    let isImpactArea = this.isImpactArea;
    let status = this.status;
    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Monthly") {
        startMonth = element.startMonth;
      }
    });
    return {
      headerName: this.months[month - 1],
      minWidth: 90,
      copyHeadersToClipboard: true,
      suppressClipboardPaste: true,
      valueGetter: function (params) {
        return /*month >= startMonth ?*/ Number(
          params.data[`${type}col_${year}_${month}`]
        ).toFixed(2); /* : null*/
      },
      valueSetter: function (params) {
        params.data[`${type}total_${year}`] =
          (isNumeric(params.data[`${type}total_${year}`])
            ? params.data[`${type}total_${year}`]
            : 0) +
          (params.newValue - (params.oldValue != "NaN" ? params.oldValue : 0));
        params.data[`${type}col_${year}_${month}`] = Number(params.newValue);
        return true;
      },
      columnGroupShow: "open",
      tooltipField: `${type}not_${year}_${month}`,
      cellMonth: month,
      cellYear: year,
      cellType: type,
      type: "rightAligned",
      editable: function (params) {
        if (!isImpactArea) return false;
        if (!isScreenValid) return false;
        if (guidanceId != currentGuidanceId) return false;
        if (year < 0) return false;
        if (month < startMonth) return false;
        if (params.data.isSubmit == true && status != "Rejected") return false;
        if (params.data.product == "TOTAL : ") return false;
        return true;
      },
      cellStyle: function (params) {
        if (
          Number(params.data[`${type}col_${year}_${month}`]) <
            Number(
              params.data[`${type}col_${year}_${month - 1}`] * rate2diff
            ) &&
          params.data.type != 2 &&
          month - 1 >= startMonth
        ) {
          if (params.data[`${type}not_${year}_${month}`] != null) {
            return {
              color: "black",
              backgroundColor: "#fff0ee",
              border: "solid",
              borderTopWidth: "0.0px",
              borderRightWidth: "0.0px",
              borderLeftWidth: "0.0px",
              borderBottomWidth: "2px",
              borderColor: "#BB8FCE",
            }; // yükselme durumunda
          }
          return { color: "black", backgroundColor: "#fff0ee" }; //düşüş durumunda
        } else if (
          Number(params.data[`${type}col_${year}_${month}`]) >
            Number(
              params.data[`${type}col_${year}_${month - 1}`] +
                params.data[`${type}col_${year}_${month - 1}`] * rate
            ) &&
          params.data.type != 2 &&
          month - 1 >= startMonth
        ) {
          if (params.data[`${type}not_${year}_${month}`] != null) {
            return {
              color: "black",
              backgroundColor: "#f3fff3",
              border: "solid",
              borderTopWidth: "0.0px",
              borderRightWidth: "0.0px",
              borderLeftWidth: "0.0px",
              borderBottomWidth: "2px",
              borderColor: "#BB8FCE",
            }; // yükselme durumunda
          }
          return { color: "black", backgroundColor: "#f3fff3" }; // yükselme durumunda
        } else if (params.data[`${type}not_${year}_${month}`] != null) {
          return {
            border: "solid",
            borderTopWidth: "0.0px",
            borderRightWidth: "0.0px",
            borderLeftWidth: "0.0px",
            borderBottomWidth: "2px",
            borderColor: "#BB8FCE",
          }; // yükselme durumunda
        } else if (params.data.isImpactedProduct == false) {
          return { backgroundColor: "#f6f6f6" };
        } else if (isImpactArea == false) {
          return { backgroundColor: "#f6f6f6" };
        } else if (params.data.type == 2) {
          return { color: "#ff7500" };
        }
        return { color: "initial", backgroundColor: "initial" };
      },
      valueFormatter: function (params) {
        if (params.value == undefined || params.value == "NaN") {
          return "0.00";
        }
        return numberDecimal.transform(
          params.value,
          decimalFormat,
          numberFormat
        );
      },
    };
  }

  getSelectedRows() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const selectedDataStringPresentation = selectedData
      .map((node) => node.make + " " + node.model)
      .join(", ");
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  }

  changeColor() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    let type;

    if (this.volume == true) type = "v";
    else if (this.amount == true) type = "a";

    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef(type, year));
    });

    this.gridApi.setColumnDefs(this.columnDefs);

    this.translate
      .get("entryScreens.coloringRate")
      .subscribe((data: string) => {
        this.toastrService.warning(data);
      });
  }

  changeVolume() {
    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef("v", year));
    });
    this.gridApi.setColumnDefs(this.columnDefs);
    this.volume = true;
    this.amount = false;
  }

  changeAmount() {
    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef("a", year));
    });
    this.gridApi.setColumnDefs(this.columnDefs);
    this.amount = true;
    this.volume = false;
  }

  private bringFocusBack() {
    let cell = this.gridApi.getFocusedCell();

    if (cell) {
      this.gridApi.setFocusedCell(cell.rowIndex, cell.column);
    }
  }

  onColumnSpread(event: any) {
    let type = event.colDef.cellType;
    let year = event.colDef.cellYear;
    let month = event.colDef.cellMonth ? event.colDef.cellMonth : 0;

    let data4months = event.data;

    if (month == 0) {
      var startM = 1;
      this.guidancePlanningPeriodList.forEach((element) => {
        for (let row of Object.keys(this.fullData)) {
          if (this.fullData[row].type == data4months.type) {
            if (element.year == year && element.planningType == "Monthly") {
              startM = element.startMonth;
              let val =
                this.fullData[row][`${type}total_${year}`] /
                (12 - (startM - 1));
              for (let mnth = startM; mnth < 13; mnth++) {
                this.fullData[row][`${type}col_${year}_${mnth}`] = val;
              }
            } else if (
              element.year == year &&
              element.planningType == "Yearly"
            ) {
              let val = this.fullData[row][`${type}total_${year}`] / 12;
              for (let mnth = 1; mnth < 13; mnth++) {
                this.fullData[row][`${type}col_${year}_${mnth}`] = val;
              }
            }
          }
        }
      });
    }
    let rows = [...this.fullData];
    this.gridApi.setRowData(rows.filter((x) => x.isVisible));
    this.setTotalRow();

    this.bringFocusBack();
    this.gridApi.refreshCells({
      force: true,
    });
  }

  save() {
    let status = this.status;
    let data = [];
    let startMonth = 0;
    this.fullData.forEach((rowNode) => {
      if (rowNode.isSubmit == false || this.status == "Rejected") {
        data.push(rowNode);
      }
    });

    let otherForecast = new OtherForecast();
    otherForecast.rows = data;

    let currentYear = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear();

    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == currentYear && element.planningType == "Monthly") {
        startMonth = element.startMonth;
      }
    });

    this.fullData.forEach((rowNode) => {
      if (
        (rowNode.isSubmit == false || status == "Rejected") &&
        rowNode.isImpactedProduct == true
      ) {
        for (let month = 1; month < startMonth; month++) {
          if (rowNode[`acol_${currentYear}_${month}`])
            rowNode[`acol_${currentYear}_${month}`] = 0;
          if (rowNode[`vcol_${currentYear}_${month}`])
            rowNode[`vcol_${currentYear}_${month}`] = 0;
        }
        data.push(rowNode);
      }
    });

    this.otherForecastService
      .update(otherForecast)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
  }

  isEditable(data, month, year): Boolean {
    var startMonth = 0;
    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Monthly") {
        startMonth = element.startMonth;
      }
    });

    if (!this.isImpactArea) return false;
    if (!this.isScreenValid) return false;
    if (this.guidanceId != this.currentGuidanceId) return false;
    if (year < 0) return false;
    if (month < startMonth) return false;
    if (data.isSubmit == true && this.status != "Rejected") return false;
    if (data.product == "TOTAL : ") return false;
    return true;
  }

  setScenario() {
    for (let sc of Object.keys(this.scenarioList)) {
      if (this.scenarioId == this.scenarioList[sc].id) {
        this.file_name =
          "OtherForecast-" + this.scenarioList[sc].name + ".xlsx";
        return this.scenarioList[sc].name;
      }
    }
  }

  setExportData() {
    let type = this.volume ? "v" : this.amount ? "a" : "";

    let emptyRow: any[];
    let scenario: any[] = ["", "Scenario Name : " + " " + this.setScenario()];
    let guide: any[] = [
      "",
      "Excel User Guide : On This Excel Sheet Change Only Volumes/Amounts. Do not Remove Any Column or Row and Do not Change Their Names.",
    ];

    let header = ["SalesForecastId", "Name"];

    if (type == "v") {
      this.sheetName = "Sheet Volume";
    } else if (type == "a") {
      this.sheetName = "Sheet Amount";
    }

    for (let year of this.years) {
      if (year > 0) {
        header.push(`${year} Jan`);
        header.push(`${year} Feb`);
        header.push(`${year} Mar`);
        header.push(`${year} Apr`);
        header.push(`${year} May`);
        header.push(`${year} Jun`);
        header.push(`${year} Jul`);
        header.push(`${year} Aug`);
        header.push(`${year} Sep`);
        header.push(`${year} Oct`);
        header.push(`${year} Nov`);
        header.push(`${year} Dec`);
      }
    }

    this.data2Exel = [];
    this.data2Exel.push(header);

    this.fullData.forEach((element) => {
      this.salesForecastId = element.id;

      if (element.type == 0) {
        this.exelName = "Financial Income";
      } else if (element.type == 1) {
        this.exelName = "Income From Assets";
      }

      let row: any[] = [this.salesForecastId, this.exelName];
      for (let year of this.years) {
        if (year > 0) {
          row.push(element[`${type}col_${year}_1`]);
          row.push(element[`${type}col_${year}_2`]);
          row.push(element[`${type}col_${year}_3`]);
          row.push(element[`${type}col_${year}_4`]);
          row.push(element[`${type}col_${year}_5`]);
          row.push(element[`${type}col_${year}_6`]);
          row.push(element[`${type}col_${year}_7`]);
          row.push(element[`${type}col_${year}_8`]);
          row.push(element[`${type}col_${year}_9`]);
          row.push(element[`${type}col_${year}_10`]);
          row.push(element[`${type}col_${year}_11`]);
          row.push(element[`${type}col_${year}_12`]);
        }
      }
      this.data2Exel.push(row);
    });
    this.data2Exel.push(emptyRow);
    this.data2Exel.push(scenario);
    this.data2Exel.push(guide);

    this.export();
  }

  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };

  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2Exel, {
      cellStyles: true,
    });

    ws["B1"].s = {};
    ws["B1"].s = { fill: { bgColor: { rgb: "ff6600" } } };

    ws["!cols"] = [];
    ws["!cols"][0] = { hidden: true };
    ws["!cols"][1] = { wpx: 200 };

    ws["!rows"] = [];
    ws["!rows"][0] = { hpx: 35 };
    ws["!rows"][this.forecastLenght + 2] = { hpx: 35 };
    ws["!rows"][this.forecastLenght + 3] = { hpx: 35 };

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.sheetName);
    XLSX.writeFile(wb, this.file_name);
  }

  onFileAdd(event: any) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.filelist = [];

      let type = "";
      if (first_sheet_name == "Sheet Volume") {
        type = "v";
      } else if (first_sheet_name == "Sheet Amount") {
        type = "a";
      }

      if ((arraylist.length - 2) % this.forecastLenght === 0) {
        this.fullData4Import = arraylist;
        for (let row in this.fullData) {
          for (let year of this.years) {
            if (year > 0) {
              this.guidancePlanningPeriodList.forEach((element) => {
                if (element.year == year && element.planningType == "Monthly") {
                  let startMonth = element.startMonth;

                  let ArrMo = [
                    "",
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];

                  for (var month = startMonth; month < 13; month++) {
                    this.fullData[row][`${type}col_${year}_${month}`] =
                      this.fullData4Import[row][`${year} ${ArrMo[month]}`];
                  }

                  let total = 0;
                  let totaln = 0;
                  for (var mnth = 1; mnth < 13; mnth++) {
                    total += Number(
                      this.fullData[row][`${type}col_${year}_${mnth}`]
                    );
                    totaln += Number(
                      this.fullData[row][`ncol_${year}_${mnth}`]
                    );
                  }
                } else {
                  this.fullData[row][`${type}col_${year}_1`] =
                    this.fullData4Import[row][`${year} Jan`];
                  this.fullData[row][`${type}col_${year}_2`] =
                    this.fullData4Import[row][`${year} Feb`];
                  this.fullData[row][`${type}col_${year}_3`] =
                    this.fullData4Import[row][`${year} Mar`];
                  this.fullData[row][`${type}col_${year}_4`] =
                    this.fullData4Import[row][`${year} Apr`];
                  this.fullData[row][`${type}col_${year}_5`] =
                    this.fullData4Import[row][`${year} May`];
                  this.fullData[row][`${type}col_${year}_6`] =
                    this.fullData4Import[row][`${year} Jun`];
                  this.fullData[row][`${type}col_${year}_7`] =
                    this.fullData4Import[row][`${year} Jul`];
                  this.fullData[row][`${type}col_${year}_8`] =
                    this.fullData4Import[row][`${year} Aug`];
                  this.fullData[row][`${type}col_${year}_9`] =
                    this.fullData4Import[row][`${year} Sep`];
                  this.fullData[row][`${type}col_${year}_10`] =
                    this.fullData4Import[row][`${year} Oct`];
                  this.fullData[row][`${type}col_${year}_11`] =
                    this.fullData4Import[row][`${year} Nov`];
                  this.fullData[row][`${type}col_${year}_12`] =
                    this.fullData4Import[row][`${year} Dec`];

                  this.fullData[row][`${type}total_${year}`] =
                    this.fullData4Import[row][`${year} Jan`] +
                    this.fullData4Import[row][`${year} Feb`] +
                    this.fullData4Import[row][`${year} Mar`] +
                    this.fullData4Import[row][`${year} Apr`] +
                    this.fullData4Import[row][`${year} May`] +
                    this.fullData4Import[row][`${year} Jun`] +
                    this.fullData4Import[row][`${year} Jul`] +
                    this.fullData4Import[row][`${year} Aug`] +
                    this.fullData4Import[row][`${year} Sep`] +
                    this.fullData4Import[row][`${year} Oct`] +
                    this.fullData4Import[row][`${year} Nov`] +
                    this.fullData4Import[row][`${year} Dec`];
                }
              });
            }
          }
        }

        this.rowData = this.fullData.filter((x) => x.isVisible);
        this.fullData4Import = [...this.fullData];

        this.setExpandable();
        this.setTotalRow();

        if (type == "v") this.changeVolume();
        else this.changeAmount();

        // bu kod buglara yol açıyor, grid'i yeni data ile baştan setlemek daha mantıklı.
        // this.gridApi.setRowData(rows);
        // this.setTotalRow();
        // this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.corrupted").subscribe((data: any) => {
          this.toastrService.error(data);
        });
      }
    };
  }

  onCopy() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }
    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }
    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }
    this.value2copy = this.currentContextEvent.value;
  }

  onPaste() {
    if (this.value2copy == null || this.value2copy == "NaN") return;

    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data2copy = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;
    if (data2copy.expandable == true) return;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth
      ? this.currentContextEvent.colDef.cellMonth
      : 0;

    var isEditable = this.isEditable(data2copy, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let oldVal;
    if (cellMonth != 0)
      oldVal = Number(data2copy[`${type}col_${year}_${cellMonth}`]);
    else oldVal = Number(data2copy[`${type}total_${year}`]);

    if (cellMonth != 0)
      data2copy[`${type}col_${year}_${cellMonth}`] = Number(this.value2copy);
    else data2copy[`${type}total_${year}`] = Number(this.value2copy);

    this.setTotalRow();
    this.gridApi.applyTransaction({ update: [data2copy] });
    this.bringFocusBack();
  }

  sustainTrend() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data4sustain = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data4sustain, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.years.includes(year - 1)) {
      let toplam: number = 0;
      for (var month = 1; month < 13; month++) {
        toplam =
          toplam + Number(data4sustain[`${type}col_${year - 1}_${month}`]); //bir önceki yılın trendinin hesaplar.
      }

      let ort: number = Math.floor(toplam / 12);
      for (var month = 1; month < 13; month++) {
        data4sustain[`${type}col_${year}_${month}`] = ort;
      }

      data4sustain[`${type}total_${year}`] = 0;
      for (var month = 1; month < 13; month++) {
        data4sustain[`${type}total_${year}`] += Number(
          data4sustain[`${type}col_${year}_${month}`]
        );
      }

      this.setTotalRow();
      this.gridApi.applyTransaction({ update: [data4sustain] });
      this.bringFocusBack();
    } else {
      this.translate.get("entryScreens.noPast").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
    }
  }

  onCellClicked(event) {
    if (event.column.colDef.field != "type") {
      return;
    }

    var parentId = event.data.id;
    var rows = [...this.fullData];

    let currentRow = rows.filter((row) => row.id == parentId)[0];
    let isOpen = currentRow.selected;

    let currentRows = rows.filter((row) => row.parentId == parentId);
    currentRows.forEach(function (row) {
      row.isVisible = !isOpen;
      if (isOpen) {
        row.selected = false;
        let subRows = rows.filter((r) => r.parentId == row.id);
        subRows.forEach(function (row) {
          row.isVisible = false;
        });
      }
    });
    currentRow.selected = !isOpen;
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
  }

  openRows() {
    var rows = [...this.fullData];

    this.isOpen = !this.isOpen;
    var value = this.isOpen;

    rows.forEach(function (row) {
      if (row.parentId != 0) {
        row.isVisible = value;
        row.selected = value;
      } else {
        row.selected = value;
      }
    });
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
  }

  currentContextEvent: any;
  onCellContextMenu(event) {
    this.currentContextEvent = event;
  }

  onEditNotes() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }
    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }
    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    var note = this.noteValue;

    var data4note = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    var type = this.currentContextEvent.colDef.cellType;
    var year = this.currentContextEvent.colDef.cellYear;
    var cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data4note, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth)
      data4note[`${type}not_${year}_1`] =
        note.trim() == "" ? null : note.trim();
    else
      data4note[`${type}not_${year}_${cellMonth}`] =
        note.trim() == "" ? null : note.trim();

    this.gridApi.applyTransaction({ update: [data4note] });
    this.gridApi.redrawRows();
    this.bringFocusBack();

    let data = [];
    this.gridApi.forEachNode(function (rowNode, index) {
      data.push(rowNode.data);
    });

    let otherForecast = new OtherForecast();
    otherForecast.rows = data;

    this.otherForecastService
      .update(otherForecast)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
  }

  onEditNotesShow() {
    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    if (cellMonth) {
      this.cellNote = data[`${type}not_${year}_${cellMonth}`];
    }
    if (!cellMonth) {
      this.cellNote = data[`${type}not_${year}_1`];
    }

    if (this.cellNote != null) return this.cellNote;
    else return "no note..";
  }

  onCarryForward() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    for (var month = cellMonth + 1; month < 13; month++) {
      this.setTotalRow();
      data[`${type}col_${year}_${month}`] = Number(
        data[`${type}col_${year}_${cellMonth}`]
      );
    }

    this.setTotalRow();
    this.refreshRowTotal(data, type, year, month);
    this.gridApi.applyTransaction({ update: [data] });
    this.bringFocusBack();

    return true;
  }

  onIncreaseAllMonthly(typeF) {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (typeF == 0) {
      if (this.increaseAllValue > -1) {
        let value = this.increaseAllValue;

        for (var month = cellMonth; month < 13; month++) {
          data[`${type}col_${year}_${month}`] = Number(
            Number(data[`${type}col_${year}_${month}`]) + Number(value)
          );
        }

        data[`${type}total_${year}`] = 0;
        for (month = 1; month < 13; month++) {
          data[`${type}total_${year}`] += Number(
            data[`${type}col_${year}_${month}`]
          );
        }

        this.setTotalRow();
        this.gridApi.applyTransaction({ update: [data] });
        this.bringFocusBack();
        return true;
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      }
    } else {
      if (this.increaseAllValueP > -1) {
        let value = this.increaseAllValueP;

        for (var month = cellMonth; month < 13; month++) {
          data[`${type}col_${year}_${month}`] = Number(
            Number(data[`${type}col_${year}_${month}`]) +
              (Number(data[`${type}col_${year}_${month}`]) * Number(value)) /
                100
          );
        }

        data[`${type}total_${year}`] = 0;
        for (month = 1; month < 13; month++) {
          data[`${type}total_${year}`] += Number(
            data[`${type}col_${year}_${month}`]
          );
        }

        this.setTotalRow();
        this.gridApi.applyTransaction({ update: [data] });
        this.bringFocusBack();
        return true;
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      }
    }
  }

  onIncreaseMonthly(typeF) {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (typeF == 0) {
      if (this.increaseValue > -1) {
        let value = this.increaseValue;

        for (var month = cellMonth + 1; month < 13; month++) {
          data[`${type}col_${year}_${month}`] = Number(
            Number(data[`${type}col_${year}_${month - 1}`]) + Number(value)
          );
        }

        data[`${type}total_${year}`] = 0;
        for (month = 1; month < 13; month++) {
          data[`${type}total_${year}`] += Number(
            data[`${type}col_${year}_${month}`]
          );
        }

        this.setTotalRow();
        this.gridApi.applyTransaction({ update: [data] });
        this.bringFocusBack();
        return true;
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      }
    } else {
      if (this.increaseValueP > -1) {
        let value = this.increaseValueP;

        for (var month = cellMonth + 1; month < 13; month++) {
          data[`${type}col_${year}_${month}`] = Number(
            Number(data[`${type}col_${year}_${month - 1}`]) +
              (Number(data[`${type}col_${year}_${month - 1}`]) *
                Number(value)) /
                100
          );
        }

        data[`${type}total_${year}`] = 0;
        for (month = 1; month < 13; month++) {
          data[`${type}total_${year}`] += Number(
            data[`${type}col_${year}_${month}`]
          );
        }

        this.setTotalRow();
        this.gridApi.applyTransaction({ update: [data] });
        this.bringFocusBack();
        return true;
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      }
    }
  }

  onDecreaseAllMonthly(typeF) {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (typeF == 0) {
      if (this.decreaseAllValue > -1) {
        let value = this.decreaseAllValue;

        for (var month = cellMonth; month < 13; month++) {
          data[`${type}col_${year}_${month}`] = Number(
            data[`${type}col_${year}_${month}`] - Number(value)
          );
        }

        data[`${type}total_${year}`] = 0;
        for (month = 1; month < 13; month++) {
          data[`${type}total_${year}`] += Number(
            data[`${type}col_${year}_${month}`]
          );
        }

        this.setTotalRow();
        this.gridApi.applyTransaction({ update: [data] });
        this.bringFocusBack();
        return true;
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    } else {
      if (this.decreaseAllValueP > -1) {
        let value = this.decreaseAllValueP;

        for (var month = cellMonth; month < 13; month++) {
          data[`${type}col_${year}_${month}`] = Number(
            data[`${type}col_${year}_${month}`] -
              (Number(data[`${type}col_${year}_${month}`]) * Number(value)) /
                100
          );
        }

        data[`${type}total_${year}`] = 0;
        for (month = 1; month < 13; month++) {
          data[`${type}total_${year}`] += Number(
            data[`${type}col_${year}_${month}`]
          );
        }

        this.setTotalRow();
        this.gridApi.applyTransaction({ update: [data] });
        this.bringFocusBack();
        return true;
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    }
  }

  onDecreaseMonthly(typeF) {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (typeF == 0) {
      if (this.decreaseValue > -1) {
        let value = this.decreaseValue;

        for (var month = cellMonth + 1; month < 13; month++) {
          data[`${type}col_${year}_${month}`] = Number(
            data[`${type}col_${year}_${month - 1}`] - Number(value)
          );
        }

        data[`${type}total_${year}`] = 0;
        for (month = 1; month < 13; month++) {
          data[`${type}total_${year}`] += Number(
            data[`${type}col_${year}_${month}`]
          );
        }

        this.setTotalRow();
        this.gridApi.applyTransaction({ update: [data] });
        this.bringFocusBack();
        return true;
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    } else {
      if (this.decreaseValueP > -1) {
        let value = this.decreaseValueP;

        for (var month = cellMonth + 1; month < 13; month++) {
          data[`${type}col_${year}_${month}`] = Number(
            data[`${type}col_${year}_${month - 1}`] -
              (Number(data[`${type}col_${year}_${month - 1}`]) *
                Number(value)) /
                100
          );
        }

        data[`${type}total_${year}`] = 0;
        for (month = 1; month < 13; month++) {
          data[`${type}total_${year}`] += Number(
            data[`${type}col_${year}_${month}`]
          );
        }

        this.setTotalRow();
        this.gridApi.applyTransaction({ update: [data] });
        this.bringFocusBack();
        return true;
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    }
  }

  copyActuals() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }
    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;
    let year = this.currentContextEvent.colDef.cellYear;
    let type = this.currentContextEvent.colDef.cellType;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    for (let month = 1; month < 13; month++) {
      if (data[`${type}col_-${this.year4actual}_${month}`]) {
        data[`${type}col_${year}_${month}`] =
          data[`${type}col_-${this.year4actual}_${month}`];
      }
    }

    //toplan column set
    data[`${type}total_${year}`] = 0;
    for (var month = 1; month < 13; month++) {
      data[`${type}total_${year}`] += Number(
        data[`${type}col_${year}_${month}`]
      );
    }

    this.setTotalRow();
    this.gridApi.applyTransaction({ update: [data] });
    this.bringFocusBack();
    return true;
  }

  copyScenario() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }
    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    this.otherForecastService
      .listPrices(this.copyScenarioId)
      .subscribe((result) => {
        if (!result) {
          this.translate
            .get("entryScreens.no4scenario")
            .subscribe((data: string) => {
              this.toastrService.warning(data);
            });
          return;
        }

        this.fullDataS = result.data;

        for (let rowS in this.fullDataS) {
          for (let row in this.fullData) {
            if (this.fullDataS[rowS].type == this.fullData[row].type) {
              let types = ["v", "a"];
              let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

              for (let type of types) {
                for (let year of this.years) {
                  for (let month of months) {
                    this.fullData[row][`${type}col_${year}_${month}`] =
                      this.fullDataS[rowS][`${type}col_${year}_${month}`];
                  }
                  this.fullData[row][`${type}total_${year}`] =
                    this.fullDataS[rowS][`${type}total_${year}`];
                }
              }
            }
          }
        }

        this.rowData = this.fullData.filter((x) => x.isVisible);
        this.fullData4Import = [...this.fullData];

        this.setExpandable();
        this.setTotalRow();
        this.changeVolume();
      });

    return true;
  }

  copyPlanning() {
    if (this.isScreenValid == false) {
      this.translate.get("entryScreens.incomeOut").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }
    if (!this.isImpactArea) {
      this.translate
        .get("entryScreens.notUsableImpact")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    this.scenarioService
      .listScenariosByGuidance(this.copyPlanningGuidanceId, 1)
      .subscribe((result) => {
        this.scenarioCopyList = result;
        this.scenarioGuiList = result;
      });
  }

  refreshRowTotal(data, type, year, month) {
    data[`${type}total_${year}`] = 0;
    for (month = 1; month < 13; month++) {
      data[`${type}total_${year}`] += Number(
        data[`${type}col_${year}_${month}`]
      );
    }
    data[`${type}total_${year}`] = data[`${type}total_${year}`] /*.toFixed(2)*/;
  }

  getNumericCellEditor() {
    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }
    function isCharDecimal(charStr) {
      return ".".indexOf(charStr) === 0;
    }
    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr) || isCharDecimal(charStr);
    }
    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === "undefined" ? event.keyCode : event.which;
    }
    function NumericCellEditor() {}
    NumericCellEditor.prototype.init = function (params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement("input");
      this.eInput.style.width = "100%";
      this.eInput.style.height = "100%";
      this.eInput.value = isCharNumeric(params.charPress)
        ? params.charPress
        : params.value;
      var that = this;
      this.eInput.addEventListener("keypress", function (event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };
    NumericCellEditor.prototype.getGui = function () {
      return this.eInput;
    };
    NumericCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        //this.eInput.select();
      }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function () {
      return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.isCancelAfterEnd = function () {};
    NumericCellEditor.prototype.getValue = function () {
      return this.eInput.value;
    };
    NumericCellEditor.prototype.focusIn = function () {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
      console.log("NumericCellEditor.focusIn()");
    };
    NumericCellEditor.prototype.focusOut = function () {
      console.log("NumericCellEditor.focusOut()");
    };
    return NumericCellEditor;
  }

  @ViewChild("userMenu") userMenu: TemplateRef<any>;
  sub: Subscription;
  overlayRef: OverlayRef | null;

  open({ x, y }: MouseEvent, user) {
    this.close();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top",
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(
      new TemplatePortal(this.userMenu, this.viewContainerRef, {
        $implicit: user,
      })
    );

    this.sub = fromEvent<MouseEvent>(document, "click")
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return (
            !!this.overlayRef &&
            !this.overlayRef.overlayElement.contains(clickTarget)
          );
        }),
        take(1)
      )
      .subscribe(() => this.close());
  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
    this.rulesPopup = false;
    this.notesPopup = false;
    this.colorPopup = false;
  }
}
