import { AbstractEnum } from "@planard/@core/base/AbstractEnum";

export class EnumCostType extends AbstractEnum {
  static readonly DEPARMENTAL = new EnumCostType("Deparmental");
  static readonly PRODUCT = new EnumCostType("Product");
  static readonly CENTRAL = new EnumCostType("Central");
  static readonly ASSETS = new EnumCostType("Assets");

  static values = AbstractEnum.getValues(EnumCostType);

  private constructor(public readonly displayValue: string) {
    super();
  }
}
