import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AgGridAngular } from "ag-grid-angular";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { Scenario } from "@planard/@core/entities/scenario";
import { MatDialog } from "@angular/material/dialog";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { Guidance } from "@planard/@core/entities/guidance";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { CentralPlanService } from "@planard/@core/backend/service/centralPlan.service";
import { CentralPlan } from "@planard/@core/entities/centralPlan";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ProductForm } from "@planard/@core/entities/productForm";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { ActivatedRoute } from "@angular/router";
import { ApproverRequestService } from "@planard/@core/backend/service/approverRequest.service";
import { AuthService } from "@planard/auth/services/auth.service";
import { TemplatePortal } from "@angular/cdk/portal";
import { fromEvent, Subscription } from "rxjs";
import { take, filter } from "rxjs/operators";
import { Router } from "@angular/router";
import { isNumeric } from "rxjs/internal-compatibility";
import { TranslateService } from "@ngx-translate/core";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import * as XLSX from "xlsx";
@Component({
  selector: "centralPlan-approver",
  templateUrl: "./centralPlan-approver.component.html",
  styleUrls: ["./centralPlan-approver.component.scss"],
  providers: [NumberDecimalPipe],
})
export class CentralPlanApproverComponent implements OnInit {
  //#region
  @ViewChild("agGrid") agGrid: AgGridAngular;
  private gridApi;
  private gridColumnApi;

  numberFormat;
  decimalFormat;

  scenarioList: Scenario[];
  scenarioList2Base: Scenario[];
  scenarioCopyList: Scenario[];
  months: string[];
  years: number[];
  yearsCopyActual: number[];
  guidanceList: Guidance[];
  scenarioGuiList: Scenario[] = [];
  formList: ProductForm[];
  cellNote: any; //gösterilecek olan not.
  volume: boolean;
  amount: boolean;
  scenario: Scenario;
  scenarioId: number;
  copyScenarioId: number;
  guidanceId: number;
  copyPlanningGuidanceId: number;
  currentGuidanceId: number;
  percent2coloring: Number = 40;
  carryForwardClicked: boolean;
  increaseMonthlyClicked: boolean;
  decreaseMonthlyClicked: boolean;
  increaseMonthlyPClicked: boolean;
  decreaseMonthlyPClicked: boolean;
  increaseAllMonthlyClicked: boolean;
  decreaseAllMonthlyClicked: boolean;
  increaseAllMonthlyPClicked: boolean;
  decreaseAllMonthlyPClicked: boolean;
  sustainTrendClicked: boolean;
  rulesClicked: boolean;
  exportClicked: boolean;
  importClicked: boolean;
  exelExportClicked: boolean;
  exelImportClicked: boolean;
  copyPlannngClicked: boolean;
  copyClicked: boolean;
  pasteClicked: boolean;
  copyHeadersClicked: boolean;
  notesClicked: boolean;
  priceNotesClicked: boolean;
  discountNotesClicked: boolean;
  copyActualsClicked: boolean;
  copyScenarioClicked: boolean;
  copyPlaningClicked: boolean;
  sheetName: string = "Costs";
  colorPopup: boolean = false;
  rulesPopup: boolean = false;
  notesPopup: boolean = false;
  increaseValueP: number;
  decreaseValueP: number;
  increaseAllValue: number;
  decreaseAllValue: number;
  increaseAllValueP: number;
  decreaseAllValueP: number;
  input: boolean;
  notes: boolean;
  guidaneSet: boolean;
  exelPriceData: any;
  exelDiscountData: any;
  salesForecastId: number;
  exelYear: number = 2020;
  exelName: string;
  file: File;
  filelist: any;
  arrayBuffer: any;
  forecastLenght: number;
  value2copy: any;
  increaseValue: number;
  decreaseValue: number;
  noteValue: any;
  file_name: string;
  formConversionRate: number = 1;
  guidanceName: any;
  guidancePlanningPeriodList: GuidancePlanningPeriod[];
  year4actual: number;
  approverRequestId: any;
  nextApproverId: any;
  rejectButton: boolean = true;
  rejectReason = "";
  rowData: any;
  fullData: any;
  fullData4Import: any;
  rowDataS: any;
  fullDataS: any;
  isScreenValid: boolean;
  data2Exel: any;
  headData: any; // excel row header
  isRoleValid: boolean;
  x: number;
  y4c: number;
  y4d: number;
  companyPackage: string;
  currentYear = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  defaultColDef = {
    sortable: false,
    flex: 1,
    minWidth: 80,
    filter: false,
    resizable: true,
    cellEditor: "numericCellEditor",
  };

  components = { numericCellEditor: this.getNumericCellEditor() };
  columnDefs = [];
  loadingTemplate;
  noRowsTemplate;
  costName;

  isOpen: boolean = false;
  //#endregion

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public toastrService: ToastrService,
    public centralPlanService: CentralPlanService,
    public productsService: ProductsService,
    public guidanceService: GuidanceService,
    public approverRequestService: ApproverRequestService,
    private _Activatedroute: ActivatedRoute,
    public scenarioService: ScenarioService,
    public translate: TranslateService,
    private numberDecimal: NumberDecimalPipe,
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.translate.get("agGrid.loading").subscribe((data: any) => {
      this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
    });
    this.translate.get("agGrid.no").subscribe((data: any) => {
      this.noRowsTemplate = `"<span">${data}</span>"`;
    });

    if (this.translate.currentLang == "en") this.costName = "Cost";
    if (this.translate.currentLang == "tr") this.costName = "Masraf";

    translate.onLangChange.subscribe((lang) => {
      this.translate.get("agGrid.loading").subscribe((data: any) => {
        this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
      });
      this.translate.get("agGrid.no").subscribe((data: any) => {
        this.noRowsTemplate = `"<span">${data}</span>"`;
      });

      if (this.translate.currentLang == "en") this.costName = "Cost";
      if (this.translate.currentLang == "tr") this.costName = "Masraf";

      this.changeVolume();
    });

    this.months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();
    this.isRoleValid = this.authService.isSupportFunctionApprover();
    this.companyPackage = this.authService.getCompanyPackage();
    if (this.isRoleValid == false || this.companyPackage == "basic")
      this.router.navigate(["auth/404"]);
    this.isScreenValid = this.authService.centralCostApproval();
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanTaskApproval")
        .subscribe((data: string) => {
          this.toastrService.error(data);
        });
    }
  }

  ngOnInit() {
    this._Activatedroute.paramMap.subscribe((params) => {
      this.approverRequestId = Number(params.get("requestId"));
    });
    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidanceName = result.planningCycleName;
      this.guidanceId = result.id;
      this.currentGuidanceId = result.id;
      this.guidanceService.getPlanningRecord(result.id).subscribe((result) => {
        this.guidancePlanningPeriodList = result;
      });
    });
  }

  onGridSet() {
    // setTimeout(() => {
    //   this.autoSizeAll(true);
    // }, 1000);
  }

  autoSizeAll(skipHeader) {
    //this.gridApi.sizeColumnsToFit();
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  addOnClick(event) {
    this.x = event.pageX + 75;
    this.y4c = event.pageY + 100;
    this.y4d = event.pageY + 50;
  }

  approve() {
    let approverRequest = {
      approveRequestId: this.approverRequestId,
      rejectMessage: null,
    };

    this.approverRequestService
      .requestApprove(approverRequest)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.router.navigate(["planning-costs/cost-approvals"]);
              this.toastrService.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
  }

  reject() {
    if (this.rejectReason == "") {
      this.translate
        .get("entryScreens.rejectReasonPlease")
        .subscribe((data: any) => {
          this.toastrService.error(data);
        });
      return;
    }

    let approverRequest = {
      approveRequestId: this.approverRequestId,
      rejectMessage: this.rejectReason,
    };

    this.approverRequestService
      .requestReject(approverRequest)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.router.navigate(["planning-costs/cost-approvals"]);
              this.toastrService.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    this.rejectReason = "";
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    setTimeout(() => {
      this.getForecast();
    }, 1000);
  }

  getForecast() {
    this.centralPlanService
      .listCost(0, this.approverRequestId)
      .subscribe((result) => {
        this.fullData = result.data;
        this.fullData4Import = [...result.data];

        this.rowData = [...result.data].filter((x) => x.isVisible);

        var neg = [];
        var pos = [];

        neg = result.years.filter((x) => x < 0).sort((a, b) => b - a);
        pos = result.years.filter((x) => x > 0).sort((a, b) => a - b);

        this.years = [...neg, ...pos];
        this.yearsCopyActual = [
          ...neg.map((x) => x * -1),
          ...[this.currentYear],
        ];
        this.year4actual = this.yearsCopyActual[0];
        this.forecastLenght = this.fullData.length;

        this.changeVolume();
        this.setTotalRow();
        this.setExpandable();
      });
  }

  setClick() {
    this.carryForwardClicked = false;
    this.increaseMonthlyClicked = false;
    this.decreaseMonthlyClicked = false;
    this.sustainTrendClicked = false;
    this.rulesClicked = false;
    this.exportClicked = false;
    this.importClicked = false;
    this.exelExportClicked = false;
    this.exelImportClicked = false;
    this.copyClicked = false;
    this.pasteClicked = false;
    this.copyHeadersClicked = false;
    this.notesClicked = false;
    this.priceNotesClicked = false;
    this.discountNotesClicked = false;
    this.copyActualsClicked = false;
    this.copyScenarioClicked = false;
    this.copyPlannngClicked = false;
    this.increaseMonthlyPClicked = false;
    this.decreaseMonthlyPClicked = false;
    this.increaseAllMonthlyClicked = false;
    this.decreaseAllMonthlyClicked = false;
    this.increaseAllMonthlyPClicked = false;
    this.decreaseAllMonthlyPClicked = false;
  }

  setExpandable() {
    for (let line of this.fullData) {
      var id = line.id;
      var isParent = false;
      for (let lin of this.fullData) {
        if (id == lin.parentId) {
          isParent = true;
        }
      }
      line.expandable = isParent;
    }
  }

  setTotalRow() {
    let totalRow: any = [...this.fullData[0]];
    totalRow.cost = "TOTAL : ";

    let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    for (let year of this.years) {
      for (let month of months) {
        let total = 0;
        for (let row of this.fullData) {
          if (
            row[`col_${year}_${month}`] != undefined &&
            row.departmentId == null &&
            row.subDepartmentId == null
          )
            total = total + Number(row[`col_${year}_${month}`]);
        }
        totalRow[`col_${year}_${month}`] = total.toFixed(2);
      }
      totalRow[`total_${year}`] = Math.round(
        Number(totalRow[`col_${year}_1`]) +
          Number(totalRow[`col_${year}_2`]) +
          Number(totalRow[`col_${year}_3`]) +
          Number(totalRow[`col_${year}_4`]) +
          Number(totalRow[`col_${year}_5`]) +
          Number(totalRow[`col_${year}_6`]) +
          Number(totalRow[`col_${year}_7`]) +
          Number(totalRow[`col_${year}_8`]) +
          Number(totalRow[`col_${year}_9`]) +
          Number(totalRow[`col_${year}_10`]) +
          Number(totalRow[`col_${year}_11`]) +
          Number(totalRow[`col_${year}_12`])
      );
    }

    this.gridApi.setPinnedBottomRowData([totalRow]);
  }

  getProductDef() {
    return {
      headerName: this.costName,
      field: "cost",
      /*lockPosition: true,*/ pinned: "left",
      editable: this.cellEditable,
      minWidth: 200,
      visible: false,
      cellRenderer: this.productCellRenderer,
    };
  }

  cellEditable(params) {
    return false;
  }

  productCellRenderer(params) {
    let style = "";
    if (params.data.parentId != 0) style = 'style="padding-left: 10px;"';
    if (params.data.expandable && params.data.selected) {
      if (
        params.data[`subDepartmentId`] == null &&
        params.data[`departmentId`] != null
      ) {
        return (
          `<span class="ag-icon ag-icon-tree-open" ` +
          style +
          `></span> ` +
          `<span style="color: green;">` +
          params.value +
          `</span>` +
          ``
        );
      } else {
        return (
          `<span class="ag-icon ag-icon-tree-open" ` +
          style +
          `></span> ` +
          params.value +
          ``
        );
      }
    } else if (params.data.expandable) {
      if (
        params.data[`subDepartmentId`] == null &&
        params.data[`departmentId`] != null
      ) {
        return (
          `<span class="ag-icon ag-icon-contracted" ` +
          style +
          `></span> ` +
          `<span style="color: green;">` +
          params.value +
          `</span>` +
          ``
        );
      } else {
        return (
          `<span class="ag-icon ag-icon-contracted" ` +
          style +
          `></span> ` +
          params.value +
          ``
        );
      }
    } else if (
      !params.data.expandable &&
      params.data[`subDepartmentId`] == null &&
      params.data[`departmentId`] == null &&
      params.data.cost != "TOTAL : "
    ) {
      // product has no child
      return `<span` + style + `></span> ` + params.value + ``;
    } else if (
      !params.data.expandable &&
      params.data[`subDepartmentId`] == null &&
      params.data.cost != "TOTAL : "
    ) {
      // form has no child
      return (
        `<span ` +
        style +
        `></span> ` +
        `<span style="color: green;">` +
        params.value +
        `</span>` +
        ``
      );
    } else if (params.data.cost == "TOTAL : ") {
      return (
        `<span style="padding-left: 40px;"></span>` +
        `<span style="color: #ff7500;">` +
        params.value +
        `</span>`
      );
    }
    return (
      `<span style="padding-left: 40px;"></span>` +
      `<span style="color: blue;">` +
      params.value +
      `</strong>`
    );
  }

  getYearColumnDef(year) {
    let numberDecimal = this.numberDecimal;
    let numberFormat = this.numberFormat;
    let decimalFormat = this.decimalFormat;
    let rate = Number(this.percent2coloring) / 100;
    let rate2diff = (100 - Number(this.percent2coloring)) / 100;
    var name = this.guidanceName;
    var isYearly: boolean = false;
    let guidanceId = this.guidanceId;
    let isScreenValid = this.isScreenValid;
    let currentGuidanceId = this.currentGuidanceId;
    let years = this.years;
    let yearIndex;
    for (let index in this.years) {
      if (this.years[index] == year) yearIndex = Number(index);
    }
    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Yearly") {
        isYearly = true;
      }
    });
    if (!isYearly) {
      return {
        headerName: `${year > 0 ? name : ""} ${
          year < 0 ? "A`" + year * -1 : year
        }`,
        children: [
          {
            headerName: "Total",
            minWidth: 150,
            copyHeadersToClipboard: true,
            suppressClipboardPaste: true,
            editable: function (params) {
              if (!isScreenValid) return false;
              if (guidanceId != currentGuidanceId) return false;
              if (year < 0) return false;
              if (params.data.expandable) return false;
              if (params.data.cost == "TOTAL : ") return false;
              return true;
            },
            columnGroupShow: "closed",
            cellYear: year,
            valueGetter: function (params) {
              return Number(params.data[`total_${year}`]).toFixed(2);
            },
            cellStyle: function (params) {
              if (
                Number(params.data[`total_${year}`]) <
                  Number(
                    params.data[`total_${years[yearIndex - 1]}`] * rate2diff
                  ) &&
                params.data.expandable != true &&
                params.data.cost != "TOTAL : "
              ) {
                return { color: "black", backgroundColor: "#fff0ee" }; //düşüş durumunda
              } else if (
                Number(params.data[`total_${year}`]) >
                  Number(
                    params.data[`total_${years[yearIndex - 1]}`] +
                      params.data[`total_${years[yearIndex - 1]}`] * rate
                  ) &&
                params.data.expandable != true &&
                params.data.cost != "TOTAL : "
              ) {
                return { color: "black", backgroundColor: "#f3fff3" }; // yükselme durumunda
              } else if (params.data.cost == "TOTAL : ") {
                return { color: "#ff7500" };
              }
            },
            valueSetter: function (params) {
              params.data[`total_${year}`] = Number(params.newValue);
              return true;
            },
            valueFormatter: function (params) {
              if (params.value == undefined || params.value == "NaN") {
                return "0.00";
              }
              return numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            },
          },
          this.getMonthColumnDef(year, 1),
          this.getMonthColumnDef(year, 2),
          this.getMonthColumnDef(year, 3),
          this.getMonthColumnDef(year, 4),
          this.getMonthColumnDef(year, 5),
          this.getMonthColumnDef(year, 6),
          this.getMonthColumnDef(year, 7),
          this.getMonthColumnDef(year, 8),
          this.getMonthColumnDef(year, 9),
          this.getMonthColumnDef(year, 10),
          this.getMonthColumnDef(year, 11),
          this.getMonthColumnDef(year, 12),
          {
            headerName: "Total",
            minWidth: 150,
            copyHeadersToClipboard: true,
            suppressClipboardPaste: true,
            editable: function (params) {
              if (guidanceId != currentGuidanceId) return false;
              if (year < 0) return false;
              if (params.data.expandable) return false;
              if (params.data.cost == "TOTAL : ") return false;
              return true;
            },
            columnGroupShow: "open",
            cellYear: year,
            valueGetter: function (params) {
              return Number(params.data[`total_${year}`]).toFixed(2);
            },
            cellStyle: function (params) {
              if (params.data.cost == "TOTAL : ") {
                return { color: "#ff7500" };
              }
            },
            valueSetter: function (params) {
              params.data[`total_${year}`] = Number(params.newValue);
              return true;
            },
            valueFormatter: function (params) {
              if (params.value == undefined || params.value == "NaN") {
                return "0.00";
              }
              return numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            },
          },
        ],
      };
    } else {
      return {
        headerName: `${year > 0 ? name : ""} ${
          year < 0 ? "A`" + year * -1 : year
        }`,
        children: [
          {
            headerName: "Total",
            minWidth: 150,
            copyHeadersToClipboard: true,
            suppressClipboardPaste: true,
            editable: function (params) {
              if (!isScreenValid) return false;
              if (guidanceId != currentGuidanceId) return false;
              if (year < 0) return false;
              if (params.data.expandable) return false;
              if (params.data.cost == "TOTAL : ") return false;
              return true;
            },
            columnGroupShow: "closed",
            cellYear: year,
            valueGetter: function (params) {
              return Number(params.data[`total_${year}`]).toFixed(2);
            },
            cellStyle: function (params) {
              if (
                Number(params.data[`total_${year}`]) <
                  Number(
                    params.data[`total_${years[yearIndex - 1]}`] * rate2diff
                  ) &&
                params.data.expandable != true &&
                params.data.cost != "TOTAL : "
              ) {
                return { color: "black", backgroundColor: "#fff0ee" }; //düşüş durumunda
              } else if (
                Number(params.data[`total_${year}`]) >
                  Number(
                    params.data[`total_${years[yearIndex - 1]}`] +
                      params.data[`total_${years[yearIndex - 1]}`] * rate
                  ) &&
                params.data.expandable != true &&
                params.data.cost != "TOTAL : "
              ) {
                return { color: "black", backgroundColor: "#f3fff3" }; // yükselme durumunda
              }
              //else if( params.data.expandable == true ){
              //return {backgroundColor: '#f6f6f6'};
              //}
              else if (params.data.cost == "TOTAL : ") {
                return { color: "#ff7500" };
              }
            },
            valueSetter: function (params) {
              params.data[`total_${year}`] = Number(params.newValue);
              return true;
            },
            valueFormatter: function (params) {
              if (params.value == undefined || params.value == "NaN") {
                return "0.00";
              }
              return numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            },
          },
        ],
      };
    }
  }

  getMonthColumnDef(year, month) {
    let numberDecimal = this.numberDecimal;
    let numberFormat = this.numberFormat;
    let decimalFormat = this.decimalFormat;
    this.percent2coloring;
    let rate = Number(this.percent2coloring) / 100;
    let rate2diff = (100 - Number(this.percent2coloring)) / 100;
    let guidanceId = this.guidanceId;
    let isScreenValid = this.isScreenValid;
    let currentGuidanceId = this.currentGuidanceId;
    var startMonth: any = 1;
    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Monthly") {
        startMonth = element.startMonth;
      }
    });
    return {
      headerName: this.months[month - 1],
      minWidth: 85,
      copyHeadersToClipboard: true,
      suppressClipboardPaste: true,
      valueGetter: function (params) {
        return /*month >= startMonth ?*/ Number(
          params.data[`col_${year}_${month}`]
        ).toFixed(2); /* : null*/
      },
      valueSetter: function (params) {
        params.data[`col_${year}_${month}`] = Number(params.newValue);
        return true;
      },
      columnGroupShow: "open",
      tooltipField: `not_${year}_${month}`,
      cellMonth: month,
      cellYear: year,
      editable: function (params) {
        if (!isScreenValid) return false;
        if (month < startMonth) return false;
        if (guidanceId != currentGuidanceId) return false;
        if (year < 0) return false;
        if (params.data.expandable) return false;
        if (params.data.cost == "TOTAL : ") return false;
        return true;
      },
      cellStyle: function (params) {
        if (
          Number(params.data[`col_${year}_${month}`]) <
            Number(params.data[`col_${year}_${month - 1}`] * rate2diff) &&
          params.data.expandable != true &&
          params.data.cost != "TOTAL : " &&
          month - 1 >= startMonth
        ) {
          if (params.data[`not_${year}_${month}`] != null) {
            return {
              color: "black",
              backgroundColor: "#fff0ee",
              border: "solid",
              borderTopWidth: "0.0px",
              borderRightWidth: "0.0px",
              borderLeftWidth: "0.0px",
              borderBottomWidth: "2px",
              borderColor: "#BB8FCE",
            }; // yükselme durumunda
          }
          return { color: "black", backgroundColor: "#fff0ee" }; //düşüş durumunda
        } else if (
          Number(params.data[`col_${year}_${month}`]) >
            Number(
              params.data[`col_${year}_${month - 1}`] +
                params.data[`col_${year}_${month - 1}`] * rate
            ) &&
          params.data.expandable != true &&
          params.data.cost != "TOTAL : " &&
          month - 1 >= startMonth
        ) {
          if (params.data[`not_${year}_${month}`] != null) {
            return {
              color: "black",
              backgroundColor: "#f3fff3",
              border: "solid",
              borderTopWidth: "0.0px",
              borderRightWidth: "0.0px",
              borderLeftWidth: "0.0px",
              borderBottomWidth: "2px",
              borderColor: "#BB8FCE",
            }; // yükselme durumunda
          }
          return { color: "black", backgroundColor: "#f3fff3" }; // yükselme durumunda
        } else if (
          params.data[`not_${year}_${month}`] != null &&
          params.data.expandable != true
        ) {
          return {
            border: "solid",
            borderTopWidth: "0.0px",
            borderRightWidth: "0.0px",
            borderLeftWidth: "0.0px",
            borderBottomWidth: "2px",
            borderColor: "#BB8FCE",
          }; // yükselme durumunda
        } else if (params.data.cost == "TOTAL : ") {
          return { color: "#ff7500" };
        }
        return { color: "initial", backgroundColor: "initial" };
      },
      valueFormatter: function (params) {
        if (params.value == undefined || params.value == "NaN") {
          return "0.00";
        }
        return numberDecimal.transform(
          params.value,
          decimalFormat,
          numberFormat
        );
      },
    };
  }

  getSelectedRows() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const selectedDataStringPresentation = selectedData
      .map((node) => node.make + " " + node.model)
      .join(", ");
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  }

  changeColor() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());

    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef(year));
    });

    this.gridApi.setColumnDefs(this.columnDefs);
    this.translate
      .get("entryScreens.coloringRate")
      .subscribe((data: string) => {
        this.toastrService.warning(data);
      });
  }

  changeVolume() {
    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef(year));
    });
    this.gridApi.setColumnDefs(this.columnDefs);
  }

  save() {
    let data = [];
    let startMonths = [];

    let currentYear = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear();

    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year <= currentYear && element.planningType == "Monthly") {
        startMonths.push({
          startMonth: element.startMonth,
          year: element.year,
        });
      }
    });

    this.fullData.forEach((rowNode) => {
      startMonths.forEach((ele) => {
        for (let month = 1; month < ele.startMonth; month++) {
          if (rowNode[`col_${ele.year}_${month}`])
            rowNode[`col_${ele.year}_${month}`] = 0;
        }
      });
      data.push(rowNode);
    });

    let centralplan = new CentralPlan();
    centralplan.rows = data;

    this.centralPlanService.update(centralplan).subscribe((backendResult) => {
      if (backendResult.isSuccess) {
        this.translate
          .get(`messages.${backendResult.messageId}`)
          .subscribe((data: any) => {
            this.toastrService.success(data);
          });
      } else {
        this.translate
          .get(`messages.${backendResult.messageId}`)
          .subscribe((data: any) => {
            this.toastrService.error(data);
          });
      }
    });
  }

  isEditable(data, month, year): Boolean {
    var startMonth = 0;
    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Monthly") {
        startMonth = element.startMonth;
      }
    });

    if (!this.isScreenValid) return false;
    if (month < startMonth) return false;
    if (this.guidanceId != this.currentGuidanceId) return false;
    if (year < 0) return false;
    if (data.expandable) return false;
    if (data.cost == "TOTAL : ") return false;
    return true;
  }

  setScenario() {
    for (let sc of Object.keys(this.scenarioList)) {
      if (this.scenarioId == this.scenarioList[sc].id) {
        this.file_name = "CentralCost-" + this.scenarioList[sc].name + ".xlsx";
        return this.scenarioList[sc].name;
      }
    }
  }

  private bringFocusBack() {
    let cell = this.gridApi.getFocusedCell();

    if (cell) {
      this.gridApi.setFocusedCell(cell.rowIndex, cell.column);
    }
  }

  setExportData() {
    let emptyRow: any[];
    let scenario: any[] = ["", "Scenario Name : " + " " + this.setScenario()];
    let guide: any[] = [
      "",
      "Excel User Guide : On This Excel Sheet Change Only Costs. Do not Remove Any Column or Row and Do not Change Their Names.",
    ];

    let header = ["SalesForecastId", "Name"];

    for (let year of this.years) {
      header.push(`${year} Jan`);
      header.push(`${year} Feb`);
      header.push(`${year} Mar`);
      header.push(`${year} Apr`);
      header.push(`${year} May`);
      header.push(`${year} Jun`);
      header.push(`${year} Jul`);
      header.push(`${year} Aug`);
      header.push(`${year} Sep`);
      header.push(`${year} Oct`);
      header.push(`${year} Nov`);
      header.push(`${year} Dec`);
    }

    this.data2Exel = [];
    this.data2Exel.push(header);

    this.fullData.forEach((element) => {
      this.salesForecastId = element.id;

      if (element.departmentId != null && element.subDepartmentId == null) {
        this.exelName = " " + " " + " " + " " + " " + " " + element.cost;
      } else if (element.departmentId != null) {
        this.exelName =
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          element.cost;
      } else {
        this.exelName = element.cost;
      }

      let row: any[] = [this.salesForecastId, this.exelName];
      for (let year of this.years) {
        row.push(element[`col_${year}_1`]);
        row.push(element[`col_${year}_2`]);
        row.push(element[`col_${year}_3`]);
        row.push(element[`col_${year}_4`]);
        row.push(element[`col_${year}_5`]);
        row.push(element[`col_${year}_6`]);
        row.push(element[`col_${year}_7`]);
        row.push(element[`col_${year}_8`]);
        row.push(element[`col_${year}_9`]);
        row.push(element[`col_${year}_10`]);
        row.push(element[`col_${year}_11`]);
        row.push(element[`col_${year}_12`]);
      }
      this.data2Exel.push(row);
    });
    this.data2Exel.push(emptyRow);
    this.data2Exel.push(scenario);
    this.data2Exel.push(guide);

    this.export();
  }

  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2Exel, {
      cellStyles: true,
    });

    ws["B1"].s = {};
    ws["B1"].s = { fill: { bgColor: { rgb: "ff6600" } } };

    ws["!cols"] = [];
    ws["!cols"][0] = { hidden: true };
    ws["!cols"][1] = { wpx: 200 };

    ws["!rows"] = [];
    ws["!rows"][0] = { hpx: 35 };
    ws["!rows"][this.forecastLenght + 2] = { hpx: 35 };
    ws["!rows"][this.forecastLenght + 3] = { hpx: 35 };

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.sheetName);
    XLSX.writeFile(wb, this.file_name);
  }

  onFileAdd(event: any) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.filelist = [];

      if ((arraylist.length - 2) % this.forecastLenght === 0) {
        this.fullData4Import = arraylist;
        for (let row in this.fullData) {
          for (let year of this.years) {
            if (
              year > 0 &&
              this.isScreenValid &&
              this.guidanceId == this.currentGuidanceId /*&&
                !this.fullData[row].isSubmit*/
            ) {
              this.guidancePlanningPeriodList.forEach((element) => {
                if (element.year == year && element.planningType == "Monthly") {
                  let startMonth = element.startMonth;

                  let ArrMo = [
                    "",
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];

                  for (var month = startMonth; month < 13; month++) {
                    this.fullData[row][`col_${year}_${month}`] =
                      this.fullData4Import[row][`${year} ${ArrMo[month]}`];
                  }

                  let total = 0;
                  let totaln = 0;
                  for (var mnth = 1; mnth < 13; mnth++) {
                    total += Number(this.fullData[row][`col_${year}_${mnth}`]);
                    totaln += Number(
                      this.fullData[row][`ncol_${year}_${mnth}`]
                    );
                  }
                } else {
                  this.fullData[row][`col_${year}_1`] =
                    this.fullData4Import[row][`${year} Jan`];
                  this.fullData[row][`col_${year}_2`] =
                    this.fullData4Import[row][`${year} Feb`];
                  this.fullData[row][`col_${year}_3`] =
                    this.fullData4Import[row][`${year} Mar`];
                  this.fullData[row][`col_${year}_4`] =
                    this.fullData4Import[row][`${year} Apr`];
                  this.fullData[row][`col_${year}_5`] =
                    this.fullData4Import[row][`${year} May`];
                  this.fullData[row][`col_${year}_6`] =
                    this.fullData4Import[row][`${year} Jun`];
                  this.fullData[row][`col_${year}_7`] =
                    this.fullData4Import[row][`${year} Jul`];
                  this.fullData[row][`col_${year}_8`] =
                    this.fullData4Import[row][`${year} Aug`];
                  this.fullData[row][`col_${year}_9`] =
                    this.fullData4Import[row][`${year} Sep`];
                  this.fullData[row][`col_${year}_10`] =
                    this.fullData4Import[row][`${year} Oct`];
                  this.fullData[row][`col_${year}_11`] =
                    this.fullData4Import[row][`${year} Nov`];
                  this.fullData[row][`col_${year}_12`] =
                    this.fullData4Import[row][`${year} Dec`];

                  this.fullData[row][`total_${year}`] =
                    this.fullData4Import[row][`${year} Jan`] +
                    this.fullData4Import[row][`${year} Feb`] +
                    this.fullData4Import[row][`${year} Mar`] +
                    this.fullData4Import[row][`${year} Apr`] +
                    this.fullData4Import[row][`${year} May`] +
                    this.fullData4Import[row][`${year} Jun`] +
                    this.fullData4Import[row][`${year} Jul`] +
                    this.fullData4Import[row][`${year} Aug`] +
                    this.fullData4Import[row][`${year} Sep`] +
                    this.fullData4Import[row][`${year} Oct`] +
                    this.fullData4Import[row][`${year} Nov`] +
                    this.fullData4Import[row][`${year} Dec`];
                }
              });
            }
          }
        }

        let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

        /* Sub-department'ten Department'e Yazma */
        for (let row of Object.keys(this.fullData)) {
          if (
            this.fullData[row][`costId`] != null &&
            this.fullData[row][`departmentId`] != null &&
            this.fullData[row][`subDepartmentId`] == null
          ) {
            let deptId = this.fullData[row][`departmentId`];
            let catId = this.fullData[row][`costId`];

            for (let year of this.years) {
              this.fullData[row][`col_${year}_1`] = 0;
              this.fullData[row][`col_${year}_2`] = 0;
              this.fullData[row][`col_${year}_3`] = 0;
              this.fullData[row][`col_${year}_4`] = 0;
              this.fullData[row][`col_${year}_5`] = 0;
              this.fullData[row][`col_${year}_6`] = 0;
              this.fullData[row][`col_${year}_7`] = 0;
              this.fullData[row][`col_${year}_8`] = 0;
              this.fullData[row][`col_${year}_9`] = 0;
              this.fullData[row][`col_${year}_10`] = 0;
              this.fullData[row][`col_${year}_11`] = 0;
              this.fullData[row][`col_${year}_12`] = 0;
            }

            for (let rowC of Object.keys(this.fullData)) {
              if (
                this.fullData[rowC][`costId`] == catId &&
                this.fullData[rowC][`departmentId`] == deptId &&
                this.fullData[rowC][`subDepartmentId`] != null
              ) {
                for (let year of this.years) {
                  for (let month of months) {
                    this.fullData[row][`col_${year}_${month}`] += isNumeric(
                      this.fullData[rowC][`col_${year}_${month}`]
                    )
                      ? this.fullData[rowC][`col_${year}_${month}`]
                      : 0;
                  }

                  this.fullData[row][`total_${year}`] =
                    this.fullData[row][`col_${year}_1`] +
                    this.fullData[row][`col_${year}_2`] +
                    this.fullData[row][`col_${year}_3`] +
                    this.fullData[row][`col_${year}_4`] +
                    this.fullData[row][`col_${year}_5`] +
                    this.fullData[row][`col_${year}_6`] +
                    this.fullData[row][`col_${year}_7`] +
                    this.fullData[row][`col_${year}_8`] +
                    this.fullData[row][`col_${year}_9`] +
                    this.fullData[row][`col_${year}_10`] +
                    this.fullData[row][`col_${year}_11`] +
                    this.fullData[row][`col_${year}_12`];
                }
              }
            }
          }
        }

        /* Department'ten Cost'a Yazma */
        for (let row of Object.keys(this.fullData)) {
          if (
            this.fullData[row][`costId`] != null &&
            this.fullData[row][`departmentId`] == null &&
            this.fullData[row][`subDepartmentId`] == null
          ) {
            let costId = this.fullData[row][`costId`];

            for (let year of this.years) {
              this.fullData[row][`col_${year}_1`] = 0;
              this.fullData[row][`col_${year}_2`] = 0;
              this.fullData[row][`col_${year}_3`] = 0;
              this.fullData[row][`col_${year}_4`] = 0;
              this.fullData[row][`col_${year}_5`] = 0;
              this.fullData[row][`col_${year}_6`] = 0;
              this.fullData[row][`col_${year}_7`] = 0;
              this.fullData[row][`col_${year}_8`] = 0;
              this.fullData[row][`col_${year}_9`] = 0;
              this.fullData[row][`col_${year}_10`] = 0;
              this.fullData[row][`col_${year}_11`] = 0;
              this.fullData[row][`col_${year}_12`] = 0;
            }

            for (let rowC of Object.keys(this.fullData)) {
              if (
                this.fullData[rowC][`costId`] == costId &&
                this.fullData[rowC][`departmentId`] != null &&
                this.fullData[rowC][`subDepartmentId`] == null
              ) {
                for (let year of this.years) {
                  for (let month of months) {
                    this.fullData[row][`col_${year}_${month}`] += isNumeric(
                      this.fullData[rowC][`col_${year}_${month}`]
                    )
                      ? this.fullData[rowC][`col_${year}_${month}`]
                      : 0;
                  }

                  this.fullData[row][`total_${year}`] =
                    this.fullData[row][`col_${year}_1`] +
                    this.fullData[row][`col_${year}_2`] +
                    this.fullData[row][`col_${year}_3`] +
                    this.fullData[row][`col_${year}_4`] +
                    this.fullData[row][`col_${year}_5`] +
                    this.fullData[row][`col_${year}_6`] +
                    this.fullData[row][`col_${year}_7`] +
                    this.fullData[row][`col_${year}_8`] +
                    this.fullData[row][`col_${year}_9`] +
                    this.fullData[row][`col_${year}_10`] +
                    this.fullData[row][`col_${year}_11`] +
                    this.fullData[row][`col_${year}_12`];
                }
              }
            }
          }
        }

        this.rowData = this.fullData.filter((x) => x.isVisible);
        this.fullData4Import = [...this.fullData];

        this.setExpandable();
        this.changeVolume();
      } else {
        this.translate.get("entryScreens.corrupted").subscribe((data: any) => {
          this.toastrService.error(data);
        });
      }
    };
  }

  onColumnSpread(event: any) {
    let newValue: number = Number(event.value);
    let year = event.colDef.cellYear;
    let month = event.colDef.cellMonth ? event.colDef.cellMonth : 0;
    let data = event.data;

    if (month != 0) {
      this.columnSpread(data.id, newValue, data.costId, year, month);

      /* grid total row refresh */
      this.refreshRowTotal(data, year);

      this.gridApi.applyTransaction({ update: [data] });
      this.setTotalRow();
      this.bringFocusBack();
    } else if (month == 0) {
      let startM: any;
      this.guidancePlanningPeriodList.forEach((element) => {
        //monthly hesaplanan total dağıtımı
        if (element.year == year && element.planningType == "Monthly") {
          startM = element.startMonth;
          var actualsTotal = 0;
          for (let actM = 1; actM < startM; actM++) {
            actualsTotal += Number(data[`col_${year}_${actM}`]);
          }

          let val =
            (Number(data[`total_${year}`]) - actualsTotal) /
            (12 - (startM - 1));

          for (let mnth = startM; mnth < 13; mnth++) {
            data[`col_${year}_${mnth}`] = val;
            this.columnSpread(data.id, val, data.costId, year, mnth);
          }
          /* grid total row refresh */
          this.refreshRowTotal(data, year);

          this.gridApi.applyTransaction({ update: [data] });
          this.setTotalRow();
          this.bringFocusBack();
        } else if (element.year == year && element.planningType == "Yearly") {
          //yearly hesaplanan total dağıtımı
          let val = data[`total_${year}`] / 12;
          for (let mnth = 1; mnth < 13; mnth++) {
            data[`col_${year}_${mnth}`] = val;
            this.columnSpread(data.id, val, data.costId, year, mnth);
          }
          /* grid total row refresh */
          this.refreshRowTotal(data, year);

          this.gridApi.applyTransaction({ update: [data] });
          this.setTotalRow();
          this.bringFocusBack();
        }
      });

      this.columnSpreadTotal(data.id, newValue, data.costId, year);
    }

    this.setTotalRow();
    this.gridApi.refreshCells({
      force: true,
    });
  }

  columnSpread(id, newValue, costId, year, month) {
    var data;
    var editedGaranularity: "cost" | "department" | "subDepartment";
    var spreadParent: boolean;
    var spreadChild: boolean;

    this.fullData.forEach((row) => {
      if (row.id == id) {
        //row[`col_${year}_${month}`] = newValue;

        data = { ...row };
        editedGaranularity =
          row.subDepartmentId != null
            ? "subDepartment"
            : row.subDepartmentId == null && row.departmentId != null
            ? "department"
            : "cost";

        spreadParent = editedGaranularity != "cost" ? true : false;
        spreadChild = editedGaranularity != "subDepartment" ? true : false;
      }
    });

    /* AŞAĞI/CHILD'A YAZMA */
    if (spreadChild) {
      if (editedGaranularity == "cost") {
        //costtan giriş department ve subdept e dağıtma
        let deptVal = newValue / data.departmentChildCount;
        for (let row of Object.keys(this.fullData)) {
          if (this.fullData[row].parentId == data.id) {
            this.fullData[row][`col_${year}_${month}`] = deptVal;
            let subDeptVal =
              deptVal / this.fullData[row].subDepartmentChildCount;
            let id = this.fullData[row].id;

            for (let rowS of Object.keys(this.fullData)) {
              if (this.fullData[rowS].parentId == id) {
                this.fullData[rowS][`col_${year}_${month}`] = subDeptVal;

                var total = 0;
                for (var mnth = 1; mnth < 13; mnth++) {
                  total += Number(this.fullData[rowS][`col_${year}_${mnth}`]);
                }
                this.fullData[rowS][`total_${year}`] = total;
              }
            }

            var total = 0;
            for (var mnth = 1; mnth < 13; mnth++) {
              total += Number(this.fullData[row][`col_${year}_${mnth}`]);
            }
            this.fullData[row][`total_${year}`] = total;
          }
        }
      }
      if (editedGaranularity == "department") {
        //department dan giriş subdept e dağıtma
        let subDeptVal = newValue / data.subDepartmentChildCount;
        for (let row of Object.keys(this.fullData)) {
          if (this.fullData[row].parentId == data.id) {
            this.fullData[row][`col_${year}_${month}`] = subDeptVal;

            var total = 0;
            for (var mnth = 1; mnth < 13; mnth++) {
              total += Number(this.fullData[row][`col_${year}_${mnth}`]);
            }
            this.fullData[row][`total_${year}`] = total;
          }
        }
      }
    }

    /* YUKARI/PARENT'A YAZMA */
    if (spreadParent) {
      /* step 1 sub-department satırlarından department'a yazma */
      this.fullData.forEach((row) => {
        if (
          row.costId == costId &&
          row.departmentId != null &&
          row.subDepartmentId == null
        ) {
          var subDepartmentLines = this.fullData.filter(
            (x) =>
              x.costId == row.costId &&
              x.departmentId == row.departmentId &&
              x.subDepartmentId != null
          );
          if (subDepartmentLines[0]) row[`col_${year}_${month}`] = 0;

          subDepartmentLines.forEach((subDeptRow) => {
            row[`col_${year}_${month}`] += Number(
              subDeptRow[`col_${year}_${month}`]
            );
          });

          var total = 0;
          for (var mnth = 1; mnth < 13; mnth++) {
            total += Number(row[`col_${year}_${mnth}`]);
          }
          row[`total_${year}`] = total;
        }
      });

      /* step 2 department satırlarından cost'a yazma */
      this.fullData.forEach((row) => {
        if (
          row.costId == costId &&
          row.departmentId == null &&
          row.subDepartmentId == null
        ) {
          var departmentLines = this.fullData.filter(
            (x) =>
              x.costId == row.costId &&
              x.departmentId != null &&
              x.subDepartmentId == null
          );
          if (departmentLines[0]) row[`col_${year}_${month}`] = 0;

          departmentLines.forEach((formRow) => {
            row[`col_${year}_${month}`] += Number(
              formRow[`col_${year}_${month}`]
            );
          });

          var total = 0;
          for (var mnth = 1; mnth < 13; mnth++) {
            total += Number(row[`col_${year}_${mnth}`]);
          }
          row[`total_${year}`] = total;
        }
      });
    }

    var rows = [...this.fullData];
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
    this.bringFocusBack();
  }

  columnSpreadTotal(id, newValue, costId, year) {
    var data;
    var editedGaranularity: "cost" | "department" | "subDepartment";
    var spreadParent: boolean;
    var spreadChild: boolean;

    this.fullData.forEach((row) => {
      if (row.id == id) {
        //if (newValue != null) row[`total_${year}`] = newValue;

        data = { ...row };
        editedGaranularity =
          row.subDepartmentId != null
            ? "subDepartment"
            : row.subDepartmentId == null && row.departmentId != null
            ? "department"
            : "cost";

        spreadParent = editedGaranularity != "cost" ? true : false;
        spreadChild = editedGaranularity != "subDepartment" ? true : false;
      }
    });

    /* AŞAĞI/CHILD'A YAZMA */
    if (spreadChild) {
      if (editedGaranularity == "cost") {
        //costtan giriş department ve subdept e dağıtma
        let deptVal = data[`total_${year}`] / data.departmentChildCount;
        for (let row of Object.keys(this.fullData)) {
          if (this.fullData[row].parentId == data.id) {
            this.fullData[row][`total_${year}`] = deptVal;
          }
        }
      } else if (editedGaranularity == "department") {
        //department dan giriş subdept e dağıtma
        let subDeptVal = data[`total_${year}`] / data.subDepartmentChildCount;
        for (let row of Object.keys(this.fullData)) {
          if (this.fullData[row].parentId == data.id) {
            this.fullData[row][`total_${year}`] = subDeptVal;
          }
        }
      }
    }

    /* YUKARI/PARENT'A YAZMA */
    if (spreadParent) {
      /* step 1 sub-departmentten satırlarından department'e yazma */
      this.fullData.forEach((row) => {
        if (
          row.costId == costId &&
          row.departmentId != null &&
          row.subDepartmentId == null
        ) {
          var subDepartmentLines = this.fullData.filter(
            (x) =>
              x.costId == row.costId &&
              x.departmentId == row.departmentId &&
              x.subDepartmentId != null
          );
          if (subDepartmentLines[0]) row[`total_${year}`] = 0;

          subDepartmentLines.forEach((subRow) => {
            row[`total_${year}`] += subRow[`total_${year}`];
          });
        }
      });

      /* step 2 form satırlarından product'a yazma */
      this.fullData.forEach((row) => {
        if (
          row.costId == costId &&
          row.departmentId == null &&
          row.subDepartmentId == null
        ) {
          var departmentLines = this.fullData.filter(
            (x) =>
              x.costId == row.costId &&
              x.departmentId != null &&
              x.subDepartmentId == null
          );
          if (departmentLines[0]) row[`total_${year}`] = 0;

          departmentLines.forEach((formRow) => {
            row[`total_${year}`] += formRow[`total_${year}`];
          });
        }
      });
    }

    var rows = [...this.fullData];
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
    this.bringFocusBack();
  }

  onCopy() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    this.value2copy = this.currentContextEvent.value;
  }

  onPaste() {
    if (this.value2copy == null || this.value2copy == "NaN") return;

    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let data2copy = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth
      ? this.currentContextEvent.colDef.cellMonth
      : 0;

    var isEditable = this.isEditable(data2copy, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let newVal = Number(this.value2copy);
    if (cellMonth != 0)
      data2copy[`col_${year}_${cellMonth}`] = Number(this.value2copy);
    else data2copy[`total_${year}`] = Number(this.value2copy);

    if (cellMonth != 0)
      this.columnSpread(
        data2copy.id,
        newVal,
        data2copy.costId,
        year,
        cellMonth
      );
    else this.columnSpreadTotal(data2copy.id, newVal, data2copy.costId, year);
    /* grid total row refresh */
    this.refreshRowTotal(data2copy, year);

    this.setTotalRow();
    this.gridApi.applyTransaction({ update: [data2copy] });
    this.gridApi.redrawRows();
    this.bringFocusBack();
  }

  sustainTrend() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let data4sustain = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data4sustain, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.years.includes(year - 1)) {
      let toplam: number = 0;
      for (var month = 1; month < 13; month++) {
        toplam = toplam + Number(data4sustain[`col_${year - 1}_${month}`]);
      }

      let ort: number = Math.floor(toplam / 12);
      for (var month = 1; month < 13; month++) {
        let oldVal = data4sustain[`col_${year}_${month}`];
        let diff = ort - oldVal;
        let parentId = data4sustain.parentId;

        data4sustain[`col_${year}_${month}`] = ort;
        this.columnSpread(oldVal, diff, parentId, year, month);
        this.columnSpreadTotal(oldVal, diff, parentId, year);
      }

      data4sustain[`total_${year}`] = 0;
      for (var month = 1; month < 13; month++) {
        data4sustain[`total_${year}`] += Number(
          data4sustain[`col_${year}_${month}`]
        );
      }

      this.setTotalRow();
      this.gridApi.applyTransaction({ update: [data4sustain] });
      this.bringFocusBack();
    } else {
      this.translate.get("entryScreens.noPast").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
    }
  }

  onCellClicked(event) {
    if (event.column.colDef.field != "cost") {
      return;
    }

    var parentId = event.data.id;
    var rows = [...this.fullData];

    let currentRow = rows.filter((row) => row.id == parentId)[0];
    let isOpen = currentRow.selected;

    let currentRows = rows.filter((row) => row.parentId == parentId);
    currentRows.forEach(function (row) {
      row.isVisible = !isOpen;
      if (isOpen) {
        row.selected = false;
        let subRows = rows.filter((r) => r.parentId == row.id);
        subRows.forEach(function (row) {
          row.isVisible = false;
        });
      }
    });
    currentRow.selected = !isOpen;
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
  }

  openRows() {
    var rows = [...this.fullData];

    this.isOpen = !this.isOpen;
    var value = this.isOpen;

    rows.forEach(function (row) {
      if (row.parentId != 0) {
        row.isVisible = value;
        row.selected = value;
      } else {
        row.selected = value;
      }
    });
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
  }

  onCarryForward() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    for (var month = cellMonth + 1; month < 13; month++) {
      var newValue = Number(data[`col_${year}_${cellMonth}`]);
      data[`col_${year}_${month}`] = newValue;
      this.columnSpread(data.id, newValue, data.costId, year, month);
    }
    /* grid total row refresh */
    this.refreshRowTotal(data, year);

    this.gridApi.applyTransaction({ update: [data] });
    this.setTotalRow();
    this.bringFocusBack();
  }

  onIncreaseAllMonthly(type_n) {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }
    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (type_n == 0) {
      if (this.increaseAllValue > -1) {
        let value = this.increaseAllValue;

        for (var month = cellMonth; month < 13; month++) {
          var newValue = Number(data[`col_${year}_${month}`]) + Number(value);
          data[`col_${year}_${month}`] = newValue;
          this.columnSpread(data.id, newValue, data.costId, year, month);
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    } else {
      if (this.increaseAllValueP > -1) {
        let value = this.increaseAllValueP;

        for (var month = cellMonth; month < 13; month++) {
          var newValue2 = Number(
            Number(data[`col_${year}_${month}`]) +
              (Number(data[`col_${year}_${month}`]) * Number(value)) / 100
          );
          data[`col_${year}_${month}`] = newValue2;
          this.columnSpread(data.id, newValue2, data.costId, year, month);
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    }
  }

  onIncreaseMonthly(type_n) {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }
    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (type_n == 0) {
      if (this.increaseValue > -1) {
        let value = this.increaseValue;

        for (var month = cellMonth + 1; month < 13; month++) {
          var newValue =
            Number(data[`col_${year}_${month - 1}`]) + Number(value);
          data[`col_${year}_${month}`] = newValue;
          this.columnSpread(data.id, newValue, data.costId, year, month);
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
        return true;
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    } else {
      if (this.increaseValueP > -1) {
        let value = this.increaseValueP;

        for (var month = cellMonth + 1; month < 13; month++) {
          var newValue = Number(
            Number(data[`col_${year}_${month - 1}`]) +
              (Number(data[`col_${year}_${month - 1}`]) * Number(value)) / 100
          );
          data[`col_${year}_${month}`] = newValue;
          this.columnSpread(data.id, newValue, data.costId, year, month);
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    }
  }

  onDecreaseAllMonthly(type_n) {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (type_n == 0) {
      if (this.decreaseAllValue > -1) {
        let value = this.decreaseAllValue;

        for (var month = cellMonth; month < 13; month++) {
          var newValue = Number(data[`col_${year}_${month}`] - value);
          data[`col_${year}_${month}`] = newValue;
          this.columnSpread(data.id, newValue, data.costId, year, month);
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    } else {
      if (this.decreaseAllValueP > -1) {
        let value = this.decreaseAllValueP;

        for (var month = cellMonth; month < 13; month++) {
          var newValue = Number(
            data[`col_${year}_${month}`] -
              (Number(data[`col_${year}_${month}`]) * Number(value)) / 100
          );
          data[`col_${year}_${month}`] = newValue;
          this.columnSpread(data.id, newValue, data.costId, year, month);
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    }
  }

  onDecreaseMonthly(type_n) {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (type_n == 0) {
      if (this.decreaseValue > -1) {
        let value = this.decreaseValue;

        for (var month = cellMonth + 1; month < 13; month++) {
          var newValue = Number(data[`col_${year}_${month - 1}`] - value);
          data[`col_${year}_${month}`] = newValue;
          this.columnSpread(data.id, newValue, data.costId, year, month);
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    } else {
      if (this.decreaseValueP > -1) {
        let value = this.decreaseValueP;

        for (var month = cellMonth + 1; month < 13; month++) {
          var newValue = Number(
            data[`col_${year}_${month - 1}`] -
              (Number(data[`col_${year}_${month - 1}`]) * Number(value)) / 100
          );
          data[`col_${year}_${month}`] = newValue;
          this.columnSpread(data.id, newValue, data.costId, year, month);
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    }
  }

  copyActuals() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    for (let month = 1; month < 13; month++) {
      if (data[`col_-${this.year4actual}_${month}`]) {
        data[`col_${year}_${month}`] =
          data[`col_-${this.year4actual}_${month}`];
      }
    }

    for (var month = 1; month < 13; month++) {
      let newValue = Number(
        data[`col_-${this.year4actual}_${month}`]
          ? data[`col_-${this.year4actual}_${month}`]
          : data[`col_${year}_${month}`]
      );
      data[`col_${year}_${month}`] = newValue;
      this.columnSpread(data.id, newValue, data.COSTId, year, month);
    }
    /* grid total row refresh */
    this.refreshRowTotal(data, year);

    this.gridApi.applyTransaction({ update: [data] });
    this.setTotalRow();
    this.bringFocusBack();
  }

  copyScenario() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    this.centralPlanService
      .listCost(this.copyScenarioId)
      .subscribe((result) => {
        if (!result) {
          this.translate
            .get("entryScreens.no4scenario")
            .subscribe((data: string) => {
              this.toastrService.warning(data);
            });
          return;
        }
        this.fullDataS = result.data;

        for (let rowS in this.fullDataS) {
          for (let row in this.fullData) {
            if (
              this.fullDataS[rowS].costId == this.fullData[row].costId &&
              this.fullDataS[rowS].departmentId ==
                this.fullData[row].departmentId &&
              this.fullDataS[rowS].subDepartmentId ==
                this.fullData[row].subDepartmentId
            ) {
              let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

              for (let year of this.years) {
                for (let month of months) {
                  this.fullData[row][`col_${year}_${month}`] =
                    this.fullDataS[rowS][`col_${year}_${month}`];
                }
                this.fullData[row][`total_${year}`] =
                  this.fullDataS[rowS][`total_${year}`];
              }
            }
          }
        }

        this.rowData = this.fullData.filter((x) => x.isVisible);
        this.fullData4Import = [...this.fullData];

        this.setExpandable();
        this.setTotalRow();
        this.changeVolume();
      });

    return true;
  }

  copyPlanning() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.centralPlanApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    this.scenarioService
      .listScenariosByGuidance(this.copyPlanningGuidanceId, 2)
      .subscribe((result) => {
        this.scenarioCopyList = result;
        this.scenarioGuiList = result;
      });
  }

  currentContextEvent: any;
  onCellContextMenu(event) {
    this.currentContextEvent = event;
  }

  refreshRowTotal(data, year) {
    /* Aggrid refresh için */
    var total = 0;
    for (var mnth = 1; mnth < 13; mnth++) {
      total += Number(data[`col_${year}_${mnth}`]);
    }
    data[`total_${year}`] = total;
  }

  getNumericCellEditor() {
    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }
    function isCharDecimal(charStr) {
      return ".".indexOf(charStr) === 0;
    }
    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr) || isCharDecimal(charStr);
    }
    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === "undefined" ? event.keyCode : event.which;
    }
    function NumericCellEditor() {}
    NumericCellEditor.prototype.init = function (params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement("input");
      this.eInput.style.width = "100%";
      this.eInput.style.height = "100%";
      this.eInput.value = isCharNumeric(params.charPress)
        ? params.charPress
        : params.value;
      var that = this;
      this.eInput.addEventListener("keypress", function (event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };
    NumericCellEditor.prototype.getGui = function () {
      return this.eInput;
    };
    NumericCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        //this.eInput.select();
      }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function () {
      return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.isCancelAfterEnd = function () {};
    NumericCellEditor.prototype.getValue = function () {
      return this.eInput.value;
    };
    NumericCellEditor.prototype.focusIn = function () {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
      console.log("NumericCellEditor.focusIn()");
    };
    NumericCellEditor.prototype.focusOut = function () {
      console.log("NumericCellEditor.focusOut()");
    };
    return NumericCellEditor;
  }

  @ViewChild("userMenu") userMenu: TemplateRef<any>;
  sub: Subscription;
  overlayRef: OverlayRef | null;

  open({ x, y }: MouseEvent, user) {
    this.close();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top",
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(
      new TemplatePortal(this.userMenu, this.viewContainerRef, {
        $implicit: user,
      })
    );

    this.sub = fromEvent<MouseEvent>(document, "click")
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return (
            !!this.overlayRef &&
            !this.overlayRef.overlayElement.contains(clickTarget)
          );
        }),
        take(1)
      )
      .subscribe(() => this.close());
  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
    this.rulesPopup = false;
    this.notesPopup = false;
    this.colorPopup = false;
  }
}
