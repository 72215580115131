import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProcessResult } from "@planard/@core/entities/process-result";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NarrativeService } from "@planard/@core/backend/service/narrative.service";
import { SaleNarrative } from "@planard/@core/entities/saleNarrative";
import { NarrativeRequest } from "@planard/@core/entities/narrativeRequest";
import { Scenario } from "@planard/@core/entities/scenario";
import { Products } from "@planard/@core/entities/product";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./add-narratives.component.html",
})
export class AddNarrativesComponent implements OnInit {
  sale: SaleNarrative;
  processResult = new ProcessResult();
  scenarioList: Scenario[];
  productsList: Products[];
  saleRequestList: NarrativeRequest[];
  requestId: number;
  productId: any;

  area: any;

  priceCatList = [
    "Currency Impact",
    "Price Increase Due to Inflation",
    "Tax Regulations",
    "Competitor Entry/Exit",
    "Change in Manufacturing Costs",
    "Price Adjustments",
    "Other Price Impacts",
  ];

  volumeCatList = [
    "New Product Launch",
    "Competitor Entry/Exit",
    "Market Growth/Shrink",
    "Macro Factors",
    "Competitive Advantage",
    "Large Scale Agreement",
    "New Channels",
    "Price Change Impact on Volume",
    "Other Volume Impacts",
  ];

  form: FormGroup = new FormGroup({
    productId: new FormControl("", [Validators.required]),
    narrativeRequestId: new FormControl("", [Validators.required]),
    amount: new FormControl("", [Validators.required]),
    impactArea: new FormControl("", [Validators.required, Validators.min(0)]),
    category: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  constructor(
    public fb: FormBuilder,
    public narrativeService: NarrativeService,
    public scenarioService: ScenarioService,
    public productsService: ProductsService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<AddNarrativesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.requestId = data.requestId;
    this.productId = data.productId;
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.scenarioService.listAll().subscribe((data) => {
      this.scenarioList = data;
    });
    this.productsService.listAll().subscribe((data) => {
      this.productsList = data;
    });
    this.narrativeService.listSalesRequests().subscribe((result) => {
      this.saleRequestList = result;
    });
    this.form.patchValue({ narrativeRequestId: this.requestId });
    if (this.productId != null) {
      this.form.patchValue({ productId: this.productId });
    }
  }

  save() {
    if (this.form.valid) {
      this.sale = this.form.value;
      this.narrativeService.addSale(this.sale).subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.dialogRef.close(this.sale);
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
