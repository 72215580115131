import { Observable } from "rxjs";
import { Plan } from "@planard/@core/entities/plan";

export abstract class PlanData {
  abstract listPrices(
    scenarioId: number,
    approveRequestId?: number
  ): Observable<any>;

  abstract costTrend(
    scenarioId: number,
    categoryId: number,
    subcategoryId: number,
    timescale,
    actuals: number,
    periodId: number,
    currencyId: number,
    subDepartmentId: number,
    costId: number
  ): Observable<any>;

  abstract costOverview(
    scenarioId: number,
    actuals: any,
    periodId: any,
    currencyId: number,
    subDepartmentId: number
  ): Observable<any>;

  abstract update(plan: Plan): Observable<any>;

  abstract listApproverUsersSubDepartments(): Observable<any>;
}
