import { EventEmitter, Injectable } from "@angular/core";
import { ApproverRequest } from "@planard/@core/entities/approverRequest";
import { ApproverRequestData } from "@planard/@core/entities/interface/approverRequest";
import { ApproverRequestApi } from "../api/approverRequest.api";

@Injectable()
export class ApproverRequestService extends ApproverRequestData {
  onApproverRequestReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ApproverRequestApi) {
    super();
  }

  submit(approverRequest: ApproverRequest) {
    return this.api.submit<any>(approverRequest);
  }

  requestApprove(approverRequest: any) {
    return this.api.requestApprove<any>(approverRequest);
  }

  requestReject(approverRequest: any) {
    return this.api.requestReject<any>(approverRequest);
  }

  listAllSales() {
    return this.api.listAllSales<any>();
  }

  listAllCosts() {
    return this.api.listAllCosts<any>();
  }
}
