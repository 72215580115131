import { Routes } from "@angular/router";
import { BusinessUnitNewComponent } from "@planard/admin/general/business-unit/business-unit-new.component";
import { ProductsNewComponent } from "@planard/admin/general/products/products-new.component";
import { NotificationComponent } from "@planard/notification/notification.component";
import { ApprovalFlowComponent } from "@planard/admin/general/approval-flow/approval-flow.component";
import { RolesComponent } from "@planard/admin/general/roles/roles.component";
import { ChannelNewComponent } from "@planard/admin/general/channel/channel-new.component";
import { SettingsComponent } from "@planard/settings/settings.component";
import { ProfileComponent } from "@planard/admin/auth/profile/profile.component";
import { DepartmentNewComponent } from "./department/department-new.component";
import { SubdepartmentNewComponent } from "./subdepartment/subdepartment-new.component";
import { ProductFamilyNewComponent } from "./product-family/product-family-new.component";
import { CostTypeNewComponent } from "./costtypes/cost-type-new.component";
import { UsersNewComponent } from "./users/users-new.component";

export const AdminGeneralRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "business-unit",
        component: BusinessUnitNewComponent,
        data: {
          title: "Business Unit",
          urls: [{ title: "Business Unit", url: "/business-unit" }],
        },
      },
      {
        path: "department",
        component: DepartmentNewComponent,
        data: {
          title: "Department",
          urls: [{ title: "Department", url: "/department" }],
        },
      },
      {
        path: "subdepartment",
        component: SubdepartmentNewComponent,
        data: {
          title: "Sub Department",
          urls: [{ title: "Sub Department", url: "/subdepartment" }],
        },
      },
      {
        path: "product-family",
        component: ProductFamilyNewComponent,
        data: {
          title: "Product Families",
          urls: [{ title: "Product Families", url: "/product-family" }],
        },
      },
      {
        path: "channel",
        component: ChannelNewComponent,
        data: {
          title: "Channel",
          urls: [{ title: "Channel", url: "/channel" }],
        },
      },
      {
        path: "notification",
        component: NotificationComponent,
        data: {
          title: "Notification",
          urls: [{ title: "Notification", url: "/notification" }],
        },
      },
      {
        path: "setup",
        component: SettingsComponent,
        data: {
          title: "Setup",
          urls: [{ title: "Setup", url: "/setup" }],
        },
      },
      {
        path: "products",
        component: ProductsNewComponent,
        data: {
          title: "Products",
          urls: [{ title: "Products", url: "/products" }],
        },
      },
      {
        path: "roles",
        component: RolesComponent, // 8
        data: {
          title: "Roles",
          urls: [{ title: "Roles", url: "/roles" }],
        },
      },
      {
        path: "users",
        component: UsersNewComponent,
        data: {
          title: "Company Users",
          urls: [{ title: "Company Users", url: "/users" }],
        },
      },
      {
        path: "approval-flow",
        component: ApprovalFlowComponent, // 10
        data: {
          title: "Users Approval",
          urls: [{ title: "Users Approval", url: "/approval-flow" }],
        },
      },
      {
        path: "costtypes",
        component: CostTypeNewComponent,
        data: {
          title: "Cost Types",
          urls: [{ title: "Cost Types", url: "/costtypes" }],
        },
      },
      {
        path: "auth/profile",
        component: ProfileComponent,
        data: {
          title: "Profile",
        },
      },
    ],
  },
];
