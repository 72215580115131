import "hammerjs";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule, DecimalPipe } from "@angular/common";
import { DemoMaterialModule } from "../../common/demo-material-module";
import { ChartsModule } from "ng2-charts";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SalesReportsRoutes } from "./sales-reports.routing";
import { PriceTrendComponent } from "./price-trend/price-trend.component";
import { ChartModule } from "angular2-chartjs";
import { MenuItems } from "@planard/shared/menu-items/menu-items";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ScenarioApi } from "@planard/@core/backend/api/scenario.api";
import { ProductsApi } from "@planard/@core/backend/api/products.api";
import { PipesModule } from "@planard/@core/pipes/pipes.module";
import { SalesTrendComponent } from "./sales-trend/sales-trend.component";
import { SalesBridgeComponent } from "./sales-bridge/sales-bridge.component";
import { PriceVolumeComponent } from "./price-volume/price-volume.component";
import { SalesDriversComponent } from "./sales-drivers/sales-drivers.component";
import { LaunchDatesComponent } from "./launch-dates/launch-dates.component";
import { NgxCaptureModule } from "ngx-capture";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { ReportScreenshotApi } from "@planard/@core/backend/api/reportScreenshot.api";
import { AgGridModule } from "ag-grid-angular";
import { SalesOverviewComponent } from "./sales-overview/sales-overview.component";
import { BusinessUnitApi } from "@planard/@core/backend/api/business-unit.api";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import { ProductFamilyApi } from "@planard/@core/backend/api/product-family.api";
import { ProductFamilyService } from "@planard/@core/backend/service/product-family.service";
import { ChannelApi } from "@planard/@core/backend/api/channel.api";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { GuidanceApi } from "@planard/@core/backend/api/guidance.api";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { LaunchTimelineApi } from "@planard/@core/backend/api/launchTimeline.api";
import { LaunchTimelineService } from "@planard/@core/backend/service/launchTimeline.service";
import { ReportsApi } from "@planard/@core/backend/api/reports.api";
import { ReportsService } from "@planard/@core/backend/service/reports.service";
import { GetPeriodGroupService } from "@planard/@core/backend/service/getPeriodGroup.service";
import { ProductOverviewComponent } from "./product-overview/product-overview.component";
import { ChannelOverviewComponent } from "./channel-overview/channel-overview.component";
import { TranslateModule } from "@ngx-translate/core";
import { ActualPlanComponent } from "./actual-plan/actual-plan.component";

const API = [
  ScenarioApi,
  ProductsApi,
  ReportScreenshotApi,
  BusinessUnitApi,
  ProductFamilyApi,
  ChannelApi,
  GuidanceApi,
  LaunchTimelineApi,
  ReportsApi,
];

const SERVICES = [
  ScenarioService,
  ProductsService,
  ReportScreenshotService,
  BusinessUnitService,
  ProductFamilyService,
  ChannelService,
  GuidanceService,
  LaunchTimelineService,
  ReportsService,
  GetPeriodGroupService,
];

@NgModule({
  imports: [
    CommonModule,
    ChartModule,
    PipesModule,
    RouterModule.forChild(SalesReportsRoutes),
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ChartsModule,
    TranslateModule,
    NgxCaptureModule,
    AgGridModule.withComponents([]),
  ],
  providers: [MenuItems, ...API, ...SERVICES, DecimalPipe],
  declarations: [
    PriceTrendComponent,
    SalesTrendComponent,
    SalesBridgeComponent,
    PriceVolumeComponent,
    SalesDriversComponent,
    LaunchDatesComponent,
    SalesOverviewComponent,
    ProductOverviewComponent,
    ChannelOverviewComponent,
    ActualPlanComponent,
  ],
  exports: [SalesTrendComponent, SalesDriversComponent],
})
export class SalesReportsModule {}
