import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";

@Injectable()
export class RoleApi {
  private readonly apiController: string = "role";

  constructor(private api: HttpService) {}

  list<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/list`);
  }
}
