<h1 mat-dialog-title>Add Role</h1>

<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input formControlName="name" matInput name="name" placeholder="Name" />
    </mat-form-field>

    <mat-form-field>
      <mat-select
        formControlName="responsibility"
        name="responsibility"
        placeholder="Responsibility"
      >
        <mat-option
          *ngFor="let o of responsibilityList"
          [value]="o"
          value="option"
        >
          {{ o.displayValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        formControlName="reportAccess"
        name="reportAccess"
        placeholder="Report Access"
      >
        <mat-option
          *ngFor="let o of reportAccessList"
          [value]="o"
          value="option"
        >
          {{ o.displayValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-button tabindex="2" type="submit">Save</button>
    <button (click)="cancel()" mat-button tabindex="-1" type="button">
      Cancel
    </button>
  </div>
</form>
