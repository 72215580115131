import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ToastrService } from "ngx-toastr";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProductChannel } from "@planard/@core/entities/productChannel";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { Channel } from "@planard/@core/entities/channel";

@Component({
  templateUrl: "./productChannel-add.component.html",
})
export class ProductChannelAddComponent implements OnInit {
  channelList: Channel[];
  productChannel: ProductChannel;
  pcForm: FormGroup = new FormGroup({
    channelId: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  constructor(
    public fb: FormBuilder,
    public channelService: ChannelService,
    public productsService: ProductsService,
    public toastrService: ToastrService,
    public dialogRef: MatDialogRef<ProductChannelAddComponent>,
    @Inject(MAT_DIALOG_DATA) public productId: number
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.channelService.listAll().subscribe((result) => {
      this.channelList = result;
    });
  }

  save() {
    if (this.pcForm.valid) {
      this.productChannel = this.pcForm.value;
      this.productChannel.productId = this.productId;
      this.productsService
        .addChannel(this.productChannel)
        .subscribe((backendResult) => {
          if (backendResult.isSuccess) {
            this.toastrService.success(backendResult.message);
            this.dialogRef.close(this.productChannel);
          } else {
            this.toastrService.error(backendResult.message);
          }
        });
    } else {
      this.toastrService.warning("Please fill all required fields.");
    }
  }

  cancelClick() {
    this.dialogRef.close(false);
  }
}
