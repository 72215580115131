<h1 mat-dialog-title>{{ "guidance.planningPeriod.add" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <mat-select
        tabindex="1"
        placeholder="{{ 'guidance.planningPeriod.year' | translate }}"
        name="year"
        formControlName="year"
      >
        <mat-option *ngFor="let o of yearList" [value]="o" value="option">
          {{ o }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="2"
        (selectionChange)="typeSet()"
        formControlName="planningType"
        name="planningType"
        placeholder="{{ 'guidance.planningPeriod.planningType' | translate }}"
      >
        <mat-option value="Yearly">{{
          "guidance.Yearly" | translate
        }}</mat-option>
        <mat-option value="Monthly">{{
          "guidance.Monthly" | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="monthly == true">
      <mat-select
        tabindex="3"
        placeholder="{{ 'guidance.planningPeriod.startMonth' | translate }}"
        name="startMonth"
        formControlName="startMonth"
      >
        <mat-option
          *ngFor="let o of monthList"
          [value]="o.value"
          value="option"
        >
          <span *ngIf="'months.' + o.label as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!--<mat-form-field>
      <mat-select tabindex="3" placeholder="Granularity" name="granularity" formControlName="granularity">
        <mat-option *ngFor="let o of granularityList" [value]="o.value" value="option">
          {{o.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>-->
  </div>

  <div mat-dialog-actions>
    <button mat-button tabindex="4" type="submit">
      {{ "guidance.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="5" type="button">
      {{ "guidance.cancel" | translate }}
    </button>
  </div>
</form>
