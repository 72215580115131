<h1 mat-dialog-title>{{ "riskOppsAdd.title" | translate }}</h1>

<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        tabindex="1"
        formControlName="description"
        matInput
        name="description"
        placeholder="{{ 'riskOppsAdd.description' | translate }}"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="2"
        formControlName="impactArea"
        name="impactArea"
        placeholder="{{ 'riskOppsAdd.impactArea' | translate }}"
        multiple
      >
        <mat-option *ngFor="let o of impactAreaList" [value]="o">
          <span *ngIf="'impactArea.' + o as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="3"
        formControlName="typeOfImpact"
        name="typeOfImpact"
        placeholder="{{ 'riskOppsAdd.typeImpact' | translate }}"
      >
        <mat-option value="Risk">{{ "riskOpps.Risk" | translate }}</mat-option>
        <mat-option value="Opportunity">{{
          "riskOpps.Opportunity" | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="4"
        formControlName="impactAmount"
        matInput
        type="number"
        name="impactAmount"
        placeholder="{{ 'riskOppsAdd.impactAmount' | translate }}"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="5"
        formControlName="likeliHood"
        name="likeliHood"
        placeholder="{{ 'riskOppsAdd.likelihood' | translate }}"
      >
        <mat-option value="Low">{{ "riskOpps.Low" | translate }}</mat-option>
        <mat-option value="Medium">{{
          "riskOpps.Medium" | translate
        }}</mat-option>
        <mat-option value="High">{{ "riskOpps.High" | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="6"
        [matDatepicker]="spicker"
        formControlName="dayOfCertainty"
        matInput
        name="dayOfCertainty"
        placeholder="{{ 'riskOppsAdd.date' | translate }}"
      />
      <mat-datepicker-toggle [for]="spicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #spicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-button tabindex="7" type="submit">
      {{ "riskOppsAdd.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="8" type="button">
      {{ "riskOppsAdd.cancel" | translate }}
    </button>
  </div>
</form>
