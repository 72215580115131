import { Routes } from "@angular/router";
import { CostBridgeComponent } from "./cost-bridge/cost-bridge.component";
import { CostOverviewComponent } from "./cost-overview/cost-overview.component";
import { CostTrendComponent } from "./cost-trend/cost-trend.component";

export const CostReportsRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "cost-overview",
        component: CostOverviewComponent,
        data: {
          title: "Cost Overview",
          urls: [{ title: "Cost Overview", url: "/cost-overview" }],
        },
      },
      {
        path: "cost-trend",
        component: CostTrendComponent,
        data: {
          title: "Cost Trend",
          urls: [{ title: "Cost Trend", url: "/cost-trend" }],
        },
      },
      {
        path: "cost-bridge",
        component: CostBridgeComponent,
        data: {
          title: "Cost Bridge",
          urls: [{ title: "Cost Bridge", url: "/cost-bridge" }],
        },
      },
    ],
  },
];
