import { EventEmitter, Injectable } from "@angular/core";
import { GuidanceApi } from "@planard/@core/backend/api/guidance.api";
import { GuidanceData } from "@planard/@core/entities/interface/guidance";
import { GuidancePlanningPeriod } from "../../entities/guidancePlanningPeriod";
import { GuidanceTarget } from "../../entities/guidanceTarget";
import { Guidance } from "../../entities/guidance";
import { GuidanceFxRate } from "@planard/@core/entities/guidanceFxRate";
import { GuidanceInflationRate } from "@planard/@core/entities/guidanceInflationRate";
import { GuidanceKeyContact } from "@planard/@core/entities/guidanceKeyContact";

@Injectable()
export class GuidanceService extends GuidanceData {
  onGuidancePeriodReload: EventEmitter<any> = new EventEmitter<any>();
  onGuidanceTargetReload: EventEmitter<any> = new EventEmitter<any>();
  onGuidanceReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: GuidanceApi) {
    super();
  }

  getGuidance() {
    return this.api.getGuidance<Guidance>();
  }

  getById(guidanceId: number) {
    return this.api.getById<Guidance>(guidanceId);
  }

  listAll() {
    return this.api.listAll<any[]>();
  }

  listAllFxRates() {
    return this.api.listAllFxRates<any[]>();
  }

  getPlanningRecord(guidanceId?: number) {
    return this.api.getPlanningRecord<any[]>(guidanceId);
  }

  getTargetRecord(guidanceId: number) {
    return this.api.getTargetRecord<any[]>(guidanceId);
  }

  getKeyContact(guidanceId: number) {
    return this.api.getKeyContact<any[]>(guidanceId);
  }

  getFxRate(guidanceId: number) {
    return this.api.getfxRate<any[]>(guidanceId);
  }

  getInflationRate(guidanceId: number) {
    return this.api.getInflationRate<any[]>(guidanceId);
  }

  deletePeriod(periodId: number) {
    return this.api.deletePeriod<any>(periodId);
  }

  deleteKeyContact(keyContactId: number) {
    return this.api.deleteKeyContact<any>(keyContactId);
  }

  deleteFxRate(fxRateId: number) {
    return this.api.deleteFxRate<any>(fxRateId);
  }

  deleteInflationRate(inflationRateId: number) {
    return this.api.deleteInflationRate<any>(inflationRateId);
  }

  deleteTarget(targetId: number) {
    return this.api.deleteTarget<any>(targetId);
  }

  addPeriod(period: GuidancePlanningPeriod) {
    return this.api.addPeriod<any>(period);
  }

  addTarget(target: GuidanceTarget) {
    return this.api.addTarget<any>(target);
  }

  addKeyContact(keyContact: GuidanceKeyContact) {
    return this.api.addKeyContact<any>(keyContact);
  }

  addFxRate(fxRate: GuidanceFxRate) {
    return this.api.addFxRate<any>(fxRate);
  }

  addInflationRate(inflationRate: GuidanceInflationRate) {
    return this.api.addInflationRate<any>(inflationRate);
  }

  updateDeadline(guidance: Guidance) {
    return this.api.updateDeadline<any>(guidance);
  }

  updateCycleName(guidance: Guidance) {
    return this.api.updateCycleName<any>(guidance);
  }

  updateGranularity(guidance: Guidance) {
    return this.api.updateGranularity<any>(guidance);
  }

  updateFxRate(guidanceFxRate: GuidanceFxRate) {
    return this.api.updateFxRate<any>(guidanceFxRate);
  }

  updateInflationRate(guidanceInflationRate: GuidanceInflationRate) {
    return this.api.updateInflationRate<any>(guidanceInflationRate);
  }

  updateTarget(guidanceTarget: GuidanceTarget) {
    return this.api.updateTarget<any>(guidanceTarget);
  }

  updatePeriod(guidancePlanningPeriod: GuidancePlanningPeriod) {
    return this.api.updatePeriod<any>(guidancePlanningPeriod);
  }

  updateContact(guidanceKeyContact: GuidanceKeyContact) {
    return this.api.updateContact<any>(guidanceKeyContact);
  }

  startGuidance(guidanceId: any) {
    return this.api.startGuidance<any>(guidanceId);
  }

  endGuidance(guidanceId: any) {
    return this.api.endGuidance<any>(guidanceId);
  }

  copyGuidance(guidanceId: any) {
    return this.api.copyGuidance<any>(guidanceId);
  }
}
