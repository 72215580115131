<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <mat-card-title>{{ "profile.title" | translate }}</mat-card-title>
        <br />
        <br />
        <form [formGroup]="form" (ngSubmit)="save()">
          <mat-dialog-content>
            <mat-form-field>
              <input
                style="color: grey"
                matInput
                formControlName="name"
                [(ngModel)]="name"
                placeholder="{{ 'profile.name' | translate }}"
                autocomplete="off"
                maxlength="255"
                readonly
              />
            </mat-form-field>

            <mat-form-field>
              <input
                style="color: grey"
                matInput
                formControlName="email"
                [(ngModel)]="mail"
                placeholder="{{ 'profile.email' | translate }}"
                autocomplete="off"
                type="email"
                maxlength="255"
                readonly
              />
            </mat-form-field>

            <mat-form-field *ngIf="companyPackage == 'premium'">
              <input
                style="color: grey"
                matInput
                formControlName="approver"
                [(ngModel)]="approver"
                placeholder="{{ 'profile.approver' | translate }}"
                autocomplete="off"
                maxlength="255"
                readonly
              />
            </mat-form-field>

            <mat-form-field>
              <input
                style="color: grey"
                matInput
                formControlName="department"
                [(ngModel)]="department"
                placeholder="{{ 'profile.department' | translate }}"
                autocomplete="off"
                maxlength="255"
                readonly
              />
            </mat-form-field>

            <mat-form-field>
              <input
                style="color: grey"
                matInput
                formControlName="subdepartment"
                [(ngModel)]="subdepartment"
                placeholder="{{ 'profile.subDepartment' | translate }}"
                autocomplete="off"
                maxlength="255"
                readonly
              />
            </mat-form-field>

            <mat-form-field>
              <input
                style="color: grey"
                matInput
                formControlName="roles"
                [(ngModel)]="role"
                placeholder="{{ 'profile.roles' | translate }}"
                autocomplete="off"
                maxlength="255"
                readonly
              />
            </mat-form-field>

            <br />
            <br />

            <h6>{{ "profile.change" | translate }}</h6>
            <mat-form-field>
              <input
                matInput
                formControlName="currentPassword"
                placeholder="{{ 'profile.current' | translate }}"
                type="password"
                autocomplete="off"
                maxlength="255"
              />
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                formControlName="password"
                placeholder="{{ 'profile.new' | translate }}"
                type="password"
                autocomplete="off"
                maxlength="255"
              />
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                formControlName="rePassword"
                placeholder="{{ 'profile.confirm' | translate }}"
                type="password"
                autocomplete="off"
                maxlength="255"
              />
            </mat-form-field>

            <mat-error
              *ngIf="!isPasswordSame"
              style="font-size: small; font-weight: bold"
            >
              {{ "profile.warning" | translate }}</mat-error
            >
          </mat-dialog-content>
          <mat-dialog-actions>
            <button
              mat-raised-button
              color="accent"
              cdkFocusInitial
              type="submit"
            >
              {{ "profile.save" | translate }}
            </button>
          </mat-dialog-actions>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
