import { Component, OnInit } from "@angular/core";
import { SubDepartment } from "@planard/@core/entities/subDepartment";
import { Department } from "@planard/@core/entities/department";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DepartmentService } from "@planard/@core/backend/service/department.service";
import { ToastrService } from "ngx-toastr";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";
@Component({
  templateUrl: "./subdepartment-add.component.html",
})
export class SubdepartmentAddComponent implements OnInit {
  subdepartment: SubDepartment;
  departmentList: Department[];

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, removeSpaces]),
    departmentId: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  constructor(
    public departmentService: DepartmentService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<SubdepartmentAddComponent>,
    public fb: FormBuilder
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.departmentService.listAll().subscribe((result) => {
      this.departmentList = result;
    });
  }

  save() {
    if (this.form.valid) {
      this.subdepartment = this.form.value;
      this.departmentService
        .addSub(this.subdepartment)
        .subscribe((backendResult) => {
          if (backendResult.isSuccess) {
            this.translate
              .get(`messages.${backendResult.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.success(data);
              });
            this.dialogRef.close(this.subdepartment);
          } else {
            this.translate
              .get(`messages.${backendResult.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.error(data);
              });
          }
        });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
