<div fxLayout="row wrap">
  <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.gt-xs="100">
    <mat-card>
      <mat-card-content>
        <mat-card-title>Product Channels</mat-card-title>
        <div class="bg-light p-10 p-r-20 p-l-20">
          <mat-form-field>
            <input
              (keyup)="applyFilter($event.target.value)"
              matInput
              placeholder="Filter"
            />
          </mat-form-field>
        </div>
        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="channel">
              <mat-header-cell *matHeaderCellDef mat-sort-header="id">
                Channels</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let rowIndex = index"
                [style.color]="row.color"
              >
                <span
                  (dblclick)="editing[rowIndex + '-channelId'] = true"
                  *ngIf="!editing[rowIndex + '-channelId']"
                  title="Double click to edit"
                >
                  {{ getChannel(row.id) }}
                </span>

                <select
                  (change)="onUpdate($event, 'channelId', rowIndex)"
                  *ngIf="editing[rowIndex + '-channelId']"
                  [value]="row.channel"
                >
                  <option
                    *ngFor="let o of channelList"
                    [value]="o.id"
                    value="option"
                  >
                    {{ o.name }}
                  </option>
                </select>
                <button
                  (click)="editing[rowIndex + '-channelId'] = false"
                  *ngIf="editing[rowIndex + '-channelId']"
                  color="basic"
                  mat-icon-button
                >
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRightReverse'">
                Actions</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="'toRightReverse'"
                [style.color]="row.color"
              >
                <button
                  (click)="onDelete(row.id)"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
            [pageSize]="50"
          ></mat-paginator>
        </div>
        <mat-card-actions>
          <button (click)="onAdd()" color="accent" mat-raised-button>
            Add Product Channel
          </button>
          <button
            (click)="cancel()"
            color="accent"
            mat-raised-button
            type="button"
          >
            Cancel
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
