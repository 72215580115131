import { Observable } from "rxjs";
import { LaunchTimeline } from "../launchTimeline";
export abstract class LaunchTimelineData {
  abstract listAll(): Observable<any[]>;

  abstract list(scenarioId: number): Observable<any[]>;

  abstract launchTimeline(
    scenarioId: number,
    productId: number
  ): Observable<any>;

  abstract add(launchTimeline: LaunchTimeline);

  abstract update(launchTimeline: LaunchTimeline);

  abstract delete(id: number);
}
