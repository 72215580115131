<h1 mat-dialog-title>{{ "guidance.keyContacts.edit" | translate }}</h1>
<div mat-dialog-content>
  <div class="responsive-table">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="department">
        <mat-header-cell *matHeaderCellDef mat-sort-header="year">
          {{ "guidance.keyContacts.department" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'department')">
            <ng-template viewMode>
              {{ element.department }}
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <input
                  matInput
                  [formControl]="getControl(element.id, 'department')"
                  focusable
                  editableOnEnter
                />
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header="name">{{
          "guidance.keyContacts.name" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'name')">
            <ng-template viewMode>
              {{ element.name }}
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <input
                  matInput
                  [formControl]="getControl(element.id, 'name')"
                  focusable
                  editableOnEnter
                />
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef mat-sort-header="phone">{{
          "guidance.keyContacts.phone" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'phone')">
            <ng-template viewMode>
              {{ element.phone }}
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <input
                  matInput
                  type="tel"
                  maxlength="15"
                  [formControl]="getControl(element.id, 'phone')"
                  focusable
                  editableOnEnter
                />
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header="email">{{
          "guidance.keyContacts.email" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <editable (update)="updateField(element.id, 'email')">
            <ng-template viewMode>
              {{ element.email }}
            </ng-template>
            <ng-template editMode>
              <mat-form-field>
                <input
                  matInput
                  [formControl]="getControl(element.id, 'email')"
                  focusable
                  editableOnEnter
                />
              </mat-form-field>
            </ng-template>
          </editable>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">{{
          "guidance.actions" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
          <button
            (click)="onDelete(row)"
            autofocus
            color="warn"
            mat-icon-button
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>

    <table *ngIf="IsEmpty == true" style="min-height: 48px">
      <tr>
        <td style="text-align: center" [attr.colspan]="displayedColumns.length">
          No Records Found!
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25]"
    ></mat-paginator>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="addDialog()" mat-button tabindex="2">
    {{ "guidance.add" | translate }}
  </button>
  <button (click)="cancel()" mat-button type="button">
    {{ "guidance.close" | translate }}
  </button>
</div>
