import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ApprovalFlowService } from "@planard/@core/backend/service/approval-flow.service";
import { ToastrService } from "ngx-toastr";
import { ApprovalFlow } from "@planard/@core/entities/approvalFlow";
import { ComboItemStr } from "@planard/@core/entities/common/comboItemStr";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  templateUrl: "./approver-add.component.html",
})
export class ApproverAddComponent implements OnInit {
  approverList: ComboItemStr[];
  approvalFlowUser: ApprovalFlow;
  form: FormGroup = new FormGroup({
    approverId: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  constructor(
    public fb: FormBuilder,
    public approvalFlowService: ApprovalFlowService,
    public toastrService: ToastrService,
    public dialogRef: MatDialogRef<ApproverAddComponent>,
    @Inject(MAT_DIALOG_DATA) public applicationUserId: any
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.approvalFlowService.listApprovers().subscribe((results: any) => {
      this.approverList = results;
    });
  }

  save() {
    if (this.form.valid) {
      this.approvalFlowUser = this.form.value;
      this.approvalFlowUser.approverUserId = this.applicationUserId;
      this.approvalFlowService
        .update(this.approvalFlowUser)
        .subscribe((backendResult) => {
          if (backendResult.isSuccess) {
            this.toastrService.success(backendResult.message);
            this.dialogRef.close(this.approvalFlowUser);
          } else {
            this.toastrService.error(backendResult.message);
          }
        });
    } else {
      this.toastrService.warning("Please fill all required fields.");
    }
  }

  cancelClick() {
    this.dialogRef.close(false);
  }
}
