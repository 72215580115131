import { Injectable } from "@angular/core";
import { NotificationApi } from "@planard/@core/backend/api/notification.api";
import { NotificationData } from "@planard/@core/entities/interface/notifications";
import { Observable } from "rxjs";

@Injectable()
export class NotificationService extends NotificationData {
  constructor(private api: NotificationApi) {
    super();
  }

  getNotifications() {
    return this.api.getNotifications<Notification[]>();
  }

  getAllNotifications(): Observable<any[]> {
    return this.api.getAllNotifications<Notification[]>();
  }
}
