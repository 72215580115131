import { Observable } from "rxjs";

export abstract class ReportsData {
  abstract listChannels(
    scenarioId: number,
    granularityId: number,
    actuals: any,
    periodId: any,
    currencyId: number,
    indicatorId: number
  ): Observable<any>;

  abstract listSales(
    scenarioId: number,
    granularityId: number,
    actuals: any,
    periodId: any,
    currencyId: number,
    indicatorId: number
  ): Observable<any>;

  abstract listProduct(
    scenarioId: number,
    granularityId: number,
    actuals: any,
    periodId: any,
    currencyId: number,
    indicatorId: number
  ): Observable<any>;

  abstract priceVolume(
    productId: number,
    scenarioId: number,
    startActuals: number,
    startPeriods: number,
    endActuals: number,
    endPeriods: number,
    currency: number
  ): Observable<any>;

  abstract scenarioComp(
    scenarioId: any,
    granularityId: number,
    categoryId: number,
    indicatorId: number,
    timescale: number,
    actuals: number,
    periodId: number,
    currencyId: number
  ): Observable<any>;

  abstract listIncomeStatement(
    scenarioId: number,
    guidanceId: number,
    actuals: any,
    periodId: any,
    currency: number
  ): Observable<any>;
}
