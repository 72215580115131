<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 85%">
            <mat-card-title>{{ "costType.title" | translate }}</mat-card-title>
          </div>
          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>

        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header="name">
                {{ "costType.name" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'name')">
                  <ng-template viewMode>
                    {{ element.name }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        maxlength="20"
                        [formControl]="getControl(element.id, 'name')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="categoryId">
              <mat-header-cell *matHeaderCellDef mat-sort-header="categoryId">{{
                "costType.category" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable2 (update)="updateField(element.id, 'categoryId')">
                  <ng-template viewMode2>
                    <span
                      *ngIf="'category.' + getCategory(element) as variable"
                      >{{ variable | translate }}</span
                    >
                  </ng-template>
                  <ng-template editMode2>
                    <mat-form-field>
                      <mat-select
                        (selectionChange)="subCategorySet($event.value)"
                        [formControl]="getControl(element.id, 'categoryId')"
                      >
                        <mat-option
                          *ngFor="let o of categoryList"
                          [value]="o.id"
                        >
                          <span *ngIf="'category.' + o.name as variable">{{
                            variable | translate
                          }}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field
                      (click)="subCategorySet(element.categoryId)"
                    >
                      <mat-select
                        [formControl]="getControl(element.id, 'subCategoryId')"
                      >
                        <mat-option *ngFor="let o of subCatList" [value]="o.id">
                          <span *ngIf="'subcategory.' + o.name as variable">{{
                            variable | translate
                          }}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable2>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="subCategoryId">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="subCategoryId"
                >{{ "costType.subCategory" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'subCategoryId')">
                  <ng-template viewMode>
                    <span
                      *ngIf="
                        'subcategory.' + getSubCategory(element) as variable
                      "
                      >{{ variable | translate }}</span
                    >
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field
                      (click)="subCategorySet(element.categoryId)"
                    >
                      <mat-select
                        [formControl]="getControl(element.id, 'subCategoryId')"
                      >
                        <mat-option *ngFor="let o of subCatList" [value]="o.id">
                          <span *ngIf="'subcategory.' + o.name as variable">{{
                            variable | translate
                          }}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="costType">
              <mat-header-cell *matHeaderCellDef mat-sort-header="costType">{{
                "costType.costType" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'costType')">
                  <ng-template viewMode>
                    <span
                      *ngIf="'enums.costtype.' + element.costType as variable"
                      >{{ variable | translate }}</span
                    >
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <mat-select
                        [formControl]="getControl(element.id, 'costType')"
                      >
                        <mat-option *ngFor="let o of costTypeList" [value]="o">
                          <span *ngIf="'enums.costtype.' + o as variable">{{
                            variable | translate
                          }}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lifeSpan">
              <mat-header-cell *matHeaderCellDef mat-sort-header="lifeSpan">
                {{ "costType.lifeSpan" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="element.costType != 'ASSETS'">{{
                  element.costType == "ASSETS" ? element.lifeSpan : "-"
                }}</span>

                <editable
                  (update)="updateField(element.id, 'lifeSpan')"
                  *ngIf="element.costType == 'ASSETS'"
                >
                  <ng-template viewMode>
                    {{ element.costType == "ASSETS" ? element.lifeSpan : "-" }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        type="number"
                        [formControl]="getControl(element.id, 'lifeSpan')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRight'">
                {{ "costType.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'toRight'">
                <button
                  (click)="onDelete(row)"
                  autofocus
                  color="warn"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>

        <mat-card-actions>
          <button (click)="addDialog()" color="accent" mat-raised-button>
            {{ "costType.add" | translate }}
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
