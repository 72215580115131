import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  Inject,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PlanningAddComponent } from "./add/planning-add.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./planning-edit-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 10%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class PlanningEditNewComponent implements OnInit {
  displayedColumns: string[] = [
    "year",
    "planningType",
    "startMonth",
    "actions",
  ];
  dataSource: MatTableDataSource<GuidancePlanningPeriod>;
  controls: any[];
  deneme = "deneme";

  currentYear = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  yearList = [
    this.currentYear,
    this.currentYear + 1,
    this.currentYear + 2,
    this.currentYear + 3,
    this.currentYear + 4,
    this.currentYear + 5,
    this.currentYear + 6,
    this.currentYear + 7,
    this.currentYear + 8,
    this.currentYear + 9,
  ];

  granularityList = [
    { value: "Product", label: "Product" },
    { value: "Product-Channel", label: "Product-Channel" },
    { value: "Product-Form", label: "Product-Form" },
    { value: "Product-Channel-Form", label: "Product-Channel-Form" },
  ];

  monthList = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  guidanceId: number;
  IsEmpty: boolean;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<PlanningEditNewComponent>,
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    private guidanceService: GuidanceService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.guidanceId = data.guidanceId;
  }

  ngOnInit() {
    //this.translate.get(`${this.deneme}`).subscribe((data:any)=> {
    //this.toastrService.success(data);
    //});

    this.guidanceService
      .getPlanningRecord(this.guidanceId)
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);

        if (data != null) {
          this.IsEmpty = false;
        } else {
          this.IsEmpty = true;
        }

        if (this.dataSource != null) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
            typeof data[sortHeaderId] === "string"
              ? data[sortHeaderId].toLocaleLowerCase()
              : data[sortHeaderId];
          this.dataSource.sort = this.sort;

          const sortState: Sort = { active: "year", direction: "asc" };
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState);
        }

        this.controls = data.map((entity) => {
          return {
            data: entity,
            formGroup: new FormGroup(
              {
                year: new FormControl(entity.year, [Validators.required]),
                planningType: new FormControl(entity.planningType, [
                  Validators.required,
                ]),
                startMonth: new FormControl(entity.startMonth, [
                  Validators.required,
                ]),
              },
              { updateOn: "change" }
            ),
          };
        });
      });
  }

  setMonth(index, value: any) {
    var control = this.getControl(index, "startMonth");
    if (value == "Yearly") {
      control.setValue(0);
    }
  }

  getPlanningType(type: string) {
    var t = "";
    this.translate.get(`guidance.${type}`).subscribe((data: any) => {
      t = data;
    });
    return t;
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var period = this.getElement(index);
      period[field] = control.value;

      if (
        field == "planningType" &&
        control.value == "Monthly" &&
        (period.startMonth == null || period.startMonth == 0)
      ) {
        this.translate.get("common.formNotValid").subscribe((data: any) => {
          this.toastrService.error(data);
        });
        return;
      }

      this.guidanceService.updatePeriod(period).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.ngOnInit();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.ngOnInit();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    const dialogRef = this.dialog.open(PlanningAddComponent, {
      width: "500px",
      data: {
        guidanceId: this.guidanceId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  onDelete(row: any) {
    this.translate.get("delete.planningperiod").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.guidanceService.deletePeriod(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.toastrService.success(result.message);
              this.ngOnInit();
            } else {
              this.toastrService.error(result.message);
              this.ngOnInit();
            }
          });
        }
      });
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  getMonth(month: number) {
    if (month != null) {
      for (let mt of this.monthList) {
        if (month == mt.value) {
          return mt.label;
        }
      }
    }
  }
}
