import { AbstractEnum } from "../base/AbstractEnum";

export class EnumReportAccess extends AbstractEnum {
  static readonly COST_REPORTS_OWN = new EnumReportAccess("Cost reports (own)");
  static readonly COST_REPORTS_ALL = new EnumReportAccess("Cost reports (all)");
  static readonly HC_REPORTS_OWN = new EnumReportAccess("HC reports (own)");
  static readonly HC_REPORTS_ALL = new EnumReportAccess("HC reports (all)");
  static readonly SALES_REPORTS = new EnumReportAccess("Sales Reports");
  static readonly LAUNCH_REPORTS = new EnumReportAccess("Launch Reports");
  static readonly ALL_OWN_PRODUCT = new EnumReportAccess("All (own product)");
  static readonly ALL = new EnumReportAccess("All");

  static values = AbstractEnum.getValues(EnumReportAccess);

  private constructor(public readonly displayValue: string) {
    super();
  }
}
