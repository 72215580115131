import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { LoaderService } from "@planard/@core/backend/service/loader.service";
import { ReloadService } from "@planard/@core/backend/service/reload.service";
@Component({
  selector: "app-auth-callback",
  templateUrl: "./auth-callback.component.html",
  styleUrls: ["./auth-callback.component.scss"],
})
export class AuthCallbackComponent implements OnInit {
  isSuper: boolean = false;
  location;

  constructor(
    private router: Router,
    private loader: LoaderService,
    private authService: AuthService,
    public reloadService: ReloadService
  ) {}

  ngOnInit() {
    this.authService.completeAuthentication().then((e) => {
      this.loader.show();
      this.authService.getAppUser();
      setTimeout(() => {
        this.isSuper = this.authService.isSuperAdmin();
        if (this.isSuper) this.router.navigate(["/admin-super/companies"]);
        else {
          let companyPackage = this.authService.getCompanyPackage();
          if (companyPackage == "basic")
            this.router.navigate(["/planning-instructions/guidance"]);
          else this.router.navigate(["/dashboard"]);
        }
        this.loader.hide();
      }, 2000);
    });
  }
}
