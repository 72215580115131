<h1 mat-dialog-title>{{ "guidance.keyContacts.add" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        tabindex="1"
        formControlName="department"
        matInput
        name="department"
        placeholder="{{ 'guidance.keyContacts.department' | translate }}"
        tabindex="1"
      />
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="2"
        formControlName="name"
        matInput
        name="name"
        placeholder="{{ 'guidance.keyContacts.name' | translate }}"
        tabindex="1"
      />
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="3"
        type="tel"
        maxlength="15"
        formControlName="phone"
        matInput
        name="phone"
        placeholder="{{ 'guidance.keyContacts.phone' | translate }}"
        tabindex="1"
      />
    </mat-form-field>

    <mat-form-field>
      <input
        tabindex="4"
        formControlName="email"
        matInput
        name="email"
        placeholder="{{ 'guidance.keyContacts.email' | translate }}"
        tabindex="1"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="5" type="submit">
      {{ "guidance.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="6" type="button">
      {{ "guidance.cancel" | translate }}
    </button>
  </div>
</form>
