import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "environments/environment";
import { map, catchError } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const token = ??

    if (request.url == "/assets/i18n/en.json") return;
    if (request.url == "/assets/i18n/tr.json") return;

    if (request.url.startsWith(environment.apiUrl)) {
      request = request.clone({
        setHeaders: {
          Accept: "text/html",
          Authorization: this.authService.getAuthorizationHeaderValue(),
        },
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const res = event;
          if (!res.body.isSuccess) {
            // hata var
          }
        }

        return event;
      }),
      catchError((err: any, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            console.log("err.error =", err.error, ";");
          }
          return throwError(err);
        }
      })
    );
  }
}
