<h1 mat-dialog-title>{{ "subDepartmentAdd.title" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        formControlName="name"
        matInput
        name="name"
        placeholder="{{ 'subDepartmentAdd.name' | translate }}"
        tabindex="1"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="2"
        formControlName="departmentId"
        name="department"
        placeholder="{{ 'subDepartmentAdd.department' | translate }}"
      >
        <mat-option
          *ngFor="let o of departmentList"
          [value]="o.id"
          value="option"
        >
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-button tabindex="3" type="submit">
      {{ "subDepartmentAdd.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="4" type="button">
      {{ "subDepartmentAdd.cancel" | translate }}
    </button>
  </div>
</form>
