import { EventEmitter, Injectable } from "@angular/core";
import { CostsApi } from "../api/costs.api";
import { CostsData } from "../../entities/interface/costs";
import { CostType } from "../../entities/costType";
import { Observable } from "rxjs";
import { Category } from "@planard/@core/entities/category";
import { SubCategory } from "@planard/@core/entities/subcategory";

@Injectable()
export class CostService extends CostsData {
  onCostTypeReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: CostsApi) {
    super();
  }

  add(costType: CostType) {
    return this.api.add<any>(costType);
  }

  delete(id: number) {
    return this.api.delete<any>(id);
  }

  listAll(): Observable<CostType[]> {
    return this.api.listAll<CostType[]>();
  }

  update(costType: CostType) {
    return this.api.update<any>(costType);
  }

  listCategories(): Observable<Category[]> {
    return this.api.listCategories<Category[]>();
  }

  listCategoriesCurrent(): Observable<Category[]> {
    return this.api.listCategoriesCurrent<Category[]>();
  }

  listSubcategories(): Observable<SubCategory[]> {
    return this.api.listSubcategories<SubCategory[]>();
  }
}
