import { Observable } from "rxjs";
import { OtherForecast } from "../otherForecast";

export abstract class OtherForecastData {
  abstract listPrices(
    scenarioId: number,
    approveRequestId?: number
  ): Observable<any>;

  abstract update(otherForecast: OtherForecast): Observable<any>;
}
