import { Routes } from "@angular/router";
import { CompanySetupComponent } from "@planard/admin/super/companies/company-setup.component";
import { SettingsComponent } from "./settings/settings.component";

export const AdminSuperRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "companies",
        component: CompanySetupComponent,
        data: {
          title: "Companies",
          urls: [{ title: "Companies", url: "/companies" }],
        },
      },
      {
        path: "settings",
        component: SettingsComponent,
        data: {
          title: "Settings",
          urls: [{ title: "Settings", url: "/settings" }],
        },
      },
    ],
  },
];
