import { Component, Inject, OnInit } from "@angular/core";
import { CompanySetupService } from "@planard/@core/backend/service/company-setup.service";
import { Company } from "@planard/@core/entities/company";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { EnumSector } from "@planard/@core/enums/EnumSector";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./company-setup-add.component.html",
})
export class CompanySetupAddComponent implements OnInit {
  sectorList: EnumSector[] = EnumSector.values;
  currencyList: EnumCurrency[] = EnumCurrency.values;
  company: Company;
  adminRole;
  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, removeSpaces]),
    companyCode: new FormControl("", [Validators.required, removeSpaces]),
    sector: new FormControl("", [Validators.required]),
    contactEmail: new FormControl("", [Validators.required, removeSpaces]),
    contactName: new FormControl("", [Validators.required, removeSpaces]),
    package: new FormControl("", Validators.required),
    language: new FormControl("", Validators.required),
    userCount: new FormControl(""),
  });
  options: FormGroup;

  package;
  userCount;

  constructor(
    public fb: FormBuilder,
    public companySetupService: CompanySetupService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<CompanySetupAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Company
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {}

  save() {
    if (this.form.valid) {
      this.company = this.form.value;
      if (this.package == "basic") this.company.userCount = 3;
      this.companySetupService.add(this.company).subscribe((backendResult) => {
        if (backendResult.isSuccess == true) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.dialogRef.close(this.company);
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  setUser() {
    if (this.package == "basic") this.userCount = 3;
    if (this.package == "premium") this.userCount = 0;
  }
}
