import { Component, OnInit } from "@angular/core";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { MatDialog } from "@angular/material/dialog";
import { Guidance } from "@planard/@core/entities/guidance";
import { GuidanceTarget } from "@planard/@core/entities/guidanceTarget";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { CompanySetupService } from "@planard/@core/backend/service/company-setup.service";
import { FaqService } from "@planard/@core/backend/service/faq.service";
import { Faq } from "@planard/@core/entities/faq";
import { GuidanceFxRate } from "@planard/@core/entities/guidanceFxRate";
import { GuidanceInflationRate } from "@planard/@core/entities/guidanceInflationRate";
import { GuidanceKeyContact } from "@planard/@core/entities/guidanceKeyContact";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import { NumberDecimalReversePipe } from "@planard/@core/pipes/NumberDecimalReversePipe";
import { AuthService } from "@planard/auth/services/auth.service";

@Component({
  templateUrl: "./guidance.component.html",
  styleUrls: ["./guidance.component.scss"],
  styles: [
    `
      .toRight {
        flex: 0 0 95%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
  providers: [NumberDecimalPipe, NumberDecimalReversePipe],
})
export class GuidanceComponent implements OnInit {
  guidancePlanningPeriodList: GuidancePlanningPeriod[];
  guidanceTargetList: GuidanceTarget[];
  guidanceKeyContactList: GuidanceKeyContact[];
  guidance: Guidance = new Guidance();
  faqList: Faq[];
  guidanceIsStart: boolean;
  guidanceFxRateList: GuidanceFxRate[];
  guidanceInflationRateList: GuidanceInflationRate[];
  numberFormat: string;
  package: string;
  fxflexvalue: number;
  decimalFormat: string;
  monthList = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  constructor(
    public dialog: MatDialog,
    private guidanceService: GuidanceService,
    public faqService: FaqService,
    private numberDecimal: NumberDecimalPipe,
    public authService: AuthService,
    private numberReverseDecimal: NumberDecimalReversePipe,
    public companySetupService: CompanySetupService
  ) {
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();
    this.package = this.authService.getCompanyPackage();

    if (this.package == "premium") this.fxflexvalue = 70;
    else this.fxflexvalue = 40;
  }

  ngOnInit() {
    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidance = result;
      this.guidanceIsStart = result.start ? true : false;
      this.guidanceService
        .getPlanningRecord(this.guidance.id)
        .subscribe((result) => {
          this.guidancePlanningPeriodList = result;
          //this.guidancePlanningPeriodList = this.guidancePlanningPeriodList.slice(0, 2);
        });
      this.guidanceService
        .getTargetRecord(this.guidance.id)
        .subscribe((result) => {
          this.guidanceTargetList = result;
          //this.guidanceTargetList = this.guidanceTargetList.slice(0, 2);
        });
      this.guidanceService
        .getKeyContact(this.guidance.id)
        .subscribe((result) => {
          this.guidanceKeyContactList = result;
          //this.guidanceKeyContactList = this.guidanceKeyContactList.slice(0, 1);
        });
      this.guidanceService.getFxRate(this.guidance.id).subscribe((result) => {
        this.guidanceFxRateList = result;
        //this.guidanceFxRateList = this.guidanceFxRateList.slice(0, 3);
      });
      this.guidanceService
        .getInflationRate(this.guidance.id)
        .subscribe((result) => {
          this.guidanceInflationRateList = result;
          //this.guidanceInflationRateList = this.guidanceInflationRateList.slice(0, 3);
        });
    });
    this.faqService.listAll().subscribe((result) => {
      this.faqList = result;
      //this.faqList = this.faqList.slice(0, 2);
    });
  }

  formatNumber(value, decimal, number) {
    return this.numberDecimal.transform(value, decimal, number);
  }

  formatNumberReverse(string, decimal, number) {
    return this.numberReverseDecimal.transform(string, decimal, number);
  }

  getMonth(month: number) {
    if (month != null) {
      for (let mt of this.monthList) {
        if (month == mt.value) {
          return mt.label;
        }
      }
    }
  }

  getCycleStart(start: any) {
    var current: any = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    var start: any = new Date(
      (typeof start === "string" ? new Date(start) : start).toLocaleString(
        "en-US",
        { timeZone: "Europe/Berlin" }
      )
    );
    if (current < start) {
      return true;
    } else {
      return false;
    }
  }
}
