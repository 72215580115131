import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ToastrService } from "ngx-toastr";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { ProductForm } from "@planard/@core/entities/productForm";
import { TranslateService } from "@ngx-translate/core";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { AuthService } from "@planard/auth/services/auth.service";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./productForm-add.component.html",
})
export class ProductFormAddComponent {
  productForm: ProductForm;
  pfForm: FormGroup = new FormGroup({
    form: new FormControl("", [Validators.required, removeSpaces]),
    conversionRate: new FormControl("", [
      Validators.required,
      Validators.min(0),
    ]),
  });
  options: FormGroup;
  guidanceId: number;
  package: string;

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder,
    public productsService: ProductsService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public guidanceService: GuidanceService,
    public authService: AuthService,
    public dialogRef: MatDialogRef<ProductFormAddComponent>,
    @Inject(MAT_DIALOG_DATA) public productId: number
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
    setTimeout(() => {
      this.package = this.authService.getCompanyPackage();
    }, 150);
  }

  ngOnInit() {
    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidanceId = result.id;
    });
  }

  save() {
    if (this.pfForm.valid) {
      this.translate.get("delete.productformWarn").subscribe((data: any) => {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: "350px",
          data: data,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.productForm = this.pfForm.value;
            this.productForm.productId = this.productId;
            this.productsService
              .addForm(this.productForm)
              .subscribe((backendResult) => {
                if (backendResult.isSuccess) {
                  this.translate
                    .get(`messages.${backendResult.messageId}`)
                    .subscribe((data: any) => {
                      this.toastrService.success(data);
                    });
                  if (this.package == "basic") {
                    this.startCycleAuto();
                  }
                  this.dialogRef.close(this.productForm);
                } else {
                  this.translate
                    .get(`messages.${backendResult.messageId}`)
                    .subscribe((data: any) => {
                      this.toastrService.error(data);
                    });
                }
              });
          }
        });
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancelClick() {
    this.dialogRef.close(false);
  }

  startCycleAuto() {
    let body = { guidanceId: this.guidanceId };
    this.guidanceService.startGuidance(body).subscribe((result) => {
      if (result.isSuccess) {
        console.log("guidance started!!!");
      } else {
        console.log("result: " + result.message);
        console.log("guidance cannot started!!!");
      }
    });
  }
}
