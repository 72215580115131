<h1 mat-dialog-title>{{ "guidance.granularity.edit" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <mat-select
        tabindex="1"
        placeholder="{{ 'guidance.granularity.title' | translate }}"
        name="granularityType"
        formControlName="granularityType"
      >
        <mat-option
          *ngFor="let o of granularityList"
          [value]="o.value"
          value="option"
        >
          <span *ngIf="'guidance.' + o.label as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="2" type="submit">
      {{ "guidance.save" | translate }}
    </button>
    <button (click)="cancel()" mat-button tabindex="3" type="button">
      {{ "guidance.cancel" | translate }}
    </button>
  </div>
</form>
