import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AgGridAngular } from "ag-grid-angular";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { Scenario } from "@planard/@core/entities/scenario";
import { MatDialog } from "@angular/material/dialog";
import { Overlay } from "@angular/cdk/overlay";
import { Guidance } from "@planard/@core/entities/guidance";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ProductForm } from "@planard/@core/entities/productForm";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import { FxRate } from "@planard/@core/entities/fxRate";
import { ReportsService } from "@planard/@core/backend/service/reports.service";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import { NumberDecimalReversePipe } from "@planard/@core/pipes/NumberDecimalReversePipe";
import * as XLSX from "xlsx";
@Component({
  templateUrl: "./product-overview.component.html",
  styleUrls: ["./product-overview.component.scss"],
  providers: [NumberDecimalPipe, NumberDecimalReversePipe],
})
export class ProductOverviewComponent implements OnInit {
  //#region
  @ViewChild("agGrid") agGrid: AgGridAngular;
  private gridApi;
  private gridColumnApi;

  rowData: any;
  fullData: any;
  fullData4Import: any;
  display: any = 0;
  displayList = [
    { value: 0, label: "Sales" },
    { value: 1, label: "Growth(%)" },
    { value: 2, label: "Growth(Value)" },
  ];
  year4actual: number;
  rowDataS: any;
  fullDataS: any;
  isPercent: boolean;
  data2Exel: any;
  headData: any; // excel row header
  myparams: any;
  scenarioList: Scenario[];
  scenarioList2Base: Scenario[];
  scenarioCopyList: Scenario[];
  months: string[];
  years: number[];
  guidanceList: Guidance[];
  scenarioGuiList: Scenario[] = [];
  formList: ProductForm[];
  volume: boolean;
  amount: boolean;
  scenario: Scenario;
  copyScenarioId: number;
  guidanceId: number;
  percent2coloring: Number = 40;
  sheetName: string;
  input: boolean;
  notes: boolean;
  salesPriceId: number;
  exelYear: number = 2020;
  exelName: string;
  file: File;
  filelist: any;
  priceLength: number;
  value2copy: any;
  increaseValue: number;
  decreaseValue: number;
  noteValue: any;
  file_name: string;
  formConversionRate: number = 1;
  guidanceIsStart: any = null;
  currencyList: EnumCurrency[] = EnumCurrency.values;
  indicatorList = [
    { value: 0, label: "Units" },
    { value: 1, label: "Amount" },
  ];

  granularityList = [
    { value: 0, label: "Product" },
    { value: 1, label: "Product-Channel" },
    { value: 2, label: "Product-Form" },
    { value: 3, label: "Product-Form-Channel" },
  ];
  currency: any = 0;
  granularity: any = 0;
  indicator: any = 1;
  scenarioId: number;
  reportScreenshot: ReportScreenshot = {};
  img = "";
  isSet: boolean;
  imgName: any;
  imgDescription: any;
  fxrateList: FxRate[];
  guidanceFxRateList: any[];
  actualsList: any[] = [];
  currentPlanPeriodList: any[] = [];
  oldPlansPeriodList: any[] = [];
  period: any;
  periodGroup: any;
  guidanceName: any;
  loadingTemplate: any;
  noRowsTemplate: any;
  isRun: boolean;
  firstYear: any;
  exelHeaderList: any[] = [];
  isRoleValid: boolean;
  isCurrenctYearExist = false;
  companyCurrency: string;
  companyNumberFormat: string;
  isOpen: boolean = false;
  displayTotalBase: any;

  numberFormat: string;
  decimalFormat: string;

  rawData;

  @ViewChild("screen", { static: true }) screen: any;
  defaultColDef = {
    //editable: true, //this.cellEditable,
    sortable: false,
    flex: 1,
    minWidth: 60,
    filter: false,
    resizable: true,
    cellEditor: "numericCellEditor",
  };

  components = { numericCellEditor: this.getNumericCellEditor() };
  columnDefs = [];

  currentplanperiods: GuidancePlanningPeriod[]; //header için
  oldplanperiods: GuidancePlanningPeriod[] = []; //header için
  allPlansPeriods = [];
  headerName: any;

  actualsName;
  currentPlanName;
  oldPlansName;
  name;

  fullYear = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  scenarioControl = new FormControl("", [Validators.required]);
  indicatorControl = new FormControl("", [Validators.required]);
  displayControl = new FormControl("", [Validators.required]);
  granularityControl = new FormControl("", [Validators.required]);
  periodControl = new FormControl("", [Validators.required]);
  currencyControl = new FormControl("", [Validators.required]);

  headerPeriodList = [];
  //#endregion

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public toastrService: ToastrService,
    public reportScreenshotService: ReportScreenshotService,
    public reportsService: ReportsService,
    public productsService: ProductsService,
    public translate: TranslateService,
    public actualsService: ActualsService,
    public guidanceService: GuidanceService,
    public scenarioService: ScenarioService,
    private numberDecimal: NumberDecimalPipe,
    private router: Router,
    public authService: AuthService,
    private captureService: NgxCaptureService,
    public dialog: MatDialog
  ) {
    this.translate.get("agGrid.report").subscribe((data: any) => {
      this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
    });
    this.translate.get("agGrid.no").subscribe((data: any) => {
      this.noRowsTemplate = `"<span">${data}</span>"`;
    });

    translate.onLangChange.subscribe((lang) => {
      this.translate.get("agGrid.report").subscribe((data: any) => {
        this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
      });
      this.translate.get("agGrid.no").subscribe((data: any) => {
        this.noRowsTemplate = `"<span">${data}</span>"`;
      });

      if (this.translate.currentLang == "en") {
        if (this.granularity == 0) this.name = "Product";
        if (this.granularity == 1) this.name = "Product-Channel";
        if (this.granularity == 2) this.name = "Product-Form";
        if (this.granularity == 3) this.name = "Product-Form-Channel";
      }
      if (this.translate.currentLang == "tr") {
        if (this.granularity == 0) this.name = "Ürün";
        if (this.granularity == 1) this.name = "Ürün-Kanal";
        if (this.granularity == 2) this.name = "Ürün-Form";
        if (this.granularity == 3) this.name = "Ürün-Form-Kanal";
      }
      this.change();
    });

    var companyPackage = this.authService.getCompanyPackage();
    if (companyPackage == "basic")
      this.isRoleValid =
        this.authService.isAdmin() || this.authService.isBusinessUnitPlanner();

    if (companyPackage == "premium")
      this.isRoleValid =
        this.authService.isAdmin() ||
        this.authService.isBusinessUnitApprover() ||
        this.authService.isBusinessUnitPlanner();

    if (this.isRoleValid == false) this.router.navigate(["auth/404"]);

    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();
    this.companyNumberFormat =
      this.authService.getNumberFormat() == "noScale"
        ? ""
        : this.authService.getNumberFormat() == "thousands"
        ? "k"
        : this.authService.getNumberFormat() == "millions"
        ? "m"
        : "n/a";
    this.companyCurrency = this.authService.getCompanyCurrency();

    this.months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
  }

  ngOnInit() {
    this.scenarioService.listScenariosWithSales().subscribe((result) => {
      this.scenarioList = result;
      result.forEach((element) => {
        if (element.isBase == true) {
          this.scenarioId = element.id;
        }
      });
    });
    this.guidanceService.listAllFxRates().subscribe((result: any) => {
      this.fxrateList = result;
    });
    this.guidanceService.getGuidance().subscribe((result: any) => {
      this.guidanceName = result.planningCycleName;
      this.guidanceId = result.id;
      this.guidanceService.getFxRate(this.guidanceId).subscribe((data) => {
        this.guidanceFxRateList = data;
      });
      //plan yılları
      this.guidanceService
        .getPlanningRecord(this.guidanceId)
        .subscribe((result2) => {
          this.currentplanperiods = result2;
          result2.forEach((element) => {
            let ele = {
              value: element.id,
              label: `${this.guidanceName}'${element.year}`,
              isActuals: false,
            };
            this.currentPlanPeriodList.push(ele);
            this.headerPeriodList.push(ele);
          });
        });
      //eski plan yılları
      // this.guidanceService.listAll().subscribe((result3) => {
      //   result3.forEach((ele) => {
      //     if (ele.id == this.guidanceId) {
      //       return;
      //     }
      //     var guidance = ele;
      //     this.guidanceService
      //       .getPlanningRecord(ele.id)
      //       .subscribe((result4) => {
      //         result4.forEach((element) => {
      //           if (element.year == this.fullYear)
      //             this.isCurrenctYearExist = true;
      //           this.oldplanperiods.push(element);
      //           if (
      //             element.year == this.fullYear ||
      //             element.year == this.fullYear - 1
      //           ) {
      //             let ele = {
      //               value: element.id,
      //               label: `${guidance.planningCycleName}'${element.year}`,
      //               isActuals: false,
      //             };
      //             this.oldPlansPeriodList.push(ele);
      //             this.headerPeriodList.push(ele);
      //           }
      //         });
      //       });
      //   });
      // });
    });
    setTimeout(() => {
      // this.oldplanperiods.forEach((element) => {
      //   this.allPlansPeriods.push(element);
      // });
      this.currentplanperiods.forEach((element) => {
        this.allPlansPeriods.push(element);
      });
      this.setPeriod();
    }, 1500);
  }

  setPeriod() {
    let type = this.indicator == 0 ? "Volume" : "Amount";
    this.actualsList = [];
    this.period = [];
    this.actualsService.listFilePeriod(type).subscribe((data) => {
      data.forEach((element) => {
        //if ( element.period == this.fullYear && this.isCurrenctYearExist ) return;
        let ele = {
          value: element.period,
          label: `A'${element.period}`,
          isActuals: true,
        };
        this.actualsList.push(ele);
      });
    });
    //translate
    this.translate.get("reports.Actuals").subscribe((data: any) => {
      this.actualsName = data;
    });
    this.translate.get("reports.Current Plan").subscribe((data: any) => {
      this.currentPlanName = data;
    });
    this.translate.get("reports.Old Plans").subscribe((data: any) => {
      this.oldPlansName = data;
    });
    this.translate.onLangChange.subscribe((lang) => {
      this.translate.get("reports.Actuals").subscribe((data: any) => {
        this.actualsName = data;
      });
      this.translate.get("reports.Current Plan").subscribe((data: any) => {
        this.currentPlanName = data;
      });
      this.translate.get("reports.Old Plans").subscribe((data: any) => {
        this.oldPlansName = data;
      });
      this.periodGroup = [
        {
          name: this.actualsName, // value yıl olucak
          period: this.actualsList,
        },
        // {
        //   name: this.oldPlansName, // value plannig id olucak
        //   period: this.oldPlansPeriodList,
        // },
        {
          name: this.currentPlanName, // value plannig id olucak
          period: this.currentPlanPeriodList,
        },
      ];
      this.period = [...this.currentPlanPeriodList];
      if (this.actualsList[this.actualsList.length - 1])
        this.period.push(this.actualsList[this.actualsList.length - 1]);
    });
    setTimeout(() => {
      this.periodGroup = [
        {
          name: this.actualsName, // value yıl olucak
          period: this.actualsList,
        },
        // {
        //   name: this.oldPlansName, // value plannig id olucak
        //   period: this.oldPlansPeriodList,
        // },
        {
          name: this.currentPlanName, // value plannig id olucak
          period: this.currentPlanPeriodList,
        },
      ];
      this.period = [...this.currentPlanPeriodList];
      if (this.actualsList[this.actualsList.length - 1])
        this.period.push(this.actualsList[this.actualsList.length - 1]);
    }, 250);
  }

  take() {
    if (this.isRun != true) {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    }
    this.captureService
      .getImage(this.screen.nativeElement, true, 400)
      .pipe(
        tap((img) => {
          this.img = img;
        })
      )
      .subscribe();
  }

  reset() {
    this.img = "";
  }

  save() {
    var array = this.img.split(",");
    this.reportScreenshot.image = array[1];
    this.reportScreenshot.guidanceId = this.guidanceId;
    this.reportScreenshot.name = `${this.setScenario()} / ${
      this.displayList[this.display].label
    } / ${this.indicatorList[this.indicator].label} / ${this.getFxRate(
      this.currency
    )}`;
    this.reportScreenshot.reportName = "Product Overview";

    this.reportScreenshotService
      .addReportScreenshot(this.reportScreenshot)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
  }

  onDelete() {}

  onGridReady(params) {
    this.myparams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setDomLayout("autoHeight");
  }

  resetGrid(params) {
    params.successCallback(params.data.callRecords);
  }

  isValid() {
    if (
      this.scenarioControl.valid == false ||
      this.indicatorControl.valid == false ||
      this.displayControl.valid == false ||
      this.granularityControl.valid == false ||
      this.periodControl.valid == false ||
      this.currencyControl.valid == false
    ) {
      this.translate.get("reports.allrequired").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    } else if (
      this.scenarioControl.valid == true &&
      this.indicatorControl.valid == true &&
      this.displayControl.valid == true &&
      this.granularityControl.valid == true &&
      this.periodControl.valid == true &&
      this.currencyControl.valid == true
    ) {
      this.run();
    }
  }

  run() {
    var actuals: any = [];
    var periodIds: any = [];

    if (this.period != null) {
      this.period.forEach((element) => {
        if (element.isActuals == true) {
          actuals.push(element.value);
        } else {
          periodIds.push(element.value);
        }
      });
    }

    if (actuals.length == 0) actuals = 0;
    if (periodIds.length == 0) periodIds = 0;
    if (this.indicator == 0) this.currency = 0;

    this.reportsService
      .listProduct(
        this.scenarioId,
        this.granularity,
        actuals,
        periodIds,
        this.currency,
        this.indicator
      )
      .subscribe((result) => {
        this.isRun = true;

        result.data.forEach((element) => {
          if (element.expandable == undefined) {
            element.expandable = false;
          }
        });

        var neg = [];
        var pos = [];

        neg = result.years.filter((x) => x < 0).sort((a, b) => b - a);
        pos = result.years.filter((x) => x > 0).sort((a, b) => a - b);

        this.years = [...neg, ...pos];
        var yearsCopyActual = [...neg.map((x) => x * -1), ...[this.fullYear]];
        this.year4actual = yearsCopyActual[0];

        let data = [...result.data];
        this.rawData = [...result.data];

        if (this.display == 0) {
          this.fullData = result.data;
          this.fullData4Import = [...result.data];
          this.rowData = [...result.data].filter((x) => x.isVisible);

          this.firstYear = null;
        } else if (this.display == 1) {
          // DISPLAY == GROWTH %
          this.setTotalRowBase(data);
          for (let row of data) {
            let secondVal = 0;
            for (
              let yearIndex = 0;
              yearIndex < this.years.length;
              yearIndex++
            ) {
              // [(V2-V1)/V1] x 100
              if (row[`total_${this.years[yearIndex + 1]}`] != undefined) {
                let val = row[`total_${this.years[yearIndex + 1]}`];
                row[`total_${this.years[yearIndex + 1]}`] =
                  ((row[`total_${this.years[yearIndex + 1]}`] -
                    (yearIndex == 0
                      ? row[`total_${this.years[yearIndex]}`]
                      : secondVal)) /
                    (yearIndex == 0
                      ? row[`total_${this.years[yearIndex]}`]
                      : secondVal)) *
                  100;
                // if (row[`total_${this.years[yearIndex]}`] == 0)
                //   row[`total_${this.years[yearIndex + 1]}`] = undefined;
                secondVal = val;
              }
            }
            this.firstYear = this.years[0];
          }
          this.fullData = data;
          this.rowData = [...result.data].filter((x) => x.isVisible);
        } else if (this.display == 2) {
          for (let row of data) {
            let secondVal = 0;
            for (
              let yearIndex = 0;
              yearIndex < this.years.length;
              yearIndex++
            ) {
              // [V2-V1]
              if (row[`total_${this.years[yearIndex + 1]}`] != undefined) {
                let val = row[`total_${this.years[yearIndex + 1]}`];
                row[`total_${this.years[yearIndex + 1]}`] =
                  row[`total_${this.years[yearIndex + 1]}`] -
                  (yearIndex == 0
                    ? row[`total_${this.years[yearIndex]}`]
                    : secondVal); // / row[`total_${this.years[yearIndex]}`]);
                secondVal = val;
              }
            }
            this.firstYear = this.years[0];
          }
          this.fullData = data;
          this.rowData = [...result.data].filter((x) => x.isVisible);
        }
        this.priceLength = this.fullData.length;
        this.change();
        this.setTotalRow();
      });
  }

  getFxRate(fxRateId) {
    if (fxRateId == 0) {
      return "Default Currency";
    }
    for (let index in this.fxrateList) {
      if (fxRateId == this.fxrateList[index].id) {
        return this.fxrateList[index].name;
      }
    }
  }

  setExpandable() {
    for (let line of this.fullData) {
      var id = line.id;
      var isParent = false;
      for (let lin of this.fullData) {
        if (id == lin.parentId) {
          isParent = true;
        }
      }
      line.expandable = isParent;
    }
  }

  setTotalRow() {
    var totalRow = {};
    totalRow["product"] = "TOTAL : ";

    if (this.display == 1) {
      // 20230502 ortalama hesabını kapattırdılar.
      // for (let yearIndex = 1; yearIndex < this.years.length; yearIndex++) {
      //   totalRow[`total_${this.years[yearIndex]}`] =
      //     totalRow[`total_${this.years[yearIndex]}`] /
      //     this.rawData.filter((x) => x[`parentId`] == 0).length;
      // }
      totalRow = { ...this.displayTotalBase };
      let secondVal = 0;
      for (let yearIndex = 0; yearIndex < this.years.length; yearIndex++) {
        if (totalRow[`total_${this.years[yearIndex + 1]}`] != undefined) {
          let val = totalRow[`total_${this.years[yearIndex + 1]}`];
          totalRow[`total_${this.years[yearIndex + 1]}`] =
            ((totalRow[`total_${this.years[yearIndex + 1]}`] -
              (yearIndex == 0
                ? totalRow[`total_${this.years[yearIndex]}`]
                : secondVal)) /
              (yearIndex == 0
                ? totalRow[`total_${this.years[yearIndex]}`]
                : secondVal)) *
            100;
          if (totalRow[`total_${this.years[yearIndex]}`] == 0)
            totalRow[`total_${this.years[yearIndex + 1]}`] = undefined;
          secondVal = val;
        }
      }
      totalRow[`total_${this.years[0]}`] = undefined;
      this.gridApi.setPinnedBottomRowData([totalRow]);
    } else {
      for (let year of this.years) {
        let total = 0;
        for (let row of this.rawData) {
          if (
            row[`parentId`] == 0 &&
            row[`total_${year}`] != undefined &&
            row[`total_${year}`] != Infinity
          ) {
            total = total + Number(row[`total_${year}`]);
          }
        }
        totalRow[`total_${year}`] = total;
      }
      this.gridApi.setPinnedBottomRowData([totalRow]);
    }
  }

  setTotalRowBase(data) {
    var totalRow = {};
    totalRow["product"] = "TOTAL : ";

    for (let year of this.years) {
      let total = 0;
      for (let row of data) {
        if (
          row[`parentId`] == 0 &&
          row[`total_${year}`] != undefined &&
          row[`total_${year}`] != Infinity
        ) {
          total = total + Number(row[`total_${year}`]);
        }
      }
      totalRow[`total_${year}`] = total;
    }
    this.displayTotalBase = { ...totalRow };
  }

  getProductDef() {
    return {
      headerName:
        this.name != null
          ? this.name
          : this.granularityList[this.granularity].label,
      lockPosition: true,
      pinned: "left",
      field: "product",
      editable: this.cellEditable,
      minWidth: 150,
      visible: false,
      cellRenderer: this.productCellRenderer,
    };
  }

  cellEditable(params) {
    return false;
  }

  productCellRenderer(params) {
    let style = "";
    if (params.data.parentId != 0) style = 'style="padding-left: 10px;"';
    if (params.data.expandable && params.data.selected) {
      if (
        params.data[`productChannelId`] == null &&
        params.data[`productFormId`] != null
      ) {
        return (
          `<span class="ag-icon ag-icon-tree-open" ` +
          style +
          `></span> ` +
          `<span style="color: green;">` +
          params.value +
          `</span>` +
          ``
        );
      } else {
        return (
          `<span class="ag-icon ag-icon-tree-open" ` +
          style +
          `></span> ` +
          params.value +
          ``
        );
      }
    } else if (params.data.expandable) {
      if (
        params.data[`productChannelId`] == null &&
        params.data[`productFormId`] != null
      ) {
        return (
          `<span class="ag-icon ag-icon-contracted" ` +
          style +
          `></span> ` +
          `<span style="color: green;">` +
          params.value +
          `</span>` +
          ``
        );
      } else {
        return (
          `<span class="ag-icon ag-icon-contracted" ` +
          style +
          `></span> ` +
          params.value +
          ``
        );
      }
    } else if (
      !params.data.expandable &&
      params.data[`productChannelId`] == null &&
      params.data[`productFormId`] == null &&
      params.data.product != "TOTAL : "
    ) {
      // product has no child
      return `<span` + style + `></span> ` + params.value + ``;
    } else if (
      !params.data.expandable &&
      params.data[`productChannelId`] == null &&
      params.data.product != "TOTAL : "
    ) {
      // form has no child
      return (
        `<span ` +
        style +
        `></span> ` +
        `<span style="color: green;">` +
        params.value +
        `</span>` +
        ``
      );
    } else if (params.data.product == "TOTAL : ") {
      return (
        `<span style="padding-left: 40px;"></span>` +
        `<span style="color: #ff7500;">` +
        params.value +
        `</span>`
      );
    }
    return (
      `<span style="padding-left: 40px;"></span>` +
      `<span style="color: blue;">` +
      params.value +
      `</strong>`
    );
  }

  getYearColumnDef(year) {
    var display = this.display;
    let first = this.firstYear;
    let numberDecimal = this.numberDecimal;
    let decimalFormat = this.decimalFormat;
    let numberFormat = this.numberFormat;
    let headerPeriodList = this.headerPeriodList;
    return {
      type: "rightAligned",
      year: year,
      headerName: `${
        year < 0
          ? "A`" + year * -1
          : headerPeriodList.filter((x) => x.value == year)[0].label
      }`,
      valueGetter: function (params) {
        return Number(params.data[`total_${year}`]).toFixed(2);
      },
      cellStyle: function (params) {
        if (params.data.product == "TOTAL : ") {
          return { color: "#ff7500" };
        }
      },
      valueFormatter: function (params) {
        if (params.value == undefined || params.value == "NaN") {
          return "n/a";
        }
        if (display == 1) {
          if (year == first) {
            return "n/a";
          }
          return (
            "%" +
            numberDecimal.transform(
              Number(params.value),
              decimalFormat,
              numberFormat
            )
          );
        } else if (display == 2) {
          if (year == first) {
            return "n/a";
          }
        }
        return numberDecimal.transform(
          Number(params.value),
          decimalFormat,
          numberFormat
        );
      },
    };
  }

  getSelectedRows() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const selectedDataStringPresentation = selectedData
      .map((node) => node.make + " " + node.model)
      .join(", ");
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  }

  change() {
    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef(year));
    });
    this.gridApi.setColumnDefs(this.columnDefs);
  }

  setScenario() {
    for (let sc of Object.keys(this.scenarioList)) {
      if (this.scenarioId == this.scenarioList[sc].id) {
        this.file_name =
          "ProductOverview-" + this.scenarioList[sc].name + ".xlsx";
        return this.scenarioList[sc].name;
      }
    }
  }

  setExportData() {
    if (this.isRun != true) {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    }

    let type = this.indicator == 0 ? "v" : this.indicator == 1 ? "a" : "";

    let emptyRow: any[];
    let scenario: any[] = ["", "Scenario Name : " + " " + this.setScenario()];
    let guide: any[] = [
      "",
      "Excel User Guide : On This Excel Sheet Change Only Volumes/Amounts. Do not Remove Any Column or Row and Do not Change Their Names.",
    ];

    let header = ["SalesForecastId"];

    if (type == "v") {
      this.sheetName = "Sheet Volume";
    } else if (type == "a") {
      this.sheetName = "Sheet Amount";
    }

    let header2 = this.gridColumnApi
      .getAllDisplayedColumns()
      .map(function (col) {
        return col.userProvidedColDef.headerName;
      });

    header2.forEach((element) => {
      header.push(element);
    });

    this.data2Exel = [];
    this.data2Exel.push(header);

    this.fullData.forEach((element) => {
      this.salesPriceId = element.id;

      if (element.productFormId != null && element.productChannelId == null) {
        this.exelName = " " + " " + " " + element.product;
      } else if (element.productChannelId != null) {
        this.exelName = " " + " " + " " + " " + " " + " " + element.product;
      } else {
        this.exelName = element.product;
      }

      let row: any[] = [this.salesPriceId, this.exelName];
      for (let year of this.years) {
        if (
          (element[`total_${year}`] || element[`total_${year}`] == 0) &&
          year != this.firstYear
        ) {
          row.push(element[`total_${year}`]);
        } else {
          row.push("n/a");
        }
      }
      this.data2Exel.push(row);
    });
    this.data2Exel.push(emptyRow);
    this.data2Exel.push(scenario);
    this.data2Exel.push(guide);

    this.export();
  }

  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };

  export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2Exel, {
      cellStyles: true,
    });

    /*hide salesPriceID column*/
    ws["!cols"] = [];
    ws["!cols"][0] = { hidden: true };
    ws["!cols"][1] = { wpx: 200 };

    ws["!rows"] = [];
    ws["!rows"][0] = { hpx: 35 };

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.sheetName);

    /* save to file */
    XLSX.writeFile(wb, this.file_name);
  }

  onCellClicked(event) {
    if (event.column.colId == "product" || event.column.colId == "product_1") {
      var parentId = event.data.id;
      var rows = [...this.fullData];

      let currentRow = rows.filter((row) => row.id == parentId)[0];
      let isOpen = currentRow.selected;

      let currentRows = rows.filter((row) => row.parentId == parentId);
      currentRows.forEach(function (row) {
        row.isVisible = !isOpen;
        if (isOpen) {
          row.selected = false;
          let subRows = rows.filter((r) => r.parentId == row.id);
          subRows.forEach(function (row) {
            row.isVisible = false;
          });
        }
      });
      currentRow.selected = !isOpen;
      rows = rows.filter((row) => row.isVisible || row.parentId == 0);
      this.gridApi.setRowData(rows);
    } else return;
  }

  openRows() {
    // rowların hepsini açma
    var rows = [...this.fullData];

    this.isOpen = !this.isOpen;
    var value = this.isOpen;

    rows.forEach(function (row) {
      if (row.parentId != 0) {
        row.isVisible = value;
        row.selected = value;
      } else {
        row.selected = value;
      }
    });
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
  }

  currentContextEvent: any;
  onCellContextMenu(event) {
    this.currentContextEvent = event;
  }

  getNumericCellEditor() {
    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }
    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr);
    }
    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === "undefined" ? event.keyCode : event.which;
    }
    function NumericCellEditor() {}
    NumericCellEditor.prototype.init = function (params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement("input");
      this.eInput.style.width = "100%";
      this.eInput.style.height = "100%";
      this.eInput.value = isCharNumeric(params.charPress)
        ? params.charPress
        : params.value;
      var that = this;
      this.eInput.addEventListener("keypress", function (event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };
    NumericCellEditor.prototype.getGui = function () {
      return this.eInput;
    };
    NumericCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        //this.eInput.select();
      }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function () {
      return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.isCancelAfterEnd = function () {};
    NumericCellEditor.prototype.getValue = function () {
      return this.eInput.value;
    };
    NumericCellEditor.prototype.focusIn = function () {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
    };
    NumericCellEditor.prototype.focusOut = function () {};
    return NumericCellEditor;
  }
}
