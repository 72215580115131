import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Department } from "@planard/@core/entities/department";
import { DepartmentService } from "@planard/@core/backend/service/department.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";
@Component({
  templateUrl: "./add.component.html",
})
export class DepartmentAddComponent {
  department: Department;
  departmentList: Department[];

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, removeSpaces]),
  });
  options: FormGroup;

  constructor(
    public departmentService: DepartmentService,
    public dialogRef: MatDialogRef<DepartmentAddComponent>,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public fb: FormBuilder
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  ngOnInit() {
    this.departmentService.listAll().subscribe((result: any) => {
      this.departmentList = result;
    });
  }

  save() {
    if (this.form.valid) {
      this.department = this.form.value;
      this.departmentService.add(this.department).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.dialogRef.close(this.department);
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
