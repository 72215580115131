import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";

@Injectable()
export class ProductFamilyApi {
  private readonly apiController: string = "productfamily";

  constructor(private api: HttpService) {}

  listAll<T>(page?: number, pageSize?: number): Observable<any> {
    const endpointUrl =
      page && pageSize
        ? `${this.apiController}/listAll/${page}/${pageSize}`
        : `${this.apiController}/listAll`;
    return this.api.get(endpointUrl);
  }

  listUserProductFamilies<T>(
    page?: number,
    pageSize?: number
  ): Observable<any> {
    const endpointUrl =
      page && pageSize
        ? `${this.apiController}/listUserProductFamilies/${page}/${pageSize}`
        : `${this.apiController}/listUserProductFamilies`;
    return this.api.get(endpointUrl);
  }

  add<T>(businessUnit: any): Observable<any> {
    return this.api.post(`${this.apiController}/add`, businessUnit);
  }

  update<T>(businessUnit: any): Observable<any> {
    return this.api.post(`${this.apiController}/update`, businessUnit);
  }

  delete<T>(businessUnitId: any): Observable<any> {
    var json = { id: businessUnitId };
    const endPointUrl = `${this.apiController}/delete`;
    return this.api.post(endPointUrl, json);
  }
}
