import { Routes } from "@angular/router";
import { AuthCallbackComponent } from "./callback/auth-callback.component";
import { AuthLogoutComponent } from "./logout/auth-logout.component";
import { ErrorComponent } from "./error/error.component";
export const AuthenticationRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "callback",
        component: AuthCallbackComponent,
      },
      {
        path: "logout",
        component: AuthLogoutComponent,
      },
      {
        path: "404",
        component: ErrorComponent,
      },
    ],
  },
];
