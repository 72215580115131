<div fxLayout="row wrap">
  <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.gt-xs="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 85%">
            <mat-card-title>{{ "companies.title" | translate }}</mat-card-title>
          </div>
          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>
        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header="name">{{
                "companies.name" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'name')">
                  <ng-template viewMode>
                    {{ element.name }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [value]="element.name"
                        [formControl]="getControl(element.id, 'name')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <!-- Code Column -->
            <ng-container matColumnDef="code">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="code"
                [ngClass]="'toRight15'"
                >{{ "companies.code" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element" [ngClass]="'toRight15'">
                <editable (update)="updateField(element.id, 'code')">
                  <ng-template viewMode>
                    {{ element.code }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [value]="element.code"
                        [formControl]="getControl(element.id, 'code')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <!-- Sector Column -->
            <ng-container matColumnDef="sector">
              <mat-header-cell *matHeaderCellDef mat-sort-header="sector">{{
                "companies.sector" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'sector')">
                  <ng-template viewMode>
                    <span
                      *ngIf="'enums.sector.' + element.sector as variable"
                      >{{ variable | translate }}</span
                    >
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <mat-select
                        [formControl]="getControl(element.id, 'sector')"
                      >
                        <mat-option *ngFor="let o of sectorList" [value]="o">
                          <span *ngIf="'enums.sector.' + o as variable">{{
                            variable | translate
                          }}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="contactName">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="contactName"
                >{{ "companies.adminName" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'contactName')">
                  <ng-template viewMode>
                    {{ element.contactName ? element.contactName : "-" }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [value]="element.contactName"
                        [formControl]="getControl(element.id, 'contactName')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <!-- Admin Mail Column -->
            <ng-container matColumnDef="contactEmail">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="contactEmail"
                >{{ "companies.adminEmail" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'contactEmail')">
                  <ng-template viewMode>
                    {{ element.contactEmail ? element.contactEmail : "-" }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        matInput
                        [value]="element.contactEmail"
                        [formControl]="getControl(element.id, 'contactEmail')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="package">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="package"
                [ngClass]="'toRight'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                  "companies.package" | translate
                }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element" [ngClass]="'toRight'">
                <span style="width: 70%; float: left">
                  <button
                    *ngIf="element.package == 'premium'"
                    (click)="changePackage(element)"
                    mat-button
                    style="color: rgb(255, 160, 52)"
                    type="button"
                  >
                    Premium
                  </button>
                  <button
                    *ngIf="element.package == 'basic'"
                    (click)="changePackage(element)"
                    mat-button
                    style="color: rgb(84, 137, 236)"
                    type="button"
                  >
                    Basic
                  </button>
                </span>
              </mat-cell>
            </ng-container>

            <!-- User Count Column -->
            <ng-container matColumnDef="userCount">
              <mat-header-cell *matHeaderCellDef mat-sort-header="userCount">{{
                "companies.userCount" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="element.package == 'basic'">{{
                  element.userCount
                }}</span>

                <editable
                  (update)="updateField(element.id, 'userCount')"
                  *ngIf="element.package == 'premium'"
                >
                  <ng-template viewMode>
                    {{ element.userCount }}
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        type="number"
                        matInput
                        [value]="element.userCount"
                        [formControl]="getControl(element.id, 'userCount')"
                        focusable
                        editableOnEnter
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <!-- Sector Column -->
            <ng-container matColumnDef="language">
              <mat-header-cell *matHeaderCellDef mat-sort-header="language">{{
                "companySetup.language" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <editable (update)="updateField(element.id, 'language')">
                  <ng-template viewMode>
                    <span
                      *ngIf="'companySetup.' + element.language as variable"
                      >{{ variable | translate }}</span
                    >
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <mat-select
                        [formControl]="getControl(element.id, 'language')"
                      >
                        <mat-option [value]="'tr'">{{
                          "companySetup.tr" | translate
                        }}</mat-option>
                        <mat-option [value]="'en'">{{
                          "companySetup.en" | translate
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-template>
                </editable>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="isActive">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="isActive"
                [ngClass]="'toRight'"
                >{{ "companies.status" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let element" [ngClass]="'toRight'">
                {{
                  element.isActive
                    ? ("companies.active" | translate)
                    : ("companies.notActive" | translate)
                }}
              </mat-cell>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef [ngClass]="'toRightReverse'">{{
                "companies.actions" | translate
              }}</mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                [style.color]="row.color"
                [ngClass]="'toRightReverse'"
              >
                <button
                  *ngIf="row.isActive == false"
                  title="{{ 'companies.activeM' | translate }}"
                  style="color: yellowgreen"
                  (click)="onDelete(row)"
                  autofocus
                  mat-icon-button
                >
                  <mat-icon>verified_user</mat-icon>
                </button>

                <button
                  *ngIf="row.isActive == true"
                  title="{{ 'companies.notActiveM' | translate }}"
                  style="color: crimson"
                  (click)="onDelete(row)"
                  autofocus
                  mat-icon-button
                >
                  <mat-icon>highlight_off</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <!-- Sector Column -->
          <ng-container matColumnDef="sector">
            <mat-header-cell *matHeaderCellDef mat-sort-header="sector">{{
              "companies.sector" | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <editable (update)="updateField(element.id, 'sector')">
                <ng-template viewMode>
                  <span *ngIf="'enums.sector.' + element.sector as variable">{{
                    variable | translate
                  }}</span>
                </ng-template>
                <ng-template editMode>
                  <mat-form-field>
                    <mat-select
                      [formControl]="getControl(element.id, 'sector')"
                    >
                      <mat-option *ngFor="let o of sectorList" [value]="o">
                        <span *ngIf="'enums.sector.' + o as variable">{{
                          variable | translate
                        }}</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-template>
              </editable>
            </mat-cell>
          </ng-container>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>
        <mat-card-actions>
          <button (click)="addDialog()" color="accent" mat-raised-button>
            {{ "companies.add" | translate }}
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
