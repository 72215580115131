import {
  HttpErrorResponse,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "environments/environment";
import "rxjs/add/operator/timeout";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class HttpErrorInterceptor implements HttpInterceptor {
  redirect_uri = environment.callback;

  constructor(
    public toastrService: ToastrService,
    public translate: TranslateService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url == "/assets/i18n/en.json") return;
    if (req.url == "/assets/i18n/tr.json") return;

    if (
      req.url.split("/api/")[1] != "guidance/startGuidance" &&
      req.url.split("/api/")[1] != "guidance/endGuidance" &&
      req.url.split("/api/")[1] != "supersettings/add" &&
      req.url.split("/api/")[1] != "costplan/update"
    ) {
      // assetslerin yazılması uzun sürebiliyor kesilince data bozulmasın diye !!!
      return next
        .handle(req)
        .timeout(600000)
        .pipe(
          //retry(2),
          catchError((errResp: HttpErrorResponse) => {
            if (errResp.status == 401) {
              window.location.href = this.redirect_uri; //this.authService.completeLogout();
            }
            return throwError(errResp);
          })
        );
    } else {
      return next.handle(req).pipe(
        //retry(2),
        catchError((errResp: HttpErrorResponse) => {
          if (errResp.status == 401) {
            window.location.href = this.redirect_uri; //this.authService.completeLogout();
          }
          return throwError(errResp);
        })
      );
    }
  }
}
