import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders } from "@angular/compiler/src/core";
import { ProductsService } from "./service/products.service";
import { ProductsApi } from "./api/products.api";
import { HttpService } from "./api/http.service";
import { ProductsData } from "../entities/interface/products";
import { ProductFamilyService } from "./service/product-family.service";
import { ProductFamilyApi } from "./api/product-family.api";
import { ScenarioApi } from "./api/scenario.api";
import { ScenarioService } from "./service/scenario.service";
import { CostService } from "./service/cost.service";
import { CostsData } from "../entities/interface/costs";
import { CostsApi } from "./api/costs.api";
import { CompanyApi } from "./api/company.api";
import { CompanySetupService } from "./service/company-setup.service";
import { CompanyData } from "../entities/interface/company";
import { AccountsApi } from "./api/accounts.api";
import { AccountsData } from "../entities/interface/accounts";
import { AccountsService } from "./service/accounts.service";
import { ProfileData } from "../entities/interface/profile";
import { ProfileService } from "./service/profile.service";
import { ProfileApi } from "./api/profile.api";
import { GuidanceApi } from "@planard/@core/backend/api/guidance.api";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidanceData } from "@planard/@core/entities/interface/guidance";
import { TimelineApi } from "@planard/@core/backend/api/timeline.api";
import { TimelineService } from "@planard/@core/backend/service/timeline.service";
import { ProcessData } from "@planard/@core/entities/interface/process";
import { ChannelApi } from "@planard/@core/backend/api/channel.api";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { ChannelData } from "@planard/@core/entities/interface/channel";
import { RoleService } from "@planard/@core/backend/service/role.service";
import { RoleApi } from "@planard/@core/backend/api/role.api";
import { RoleData } from "@planard/@core/entities/interface/role";
import { NotificationData } from "@planard/@core/entities/interface/notifications";
import { NotificationService } from "@planard/@core/backend/service/notification.service";
import { NotificationApi } from "@planard/@core/backend/api/notification.api";
import { ActualsApi } from "@planard/@core/backend/api/actuals.api";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { ActualsData } from "@planard/@core/entities/interface/actuals";
import { SalesData } from "@planard/@core/entities/interface/sales";
import { SalesService } from "@planard/@core/backend/service/sales.service";

const API = [
  ProductsApi,
  ProductFamilyApi,
  ScenarioApi,
  HttpService,
  CostsApi,
  CompanyApi,
  AccountsApi,
  ProfileApi,
  GuidanceApi,
  TimelineApi,
  ChannelApi,
  RoleApi,
  NotificationApi,
  ActualsApi,
  SalesData,
];

const SERVICES = [
  { provide: ProductsData, useClass: ProductsService },
  { provide: CostsData, useClass: CostService },
  { provide: CompanyData, useClass: CompanySetupService },
  { provide: AccountsData, useClass: AccountsService },
  { provide: ProfileData, useClass: ProfileService },
  { provide: GuidanceData, useClass: GuidanceService },
  { provide: ProcessData, useClass: TimelineService },
  { provide: ChannelData, useClass: ChannelService },
  { provide: RoleData, useClass: RoleService },
  { provide: NotificationData, useClass: NotificationService },
  { provide: ActualsData, useClass: ActualsService },
  { provide: SalesData, useClass: SalesService },
];

@NgModule({
  imports: [CommonModule],
})
export class CommonBackendModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CommonBackendModule,
      providers: [...API, ...SERVICES, ProductFamilyService, ScenarioService],
    };
  }
}
