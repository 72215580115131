import { Observable } from "rxjs";
import { ApproverRequest } from "../approverRequest";

export abstract class ApproverRequestData {
  abstract submit(approverRequest: ApproverRequest): Observable<any>;

  abstract requestApprove(approverRequest: any): Observable<any>;

  abstract requestReject(approverRequest: any): Observable<any>;

  abstract listAllSales(): Observable<any>;

  abstract listAllCosts(): Observable<any>;
}
