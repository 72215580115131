import { BaseEntity } from "../base/BaseEntity";
export class Users extends BaseEntity {
  name?: string;
  email?: string;
  departmentId?: number;
  departmentStr?: string;
  subDepartmentId?: number;
  subDepartmentStr?: string;
  selectedRoles?: number[];
  rolesStr?: string;
  approverUserId?: string;
  approversStr?: string;
  selectedProducts?: number[];
  productsStr?: string;
  centralCostsStr?: string;
  selectedCentralCosts?: number[];
  isManager?: boolean;
  isContact?: boolean;
}
