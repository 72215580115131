import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Products } from "@planard/@core/entities/product";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { NarrativeRequest } from "@planard/@core/entities/narrativeRequest";
import { NarrativeService } from "@planard/@core/backend/service/narrative.service";
import { AddNarrativesComponent } from "./add/add-narratives.component";
import { CostNarrative } from "@planard/@core/entities/costNarrative";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import { Category } from "@planard/@core/entities/category";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { NumberDecimalReversePipe } from "@planard/@core/pipes/NumberDecimalReversePipe";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./narratives-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 5%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
  providers: [NumberDecimalPipe, NumberDecimalReversePipe],
})
export class NarrativesNewComponent implements OnInit {
  displayedColumns: string[] = [
    "amount",
    "narrativeCategory",
    "description",
    "actions",
  ];
  dataSource: MatTableDataSource<CostNarrative>;
  controls: any[];

  productsList: Products[];
  costRequestList: NarrativeRequest[];
  requestId: number;
  selected: boolean = false;
  remaining: number;
  categoryId: any = null;
  dataRemaining: number = 0;
  costCategoryList: Category[];
  genelRem: number = 0;
  isSubmit: boolean;
  isScreenValid: boolean;
  isRoleValid: boolean;
  numberFormat: string;
  decimalFormat: string;
  companyPackage: string;
  categoryList = [
    "Cost Increase Due to Inflation",
    "Increase/Decrease in Headcount",
    "Offboarding",
    "Facility move/renovation",
    "Marketing Campaings",
    "New Market Research",
    "Business Scope Extend",
    "Major Events",
    "Change in Fleet",
    "New Assets",
    "New Products",
    "New R&D Activities",
    "New Audit",
    "New Consultancy",
    "Currency Impact",
    "Price Adjustments",
    "New Supplier",
    "Fines & Suits",
    "Other Impacts on Cost",
    "Organizational Re-structuring",
  ];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public narrativeService: NarrativeService,
    public costService: CostService,
    private numberDecimal: NumberDecimalPipe,
    public productsService: ProductsService,
    private numberReverseDecimal: NumberDecimalReversePipe,
    public authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    public toastrService: ToastrService
  ) {
    this.isRoleValid =
      this.authService.isCentralCostPlanner() ||
      this.authService.isSupportFunctionPlanner() ||
      this.authService.isSupportFunctionApprover();
    this.companyPackage = this.authService.getCompanyPackage();
    if (this.isRoleValid == false || this.companyPackage == "basic")
      this.router.navigate(["auth/404"]);
    this.isScreenValid = this.authService.costNarratives();
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();
    if (!this.isScreenValid) {
      this.translate
        .get("entryScreens.costNarrativeTask")
        .subscribe((data: any) => {
          this.toastrService.error(data);
        });
    }
  }

  ngOnInit() {
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();

    this.productsService.listAll().subscribe((data) => {
      this.productsList = data;
    });
    this.narrativeService.listCostRequests().subscribe((result) => {
      // içinde bulunduğumuz guidance ın requestleri
      this.costRequestList = result;
    });
    this.costService.listCategoriesCurrent().subscribe((result) => {
      // bunlarda aslında guidancedaki cost'ların categorileri olmalı
      this.costCategoryList = result;
    });
  }

  formatNumber(value, decimal, number) {
    return this.numberDecimal.transform(value, decimal, number);
  }

  formatNumberReverse(string, decimal, number) {
    return this.numberReverseDecimal.transform(string, decimal, number);
  }

  getTableData() {
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();

    if (this.requestId == null || this.categoryId == null) {
      this.translate.get("costNarrative.must").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    }

    this.selected = true;
    this.narrativeService
      .listAllCost(this.requestId, this.categoryId)
      .subscribe((result) => {
        this.remaining = result.remaining;
        this.isSubmit = result.isSubmit;

        this.dataRemaining = 0;
        result.data.forEach((element) => {
          this.dataRemaining += element.amount;
        });

        let data = result.data;
        this.dataSource = new MatTableDataSource(data);

        if (this.dataSource) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
            typeof data[sortHeaderId] === "string"
              ? data[sortHeaderId].toLocaleLowerCase()
              : data[sortHeaderId];
          this.dataSource.sort = this.sort;

          /*const sortState: Sort = {active: 'id', direction: 'asc'};
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);*/
        }

        this.controls = data.map((entity) => {
          return {
            data: entity,
            formGroup: new FormGroup(
              {
                amount: new FormControl(
                  entity.amount /*this.formatNumber(entity.amount, this.decimalFormat, this.numberFormat)*/,
                  [Validators.required]
                ),
                categoryId: new FormControl(entity.categoryId, [
                  Validators.required,
                ]),
                narrativeCategory: new FormControl(entity.narrativeCategory, [
                  Validators.required,
                ]),
                description: new FormControl(entity.description, [
                  Validators.required,
                ]),
              },
              { updateOn: "change" }
            ),
          };
        });
      });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var cost = this.getElement(index);
      cost[field] = control.value;

      /*if (field == "amount") cost.amount = this.formatNumberReverse(cost.amount, this.decimalFormat, this.numberFormat);*/

      this.narrativeService.updateCost(cost).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.getTableData();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.getTableData();
        }
      });
    } else {
      this.translate.get("common.costNarrativeTask").subscribe((data: any) => {
        this.toastrService.success(data);
      });
      this.ngOnInit();
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    if (this.requestId) {
      const dialogRef = this.dialog.open(AddNarrativesComponent, {
        width: "500px",
        data: { requestId: this.requestId, categoryId: this.categoryId },
      });
      dialogRef.afterClosed().subscribe((result) => {
        //if (result) {
        //this.dataSource.data.push(result);
        this.getTableData();
        //}
      });
    } else {
      this.translate.get("costNarrative.select4add").subscribe((data: any) => {
        this.toastrService.success(data);
      });
    }
  }

  submit() {
    this.narrativeService
      .listAllCost(this.requestId, this.categoryId)
      .subscribe((result) => {
        let genelRem = 0;
        result.data.forEach((element) => {
          genelRem += element.amount;
        });

        //let diff = this.remaining - genelRem;
        // Küsürat farklarından dolayı sıfır elde edemiyorduk, son 2 haneyi kontrol edecek şekilde ayarlandı -tuba

        if (this.remaining.toFixed(2) == genelRem.toFixed(2)) {
          let body = {
            id: this.requestId,
            remaining: /*diff*/ 0,
            productId: null,
            categoryId: this.categoryId,
          };
          this.narrativeService.submit(body).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.getTableData();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.getTableData();
            }
          });
        } else {
          this.translate.get("costNarrative.explain").subscribe((data: any) => {
            this.toastrService.warning(data);
          });
        }
      });
  }

  update(rem: number) {
    this.remaining = rem;
  }

  onDelete(row: any) {
    this.translate.get("delete.narrative").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.narrativeService.deleteCost(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.getTableData();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.getTableData();
            }
          });
        }
      });
    });
  }

  outToastr(msg: string) {
    this.translate.get(`${msg}`).subscribe((data: any) => {
      this.toastrService.error(data);
    });
  }
}
