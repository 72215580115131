<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 85%">
            <mat-card-title>{{ "actuals.title" | translate }}</mat-card-title>
          </div>
          <div class="div" style="width: 15%">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" />
              <mat-placeholder>
                <mat-icon>search</mat-icon>
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>

        <div class="responsive-table">
          <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="fileName">
              <mat-header-cell *matHeaderCellDef mat-sort-header="filename">{{
                "actuals.fileName" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span>
                  {{ row.filename }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef mat-sort-header="type">{{
                "actuals.type" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span *ngIf="'actuals.' + row.type as variable">{{
                  variable | translate
                }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="period">
              <mat-header-cell *matHeaderCellDef mat-sort-header="period">{{
                "actuals.period" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span>
                  {{ row.period }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdDate">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="createdDate"
                >{{ "actuals.upload" | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let row">
                <span>
                  {{ row.createdDate | date : "dd/MM/yyyy" }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef style="flex: 0 0 5%">
                {{ "actuals.actions" | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" style="flex: 0 0 5%">
                <button
                  autofocus
                  color="warn"
                  (click)="onDelete(row)"
                  mat-icon-button
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="50"
            [pageSizeOptions]="[5, 10, 25, 50, 100]"
          ></mat-paginator>
        </div>

        <mat-card-actions>
          <button
            color="accent"
            mat-raised-button
            type="button"
            (click)="dowlandTemplate()"
          >
            <mat-icon>cloud_upload</mat-icon>&nbsp;{{
              "actuals.dowland" | translate
            }}&nbsp;&nbsp;</button
          >&nbsp;
          <button
            color="accent"
            mat-raised-button
            type="button"
            (click)="onUpload()"
          >
            <mat-icon>cloud_download</mat-icon>&nbsp;{{
              "actuals.upload" | translate
            }}&nbsp;&nbsp;</button
          >&nbsp;
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
