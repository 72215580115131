import { Injectable } from "@angular/core";
import { AuthService } from "@planard/auth/services/auth.service";
import { EnumRole } from "@planard/@core/enums/EnumRole";

export interface BadgeItem {
  type: string;
  value: string;
}

export interface Separator {
  name: string;
  type?: string;
  icon: string;
}

export interface Divider {
  name: string;
  type?: string;
  icon: string;
}

export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
  roles?: EnumRole[];
  rolesBasic?: EnumRole[];
  packages?: string[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  separator?: Separator[];
  children?: ChildrenItems[];
  rolesBasic?: EnumRole[];
  roles?: EnumRole[];
  packages?: string[];
}

const MENUITEMS = [
  {
    state: "dashboard",
    name: "Dashboard",
    type: "link",
    icon: "dashboard",
    children: [],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_BU,
      EnumRole.APPROVER_SF,
    ],
    packages: ["premium"],
  },
  {
    state: "",
    icon: "perm_contact_calendar",
    name: "Planning",
    type: "separator",
    children: [],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.APPROVER_BU,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_SF,
    ],
    packages: ["basic", "premium"],
  },
  {
    state: "planning-instructions",
    name: "Instructions",
    type: "sub",
    icon: "info",
    children: [
      {
        state: "guidance",
        name: "Guidance",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.CENTRAL_COST_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_BU,
          EnumRole.APPROVER_SF,
        ],
        packages: ["basic", "premium"],
      },
      {
        state: "scenarios",
        name: "Scenarios",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.CENTRAL_COST_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_BU,
          EnumRole.APPROVER_SF,
        ],
        packages: ["basic", "premium"],
      },
      {
        state: "timeline",
        name: "Timeline",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.CENTRAL_COST_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_BU,
          EnumRole.APPROVER_SF,
        ],
        packages: ["premium"],
      },
    ],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_BU,
      EnumRole.APPROVER_SF,
    ],
    packages: ["basic", "premium"],
  },
  {
    state: "planning-sales",
    name: "Sales",
    type: "sub",
    icon: "bubble_chart",
    children: [
      {
        state: "prices",
        name: "Prices",
        type: "link",
        roles: [EnumRole.PRICE_PLANNER],
        packages: ["basic", "premium"],
      },
      {
        state: "forecast",
        name: "Forecast",
        type: "link",
        roles: [EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        packages: ["basic", "premium"],
      },
      {
        state: "risk-opps",
        name: "Risk & Opps",
        type: "link",
        roles: [
          EnumRole.PRICE_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.APPROVER_BU,
        ],
        packages: ["premium"],
      },
      {
        state: "narratives",
        name: "Narratives",
        type: "link",
        roles: [EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        packages: ["premium"],
      },
      {
        state: "sales-approvals",
        name: "Approvals",
        type: "link",
        roles: [EnumRole.APPROVER_BU],
        packages: ["premium"],
      },
    ],
    roles: [EnumRole.PRICE_PLANNER, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
    packages: ["basic", "premium"],
  },
  {
    state: "planning-costs",
    name: "Costs",
    type: "sub",
    icon: "group_work",
    children: [
      {
        state: "plan",
        name: "Plan",
        type: "link",
        roles: [EnumRole.PLANNER_SF, EnumRole.APPROVER_SF],
        packages: ["basic", "premium"],
      },
      {
        state: "centralPlan",
        name: "Plan (Central Cost)",
        type: "link",
        roles: [EnumRole.CENTRAL_COST_PLANNER],
        packages: ["premium"],
      },
      {
        state: "risk-opps",
        name: "Risk & Opps",
        type: "link",
        roles: [
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_SF,
          EnumRole.CENTRAL_COST_PLANNER,
        ],
        packages: ["premium"],
      },
      {
        state: "narratives",
        name: "Narratives",
        type: "link",
        roles: [
          EnumRole.CENTRAL_COST_PLANNER,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_SF,
        ],
        packages: ["premium"],
      },
      {
        state: "cost-approvals",
        name: "Approvals",
        type: "link",
        roles: [EnumRole.APPROVER_SF],
        packages: ["premium"],
      },
    ],
    roles: [
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_SF,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.APPROVER_BU,
    ],
    packages: ["basic", "premium"],
  },
  {
    state: "",
    name: "Reports",
    type: "separator",
    icon: "list_alt",
    children: [],
    roles: [
      EnumRole.ADMIN,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_SF,
      EnumRole.PLANNER_BU,
      EnumRole.APPROVER_BU,
      EnumRole.PRICE_PLANNER,
    ],
    packages: ["basic", "premium"],
  },
  {
    state: "sales-reports",
    name: "Sales Reports",
    type: "sub",
    icon: "point_of_sale",
    children: [
      {
        state: "sales-overview",
        name: "Sales Overview",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU],
        packages: ["basic", "premium"],
      },
      {
        state: "product-overview",
        name: "Product Overview",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU],
        packages: ["basic", "premium"],
      },
      {
        state: "channel-overview",
        name: "Channel Overview",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU],
        packages: ["basic", "premium"],
      },
      {
        state: "sales-drivers",
        name: "Growth Drivers",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        packages: ["premium"],
      },
      {
        state: "sales-trend",
        name: "Sales Trend",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU],
        packages: ["basic", "premium"],
      },
      {
        state: "sales-bridge",
        name: "Sales Bridge",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        packages: ["premium"],
      },
      {
        state: "price-volume",
        name: "Price & Volume",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        packages: ["premium"],
      },
      {
        state: "launch-dates",
        name: "Launch Timeline",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.APPROVER_BU,
        ],
        packages: ["premium"],
      },
      {
        state: "price-trend",
        name: "Price Trend",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.APPROVER_BU,
        ],
        rolesBasic: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.PLANNER_BU,
        ],
        packages: ["basic", "premium"],
      },
      {
        state: "actual-plan",
        name: "Actual vs Plan",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU],
        packages: ["basic", "premium"],
      },
    ],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.APPROVER_BU,
    ],
    rolesBasic: [EnumRole.ADMIN, EnumRole.PRICE_PLANNER, EnumRole.PLANNER_BU],
    packages: ["basic", "premium"],
  },
  {
    state: "cost-reports",
    name: "Cost Reports",
    type: "sub",
    icon: "casino",
    children: [
      {
        state: "cost-overview",
        name: "Cost Overview",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_SF, EnumRole.APPROVER_SF],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_SF],
        packages: ["basic", "premium"],
      },
      {
        state: "cost-bridge",
        name: "Cost Bridge",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_SF, EnumRole.APPROVER_SF],
        packages: ["premium"],
      },
      {
        state: "cost-trend",
        name: "Cost Trend",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_SF, EnumRole.APPROVER_SF],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_SF],
        packages: ["basic", "premium"],
      },
    ],
    roles: [EnumRole.ADMIN, EnumRole.PLANNER_SF, EnumRole.APPROVER_SF],
    rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_SF],
    packages: ["basic", "premium"],
  },
  {
    state: "managerial-reports",
    name: "Managerial Reports",
    type: "sub",
    icon: "timeline",
    children: [
      {
        state: "scenario-comp",
        name: "Scenario Comp.",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PLANNER_BU,
          EnumRole.APPROVER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_SF,
        ],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.PLANNER_SF],
        packages: ["basic", "premium"],
      },
      {
        state: "income-statement-report",
        name: "Income Statement",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["premium"],
      },
      {
        state: "risk-opps-report",
        name: "Risk & Opps.",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PLANNER_BU,
          EnumRole.APPROVER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_SF,
          EnumRole.PRICE_PLANNER,
        ],
        packages: ["premium"],
      },
    ],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PLANNER_BU,
      EnumRole.APPROVER_BU,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_SF,
    ],
    rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.PLANNER_SF],
    packages: ["basic", "premium"],
  },
  {
    state: "economic-reports",
    name: "Economic Reports",
    type: "link",
    icon: "bookmark_border",
    children: [],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_BU,
      EnumRole.APPROVER_SF,
    ],
    rolesBasic: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.PLANNER_SF,
    ],
    packages: ["basic", "premium"],
  },
  {
    state: "presenter",
    name: "Presenter",
    type: "sub",
    icon: "style",
    children: [
      {
        state: "create-new",
        name: "Create New",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.CENTRAL_COST_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_BU,
          EnumRole.APPROVER_SF,
        ],
        packages: ["premium"],
      },
      {
        state: "my-presentations",
        name: "My Presentations",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.CENTRAL_COST_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_BU,
          EnumRole.APPROVER_SF,
        ],
        packages: ["premium"],
      },
    ],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_BU,
      EnumRole.APPROVER_SF,
    ],
    packages: ["premium"],
  },
  // {
  //   state: 'training',
  //   name: 'Training',
  //   type: 'link',
  //   icon: 'subscriptions',
  //   children: [],
  //   roles: [EnumRole.ADMIN, EnumRole.PRICE_PLANNER, EnumRole.CENTRAL_COST_PLANNER, EnumRole.PLANNER_BU, EnumRole.PLANNER_SF, EnumRole.APPROVER_BU, EnumRole.APPROVER_SF],
  //   packages: ['basic', 'premium']
  // },
  {
    state: "",
    name: "Admin",
    type: "separator",
    icon: "av_timer",
    children: [],
    roles: [EnumRole.ADMIN, EnumRole.SUPER_ADMIN],
    packages: ["basic", "premium"],
  },
  {
    state: "admin-general",
    name: "General",
    type: "sub",
    icon: "account_balance",
    children: [
      {
        state: "setup",
        name: "Company Setup",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "department",
        name: "Department",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "subdepartment",
        name: "Sub Department",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "business-unit",
        name: "Business Unit",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "product-family",
        name: "Product Family",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "channel",
        name: "Channel",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "products",
        name: "Product",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "roles",
        name: "Roles",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["premium"],
      },
      {
        state: "costtypes",
        name: "Cost Types",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "users",
        name: "Users",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
    ],
    roles: [EnumRole.ADMIN],
    packages: ["basic", "premium"],
  },
  {
    state: "admin-planning",
    name: "Planning",
    type: "sub",
    icon: "insert_drive_file",
    children: [
      {
        state: "guidance",
        name: "Guidance",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "scenarios",
        name: "Scenarios",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "timeline",
        name: "Timeline",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "actuals",
        name: "Actuals",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "tasks",
        name: "Tasks",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["premium"],
      },
    ],
    roles: [EnumRole.ADMIN],
    packages: ["basic", "premium"],
  },
  {
    state: "admin-reports",
    name: "Reports",
    type: "sub",
    icon: "insert_chart_outlined",
    children: [
      {
        state: "settings",
        name: "Settings",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
    ],
    roles: [EnumRole.ADMIN],
    packages: ["basic", "premium"],
  },
  {
    state: "admin-super",
    name: "Super",
    type: "sub",
    icon: "star",
    children: [
      {
        state: "companies",
        name: "Companies",
        type: "link",
        roles: [EnumRole.SUPER_ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "settings",
        name: "Economic Report",
        type: "link",
        roles: [EnumRole.SUPER_ADMIN],
        packages: ["basic", "premium"],
      },
    ],
    roles: [EnumRole.SUPER_ADMIN],
    packages: ["basic", "premium"],
  },
];

const MENUITEMSTR = [
  {
    state: "dashboard",
    name: "Kullanıcı Paneli",
    type: "link",
    icon: "dashboard",
    children: [],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_BU,
      EnumRole.APPROVER_SF,
    ],
    packages: ["premium"],
  },
  {
    state: "",
    icon: "perm_contact_calendar",
    name: "Planlama",
    type: "separator",
    children: [],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.APPROVER_BU,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_SF,
    ],
    packages: ["basic", "premium"],
  },
  {
    state: "planning-instructions",
    name: "Talimatlar",
    type: "sub",
    icon: "info",
    children: [
      {
        state: "guidance",
        name: "Kılavuz",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.CENTRAL_COST_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_BU,
          EnumRole.APPROVER_SF,
        ],
        packages: ["basic", "premium"],
      },
      {
        state: "scenarios",
        name: "Senaryolar",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.CENTRAL_COST_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_BU,
          EnumRole.APPROVER_SF,
        ],
        packages: ["basic", "premium"],
      },
      {
        state: "timeline",
        name: "Zaman Çizelgesi",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.CENTRAL_COST_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_BU,
          EnumRole.APPROVER_SF,
        ],
        packages: ["premium"],
      },
    ],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_BU,
      EnumRole.APPROVER_SF,
    ],
    packages: ["basic", "premium"],
  },
  {
    state: "planning-sales",
    name: "Satış",
    type: "sub",
    icon: "bubble_chart",
    children: [
      {
        state: "prices",
        name: "Fiyatlar",
        type: "link",
        roles: [EnumRole.PRICE_PLANNER],
        packages: ["basic", "premium"],
      },
      {
        state: "forecast",
        name: "Satış",
        type: "link",
        roles: [EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        packages: ["basic", "premium"],
      },
      {
        state: "risk-opps",
        name: "Risk & Fırsatlar",
        type: "link",
        roles: [
          EnumRole.PRICE_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.APPROVER_BU,
        ],
        packages: ["premium"],
      },
      {
        state: "narratives",
        name: "Açıklamalar",
        type: "link",
        roles: [EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        packages: ["premium"],
      },
      {
        state: "sales-approvals",
        name: "Onaylamalar",
        type: "link",
        roles: [EnumRole.APPROVER_BU],
        packages: ["premium"],
      },
    ],
    roles: [
      EnumRole.PRICE_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.APPROVER_BU,
    ],
    packages: ["basic", "premium"],
  },
  {
    state: "planning-costs",
    name: "Masraflar",
    type: "sub",
    icon: "group_work",
    children: [
      {
        state: "plan",
        name: "Plan",
        type: "link",
        roles: [EnumRole.PLANNER_SF, EnumRole.APPROVER_SF],
        packages: ["basic", "premium"],
      },
      {
        state: "centralPlan",
        name: "Plan (Merkezi Masraf)",
        type: "link",
        roles: [EnumRole.CENTRAL_COST_PLANNER],
        packages: ["premium"],
      },
      {
        state: "risk-opps",
        name: "Risk & Fırsatlar",
        type: "link",
        roles: [
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_SF,
          EnumRole.CENTRAL_COST_PLANNER,
        ],
        packages: ["premium"],
      },
      {
        state: "narratives",
        name: "Açıklamalar",
        type: "link",
        roles: [
          EnumRole.CENTRAL_COST_PLANNER,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_SF,
        ],
        packages: ["premium"],
      },
      {
        state: "cost-approvals",
        name: "Onaylamalar",
        type: "link",
        roles: [EnumRole.APPROVER_SF],
        packages: ["premium"],
      },
    ],
    roles: [
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_SF,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.APPROVER_BU,
    ],
    packages: ["basic", "premium"],
  },
  {
    state: "",
    name: "Raporlar",
    type: "separator",
    icon: "list_alt",
    children: [],
    roles: [
      EnumRole.ADMIN,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_SF,
      EnumRole.PLANNER_BU,
      EnumRole.APPROVER_BU,
      EnumRole.PRICE_PLANNER,
    ],
    packages: ["basic", "premium"],
  },
  {
    state: "sales-reports",
    name: "Satış Raporları",
    type: "sub",
    icon: "point_of_sale",
    children: [
      {
        state: "sales-overview",
        name: "Satış Özeti",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU],
        packages: ["basic", "premium"],
      },
      {
        state: "product-overview",
        name: "Ürün Özeti",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU],
        packages: ["basic", "premium"],
      },
      {
        state: "channel-overview",
        name: "Kanal Özeti",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU],
        packages: ["basic", "premium"],
      },
      {
        state: "sales-drivers",
        name: "Büyüme Etkileri",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        packages: ["premium"],
      },
      {
        state: "sales-trend",
        name: "Satış Trend'i",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU],
        packages: ["basic", "premium"],
      },
      {
        state: "sales-bridge",
        name: "Satış Köprüsü",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        packages: ["premium"],
      },
      {
        state: "price-volume",
        name: "Fiyat & Hacim",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        packages: ["premium"],
      },
      {
        state: "launch-dates",
        name: "Önemli Tarihler",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.APPROVER_BU,
        ],
        packages: ["premium"],
      },
      {
        state: "price-trend",
        name: "Fiyat Trend'i",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.APPROVER_BU,
        ],
        rolesBasic: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.PLANNER_BU,
        ],
        packages: ["basic", "premium"],
      },
      {
        state: "actual-plan",
        name: "Gerçekleşen vs Plan",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU],
        packages: ["basic", "premium"],
      },
    ],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.APPROVER_BU,
    ],
    rolesBasic: [EnumRole.ADMIN, EnumRole.PRICE_PLANNER, EnumRole.PLANNER_BU],
    packages: ["basic", "premium"],
  },
  {
    state: "cost-reports",
    name: "Masraf Raporları",
    type: "sub",
    icon: "casino",
    children: [
      {
        state: "cost-overview",
        name: "Masraf Özeti",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_SF, EnumRole.APPROVER_SF],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_SF],
        packages: ["basic", "premium"],
      },
      {
        state: "cost-bridge",
        name: "Masraf Köprüsü",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_SF, EnumRole.APPROVER_SF],
        packages: ["premium"],
      },
      {
        state: "cost-trend",
        name: "Masraf Trend'i",
        type: "link",
        roles: [EnumRole.ADMIN, EnumRole.PLANNER_SF, EnumRole.APPROVER_SF],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_SF],
        packages: ["basic", "premium"],
      },
    ],
    roles: [EnumRole.ADMIN, EnumRole.PLANNER_SF, EnumRole.APPROVER_SF],
    rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_SF],
    packages: ["basic", "premium"],
  },
  {
    state: "managerial-reports",
    name: "Yönetim Raporları",
    type: "sub",
    icon: "timeline",
    children: [
      {
        state: "scenario-comp",
        name: "Senaryo Karşılaştırma",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PLANNER_BU,
          EnumRole.APPROVER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_SF,
        ],
        rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.PLANNER_SF],
        packages: ["basic", "premium"],
      },
      {
        state: "income-statement-report",
        name: "Gelir Durumu",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["premium"],
      },
      {
        state: "risk-opps-report",
        name: "Risk ve Fırsat",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PLANNER_BU,
          EnumRole.APPROVER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_SF,
          EnumRole.PRICE_PLANNER,
        ],
        packages: ["premium"],
      },
    ],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PLANNER_BU,
      EnumRole.APPROVER_BU,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_SF,
    ],
    rolesBasic: [EnumRole.ADMIN, EnumRole.PLANNER_BU, EnumRole.PLANNER_SF],
    packages: ["basic", "premium"],
  },
  {
    state: "economic-reports",
    name: "Ekonomik Raporlar",
    type: "link",
    icon: "bookmark_border",
    children: [],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_BU,
      EnumRole.APPROVER_SF,
    ],
    rolesBasic: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.PLANNER_SF,
    ],
    packages: ["basic", "premium"],
  },
  /*{
    state: 'divider',
    icon: 'perm_contact_calendar',
    name: 'divider',
    type: 'divider',
    children: [],
    roles: [EnumRole.ADMIN, EnumRole.PRICE_PLANNER, EnumRole.PLANNER_BU, EnumRole.APPROVER_BU, EnumRole.CENTRAL_COST_PLANNER, EnumRole.PLANNER_SF, EnumRole.APPROVER_SF]
  },*/
  {
    state: "presenter",
    name: "Sunum",
    type: "sub",
    icon: "style",
    children: [
      {
        state: "create-new",
        name: "Yeni Oluştur",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.CENTRAL_COST_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_BU,
          EnumRole.APPROVER_SF,
        ],
        packages: ["premium"],
      },
      {
        state: "my-presentations",
        name: "Benim Sunumlarım",
        type: "link",
        roles: [
          EnumRole.ADMIN,
          EnumRole.PRICE_PLANNER,
          EnumRole.CENTRAL_COST_PLANNER,
          EnumRole.PLANNER_BU,
          EnumRole.PLANNER_SF,
          EnumRole.APPROVER_BU,
          EnumRole.APPROVER_SF,
        ],
        packages: ["premium"],
      },
    ],
    roles: [
      EnumRole.ADMIN,
      EnumRole.PRICE_PLANNER,
      EnumRole.CENTRAL_COST_PLANNER,
      EnumRole.PLANNER_BU,
      EnumRole.PLANNER_SF,
      EnumRole.APPROVER_BU,
      EnumRole.APPROVER_SF,
    ],
    packages: ["premium"],
  },
  // {
  //   state: 'training',
  //   name: 'Eğitim',
  //   type: 'link',
  //   icon: 'subscriptions',
  //   children: [],
  //   roles: [EnumRole.ADMIN, EnumRole.PRICE_PLANNER, EnumRole.CENTRAL_COST_PLANNER, EnumRole.PLANNER_BU, EnumRole.PLANNER_SF, EnumRole.APPROVER_BU, EnumRole.APPROVER_SF],
  //   packages: ['basic', 'premium']
  // },
  {
    state: "",
    name: "Admin",
    type: "separator",
    icon: "av_timer",
    children: [],
    roles: [EnumRole.ADMIN, EnumRole.SUPER_ADMIN],
    packages: ["basic", "premium"],
  },
  {
    state: "admin-general",
    name: "Genel",
    type: "sub",
    icon: "account_balance",
    children: [
      {
        state: "setup",
        name: "Şirket Kurulumu",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "department",
        name: "Departman",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "subdepartment",
        name: "Alt Departman",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "business-unit",
        name: "İş Birimi",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "product-family",
        name: "Ürün Ailesi",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "channel",
        name: "Kanal",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "products",
        name: "Ürün",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "roles",
        name: "Roller",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["premium"],
      },
      {
        state: "costtypes",
        name: "Masraf Tipleri",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "users",
        name: "Kullanıcılar",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
    ],
    roles: [EnumRole.ADMIN],
    packages: ["basic", "premium"],
  },
  {
    state: "admin-planning",
    name: "Planlama",
    type: "sub",
    icon: "insert_drive_file",
    children: [
      {
        state: "guidance",
        name: "Kılavuz",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "scenarios",
        name: "Senaryolar",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "timeline",
        name: "Zaman Çizelgesi",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "actuals",
        name: "Gerçekleşenler",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "tasks",
        name: "Görevler",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["premium"],
      },
    ],
    roles: [EnumRole.ADMIN],
    packages: ["basic", "premium"],
  },
  {
    state: "admin-reports",
    name: "Raporlar",
    type: "sub",
    icon: "insert_chart_outlined",
    children: [
      {
        state: "settings",
        name: "Ayarlar",
        type: "link",
        roles: [EnumRole.ADMIN],
        packages: ["basic", "premium"],
      },
    ],
    roles: [EnumRole.ADMIN],
    packages: ["basic", "premium"],
  },
  {
    state: "admin-super",
    name: "Süper",
    type: "sub",
    icon: "star",
    children: [
      {
        state: "companies",
        name: "Şirketler",
        type: "link",
        roles: [EnumRole.SUPER_ADMIN],
        packages: ["basic", "premium"],
      },
      {
        state: "settings",
        name: "Ekonomik Raporlar",
        type: "link",
        roles: [EnumRole.SUPER_ADMIN],
        packages: ["basic", "premium"],
      },
    ],
    roles: [EnumRole.SUPER_ADMIN],
    packages: ["basic", "premium"],
  },
];

@Injectable()
export class MenuItems {
  constructor(private authService: AuthService) {}

  getMenuitem(): Menu[] {
    return MENUITEMS;
  }

  getMenuitemTr(): Menu[] {
    return MENUITEMSTR;
  }
}
