import { EventEmitter, Injectable } from "@angular/core";
import { CostOverviewData } from "@planard/@core/entities/interface/costOverview";
import { CostOverview } from "@planard/@core/entities/costOverview";
import { CostOverviewApi } from "../api/costOverview.api";

@Injectable()
export class CostOverviewService extends CostOverviewData {
  onPriceReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: CostOverviewApi) {
    super();
  }

  listCost(scenarioId: number) {
    return this.api.listCost<any>(scenarioId);
  }

  update(cost: CostOverview) {
    return this.api.update<any>(cost);
  }
}
