import { BaseEntity } from "@planard/@core/base/BaseEntity";
import { Products } from "@planard/@core/entities/product";

export class Scenario extends BaseEntity {
  public name?: string;
  public impactedProducts?: Products[];
  public impactedProductsStr?: string;
  public impactArea?: any;
  public impactAreaStr?: any;
  public description?: string;
  public selectedImpactedProducts?: number[];
  public guidanceId?: number;
  public isBase?: boolean;
}
