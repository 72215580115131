<h1 mat-dialog-title>{{ "users.editContact" | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        formControlName="name"
        matInput
        name="name"
        placeholder="{{ 'usersAdd.name' | translate }}"
        tabindex="1"
      />
    </mat-form-field>

    <mat-form-field>
      <input
        formControlName="email"
        matInput
        name="email"
        placeholder="{{ 'usersAdd.email' | translate }}"
        tabindex="2"
        type="email"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="3"
        (selectionChange)="subdeptSet()"
        formControlName="departmentId"
        name="departmentId"
        placeholder="{{ 'usersAdd.department' | translate }}"
      >
        <mat-option
          *ngFor="let o of departmentList"
          [value]="o.id"
          value="option"
        >
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="subdeptDepList[0]">
      <mat-select
        tabindex="4"
        formControlName="subDepartmentId"
        name="subDepartmentId"
        placeholder="{{ 'usersAdd.subDepartment' | translate }}"
      >
        <mat-option
          *ngFor="let o of subdeptDepList"
          [value]="o.id"
          value="option"
        >
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="5"
        (openedChange)="roleSet(); roleSetProduct()"
        formControlName="selectedRoles"
        multiple
        name="selectedRoles"
        placeholder="{{ 'usersAdd.role' | translate }}"
      >
        <mat-option style="display: none"></mat-option>
        <mat-option *ngFor="let o of roleList" [value]="o.id" value="option">
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="central == true && companyPackage == 'premium'">
      <mat-select
        tabindex="6"
        formControlName="selectedCentralCosts"
        multiple
        name="selectedCentralCosts"
        placeholder="{{ 'usersAdd.centralCost' | translate }}"
      >
        <mat-option style="display: none"></mat-option>
        <mat-option
          *ngFor="let o of centralCostList"
          [value]="o.id"
          value="option"
        >
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="product == true">
      <mat-select
        #businessUnitSelect
        multiple
        tabindex="7"
        (selectionChange)="ProductFamilySet()"
        placeholder="{{ 'usersAdd.businessUnit' | translate }}"
        name="businessUnitId"
        formControlName="businessUnitId"
      >
        <mat-option disabled="disabled" class="filter-option">
          <button
            mat-raised-button
            class="mat-primary fill text-sm"
            (click)="selectAllBu(businessUnitSelect)"
          >
            Select All
          </button>
          &nbsp;
          <button
            mat-raised-button
            class="mat-accent fill text-sm"
            (click)="deselectAllBu()"
          >
            Deselect All
          </button>
        </mat-option>

        <mat-option
          *ngFor="let o of businessUnitList"
          [value]="o"
          value="option"
          (click)="deselectOneBu(o, businessUnitSelect)"
        >
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="product == true">
      <mat-select
        #productFamilySelect
        multiple
        tabindex="8"
        placeholder="{{ 'usersAdd.productFamily' | translate }}"
        (selectionChange)="ProductSet()"
        name="productFamilyId"
        formControlName="productFamilyId"
      >
        <mat-option disabled="disabled" class="filter-option">
          <button
            mat-raised-button
            class="mat-primary fill text-sm"
            (click)="selectAllPf(productFamilySelect)"
          >
            Select All
          </button>
          &nbsp;
          <button
            mat-raised-button
            class="mat-accent fill text-sm"
            (click)="deselectAllPf()"
          >
            Deselect All
          </button>
        </mat-option>

        <mat-option
          *ngFor="let o of productFamilyBuList"
          [value]="o"
          value="option"
          (click)="deselectOnePf(o, productFamilySelect)"
        >
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="product == true">
      <mat-select
        #productSelect
        tabindex="9"
        formControlName="selectedProducts"
        multiple
        name="selectedProducts"
        placeholder="{{ 'usersAdd.product' | translate }}"
      >
        <mat-option disabled="disabled" class="filter-option">
          <button
            mat-raised-button
            class="mat-primary fill text-sm"
            (click)="selectAll(productSelect)"
          >
            Select All
          </button>
          &nbsp;
          <button
            mat-raised-button
            class="mat-accent fill text-sm"
            (click)="deselectAll()"
          >
            Deselect All
          </button>
        </mat-option>

        <mat-option
          *ngFor="let o of productFamList"
          [value]="o.id"
          (click)="deselectOne(o.id, productSelect)"
          value="option"
        >
          {{ o.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button tabindex="10" type="submit">
      {{ "usersAdd.save" | translate }}
    </button>
    <button (click)="cancelClick()" mat-button tabindex="11" type="button">
      {{ "usersAdd.cancel" | translate }}
    </button>
  </div>
</form>
