import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { UserTask } from "@planard/@core/entities/user-task";

@Injectable()
export class UserTaskApi {
  private readonly apiController: string = "usertask";

  constructor(private api: HttpService) {}

  getUserTasks<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listUserTasks`);
  }

  getAllUserTasks<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAll`);
  }

  update<T>(userTask: UserTask): Observable<any> {
    return this.api.post(`${this.apiController}/update`, userTask);
  }
}
