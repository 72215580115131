import { AbstractEnum } from "../base/AbstractEnum";

export class EnumImpactArea extends AbstractEnum {
  static readonly PRICE = new EnumImpactArea("Price");
  static readonly VOLUME = new EnumImpactArea("Volume");
  static readonly COST = new EnumImpactArea("Cost");

  static values = AbstractEnum.getValues(EnumImpactArea);

  private constructor(public readonly displayValue: string) {
    super();
  }
}
