import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";

@Injectable()
export class NotificationApi {
  private readonly apiController: string = "notification";

  constructor(private api: HttpService) {}

  getNotifications<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listNotifications`); //user bazlı
  }

  getAllNotifications<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAll`); // all of them
  }
}
