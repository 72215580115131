import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { AccountsService } from "@planard/@core/backend/service/accounts.service";
import { ApprovalFlowService } from "@planard/@core/backend/service/approval-flow.service";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { Users } from "@planard/@core/entities/user";
import { ProcessResult } from "@planard/@core/entities/process-result";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ApprovalFlow } from "@planard/@core/entities/approvalFlow";

@Component({
  templateUrl: "./approval-flow.component.html",
  styleUrls: ["./approval-flow.component.scss"],
  styles: [
    `
      .toRight {
        flex: 0 0 10%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class ApprovalFlowComponent implements OnInit {
  edit: boolean;
  editing = {};
  rows = [];
  displayedColumns: string[] = ["user", "approvers"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  userList: ApprovalFlow[];
  loadingIndicator = true;
  approvalFlow: ApprovalFlow;

  dataSource: MatTableDataSource<Users>;
  @ViewChild(ApprovalFlowComponent, { static: true }) table: any[];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private approvalFlowService: ApprovalFlowService,
    private accountsService: AccountsService,
    public toastrService: ToastrService,
    public dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.accountsService.listUsers().subscribe((results: any) => {
      this.dataSource = results;
      this.rows = [...results];
    });
    this.approvalFlowService.listApprovers().subscribe((results: any) => {
      this.userList = results;
    });
    setTimeout(() => {
      this.loadingIndicator = false;
      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }, 1500);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  onUpdate(event, cell, rowIndex) {
    if (this.edit == true) {
      var approvalFlow = this.rows[rowIndex];
      approvalFlow[cell] = event.target.value;
      this.approvalFlowService
        .update(approvalFlow)
        .subscribe((result: ProcessResult) => {
          if (result.isSuccess) {
            this.editing[rowIndex + "-" + cell] = false;
            this.refresh();
            this.toastrService.success(result.message);
          } else {
            this.toastrService.error(result.message);
          }
        });
    }
  }

  onUpdateId(event, cell, rowIndex) {
    var approvalFlow = this.rows[rowIndex];
    approvalFlow[cell] = event.target.value;
    this.approvalFlowService
      .update(approvalFlow)
      .subscribe((result: ProcessResult) => {
        if (result.isSuccess) {
          this.editing[rowIndex + "-" + cell] = false;
          this.refresh();
          this.toastrService.success(result.message);
        } else {
          this.toastrService.error(result.message);
        }
      });
  }

  //addApprover(row) {
  //const dialogRef = this.dialog.open(ApproverAddComponent, {
  //width: '400px',
  //data: row
  //});

  //dialogRef.afterClosed().subscribe(result => {
  //if (result) {
  //this.rows.push(result);
  //this.refresh();
  //}
  //});
  //}

  // getApprovers(approval: Approval) {
  //   if (approval != null) {
  //     if (approval.isManager) {
  //       return 'NA';
  //     } else {
  //       return this.getUsername(approval.approver1Guid) + ', ' +
  //         this.getUsername(approval.approver2Guid) + ', ' +
  //         this.getUsername(approval.approver3Guid) + ', ' +
  //         this.getUsername(approval.approver4Guid) + ', ' +
  //         this.getUsername(approval.approver5Guid);
  //     }
  //   }
  // }

  //getUsername(userId) {
  //if (userId != null && userId != '00000000-0000-0000-0000-000000000000') {
  //for (let us of Object.keys(this.userList)) {
  //if (userId === this.userList[us].id) {
  //if (this.userList[us].name != null) {
  //return this.userList[us].name;
  //}
  //}
  //}
  //}
  //}

  refresh() {
    this.rows = [...this.rows];
    this.approvalFlowService.onApprovalFlowUsersReload.emit();
    this.changeDetectorRefs.detectChanges();
    this.dataSource.data = [...this.dataSource.data];
  }
}
