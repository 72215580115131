import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { Scenario } from "@planard/@core/entities/scenario";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { Products } from "@planard/@core/entities/product";
import { Channel } from "@planard/@core/entities/channel";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { PriceService } from "@planard/@core/backend/service/price.service";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { Actuals } from "@planard/@core/entities/actuals";
import { TranslateService } from "@ngx-translate/core";
import { EnumCostType } from "@planard/@core/enums/EnumCostType";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { Category } from "@planard/@core/entities/category";
import { SubCategory } from "@planard/@core/entities/subcategory";
import { CostType } from "@planard/@core/entities/costType";
import * as XLSX from "xlsx";
@Component({
  templateUrl: "./actuals-import.component.html",
})
export class ActualsImportComponent {
  name = "Angular";
  data2Exel: any;
  headData: any; // excel row header
  fullData: any;

  actuals: Actuals = {};

  currentYear = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  yearList = [
    this.currentYear,
    this.currentYear - 1,
    this.currentYear - 2,
    this.currentYear - 3,
    this.currentYear - 4,
    this.currentYear - 5,
  ];

  scenarioList: Scenario[];
  productList: Products[];
  channelList: Channel[];
  arrayData: any;
  type2data: any;

  scenarioId: any;
  type: any;
  year: number;
  exelName: string;
  productId: number;
  formId: number;
  channelId: number;

  //for exel import and read
  file: File;
  filelist: any;
  arrayBuffer: any;

  exelYear: any;
  exelType: any;
  exelScenarioName: any;
  exelFileName: any;
  isSpreadDone: boolean;
  exelImport: boolean;

  guidanceIsStart;

  categoryList: Category[];
  subcategoryList: SubCategory[];

  categoryListFiltered: Category[] = [];
  subcategoryListFiltered: SubCategory[] = [];

  OtherCostList: CostType[] = [];
  CentralCostList: CostType[] = [];

  form: FormGroup = new FormGroup({
    type: new FormControl("", [Validators.required]),
    period: new FormControl("", [Validators.required]),
  });
  options: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ActualsImportComponent>,
    public guidanceService: GuidanceService,
    public scenarioService: ScenarioService,
    public priceService: PriceService,
    public costService: CostService,
    public actualsService: ActualsService,
    public productService: ProductsService,
    public channelService: ChannelService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public fb: FormBuilder
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });

    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidanceIsStart = result.start ? true : false;
      this.guidanceService.getPlanningRecord(result.id).subscribe((result) => {
        result.forEach((element) => {
          if (
            element.year == new Date().getFullYear() &&
            element.planningType == "Monthly"
          ) {
            this.yearList = [
              this.currentYear,
              this.currentYear - 1,
              this.currentYear - 2,
              this.currentYear - 3,
              this.currentYear - 4,
              this.currentYear - 5,
            ];
          }
        });
      });
    });
  }

  ngOnInit() {
    this.scenarioService.listAll().subscribe((result) => {
      this.scenarioList = result;
    });
    this.productService.listAll().subscribe((result) => {
      this.productList = result;
    });
    this.channelService.listAll().subscribe((result) => {
      this.channelList = result;
    });
    this.costService.listAll().subscribe((result) => {
      result.forEach((element) => {
        if (element.costType == EnumCostType.CENTRAL) {
          this.CentralCostList.push(element);
        } else {
          this.OtherCostList.push(element);
        }
      });

      this.costService.listCategories().subscribe((result) => {
        this.categoryList = result;

        this.OtherCostList.forEach((ele) => {
          this.categoryList.forEach((element) => {
            if (ele.categoryId == element.id) {
              this.categoryListFiltered.push(element);
            }
          });
        });

        //make the list unique
        let newArr = [];
        this.categoryListFiltered.forEach((item, index) => {
          if (newArr.findIndex((i) => i.id == item.id) === -1) {
            newArr.push(item);
          }
        });
        this.categoryListFiltered = newArr;
      });

      this.costService.listSubcategories().subscribe((result) => {
        this.subcategoryList = result;

        this.OtherCostList.forEach((ele) => {
          this.subcategoryList.forEach((element) => {
            if (ele.subCategoryId == element.id) {
              this.subcategoryListFiltered.push(element);
            }
          });
        });

        //make the list unique
        let newArr2 = [];
        this.subcategoryListFiltered.forEach((item, index) => {
          if (newArr2.findIndex((i) => i.id == item.id) === -1) {
            newArr2.push(item);
          }
        });
        this.subcategoryListFiltered = newArr2;
      });
    });
  }

  setScenario(id: number) {
    for (let sc of Object.keys(this.scenarioList)) {
      if (id == this.scenarioList[sc].id) {
        return this.scenarioList[sc].name;
      }
    }
  }

  save() {
    if (this.form.valid) {
      let type = this.form.get("type").value;
      let year = this.form.get("period").value;

      this.actuals.filename = this.exelFileName;
      this.actuals.type = type;
      this.actuals.period = year;
      this.actuals.createdDate = new Date(
        new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
      );

      let data = { Rows: this.arrayData };

      if (type == this.exelType && year == this.exelYear && this.exelImport) {
        if (type == "Price(Price)" || type == "Price(Discount)") {
          this.actualsService.uploadPrice(data).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.dialogRef.close();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
            }
          });
        } else if (type == "Forecast(Amount)" || type == "Forecast(Volume)") {
          this.actualsService.uploadForecast(data).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.dialogRef.close();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
            }
          });
        } else if (type == "OtherForecast") {
          this.actualsService.uploadOtherForecast(data).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.dialogRef.close();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
            }
          });
        } else if (type == "Cost") {
          this.actualsService.uploadCost(data).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.dialogRef.close();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
            }
          });
        } else if (type == "CentralCost") {
          this.actualsService.uploadCentralCost(data).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.dialogRef.close();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
            }
          });
        }
        this.dialogRef.close();
      } else {
        this.toastrService.error(
          "Excel and chosen values does not match or excel is corrupted."
        );
        this.dialogRef.close();
      }
    } else {
      this.toastrService.error("Form is not Valid.");
    }
  }

  upload(event: any) {
    this.isSpreadDone = false;
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];

      var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true, defval: 0 });
      this.filelist = [];
      this.exelYear = arraylist[arraylist.length - 2]["Name"];
      this.exelType = first_sheet_name;
      this.exelFileName = event.target.files[0].name;
      this.type2data = arraylist[1]["Type"];

      this.exelImport = true;

      let dummy = [...arraylist];
      this.arrayData = dummy.splice(0, dummy.length - 2); //arraydata gönderilecek olan json

      console.log(this.arrayData);

      if (this.type2data == "Price") this.isSpreadDone = true;
      else if (this.type2data == "Discount") {
        let header = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var discountNotValid = false;
        this.arrayData.forEach((row) => {
          header.forEach((head) => {
            if (row[`${head} Discount`] > 100) discountNotValid = true;
          });
        });
        if (discountNotValid) {
          this.translate.get(`messagesV2.V2001`).subscribe((data: any) => {
            this.toastrService.error(data);
          });
          return;
        } else this.isSpreadDone = true;
      } else if (this.type2data == "Volume" || this.type2data == "Amount")
        this.columnSpreadForecast();
      else if (this.type2data == "OtherForecast") this.isSpreadDone = true;
      else if (this.type2data == "Cost") this.columnSpreadMix();
      else if (this.type2data == "CentralCost") this.columnSpreadCentral();
    };
  }

  columnSpreadForecast() {
    let type = this.type2data;
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    /* Form'a Yazma */
    for (let row of Object.keys(this.arrayData)) {
      if (
        this.arrayData[row][`productId`] != 0 &&
        this.arrayData[row][`productFormId`] != 0 &&
        this.arrayData[row][`productChannelId`] == 0
      ) {
        // form satırları

        let formFormId = this.arrayData[row][`productFormId`];
        let formProductId = this.arrayData[row][`productId`];

        let channelLines = this.arrayData.filter(
          (w: any) =>
            w[`productId`] == formProductId &&
            w[`productFormId`] == formFormId &&
            w[`productChannelId`] != 0
        );

        if (channelLines.length > 0) {
          this.arrayData[row][`Jan ${type}`] = 0;
          this.arrayData[row][`Feb ${type}`] = 0;
          this.arrayData[row][`Mar ${type}`] = 0;
          this.arrayData[row][`Apr ${type}`] = 0;
          this.arrayData[row][`May ${type}`] = 0;
          this.arrayData[row][`Jun ${type}`] = 0;
          this.arrayData[row][`Jul ${type}`] = 0;
          this.arrayData[row][`Aug ${type}`] = 0;
          this.arrayData[row][`Sep ${type}`] = 0;
          this.arrayData[row][`Oct ${type}`] = 0;
          this.arrayData[row][`Nov ${type}`] = 0;
          this.arrayData[row][`Dec ${type}`] = 0;

          for (let rowC of Object.keys(this.arrayData)) {
            if (
              this.arrayData[rowC][`productId`] == formProductId &&
              this.arrayData[rowC][`productFormId`] == formFormId &&
              this.arrayData[rowC][`productChannelId`] != 0
            ) {
              //  formun channel'ları
              for (let month of months) {
                this.arrayData[row][`${month} ${type}`] +=
                  this.arrayData[rowC][`${month} ${type}`];
              }
            }
          }
        }
      }
    }

    /* Product'a Yazma */
    for (let row of Object.keys(this.arrayData)) {
      if (
        this.arrayData[row][`productId`] != 0 &&
        this.arrayData[row][`productFormId`] == 0 &&
        this.arrayData[row][`productChannelId`] == 0
      ) {
        let productId = this.arrayData[row][`productId`];

        let formLines = this.arrayData.filter(
          (w: any) =>
            w[`productId`] == productId &&
            w[`productFormId`] != 0 &&
            w[`productChannelId`] == 0
        );

        if (formLines.length > 0) {
          this.arrayData[row][`Jan ${type}`] = 0;
          this.arrayData[row][`Feb ${type}`] = 0;
          this.arrayData[row][`Mar ${type}`] = 0;
          this.arrayData[row][`Apr ${type}`] = 0;
          this.arrayData[row][`May ${type}`] = 0;
          this.arrayData[row][`Jun ${type}`] = 0;
          this.arrayData[row][`Jul ${type}`] = 0;
          this.arrayData[row][`Aug ${type}`] = 0;
          this.arrayData[row][`Sep ${type}`] = 0;
          this.arrayData[row][`Oct ${type}`] = 0;
          this.arrayData[row][`Nov ${type}`] = 0;
          this.arrayData[row][`Dec ${type}`] = 0;

          for (let rowC of Object.keys(this.arrayData)) {
            if (
              this.arrayData[rowC][`productId`] == productId &&
              this.arrayData[rowC][`productFormId`] != 0 &&
              this.arrayData[rowC][`productChannelId`] == 0
            ) {
              let formId = this.arrayData[rowC][`productFormId`];
              let conversionRate = 1;

              this.productService.listAllForms(productId).subscribe((forms) => {
                forms.forEach((element) => {
                  if (element.id == formId) {
                    conversionRate = element.conversionRate;

                    if (type == "Volume") {
                      for (let month of months) {
                        this.arrayData[row][`${month} ${type}`] +=
                          this.arrayData[rowC][`${month} ${type}`] *
                          conversionRate; //conversion rate ile çarpmak lazım.
                      }
                    } else {
                      for (let month of months) {
                        this.arrayData[row][`${month} ${type}`] +=
                          this.arrayData[rowC][`${month} ${type}`];
                      }
                    }
                  }
                });
              });
            }
          }
        }
      }
    }
    this.isSpreadDone = true;
  }

  columnSetCost() {
    let newRows = [];

    let deptSubArr = [];
    for (let row of this.arrayData) {
      if (row.departmentId != 0) deptSubArr.push(row);
    }

    let sampleRow = deptSubArr[0];

    let deptIdArr = [];
    let subIdArr = [];

    for (let row of deptSubArr) {
      if (!deptIdArr.includes(row.departmentId) && row.departmentId != 0)
        deptIdArr.push(row.departmentId);
      if (subIdArr[0]) {
        if (
          !subIdArr.find((item) => item.id === row.subDepartmentId) &&
          row.subDepartmentId != 0
        )
          subIdArr.push({
            id: row.subDepartmentId,
            departmentId: row.departmentId,
          });
      } else if (row.subDepartmentId != 0)
        subIdArr.push({
          id: row.subDepartmentId,
          departmentId: row.departmentId,
        });
    }

    for (let d of deptIdArr) {
      let departmentId = d;
      if (subIdArr.find((item) => item.departmentId === d)) {
        // departmanın sub dep'i var

        for (let s of subIdArr) {
          if (s.departmentId == d) {
            let subDepartmentId = subIdArr.find(
              (item) => item.departmentId === d
            ).id;

            this.categoryListFiltered.forEach((element) => {
              let categoryRow = { ...sampleRow };
              categoryRow.categoryId = element.id;
              categoryRow.subCategoryId = 0;
              categoryRow.costId = 0;
              categoryRow.departmentId = departmentId;
              categoryRow.subDepartmentId = subDepartmentId;
              newRows.push(categoryRow);

              this.subcategoryListFiltered.forEach((ele) => {
                if (ele.categoryId == element.id) {
                  let subcategoryRow = { ...sampleRow };
                  subcategoryRow.categoryId = element.id;
                  subcategoryRow.subCategoryId = ele.id;
                  subcategoryRow.costId = 0;
                  subcategoryRow.departmentId = departmentId;
                  subcategoryRow.subDepartmentId = subDepartmentId;
                  newRows.push(subcategoryRow);

                  this.OtherCostList.forEach((e) => {
                    if (
                      e.categoryId == element.id &&
                      e.subCategoryId == ele.id
                    ) {
                      let costRow = { ...sampleRow };
                      costRow.categoryId = element.id;
                      costRow.subCategoryId = ele.id;
                      costRow.costId = e.id;
                      costRow.departmentId = departmentId;
                      costRow.subDepartmentId = subDepartmentId;
                      newRows.push(costRow);
                    }
                  });
                }
              });
            });
          }
        }
      } else {
        // departmanın sub dep'i yok

        this.categoryListFiltered.forEach((element) => {
          let categoryRow = { ...sampleRow };
          categoryRow.categoryId = element.id;
          categoryRow.subCategoryId = 0;
          categoryRow.costId = 0;
          categoryRow.departmentId = departmentId;
          categoryRow.subDepartmentId = 0;
          newRows.push(categoryRow);

          this.subcategoryListFiltered.forEach((ele) => {
            if (ele.categoryId == element.id) {
              let subcategoryRow = { ...sampleRow };
              subcategoryRow.categoryId = element.id;
              subcategoryRow.subCategoryId = ele.id;
              subcategoryRow.costId = 0;
              subcategoryRow.departmentId = departmentId;
              subcategoryRow.subDepartmentId = 0;
              newRows.push(subcategoryRow);

              this.OtherCostList.forEach((e) => {
                if (e.categoryId == element.id && e.subCategoryId == ele.id) {
                  let costRow = { ...sampleRow };
                  costRow.categoryId = element.id;
                  costRow.subCategoryId = ele.id;
                  costRow.costId = e.id;
                  costRow.departmentId = departmentId;
                  costRow.subDepartmentId = 0;
                  newRows.push(costRow);
                }
              });
            }
          });
        });
      }
    }

    deptSubArr.forEach((element) => {
      newRows.push(element);
    });

    this.arrayData = newRows;
    this.columnSpreadMix();
  }

  columnSpreadCost() {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    /* Sub Categorye yazma */
    for (let row of Object.keys(this.arrayData)) {
      if (
        this.arrayData[row][`categoryId`] != 0 &&
        this.arrayData[row][`subCategoryId`] != 0 &&
        this.arrayData[row][`costId`] == 0
      ) {
        let subCategoryId = this.arrayData[row][`subCategoryId`];
        let categoryId = this.arrayData[row][`categoryId`];

        this.arrayData[row][`Jan`] = 0;
        this.arrayData[row][`Feb`] = 0;
        this.arrayData[row][`Mar`] = 0;
        this.arrayData[row][`Apr`] = 0;
        this.arrayData[row][`May`] = 0;
        this.arrayData[row][`Jun`] = 0;
        this.arrayData[row][`Jul`] = 0;
        this.arrayData[row][`Aug`] = 0;
        this.arrayData[row][`Sep`] = 0;
        this.arrayData[row][`Oct`] = 0;
        this.arrayData[row][`Nov`] = 0;
        this.arrayData[row][`Dec`] = 0;

        for (let rowC of Object.keys(this.arrayData)) {
          if (
            this.arrayData[rowC][`categoryId`] == categoryId &&
            this.arrayData[rowC][`subCategoryId`] == subCategoryId &&
            this.arrayData[rowC][`costId`] != 0
          ) {
            for (let month of months) {
              this.arrayData[row][`${month}`] +=
                this.arrayData[rowC][`${month}`];
            }
          }
        }
      }
    }

    /* Categorye yazma */
    for (let row of Object.keys(this.arrayData)) {
      if (
        this.arrayData[row][`categoryId`] != 0 &&
        this.arrayData[row][`subCategoryId`] == 0 &&
        this.arrayData[row][`costId`] == 0
      ) {
        let categoryId = this.arrayData[row][`categoryId`];

        this.arrayData[row][`Jan`] = 0;
        this.arrayData[row][`Feb`] = 0;
        this.arrayData[row][`Mar`] = 0;
        this.arrayData[row][`Apr`] = 0;
        this.arrayData[row][`May`] = 0;
        this.arrayData[row][`Jun`] = 0;
        this.arrayData[row][`Jul`] = 0;
        this.arrayData[row][`Aug`] = 0;
        this.arrayData[row][`Sep`] = 0;
        this.arrayData[row][`Oct`] = 0;
        this.arrayData[row][`Nov`] = 0;
        this.arrayData[row][`Dec`] = 0;

        for (let rowC of Object.keys(this.arrayData)) {
          if (
            this.arrayData[rowC][`categoryId`] == categoryId &&
            this.arrayData[rowC][`subCategoryId`] != 0 &&
            this.arrayData[rowC][`costId`] == 0
          ) {
            for (let month of months) {
              this.arrayData[row][`${month}`] +=
                this.arrayData[rowC][`${month}`];
            }
          }
        }
      }
    }
    this.isSpreadDone = true;
  }

  columnSpreadMix() {
    // cat - subcat - cost - dept - subdept
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    /* Sub Departmentten -> Departmente yazma */
    for (let rowDept of Object.keys(this.arrayData)) {
      if (
        this.arrayData[rowDept][`categoryId`] != 0 &&
        this.arrayData[rowDept][`subCategoryId`] != 0 &&
        this.arrayData[rowDept][`costId`] != 0 &&
        this.arrayData[rowDept][`departmentId`] != 0 &&
        this.arrayData[rowDept][`subDepartmentId`] == 0
      ) {
        // departman satırları

        let categoryId = this.arrayData[rowDept][`categoryId`];
        let subCategoryId = this.arrayData[rowDept][`subCategoryId`];
        let costId = this.arrayData[rowDept][`costId`];
        let departmentId = this.arrayData[rowDept][`departmentId`];

        let isDeptHasSub = false;
        for (let rowSub of Object.keys(this.arrayData)) {
          if (
            this.arrayData[rowSub][`categoryId`] == categoryId &&
            this.arrayData[rowSub][`subCategoryId`] == subCategoryId &&
            this.arrayData[rowSub][`costId`] == costId &&
            this.arrayData[rowSub][`departmentId`] == departmentId &&
            this.arrayData[rowSub][`subDepartmentId`] != 0
          ) {
            isDeptHasSub = true;
          }
        }

        if (isDeptHasSub) {
          // dept'in sub dept i varsa sıfırlamalıyız, dept satırına veri girilmiş olması ihtimaline karşı.
          this.arrayData[rowDept][`Jan`] = 0;
          this.arrayData[rowDept][`Feb`] = 0;
          this.arrayData[rowDept][`Mar`] = 0;
          this.arrayData[rowDept][`Apr`] = 0;
          this.arrayData[rowDept][`May`] = 0;
          this.arrayData[rowDept][`Jun`] = 0;
          this.arrayData[rowDept][`Jul`] = 0;
          this.arrayData[rowDept][`Aug`] = 0;
          this.arrayData[rowDept][`Sep`] = 0;
          this.arrayData[rowDept][`Oct`] = 0;
          this.arrayData[rowDept][`Nov`] = 0;
          this.arrayData[rowDept][`Dec`] = 0;
        }

        for (let rowSubDept of Object.keys(this.arrayData)) {
          if (
            this.arrayData[rowSubDept][`categoryId`] == categoryId &&
            this.arrayData[rowSubDept][`subCategoryId`] == subCategoryId &&
            this.arrayData[rowSubDept][`costId`] == costId &&
            this.arrayData[rowSubDept][`departmentId`] == departmentId &&
            this.arrayData[rowSubDept][`subDepartmentId`] != 0
          ) {
            // departmana ait sub-departman satırları
            for (let month of months) {
              this.arrayData[rowDept][`${month}`] +=
                this.arrayData[rowSubDept][`${month}`];
            }
          }
        }
      }
    }

    /* Departmentten -> Costa yazma */
    for (let rowCost of Object.keys(this.arrayData)) {
      if (
        this.arrayData[rowCost][`categoryId`] != 0 &&
        this.arrayData[rowCost][`subCategoryId`] != 0 &&
        this.arrayData[rowCost][`costId`] != 0 &&
        this.arrayData[rowCost][`departmentId`] == 0 &&
        this.arrayData[rowCost][`subDepartmentId`] == 0
      ) {
        // cost satırları

        let categoryId = this.arrayData[rowCost][`categoryId`];
        let subCategoryId = this.arrayData[rowCost][`subCategoryId`];
        let costId = this.arrayData[rowCost][`costId`];

        // cost satırlarını sıfırlıyoruz, veri giirlmiş ol ihtimaline karşılık.
        this.arrayData[rowCost][`Jan`] = 0;
        this.arrayData[rowCost][`Feb`] = 0;
        this.arrayData[rowCost][`Mar`] = 0;
        this.arrayData[rowCost][`Apr`] = 0;
        this.arrayData[rowCost][`May`] = 0;
        this.arrayData[rowCost][`Jun`] = 0;
        this.arrayData[rowCost][`Jul`] = 0;
        this.arrayData[rowCost][`Aug`] = 0;
        this.arrayData[rowCost][`Sep`] = 0;
        this.arrayData[rowCost][`Oct`] = 0;
        this.arrayData[rowCost][`Nov`] = 0;
        this.arrayData[rowCost][`Dec`] = 0;

        for (let rowDept2 of Object.keys(this.arrayData)) {
          if (
            this.arrayData[rowDept2][`categoryId`] == categoryId &&
            this.arrayData[rowDept2][`subCategoryId`] == subCategoryId &&
            this.arrayData[rowDept2][`costId`] == costId &&
            this.arrayData[rowDept2][`departmentId`] != 0 &&
            this.arrayData[rowDept2][`subDepartmentId`] == 0
          ) {
            // cost satırlarının altındaki departman satırları.
            for (let month of months) {
              this.arrayData[rowCost][`${month}`] +=
                this.arrayData[rowDept2][`${month}`];
            }
          }
        }
      }
    }

    /* Costtan -> Sub Categorye yazma */
    for (let rowSubCat of Object.keys(this.arrayData)) {
      if (
        this.arrayData[rowSubCat][`categoryId`] != 0 &&
        this.arrayData[rowSubCat][`subCategoryId`] != 0 &&
        this.arrayData[rowSubCat][`costId`] == 0
      ) {
        //

        let categoryId = this.arrayData[rowSubCat][`categoryId`];
        let subCategoryId = this.arrayData[rowSubCat][`subCategoryId`];

        // veri girilme ihtimaline karşı sub category satırlarını sıfırlıyoruz.
        this.arrayData[rowSubCat][`Jan`] = 0;
        this.arrayData[rowSubCat][`Feb`] = 0;
        this.arrayData[rowSubCat][`Mar`] = 0;
        this.arrayData[rowSubCat][`Apr`] = 0;
        this.arrayData[rowSubCat][`May`] = 0;
        this.arrayData[rowSubCat][`Jun`] = 0;
        this.arrayData[rowSubCat][`Jul`] = 0;
        this.arrayData[rowSubCat][`Aug`] = 0;
        this.arrayData[rowSubCat][`Sep`] = 0;
        this.arrayData[rowSubCat][`Oct`] = 0;
        this.arrayData[rowSubCat][`Nov`] = 0;
        this.arrayData[rowSubCat][`Dec`] = 0;

        for (let rowCost2 of Object.keys(this.arrayData)) {
          if (
            this.arrayData[rowCost2][`categoryId`] == categoryId &&
            this.arrayData[rowCost2][`subCategoryId`] == subCategoryId &&
            this.arrayData[rowCost2][`costId`] != 0 &&
            this.arrayData[rowCost2][`departmentId`] == 0 &&
            this.arrayData[rowCost2][`subDepartmentId`] == 0
          ) {
            // sub-categorynin altındaki cost satırları.;
            for (let month of months) {
              this.arrayData[rowSubCat][`${month}`] +=
                this.arrayData[rowCost2][`${month}`];
            }
          }
        }
      }
    }

    /* Sub-Categoriden -> Categorye yazma */
    for (let rowCat of Object.keys(this.arrayData)) {
      if (
        this.arrayData[rowCat][`categoryId`] != 0 &&
        this.arrayData[rowCat][`subCategoryId`] == 0 &&
        this.arrayData[rowCat][`costId`] == 0
      ) {
        // category satırları

        let categoryId = this.arrayData[rowCat][`categoryId`];

        // veri girilme ihtimaline karşı category satırlarını sıfırlıyoruz.
        this.arrayData[rowCat][`Jan`] = 0;
        this.arrayData[rowCat][`Feb`] = 0;
        this.arrayData[rowCat][`Mar`] = 0;
        this.arrayData[rowCat][`Apr`] = 0;
        this.arrayData[rowCat][`May`] = 0;
        this.arrayData[rowCat][`Jun`] = 0;
        this.arrayData[rowCat][`Jul`] = 0;
        this.arrayData[rowCat][`Aug`] = 0;
        this.arrayData[rowCat][`Sep`] = 0;
        this.arrayData[rowCat][`Oct`] = 0;
        this.arrayData[rowCat][`Nov`] = 0;
        this.arrayData[rowCat][`Dec`] = 0;

        for (let rowSubCat2 of Object.keys(this.arrayData)) {
          if (
            this.arrayData[rowSubCat2][`categoryId`] == categoryId &&
            this.arrayData[rowSubCat2][`subCategoryId`] != 0 &&
            this.arrayData[rowSubCat2][`costId`] == 0 &&
            this.arrayData[rowSubCat2][`departmentId`] == 0 &&
            this.arrayData[rowSubCat2][`subDepartmentId`] == 0
          ) {
            // categorynin altındaki sub-category satırları
            for (let month of months) {
              this.arrayData[rowCat][`${month}`] +=
                this.arrayData[rowSubCat2][`${month}`];
            }
          }
        }
      }
    }
    this.isSpreadDone = true;
  }

  columnSpreadCentral() {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    /* Departmente yazma */
    for (let row of Object.keys(this.arrayData)) {
      if (
        this.arrayData[row][`costId`] != 0 &&
        this.arrayData[row][`departmentId`] != 0 &&
        this.arrayData[row][`subDepartmentId`] == 0
      ) {
        let departmentId = this.arrayData[row][`departmentId`];
        let costId = this.arrayData[row][`costId`];

        this.arrayData[row][`Jan`] = 0;
        this.arrayData[row][`Feb`] = 0;
        this.arrayData[row][`Mar`] = 0;
        this.arrayData[row][`Apr`] = 0;
        this.arrayData[row][`May`] = 0;
        this.arrayData[row][`Jun`] = 0;
        this.arrayData[row][`Jul`] = 0;
        this.arrayData[row][`Aug`] = 0;
        this.arrayData[row][`Sep`] = 0;
        this.arrayData[row][`Oct`] = 0;
        this.arrayData[row][`Nov`] = 0;
        this.arrayData[row][`Dec`] = 0;

        for (let rowC of Object.keys(this.arrayData)) {
          if (
            this.arrayData[rowC][`costId`] == costId &&
            this.arrayData[rowC][`departmentId`] == departmentId &&
            this.arrayData[rowC][`subDepartmentId`] != 0
          ) {
            for (let month of months) {
              this.arrayData[row][`${month}`] +=
                this.arrayData[rowC][`${month}`];
            }
          }
        }
      }
    }

    /* Cost a yazma */
    for (let row of Object.keys(this.arrayData)) {
      if (
        this.arrayData[row][`costId`] != 0 &&
        this.arrayData[row][`departmentId`] == 0 &&
        this.arrayData[row][`subDepartmentId`] == 0
      ) {
        let costId = this.arrayData[row][`costId`];

        this.arrayData[row][`Jan`] = 0;
        this.arrayData[row][`Feb`] = 0;
        this.arrayData[row][`Mar`] = 0;
        this.arrayData[row][`Apr`] = 0;
        this.arrayData[row][`May`] = 0;
        this.arrayData[row][`Jun`] = 0;
        this.arrayData[row][`Jul`] = 0;
        this.arrayData[row][`Aug`] = 0;
        this.arrayData[row][`Sep`] = 0;
        this.arrayData[row][`Oct`] = 0;
        this.arrayData[row][`Nov`] = 0;
        this.arrayData[row][`Dec`] = 0;

        for (let rowC of Object.keys(this.arrayData)) {
          if (
            this.arrayData[rowC][`costId`] == costId &&
            this.arrayData[rowC][`departmentId`] != 0 &&
            this.arrayData[rowC][`subDepartmentId`] == 0
          ) {
            for (let month of months) {
              this.arrayData[row][`${month}`] +=
                this.arrayData[rowC][`${month}`];
            }
          }
        }
      }
    }

    this.isSpreadDone = true;
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
