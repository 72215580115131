import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ReloadService {
  windowLocation: string = null;
  subscription: Subscription;
  browserRefresh = false;

  constructor(public router: Router) {}

  change() {
    this.router.navigate(["/hello"]);
    this.windowLocation = window.location.href;
  }
}
