import { Users } from "../user";
import { Observable } from "rxjs";
import { SubDepartment } from "@planard/@core/entities/subDepartment";
import { Department } from "@planard/@core/entities/department";

export abstract class AccountsData {
  abstract add(user: Users);

  abstract getUser();

  abstract getSignedUser();

  abstract isApprover(userId: any);

  abstract changePassword(password: any);

  abstract listDepartments(): Observable<Department[]>;

  abstract listSubDepartments(): Observable<SubDepartment[]>;

  abstract listUsers(): Observable<Users[]>;

  abstract updateUser(user: Users);
}
