import "hammerjs";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule, DecimalPipe } from "@angular/common";
import { DemoMaterialModule } from "../../common/demo-material-module";
import { ChartsModule } from "ng2-charts";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CostReportsRoutes } from "./cost-reports.routing";
import { ChartModule } from "angular2-chartjs";
import { MenuItems } from "@planard/shared/menu-items/menu-items";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ScenarioApi } from "@planard/@core/backend/api/scenario.api";
import { ProductsApi } from "@planard/@core/backend/api/products.api";
import { PipesModule } from "@planard/@core/pipes/pipes.module";
import { CostTrendComponent } from "./cost-trend/cost-trend.component";
import { CostOverviewApi } from "@planard/@core/backend/api/costOverview.api";
import { CostOverviewService } from "@planard/@core/backend/service/costOverview.service";
import { AgGridModule } from "ag-grid-angular";
import { CostOverviewComponent } from "./cost-overview/cost-overview.component";
import { PlanApi } from "@planard/@core/backend/api/plan.api";
import { PlanService } from "@planard/@core/backend/service/plan.service";
import { ReportScreenshotApi } from "@planard/@core/backend/api/reportScreenshot.api";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { CostsApi } from "@planard/@core/backend/api/costs.api";
import { GuidanceApi } from "@planard/@core/backend/api/guidance.api";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { CostBridgeComponent } from "./cost-bridge/cost-bridge.component";
import { TranslateModule } from "@ngx-translate/core";
import { AccountsService } from "@planard/@core/backend/service/accounts.service";
import { AccountsApi } from "@planard/@core/backend/api/accounts.api";

const API = [
  ScenarioApi,
  ProductsApi,
  CostOverviewApi,
  ReportScreenshotApi,
  PlanApi,
  CostsApi,
  GuidanceApi,
  AccountsApi,
];

const SERVICES = [
  ScenarioService,
  ProductsService,
  CostOverviewService,
  ReportScreenshotService,
  PlanService,
  CostService,
  GuidanceService,
  AccountsService,
];

@NgModule({
  imports: [
    CommonModule,
    ChartModule,
    PipesModule,
    RouterModule.forChild(CostReportsRoutes),
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ChartsModule,
    TranslateModule,
    AgGridModule.withComponents([]),
  ],
  providers: [MenuItems, ...API, ...SERVICES, DecimalPipe],
  declarations: [
    CostTrendComponent,
    CostOverviewComponent,
    CostBridgeComponent,
  ],
  exports: [CostTrendComponent],
})
export class CostReportsModule {}
