import { Company } from "../company";
import { Observable } from "rxjs";
import { BusinessUnit } from "../businessUnit";

export abstract class CompanyData {
  abstract get(): Observable<Company>;

  abstract list(): Observable<Company[]>;

  abstract add(company: Company);

  abstract update(company: Company);

  abstract updatePackage(body: any);

  abstract delete(id: number);

  abstract listAllBusinessUnit(): Observable<BusinessUnit[]>;
}
