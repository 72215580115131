import { Routes } from "@angular/router";
import { GuidanceComponent } from "@planard/admin/planning/guidance/guidance.component";
import { ActualsComponent } from "@planard/admin/planning/actuals/actuals.component";
import { ScenarioNewComponent } from "./scenario/scenario-new.component";
import { TimelineNewComponent } from "./timeline/timeline-new.component";
import { UserTaskComponent } from "./user-task/user-task.component";

export const AdminPlanningRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "guidance",
        component: GuidanceComponent,
        data: {
          title: "Guidance",
          urls: [{ title: "Guidance", url: "/guidance" }],
        },
      },
      {
        path: "scenarios",
        component: ScenarioNewComponent,
        data: {
          title: "Scenarios",
          urls: [{ title: "Scenarios", url: "/scenarios" }],
        },
      },
      {
        path: "timeline",
        component: TimelineNewComponent,
        data: {
          title: "Timeline",
          urls: [{ title: "Timeline", url: "/timeline" }],
        },
      },
      {
        path: "actuals",
        component: ActualsComponent,
        data: {
          title: "Actuals",
          urls: [{ title: "Actuals", url: "/actuals" }],
        },
      },
      {
        path: "tasks",
        component: UserTaskComponent,
        data: {
          title: "Tasks",
          urls: [{ title: "Tasks", url: "/tasks" }],
        },
      },
    ],
  },
];
