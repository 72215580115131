import "hammerjs";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { DemoMaterialModule } from "../../common/demo-material-module";
import { ChartsModule } from "ng2-charts";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ChartModule } from "angular2-chartjs";
import { MenuItems } from "@planard/shared/menu-items/menu-items";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ScenarioApi } from "@planard/@core/backend/api/scenario.api";
import { ProductsApi } from "@planard/@core/backend/api/products.api";
import { PipesModule } from "@planard/@core/pipes/pipes.module";
import { AgGridModule } from "ag-grid-angular";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { GuidanceApi } from "@planard/@core/backend/api/guidance.api";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { BusinessUnitApi } from "@planard/@core/backend/api/business-unit.api";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import { ProductFamilyApi } from "@planard/@core/backend/api/product-family.api";
import { ChannelApi } from "@planard/@core/backend/api/channel.api";
import { CostsApi } from "@planard/@core/backend/api/costs.api";
import { ProductFamilyService } from "@planard/@core/backend/service/product-family.service";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { ReportsService } from "@planard/@core/backend/service/reports.service";
import { ReportsApi } from "@planard/@core/backend/api/reports.api";
import { SettingService } from "@planard/@core/backend/service/setting.service";
import { SettingApi } from "@planard/@core/backend/api/setting.api";
import { EconomicReportsComponent } from "./ecenomic-reports/economic-reports.component";
import { SupersettingsService } from "@planard/@core/backend/service/supersettings.service";
import { SupersettingsApi } from "@planard/@core/backend/api/supersettings.api";
import { TranslateModule } from "@ngx-translate/core";
import { EconomicReportsRoutes } from "./economic-reports.routing";

const API = [
  ScenarioApi,
  ProductsApi,
  GuidanceApi,
  BusinessUnitApi,
  ProductFamilyApi,
  ChannelApi,
  CostsApi,
  ReportsApi,
  SettingApi,
  SupersettingsApi,
];

const SERVICES = [
  ScenarioService,
  ProductsService,
  GuidanceService,
  BusinessUnitService,
  ProductFamilyService,
  ChannelService,
  CostService,
  ReportsService,
  SettingService,
  SupersettingsService,
];

@NgModule({
  imports: [
    CommonModule,
    ChartModule,
    PipesModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ChartsModule,
    NgxDatatableModule,
    TranslateModule,
    RouterModule.forChild(EconomicReportsRoutes),
    AgGridModule.withComponents([]),
  ],
  providers: [MenuItems, ...API, ...SERVICES],
  declarations: [EconomicReportsComponent],
})
export class EconomicReportsModule {}
