import { Observable } from "rxjs";
import { CostType } from "../costType";

export abstract class CostsData {
  abstract listAll(): Observable<any[]>;

  abstract listCategories(): Observable<any[]>;

  abstract listCategoriesCurrent(): Observable<any[]>;

  abstract listSubcategories(): Observable<any[]>;

  abstract add(product: CostType): Observable<any>;

  abstract update(product: CostType): Observable<any>;

  abstract delete(id: number): Observable<any>;
}
