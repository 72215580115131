import { NgModule } from "@angular/core";
import { PipesModule } from "@planard/@core/pipes/pipes.module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CommonModule, DatePipe } from "@angular/common";
import { DemoMaterialModule } from "@planard/common/demo-material-module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ProductFamilyAddComponent } from "@planard/admin/general/product-family/add/product-family-add.component";
import { ProductsNewComponent } from "@planard/admin/general/products/products-new.component";
import { ProductsAddComponent } from "@planard/admin/general/products/add/products-add.component";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { BusinessUnitNewComponent } from "@planard/admin/general/business-unit/business-unit-new.component";
import { BusinessUnitAddComponent } from "@planard/admin/general/business-unit/add/add.component";
import { DepartmentAddComponent } from "@planard/admin/general/department/add/add.component";
import { SubdepartmentAddComponent } from "@planard/admin/general/subdepartment/add/subdepartment-add.component";
import { ChannelNewComponent } from "@planard/admin/general/channel/channel-new.component";
import { ChannelAddComponent } from "@planard/admin/general/channel/add/channel-add.component";
import { ProductsApi } from "@planard/@core/backend/api/products.api";
import { ProductFamilyApi } from "@planard/@core/backend/api/product-family.api";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { AccountsApi } from "@planard/@core/backend/api/accounts.api";
import { ApprovalFlowApi } from "@planard/@core/backend/api/approval-flow.api";
import { BusinessUnitApi } from "@planard/@core/backend/api/business-unit.api";
import { DepartmentApi } from "@planard/@core/backend/api/department.api";
import { GuidanceApi } from "@planard/@core/backend/api/guidance.api";
import { TimelineApi } from "@planard/@core/backend/api/timeline.api";
import { ChannelApi } from "@planard/@core/backend/api/channel.api";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ProductFamilyService } from "@planard/@core/backend/service/product-family.service";
import { AccountsService } from "@planard/@core/backend/service/accounts.service";
import { ApprovalFlowService } from "@planard/@core/backend/service/approval-flow.service";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import { DepartmentService } from "@planard/@core/backend/service/department.service";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { TimelineService } from "@planard/@core/backend/service/timeline.service";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { NotificationService } from "@planard/@core/backend/service/notification.service";
import { NotificationApi } from "@planard/@core/backend/api/notification.api";
import { UserTaskService } from "@planard/@core/backend/service/user-task.service";
import { UserTaskApi } from "@planard/@core/backend/api/user-task.api";
import { RoleApi } from "@planard/@core/backend/api/role.api";
import { RoleService } from "@planard/@core/backend/service/role.service";
import { UsersAddComponent } from "@planard/admin/general/users/add/users-add.component";
import { UsersUpdateComponent } from "@planard/admin/general/users/update/users-update.component";
import { RolesAddComponent } from "@planard/admin/general/roles/add/roles-add.component";
import { RolesComponent } from "@planard/admin/general/roles/roles.component";
import { AdminGeneralRoutes } from "@planard/admin/general/admin-general.routing";
import { MenuItems } from "@planard/shared/menu-items/menu-items";
import { ProductFormAddComponent } from "@planard/admin/general/products/productForm/add/productForm-add.component";
import { ProductChannelComponent } from "@planard/admin/general/products/productChannel/productChannel.component";
import { ProductChannelAddComponent } from "@planard/admin/general/products/productChannel/add/productChannel-add.component";
import { SettingsComponent } from "@planard/settings/settings.component";
import { ApprovalFlowComponent } from "@planard/admin/general/approval-flow/approval-flow.component";
import { ApproverAddComponent } from "@planard/admin/general/approval-flow/add/approver-add.component";
import { ProfileService } from "@planard/@core/backend/service/profile.service";
import { ProfileApi } from "@planard/@core/backend/api/profile.api";
import { ProfileComponent } from "@planard/admin/auth/profile/profile.component";
import { CostTypeAddComponent } from "@planard/admin/general/costtypes/add/cost-type-add.component";
import { CostsApi } from "@planard/@core/backend/api/costs.api";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { DepartmentNewComponent } from "./department/department-new.component";
import { SubdepartmentNewComponent } from "./subdepartment/subdepartment-new.component";
import { ProductFamilyNewComponent } from "./product-family/product-family-new.component";
import { CostTypeNewComponent } from "./costtypes/cost-type-new.component";
import { UsersNewComponent } from "./users/users-new.component";
import { ProductFormNewComponent } from "./products/productForm/productForm-new.component";
import { MapleModule } from "@planard/maple/maple.module";
import { TranslateModule } from "@ngx-translate/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from "@angular/material-moment-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_DATE_LOCALE } from "@angular/material/core";

const API = [
  ProductsApi,
  ProductFamilyApi,
  HttpService,
  AccountsApi,
  ApprovalFlowApi,
  BusinessUnitApi,
  DepartmentApi,
  GuidanceApi,
  TimelineApi,
  ChannelApi,
  NotificationApi,
  UserTaskApi,
  RoleApi,
  ProfileApi,
  CostsApi,
];

const SERVICES = [
  ProductsService,
  ProductFamilyService,
  AccountsService,
  ApprovalFlowService,
  BusinessUnitService,
  DepartmentService,
  GuidanceService,
  TimelineService,
  ChannelService,
  NotificationService,
  UserTaskService,
  RoleService,
  ProfileService,
  CostService,
];

@NgModule({
  imports: [
    PipesModule,
    NgxDatatableModule,
    CommonModule,
    MapleModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    TranslateModule,
    RouterModule.forChild(AdminGeneralRoutes),
  ],
  declarations: [
    ProductFamilyAddComponent,
    ProductFamilyNewComponent,
    ProductsNewComponent,
    ProductsAddComponent,
    ConfirmationDialogComponent,
    BusinessUnitNewComponent,
    BusinessUnitAddComponent,
    DepartmentNewComponent,
    DepartmentAddComponent,
    SubdepartmentNewComponent,
    SubdepartmentAddComponent,
    ChannelNewComponent,
    ChannelAddComponent,
    UsersNewComponent,
    UsersAddComponent,
    UsersUpdateComponent,
    ApprovalFlowComponent,
    ApproverAddComponent,
    RolesAddComponent,
    RolesComponent,
    ProductFormAddComponent,
    ProductFormNewComponent,
    ProductChannelComponent,
    ProductChannelAddComponent,
    SettingsComponent,
    ProfileComponent,
    CostTypeAddComponent,
    CostTypeNewComponent,
  ],
  providers: [
    MenuItems,
    DatePipe,
    ...API,
    ...SERVICES,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class AdminGeneralModule {}
