import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Observable } from "rxjs";
import { CostType } from "../../entities/costType";

@Injectable()
export class CostsApi {
  private readonly apiController: string = "cost";

  constructor(private api: HttpService) {}

  listAll<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAll`);
  }

  listCategories<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listCategories`);
  }

  listCategoriesCurrent<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listCategoriesCurrentGuidance`);
  }

  listSubcategories<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listSubcategories`);
  }

  add<T>(product: CostType): Observable<any> {
    return this.api.post(`${this.apiController}/addCost`, product);
  }

  update<T>(product: CostType): Observable<any> {
    return this.api.post(`${this.apiController}/updateCost`, product);
  }

  delete<T>(id: number): Observable<any> {
    var json = { id: id };
    return this.api.post(`${this.apiController}/deleteCost`, json);
  }
}
