import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { BusinessUnit } from "@planard/@core/entities/businessUnit";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";
@Component({
  templateUrl: "./add.component.html",
})
export class BusinessUnitAddComponent {
  businessUnit: BusinessUnit;

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, removeSpaces]),
  });
  options: FormGroup;

  constructor(
    public buService: BusinessUnitService,
    public dialogRef: MatDialogRef<BusinessUnitAddComponent>,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public fb: FormBuilder
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
  }

  save() {
    if (this.form.valid) {
      this.businessUnit = this.form.value;
      this.buService.add(this.businessUnit).subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.dialogRef.close(this.businessUnit);
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
