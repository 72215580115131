import { Routes } from "@angular/router";
import { PriceTrendComponent } from "./price-trend/price-trend.component";
import { SalesTrendComponent } from "./sales-trend/sales-trend.component";
import { SalesBridgeComponent } from "./sales-bridge/sales-bridge.component";
import { PriceVolumeComponent } from "./price-volume/price-volume.component";
import { SalesDriversComponent } from "./sales-drivers/sales-drivers.component";
import { LaunchDatesComponent } from "./launch-dates/launch-dates.component";
import { SalesOverviewComponent } from "./sales-overview/sales-overview.component";
import { ProductOverviewComponent } from "./product-overview/product-overview.component";
import { ChannelOverviewComponent } from "./channel-overview/channel-overview.component";
import { ActualPlanComponent } from "./actual-plan/actual-plan.component";

export const SalesReportsRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "sales-overview",
        component: SalesOverviewComponent,
        data: {
          title: "Sales Overview",
          urls: [{ title: "Sales Overview", url: "/sales-overview" }],
        },
      },
      {
        path: "price-trend",
        component: PriceTrendComponent,
        data: {
          title: "Price Trend",
          urls: [{ title: "Price Trend", url: "/price-trend" }],
        },
      },
      {
        path: "sales-trend",
        component: SalesTrendComponent,
        data: {
          title: "Sales Trend",
          urls: [{ title: "Sales Trend", url: "/sales-trend" }],
        },
      },
      {
        path: "sales-bridge",
        component: SalesBridgeComponent,
        data: {
          title: "Sales Bridge",
          urls: [{ title: "Sales Bridge", url: "/sales-bridge" }],
        },
      },
      {
        path: "price-volume",
        component: PriceVolumeComponent,
        data: {
          title: "Price & Volume",
          urls: [{ title: "Price & Volume", url: "/price-volume" }],
        },
      },
      {
        path: "sales-drivers",
        component: SalesDriversComponent,
        data: {
          title: "Sales Drivers",
          urls: [{ title: "Sales Drivers", url: "/sales-drivers" }],
        },
      },
      {
        path: "launch-dates",
        component: LaunchDatesComponent,
        data: {
          title: "launch-dates",
          urls: [{ title: "Launch Dates", url: "/launch-dates" }],
        },
      },
      {
        path: "product-overview",
        component: ProductOverviewComponent,
        data: {
          title: "Product Overview",
          urls: [{ title: "Product Overview", url: "/product-overview" }],
        },
      },
      {
        path: "channel-overview",
        component: ChannelOverviewComponent,
        data: {
          title: "Channel Overview",
          urls: [{ title: "Channel Overview", url: "/channel-overview" }],
        },
      },
      {
        path: "actual-plan",
        component: ActualPlanComponent,
        data: {
          title: "Actual vs Plan Report",
          urls: [{ title: "Actual vs Plan", url: "/actual-plan" }],
        },
      },
    ],
  },
];
