import { Injectable } from "@angular/core";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { Observable } from "rxjs";
import { Channel } from "@planard/@core/entities/channel";

@Injectable()
export class ChannelApi {
  private readonly apiController: string = "channel";

  constructor(private api: HttpService) {}

  listAll<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listAll`);
  }

  add<T>(channel: Channel): Observable<any> {
    return this.api.post(`${this.apiController}/add`, channel);
  }

  update<T>(channel: Channel): Observable<any> {
    return this.api.post(`${this.apiController}/update`, channel);
  }

  delete<T>(channelId: number): Observable<any> {
    var json = { id: channelId };
    return this.api.post(`${this.apiController}/delete`, json);
  }
}
