import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "@planard/@core/backend/api/http.service";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";

@Injectable()
export class ReportScreenshotApi {
  private readonly apiController: string = "reportScreenshot";

  constructor(private api: HttpService) {}

  listReportScreenshot<T>(guidanceId: any): Observable<any> {
    return this.api.get(
      `${this.apiController}/listReportScreenshot/${guidanceId}`
    );
  }

  addReportScreenshot<T>(screenshot: ReportScreenshot): Observable<any> {
    return this.api.post(
      `${this.apiController}/addReportScreenshot`,
      screenshot
    );
  }

  delete<T>(screenShotId: any): Observable<any> {
    var json = { id: screenShotId };
    const endPointUrl = `${this.apiController}/delete`;
    return this.api.post(endPointUrl, json);
  }
}
