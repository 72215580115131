<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div id="main-div">
          <div class="div" style="width: 20%">
            <mat-card-title
              >{{ "reports.costOverview" | translate }}<br />
              <span style="font-size: 15px"
                >{{ companyNumberFormat }} {{ companyCurrency }}</span
              ></mat-card-title
            >
          </div>

          <div
            class="div"
            style="width: 15%"
            [ngStyle]="{
              'margin-left': this.package == 'basic' ? '20%' : '0%'
            }"
          >
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="scenarioId"
                placeholder="{{ 'reports.scenario' | translate }}"
                [formControl]="scenarioControl"
                required
              >
                <mat-option
                  *ngFor="let o of scenarioList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="scenarioControl.hasError('required')">{{
                "reports.scenarioChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- CURRENCY -->
          <div class="div" style="width: 15%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="currency"
                placeholder="{{ 'reports.currency' | translate }}"
                [formControl]="currencyControl"
                required
              >
                <mat-option [value]="0" value="option">{{
                  "reports.defaultCurrency" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of guidanceFxRateList"
                  [value]="o.fxRateId"
                  value="option"
                >
                  {{ getFxRate(o.fxRateId) }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="currencyControl.hasError('required')">{{
                "reports.currencyChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- PERIOD -->
          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="period"
                multiple
                placeholder="{{ 'reports.period' | translate }}"
                [formControl]="periodControl"
                required
              >
                <mat-optgroup
                  *ngFor="let group of periodGroup"
                  [label]="group.name"
                >
                  <mat-option
                    *ngFor="let period of group.period"
                    [value]="period"
                  >
                    {{ period.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-error *ngIf="periodControl.hasError('required')">{{
                "reports.periodChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- GRANULARITY -->
          <div class="div" *ngIf="this.package == 'premium'" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="granularity"
                name="granularity"
                placeholder="{{ 'reports.granularity' | translate }}"
                [formControl]="granularityControl"
                required
              >
                <mat-option
                  *ngFor="let o of granularityList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="granularityControl.hasError('required')">{{
                "reports.granularityChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- SUBDEPARTMENT -->
          <div
            class="div"
            style="width: 10%"
            *ngIf="granularity == 0 && this.package == 'premium'"
          >
            <mat-form-field style="width: 85%">
              <mat-select
                multiple
                [(ngModel)]="subDepartmentId"
                placeholder="{{ 'reports.subdepartment' | translate }}"
                [formControl]="subDeptControl"
                disabled
              >
                <mat-option
                  *ngFor="let o of subDeptList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- SUBDEPARTMENT -->
          <div
            class="div"
            style="width: 10%"
            *ngIf="granularity != 0 && this.package == 'premium'"
          >
            <mat-form-field style="width: 85%">
              <mat-select
                multiple
                [(ngModel)]="subDepartmentId"
                placeholder="{{ 'reports.subdepartment' | translate }}"
                [formControl]="subDeptControl"
                required
              >
                <mat-option
                  *ngFor="let o of subDeptList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="periodControl.hasError('required')">{{
                "reports.subdepartmentChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- DISPLAY -->
          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="display"
                placeholder="{{ 'reports.display' | translate }}"
                [formControl]="displayControl"
                required
              >
                <mat-option
                  *ngFor="let o of displayList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="displayControl.hasError('required')">{{
                "reports.displayChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <div style="width: 85%">
              <button color="accent" mat-button (click)="isValid()">
                {{ "reports.run" | translate }}
              </button>
            </div>
          </div>
        </div>

        <br />

        <div class="responsive-table" style="width: auto" #screen>
          <ag-grid-angular
            #agGrid
            style="width: 100%; height: 100%"
            class="ag-theme-material"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [components]="components"
            [enableBrowserTooltips]="true"
            [enterMovesDownAfterEdit]="true"
            rowSelection="multiple"
            (gridReady)="onGridReady($event)"
            [suppressRowClickSelection]="true"
            [overlayLoadingTemplate]="loadingTemplate"
            [overlayNoRowsTemplate]="noRowsTemplate"
            (cellClicked)="onCellClicked($event)"
          >
          </ag-grid-angular>
        </div>

        <br />

        <mat-card-actions>
          <button
            color="accent"
            mat-raised-button
            type="button"
            (click)="setExportData()"
          >
            <mat-icon>cloud_upload</mat-icon>&nbsp;{{
              "reports.export" | translate
            }}&nbsp;&nbsp;</button
          >&nbsp;
          <button (click)="take()" color="accent" mat-raised-button>
            <mat-icon>camera_alt</mat-icon>&nbsp;{{
              "reports.take" | translate
            }}</button
          >&nbsp;
          <button
            *ngIf="img"
            (click)="reset()"
            color="accent"
            mat-raised-button
          >
            <mat-icon>delete_sweep</mat-icon>&nbsp;{{
              "reports.reset" | translate
            }}</button
          >&nbsp;
          <button *ngIf="img" (click)="save()" color="accent" mat-raised-button>
            <mat-icon>save</mat-icon>&nbsp;{{ "reports.save" | translate }}
          </button>
          <button
            color="accent"
            mat-raised-button
            type="button"
            style="float: right"
            (click)="openRows()"
          >
            {{ "reports.expand" | translate }}
          </button>
        </mat-card-actions>

        <br />
        <br />
        <img
          *ngIf="img"
          src="{{ img }}"
          style="width: 50%; height: 50%; border: solid; border-color: gold"
        />
      </mat-card-content>
    </mat-card>

    <!--<mat-card *ngIf="guidanceIsStart==null">
      <mat-card-content style="min-height: 200px;">
        <div>
          <div class="div" style="width: 50%;">
            <mat-card-title>Cost Overview</mat-card-title>
          </div>
        </div>
        <br>
        <br>
        <span *ngIf="isRun!=true" style="width: 60%; float: right;">Guidance not started yet.</span>
      </mat-card-content>
  </mat-card>-->
  </div>
</div>
