<h1 mat-dialog-title>{{ "costTypeAdd.title" | translate }}</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field *ngIf="this.package == 'premium'">
      <mat-select
        tabindex="1"
        (selectionChange)="assetsSet()"
        formControlName="costType"
        name="costType"
        placeholder="{{ 'costTypeAdd.costType' | translate }}"
      >
        <mat-option *ngFor="let o of costTypeList" [value]="o" value="option">
          <span *ngIf="'enums.costtype.' + o as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        *ngIf="cat != 6"
        tabindex="2"
        [(ngModel)]="cat"
        (selectionChange)="setSubcategory()"
        formControlName="categoryId"
        name="categoryId"
        placeholder="{{ 'costTypeAdd.category' | translate }}"
      >
        <mat-option
          *ngFor="let o of categoryList"
          [value]="o.id"
          value="option"
        >
          <span *ngIf="'category.' + o.name as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
      <mat-select
        *ngIf="cat == 6"
        tabindex="2"
        [(ngModel)]="cat"
        (selectionChange)="setSubcategory()"
        formControlName="categoryId"
        name="categoryId"
        placeholder="{{ 'costTypeAdd.category' | translate }}"
        disabled
      >
        <mat-option
          *ngFor="let o of categoryList"
          [value]="o.id"
          value="option"
        >
          <span *ngIf="'category.' + o.name as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        tabindex="3"
        formControlName="subCategoryId"
        name="subCategoryId"
        placeholder="{{ 'costTypeAdd.subCategory' | translate }}"
      >
        <mat-option
          *ngFor="let o of subCategoryList"
          [value]="o.id"
          value="option"
        >
          <span *ngIf="'subcategory.' + o.name as variable">{{
            variable | translate
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        tabindex="4"
        maxlength="25"
        placeholder="{{ 'costTypeAdd.name' | translate }}"
        name="name"
        formControlName="name"
      />
    </mat-form-field>

    <mat-form-field *ngIf="lifeSpan == true">
      <input
        matInput
        type="number"
        tabindex="5"
        placeholder="{{ 'costTypeAdd.lifeSpan' | translate }}"
        name="lifeSpan"
        formControlName="lifeSpan"
      />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button tabindex="6" (click)="save()">
    {{ "costTypeAdd.save" | translate }}
  </button>
  <button mat-button tabindex="7" (click)="cancelClick()">
    {{ "costTypeAdd.cancel" | translate }}
  </button>
</div>
