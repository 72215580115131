import { BaseEntity } from "@planard/@core/base/BaseEntity";

export class Guidance extends BaseEntity {
  companyId?: number;
  planningCycleName?: string;
  start?: Date;
  end?: Date;
  closed?: boolean;
  preSubmission?: Date;
  finalSubmission?: Date;
  granularityType?: number;
}
