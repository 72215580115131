<h1 mat-dialog-title>Answer to {{ faq.question }}</h1>
<form (ngSubmit)="save()" [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        formControlName="answer"
        matInput
        name="answer"
        placeholder="answer"
      />
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-button tabindex="2" type="submit">Save</button>
    <button (click)="cancel()" mat-button tabindex="-1" type="button">
      Cancel
    </button>
  </div>
</form>
