import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Observable } from "rxjs";
import { ApprovalFlow } from "@planard/@core/entities/approvalFlow";

@Injectable()
export class ApprovalFlowApi {
  private readonly apiController: string = "approval";

  constructor(private api: HttpService) {}

  update<T>(approvalFlow: ApprovalFlow): Observable<any> {
    return this.api.post(`${this.apiController}/update`, approvalFlow);
  }

  listApprovers<T>(): Observable<any> {
    return this.api.get(`${this.apiController}/listApprovers`);
  }
}
